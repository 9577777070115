import { SurveyFull } from "../interfaces/Survey";
import { SurveyFormData } from "../interfaces/SurveyForm";

export const surveyFormDataMapper = (survey: SurveyFull): SurveyFormData => {
  return {
    id: survey.survey.survey.id.toString(),
    name: survey.survey.survey.title,
    required: Boolean(survey.survey.survey.mandatory),
    active: Boolean(survey.survey.survey.status),
    type: survey.survey.survey.type,
    questions: survey.survey?.questions?.map((question) => ({
      id: question?.id?.toString(),
      name: question?.question,
      required: Boolean(question?.required_field),
      input_type: question?.answers?.[0]?.field_type,
      options: question?.answers?.map((option) => ({
        id: option?.id?.toString(),
        name: option?.answer,
      })),
    })),
  };
};
