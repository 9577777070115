import { t } from "i18next";
import { api } from ".";
import {
  APiQueryBase,
  APiQueryJSONBase,
  ApiResponseBase,
} from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { VehicleType } from "../interfaces/VehicleType";
import { isErrorResult } from "../utils/api";

export class VehicleTypeService {
  static async get({
    filter,
    page,
  }: APiQueryBase): Promise<ApiResponseBase<VehicleType[]> | ErrorResult> {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/vehicle-type`, {
        params: {
          page,
          per_page: 2,
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getWithFilter({
    filter,
    page,
  }: APiQueryJSONBase<VehicleType>): Promise<
    ApiResponseBase<VehicleType[]> | ErrorResult
  > {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/vehicle-type`, {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadVehicleTypeOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await this.getWithFilter({
      page,
      filter: {
        description: search,
      },
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d) => ({
      label: `${t(d.description)}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async getAllVehicleTypes(): Promise<VehicleType[]> {
    const first_page = await this.get({
      page: 1,
    });

    if (isErrorResult(first_page)) return [];
    if (first_page.meta?.current_page === first_page.meta?.last_page)
      return first_page.data;

    const all_pages_result = await Promise.all(
      [...new Array(first_page.meta?.last_page)].map((_, i) =>
        this.get({ page: i + 1 })
      )
    );

    let only_success_results: VehicleType[] = [];

    all_pages_result.forEach((results) => {
      if (!isErrorResult(results)) {
        only_success_results = only_success_results.concat(results.data);
      }
    });

    return only_success_results;
  }
}
