import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EmbeddedIntelligence from "./EmbeddedIntelligence";
import FleetMateSettings from "./Settings";

import { Container } from "./styles";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";
import GroupsConfigs from "./GroupsConfigs";
import FleetMateGroupSettings from "./Settings/FleetMateGroupSettings";

const ConfigsFleetMate: React.FC = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState("configs");

  useEffect(() => {
    const getActiveTab = JSON.parse(window.localStorage.getItem("activeTab")!);
    if (getActiveTab) {
      setKey(getActiveTab);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", JSON.stringify(key));
  }, [key]);

  return (
    <ProtectedComponent
      module_name={module_names.CONFIG_FLEETMATE}
      property="view"
      fallbackComponent={
        <Container
          as={Tabs}
          activeKey={key}
          className="mb-3"
          style={{ marginTop: "-25px", marginLeft: "-25px" }}
          onSelect={(k: any) => setKey(k)}
        >
          <Tab
            eventKey="group_embedded_intelligence"
            title={t("configs.fleetmate.settings")}
          >
            <GroupsConfigs />
          </Tab>
          <Tab eventKey="configs" title={t("configs.fleetmate.settings")}>
            <FleetMateSettings />
          </Tab>
        </Container>
      }
    >
      <Container
        as={Tabs}
        activeKey={key}
        className="mb-3"
        style={{ marginTop: "-25px", marginLeft: "-25px" }}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab
          eventKey="group_embedded_intelligence"
          title={t("configs.fleetmate.settings")}
        >
          <GroupsConfigs />
        </Tab>
        {/* <Tab eventKey="configs2" title={t("configs.fleetmate.settings")}>
        <FleetMateGroupSettings />
      </Tab>
      <Tab eventKey="configs" title={t("configs.fleetmate.settings")}>
        <FleetMateSettings />
      </Tab> */}
        <Tab
          eventKey="embedded_intelligence"
          title={t("configs.fleetmate.embedded_intelligence")}
        >
          <EmbeddedIntelligence />
        </Tab>
      </Container>
    </ProtectedComponent>
  );
};

export default ConfigsFleetMate;
