export const module_names = {
  VEHICLES: "Veiculos",
  DRIVERS: "Motorista",
  DRIVERS_JOURNEY: "Comportamentos-De-Direcao",
  ROUTING: "Roteirizacao",
  TRIP_PLAYBACK: "TripPlayBack",
  SENSORS: "Sensors",
  ALERTS: "Alerts",
  GEOFENCES: "Geofences",
  FLEETMATE: "Fleetmate",
  HOS: "HOS",
  CONFIG_COMPANY: "Configuracao-Company",
  CONFIG_API: "Configuracao-Api-Publica",
  CONFIG_EXIT_LOCATION: "Configuracao-Local-Saida",
  CONFIG_PROFILE: "Configuracao-Perfil",
  CONFIG_USER: "Configuracao-Usuario",
  CONFIG_ROUTING: "Configuracao-Opcoes-Roteirizacao",
  CONFIG_FLEETMATE: "Configuracao-Regra-De-Seguranca",
  CONFIG_BILLING: "Configuracao-Assinatura",
  CONFIG_GENERAL: "Configuracao-Geral",
  CONFIG_AUDIT: "Audits",
  CONFIG_JOURNEY: "Configuracao-Journey",
  CONFIG_SURVEY: "Configuracao-Survey",
  CONFIG_CAMERA: "Configuracao-Camera",
  CAMERA: "Camera",
  TRACKING_ROUTES: "Monitoramento-Tracking",
  REPORTS: "Relatorios",
};
