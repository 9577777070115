import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const vehicle = yup
  .object({
    vehicle_type_id: yup.string().max(40).required(),
    license_plate: yup.string().required(),
    total_weight: yup.string().max(40).required(),
    total_volume: yup.string().max(40).required(),
    total_amount: yup.string().max(40).required(),
    value_by_km: yup.string().max(40).required(),
    // observations: yup.string().max(250).required(),
    // tags: yup.string().max(40).required(),
    // material_type: yup.string().max(40).required(),
  })
  .required();

export const VehicleResolver = yupResolver(vehicle);

const vehicleHardware = yup
  .object({
    actuator_code_constant: yup.string().required(),
  })
  .required();

export const VehicleHardwareResolver = yupResolver(vehicleHardware);
