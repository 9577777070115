import { api } from ".";
import {
  APiQueryJSONBase,
  ApiResponseBase,
} from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { IListCompanyResponse } from "../interfaces/IListCompany";
import { User, UserChangePassword, UserUpdate } from "../interfaces/User";
import { IUserProfile } from "../interfaces/UserProfile";
import { UserLoginResulResultSuccess } from "../interfaces/UserResult";
import { SessionStorage } from "../storage/session_storage";
import { isErrorResult } from "../utils/api";

export class UserService {
  static async getUsers({
    filter,
    page,
    order,
  }: {
    filter?: string;
    page?: number;
    order?: any;
  }): Promise<UserLoginResulResultSuccess | ErrorResult> {
    return api
      .get(`/api/v2/user`, {
        params: {
          page: page ?? 1,
          filter: {
            search: filter,
          },
          order,
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  static async getUserById(
    userId: number
  ): Promise<ApiResponseBase<User> | ErrorResult> {
    return await api
      .get(`/api/v2/user/${userId}`, {})
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getWithFilter({
    filter,
    page,
  }: APiQueryJSONBase<User>): Promise<ApiResponseBase<User[]> | ErrorResult> {
    return await api
      .get(`/api/v2/user`, {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getUsersOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await this.getWithFilter({
      filter: { name: search },
      page,
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d) => ({
      label: `${d.name + " " + d.last_name}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async create(
    data: User
  ): Promise<UserLoginResulResultSuccess | ErrorResult> {
    const _user = SessionStorage.getUser();

    const [name, ...last_name] = data.name?.split(" ");
    data.name = name;
    data.last_name = last_name.join(" ");
    if (!data.company_id) data.company_id = _user.company_id;

    data.languages_id = 1;

    return api
      .post(`/api/v2/user`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(user_id: number): Promise<ErrorResult> {
    return api
      .delete(`/api/v2/user`, { data: { user_id } })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getProfiles(
    page?: number
  ): Promise<ApiResponseBase<IUserProfile[]> | ErrorResult> {
    return api
      .get(`/api/v2/user-profile`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async changePassword(data: UserChangePassword): Promise<ErrorResult> {
    return api
      .put(`/api/v2/user/password`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async associateVehicles(
    userId: number,
    data: {
      vehicle_tags: number[];
      vehicles: number[];
    }
  ): Promise<ErrorResult> {
    return api
      .put(`/api/v2/user/update-user-vehicle-permission/${userId}`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  static async update(data: UserUpdate): Promise<ErrorResult> {
    return api
      .put(`/api/v2/user`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async activate(userId: number): Promise<ErrorResult> {
    return api
      .post(`/api/v2/user/activate`, { user_id: userId })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async desactivate(userId: number): Promise<ErrorResult> {
    return api
      .post(`/api/v2/user/desactivate`, { user_id: userId })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async updateCompany(
    company_id: number
  ): Promise<IListCompanyResponse> {
    return api
      .put<{
        errors: any[];
        message: string;
      }>("/api/v2/user/update-access-company", {
        company_id,
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async listCompany({
    filter,
    order={"company_name": "asc"},
    per_page=500,
  }: IListCompanyEntry): Promise<IListCompanyResponse> {
    return api
      .get<IListCompanyResponse>("/api/v2/user/list-access-company", {
        params: {
          filter,
          order,
          per_page,          
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadTrackingFilters(){
    return api
      .get("/api/v2/filter/user-filter/module/tracking_filter")
      .then((r) => r?.data)
      .catch((e) => {})
  }
}

interface IListCompanyEntry {
  filter?: any;
  order?: any;
  per_page?: any;  
}
