import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import slide1 from "../../assets/img/login/slide1.png";
import slide2 from "../../assets/img/login/slide2.png";
import slide3 from "../../assets/img/login/slide3.png";
import slide4 from "../../assets/img/login/slide4.png";
import slide1En from "../../assets/img/login/slide1-en.png";
import slide3En from "../../assets/img/login/slide3-en.png";
import slide4En from "../../assets/img/login/slide4-en.png";
import slide1Fr from "../../assets/img/login/slide1-fr.png";
import slide3Fr from "../../assets/img/login/slide3-fr.png";
import slide4Fr from "../../assets/img/login/slide4-fr.png";

import "./styles.css";
import { useTranslation } from "react-i18next";

const LoginCarousel: React.FC = () => {
  const { t, i18n } = useTranslation();
  const language = localStorage.getItem("@FLEET_DESK_LANGUAGE") ?? "en";
  const translatedSlidesImages = [
    {
      pt: {
        slide1,
        slide2,
        slide3,
        slide4,
      },
      en: {
        slide1: slide1En,
        slide2,
        slide3: slide3En,
        slide4: slide4En,
      },
      fr: {
        slide1: slide1Fr,
        slide2,
        slide3: slide3Fr,
        slide4: slide4Fr,
      },
    },
  ];

  const slides = [
    {
      title: t("login.slide_first_title"),
      sub_title: t("login.slide_first_subtitle"),
      //@ts-ignore
      image: translatedSlidesImages[0][language.split("-")[0]]?.slide1,
    },
    {
      title: t("login.slide_second_title"),
      sub_title: t("login.slide_second_subtitle"),
      //@ts-ignore
      image: translatedSlidesImages[0][language.split("-")[0]]?.slide2,
    },
    {
      title: t("login.slide_third_title"),
      sub_title: t("login.slide_third_subtitle"),
      //@ts-ignore
      image: translatedSlidesImages[0][language.split("-")[0]]?.slide3,
    },
    {
      title: t("login.slide_fourth_title"),
      sub_title: t("login.slide_fourth_subtitle"),
      //@ts-ignore
      image: translatedSlidesImages[0][language.split("-")[0]]?.slide4,
    },
  ];

  return (
    <div id="login-carousel">
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showArrows={false}
        showStatus={false}
      >
        {slides.map((item) => (
          <div key={item.title} className="item">
            <div className="title mb-1">{item.title}</div>
            <div className="sub-title mb-5">{item.sub_title}</div>

            <div
              className="img"
              style={{ backgroundImage: `url(${item.image})` }}
            />
            {/* <img alt="sensores" src={item.image} /> */}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default LoginCarousel;
