import { Control, UseFormRegister, useFieldArray } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomInputGroup, SectionTitle } from "./styles";
import { FleetMateConfigs } from "../../../core/interfaces/FleetMate";
import { useEffect, useState } from "react";
import { FleetMateService } from "../../../core/services/FleetMateService";
import { useAppSelector } from "../../../core/redux/hooks";
import { getUser } from "../../../core/redux/reducer/auth";

interface EntriesFieldArrayProps {
  control: Control<FleetMateConfigs>;
  register: UseFormRegister<FleetMateConfigs>;
}

export default function EntriesFieldArray({
  control,
  register,
}: EntriesFieldArrayProps) {
  const [portsOptions, setPortsOptions] = useState([]);
  const user = useAppSelector(getUser);
  const { t, i18n } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: "entryPorts",
    keyName: "fieldId",
  });

  async function getAllPorts() {
    const response = await FleetMateService.getPorts();

    const options: any = [];

    response.forEach((obj: any) => {
      if (obj.type === "entry") {
        options.push({
          value: obj.constant,
          label: t(`fleetmate.entries.${obj.constant.toLowerCase()}`),
        });
      }
    });

    setPortsOptions(options);
  }

  useEffect(() => {
    getAllPorts();
  }, [i18n.language, user?.company_id]);

  return (
    <Row>
      <Col className="mb-3">
        <Row>
          <Col className="my-3">
            <SectionTitle>
              {t("configs.fleetmate.setting.entries")}
            </SectionTitle>
          </Col>
        </Row>

        {fields.length > 0 && portsOptions.length > 0 && (
          <Row>
            {fields?.map((item, index) => (
              <Form.Group
                key={`${index}_${item.fieldId}`}
                xl={4}
                md={12}
                as={Col}
                controlId={`entryPorts.${index}.sensor`}
                className="mb-4"
              >
                <input
                  hidden
                  value={index + 1}
                  {...register(`entryPorts.${index}.id`)}
                />
                <CustomInputGroup>
                  <Form.Label>{`${t("configs.fleetmate.setting.entrie")} ${
                    index + 1
                  }`}</Form.Label>
                  <Form.Select {...register(`entryPorts.${index}.sensor`)}>
                    {portsOptions?.map((item: any, i) => (
                      <option key={i} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </CustomInputGroup>
                <CustomInputGroup className="mt-2">
                  <Form.Label>
                    {t("configs.fleetmate.setting.tolerance_time")}
                  </Form.Label>
                  <Form.Control
                    className="py-0"
                    style={{ height: 36 }}
                    placeholder={t("sensors.form_alert_more_then_enter")}
                    defaultValue={0}
                    min={0}
                    type="number"
                    {...register(`entryPorts.${index}.delay`)}
                  />
                  <Form.Label>
                    {t("configs.fleetmate.setting.seconds")}
                  </Form.Label>
                </CustomInputGroup>
              </Form.Group>
            ))}
          </Row>
        )}
      </Col>
    </Row>
  );
}
