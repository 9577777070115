import React, { CSSProperties } from "react";

import { ReactComponent as IcAdd } from "../../assets/icons/add.svg";
import { Container, Title } from "./styles";

interface AddButtonProps {
  children: string;
  disabled?: boolean;
  onClick?(): void;
  style?: CSSProperties;
}

const AddButton: React.FC<AddButtonProps> = ({
  style,
  children,
  onClick,
  disabled,
}) => {
  return (
    <Container
      type="button"
      disabled={disabled}
      style={style}
      onClick={onClick}
    >
      <div className="icon">
        <IcAdd />
      </div>

      <Title>{children}</Title>
    </Container>
  );
};

export default AddButton;
