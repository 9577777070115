import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsArrowLeftShort } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../components/AddButton";
import CustomSearchInput from "../../../components/CustomSearchInput";
import CustomThead from "../../../components/CustomThead";
import LinkAlert from "../../../components/LinkAlert";
import LinkGoBack from "../../../components/LinkGoBack";
import SensorFilterModal from "../../../components/SensorFilterModal";
import TableActions from "../../../components/TableActions";
import PrivateMasterPage from "../../../layout/PrivateMasterPage";
import { Container } from "./styles";
import { AlertsService } from "../../../core/services/AlertsService";
import { isErrorResult } from "../../../core/utils/api";
import { useFieldArray, useForm } from "react-hook-form";
import { APiQueryBase } from "../../../core/interfaces/ApiResponseBase";
import moment from "moment";
import TableFooter from "../../../components/TableFooter";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useAppDispatch } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";

const AlertRule: React.FC = () => {
  const [isBusy, setBusy] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | false>(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: string;
  }>({ page: 1, pages: 0, filter: "" });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  type Register = any & {
    isEdited?: boolean;
    isSelected?: boolean;
  };

  type FormValues = {
    registers: Register[];
  };

  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const actions = {
    edit: t("sensors.option_edit_rule"),
    delete: t("sensors.option_delete_rule"),
  };
  const actionOptions = Object.values(actions);

  function handleSelectAction(action: string, values: any) {
    if (action === actions.edit) {
      navigate(`./${values.id}`)
    } else if (action === actions.delete) {
      setDeleteId(values.id);
    }
  }

  function handleForm() {
    navigate("./new");
  }

  function haveEditedItems(): boolean {
    return !!watch("registers")?.find((item) => !!item?.isEdited);
  }

  async function loadRegistries({ filter, page = 1, order }: APiQueryBase) {
    try {
      const response = await AlertsService.get({ filter, page });
      if (!isErrorResult(response) && response.data) {
        replace(response.data);
        setPagination((prev) => ({
          ...prev,
          page: response?.meta?.current_page || 1, pages: response?.meta?.last_page || 0,
        }));
      }
    } catch (error) {
      console.log("loadRegistries error", error);
    }
  }

  async function onSubmitDelete() {
    if (!deleteId || isBusy) return;
    setBusy(true);

    const response = await AlertsService.delete(deleteId);
    if (response?.success) {
      setBusy(false);
      setDeleteId(false);
      dispatch(updateAlert({ title: t("message.success.delete_many_data") }));
      loadRegistries(pagination);
    } else {
      setBusy(false);
      setDeleteId(false);
      dispatch(
        updateAlert({
          title: t("message.error.delete_data"),
          message: response.message,
          type: "error",
        })
      );
    }
  }

  useEffect(() => {
    loadRegistries({ filter: pagination.filter, page: pagination.page });
  }, [pagination.filter, pagination.page]);

  return (
    <PrivateMasterPage
      title={t("sensors.title")}
      breadcrumb={[t("sensors.title")]}
    >
      <Container className="p-3">
        <Card className="mb-5">
          <Card.Body className="p-4">
            <LinkGoBack onClick={() => navigate(-1)}>
              <BsArrowLeftShort size={24} />
              {t("link.go_back")}
            </LinkGoBack>

            <Row className="mb-3 mt-4">
              <Col className="d-flex align-items-center" xs>
                <AddButton onClick={handleForm}>
                  {t("sensors.new_alert_rule_sensor")}
                </AddButton>
              </Col>

              <Col style={{ maxWidth: 340 }}>
                <Stack direction="horizontal" gap={2}>
                  <CustomSearchInput placeholder={t("search")} />

                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowFilter(true)}
                  >
                    <FaFilter size={18} />
                  </Button>
                </Stack>
              </Col>
            </Row>
            <Table responsive>
              <CustomThead
                data={[
                  { column: "1", name: t("sensors.rule.table_created_at") },
                  { column: "2", name: t("sensors.rule.table_rule_name") },
                  { column: "3", name: t("sensors.rule.table_sensor_type") },
                  { column: "4", name: t("sensors.rule.table_user") },
                  // { column: "5", name: t("sensors.table_alert") },
                  { column: "menu", name: <th />, menu: true },
                ]}
                onChangeOrder={(event) => console.log(event)}
              />
              <tbody>
                {fields.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {moment(item?.created_at).format("DD/MM/YYYY - HH:mm")}
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.type?.description}</td>
                    <td>{item?.user_name}</td>
                    {/* <td>
                      <LinkAlert>4</LinkAlert>
                    </td> */}
                    <td>
                      <TableActions
                        id={item.id}
                        items={actionOptions}
                        onSelectAction={(action) =>
                          handleSelectAction(action, item)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>

          <TableFooter
            actions={{
              hidden: !haveEditedItems(),
              titleSubmit: isSubmitting ? t("status.saving") : t("button.save"),
              titleCancel: t("button.cancel"),
              isSubmitting,
            }}
            paginate={{
              current_page: pagination.page,
              qtd: pagination.pages,
              onSelectPage: (page) =>
                setPagination((prev) => ({ ...prev, page })),
            }}
          />
        </Card>

        <SensorFilterModal
          visible={showFilter}
          onClose={() => setShowFilter(false)}
        />
      </Container>

      <ConfirmationModal
        visible={!!deleteId}
        title={t("alerts.confirm_delete_title")}
        onClose={() => setDeleteId(false)}
        onConfirm={onSubmitDelete}
        isLoading={isBusy && !!deleteId}
      />
    </PrivateMasterPage>
  );
};

export default AlertRule;
