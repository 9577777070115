import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/AddButton";

import { Container } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import Pagination from "../../components/Pagination";
import { useAppDispatch } from "../../core/redux/hooks";
import { updateAlert } from "../../core/redux/reducer/alert";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomSearchInput from "../../components/CustomSearchInput";
import SubContractorInvitationModal from "../../components/SubContractorInvitationModal";
import Footer from "../../components/Footer";
import EmptyListMessage from "../../components/EmptyListMessage";
import { ISubcontractor } from "../../core/interfaces/Subcontractor";
import {
  GetSubcontractor,
  SubcontractorService,
} from "../../core/services/SubcontractorService";
import { ActionToolButton } from "../../components/ActionToolButton";
import { TbTrash } from "react-icons/tb";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import CustomThead from "../../components/CustomThead";
import { FaCheckSquare } from "react-icons/fa";
import { MdCancelPresentation } from "react-icons/md";

type Register = ISubcontractor & {
  isEdited?: boolean;
};

type FormValues = {
  registers: Register[];
};

const ConfigsSubContractors: React.FC = () => {
  const [isBusy, setBusy] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const [invitation_is_visible, setInvitationIsVisible] = useState(false);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: string;
  }>({ page: 1, pages: 0, filter: "" });

  async function loadSubcontractors({
    filter = "",
    page = 1,
  }: GetSubcontractor) {
    try {
      const _response = await SubcontractorService.get({ filter, page });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        replace(_response.data);

        setPagination((prev) => ({
          ...prev,
          page: _response.meta?.current_page || 0,
          pages: _response.meta?.last_page || 0,
        }));
      }
    } catch (error: any) {
      console.log("Erro in load subcontractor", error.message);
    }
  }

  function handleOnInvitationSuccess() {
    setInvitationIsVisible(false);
    loadSubcontractors(pagination);
    dispatch(
      updateAlert({
        title: t("configs.subcontractor.successfully_invited"),
        // message: "O subcontratado receberá um link no email cadastrado.",
      })
    );
  }

  const onSubmit: SubmitHandler<FormValues> = async ({ registers }) => {
    try {
      for await (const data of registers) {
        if (!!data.id && data.isEdited) {
          const response = await SubcontractorService.setVisible(
            data.subcontractor_id,
            data.visible
          );

          if (!response?.success) throw Error(response?.message);

          const i = registers.findIndex((item) => item.id === data.id);
          registers[i] = { ...data, isEdited: false };
        }
      }

      reset({ registers });

      dispatch(updateAlert({ title: t("message.success.save_data") }));
    } catch (error: any) {
      console.log("Erro in onsubmit edit subcontractor", error.message);
    }
  };

  const handleEditCancel = async () => {
    replace(fields);
  };

  const selectStatusLabel = (is_active: boolean, total_vehicles: number) => {
    if(is_active && total_vehicles > 0){
      return t("configs.subcontractor.active");
    }else if(is_active && total_vehicles == 0){
      return t("configs.subcontractor.awaiting_approval");
    }else{
      return t("configs.subcontractor.inactive");
    }
  }

  useEffect(() => {
    loadSubcontractors({ filter: pagination.filter, page: pagination.page });
  }, [pagination.filter, pagination.page]);

  return (
    <Container className="mb-3">
      <Row className="mb-4">
        <Col xl={7} lg={6} md={4} className="d-flex align-items-center">
          <h5 className="mb-0 p-0">{t("configs.subcontractors.title")}</h5>
        </Col>

        <Col xl={5} lg={6} md={8}>
          <Row>
            <Col>
              <AddButton onClick={() => setInvitationIsVisible(true)}>
                {t("configs.subcontractors.add")}
              </AddButton>
            </Col>

            <Col>
              <CustomSearchInput
                placeholder={t("search")}
                onChange={(e) =>
                  setPagination((prev) => ({
                    ...prev,
                    page: 1,
                    filter: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive>
            <CustomThead
              data={[
                {
                  column: "1",
                  name: t("configs.subcontractors.table_subcontractor"),
                },
                {
                  column: "2",
                  name: t("configs.subcontractors.table_vehicle"),
                },
                { column: "3", name: t("configs.subcontractors.table_email") },
                { column: "5", name: t("configs.subcontractors.table_status") },
              ]}
              onChangeOrder={(event) => console.log(event)}
            />

            <tbody>
              {fields?.map((item: any, index) => (
                <tr key={`${index}_${item.fieldId}`}>
                  <td>{item.company_hired.company_name}</td>
                  <td>{item.total_vehicles}</td>
                  <td>{item.company_hired.mail}</td>
                  <td className="col-2">
                    {selectStatusLabel(item.active, item.total_vehicles)}
                  </td>
                  {/* <td>
                    {
                      item.active == 0 && 
                      <ActionToolButton
                        onClick={(e) => {
                          e.preventDefault();
                          setApproveSubcontractor(item);
                        }}
                      >
                        <FaCheckSquare />
                      </ActionToolButton>
                    }
                    {
                      item.active == 1 && 
                      <ActionToolButton
                        onClick={(e) => {
                          e.preventDefault();
                          setDisapproveSubcontractor(item);
                        }}
                      >
                        <MdCancelPresentation />
                      </ActionToolButton>
                    }

                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          {!fields.length && (
            <EmptyListMessage style={{ marginTop: "5%" }}>
              <div style={{ maxWidth: "90%" }}>
                {t("configs.subcontractor.empty_list")}

                <div className="empty-list-button-add">
                  <AddButton
                    style={{
                      flexDirection: "column",
                      gap: 5,
                    }}
                    onClick={() => setInvitationIsVisible(true)}
                  >
                    {t("configs.subcontractors.add")}
                  </AddButton>
                </div>
              </div>
            </EmptyListMessage>
          )}
        </Col>
      </Row>

      <Row className="mt-5">
        <Footer>
          <div className="mb-3">
            <Stack direction="horizontal" gap={2}>
              <Button
                onClick={handleSubmit(onSubmit, console.log)}
                disabled={
                  !watch("registers")?.find(({ isEdited }) => !!isEdited)
                }
              >
                {isSubmitting ? t("status.saving") : t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={handleEditCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </div>

          <div className="mx-3">
            <Pagination
              current_page={pagination.page}
              qtd={pagination.pages}
              onSelectPage={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
            />
          </div>
        </Footer>
      </Row>

      <SubContractorInvitationModal
        visible={invitation_is_visible}
        onClose={() => setInvitationIsVisible(false)}
        onSuccess={() => handleOnInvitationSuccess()}
      />
      
    </Container>
  );
};

export default ConfigsSubContractors;
