import React from "react";
import { Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormRegister,
  useFormState,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TbGripVertical } from "react-icons/tb";
import AddButton from "../AddButton";
import QuestionNestedFieldArray from "./questionNestedFieldArray";
import { ActionButton, Card, Line, SpinnerContent } from "./styles";
import { BsTrash } from "react-icons/bs";
import { SurveyFormData } from "../../core/interfaces/SurveyForm";

interface QuestionFieldArrayProps {
  control: Control<SurveyFormData>;
  register: UseFormRegister<SurveyFormData>;
  isLoading?: boolean;
}

const QuestionFieldArray: React.FC<QuestionFieldArrayProps> = ({
  control,
  register,
  isLoading,
}) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
    keyName: "fieldId",
  });

  const { errors } = useFormState({
    control,
  });

  const { questions } = useWatch({
    control,
  });

  const handleAddField = () => {
    append({
      name: "",
      required: true,
      input_type: "text",
    });
  };

  return (
    <div>
      {fields?.map((field, index) => (
        <Card key={field.fieldId}>
          <Row>
            <Col sm={8}>
              <Stack direction="horizontal" gap={3}>
                <TbGripVertical size={24} />

                <Form.Group
                  style={{ flex: 1 }}
                  controlId={`questions.${index}.input_type`}
                >
                  <Form.Control
                    plaintext
                    placeholder={t("configs.app.question_without_title")}
                    isInvalid={!!errors?.questions?.[index]?.name?.message}
                    {...register(`questions.${index}.name`)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors?.questions?.[index]?.name?.message}
                  </Form.Control.Feedback>

                  <Line className="m-0" />
                </Form.Group>
              </Stack>
            </Col>

            <Col sm={4}>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId={`questions.${index}.input_type`}
                >
                  <Form.Select {...register(`questions.${index}.input_type`)}>
                    <option value="text">
                      {t("configs.app.option_type_short")}
                    </option>
                    <option value="textarea">
                      {t("configs.app.option_type_long")}
                    </option>
                    <option value="checkbox">
                      {t("configs.app.option_type_multiple")}
                    </option>
                    <option value="select">
                      {t("configs.app.option_type_selection")}
                    </option>
                    <option value="image">
                      {t("configs.app.option_type_images")}
                    </option>
                  </Form.Select>
                </Form.Group>

                <ActionButton
                  type="button"
                  hidden={!!questions?.[index]?.id}
                  onClick={() => remove(index)}
                >
                  <BsTrash size="1.3rem" />
                </ActionButton>
              </Row>

              <Controller
                control={control}
                name={`questions.${index}.required`}
                render={({ field: { name, onChange, onBlur, value } }) => (
                  <Form.Group
                    controlId={name}
                    hidden={
                      questions?.[index]?.input_type !== "text" &&
                      questions?.[index]?.input_type !== "textarea" &&
                      questions?.[index]?.input_type !== "image"
                    }
                  >
                    <Form.Switch
                      label={t("configs.app.response_required")}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value}
                    />
                  </Form.Group>
                )}
              />
            </Col>

            {(questions?.[index]?.input_type === "checkbox" ||
              questions?.[index]?.input_type === "select") && (
              <QuestionNestedFieldArray
                nestIndex={index}
                control={control}
                register={register}
              />
            )}
          </Row>
        </Card>
      ))}

      <SpinnerContent hidden={!isLoading}>
        <Spinner animation="border" />
      </SpinnerContent>

      <AddButton
        children={t("configs.app.button_new_question")}
        onClick={handleAddField}
      />
    </div>
  );
};

export default QuestionFieldArray;
