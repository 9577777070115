import { TrackingTabOptions } from "../components/TrakingTasksModal";
import { PlanRoutingShow } from "../core/interfaces/RoutingModalInfo";

export const ROUTING_TASK_OPTIONS_DEFAULT: PlanRoutingShow = {
  show: {
    task: true,
    vehicle: true,
  },
};

export const PLAN_ROUTING_TASK_OPTIONS: TrackingTabOptions = {
  show: {
    task: true,
    vehicle: true,
  },
};
