import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  max-height: max-content;

  .card {
    width: 100%;
    min-height: 100%;
    max-height: max-content;
  }

  .table {
    tbody tr:nth-child(1) td {
      padding-top: 16px;
    }

    td:last-child {
      width: 50px;
      &:hover {
        cursor: pointer;
      }
    }

    td {
      color: #6c757d;
    }
  }
`;
