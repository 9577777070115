import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useForm,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
} from "react-hook-form";

import IcCam from "../../../assets/icons/ic_cam.svg";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import { CustomInfos, EditButton } from "./styles";
import { MaskService } from "../../../utils/masks-service";
import { useLocation, useNavigate } from "react-router-dom";
import { LiveStream, Vehicle } from "../../../core/interfaces/Vehicle";
import { AsyncPaginate } from "react-select-async-paginate";
import { VehicleService } from "../../../core/services/VehicleService";
import { isErrorResult } from "../../../core/utils/api";
import moment from "moment";
import { useAppDispatch } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";
import CameraModal from "../../../components/CameraModal";
import { useFormatDate } from "../../../core/hooks/useFormatDate";
import { module_names } from "../../../constants/module_names";
import { ProtectedComponent } from "../../../components/ProtectedComponent";

interface MonitoringProps {
  vehicleId?: number;
}

export type FormValues = {
  camera_model_id: { label?: string; value: number };
  phone_number: string;
  imei: string;
};

const Monitoring: React.FC<MonitoringProps> = ({ vehicleId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { validateDateFormatAndTime } = useFormatDate();
  const [lastUpdate, setLastUpdate] = useState("");
  const [url, setUrl] = useState("");
  const [typeCam, setTypeCam] = useState("");
  const [putCam, setPutCam] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>();

  async function loadCam() {
    const response = await VehicleService.getCam(vehicleId?.toString() ?? "");
    const isError = isErrorResult(response);
    const models = await VehicleService.getModelsCam();
    if (!isError && response) {
      setPutCam(true);
      if (response.message === "not found") setPutCam(false);
      setValue("camera_model_id", {
        value: response.camera_model_id,
        label:
          models.find((obj: any) => obj.id === response.camera_model_id)
            ?.name || "",
      });
      setValue("imei", response.imei);
      setValue("phone_number", "+" + response.phone_number);
      setLastUpdate(response.last_heartbeat_at);
    } else {
      setPutCam(false);
    }
  }

  useEffect(() => {
    if (!vehicleId) return;
    loadCam();
  }, [vehicleId]);

  const Cam = ({ title, type }: { title: string; type: string }) => {
    return (
      <button
        className="cam"
        type="button"
        onClick={() => handleCamLive(type, "start")}
      >
        <div className="icon">
          <img src={IcCam} alt="" />
        </div>
        <div className="title">{title}</div>
      </button>
    );
  };

  async function handleCamLive(type: string, action: string) {
    if (!vehicleId) return;

    setTypeCam(type);

    const obtToSend: LiveStream = {
      vehicle_id: vehicleId,
      source: type,
      action: action,
    };

    const response = await VehicleService.liveStream(obtToSend);

    if (action === "stop") return setUrl("");

    setUrl(response?.httpAddress);
  }

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    if (location?.pathname === "/vehicles/edit") {
      const { data = null } = location?.state as { data: Vehicle | null };

      const objToSend = {
        ...values,
        camera_model_id: values.camera_model_id.value,
        phone_number: values.phone_number.replace("+", ""),
        company_id: data?.company_id,
        vehicle_id: vehicleId,
      };

      try {
        if (putCam && vehicleId) {
          const response = await VehicleService.putCam(
            objToSend,
            vehicleId.toString()
          );

          if (!isErrorResult(response) && response) {
            dispatch(updateAlert({ title: t("message.success.save_data") }));
            requestAnimationFrame(() => navigate(-1));
          }
        } else {
          const response = await VehicleService.postCam(objToSend);

          if (!isErrorResult(response) && response) {
            dispatch(updateAlert({ title: t("message.success.save_data") }));
            requestAnimationFrame(() => navigate(-1));
          }
        }
      } catch (error: any) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            message: error.message,
            type: "error",
          })
        );
      }
    }
  };

  const onError: SubmitErrorHandler<FormValues> = (error) => {
    console.log("onSubmitError", error);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Row className="m-3">
        <h6>{t("vehicles.video_monitoring")}</h6>
        <Col xs="8" className="mt-4">
          <Stack direction="horizontal" className="gap-5">
            <Cam title={t("vehicles.hardware.camera.label") + " 1"} type="IN" />
            <Cam
              title={t("vehicles.hardware.camera.label") + " 2"}
              type="OUT"
            />
          </Stack>
        </Col>
        {lastUpdate && (
          <Row className="mt-4 d-flex">
            <CustomInfos>
              <span className="input-label">
                {t("vehicles.video_monitoring.last_update")}
              </span>
              <span className="input-info">
                {validateDateFormatAndTime(lastUpdate)}
              </span>
            </CustomInfos>
            <CustomInfos>
              <span className="input-label">
                {t("vehicles.video_monitoring.network_type")}
              </span>
              <span className="input-info">4G</span>
            </CustomInfos>
          </Row>
        )}

        <Col xs="12">
          <hr className="mt-3" />
        </Col>

        <h6 className="m-0">
          {t("vehicles.video_monitoring.hardware_information")}
        </h6>

        <Row className="mb-3 mt-4">
          <CustomInfos>
            <Form.Label className="input-label">
              {t("vehicles.video_monitoring.model")}
            </Form.Label>
            <Controller
              control={control}
              name="camera_model_id"
              render={({ field: { value, onChange, onBlur } }) => (
                <AsyncPaginate
                  isClearable
                  placeholder={t("input.select_text")}
                  loadOptions={() => VehicleService.loadModelsCam()}
                  additional={{ page: 1 }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </CustomInfos>
          <CustomInfos>
            <Form.Label className="input-label">
              {t("vehicles.video_monitoring.sim_card")}
            </Form.Label>
            <Controller
              control={control}
              name="phone_number"
              render={({ field: { value, onChange } }) => (
                <CustomPhoneInput
                  defaultCountry="BR"
                  country={value?.substring(0, 2)}
                  placeholder={t("routing.phone_enter")}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </CustomInfos>
        </Row>
        <CustomInfos>
          <Form.Label className="input-label">
            {t("vehicles.video_monitoring.imei")}
          </Form.Label>
          <Form.Control
            {...register("imei", {
              onChange: (e) => MaskService.toInputMask("imei", e),
            })}
            placeholder={t("vehicles.video_monitoring.imei")}
          />
        </CustomInfos>
        <Col xs="12" className="mt-4">
          <ProtectedComponent
            module_name={module_names.CONFIG_CAMERA}
            property="edit"
            fallbackComponent={
              <Button
                disabled
                id="vehicle_monitoring_button_submit"
                type="button"
                className="me-2"
              >
                {t("button.save")}
              </Button>
            }
          >
            <Button
              id="vehicle_monitoring_button_submit"
              type="submit"
              className="me-2"
            >
              {isSubmitting ? t("status.saving") : t("button.save")}
              {isSubmitting && <Spinner animation="border" size="sm" />}
            </Button>
          </ProtectedComponent>

          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate(-1)}
          >
            {t("button.cancel")}
          </Button>
        </Col>
      </Row>

      <CameraModal
        isLive
        onClose={() => handleCamLive(typeCam, "stop")}
        url={url}
      />
    </Form>
  );
};

export default Monitoring;
