import { api } from ".";
import {
  APiQueryJSONBase,
  ApiResponseBase,
} from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import {
  AutomaticStatusDetectionResponse,
  DriverStatus,
  DriverStatusGroup,
  IStatusDriverBody,
  RequestPassword,
} from "../interfaces/StatusDriver";
import { isErrorResult } from "../utils/api";

export class StatusDriverService {
  static async get(): Promise<
    | ApiResponseBase<{
        other_status: DriverStatusGroup[];
        status_driver_start_and_end: DriverStatusGroup[];
      }>
    | ErrorResult
  > {
    return await api
      .get(`/api/v2/status-driver-and-substatus`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getOnlyStatus(
    params?: APiQueryJSONBase<DriverStatus>
  ): Promise<ApiResponseBase<DriverStatus[]> | ErrorResult> {
    return await api
      .get("/api/v2/status-driver/only-status", {
        params: {
          page: params?.page,
          per_page: params?.per_page,
          filter: JSON.stringify(params?.filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getAllOnlyStatus(): Promise<DriverStatus[]> {
    const first_page = await this.getOnlyStatus({
      page: 1,
      per_page: 50,
    });

    if (isErrorResult(first_page)) return [];
    if (first_page.meta?.current_page === first_page.meta?.last_page)
      return first_page.data;

    const all_pages_result = await Promise.all(
      [...new Array(first_page.meta?.last_page)].map((_, i) =>
        this.getOnlyStatus({ page: i + 1 })
      )
    );

    let only_success_results: DriverStatus[] = [];

    all_pages_result.forEach((results) => {
      if (!isErrorResult(results)) {
        only_success_results = only_success_results.concat(results.data);
      }
    });

    return only_success_results;
  }

  static async create(data: IStatusDriverBody): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/status-driver`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async createSubStatus(
    data: {
      subStatusDrivers: Array<{ name: string }>;
    },
    id: number
  ): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/sub-status-driver/${id}`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async deleteSubStatus(
    data: {
      statusDrivers: Array<{ id: number }>;
    },
    id: number
  ): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/sub-status-driver/${id}`, { data })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async updateSubStatus(name: string, id: number): Promise<ErrorResult> {
    return await api
      .put(`/api/v2/sub-status-driver/${id}`, { name })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(data: IStatusDriverBody): Promise<ErrorResult> {
    return await api
      .put(`/api/v2/status-driver`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(data: {
    statusDrivers: Array<{ id: number }>;
  }): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/status-driver`, { data })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadDriverStatusOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await this.getOnlyStatus({
      filter: { name: search },
      page,
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d) => ({
      label: `${d.name}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async switchAutomaticStatusDetection(): Promise<
    AutomaticStatusDetectionResponse | ErrorResult
  > {
    return await api
      .put("/api/v2/disable-automatic-detection-of-stopped-driving-status")
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async switchRequestPassword(): Promise<RequestPassword | ErrorResult> {
    return await api
      .put("/api/v2/request-driver-password-change-status")
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
