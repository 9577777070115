import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { DriverStatus } from "../interfaces/DriverStatus";
import { ErrorResult } from "../interfaces/ErrorResult";
import { ISecuryRulesGroup } from "../interfaces/SecuryRulesGroup";

export interface GetContractor {
  filter?: Object;
  page?: number;
}

interface Associante {
  vehicle_ids: number[],
  vehicle_tag_ids:number[]
}

export class SecuryRulesGroup {
  static async get({
    filter,
    page,
    order
  }: APiQueryBase): Promise<ApiResponseBase<ISecuryRulesGroup> | ErrorResult>{
    return api
      .get(`/api/v2/security-rules-groups`, {
        params: {
          page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getById(id: string){
    return api
      .get(`/api/v2/security-rules-groups/${id}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async patch(id: number, enabled?: boolean): Promise<ApiResponseBase<ISecuryRulesGroup> | ErrorResult>{
    return api
    .patch(`/api/v2/security-rules-groups/${id}/activate`, {
      enabled: enabled
    })
    .then((r) => r.data)
    .catch((e) => e?.response?.data);
  }

  static async put(data: any): Promise<ApiResponseBase<ISecuryRulesGroup> | ErrorResult>{
    return api
    .put(`/api/v2/security-rules-groups/${data?.id}`, {
      name: data?.name
    })
    .then((r) => r.data)
    .catch((e) => e?.response?.data);
  }

  static async post(data: any){
    return api
    .post(`/api/v2/security-rules-groups`, {
      name: data?.name
    })
    .then((r) => r.data)
    .catch((e) => e?.response?.data);
  }

  static async associate(id: number, data: Associante){
    return api
    .patch(`/api/v2/security-rules/${id}/save-tags-plates`, data)
    .then((r) => r.data)
    .catch((e) => e?.response?.data);
  }

  static async getDriveStatus(): Promise<ApiResponseBase<DriverStatus> | ErrorResult>{
    return api
    .get(`/api/v2/status-driver-and-substatus`)
    .then((r) => r.data)
    .catch((e) => e?.response?.data);
  }
}