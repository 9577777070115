import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/AddButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomThead from "../../components/CustomThead";
import Pagination from "../../components/Pagination";
import TableActions from "../../components/TableActions";
import { StartPoint } from "../../core/interfaces/StartPoint";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { updateAlert } from "../../core/redux/reducer/alert";
import { StartPointService } from "../../core/services/StartPointService";
import { isErrorResult } from "../../core/utils/api";
import ExitLocationsForm from "./ExitLocationsForm";
import { AbsoluteLeft, AbsoluteRight, Container, SearchInput } from "./styles";
import { getUser } from "../../core/redux/reducer/auth";

interface LoadData {
  filter: string;
  page: number;
  order: any;
}

type FormValues = {
  registers: {
    id: number;
    company_id: number;
    name: string;
    longitude: number;
    latitude: number;
    address: string;
    enabled: number | boolean;
    created_at: string;
    updated_at: string;

    isEdited?: boolean;
  }[];
};

const ConfigsExitLocations: React.FC = () => {
  const [startPoints, setStartPoints] = useState<StartPoint[]>([]);
  const [deleteId, setDeleteId] = useState<number | false>(false);
  const [isOpenForm, setOpenForm] = useState(false);
  const [itemEdit, setItemEdit] = useState<StartPoint>();
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: string;
    order: any;
  }>({ page: 1, pages: 0, filter: "", order: undefined });

  const handleAddStartPoint = () => {
    setOpenForm(true);
  };

  const loadStartPoints = async ({
    filter = "",
    page = 1,
    order,
  }: LoadData) => {
    try {
      const _response = await StartPointService.get({ filter, page, order });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        replace(_response?.data);
        setStartPoints(_response?.data);

        setPagination((prev) => ({
          ...prev,
          page: _response.meta?.current_page || 0,
          pages: _response.meta?.last_page || 0,
        }));
      }
    } catch (error: any) {
      console.log("Erro in load start points", error.message);
    }
  };

  const actions = {
    edit: t("text.edit"),
    delete: t("text.delete"),
  };
  const actionOptions = Object.values(actions);

  function handleSelectAction(action: string, values: Partial<StartPoint>) {
    if (action === actions.edit) {
      setItemEdit(values as any);
      setOpenForm(true);
    } else if (action === actions.delete) {
      setDeleteId(values.id as any);
    }
  }

  async function onSubmitDelete() {
    if (!!deleteId) {
      await StartPointService.delete(deleteId);

      setDeleteId(false);
      loadStartPoints(pagination);
    }
  }

  async function onSubmitUpdate({ registers }: FormValues) {
    try {
      for await (let data of registers) {
        if (!!data.id && data.isEdited) {
          await StartPointService.update(data);
        }
      }

      dispatch(updateAlert({ title: t("message.success.save_data") }));
    } catch (error: any) {
      alert(`${error.message}`);
    }
  }

  const handleEditCancel = async () => {
    replace(startPoints);
  };

  React.useEffect(() => {
    loadStartPoints({
      filter: pagination.filter,
      page: pagination.page,
      order: pagination.order,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.filter, pagination.page, pagination.order, user?.company_id]);

  return (
    <Container fluid>
      <Col xs="12">
        <Row className="mb-4">
          <Col className="d-flex align-items-center" xs="6">
            <h5 className="mb-0 p-0">{t("configs.exit_location.title")}</h5>
          </Col>
          <Col xs="3">
            <AddButton onClick={handleAddStartPoint}>
              {t("configs.exit_location.add")}
            </AddButton>
          </Col>
          <Col xs="3">
            <SearchInput
              className="search"
              placeholder={t("configs.exit_location.search")}
              onChange={(e) => {
                setPagination((prev) => ({
                  ...prev,
                  page: 1,
                  filter: e.target.value,
                }));
              }}
            />
          </Col>
        </Row>
        <Table responsive>
          <CustomThead
            data={[
              { column: "name", name: t("configs.exit_location.table_name") },
              {
                column: "address",
                name: t("configs.exit_location.table_address"),
              },
              {
                column: "enabled",
                name: t("configs.exit_location.table_enabled"),
                menu: true,
              },
              { column: "menu", name: <th />, menu: true },
            ]}
            onChangeOrder={(values) =>
              setPagination((prev) => ({
                ...prev,
                page: 1,
                order: values,
              }))
            }
          />

          <tbody>
            {fields?.map((item, index) => (
              <tr key={item.id}>
                <td>
                  <div>{item?.name}</div>
                </td>
                <td>
                  <div>{item?.address}</div>
                </td>
                <td>
                  <Form.Check
                    type="switch"
                    id={String(index)}
                    defaultChecked={Boolean(item?.enabled)}
                    {...register(`registers.${index}.enabled`, {
                      onChange: () => {
                        setValue(`registers.${index}.isEdited`, true);
                      },
                    })}
                  />
                </td>
                <td>
                  <TableActions
                    items={actionOptions}
                    onSelectAction={(action) =>
                      handleSelectAction(action, item)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>

      <br />
      <br />

      <AbsoluteLeft>
        <Stack className="mb-3" direction="horizontal" gap={2}>
          <Button
            onClick={handleSubmit(onSubmitUpdate, console.log)}
            disabled={!watch("registers")?.find(({ isEdited }) => !!isEdited)}
          >
            {isSubmitting ? t("status.saving") : t("button.save")}
            {isSubmitting && <Spinner animation="border" size="sm" />}
          </Button>
          <Button
            variant="secondary"
            onClick={handleEditCancel}
            disabled={!watch("registers")?.find(({ isEdited }) => !!isEdited)}
          >
            {t("button.cancel")}
          </Button>
        </Stack>
      </AbsoluteLeft>

      <AbsoluteRight>
        <Pagination
          current_page={pagination.page}
          qtd={pagination.pages}
          onSelectPage={(page) => {
            loadStartPoints({ ...pagination, page });
          }}
        />
      </AbsoluteRight>

      <ConfirmationModal
        visible={!!deleteId}
        title={t("configs.exit_location.confirm_modal_title")}
        onClose={() => setDeleteId(false)}
        onConfirm={onSubmitDelete}
      />

      <ExitLocationsForm
        visible={isOpenForm}
        onClose={() => {
          setOpenForm(false);
          setItemEdit(undefined);
        }}
        onSuccess={function(){
          loadStartPoints(pagination);
        }}
        itemEdit={itemEdit}
      />
    </Container>
  );
};

export default ConfigsExitLocations;
