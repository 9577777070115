import React from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormRegister,
  useFormState,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddButton from "../AddButton";
import { ActionButton, Line, NestedFieldArrayContainer } from "./styles";
import { BsTrash } from "react-icons/bs";
import { SurveyFormData } from "../../core/interfaces/SurveyForm";

interface QuestionNestedFieldArrayProps {
  nestIndex: number;
  control: Control<SurveyFormData>;
  register: UseFormRegister<SurveyFormData>;
}

const QuestionNestedFieldArray: React.FC<QuestionNestedFieldArrayProps> = ({
  nestIndex,
  control,
  register,
}) => {
  const { t } = useTranslation();
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions.${nestIndex}.options`,
    keyName: "fieldId",
  });

  const { errors } = useFormState({
    control,
  });

  const { questions } = useWatch({ control });

  const handleAddOption = () => {
    append({ name: "" });
  };

  return (
    <NestedFieldArrayContainer>
      {fields?.map((item, index) => {
        return (
          <Row key={item.fieldId}>
            <Col sm={{ span: 8, offset: 0 }}>
              <Form.Group controlId={`questions.${nestIndex}.name`}>
                <Stack direction="horizontal" gap={3}>
                  <p className="text-black">{index + 1}.</p>

                  <Col>
                    <Form.Control
                      plaintext
                      placeholder={t("configs.app.question_without_title")}
                      isInvalid={
                        !!errors?.questions?.[nestIndex]?.options?.[index]?.name
                          ?.message
                      }
                      {...register(
                        `questions.${nestIndex}.options.${index}.name`
                      )}
                    />

                    <Form.Control.Feedback type="invalid">
                      {
                        errors?.questions?.[nestIndex]?.options?.[index]?.name
                          ?.message
                      }
                    </Form.Control.Feedback>
                    <Line />
                  </Col>
                </Stack>
              </Form.Group>
            </Col>

            <Col
              sm={{ span: 3, offset: 0 }}
              className="mt-2"
              hidden={index !== 0}
            >
              <Controller
                control={control}
                name={`questions.${nestIndex}.required`}
                render={({ field: { name, onChange, onBlur, value } }) => (
                  <Form.Group controlId={name}>
                    <Form.Switch
                      label={t("configs.app.response_required")}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value}
                    />
                  </Form.Group>
                )}
              />
            </Col>

            <Col
              sm={{ span: 1, offset: 0 }}
              hidden={!!questions?.[nestIndex]?.options?.[index]?.id}
            >
              <ActionButton type="button" onClick={() => remove(index)}>
                <BsTrash size="1.3rem" />
              </ActionButton>
            </Col>
          </Row>
        );
      })}

      <div className="mt-2">
        <AddButton
          children={t("configs.app.button_add_option")}
          onClick={() => handleAddOption()}
        />
      </div>
    </NestedFieldArrayContainer>
  );
};

export default QuestionNestedFieldArray;
