import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Nav,
  Row,
  Spinner,
  Stack,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Container, CustomLink } from "./styles";
import { isErrorResult } from "../../../core/utils/api";
import Pagination from "../../../components/Pagination";
import TableActions from "../../../components/TableActions";
import CustomSearchInput from "../../../components/CustomSearchInput";
import Footer from "../../../components/Footer";
import EmptyListMessage from "../../../components/EmptyListMessage";
import { useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { FaFilter } from "react-icons/fa";
import { ActionButtonOutline } from "../../../components/ActionButtonOutline";
import { APiQueryBase } from "../../../core/interfaces/ApiResponseBase";
import AddButton from "../../../components/AddButton";
import { useNavigate } from "react-router-dom";
import CustomThead from "../../../components/CustomThead";
import { SecuryRulesGroup } from "../../../core/services/SecuryRulesGroup";
import moment from "moment";
import { IVehicleTag } from "../../../core/interfaces/VehicleTag";
import VehicleFilterModal from "../../../components/VehicleFilterModal";
import { getUser } from "../../../core/redux/reducer/auth";
import { useFormatDate } from "../../../core/hooks/useFormatDate";
import { ProtectedComponent } from "../../../components/ProtectedComponent";
import { module_names } from "../../../constants/module_names";

type Register = {
  id?: number;
  name: string;
  enabled?: boolean;
  user_id: number;
  user_name: string;
  amount_events: number;
  created_at: string;
  updated_at: string;
};

type FormValues = {
  registers: Register[];
};

export type VehicleFilterEvent = {
  vehicle_type: number;
  tags: IVehicleTag[];
};

const EmbeddedIntelligence: React.FC = () => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [selectedInitialDate, setSelectedInitialDate] = useState<string>("");
  const [selectedFinalDate, setSelectedFinalDate] = useState<string>("");
  const [defaultValues, setDefaultValues] = useState<FormValues>();
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order?: { [key: string]: string };
  }>({ page: 1, pages: 0, filter: "" });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { validateDateFormatAndTime } = useFormatDate();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const actions = {
    edit: t("text.edit"),
    // vehicle_association: t("configs.fleetmate.ei.action_vehicle_association"),
  };
  const actionOptions = Object.values(actions);

  async function onSubmitUpdate({ registers }: FormValues) {
    try {
      for await (let data of registers) {
        if (!!data.id && data) {
          //@ts-ignore
          if (data.enabled === true || data.enabled === false) {
            await SecuryRulesGroup.patch(data.id, data.enabled);
          }
        }
      }

      reset({ registers });
      loadEmbeddedIntelligences(pagination);
      dispatch(
        updateAlert({
          title: t("message.success.save_data"),
        })
      );
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleEditCancel = async () => {
    replace(fields);
  };

  function getEnableSaveButton(): boolean {
    return !watch("registers")?.find(({ enabled }) => enabled);
  }

  async function loadEmbeddedIntelligences({
    filter = "",
    page = 1,
    order,
  }: APiQueryBase) {
    try {
      const _response = await SecuryRulesGroup.get({ filter, page, order });
      const isError = isErrorResult(_response);
      if (!isError && _response.data) {
        //@ts-ignore
        replace(_response.data);
        //@ts-ignore
        setDefaultValues(_response.data);
        setPagination((prev) => ({
          ...prev,
          //@ts-ignore
          page: _response?.current_page || 1,
          //@ts-ignore
          pages: _response?.last_page || 0,
        }));
      }
    } catch (error: any) {
      console.log("Erro in load fleetmate.ei", error.message);
    }
  }

  function onChangeFilter(options: VehicleFilterEvent) {
    const { tags = [], vehicle_type } = options;
    const filter: any = {
      ...pagination.filter,
      vehicle_types_id: !!vehicle_type ? [vehicle_type] : [],
      vehicle_tags_id: tags.map((element) => element.id),
    };

    if (!vehicle_type) delete filter.vehicle_types_id;
    if (!tags?.length) delete filter.vehicle_tags_id;
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onSearch(text: string): void {
    const filter = { ...pagination.filter, search: text };
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onDateFrom(text: any): void {
    const filter = { ...pagination.filter, from: text };
    if (!text) delete filter.from;
    setSelectedInitialDate(text);
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onDateTo(text: string): void {
    const filter = { ...pagination.filter, to: text };
    if (!text) delete filter.to;
    setSelectedFinalDate(text);
    setPagination((prev) => ({ ...prev, filter }));
  }

  useEffect(() => {
    loadEmbeddedIntelligences(pagination);
  }, [pagination.filter, pagination.page, pagination.order, user?.company_id]);

  console.log(watch("registers"), defaultValues);

  return (
    <Container className="mb-3">
      <Row className="mb-4">
        <Col className="d-flex align-items-end" lg={3}>
          <AddButton onClick={() => navigate("new-group")}>
            {t("configs.fleetmate.ei.add_button")}
          </AddButton>
        </Col>

        <Col lg={9}>
          <Stack className="filter-container" gap={3}>
            <Form.Group>
              <CustomSearchInput
                placeholder={t("search")}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                {t("configs.fleetmate.ei.label_from_data")}
              </Form.Label>
              <Form.Control
                min={new Date("2021-01-01T00:00")?.toDateString()}
                max={new Date()?.toDateString()}
                value={selectedInitialDate}
                type="datetime-local"
                onChange={(e) => onDateFrom(e?.target?.value)}
                className="form-control p-2"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>{t("configs.fleetmate.ei.label_to_data")}</Form.Label>
              <Form.Control
                min={new Date("2021-01-01T00:00")?.toDateString()}
                max={new Date()?.toDateString()}
                value={selectedFinalDate}
                type="datetime-local"
                onChange={(e) => onDateTo(e?.target?.value)}
                className="form-control p-2"
              />
            </Form.Group>

            <ActionButtonOutline onClick={() => setOpenFilter(true)}>
              <FaFilter size={18} />
            </ActionButtonOutline>
          </Stack>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <Table responsive>
            <CustomThead
              data={[
                { column: "name", name: t("configs.fleetmate.ei.rule_name") },
                {
                  column: "created_at",
                  name: t("configs.fleetmate.ei.created_at"),
                },
                {
                  column: "updated_at",
                  name: t("configs.fleetmate.ei.updated_at"),
                },
                { column: "user_name", name: t("configs.fleetmate.ei.user") },
                {
                  column: "amount_events",
                  name: t("configs.fleetmate.ei.qtd_events"),
                },
                {
                  column: "enabled",
                  name: t("configs.fleetmate.ei.eligibility"),
                },
                { column: "menu", name: <th />, menu: true },
              ]}
              onChangeOrder={(order) => {
                setPagination((prev) => ({ ...prev, order }));
              }}
            />

            <tbody>
              {fields?.map((item, index) => (
                <tr key={`${index}_${item.fieldId}`}>
                  <td>
                    <CustomLink to={`${item.id}`}>{item.name}</CustomLink>
                  </td>
                  <td>{validateDateFormatAndTime(item.created_at, true)}</td>
                  <td>{validateDateFormatAndTime(item.updated_at, true)}</td>
                  <td>{item.user_name}</td>
                  <td>{item.amount_events}</td>
                  <td>
                    <ProtectedComponent
                      module_name={module_names.CONFIG_FLEETMATE}
                      property="edit"
                      fallbackComponent={
                        <Form.Check
                          id={`${index}_enabled`}
                          type="switch"
                          defaultChecked={false}
                          disabled
                          {...register(`registers.${index}.enabled`)}
                        />
                      }
                    >
                      <Form.Check
                        id={`${index}_enabled`}
                        type="switch"
                        defaultChecked={false}
                        {...register(`registers.${index}.enabled`)}
                      />
                    </ProtectedComponent>
                  </td>
                  <td>
                    <TableActions
                      items={actionOptions}
                      module_name={module_names.CONFIG_FLEETMATE}
                      onSelectAction={() => {
                        navigate(`${item.id}`);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          {!fields.length && (
            <EmptyListMessage style={{ marginTop: "2%" }}>
              {t("configs.fleetmate.ei.empty_list")}
            </EmptyListMessage>
          )}
        </Col>
      </Row>

      <Row className="mt-auto">
        <Footer>
          <div>
            <Stack className="mb-3" direction="horizontal" gap={3}>
              <Button
                onClick={handleSubmit(onSubmitUpdate)}
                disabled={getEnableSaveButton()}
              >
                {isSubmitting ? t("status.saving") : t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={handleEditCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </div>

          <div className="mx-3">
            <Pagination
              current_page={pagination.page}
              qtd={pagination.pages}
              onSelectPage={(page) => {
                loadEmbeddedIntelligences({ ...pagination, page });
              }}
            />
          </div>
        </Footer>
      </Row>

      <VehicleFilterModal
        visible={openFilter}
        onClose={() => setOpenFilter(false)}
        onChange={onChangeFilter}
      />
    </Container>
  );
};

export default EmbeddedIntelligence;
