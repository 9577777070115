import BSContainer from "react-bootstrap/Container";
import styled from "styled-components";

export const Container = styled(BSContainer)`
  background-color: #263238;
  height: 100%;

  & > .row {
    height: 100%;
  }

  & .logo-container {
    margin-top: 56px;
    margin-bottom: -116px;

    svg {
      width: 297px;
      height: 67px;
    }
  }

  .right {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .right-container {
    display: flex;
    background-color: #fff;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 700px;
  }

  #form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 393px;
  }

  #form-container .wellcome-message {
    margin-bottom: 24px;
  }

  #form-container .wellcome-message .title {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }

  #form-container .wellcome-message .sub-title {
    font-size: 0.8rem;
    text-align: center;
    color: #6c757d;
  }

  #form-container form,
  #form-container .form-control {
    font-size: 0.9rem;
  }

  #form-container button {
    width: 100%;
  }

  #form-container .forgot-password {
    color: #333;
  }
`;
