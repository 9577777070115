import styled from "styled-components";
import {
  Accordion as AccordionBootstrap,
  Card as CardBootstrap,
  Container as ContainerBootstrap,
  Modal,
} from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`

`;

export const Accordion = styled(AccordionBootstrap)`
  -webkit-box-shadow: 0px 0px 9px 1px rgba(255,255,255,0.8);
  -moz-box-shadow: 0px 0px 9px 1px rgba(255,255,255,0.8);
  box-shadow: 0px 0px 9px 1px rgba(255,255,255,0.8);
`;

export const AccordionButton = styled.button`
  background-color: transparent;
  border: none;
  color: #6C757D;
  font-weight: 700;

  svg:nth-child(1) {
    margin-right: 11px;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  color: #6C757D;
`;

export const CardHeader = styled(CardBootstrap.Header)`
  width: 100%;
  background-color: #FFF;
  padding: 18px;
`;

export const AccordionList = styled.div`
  border-radius: 5px;
  background: var(--global-09-white, #FFF);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
`;

export const Header = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: #6C757D;
  border-bottom: 1px solid #F2F2F2;

  p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  
  button {
    border: none;
    bottom: white;
  }
`;

export const Content = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  height: ${(props) => (props.isOpen ? 'auto' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: height 0.3s ease, opacity 0.3s ease;
  padding: ${(props) => (props.isOpen ? '0 40px' : '0')};
`;

export const AccordionContainer = styled.div`
  margin-bottom: 10px;
`;

export const ModalBody = styled(Modal.Body)`
  padding: 40px 85px;
  width: 600px;

  > p:nth-child(1){ 
    color: #6C757D;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 108.333% */
  }

  > p:nth-child(2){ 
    color: #6C757D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 49px;
  }
`;