import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import CustomInputTag from "../../../components/CustomInputTag";
import WeekDayPicker from "../../../components/WeekDayPicker";
import { SENSORS } from "../../../constants/sensors";
import { IVehicleTag } from "../../../core/interfaces/VehicleTag";
import { useAppDispatch } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { SensorAlertResolver } from "../../../core/schemaValidations/sensor_alert";
import { AlertsService } from "../../../core/services/AlertsService";
import { VehicleTagService } from "../../../core/services/VehicleTagService";
import { isErrorResult } from "../../../core/utils/api";
import PrivateMasterPage from "../../../layout/PrivateMasterPage";
import ConditionFieldArray from "./conditionFieldArray";
import { Container } from "./styles";
import CustomInputTagAlt from "../../../components/CustomInputTagAlt";

export type IAlertRuleBody = {
  name: string;
  alert_type_id: { label: string; value: number } | number;
  alert_on_receipt: boolean;
  notify_by_email: boolean;
  notify_on_monitoring: boolean;
  notify_by_sms: boolean;
  only_alert_after_the_period_of: string;
  conditions: { rule: string; value: string; operator: string }[];
  weekdays: string[];
  starts_at: string;
  starts_until: string;
  ends_at: string;
  ends_until: string;
  week: string[];
  emails: string | { alerts_triggers_id: number; email: string }[];
  tags: string[];
  alert_more_conditions: boolean;
  data?: {
    images: string[];
    videos: string[];
  };
  conditionals?: {
    alerts_triggers_id: number;
    logical_operator: string;
    symbol: string;
    value: number;
  }[];
};

export default function AlertRuleForm() {
  const params = useParams();
  const { id } = params || {};

  const [vehicleTags, setVehicleTags] = useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<IAlertRuleBody>({
    resolver: SensorAlertResolver,
    defaultValues: {
      conditions: [{ rule: "more_then", value: "", operator: "and" }],
    },
  });

  const prepareConditions = (value: string) => {
    switch (value) {
      case "more_then":
        return ">";
      case "less_then":
        return "<";
      case ">":
        return "more_then";
      case "<":
        return "less_then";
      case "and":
        return "E";
      case "or":
        return "OU";
      case "E":
        return "and";
      case "OU":
        return "or";
    }
  };

  function getKeyByValue(value: any) {
    const foundObject = vehicleTags.find((obj) => obj.key === value);
    return foundObject ? foundObject.id : null;
  }

  const getConditions = (conditions: any) => {
    const arrayConditions = conditions.map((obj: any) => {
      return {
        rule: prepareConditions(obj.symbol),
        value: obj.value.toString(),
        operator: prepareConditions(obj.logical_operator),
      };
    });

    setValue("conditions", arrayConditions);
  };

  const prepareTags = (tags: any) => {
    const arrayTags = tags?.map((tag: any) => {
      const foundObject = vehicleTags.find((obj) => obj.id === Number(tag.tag));

      return foundObject;
    });
    if (arrayTags) {
      if (arrayTags[0]) {
        const array = arrayTags?.map((item: any) => {
          return { ...item, label: item?.key };
        });
        setValue("tags", array);
      }
    }
  };

  async function onSubmit(values: IAlertRuleBody) {
    const objToSend: any = {
      ...values,
      conditional: values?.conditions.map((condition) =>
        prepareConditions(condition.rule)
      ),
      condition_type: values?.conditions.map((condition) =>
        prepareConditions(condition.operator)
      ),
      condition_value: values?.conditions.map((condition) => condition.value),
      alert_type_id:
        typeof values?.alert_type_id === "object" &&
        values?.alert_type_id?.value,

      //@ts-ignore
      tags: values?.tags?.map((tag) => tag.id),
    };

    if (!objToSend.alert_more_conditions)
      delete objToSend.only_alert_after_the_period_of;

    delete objToSend.conditions;
    delete objToSend.alert_more_conditions;

    try {
      if (id) {
        const response = await AlertsService.update(Number(id), objToSend);
        if (!isErrorResult(response) && response) {
          navigate(-1);
          dispatch(updateAlert({ title: t("message.success.save_data") }));
        }
      } else {
        const response = await AlertsService.create(objToSend);
        if (!isErrorResult(response) && response) {
          navigate(-1);
          dispatch(updateAlert({ title: t("message.success.save_data") }));
        }
      }
    } catch (error) {
      console.log("post error", error);
    }
  }

  const handleCancel = () => {
    reset();
    navigate(-1);
  };

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  async function loadAlert() {
    const response = await AlertsService.getAlert(Number(id));
    const isError = isErrorResult(response);

    if (!isError && response.data) {
      getConditions(response.data.conditionals);
      prepareTags(response.data.tags);
      setValue("name", response.data.name);
      setValue("alert_on_receipt", response.data.alert_on_receipt);
      setValue("notify_by_email", response.data.notify_by_email);
      setValue("notify_by_sms", response.data.notify_by_sms);
      setValue("notify_on_monitoring", response.data.notify_on_monitoring);
      setValue(
        "week",
        response.data.week.map((value) => value.toString())
      );
      setValue(
        "only_alert_after_the_period_of",
        response.data.only_alert_after_the_period_of
      );
      setValue(
        "alert_more_conditions",
        response.data.only_alert_after_the_period_of ? true : false
      );
      setValue("starts_at", response.data.starts_at);
      setValue("starts_until", response.data.starts_until);
      setValue("ends_at", response.data.ends_at);
      setValue("ends_until", response.data.ends_until);
      setValue("alert_type_id", {
        label: t(
          SENSORS.find((obj) => obj.value === response.data.alert_type_id)
            ?.label || ""
        ),
        value:
          SENSORS.find((obj) => obj.value === response.data.alert_type_id)
            ?.value || 0,
      });

      if (Array.isArray(response.data.emails)) {
        const mappedEmails = response.data.emails.map((email) => {
          return email.email;
        });

        setValue("emails", mappedEmails.join(","));
      }
    }
  }

  useEffect(() => {
    loadVehicleTags();
  }, []);

  useEffect(() => {
    if (id) {
      loadAlert();
    }
  }, [vehicleTags]);

  return (
    <PrivateMasterPage
      title={t("sensors.form_title")}
      breadcrumb={[t("sensors.form_title")]}
    >
      <Container className="p-3 overflow-auto">
        <Card className="p-1">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(onSubmit, console.log)}>
              <Row className="mb-4 gap-lg-0 gap-md-0 gap-sm-3">
                <Form.Group md={5} lg={3} as={Col} controlId="name">
                  <Form.Label>{t("sensors.form_alert_name")}</Form.Label>
                  <Form.Control
                    placeholder={t("sensors.form_alert_name_enter")}
                    {...register("name")}
                    isInvalid={!!errors?.name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group md={5} lg={3} as={Col} controlId="alert_type_id">
                  <Form.Label>{t("sensors.form_type")}</Form.Label>
                  <Controller
                    control={control}
                    name="alert_type_id"
                    render={({ field: { value, onChange, onBlur } }) => (
                      <AsyncPaginate
                        isClearable
                        placeholder={t("input.select_text")}
                        loadOptions={(inputValue: string): Promise<any> => {
                          const translatedOptions = SENSORS.map((sensor) => ({
                            ...sensor,
                            label: t(sensor.label),
                          }));

                          const filteredOptions = translatedOptions.filter(
                            (option) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                          );

                          return Promise.resolve({
                            options: filteredOptions,
                            hasMore: false,
                          });
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={errors?.alert_type_id?.message && "d-flex"}
                  >
                    {errors?.alert_type_id?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <ConditionFieldArray control={control} register={register} />

              <Row className="gap-5">
                <Col>
                  {/* <Row className="mb-3">
                    <Form.Group as={Row} controlId="alert_on_receipt">
                      <Form.Label
                        className="py-0"
                        style={{ maxWidth: "fit-content" }}
                        column
                      >
                        {t("sensors.form_change_on")}
                      </Form.Label>
                      <Col>
                        <Form.Switch
                          label={t("sensors.form_receive_event")}
                          defaultChecked={false}
                          {...register("alert_on_receipt")}
                        />
                      </Col>
                    </Form.Group>
                  </Row> */}

                  <Row>
                    <Form.Group as={Col} className="mb-3" controlId="week">
                      <Form.Label>
                        {t("sensors.form_weekdays_label")}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="week"
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <WeekDayPicker onChange={onChange} value={value} />
                        )}
                      />
                    </Form.Group>
                  </Row>

                  {/* <Row>
                    <Col>
                      <Form.Label>
                        {t("sensors.form_issue_alert_when_label")}
                      </Form.Label>
                    </Col>
                  </Row> */}

                  <Row className="mb-4 gap-lg-0 gap-md-0 gap-sm-3">
                    <Form.Group as={Col} controlId="starts_at">
                      <Form.Label>{t("sensors.form_from")}</Form.Label>
                      <Form.Control
                        type="time"
                        {...register("starts_at")}
                        isInvalid={!!errors?.starts_at?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.starts_at?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="starts_until">
                      <Form.Label>{t("sensors.form_to")}</Form.Label>
                      <Form.Control
                        type="time"
                        {...register("starts_until")}
                        isInvalid={!!errors?.starts_until?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.starts_until?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Col
                      className="d-flex align-items-center mt-4"
                      style={{ maxWidth: "fit-content" }}
                    >
                      {t("sensors.form_and")}
                    </Col>

                    <Form.Group as={Col} controlId="ends_at">
                      <Form.Label>{t("sensors.form_from")}</Form.Label>
                      <Form.Control
                        type="time"
                        {...register("ends_at")}
                        isInvalid={!!errors?.ends_at?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.ends_at?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="ends_until">
                      <Form.Label>{t("sensors.form_to")}</Form.Label>
                      <Form.Control
                        type="time"
                        {...register("ends_until")}
                        isInvalid={!!errors?.ends_until?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.ends_until?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-4 gap-lg-0 gap-md-0 gap-sm-3">
                    <Form.Group as={Col} controlId="notify_by_email">
                      <Form.Switch
                        label={t("sensors.form_notify_email")}
                        defaultChecked={false}
                        {...register("notify_by_email")}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={errors?.notify_by_email?.message && "d-flex"}
                      >
                        {errors?.notify_by_email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="notify_on_monitoring">
                      <Form.Switch
                        label={t("sensors.form_notify_traking")}
                        defaultChecked={false}
                        {...register("notify_on_monitoring")}
                      />
                    </Form.Group>

                    {/* <Form.Group as={Col} controlId="notify_by_sms">
                      <Form.Switch
                        label={t("sensors.form_notify_sms")}
                        defaultChecked={false}
                        {...register("notify_by_sms")}
                      />
                    </Form.Group> */}
                  </Row>

                  <Row>
                    <Form.Group as={Col} className="mb-4" controlId="emails">
                      <div className="d-flex justify-content-between">
                        <Form.Label>
                          {t("electronic_fences.label_email_address")}
                        </Form.Label>
                        <Form.Text>
                          {t("electronic_fences.label_email_address_detail")}
                        </Form.Text>
                      </div>

                      <Form.Control
                        placeholder={t("electronic_fences.label_report")}
                        isInvalid={!!errors?.emails?.message}
                        {...register("emails")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.emails?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Col>

                <Col>
                  <Row className="mb-3">
                    <Stack as={Col} direction="horizontal" gap={2}>
                      <Form.Group controlId="alert_more_conditions">
                        <Form.Switch
                          label={t("sensors.form_alert_more_then")}
                          defaultChecked={false}
                          {...register("alert_more_conditions")}
                        />
                      </Form.Group>

                      <Form.Group controlId="only_alert_after_the_period_of">
                        <Form.Control
                          className="py-0"
                          style={{ width: 100, height: 36 }}
                          placeholder={t("sensors.form_alert_more_then_enter")}
                          defaultValue={0}
                          min={0}
                          type="number"
                          {...register("only_alert_after_the_period_of")}
                        />
                      </Form.Group>
                      <Form.Label>
                        {t("sensors.form_condition_seconds")}
                      </Form.Label>
                    </Stack>
                  </Row>

                  {/* <Row className="mt-3">
                    <Form.Group
                      as={Col}
                      controlId="inhibit_alerts_within_references"
                    >
                      <Form.Switch
                        label={t(
                          "sensors.form_inhibit_alerts_within_references"
                        )}
                        defaultChecked={false}
                        {...register("inhibit_alerts_within_references")}
                      />
                    </Form.Group>
                  </Row> */}

                  {/* <Row className="mt-3">
                    <Form.Group
                      as={Col}
                      controlId="inhibit_alerts_within_references_customer"
                    >
                      <Form.Switch
                        label={t(
                          "sensors.form_inhibit_alerts_within_references_customer"
                        )}
                        defaultChecked={false}
                        {...register(
                          "inhibit_alerts_within_references_customer"
                        )}
                      />
                    </Form.Group>
                  </Row> */}

                  {/* <Row className="mt-3">
                    <Form.Group
                      as={Col}
                      controlId="inhibit_alerts_vehicles_not_associate"
                    >
                      <Form.Switch
                        label={t(
                          "sensors.form_inhibit_alerts_vehicles_not_associate"
                        )}
                        defaultChecked={false}
                        {...register("inhibit_alerts_vehicles_not_associate")}
                      />
                    </Form.Group>
                  </Row> */}
                </Col>
              </Row>

              <Row>
                <Form.Group controlId="tags" as={Col}>
                  <Form.Label>{t("electronic_fences.label_tags")}</Form.Label>
                  <Controller
                    name="tags"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomInputTagAlt
                          selected={value || []}
                          onChange={(values: string[]) => onChange(values)}
                          options={vehicleTags?.map((op) => {
                            return { ...op, label: op.key };
                          })}
                          isLoading={isLoadingTags}
                        />
                      );
                    }}
                  />
                </Form.Group>
              </Row>

              <Row className="mt-4">
                <Stack as={Col} direction="horizontal" gap={3}>
                  <Button type="submit">
                    {isSubmitting ? t("status.saving") : t("button.save")}
                    {isSubmitting && <Spinner animation="border" size="sm" />}
                  </Button>
                  <Button variant="secondary" onClick={handleCancel}>
                    {t("button.cancel")}
                  </Button>
                </Stack>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </PrivateMasterPage>
  );
}
