import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  .filters {
    & > div {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  th {
    text-transform: uppercase;
  }

  td:last-child {
    width: 100px;
  }

  .table > :not(caption) > * > *:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .table > :not(caption) > * > *:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;
