import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import CustomInputTag from "../CustomInputTag";
import DropdownSearchAndCheck from "../DropdownSearchAndCheck";
import { VehicleService } from "../../core/services/VehicleService";
import { APiQueryBase } from "../../core/interfaces/ApiResponseBase";
import { SecuryRulesGroup } from "../../core/services/SecuryRulesGroup";
import { Controller, useForm } from "react-hook-form";
import { updateAlert } from "../../core/redux/reducer/alert";
import { useAppDispatch } from "../../core/redux/hooks";

export type VehicleFilterEvent = {
  vehicle_type: number;
  tags: IVehicleTag[];
};

export interface IVehicleTag {
  id: number;
  key: string;
  checked?: boolean;
}

interface FleetMateVehicleAssociationModalProps {
  visible: boolean;
  onClose?(): void;
  id?: number;
  vehiclesRule?: string[];
  tagsRule?: string[];
  refetch?: any;
}

const FleetMateVehicleAssociationModal: React.FC<
  FleetMateVehicleAssociationModalProps
> = ({ onClose, visible, id, tagsRule, vehiclesRule, refetch, ...props }) => {
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [tags, setTags] = React.useState<string[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);
  const [vehicles, setVehicles] = React.useState<any[]>([]);
  const [vehiclesId, setVehiclesId] = React.useState<any[]>([]);
  const [filterVehicles, setFilterVehicles] = React.useState<string>("");
  const dispatch = useAppDispatch();

  const { control, setValue, handleSubmit } = useForm();

  const { t } = useTranslation();

  const handleCancel = () => {
    tagsRule = [];
    vehiclesRule = [];
    onClose?.();
    setTags([]);
  };

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        let _tags: IVehicleTag[] = [];
        let _tagsList: string[] = [];
        _response.data.map((item) => {
          const isfound = tagsRule?.find(
            (portItem) => Number(portItem) === item.id
          );
          if (isfound) {
            _tags.push({
              id: item.id,
              key: item.key,
              checked: true,
            });
            _tagsList.push(item.key);
          } else {
            _tags.push({
              id: item.id,
              key: item.key,
              checked: false,
            });
          }
        });

        setVehicleTags(_tags);
        setTags(_tagsList);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  async function loadVehicles({ filter = "", page = 1 }: APiQueryBase) {
    let _vehicles: any[] = [];
    let _vehiclesDefalut: any[] = [];
    try {
      const _response = await VehicleService.get({
        filter,
        page,
        per_page: 100000,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        _response.data?.map((item) => {
          const isfound = vehiclesRule?.find(
            (portItem) => Number(portItem) === item.id
          );

          if (isfound) {
            _vehicles.push({
              id: item.id,
              label: item.license_plate,
              checked: true,
            });
            _vehiclesDefalut.push({
              id: item.id,
              label: item.license_plate,
              checked: true,
            });
          } else {
            _vehicles.push({
              id: item.id,
              label: item.license_plate,
              checked: false,
            });
          }
        });
        setVehicles(_vehicles);
        setVehiclesId(_vehiclesDefalut);
        setValue("vehicle_ids", _vehicles);
      }
    } catch (error: any) {
      console.log("Erro in load vehicle", error.message);
    }
  }

  function onSubmit() {
    const _tags: number[] = [];
    const _vehicles: number[] = [];

    vehiclesId
      .filter((vehicle) => vehicle.checked)
      .forEach((item) => {
        _vehicles.push(item.id);
      });

    vehicleTags
      .filter((tag) => tags.includes(tag.key))
      .forEach((item) => {
        _tags.push(item.id);
      });

    if (id) {
      SecuryRulesGroup.associate(id, {
        vehicle_ids: _vehicles,
        vehicle_tag_ids: _tags,
      }).then(() => {
        onClose?.();
        dispatch(
          updateAlert({
            title: t("message.success.save_data"),
            onClick() {
              refetch?.();
            },
          })
        );
      });
    }
  }

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
    }
  }, [visible]);

  React.useEffect(() => {
    if (visible) {
      loadVehicles({ filter: filterVehicles, page: 1 });
    }
  }, [visible, filterVehicles]);

  // console.log(vehiclesId)

  return (
    <Modal {...props} show={visible} size="md" centered onHide={handleCancel}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>
          {t("configs.fleetmate.ei.vehicle_association")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mt-5 mb-3">
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label>{t("configs.fleetmate.ei.vehicle")}</Form.Label>
              <Controller
                control={control}
                name="vehicle_ids"
                render={({ field: { onChange, value } }) => (
                  <DropdownSearchAndCheck
                    label={t("configs.fleetmate.ei.vehicle_select")}
                    search
                    onChangeSearch={setFilterVehicles}
                    onChange={(e) => {
                      onChange(e);
                      setVehiclesId(e);
                    }}
                    values={vehicles}
                    items={vehicles}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="name" as={Col}>
              <Form.Label>
                {t("configs.fleetmate.ei.attributes_tag")}
              </Form.Label>
              <Controller
                control={control}
                name="vehicle_tag_ids"
                render={({ field: { onChange, value } }) => (
                  <CustomInputTag
                    selected={tags}
                    onChange={(values: string[]) => {
                      console.log(values);
                      onChange(values);
                      const _tags: any = [];
                      vehicleTags
                        .filter((tag) => values.includes(tag.key))
                        .forEach((item) => {
                          _tags.push(item.key);
                        });
                      setTags(_tags);
                    }}
                    options={vehicleTags?.map((op) => op.key)}
                    isLoading={isLoadingTags}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button onClick={onSubmit}>{t("button.save")}</Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default FleetMateVehicleAssociationModal;
