import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
`;

export const Link = styled.a`
  font-size: 16px;
  color: #B72026;

  &:hover {
    cursor: pointer;
  }
`;
