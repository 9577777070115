import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  max-width: 40%;
  color: #6c757d;
  text-align: center;
`;
