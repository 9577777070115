import React, { useEffect, useState } from "react";
import { Button, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";

interface ConfirmationModalProps {
  onClose?(): void;
  onConfirm?(): void;
  title?: string;
  message?: string;
  visible?: boolean;
  isLoading?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible = false,
  isLoading,
  title,
  message,
  onClose,
  onConfirm,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <Modal
      {...props}
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      {/* <Modal.Header className="border-0" closeButton></Modal.Header> */}
      <Modal.Body className="pb-4">
        {!!title && <p className="title">{title}</p>}
        {!!message && <p className="message">{message}</p>}

        <Stack
          className="d-flex justify-content-center"
          direction="horizontal"
          gap={3}
        >
          <Button onClick={onConfirm}>
            {isLoading ? t("status.waiting") : t("text.yes")}
            {isLoading && <Spinner animation="border" size="sm" />}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            {t("text.no")}
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
