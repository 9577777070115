import styled from "styled-components";

export const THead = styled.thead`
  .form-check {
    min-height: 0;
    margin-bottom: 0;
  }
`;

export const GroupItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
