import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FiAlertOctagon } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { getAlert, hideAlert } from "../../core/redux/reducer/alert";
import { Modal } from "./styles";

interface ErrorModalProps {
  onClose?(): void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ onClose, ...props }) => {
  const dispatch = useAppDispatch();
  const { title, message, type } = useAppSelector(getAlert);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  function handleOnClick() {
    dispatch(hideAlert());
  }

  useEffect(() => {
    setIsVisible(type === "error" && (!!title || !!message));
  }, [title, message, type]);

  return (
    <Modal
      {...props}
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      {/* <Modal.Header className="border-0" closeButton></Modal.Header> */}
      <Modal.Body>
        <FiAlertOctagon size={41} color="#EB5757" />

        <p>
          {!!title && <div>{title}</div>}
          {!!message && <div>{message}</div>}
        </p>
        <Button onClick={handleOnClick}>Ok</Button>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
