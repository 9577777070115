import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Container } from "./styles";
import { isErrorResult } from "../../core/utils/api";

import { AlertsService } from "../../core/services/AlertsService";
import { ActionButtonOutline } from "../../components/ActionButtonOutline";
import { FaFilter } from "react-icons/fa";
import CustomThead from "../../components/CustomThead";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import ActionChips from "../../components/ActionChips";
import TableActions from "../../components/TableActions";
import CustomSearchInput from "../../components/CustomSearchInput";
import AddCommentModal from "../../components/AddCommentModal";
import CommentsModal from "../../components/CommentsModal";
import AlertFilterModal, {
  AlertFilterEvent,
} from "../../components/AlertFilterModal";
import { useFieldArray, useForm } from "react-hook-form";

import TableFooter from "../../components/TableFooter";
import { APiQueryBase } from "../../core/interfaces/ApiResponseBase";
import MediaModal from "../../components/MediaModal";
import MapModal from "../../components/MapModal";
import { IAlertRuleBody } from "../Sensors/AlertRuleForm";
import { useFormatDate } from "../../core/hooks/useFormatDate";
import { useAppSelector } from "../../core/redux/hooks";
import { getUser } from "../../core/redux/reducer/auth";
import { DateTimerPicker } from "../../components/DateTimerPicker";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";
import { Navigate } from "react-router-dom";
import { format, parse, subDays } from "date-fns";

interface IValues {
  initial_date_value: string;
  end_date_value: string;
}

const Alerts: React.FC = () => {
  const [showModal, setShowModal] = useState<string | false>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [centerMap, setCenterMap] = useState({ lat: 1, lng: 1 });
  const [showMap, setShowMap] = useState(false);
  const [alertSelected, setAlertSelected] = useState(null);
  const [media, setMedia] = useState({
    images: [""],
    videos: [""],
  });
  function getCurrentDate() {
    const now = new Date();
    return format(now, "dd/MM/yyyy HH:mm");
  }

  function getDate7DaysAgo() {
    const now = new Date();
    const sevenDaysAgo = subDays(now, 7);
    return format(sevenDaysAgo, "dd/MM/yyyy HH:mm");
  }
  const initialFinalDate = getCurrentDate();
  const initialInitialDate = getDate7DaysAgo();
  function convertDateToISOString(dateString: string) {
    const inputFormat = "dd/MM/yyyy HH:mm";

    const parsedDate = parse(dateString, inputFormat, new Date());

    const outputFormat = "yyyy-MM-dd'T'HH:mm";

    return format(parsedDate, outputFormat);
  }
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order?: { [key: string]: string };
  }>({
    page: 1,
    pages: 0,
    filter: {
      from: convertDateToISOString(initialInitialDate),
      to: convertDateToISOString(initialFinalDate),
    },
    order: { date: "desc" },
  });

  type Register = any & {
    isEdited?: boolean;
    isSelected?: boolean;
  };

  type FormValues = {
    registers: Register[];
    period: { from: string; to: string };
  };

  const {
    register,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { validateDateFormatAndTime } = useFormatDate();

  const actions = {
    add_comment: t("alerts.option_add_comment"),
    view: t("alerts.option_view_comments"),
    view_media: t("alerts.option_view_media"),
  };
  const actionOptions = Object.values(actions);

  const [values, setValues] = useState<IValues>({
    initial_date_value: initialInitialDate,
    end_date_value: initialFinalDate,
  });

  function updateValues({ name, value }: { name: string; value: any }) {
    setValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  }

  async function loadRegistries({ filter, page = 1, order }: APiQueryBase) {
    setLoading(true);
    try {
      let _response = await AlertsService.getAlerts({ filter, page, order });

      if (!isErrorResult(_response) && _response?.data) {
        replace(_response.data);
        setPagination((prev) => ({
          ...prev,
          //@ts-ignore
          page: _response.meta?.current_page || 0,
          //@ts-ignore
          pages: _response.meta?.last_page || 0,
        }));
      }
    } catch (error) {
      console.log("loadRegistries error", error);
    } finally {
      setLoading(false);
    }
  }

  function handleSelectAction(action: string, data: any) {
    setAlertSelected(data.id);

    if (action === actions.add_comment) {
      setShowModal("add_comment");
    } else if (action === actions.view) {
      setShowModal("view_comments");
    } else if (action === actions.view_media) {
      if (data?.data) {
        setMedia(data.data);
        setShowModal("view_media");
      }
    }
  }

  function onChangeFilter(options: AlertFilterEvent) {
    const { tags = [], vehicle_type, alert_type, vehicle } = options;

    const filter: any = {
      ...pagination.filter,
      vehicle_plate: vehicle,
      vehicle_type_id: vehicle_type,
      alert_type_id: alert_type,
      tags: tags.map((element) => element.id),
    };

    if (!vehicle_type) delete filter.vehicle_type;
    if (!vehicle_type) delete filter.vehicle_type;
    if (!tags?.length) delete filter.tags;
    if (!vehicle) delete filter.vehicle_plate;
    if (!vehicle_type) delete filter.vehicle_type;

    setPagination((prev) => ({ ...prev, filter }));
  }

  useEffect(() => {
    loadRegistries(pagination);

    if (fields?.length === 0) {
      loadRegistries(pagination);
    }
  }, [pagination.filter, pagination.page, pagination.order]);

  function haveEditedItems(): boolean {
    return !!watch("registers")?.find((item) => !!item?.isEdited);
  }

  function onSearch(text: string): void {
    const filter = { ...pagination.filter, search: text };
    setPagination((prev) => ({ ...prev, filter }));
  }

  function handleShowMap(lat: number, lng: number) {
    setCenterMap({
      lat: lat,
      lng: lng,
    });
    setShowMap(true);
  }

  const handleActions = (item: IAlertRuleBody) => {
    if (!item.data) {
      return actionOptions.filter(
        (action) => action !== t("alerts.option_view_media")
      );
    }

    return actionOptions;
  };

  const periodFrom = watch("period.from");
  const periodTo = watch("period.to");

  useEffect(() => {
    const filter: any = {
      ...pagination.filter,
      start_date: periodFrom,
      end_date: periodTo,
    };

    if (!periodFrom) delete filter.start_date;
    if (!periodTo) delete filter.end_date;

    setPagination((prev) => ({ ...prev, filter }));
  }, [periodFrom, periodTo, user?.company_id]);

  return (
    <PrivateMasterPage
      title={t("alerts.alert_title")}
      breadcrumb={[t("alerts.alert_title")]}
    >
      <ProtectedComponent
        module_name={module_names.ALERTS}
        property="view"
        fallbackComponent={<Navigate to={"/configs/company"} />}
      >
        <Container className="p-3">
          <Card className="mb-5">
            <Card.Body className={(!fields?.length && "flex-none") + " p-4"}>
              <Row className="justify-content-end align-items-end mb-2">
                <Form.Group
                  as={Col}
                  xxl="2"
                  md="4"
                  className="w-auto"
                  controlId="period.from"
                >
                  <Form.Label>
                    {t("alerts.filter_initial_date_label")}
                  </Form.Label>
                  <DateTimerPicker
                    value={values.initial_date_value}
                    onChange={(value) => {
                      updateValues({
                        name: "initial_date_value",
                        value: value,
                      });
                    }}
                    onSelectDate={(date) => {
                      setPagination((prev) => ({
                        ...prev,
                        filter: {
                          ...pagination.filter,
                          from: date.formattedDate,
                        },
                      }));
                    }}
                  />
                  {/* <Form.Control
                  type="datetime-local"
                  {...register("period.from")}
                /> */}
                </Form.Group>

                <Form.Group
                  as={Col}
                  xxl="2"
                  md="4"
                  className="w-auto"
                  controlId="period.to"
                >
                  <Form.Label>{t("alerts.filter_end_date_label")}</Form.Label>
                  <DateTimerPicker
                    value={values.end_date_value}
                    onChange={(value) => {
                      updateValues({
                        name: "end_date_value",
                        value: value,
                      });
                    }}
                    onSelectDate={(date) => {
                      setPagination((prev) => ({
                        ...prev,
                        filter: {
                          ...pagination.filter,
                          to: date.formattedDate,
                        },
                      }));
                    }}
                  />
                  {/* <Form.Control
                  type="datetime-local"
                  {...register("period.to")}
                /> */}
                </Form.Group>

                <Col xxl="2" md="4" className="mb-3 w-auto">
                  <CustomSearchInput
                    placeholder={t("search")}
                    onChange={(e) => onSearch(e.target.value)}
                  />
                </Col>

                <Col className="mb-3" style={{ maxWidth: "fit-content" }}>
                  <Stack direction="horizontal" gap={3}>
                    <ActionButtonOutline onClick={() => setShowModal("filter")}>
                      <FaFilter size={18} />
                    </ActionButtonOutline>

                    {/* <ActionButtonOutline>
                    <FiUpload size={20} />
                  </ActionButtonOutline> */}
                  </Stack>
                </Col>
              </Row>

              <Row className="mb-5">
                <Col xs="12">
                  <Table responsive>
                    <CustomThead
                      data={[
                        { column: "date", name: t("alerts.table_event_date") },
                        { column: "alert", name: t("alerts.table_alert") },
                        { column: "alert", name: t("alerts.description") },
                        {
                          column: "sensor_type",
                          name: t("alerts.table_type_alert"),
                        },
                        { column: "", name: t("alerts.table_reading") },
                        { column: "plate", name: t("alerts.table_vehicle") },
                        { column: "", name: t("alerts.table_route") },
                        { column: "", name: t("alerts.table_location") },
                        { column: "driver", name: t("alerts.table_driver") },
                        { column: "menu", name: <th />, menu: true },
                      ]}
                      onChangeOrder={(order) =>
                        setPagination((prev) => ({ ...prev, order }))
                      }
                    />
                    <tbody>
                      {fields?.map((item, i) => (
                        <tr key={i}>
                          <td>{validateDateFormatAndTime(item?.date)}</td>
                          <td>{item?.alert}</td>
                          <td>{item?.description}</td>
                          <td>{item?.alert_type}</td>
                          <td>{item?.last_read}</td>
                          <td>{item?.license_plate}</td>
                          <td>
                            <ActionChips
                              color="#0062FF"
                              backgroundColor="rgba(0, 98, 255, 0.1)"
                            >
                              {t("text.route_view")}
                            </ActionChips>
                          </td>
                          <td>
                            <ActionChips
                              onClick={() => {
                                handleShowMap(item?.latitude, item?.longitude);
                              }}
                            >
                              {t("text.map_view")}
                            </ActionChips>
                          </td>
                          <td>{item?.driver_name}</td>
                          <td>
                            <TableActions
                              id={i}
                              items={handleActions(item)}
                              onSelectAction={(action) =>
                                handleSelectAction(action, item)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-center">
                  {isLoading && <Spinner animation="border" />}
                </Col>
              </Row>
            </Card.Body>

            <TableFooter
              actions={{
                hidden: !haveEditedItems(),
                titleSubmit: isSubmitting
                  ? t("status.saving")
                  : t("button.save"),
                titleCancel: t("button.cancel"),
                isSubmitting,
              }}
              paginate={{
                current_page: pagination.page,
                qtd: pagination.pages,
                onSelectPage: (page) =>
                  setPagination((prev) => ({ ...prev, page })),
              }}
            />
          </Card>

          <AddCommentModal
            visible={showModal === "add_comment"}
            onClose={() => {
              setAlertSelected(null);
              setShowModal(false);
            }}
            alertId={alertSelected}
          />

          <CommentsModal
            visible={showModal === "view_comments"}
            onClose={() => {
              setAlertSelected(null);
              setShowModal(false);
            }}
            alertId={alertSelected}
          />

          <MediaModal
            visible={showModal === "view_media"}
            onClose={() => setShowModal(false)}
            media={media}
          />

          <AlertFilterModal
            visible={showModal === "filter"}
            onClose={() => setShowModal(false)}
            onChange={onChangeFilter}
          />
        </Container>
      </ProtectedComponent>
      <MapModal
        visible={showMap}
        onClose={() => setShowMap(false)}
        center={centerMap}
      />
    </PrivateMasterPage>
  );
};

export default Alerts;
