export const TRACKING_OPTIONS = [
  { id: "1", label: "traking.option_path", checked: true },
  { id: "2", label: "traking.option_vehicle", checked: true },
  { id: "3", label: "traking.option_customer", checked: true },
  {
    id: "4",
    label: "traking.option_electronic_fence",
    checked: false,
  },
];
