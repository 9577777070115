import React from "react";
import { useTranslation } from "react-i18next";
import { Typeahead } from "./styles";
import { SelectEvent } from "react-bootstrap-typeahead/types/types";

export interface AllowNewOption {
  id: string;
  name: string;
  customOption: boolean;
}

interface CustomInputTagProps {
  options: any[] | AllowNewOption[];
  onChange?(selecteds: any[]): void;
  allowNew?: boolean;
  name?: string;
  defaultSelected?: string[] | AllowNewOption[];
  selected?: string[] | AllowNewOption[];
  isLoading?: boolean;
  paginate?: boolean;
  paginationText?: React.ReactNode;
  onPaginate?(event: SelectEvent<HTMLElement>, shownResults: number): void;
}

export default function CustomInputTagAlt({
  options,
  onChange,
  allowNew,
  name,
  defaultSelected,
  selected,
  isLoading,
  paginate,
  paginationText,
  onPaginate,
}: CustomInputTagProps) {
  const { t } = useTranslation();
  const mappedOptions = options.map((option) => ({
    ...option,
    label: option.key,
  }));
  const handleOnChange = (selectedOptions: any) => {
    //@ts-ignore
    onChange(selectedOptions);
  };

  return (
    <Typeahead
      defaultSelected={defaultSelected}
      id={name || "multiple-tag"}
      labelKey="label" // Usa a propriedade `label` mapeada
      multiple
      options={mappedOptions}
      selected={selected}
      allowNew={allowNew}
      newSelectionPrefix={t("component.input_tag_new.placeholder")}
      placeholder={t("component.input_tag.placeholder")}
      emptyLabel={t("component.input_tag.emptylabel")}
      onChange={handleOnChange} // Usa a função adaptada
      paginate={paginate}
      isLoading={isLoading}
      maxResults={paginate ? 1 : undefined}
      paginationText={paginationText}
      onPaginate={onPaginate}
    />
  );
}
