import React from "react";
import { Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DriverStatus from "./DriverStatus";
import Forms from "./Forms";
import TypesOccurrence from "./TypesOccurrence";
import { Tabs } from "./styles";
import GeneralSettings from "./GeneralSettings";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";

const ConfigsDriverApp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ProtectedComponent
      module_name={module_names.CONFIG_SURVEY}
      property="view"
      fallbackComponent={
        <Tabs
          defaultActiveKey="general_settings"
          className="mb-3"
          style={{ marginTop: "-25px", marginLeft: "-25px" }}
        >
          <Tab
            eventKey="general_settings"
            title={t("configs.app.general_settings_tab")}
          >
            <GeneralSettings />
          </Tab>
          <Tab
            eventKey="driver_status"
            title={t("configs.app.driver_status_tab")}
          >
            <DriverStatus />
          </Tab>

          <Tab
            eventKey="types_occurrence"
            title={t("configs.app.types_occurrence_tab")}
          >
            <TypesOccurrence />
          </Tab>
        </Tabs>
      }
    >
      <Tabs
        defaultActiveKey="general_settings"
        className="mb-3"
        style={{ marginTop: "-25px", marginLeft: "-25px" }}
      >
        <Tab
          eventKey="general_settings"
          title={t("configs.app.general_settings_tab")}
        >
          <GeneralSettings />
        </Tab>
        <Tab
          eventKey="driver_status"
          title={t("configs.app.driver_status_tab")}
        >
          <DriverStatus />
        </Tab>

        <Tab eventKey="forms" title={t("configs.app.forms_tab")}>
          <Forms />
        </Tab>
        {/* <Tab
        eventKey="journey_control"
        title={t("configs.app.journey_control_tab")}
      >
        <JourneyControl />
      </Tab> */}
        <Tab
          eventKey="types_occurrence"
          title={t("configs.app.types_occurrence_tab")}
        >
          <TypesOccurrence />
        </Tab>
      </Tabs>
    </ProtectedComponent>
  );
};

export default ConfigsDriverApp;
