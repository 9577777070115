import styled from "styled-components";

export const Container = styled.div`
  .empty-list-button-add {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 16px;
  }

  tbody {
    height: 140px !important;
  }

  th {
    text-transform: uppercase;
  }

  td:last-child {
    width: 100px;
  }
`;
