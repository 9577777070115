import { Accordion, Col, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAudit } from "../../../core/hooks/useAudit";
import ActionChips from "../../ActionChips";
import StatusChips from "../../StatusChips";
import { CustomAuditAccordion } from "../../CustomAuditAccordion";
import { BatteryIcon } from "../../BatteryIcon";
import moment from "moment";
import { TrackingService } from "../../../core/services/TrackingService";
import { useEffect, useState } from "react";
import MapModal from "../../MapModal";

interface IProps {
  data: any;
  summary: any;
}

export default function Positions({ data, summary }: IProps) {
  const { t } = useTranslation();
  const { actuatorOptions, sensorOptions } = useAudit();
  const [showMap, setShowMap] = useState(false);
  const [centerMap, setCenterMap] = useState({ lat: 1, lng: 1 });
  const [sumarized, setSummarized] = useState<any>();
  const [sensors, setSensors] = useState([]);
  const [actuators, setActuators] = useState([]);
  const [freeSensor, setFreeSensor] = useState([]);
  let teste: any;

  function handleShowMap(lat: number, lng: number) {
    setShowMap(true);
    setCenterMap({ lat: Number(lat), lng: Number(lng) });
  }

  function handleSensorsNames(name: any) {
    let data = null;

    const nome = !!name ? String(name).toLowerCase() : "--";

    switch (nome) {
      case "trava de baú lateral":
        data = "fleetmate.outings.side_door_lock";
        break;

      case "bloqueio de combustível":
        data = "fleetmate.outings.fuel_block";
        break;

      case "trava de baú principal":
        data = "fleetmate.outings.fifth_wheel_lock";
        break;

      case "quinta roda":
        data = "fleetmate.outings.flush_door_lock";
        break;

      case "sirene":
        data = "fleetmate.outings.sirem_alarm_lock";
        break;

      case "trava do trailer":
        data = "fleetmate.outings.trailer_door_lock";
        break;

      case "trava da ignição":
        data = "fleetmate.outings.ignition_lock";
        break;

      case "porta do carona":
        data = "fleetmate.entries.right_door";
        break;

      case "porta direita":
        data = "fleetmate.entries.right_door";
        break;

      case "porta esquerda":
        data = "fleetmate.entries.left_door";
        break;

      case "porta do motorista":
        data = "fleetmate.entries.left_door";
        break;

      case "porta principal":
        data = "fleetmate.entries.flush_door";
        break;

      case "painel":
        data = "fleetmate.entries.panel";
        break;

      case "tela de janela":
        data = "fleetmate.entries.panel_narrow";
        break;

      case "porta do trailer":
        data = "fleetmate.entries.trailer_door";
        break;

      case "pânico":
        data = "fleetmate.entries.panic_button";
        break;

      case "ignição":
        data = "fleetmate.entries.ignition";
        break;

      case "porta baú principal":
        data = "fleetmate.entries.flush_door_main";
        break;

      case "baú lateral":
        data = "fleetmate.sensor.side_chest";
        break;

      case "porta baú lateral":
        data = "fleetmate.sensor.side_chest";
        break;

      case "baú principal":
        data = "fleetmate.sensor.main_chest";
        break;

      case "porta baú principal":
        data = "fleetmate.sensor.main_chest";
        break;

      case "detecção de jammer":
        data = "fleetmate.sensor.jammer_detection";
        break;

      case "engate":
        data = "fleetmate.sensor.hitch";
        break;

      case "silo":
        data = "fleetmate.sensor.silo";
        break;

      case "trava da quinta roda":
        data = "fleetmate.outings.flush_door_lock";
        break;

      case "temperatura":
        data = "sensors.audit.temperature";
        break;

      case "velocidade":
        data = "sensors.audit.speed";
        break;

      case "combustível":
        data = "sensors.audit.fuel";
        break;

      case "bateria":
        data = "sensors.audit.battery";
        break;

      case "co₂":
        data = "sensors.audit.CO2";
        break;

      case "co2":
        data = "sensors.audit.CO2";
        break;

      case "câmera":
        data = "sensors.audit.camera";
        break;

      case "umidade":
        data = "sensors.audit.moisture";
        break;

      default:
        data = "--";
    }

    return !!data ? t(data) : "--";
  }

  function lockedOrUnlocked(constantName: string, isOpen: boolean) {
    switch (constantName.toLowerCase()) {
      case "LEFT_DOOR":
      case "porta direita":
      case "porta esquerda":
      case "porta do carona":
      case "RIGHT_DOOR":
      case "porta do motorista":
      case "baú lateral":
      case "trava do baú lateral":
      case "trava de baú lateral":
      case "SIDE_DOOR":
      case "FLUSH_DOOR":
      case "porta principal":
      case "trava de baú principal":
      case "porta baú principal": {
        return isOpen === true
          ? t("traking.task.modal_open")
          : t("traking.task.modal_locked");
      }
      case "SILO":
      case "silo":
      case "painel":
      case "FIFTH_WHEEL_LOCK":
      case "FUEL_BLOCK":
      case "bloqueio de combustível":
      case "FLUSH_DOOR_LOCK":
      case "trava de baú principal":
      case "baú principal":
      case "SIDE_DOOR_LOCK":
      case "FIFTH_WHEEL":
      case "quinta roda":
      case "engate":
      case "trava da quinta roda": {
        return isOpen === true
          ? t("traking.task.modal_unblocked")
          : t("traking.task.modal_blocked");
      }
      case "IGNITION":
      case "ignição":
      case "SIREM_ALARM_LOCK":
      case "sirene": {
        return isOpen === true
          ? t("traking.task.modal_on")
          : t("traking.task.modal_off");
      }
      case "PANIC_BUTTON":
      case "pânico": {
        return isOpen === true
          ? t("traking.task.modal_enabled")
          : t("traking.task.modal_disabled");
      }
      default:
        return "--";
    }
  }

  useEffect(() => {
    if (typeof data?.registry?.data?.extra_data === "string") {
      const parsedData = JSON.parse(data?.registry?.data?.extra_data);
      setSummarized(parsedData);
    }
  }, [summary]);

  useEffect(() => {
    if (
      data?.registry?.data?.extra_data?.sensors === null ||
      data?.registry?.data?.extra_data?.sensors === undefined
    )
      return;

    setSensors(
      data?.registry?.data?.extra_data?.sensors?.filter(
        (sensor: any) => sensor.type === "entry"
      )
    );

    setActuators(
      data?.registry?.data?.extra_data?.sensors?.filter(
        (sensor: any) => sensor.type === "exit"
      )
    );

    setFreeSensor(
      data?.registry?.data?.extra_data?.sensors?.filter(
        (sensor: any) => sensor.type === null
      )
    );
  }, [data]);

  useEffect(() => {
    if (sumarized !== null && sumarized !== undefined) {
      setSensors(
        sumarized?.sensors?.filter((sensor: any) => sensor.type === "entry")
      );

      setActuators(
        sumarized?.sensors?.filter((sensor: any) => sensor.type === "exit")
      );

      setFreeSensor(
        sumarized?.sensors?.filter((sensor: any) => sensor.type === null)
      );
    }
  }, [sumarized]);

  return (
    <>
      <MapModal
        visible={showMap}
        onClose={() => setShowMap(false)}
        center={centerMap}
      />

      <div className="item-group p-0">
        <Row>
          <Col>
            <label>{t("configs.audit.register_date_hour")}:</label>
            {/* @ts-ignore */}
            <p>
              {data?.registry?.data?.datetime_send !== undefined
                ? moment(data?.registry?.data?.datetime_send).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : data?.registry?.created_at
                ? moment(data?.registry?.created_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.event_date_hour")}:</label>
            <p>
              {data?.registry?.data?.datetime_write !== undefined
                ? moment(data?.registry?.data?.datetime_write).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : data?.registry?.generated_at
                ? moment(data?.registry?.generated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.driver")}:</label>
            <p>
              {data?.driver?.name !== undefined
                ? data?.driver?.name + " " + data?.driver?.last_name
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.license_plate")}:</label>
            {/* @ts-ignore */}
            <p>
              {data?.vehicle?.license_plate !== undefined
                ? data?.vehicle?.license_plate
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.speed")}:</label>
          </Col>
          <Col>
            <p>
              {data?.registry?.data?.speed !== undefined
                ? data?.registry?.data?.speed + "km/h"
                : data?.registry?.speed
                ? data?.registry?.speed + "km/h"
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      {/* <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.ignition")}:</label>
          </Col>
          <Col>
            <p>Habilitada</p>
          </Col>
        </Row>
      </div> */}

      {/* <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.battery_voltage")}:</label>
          </Col>
          <Col>
            <p>12V</p>
          </Col>
        </Row>
      </div> */}

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.location")}:</label>
          </Col>
          <Col>
            <Stack
              className="justify-content-between align-items-center mb-3 flex-wrap"
              direction="horizontal"
              gap={2}
            >
              <p className="m-0">
                {data?.registry?.data?.latitude !== undefined &&
                data?.registry?.data?.longitude !== undefined
                  ? data?.registry?.data?.latitude +
                    "," +
                    " " +
                    data?.registry?.data?.longitude
                  : data?.registry?.lat !== undefined &&
                    data?.registry?.long !== undefined
                  ? data?.registry?.lat + "," + " " + data?.registry?.long
                  : //@ts-ignore
                  sumarized?.latitude !== undefined &&
                    //@ts-ignore
                    sumarized.longitude !== undefined
                  ? //@ts-ignore
                    sumarized?.latitude + "," + " " + sumarized?.longitude
                  : "--"}
              </p>
              {data?.registry?.data?.latitude ? (
                <ActionChips
                  backgroundColor="#f2f8ff"
                  color="#007BFF"
                  onClick={() =>
                    handleShowMap(
                      data?.registry?.data?.latitude,
                      data?.registry?.data?.longitude
                    )
                  }
                >
                  {t("text.map_view")}
                </ActionChips>
              ) : data?.registry?.lat ? (
                <ActionChips
                  backgroundColor="#f2f8ff"
                  color="#007BFF"
                  onClick={() =>
                    handleShowMap(data?.registry?.lat, data?.registry?.long)
                  }
                >
                  {t("text.map_view")}
                </ActionChips>
              ) : //@ts-ignore
              sumarized?.latitude ? (
                <ActionChips
                  backgroundColor="#f2f8ff"
                  color="#007BFF"
                  onClick={() =>
                    //@ts-ignore
                    handleShowMap(sumarized?.latitude, sumarized?.longitude)
                  }
                >
                  {t("text.map_view")}
                </ActionChips>
              ) : //@ts-ignore
              sumarized?.lat ? (
                <ActionChips
                  backgroundColor="#f2f8ff"
                  color="#007BFF"
                  //@ts-ignore
                  onClick={() => handleShowMap(sumarized?.lat, sumarized?.long)}
                >
                  {t("text.map_view")}
                </ActionChips>
              ) : (
                <ActionChips
                  backgroundColor="#F2F8FF"
                  color="#007BFF"
                  onClick={() => alert("Coordenadas inválidas")}
                />
              )}
            </Stack>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.network")}:</label>
          </Col>
          <Col>
            {/* @ts-ignore */}
            <p>
              {data?.registry?.data?.source
                ? data?.registry?.data?.source
                : data?.registry?.network
                ? data?.registry?.network
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          <Accordion id="audit_position_accordion" alwaysOpen>
            {actuators.length > 0 && (
              <>
                <CustomAuditAccordion
                  eventKey="0"
                  data={{
                    title: t("configs.audit.actuators"),
                    subtitles: [
                      t("configs.audit.status"),
                      t("configs.audit.date_reading"),
                    ],
                  }}
                />

                <Accordion.Collapse eventKey="0">
                  <>
                    {actuators.map((item: any, i: number) => (
                      <Row key={i} className="pb-3">
                        <Col xl="5">
                          <label key={i}>{handleSensorsNames(item.name)}</label>
                        </Col>
                        <Col>
                          <StatusChips
                            statusColor={
                              item.active === false ? "#28A745" : "#939698"
                            }
                          >
                            {lockedOrUnlocked(item.name, item.active)}
                          </StatusChips>
                        </Col>
                        <Col className="text-muted">
                          {moment(data?.registry?.generated_at).format(
                            "DD/MM/YYYY - HH:mm:ss"
                          )}
                        </Col>
                      </Row>
                    ))}
                  </>
                </Accordion.Collapse>

                <CustomAuditAccordion
                  eventKey="1"
                  data={{
                    title: t("configs.audit.sensors"),
                    subtitles: [
                      t("configs.audit.status"),
                      t("configs.audit.date_reading"),
                    ],
                  }}
                />
              </>
            )}

            {sensors.length > 0 && (
              <Accordion.Collapse eventKey="1">
                <>
                  {sensors.map((item: any, i: number) => (
                    <Row key={i} className="pb-3">
                      <Col xl="5">
                        <label>
                          {t("configs.audit.sensor.output_text")} {i + 1}:{" "}
                          {handleSensorsNames(item.name)}
                        </label>
                      </Col>
                      <Col>
                        <StatusChips
                          statusColor={
                            item.active === false ? "#28A745" : "#939698"
                          }
                        >
                          {lockedOrUnlocked(item.name, item.active)}
                        </StatusChips>
                      </Col>
                      <Col className="text-muted">
                        {moment(data?.registry?.generated_at).format(
                          "DD/MM/YYYY - HH:mm:ss"
                        )}
                      </Col>
                    </Row>
                  ))}

                  <div
                    className="item-group p-0 mb-2"
                    style={{ marginLeft: 40 }}
                  />

                  {freeSensor.length > 0 && (
                    <>
                      {freeSensor.map((item: any, i: number) => (
                        <Row key={i} className="pb-3">
                          <Col xl="5">
                            <label>{handleSensorsNames(item.name)}:</label>
                          </Col>
                          <Col className="gap-2">
                            <Stack direction="horizontal" gap={3}>
                              <div className="ml-2">{item.value}</div>
                              <div
                                className="d-flex gap-1"
                                style={{
                                  display:
                                    item.battery !== undefined ||
                                    item.battery !== null
                                      ? "flex"
                                      : "none",
                                }}
                              >
                                <BatteryIcon
                                  style={{
                                    display:
                                      item.battery !== undefined ||
                                      item.battery !== null
                                        ? "flex"
                                        : "none",
                                  }}
                                  percent={item.battery}
                                />
                                {item.battery}
                              </div>
                            </Stack>
                          </Col>
                          <Col className="text-muted">
                            {moment(data?.registry?.generated_at).format(
                              "DD/MM/YYYY - HH:mm:ss"
                            )}
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </>
              </Accordion.Collapse>
            )}
          </Accordion>
        </Col>
      </Row>
    </>
  );
}
