import { api } from ".";
import { Company } from "../interfaces/Company";
import { ErrorResult } from "../interfaces/ErrorResult";

export class CompanyService {
  static async get(): Promise<any | ErrorResult> {
    return api
      .get(`/api/v2/company`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(data: Company): Promise<ErrorResult> {
    return api
      .put(`/api/v2/company`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
