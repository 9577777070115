import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import CustomInputTag from "../CustomInputTag";
import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { FaFilter } from "react-icons/fa";
import { VehicleTypeService } from "../../core/services/VehicleTypeService";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";
import { SENSORS } from "../../constants/sensors";

type FormValues = {
  vehicle: string;
  alert_type: { label: string; value: number };
  vehicle_type: { label: string; value: number };
  tags: string[];
};

export type AlertFilterEvent = {
  vehicle: string;
  alert_type: string;
  vehicle_type: number;
  tags: IVehicleTag[];
};

interface AlertFilterModalProps {
  visible: boolean;
  onClose?(): void;
  onChange?(event: AlertFilterEvent): void;
}

const AlertFilterModal: React.FC<AlertFilterModalProps> = ({
  onClose,
  visible,
  onChange,
  ...props
}) => {
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const { register, control, handleSubmit, reset } = useForm<FormValues>();

  const handleCancel = () => {
    reset();
    onClose?.();
  };

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const tags = vehicleTags?.filter((item) =>
      values?.tags?.includes(item.key)
    );


    const vehicle_type = values?.vehicle_type?.value;
    const alert_type = values?.alert_type?.value.toString();

    onChange?.({ ...values, tags, alert_type, vehicle_type });
    onClose?.();
  };

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
    }
  }, [visible]);

  return (
    <Modal {...props} show={visible} size="md" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2 p-4" closeButton>
        <FaFilter size={18} />
        <Modal.Title>{t("text.filter")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pt-0">
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="vehicle_type">
              <Form.Label>{t("alerts.filter_vehicle_type_label")}</Form.Label>
              <Controller
                control={control}
                name="vehicle_type"
                render={({ field: { value, onChange, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    placeholder={t("input.select_text")}
                    loadOptions={(inputValue, options, additional) =>
                      VehicleTypeService.loadVehicleTypeOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="alert_type">
              <Form.Label>{t("alerts.filter_alert_type_label")}</Form.Label>
              <Controller
                name="alert_type"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    placeholder={t("input.select_text")}
                    loadOptions={(inputValue: string): Promise<any> => {
                      const translatedOptions = SENSORS.map((sensor) => ({
                        ...sensor,
                        label: t(sensor.label),
                      }));

                      const filteredOptions = translatedOptions.filter(
                        (option) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                      );

                      return Promise.resolve({
                        options: filteredOptions,
                        hasMore: false,
                      });
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group lg={6} md={12} controlId="vehicle" as={Col}>
              <Form.Label>{t("alerts.filter_vehicle_label")}</Form.Label>
              <Form.Control
                type="text"
                {...register("vehicle")}
                placeholder={t("vehicles.form.label.license_plate")}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="mb-4" controlId="name" as={Col}>
              <Form.Label>{t("component.tag_label")}</Form.Label>
              <Controller
                name="tags"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <CustomInputTag
                    defaultSelected={value}
                    onChange={(values: string[]) => onChange(values)}
                    options={vehicleTags?.map((op) => op.key)}
                    isLoading={isLoadingTags}
                  />
                )}
              />
            </Form.Group>
          </Row>
        </Form>

        <Row>
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button onClick={handleSubmit(onSubmit, console.log)}>
              {t("button.save")}
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AlertFilterModal;
