import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Modal = styled(RBModal)`
  .modal-header {
    border: 0;
    padding: 1.5em;
    margin-bottom: -3em;
    z-index: 5;
  }
`;
