import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFilter } from "react-icons/fa";
import { ActionButtonOutline } from "../../components/ActionButtonOutline";
import AuditDetailModal from "../../components/AuditDetailModal";
import AuditFilterModal from "../../components/AuditFilterModal";
import CustomSearchInput from "../../components/CustomSearchInput";
import CustomThead from "../../components/CustomThead";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";
import { useAudit } from "../../core/hooks/useAudit";
import { AuditEvent, AuditRegistry } from "../../core/interfaces/Audit";
import { User } from "../../core/interfaces/User";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { updateAlert } from "../../core/redux/reducer/alert";
import { getProfiles } from "../../core/redux/reducer/user";
import { AuditService } from "../../core/services/AuditService";
import { UserService } from "../../core/services/UserService";
import { isErrorResult } from "../../core/utils/api";
import { Container } from "./styles";
import { DriverService } from "../../core/services/DriverService";
import { FiDownload } from "react-icons/fi";
import AuditReportModal from "../../components/AuditReportModal";
import { useFormatDate } from "../../core/hooks/useFormatDate";
import { getUser } from "../../core/redux/reducer/auth";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";

interface LoadData {
  filter?: Object;
  page: number;
}

const ConfigsAudit: React.FC = () => {
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { validateDateFormatAndTime } = useFormatDate();
  // const dispatch = useAppDispatch();
  // const profiles = useAppSelector(getProfiles);
  const [filtered, setFilter] = useState({
    action: "",
    date_at: "",
    date_from: "",
    driver_id: "",
    event_id: "",
    record_origins: "",
    user_id: "",
    vehicle_id: "",
    limit: 10,
    offset: 0,
  });
  const actions = {
    reset_password: "Resetar Senha",
    delete: "Excluir Usuário",
  };
  const actionOptions = Object.values(actions);

  const [visibleDetails, setVisibleDetails] = useState(false);
  const [register, setRegister] = useState<any>({});
  const [auditRegistries, setAuditRegistries] = useState<AuditRegistry>();
  const [auditEvents, setAuditEvents] = useState<AuditEvent[]>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getRecordTypeTitle } = useAudit();

  const usersObj: { [key: string]: AuditRegistry } = {};
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
  }>({ page: 1, pages: 0 });
  const [deleteUser, setDeleteUser] = useState<User | null>(null);
  const [resetPasswordUser, setResetPasswordUser] = useState<User | null>(null);
  const [edit, setEdit] = useState<{ [key: string]: User }>({});
  const [module, setModule] = useState<number>();
  const [minDate, setMinDate] = useState();
  const [download, setDownload] = useState<boolean>(false);

  const options = [
    "system_record",
    "positions",
    "messages",
    "login",
    "electronic_fences",
    "driver_app_settings",
    "commands",
  ];

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const maxDate = `${year}-${month}-${day}T00:00`;

  const [firstPage, setFirstPage] = useState<boolean>();

  async function loadRegistries({ filter, page = 1 }: LoadData) {
    try {
      const _response = await AuditService.getRegisters({
        filter,
        page,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setAuditRegistries(_response.data);
        setIsLoading(false);
        setPagination((prev) => ({
          ...prev,
        }));
      }
    } catch (error) {
      console.log("loadRegistries error", error);
    }
  }

  async function loadAuditEvents() {
    try {
      const _response = (await AuditService.getEvents({})) as AuditEvent[];

      const isError = isErrorResult(_response);

      if (!isError && _response) {
        _response.sort((a, b) => a.name.localeCompare(b.name));

        setAuditEvents(_response);
      }
    } catch (error) {}
  }

  function handleSelectAction(action: string, data: User) {
    if (action === actions.delete) {
      setDeleteUser(data);
    } else if (action === actions.reset_password) {
      setResetPasswordUser(data);
    }
  }

  // function handleDeleteUser() {
  //   if (deleteUser?.id)
  //     UserService.delete(deleteUser.id).then((result) => {
  //       setDeleteUser(null);
  //       dispatch(updateAlert({ title: "Usuário deletado com sucesso!" }));
  //       loadRegistries(pagination);
  //     });
  // }

  // function handleResetPasswordUser() {
  //   setDeleteUser(null);

  //   dispatch(
  //     updateAlert({
  //       title: "Senha resetada com sucesso!",
  //       message:
  //         "O usuário receberá um link no email cadastrado para a criação de uma nova senha.",
  //     })
  //   );
  // }

  function handleDomeRecordType(index: number) {
    setRegister({ record_type: options[index] });
    setVisibleDetails(true);
  }

  function handleDownloadModal() {
    setDownload(true);
  }

  useEffect(() => {
    if (
      //@ts-ignore
      localStorage.getItem("action") !== String(filtered.action) ||
      //@ts-ignore
      localStorage.getItem("date_at") !== String(filtered.date_at) ||
      //@ts-ignore
      localStorage.getItem("date_from") !== String(filtered.date_from) ||
      //@ts-ignore
      localStorage.getItem("driver_id") !== String(filtered.driver_id) ||
      //@ts-ignore
      localStorage.getItem("event_id") !== String(filtered.event_id) ||
      //@ts-ignore
      localStorage.getItem("record_origins") !==
        String(filtered.record_origins) ||
      //@ts-ignore
      localStorage.getItem("user_id") !== String(filtered.user_id) ||
      //@ts-ignore
      localStorage.getItem("vehicle_id") !== String(filtered.vehicle_id)
    ) {
      setPagination((prev) => ({
        ...prev,
        page: 1,
      }));

      //@ts-ignore
      localStorage.setItem("action", filtered.action);
      //@ts-ignore
      localStorage.setItem("date_at", filtered.date_at);
      //@ts-ignore
      localStorage.setItem("date_from", filtered.date_from);
      //@ts-ignore
      localStorage.setItem("driver_id", filtered.driver_id);
      //@ts-ignore
      localStorage.setItem("event_id", filtered.event_id);
      //@ts-ignore
      localStorage.setItem("record_origins", filtered.record_origins);
      //@ts-ignore
      localStorage.setItem("user_id", filtered.user_id);
      //@ts-ignore
      localStorage.setItem("vehicle_id", filtered.vehicle_id);
    } else {
    }
  }, [
    localStorage.getItem("action"),
    localStorage.getItem("date_at"),
    localStorage.getItem("date_from"),
    localStorage.getItem("driver_id"),
    localStorage.getItem("event_id"),
    localStorage.getItem("record_origins"),
    localStorage.getItem("user_id"),
    localStorage.getItem("vehicle_id"),
    filtered,
  ]);

  useEffect(() => {
    loadRegistries({ filter: filtered, page: pagination.page });
  }, [filtered, pagination.page, user?.company_id]);

  useEffect(() => {
    loadAuditEvents();
  }, [user?.company_id]);

  return (
    <ProtectedComponent module_name={module_names.CONFIG_AUDIT} property="view">
      <Container className="mb-3">
        <Row className="mb-3">
          <Col className="d-flex align-items-center" xs="2">
            <h5 className="mb-0 p-0">{t("configs.audit.title")}</h5>
          </Col>

          <Col className="filters" xs="2" sm="auto" md="auto" xxl="10">
            <Stack direction="horizontal" gap={3}>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>{t("configs.audit.filter_start_date")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  placeholder=""
                  max={maxDate}
                  style={{ width: 212 }}
                  onChange={(e) => {
                    setTimeout(() => {
                      setFilter((prev) => ({
                        ...prev,
                        date_from: e.target.value,
                      }));
                    }, 2000);
                    //@ts-ignore
                    setMinDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>{t("configs.audit.filter_end_date")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  placeholder=""
                  min={minDate}
                  style={{ width: 212 }}
                  onChange={(e) =>
                    setFilter((prev) => ({
                      ...prev,
                      date_at: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>{t("configs.audit.record_type")}</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setFilter((prev) => ({
                      ...prev,
                      event_id: e.target.value,
                    }))
                  }
                  style={{ width: "auto" }}
                >
                  <option value={""}>{t("journey.all")}</option>
                  {auditEvents?.map((event, i) => (
                    <option key={i} value={event.id}>
                      {event.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {/* <CustomSearchInput
              className="search"
              placeholder={t("search")}
              onChange={(e) =>
                setTimeout(() => {
                  setFilter((prev) => ({
                    ...prev,
                    term: e.target.value,
                  }));
                }, 2000)
              }
            /> */}

              <ActionButtonOutline onClick={() => setOpenFilter(true)}>
                <FaFilter size={18} />
              </ActionButtonOutline>

              <ActionButtonOutline onClick={() => setDownload(true)}>
                <FiDownload size={20} />
              </ActionButtonOutline>
            </Stack>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col xs="12">
            <Table responsive hover>
              <CustomThead
                data={[
                  {
                    column: "created_at",
                    name: t("configs.audit.table_register_date_hour"),
                    menu: true,
                  },
                  {
                    column: "event.name",
                    name: t("configs.audit.table_register_type"),
                    menu: true,
                  },
                  {
                    column: "generated_at",
                    name: t("configs.audit.table_event_date_hour"),
                    menu: true,
                  },
                  {
                    column: "user.name",
                    name: t("configs.audit.table_user"),
                    menu: true,
                  },
                  {
                    column: "driver.email",
                    name: t("configs.audit.table_driver"),
                    menu: true,
                  },
                ]}
                onChangeOrder={(event) => console.log(event)}
              />
              {auditRegistries && auditRegistries?.registries?.length > 0 ? (
                <tbody>
                  <>
                    {auditRegistries?.registries.map((registry: any, i) => (
                      <tr
                        key={i}
                        onClick={() => {
                          setVisibleDetails(true);
                          setModule(i);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <td>
                          <div>
                            {registry.created_at
                              ? validateDateFormatAndTime(
                                  registry.created_at,
                                  true
                                )
                              : "-"}
                          </div>
                        </td>
                        <td>
                          <div>{registry.event.name ?? "-"}</div>
                        </td>
                        <td>
                          <div>
                            {registry.generated_at
                              ? validateDateFormatAndTime(
                                  registry.generated_at,
                                  true
                                )
                              : "-"}
                          </div>
                        </td>
                        <td>
                          <div>
                            {registry?.user?.name
                              ? registry?.user?.name +
                                " " +
                                registry?.user?.last_name
                              : "--"}
                          </div>
                        </td>
                        <td>
                          <div>
                            {registry?.driver?.name
                              ? registry?.driver?.name +
                                " " +
                                registry?.driver?.last_name
                              : "--"}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              ) : isLoading === false ? (
                <>
                  <td>
                    <div></div>
                  </td>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      textAlign: "end",
                      fontSize: "22px",
                      fontWeight: 700,
                    }}
                  >
                    <p>{t("configs.menu.audit.no_records")}</p>
                  </div>
                  <td>
                    <div></div>
                  </td>
                  <td>
                    <div></div>
                  </td>
                </>
              ) : null}
            </Table>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center">
            {isLoading && <Spinner animation="border" />}
          </Col>
        </Row>

        <Row>
          <Footer>
            <div />
            <div className="mx-3">
              <Pagination
                current_page={pagination.page}
                qtd={
                  auditRegistries?.registries?.length! === 10
                    ? pagination.page + 1
                    : pagination.page
                }
                onSelectPage={(page) => {
                  setFilter((prev) => ({
                    ...prev,
                    limit: 10,
                    offset: page * 10 - 10,
                  }));
                  setPagination({
                    ...pagination,
                    page,
                  });
                }}
              />
            </div>
          </Footer>
        </Row>

        <AuditReportModal
          visible={download}
          onClose={() => setDownload(false)}
        />

        {visibleDetails ? (
          <AuditDetailModal
            visible={visibleDetails}
            onClose={() => setVisibleDetails(false)}
            data={auditRegistries?.registries?.[module!]}
          />
        ) : null}

        <AuditFilterModal
          events={auditEvents!}
          visible={openFilter}
          onClose={() => setOpenFilter(false)}
          //@ts-ignore
          onChange={(filter) => setFilter(filter)}
        />
      </Container>
    </ProtectedComponent>
  );
};

export default ConfigsAudit;
