import React from "react";
import { Accordion, Form, useAccordionButton } from "react-bootstrap";
import { AccordionItemProps } from "react-bootstrap/esm/AccordionItem";
import {
  Control,
  useFieldArray,
  useWatch,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TbChevronDown, TbChevronRight } from "react-icons/tb";
import { FormValues } from ".";
import { ActionToolButton } from "../ActionToolButton";
import { NestedFieldArrayContainer, CustomBody } from "./styles";

interface ModuleNestedFieldArrayProps {
  nestIndex: number;
  control: Control<FormValues>;
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
}

const ModuleNestedFieldArray: React.FC<ModuleNestedFieldArrayProps> = ({
  nestIndex,
  control,
  register,
  setValue,
}) => {
  const [isSelected, setSelected] = React.useState<{ [key: string]: boolean }>(
    {}
  );

  const { t } = useTranslation();

  const { fields, update } = useFieldArray({
    control,
    name: `profiles.${nestIndex}.user_access_modules`,
  });

  const { profiles } = useWatch({ control });

  function handleSelectAll(index: number) {
    const module = profiles?.[nestIndex]?.user_access_modules?.[index];
    const value = !getSelectedAll(index);

    if (!!module && module.id) {
      update(index, {
        id: module.id,
        module_name: module?.module_name || "",
        view: +value,
        edit: +value,
        security: +value,
        delete: +value,
        commands: +value,
        isEdited: true,
        show_delete: module.show_delete,
        show_command: module.show_command,
        pivot: {
          commands: +value,
          company_id: module.pivot?.company_id!,
          edit: +value,
          view: +value,
          user_access_module_id: module.id,
          user_profile_id: module.pivot?.user_profile_id!,
          delete: +value,
        },
      });
    }
  }

  function getSelectedAll(index: number): boolean {
    const module = profiles?.[nestIndex]?.user_access_modules?.[index];

    return Boolean(module?.pivot?.edit);
  }

  function handleEditField(index: number) {
    setValue(
      `profiles.${nestIndex}.user_access_modules.${index}.isEdited`,
      true
    );
  }

  function CustomToggle({ children, eventKey }: AccordionItemProps) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      // console.log("totally custom!", { eventKey });
      setSelected((prev) => ({ ...prev, [eventKey]: !prev[eventKey] }));
    });

    return (
      <ActionToolButton type="button" onClick={decoratedOnClick}>
        {children}
      </ActionToolButton>
    );
  }

  return (
    <NestedFieldArrayContainer>
      <Accordion id="module_accordion" alwaysOpen>
        {fields.map((field, index) => {
          return (
            <div key={`${nestIndex}_${index}_${field.id}`}>
              <div className="d-flex align-items-center gap-2 mb-2">
                <CustomToggle eventKey={`${index}`}>
                  {isSelected[String(index)] && <TbChevronDown />}
                  {!isSelected[String(index)] && <TbChevronRight />}
                </CustomToggle>

                <Form.Group>
                  <Form.Check
                    id={`profile_${index}_${field.id}`}
                    label={field.module_name}
                    reverse
                    inline
                    onChange={() => {
                      console.log(field);
                      handleSelectAll(index);
                    }}
                    checked={getSelectedAll(index)}
                    // defaultChecked={false}
                    defaultChecked={getSelectedAll(index)}
                  />
                </Form.Group>
              </div>

              <Accordion.Collapse
                id="module_accordion_collapse"
                eventKey={`${index}`}
              >
                <CustomBody>
                  <Form.Group className="mb-2">
                    <Form.Check
                      id={`${index}_modules_view`}
                      label={t("configs.user.access_level_view")}
                      reverse
                      inline
                      defaultChecked={Boolean(field?.pivot?.view)}
                      {...register(
                        `profiles.${nestIndex}.user_access_modules.${index}.view`,
                        { onChange: () => handleEditField(index) }
                      )}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Check
                      id={`${index}_modules_edit`}
                      label={t("configs.user.access_level_edit")}
                      reverse
                      inline
                      defaultChecked={Boolean(field?.pivot?.edit)}
                      {...register(
                        `profiles.${nestIndex}.user_access_modules.${index}.edit`,
                        { onChange: () => handleEditField(index) }
                      )}
                    />
                  </Form.Group>

                  {Boolean(field.show_command) && (
                    <Form.Group className="mb-2">
                      <Form.Check
                        id={`${index}_modules_commands`}
                        label={t("configs.user.access_level_commands")}
                        reverse
                        inline
                        defaultChecked={Boolean(field?.pivot?.commands)}
                        {...register(
                          `profiles.${nestIndex}.user_access_modules.${index}.commands`,
                          { onChange: () => handleEditField(index) }
                        )}
                      />
                    </Form.Group>
                  )}
                  {Boolean(field.show_delete) && (
                    <Form.Group className="mb-2">
                      <Form.Check
                        id={`${index}_modules_delete`}
                        label={t("configs.user.access_level_delete")}
                        reverse
                        inline
                        defaultChecked={Boolean(field?.pivot?.delete)}
                        {...register(
                          `profiles.${nestIndex}.user_access_modules.${index}.delete`,
                          { onChange: () => handleEditField(index) }
                        )}
                      />
                    </Form.Group>
                  )}
                </CustomBody>
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </NestedFieldArrayContainer>
  );
};

export default ModuleNestedFieldArray;
