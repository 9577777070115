import { CSSProperties, HTMLAttributes } from "react";
import { Container, Link } from "./styles";

interface ActionChipsProps extends HTMLAttributes<HTMLAnchorElement> {
  children?: string;
  backgroundColor?: string;
  style?: CSSProperties;
}

export default function ActionChips({
  children,
  backgroundColor,
  style,
  ...props
}: ActionChipsProps) {
  return (
    <Container style={{ backgroundColor, ...style }}>
      <Link {...props} style={props.color ? { color: props.color } : {}}>
        {children}
      </Link>
    </Container>
  );
}
