import styled from "styled-components";
import { Col } from "react-bootstrap";

export const Container = styled.div`
  height: 100% !important;
  display: flex;
  padding: 2rem 1.5rem !important;
  flex-direction: column;
  /* background-color: green; */

  .form-switch {
    .form-check-label {
      color: #212529;
    }
  }

  .form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* background-color: red; */
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CustomCol = styled(Col)`
  border: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #f2f2f2;
  max-width: 43%;
  padding: 1rem 0 0.5rem;
`;

export const Line = styled.div`
  border: 1px solid #f2f2f2;
`;

export const SectionTitle = styled.label`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
`;

export const SectionSubtitle = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6c757d;
`;

export const Footer = styled.div`
  margin-top: 60px;
  @media (max-width: 1024px) {
    position: relative;
    margin-top: 3rem;
  }
`;
