import React from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import {
  Control,
  useFieldArray,
  useWatch,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormValues } from ".";
import { useAppSelector } from "../../core/redux/hooks";
import { getProfiles } from "../../core/redux/reducer/user";
import ModuleNestedFieldArray from "./moduleNestedFieldArray";
import { FieldArrayContainer } from "./styles";
import { UserProfileService } from "../../core/services/UserProfileService";
import { isError } from "lodash";
import { isErrorResult } from "../../core/utils/api";

interface ProfileFieldArrayProps {
  control: Control<FormValues>;
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
}

const ProfileFieldArray: React.FC<ProfileFieldArrayProps> = ({
  control,
  register,
  setValue,
}) => {
  const { t } = useTranslation();

  const { fields, replace, update } = useFieldArray({
    control,
    name: "profiles",
    keyName: "fieldId",
  });

  const profiles = useAppSelector(getProfiles);
  const { profiles: watchProfiles } = useWatch({ control });

  function handleSelectAll(index: number) {
    const profile = watchProfiles?.[index];
    const value = !getSelectedAll(index);

    const user_access_modules: any[] =
      profile?.user_access_modules?.map((module) => ({
        id: module.id,
        module_name: module.module_name,
        view: +value,
        edit: +value,
        security: +value,
        delete: +value,
        commands: +value,
        isEdited: true,
        show_delete: module.show_delete,
        show_command: module.show_command,
        pivot: {
          commands: +value,
          company_id: module.pivot?.company_id!,
          edit: +value,
          view: +value,
          user_access_module_id: module.id,
          user_profile_id: module.pivot?.user_profile_id!,
          delete: +value,
        },
      })) || [];

    update(index, {
      id: profile?.id,
      profile_name: profile?.profile_name || "",
      user_access_modules,
    });
  }

  function getSelectedAll(index: number): boolean {
    const profile = watchProfiles?.[index];
    let count = 0;

    profile?.user_access_modules?.map((module) => {
      if (
        Boolean(module?.view) &&
        Boolean(module?.edit) &&
        Boolean(module?.security) &&
        Boolean(module?.commands)
      ) {
        count++;
      }
    });

    return profile?.user_access_modules?.length === count;
  }

  async function getAllProfiles() {
    const _response = await UserProfileService.getAllProfiles();
    const isError = isErrorResult(_response);
    if (!isError && _response.data) {
      replace(_response.data);
    }
    return [];
  }

  React.useEffect(() => {
    getAllProfiles();
    // replace(profiles);
  }, []);

  return (
    <FieldArrayContainer className="mb-4">
      <Accordion id="profile_accordion" defaultActiveKey={["0"]} flush>
        {fields.map((field, index) => (
          <Accordion.Item
            key={`${index}_${field.fieldId}`}
            eventKey={String(index)}
          >
            <Accordion.Header>{field.profile_name}</Accordion.Header>

            <Accordion.Body id="profile_accordion_collapse">
              <Row>
                <Col>
                  <ModuleNestedFieldArray
                    nestIndex={index}
                    control={control}
                    register={register}
                    setValue={setValue}
                  />
                </Col>

                <Col>
                  <Form.Check
                    id={`profile_${index}_${field.fieldId}`}
                    label={t("configs.user.access_profile.checkbox_all")}
                    reverse
                    checked={getSelectedAll(index)}
                    onChange={(e) => {
                      handleSelectAll(index);
                    }}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </FieldArrayContainer>
  );
};

export default ProfileFieldArray;
