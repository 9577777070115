import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Modal = styled(RBModal)`
  .modal-header {
    border: 0;
    padding: 1.5em;
    margin-bottom: -3em;
    z-index: 5;
  }

  .attribute-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: fit-content;

    label {
      margin-bottom: 8px;
    }
  }
  .form-control:not(active) {
    color: white;
  }

  .form-control:focus {
    color: black;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #6c757d;
`;

export const Description = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
`;

export const Chips = styled.div`
  height: 26px;
  background: #007bff;
  border-radius: 5px;
  padding: 0 16px;
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #f8f9fa;
`;
