import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import { Container, WeekdayButton } from "./styles";

interface WeekDayPickerProps {
  style?: CSSProperties;
  onChange?(values: string[]): void;
  value?: string[];
}

export default function WeekDayPicker({
  style,
  onChange,
  value = [],
}: WeekDayPickerProps) {
  const { t } = useTranslation();

  const weekdays = [
    { label: t("weekday.sunday"), value: "1" },
    { label: t("weekday.monday"), value: "2" },
    { label: t("weekday.tuesday"), value: "3" },
    { label: t("weekday.wednesday"), value: "4" },
    { label: t("weekday.thursday"), value: "5" },
    { label: t("weekday.friday"), value: "6" },
    { label: t("weekday.saturday"), value: "7" },
  ];

  function handleOnChange(item: { label: string; value: string }) {
    const arrayCopy = value;

    if (!!value.includes(item.value)) {
      onChange?.(value.filter((v) => v !== item.value));
    } else {
      arrayCopy?.push(item.value);
      onChange?.(arrayCopy);
    }
  }

  return (
    <Container style={style}>
      {weekdays.map((item, i) => (
        <WeekdayButton
          key={`${i}_${item.value}`}
          type="button"
          selected={value.includes(item.value)}
          onClick={() => handleOnChange(item)}
        >
          {item.label?.[0]}
        </WeekdayButton>
      ))}
    </Container>
  );
}
