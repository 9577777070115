import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsArrowLeftShort } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BatteryFull } from "../../../assets/icons/bi_battery-full.svg";
import { ReactComponent as BatteryLow } from "../../../assets/icons/bi_battery-low.svg";
import ActionChips from "../../../components/ActionChips";
import CustomThead from "../../../components/CustomThead";
import LineChart from "../../../components/LineChart";
import LinkAlert from "../../../components/LinkAlert";
import LinkGoBack from "../../../components/LinkGoBack";
import MapModal from "../../../components/MapModal";
import SensorFilterModal, {
  SensorFilterEvent,
} from "../../../components/SensorFilterModal";
import TableFooter from "../../../components/TableFooter";
import { Sensor, SensorFilter } from "../../../core/interfaces/Sensors";
import {
  SensorChart,
  SensorService,
} from "../../../core/services/SensorsService";
import { isErrorResult } from "../../../core/utils/api";
import PrivateMasterPage from "../../../layout/PrivateMasterPage";
import { Container } from "../styles";

export default function SensorDetail() {
  const params = useParams();
  const { id } = params || {};

  const [chartData, setChartData] = useState<
    { label: string; value: number }[]
  >([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [showMap, setShowMap] = useState(false);
  const [centerMap, setCenterMap] = useState({ lat: 1, lng: 1 });
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    sensor_id: string | undefined;
    order?: { [key: string]: string };
  }>({ page: 1, pages: 0, sensor_id: id, filter: "", order: 
  {"date_time":"desc"} });

  type Register = Sensor & {
    isEdited?: boolean;
    isSelected?: boolean;
  };

  type FormValues = {
    registers: Register[];
  };

  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  function getBattery(level?: number) {
    if (!level) return <BatteryLow />;
    if (level >= 15) return <BatteryFull />;
    if (level < 15) return <BatteryLow />;
  }

  function getFormatSensor(value: number, type: number) {
    if (value === undefined) return null;
    switch (type) {
      case 1:
        return `${value.toFixed(2)}°C`;
      case 2:
        return `${value} Km/h`;
      case 3:
        return `${value}%`;
      case 4:
        return `${value}%`;
      case 7:
          return `${value}%`;
      default:
        return `${value}`;
    }
  }
  function getFormatSensorChart(value: number, type: number) {
    if (value === undefined) return null;
    switch (type) {
      case 1:
        return `${value.toFixed(0)}°C`;
      case 2:
        return `${value}`;
      case 3:
        return `${value}%`;
      case 4:
        return `${value}%`;
      case 7:
          return `${value}%`;
      default:
        return `${value}`;
    }
  }

  async function loadSensor({
    sensor_id = id,
    page = 1,
    order,
    filter,
  }: SensorFilter) {
    setLoading(true);
    try {
      const response = await SensorService.get({
        sensor_id,
        page,
        order,
        filter,
      });
      if (!isErrorResult(response) && response.data) {
        replace(response.data);
        setPagination((prev) => ({
          ...prev,
          page: response?.meta?.current_page || 1,
          pages: response?.meta?.last_page || 0,
        }));
      }
      const responseChart = await SensorChart.get({ sensor_id, page, order, filter });
      if (!isErrorResult(responseChart) && responseChart.data) {
        //@ts-ignore
        const newArray = responseChart.data.sensors.map((obj) => {
          return {
            label: obj.date_time,
            value: obj.value,
          };
        });
        setChartData(newArray.reverse());
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  function onChangeFilter(options: SensorFilterEvent) {
    const { period } = options;
    const filter: any = {
      ...pagination.filter,
      start_date: period.from,
      end_date: period.to,
    };

    if (!period?.from) delete filter.start_date;
    if (!period?.to) delete filter.end_date;

    setPagination((prev) => ({ ...prev, filter }));
  }

  function haveEditedItems(): boolean {
    return !!watch("registers")?.find((item) => !!item?.isEdited);
  }

  function handleShowMap(lat: number, lng: number) {
    setCenterMap({
      lat: lat,
      lng: lng,
    });
    setShowMap(true);
  }

  useEffect(() => {
    loadSensor(pagination);
  }, [
    pagination.page,
    pagination.order,
    pagination.sensor_id,
    pagination.filter,
  ]);

  return (
    <PrivateMasterPage
      title={t("sensors.title")}
      breadcrumb={[t("sensors.title"), `${id}`]}
    >
      <Container fluid className="p-0">
        <Card className="m-3">
          <Card.Body className="p-4">
            <Row className="mb-4">
              <Col xs={7}>
                <LinkGoBack onClick={() => navigate(-1)}>
                  <Stack direction="horizontal" gap={1}>
                    <BsArrowLeftShort size={24} />
                    {t("link.go_back")}
                  </Stack>
                </LinkGoBack>
              </Col>
              <Col xs={5} className="sensor-detail-actions">
                <Stack
                  className="justify-content-end"
                  direction="horizontal"
                  gap={2}
                >
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowFilter(true)}
                  >
                    <FaFilter size={18} />
                  </Button>
                  {/* <Button variant="outline-secondary">
                    <IcImport />
                  </Button>
                  <Button variant="outline-secondary">
                    <ICMore />
                  </Button> */}
                </Stack>
              </Col>

              <Col xs={2}>
                <div>{t("sensors.sonsor_id")}</div>
                <div className="text-dark">{id}</div>
              </Col>
              <Col xs={10} className="d-flex align-items-end">
                <div className="alert-level">{t("sensors.chart_label")}</div>
              </Col>

              <Col className="mt-3" xs={2}>
                <Card className="temperature">
                  <div className="title">{fields[0]?.type?.description}</div>
                  <div className="value">
                    {getFormatSensorChart(chartData[chartData?.length - 1]?.value, fields[0]?.type?.id)}
                  </div>
                  <div className="label text-center">
                    {t("sensors.last_update")}
                  </div>
                  <div className="date text-center">
                    {moment(chartData[chartData?.length - 1]?.label).format(
                      "DD/MM/YYYY - HH:mm"
                    )}
                  </div>
                </Card>
              </Col>
              <Col className="mt-3" xs={10}>
                <Card
                  className="chart w-100 pt-3 pb-1 pe-2"
                  style={{ height: 226 }}
                >
                  <LineChart
                    data={chartData?.map((obj) => {
                      return {
                        label: moment(obj.label).format("DD/MM/YYYY - HH:mm"),
                        value: obj.value,
                      };
                    })}
                  />
                </Card>
              </Col>
            </Row>

            <Table>
              <CustomThead
                data={[
                  { column: "date_time", name: t("sensor_detail.table_date_time") },
                  {
                    column: "vehicle_license_plate",
                    name: t("sensor_detail.table_vehicle"),
                  },
                  { column: "value", name: t("sensor_detail.table_read") },
                  { column: "", name: t("sensor_detail.table_battery") },
                  // { column: "", name: t("sensor_detail.table_route") },
                  { column: "", name: t("sensor_detail.table_location") },
                  { column: "driver", name: t("sensor_detail.table_driver") },
                  { column: "", name: t("sensor_detail.table_alert") },
                ]}
                onChangeOrder={(order) =>
                  setPagination((prev) => ({ ...prev, order }))
                }
              />
              <tbody>
                {fields.map((item, index) => (
                  <tr key={`sensor_${index}`}>
                    <td>
                      {moment(item?.date_time).format("DD/MM/YYYY - HH:mm")}
                    </td>
                    <td>{item?.vehicle?.license_plate}</td>
                    <td>
                      {getFormatSensor(item?.value, item?.sensor_type_id)}
                    </td>
                    <td>
                      {getBattery(Number(item?.battery_percentage))}{" "}
                      {Number(item?.battery_percentage).toFixed(1) + "%" || "-"}
                    </td>
                    {/* <td>
                      <ActionChips
                        color="#0062FF"
                        backgroundColor="rgba(0, 98, 255, 0.1)"
                      >
                        {t("sensor_detail.table_view_route")}
                      </ActionChips>
                    </td> */}
                    <td>
                      <ActionChips
                        onClick={() =>
                          handleShowMap(item.latitude, item.longitude)
                        }
                      >
                        {t("sensor_detail.table_view_map")}
                      </ActionChips>
                    </td>
                    <td>{item?.driver_name}</td>
                    <td>
                      {item?.alerts.length ? <LinkAlert></LinkAlert> : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row>
              <Col className="d-flex justify-content-center">
                {isLoading && <Spinner animation="border" />}
              </Col>
            </Row>
          </Card.Body>

          <TableFooter
            actions={{
              hidden: !haveEditedItems(),
              titleSubmit: isSubmitting ? t("status.saving") : t("button.save"),
              titleCancel: t("button.cancel"),
              isSubmitting,
            }}
            paginate={{
              current_page: pagination.page,
              qtd: pagination.pages,
              onSelectPage: (page) =>
                setPagination((prev) => ({ ...prev, page })),
            }}
          />
        </Card>

        <SensorFilterModal
          visible={showFilter}
          onClose={() => setShowFilter(false)}
          onChange={onChangeFilter}
          sensorDetail
        />

        <MapModal
          visible={showMap}
          onClose={() => setShowMap(false)}
          center={centerMap}
          sensorId={id ?? ""}
        />
      </Container>
    </PrivateMasterPage>
  );
}
