import { HTMLAttributes } from "react";
import { Container, Status, Text } from "./styles";

interface StatusChipsProps extends HTMLAttributes<HTMLDivElement> {
  children?: string;
  statusColor?: string;
}

export default function StatusChips({
  children,
  statusColor,
  ...props
}: StatusChipsProps) {
  return (
    <Container {...props}>
      <Status style={statusColor ? { backgroundColor: statusColor } : {}} />
      <Text>{children}</Text>
    </Container>
  );
}
