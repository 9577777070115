import styled from "styled-components";

export const ModalStyles = styled.div`
  height: 100%;

  .pr-4 {
    padding-right: 56px;
  }

  .modal_subtitle {
    color: #6c757d;
  }

  .text-right {
    text-align: right;
  }

  .line-text-decoration-line-through {
    text-decoration: line-through;
  }

  .link {
    color: #2f80ed;
    font-size: 16px;
    margin: 0;
    border: 0;
    text-decoration: underline;
    background: none;
  }

  .change_qty_button {
    border: 1px solid #ced4da;
    background: none;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quantity {
    width: 107px;
    height: 36px;
    color: #6c757d;
    border: 1px solid #ced4da;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quantity_value {
    font-size: 28px;
    font-weight: 500;
    line-height: 33.41px;
    color: #6c757d;
    text-align: right;
    display: block;
  }
`;
