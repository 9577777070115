import { api } from "."

interface ISendRecoverPasswordEntry{
    email:string
}

export async function sendRecoverPassword({email}:ISendRecoverPasswordEntry){
    try{
        const {data} = await api.post('/api/v2/forget-password',{
            email
        })
        return data
    }catch(err:any){
        console.error(err)
        throw new Error(err)
    }
}