/*
1	Porta do motorista	LEFT_DOOR	entry
2	Porta do carona	RIGHT_DOOR	entry
3	Quinta roda	FIFTH_WHEEL	entry
4	Porta Baú lateral	SIDE_DOOR	entry
5	Porta Baú traseira	FLUSH_DOOR	entry
6	Painel	PANEL	entry
7	Tela de janela	PANEL_NARROW	entry
8	Engate	COUPLING	entry
9	Porta do trailer	TRAILER_DOOR	entry
10	Pânico	PANIC_BUTTON	entry
11	Ignição	IGNITION	entry
12	Silo	SILO	entry
13	Bloqueio de combustível	FUEL_BLOCK	exit
14	Trava de baú lateral	SIDE_DOOR_LOCK	exit
15	Trava de baú traseira	FLUSH_DOOR_LOCK	exit
16	Trava da quinta roda	FIFTH_WHEEL_LOCK	exit
17	Sirene	SIREM_ALARM_LOCK	exit
18	Trava do trailer	TRAILER_DOOR_LOCK	exit
19	Porta baú principal	FLUSH_DOOR_MAIN	entry
*/

export const VEHICLE_SENSORS = {
  LEFT_DOOR: "LEFT_DOOR",
  RIGHT_DOOR: "RIGHT_DOOR",
  FIFTH_WHEEL: "FIFTH_WHEEL",
  SIDE_DOOR: "SIDE_DOOR",
  FLUSH_DOOR: "FLUSH_DOOR",
  PANEL: "FLUSH_DOOR",
  PANEL_NARROW: "PANEL_NARROW",
  COUPLING: "COUPLING",
  TRAILER_DOOR: "TRAILER_DOOR",
  PANIC_BUTTON: "PANIC_BUTTON",
  IGNITION: "IGNITION",
  SILO: "SILO",
  FUEL_BLOCK: "FUEL_BLOCK",
  SIDE_DOOR_LOCK: "SIDE_DOOR_LOCK",
  FLUSH_DOOR_LOCK: "FLUSH_DOOR_LOCK",
  FIFTH_WHEEL_LOCK: "FIFTH_WHEEL_LOCK",
  SIREM_ALARM_LOCK: "SIREM_ALARM_LOCK",
  TRAILER_DOOR_LOCK: "TRAILER_DOOR_LOCK",
  FLUSH_DOOR_MAIN: "FLUSH_DOOR_MAIN",
};
