import { ISkeletonProps } from "./ISkeletonProps"
import { SkeletonContainer } from "./styles"

export const Skeleton = ({height = '100%', width = '100%', isLoading, children }: ISkeletonProps) => {

    if (isLoading) {
        return (
            <SkeletonContainer $width={width} $height={height} />
         
        )
    }
    return (
        <>
            {children}
        </>
    )
}