import { Container as BSContainer, Card as BSCard } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(BSContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding-left: 0;

  .form {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Card = styled(BSCard)`
  padding: 2rem;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c757d;
`;

export const LinkGoBack = styled.a`
  align-self: flex-start;
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  color: #6c757d;

  &:hover {
    cursor: pointer;
  }
`;

export const AddButton = styled.button`
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  background-color: white;
  border-radius: 20px;
  border-width: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  color: #202c4a;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f1f1f1;
    transition: background-color 0.2s ease;
  }
`;

export const CardTitle = styled.h5`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #6c757d;
`;

export const AutocompleteFieldList = styled.div`
  display: flex;
  padding-top: 1.5rem;
  flex-direction: column;
`;

export const AutocompleteField = styled.div`
  display: flex;
  width: 100%;
  height: 2.5rem;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 0 0.5rem;
  align-items: center;

  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const AutocompleteFieldName = styled.h5`
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #6c757d;
  margin-left: 8px;
`;

export const ActionButton = styled.button`
  height: 32px;
  width: 32px;
  color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-width: 0;
  background-color: transparent;

  &:hover {
    color: #b72026;
    transition: color 0.3s ease;
  }
`;
