import React, { HTMLAttributes } from "react";
import { Container } from "./styled";

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactElement | React.ReactElement[] | string | string[];
}

export default function Footer({ children, ...props }: FooterProps) {
  return <Container {...props}>{children}</Container>;
}
