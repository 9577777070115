import React, { useState } from "react";
import { BsPauseFill, BsPlayFill } from "react-icons/bs";
import { TrackingMessage } from "../../../core/interfaces/TrackingMessage";

// import { Container } from './styles';

type AudioMessageProps = {
  name: string;
  message: TrackingMessage;
  me: boolean;
};

const AudioMessage: React.FC<AudioMessageProps> = ({ name, me, message }) => {
  const [playing, setPlaying] = useState(false);
  const [time, setTime] = useState<number | undefined>(undefined);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  //   function onLoad(data: any) {
  //     console.log("AudioMessage onLoad", data);
  //   }

  //   function updateTime(data: any) {
  //     const interval = setInterval(() => {
  //       const time = audioRef?.current?.duration;

  //       console.log("AudioMessage updateTime", {
  //         data,
  //         ref: audioRef?.current,
  //         time,
  //       });

  //       if (time && Number.isFinite(time) && time >= 0) {
  //         setTime(time);
  //         clearInterval(interval);
  //       }
  //     }, 500);
  //   }

  //   useEffect(() => {
  //     console.log("AudioMessage useEffect", message);

  //     if (time === undefined && audioRef.current) {
  //       audioRef.current.play();
  //       audioRef.current.volume = 0;
  //     } else if (audioRef.current) {
  //       audioRef.current.pause();
  //       audioRef.current.volume = 1;
  //     }

  //     // audioRef?.current?.load();
  //     // audioRef?.current?.load();
  //   }, [time]);

  function secondsToTime(seconds: number) {
    const _minutes = seconds / 60;
    const _seconds = seconds % 60;

    return `${Math.floor(_minutes)?.toString().padStart(2, "0")}:${Math.floor(
      _seconds
    )
      ?.toString()
      .padStart(2, "0")}`;
  }

  function onTimeUpdate(data: any) {
    console.log("AudioMessage onTimeUpdate", { data });
    setTime(audioRef.current?.currentTime);
  }

  function toggleAudio() {
    if (
      audioRef.current &&
      (audioRef.current.paused || audioRef.current.ended)
    ) {
      audioRef.current.play();
      setPlaying(true);
    } else if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause();
      setPlaying(false);
    }
  }

  return (
    <div className={`media-box ${me ? "highlight" : ""}`}>
      <div>
        {!playing && <BsPlayFill size={33} onClick={() => toggleAudio()} />}
        {playing && <BsPauseFill size={33} onClick={() => toggleAudio()} />}

        <div className="d-flex flex-column">
          <label>Mensagem de Audio</label>
          <span>{time ? secondsToTime(time) : "00:00"}</span>
        </div>
        <audio
          ref={audioRef}
          src={message.attachment_url}
          onTimeUpdate={onTimeUpdate}
          onEnded={() => setPlaying(false)}
          preload="metadata"
        >
          Audio tag not supported in this browser
        </audio>
      </div>
    </div>
  );
};

export default AudioMessage;
