import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
// import { TrackingChangeVehicle } from "../../interfaces/TrackingChangeVehicle";
import type { RootState } from "../store";
import { TrackingChangeVehicle } from "../../interfaces/TrackingChangeVehicle";

// Define a type for the slice state
interface TrackingChangeVehicleState extends Partial<TrackingChangeVehicle> {}

// Define the initial state using that type
const initialState: TrackingChangeVehicleState = {
  trackingId: undefined,
  show: false,
};

interface ReducerActions extends SliceCaseReducers<TrackingChangeVehicleState> {
  updateTrackingChangeVehicle(
    state: TrackingChangeVehicleState,
    action: PayloadAction<TrackingChangeVehicle>
  ): void;
  hideTrackingChangeVehicle(state: TrackingChangeVehicleState): void;
}

export const alertSlice: Slice<TrackingChangeVehicleState, ReducerActions> =
  createSlice({
    name: "tracking",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      // Use the PayloadAction type to declare the contents of `action.payload`
      updateTrackingChangeVehicle: (
        state,
        action: PayloadAction<TrackingChangeVehicle>
      ) => {
        state.trackingId = action.payload.trackingId;
        state.show = action.payload.show;
      },
      hideTrackingChangeVehicle: (state) => {
        state.trackingId = undefined;
        state.show = false;
      },
    },
  });

export const { updateTrackingChangeVehicle, hideTrackingChangeVehicle } = alertSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getTrackingChangeVehicle = (state: RootState) => state.tracking_change_vehicle;
export const getTrackingId = (state: RootState) => {
  return state.tracking_change_vehicle.trackingId;
};

export default alertSlice.reducer;
