import styled from "styled-components";

export const Container = styled.div`
  .filters {
    & > div {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
`;
