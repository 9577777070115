import { useTranslation } from "react-i18next";
import {format} from "date-fns";


export function useFormatDate(){
    const { i18n } = useTranslation();

    const getDateLocale = (): string => {
      const locales: any = {
        pt: 'pt-BR',
        en: 'en-US',
        fr: 'fr-FR',
      };
  
      return locales[i18n.language];
  };

  function validateDateFormatAndTime(date:string, hasHour?:boolean){
    if(!date) return '00:00'
  
    const hours = date.split(' ')[1]

    if(hasHour){
     return new Intl.DateTimeFormat(getDateLocale()).format(new Date(date)) + ' ' + format(new Date(date), 'HH:mm')
    }

    if(hours){
      return new Intl.DateTimeFormat(getDateLocale()).format(new Date(date)) + ' ' + date.split(' ')[1]
    }

    return new Intl.DateTimeFormat(getDateLocale()).format(new Date(date))
  }

  return {
    validateDateFormatAndTime,
    getDateLocale
  }
}
