import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Row,
  Spinner,
  Stack,
  Table,
} from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFilter } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";
import { ActionButtonOutline } from "../../components/ActionButtonOutline";
import AddButton from "../../components/AddButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomSearchInput from "../../components/CustomSearchInput";
import CustomThead from "../../components/CustomThead";
import ListEmpty from "../../components/ListEmpty";
import TableActions from "../../components/TableActions";
import TableFooter from "../../components/TableFooter";
import VehicleFilterModal, {
  VehicleFilterEvent,
} from "../../components/VehicleFilterModal";
import { APiQueryBase } from "../../core/interfaces/ApiResponseBase";
import { Vehicle } from "../../core/interfaces/Vehicle";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { updateAlert } from "../../core/redux/reducer/alert";
import { VehicleService } from "../../core/services/VehicleService";
import { isErrorResult } from "../../core/utils/api";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { convertToCurrency } from "../../utils/currency";
import { Container } from "./styles";
import { getUser } from "../../core/redux/reducer/auth";
import { useFormatDate } from "../../core/hooks/useFormatDate";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";

type Register = Vehicle & {
  isEdited?: boolean;
  isSelected?: boolean;
};

type FormValues = {
  registers: Register[];
};

const Vehicles: React.FC = () => {
  const [deleteId, setDeleteId] = useState<number | false>(false);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order?: { [key: string]: string };
  }>({ page: 1, pages: 0, filter: "" });
  const [showFilter, setShowFilter] = React.useState(false);
  const [isBusy, setBusy] = React.useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useAppSelector(getUser);
  const { validateDateFormatAndTime } = useFormatDate();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function loadVehicles({ filter = "", page = 1, order }: APiQueryBase) {
    setLoading(true);
    try {
      const response = await VehicleService.get({ filter, page, order });

      if (!isErrorResult(response) && response.data) {
        replace(response.data);
        console.log(response);
        if (response.meta.current_page > response.meta.last_page) {
          setPagination((prev) => ({
            ...prev,
            page: response?.meta?.current_page - 1 || 1,
            pages: response?.meta?.last_page || 0,
          }));
          return;
        }
        setPagination((prev) => ({
          ...prev,
          page: response?.meta?.current_page || 1,
          pages: response?.meta?.last_page || 0,
        }));
      }
    } catch (error) {
      console.log("loadUsers error", error);
    } finally {
      setLoading(false);
    }
  }

  async function onSubmitUpdate({ registers }: FormValues) {
    try {
      for await (let data of registers) {
        if (!!data?.id && data?.isEdited) {
          const response = await VehicleService.update(data);
          if (!response?.success) throw Error(response?.message);

          const i = registers.findIndex((item) => item?.id === data?.id);
          data.isEdited = false;
          registers[i] = { ...data };
        }
      }

      reset({ registers });

      dispatch(updateAlert({ title: t("message.success.save_data") }));
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  }

  async function onSubmitDeleteMany({ registers }: FormValues) {
    if (isBusy) return;
    setBusy(true);

    try {
      for await (let data of registers) {
        if (!!data.id && data?.isSelected) {
          const response = await VehicleService.delete(data.id);
          if (!response?.success) throw Error(response?.message);
        }
      }

      loadVehicles(pagination);
      dispatch(updateAlert({ title: t("message.success.delete_many_data") }));
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.delete_many_data"),
          message: error.message,
          type: "error",
        })
      );
    } finally {
      setBusy(false);
      setDeleteMany(false);
    }
  }

  async function onSubmitDelete() {
    if (!deleteId || isBusy) return;
    setBusy(true);

    const response = await VehicleService.delete(deleteId);
    if (response?.success) {
      setBusy(false);
      setDeleteId(false);
      dispatch(updateAlert({ title: t("message.success.delete_many_data") }));
      loadVehicles(pagination);
    } else {
      setBusy(false);
      setDeleteId(false);
      dispatch(
        updateAlert({
          title: t("message.error.delete_data"),
          message: response.message,
          type: "error",
        })
      );
    }
  }

  const handleEditCancel = async () => {
    // replace(fields);
    if (!watch("registers")?.find((item) => !!item?.isEdited)) return;
    await loadVehicles(pagination);
  };

  function handleSelectAll() {
    const data = watch("registers");
    const value = !getSelectedAll();

    const newDataList: any[] = data?.map((item) => {
      item.isSelected = value;
      return item;
    });

    replace(newDataList);
  }

  function getSelectedAll(): boolean {
    const data = watch("registers");
    let count = 0;

    data?.forEach((item) => {
      if (!!item?.isSelected) count++;
    });

    return data?.length === count && data?.length >= 1;
  }

  function getSelected(): boolean {
    const data = watch("registers");
    return !!data?.find((item) => item?.isSelected === true);
  }

  function haveEditedItems(): boolean {
    return !!watch("registers")?.find((item) => !!item?.isEdited);
  }

  const actions = {
    edit: t("text.edit"),
    delete: t("text.delete"),
  };
  const actionOptions = Object.values(actions);

  function handleSelectAction(action: string, data: Vehicle) {
    if (action === actions.delete) {
      setDeleteId(data.id);
    } else if (action === actions.edit) {
      navigate("/vehicles/edit", { state: { data } });
    }
  }

  function onSearch(text: string): void {
    const filter = { ...pagination.filter, search: text };
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onChangeFilter(options: VehicleFilterEvent) {
    const { tags = [], vehicle_type } = options;
    const filter: any = {
      ...pagination.filter,
      vehicle_type_id: !!vehicle_type ? [vehicle_type] : [],
      vehicle_tags_id: tags.map((element) => element.id),
    };

    if (!vehicle_type) delete filter.vehicle_type_id;
    if (!tags?.length) delete filter.vehicle_tags_id;
    setPagination((prev) => ({ ...prev, filter }));
  }

  React.useEffect(() => {
    loadVehicles(pagination);
  }, [pagination.filter, pagination.page, pagination.order, user?.company_id]);

  return (
    <PrivateMasterPage
      title={t("vehicles.title")}
      breadcrumb={[t("vehicles.title")]}
    >
      <ProtectedComponent
        module_name={module_names.VEHICLES}
        property="view"
        fallbackComponent={<Navigate to={"/drivers"} />}
      >
        <Container>
          <Card className="m-3">
            <Card.Body className={(!fields?.length && "flex-none") + " p-4"}>
              <Row className="mb-4">
                <Col className="d-flex align-items-center" xl={3} lg={3} md={2}>
                  <AddButton onClick={() => navigate("/vehicles/new")}>
                    {t("vehicles.button_add")}
                  </AddButton>
                </Col>
                <Col xl={9} lg={9} md={10}>
                  <Stack
                    className="d-flex justify-content-end"
                    direction="horizontal"
                    gap={3}
                  >
                    <Button
                      variant="link"
                      disabled={!getSelected()}
                      className="d-flex gap-2 align-items-center"
                      onClick={() => setDeleteMany(true)}
                    >
                      <IoTrashOutline size={20} />
                      {t("vehicles.delete_many_text")}
                    </Button>

                    <CustomSearchInput
                      style={{ maxWidth: "30%" }}
                      placeholder={t("search")}
                      onChange={(e) => onSearch(e.target.value)}
                    />

                    {/* <ActionButtonOutline>
                    <FiDownload size={20} />
                  </ActionButtonOutline>

                  <ActionButtonOutline>
                    <FiUpload size={20} />
                  </ActionButtonOutline> */}

                    <ActionButtonOutline onClick={() => setShowFilter(true)}>
                      <FaFilter size={18} />
                    </ActionButtonOutline>
                  </Stack>
                </Col>
              </Row>

              <Table responsive hover>
                <CustomThead
                  checkbox
                  selectedAll={getSelectedAll()}
                  onChangeSelectAll={handleSelectAll}
                  data={[
                    { column: "type", name: t("vehicles.table_type") },
                    {
                      column: "license_plate",
                      name: t("vehicles.table_board"),
                    },
                    {
                      column: "created_at",
                      name: t("vehicles.table_created_at"),
                    },
                    {
                      column: "value_by_km",
                      name: t("vehicles.table_price_by_km"),
                    },
                    { column: "hardware", name: t("vehicles.table_hardware") },
                    {
                      column: "active",
                      name: t("vehicles.table_availability"),
                    },
                    { column: "menu", name: <th />, menu: true },
                  ]}
                  onChangeOrder={(order) => {
                    setPagination((prev) => ({ ...prev, order }));
                  }}
                />
                <tbody>
                  {fields?.map((item, index) => (
                    <tr key={`${index}_${item?.id}_${item?.fieldId}`}>
                      <td>
                        <FormCheck
                          id={`${item?.fieldId}_selected`}
                          defaultChecked={false}
                          label={item?.vehicle_type_description}
                          {...register(`registers.${index}.isSelected`)}
                        />
                      </td>
                      <td>{item?.license_plate?.toUpperCase()}</td>
                      <td>{validateDateFormatAndTime(item?.created_at)}</td>
                      <td>{convertToCurrency(item?.value_by_km)}</td>
                      <td>FleetMate</td>

                      <td>
                        <Form.Switch
                          id={`${item?.fieldId}_active`}
                          defaultChecked={false}
                          {...register(`registers.${index}.active`, {
                            onChange: (e) => {
                              setValue(`registers.${index}.isEdited`, true);
                            },
                          })}
                        />
                      </td>
                      <td>
                        <TableActions
                          id={item?.id}
                          items={actionOptions}
                          onSelectAction={(action) =>
                            handleSelectAction(action, item)
                          }
                          module_name={module_names.VEHICLES}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Row>
                <Col className="d-flex justify-content-center">
                  {isLoading && <Spinner animation="border" />}
                </Col>
              </Row>
            </Card.Body>

            <ListEmpty hidden={fields?.length >= 1}>
              {t("vehicles.empty_list.message")}
              <AddButton
                style={{ flexDirection: "column", gap: 5 }}
                onClick={() => navigate("/vehicles/new")}
              >
                {t("vehicles.button_add")}
              </AddButton>
            </ListEmpty>

            <TableFooter
              actions={{
                hidden: !haveEditedItems(),
                titleSubmit: isSubmitting
                  ? t("status.saving")
                  : t("button.save"),
                titleCancel: t("button.cancel"),
                isSubmitting,
                onSubmit: handleSubmit(onSubmitUpdate, console.log),
                onCancel: handleEditCancel,
              }}
              paginate={{
                current_page: pagination.page,
                qtd: pagination.pages,
                onSelectPage: (page) =>
                  setPagination((prev) => ({ ...prev, page })),
              }}
            />
          </Card>
        </Container>
      </ProtectedComponent>

      <VehicleFilterModal
        visible={showFilter}
        onClose={() => setShowFilter(false)}
        onChange={onChangeFilter}
      />

      <ConfirmationModal
        visible={!!deleteId}
        title={t("vehicles.confirm_delete_title")}
        onClose={() => setDeleteId(false)}
        onConfirm={onSubmitDelete}
        isLoading={isBusy && !!deleteId}
      />

      <ConfirmationModal
        visible={deleteMany}
        title={t("vehicles.confirm_delete_many_title")}
        onClose={() => setDeleteMany(false)}
        onConfirm={handleSubmit(onSubmitDeleteMany, console.log)}
        isLoading={isBusy && deleteMany}
      />
    </PrivateMasterPage>
  );
};

export default Vehicles;
