import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const user_invitation = yup
  .object({
    name: yup.string().max(150).required(),
    email: yup.string().email().max(130).required(),
    user_profile_id: yup.string().required(),
  })
  .required();

export const UserInvitationResolver = yupResolver(user_invitation);
