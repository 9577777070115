import { Row } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(Row)`
  .btn {
    display: flex;
    align-items: center;
    border-color: #ced4da;
  }

  .btn:focus {
    outline: none;
    /* box-shadow: 0 0 0 0.05rem rgba(108, 117, 125, 0.5); */
  }

  .btn:hover {
    background: transparent;
    color: #6c757d;
  }

  .btn svg {
    margin-right: 9px;
  }

  .title {
    color: #6c757d;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
  .code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    background: #f2f2f2;
    border-radius: 11px;
    padding: 0 71px 0 19px;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;
    }

    div svg {
      margin-left: 8px;
    }
  }
`;
