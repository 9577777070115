import { Control, UseFormRegister } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "./styles";
import { useFleetMate } from "../../../core/hooks/useFleetMate";
import { FleetMateConfigs } from "../../../core/interfaces/FleetMate";

interface IntervalsProps {
  control: Control<FleetMateConfigs>;
  register: UseFormRegister<FleetMateConfigs>;
}

export default function Intervals({ control, register }: IntervalsProps) {
  const { t } = useTranslation();
  const {
    timeOptionsLoRaWANIgnitionOn,
    timeOptionsLoRaWANIgnitionOff,
    timeOptionsIridiumIgnitionOn,
    timeOptionsIridiumIgnitionOff,
  } = useFleetMate();

  return (
    <Row>
      <Col className="mb-3" xl={6} md={12}>
        <Row>
          <Col className="mb-3">
            <SectionTitle>
              {t("configs.fleetmate.setting.interval_lorawan")}
            </SectionTitle>
          </Col>
        </Row>

        <Row>
          <Form.Group
            as={Col}
            controlId="lorawanNetworkCommunicationIntervals.ignitionOn"
          >
            <Form.Label>
              {t("configs.fleetmate.setting.ignition_on")}
            </Form.Label>
            <Form.Select
              {...register("lorawanNetworkCommunicationIntervals.ignitionOn")}
            >
              {timeOptionsLoRaWANIgnitionOn.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group
            as={Col}
            controlId="lorawanNetworkCommunicationIntervals.ignitionOff"
          >
            <Form.Label>
              {t("configs.fleetmate.setting.ignition_off")}
            </Form.Label>
            <Form.Select
              {...register("lorawanNetworkCommunicationIntervals.ignitionOff")}
            >
              {timeOptionsLoRaWANIgnitionOff.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
      </Col>

      <Col className="mb-3" xl={6} md={12}>
        <Row>
          <Col className="mb-3">
            <SectionTitle>
              {t("configs.fleetmate.setting.interval_iridium")}
            </SectionTitle>
          </Col>
        </Row>

        <Row>
          <Form.Group
            as={Col}
            controlId="iridiumNetworkCommunicationIntervals.ignitionOn"
          >
            <Form.Label>
              {t("configs.fleetmate.setting.ignition_on")}
            </Form.Label>
            <Form.Select
              {...register("iridiumNetworkCommunicationIntervals.ignitionOn")}
            >
              {timeOptionsIridiumIgnitionOn.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group
            as={Col}
            controlId="iridiumNetworkCommunicationIntervals.ignitionOff"
          >
            <Form.Label>
              {t("configs.fleetmate.setting.ignition_off")}
            </Form.Label>
            <Form.Select
              {...register("iridiumNetworkCommunicationIntervals.ignitionOff")}
            >
              {timeOptionsIridiumIgnitionOff.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
      </Col>
    </Row>
  );
}
