import React, { useEffect, useRef, useCallback, CSSProperties } from "react";
import { Container } from "./styles";

interface DoubleRangeSliderProps {
  min?: number;
  max?: number;
  value?: { min: number; max: number };
  onChange?(value: { min: number; max: number }): void;
  styles?: CSSProperties;
}
export default function DoubleRangeSlider({
  min = 0,
  max = 10,
  value = { min, max },
  onChange,
  styles,
}: DoubleRangeSliderProps) {
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef<any>(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(value.min);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [value.min, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(value.max);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [value.max, getPercent]);

  return (
    <Container style={styles}>
      <input
        type="range"
        min={min}
        max={max}
        value={value.min}
        onChange={(event) => {
          const _value = Math.min(Number(event.target.value), value.max - 1);
          onChange?.({ ...value, min: _value });
          minValRef.current = _value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: value.min > max - 100 ? "5" : "4" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={value.max}
        onChange={(event) => {
          const _value = Math.max(Number(event.target.value), value.min + 1);
          onChange?.({ ...value, max: _value });
          maxValRef.current = _value;
        }}
        className="thumb thumb--right"
      />
      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>
    </Container>
  );
}
