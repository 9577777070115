import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  th {
    text-transform: uppercase;
  }

  td:last-child {
    width: 100px;
  }
`;