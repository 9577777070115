import { isSameDay, parse } from "date-fns";
import styled from "styled-components";
const colStartClasses = ["", "2", "3", "4", "5", "6", "7"];

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  background: white;
  padding: 30px 10px 10px 10px;
  min-width: 295px;
  z-index: 999;
`;

export const CalendarButtonState = styled.button`
  height: 52px;
  width: 100%;
  background-color: #f2f2f2 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TimerSelector = styled.div`
  display: flex;
  flex: column;
  gap: 10px;
  align-items: center;

  & .timer_input {
    border: 0;
    text-align: center;
    font-weight: 700;
    width: 27px;
  }
  & .timer_input:focus {
    outline: none;
    border: 0;
    box-shadow: none;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  color: black;
  margin: 30px 0;
`;
export const CalendarContainer = styled.div`
  width: 100%;
`;

export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
`;

export const CalendarHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
  font-weight: 500;
  width: 100%;
  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6c757d;
  }
  & button {
    border: 1px solid #e2e2ea !important;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    position: relative;
  }
  & button > svg {
    position: absolute;
    inset: 0;
    margin: auto;
  }
  & > div > div > button:hover {
    background: transparent !important;
    border: 1px solid #e2e2ea !important;
  }
  & > div > div > button:active {
    background: transparent;
    border: 1px solid #e2e2ea;
  }
`;

export const CalendarWeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 30px;
  font-size: 12px;
  color: #92929d;
  font-weigth: semibold;
  text-align: center;
`;
export const CalendarDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 17px;
  font-size: 12px;
`;
export const CalendarDayWrapper = styled.div<{
  $isToday: boolean;
  $day: Date;
  $dayIdx: number;
  $dayOfWeek: number;
  $isSameMonth: boolean;
  $selectedDate: Date | null | "Invalid Date";
}>`
  padding: 10px 0;
  grid-column-start: ${(props) =>
    props.$dayIdx === 0 && colStartClasses[props.$dayOfWeek]};
  & button {
    color: ${(props) =>
      props.$selectedDate && props.$isSameMonth ? "black" : "#171725"};
    border: ${(props) =>
      props.$isToday ? "1px solid #2F80ED!important" : "0!important"};
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background: ${(props) =>
      isSameDay(props.$day, props.$selectedDate as Date) ? "#2F80ED" : ""};
    color: ${(props) =>
      isSameDay(props.$day, props.$selectedDate as Date) ? "white" : "black"};
    transition: all 0.1s ease-in-out;
  }
  & button:hover {
    background: ${(props) =>
      props.$day !== props.$selectedDate ? "#2F80ED" : ""};
    color: ${(props) =>
      props.$day !== props.$selectedDate ? "white" : "black"};
  }
  .dot-container {
    display: flex;
    justify-content: center;
    gap: 4px;
  }
  .dot-container .red-dot {
    width: 4px;
    height: 4px;
    background-color: red;
    border-radius: 100%;
  }
  .dot-container .blue-dot {
    width: 4px;
    height: 4px;
    background-color: #007bff;
    border-radius: 100%;
  }
`;
