import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  data: any;
  summary: any;
}

export default function Login({ data, summary }: IProps) {
  const { t } = useTranslation();
  const [sumarized, setSummarized] = useState(undefined);

  useEffect(() => {
    if (typeof data?.registry?.data === "string") {
      const parsedData = JSON.parse(data?.registry?.data);
      setSummarized(parsedData);
    }
  }, [summary]);

  return (
    <>
      <div className="item-group p-0">
        <Row>
          <Col>
            <label>{t("configs.audit.login_datetime")}</label>
            <p>
              {data?.registry?.created_at
                ? moment(data?.registry?.generated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.user")}</label>
            <p>
              {data?.user?.name
                ? data?.user?.name + " " + data?.user?.last_name
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group border-0">
        <Row>
          <Col>
            <label>{t("configs.audit.driver")}</label>
            <p>
              {data?.driver?.name
                ? data?.driver?.name + " " + data?.driver?.last_name
                : "--"}
            </p>
          </Col>
          <Col>
            <label>{t("configs.audit.plate")}</label>
            <p>{data?.vehicle ? data?.vehicle?.license_plate : "--"}</p>
          </Col>
        </Row>
      </div>
    </>
  );
}
