import { AnyAction, configureStore } from "@reduxjs/toolkit";
import auth from "./reducer/auth";
import alert from "./reducer/alert";
import user from "./reducer/user";
import tracking from "./reducer/tracking";
import tracking_comunication from "./reducer/tracking_comunication";
import vehicle from "./reducer/vehicle";
import tracking_change_vehicle from "./reducer/tracking_change_vehicle";
import routing from "./reducer/routing";
import plan_routing from "./reducer/plan_routing";

export const store = configureStore({
  reducer: {
    auth,
    alert,
    user,
    tracking,
    tracking_comunication,
    tracking_change_vehicle,
    vehicle,
    routing,
    plan_routing,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
