import { Tabs } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(Tabs)`
  & .nav-item:nth-child(1) {
    /* background-color: red; */
    margin-left: 49px;
  }

  .nav-item .nav-link {
    color: #939698;
    background-color: #fff;
    border-width: 1px;
    border-color: rgba(147, 150, 152, 0.25);
    font-weight: 500;
    padding: 10px 47px;

    &.active {
      color: #6c757d;
      border-color: rgba(147, 150, 152, 0.5);
      border-bottom-color: #fff;
    }
  }
`;
