import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import CustomInputTag from "../CustomInputTag";
import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { FaFilter } from "react-icons/fa";
import { VehicleTypeService } from "../../core/services/VehicleTypeService";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { SENSORS } from "../../constants/sensors";
import { AsyncPaginate } from "react-select-async-paginate";

type FormValues = {
  vehicle_type: { label: string; value: number };
  sensor_type: { label: string; value: number };
  tags: string[];
  period: { from: string; to: string };
};

export type SensorFilterEvent = {
  vehicle_type: number;
  sensor_type: number;
  tags: IVehicleTag[];
  period: { from: string; to: string };
};

interface SensorFilterModalProps {
  visible: boolean;
  onClose?(): void;
  onChange?(event: SensorFilterEvent): void;
  sensorDetail?: boolean;
}

const SensorFilterModal: React.FC<SensorFilterModalProps> = ({
  onClose,
  visible,
  onChange,
  sensorDetail,
  ...props
}) => {
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const { register, control, handleSubmit, reset } = useForm<FormValues>();

  const handleCancel = () => {
    reset();
    onClose?.();
  };

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const tags = vehicleTags?.filter((item) =>
      values?.tags?.includes(item.key)
    );
    onChange?.({
      ...values,
      tags,
      vehicle_type: values?.vehicle_type?.value,
      sensor_type: values?.sensor_type?.value,
    });
    onClose?.();
  };

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
    }
  }, [visible]);

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <FaFilter size={18} />
        <Modal.Title>{t("text.filter")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        <Form>
          {!sensorDetail && (
            <Row className="mt-4 mb-3">
              <Col lg={6} md={12}>
                <Form.Group controlId="vehicle_type">
                  <Form.Label>
                    {t("configs.fleetmate.ei.vehicle_type")}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="vehicle_type"
                    render={({ field: { value, onChange, onBlur } }) => (
                      <AsyncPaginate
                        isClearable
                        placeholder={t("input.select_text")}
                        loadOptions={(inputValue, options, additional) =>
                          VehicleTypeService.loadVehicleTypeOptions(
                            inputValue,
                            options,
                            additional
                          )
                        }
                        additional={{ page: 1 }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}

          {!sensorDetail && (
            <Row>
              <Col>
                <Form.Group className="mb-4" controlId="name" as={Col}>
                  <Form.Label>
                    {t("configs.fleetmate.ei.attributes_tag")}
                  </Form.Label>
                  <Controller
                    name="tags"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { value, onChange } }) => (
                      <CustomInputTag
                        defaultSelected={value}
                        onChange={(values: string[]) => onChange(values)}
                        options={vehicleTags?.map((op) => op.key)}
                        isLoading={isLoadingTags}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <Form.Label>{t("sensors.filter_last_read_label")}</Form.Label>
            </Col>
          </Row>

          <Row>
            <Col xs={4}>
              <Form.Group as={Col} className="mb-3" controlId="period.from">
                <Form.Label>{t("sensors.filter_start_date")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register("period.from")}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group as={Col} className="mb-3" controlId="period.to">
                <Form.Label>{t("sensors.filter_end_date")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register("period.to")}
                />
              </Form.Group>
            </Col>
            {!sensorDetail && (
              <Col xs={4}>
                <Form.Group as={Col} className="mb-3" controlId="sensor_type">
                  <Form.Label>{t("sensors.filter_sensor_type")}</Form.Label>
                  <Controller
                    control={control}
                    name="sensor_type"
                    render={({ field: { value, onChange, onBlur } }) => (
                      <AsyncPaginate
                        isClearable
                        placeholder={t("input.select_text")}
                        loadOptions={(inputValue: string): Promise<any> => {
                          const translatedOptions = SENSORS.map((sensor) => ({
                            ...sensor,
                            label: t(sensor.label),
                          }));

                          const filteredOptions = translatedOptions.filter(
                            (option) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                          );

                          return Promise.resolve({
                            options: filteredOptions,
                            hasMore: false,
                          });
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>

        <Row className="mt-2">
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button onClick={handleSubmit(onSubmit, console.log)}>
              {t("button.save")}
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SensorFilterModal;
