import styled from "styled-components";

export const BoxTooltip = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 36px rgba(118, 118, 118, 0.38));
  padding: 12px;

  p {
    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    margin: 0;
  }
`;
