import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Stack } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SubcontractorInvitationResolver } from "../../core/schemaValidations/subcontractor_invitation";
import { SubcontractorService } from "../../core/services/SubcontractorService";
import { isErrorResult } from "../../core/utils/api";
import { MaskService } from "../../utils/masks-service";

interface SubContractorInvitationModalProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}

type FormValues = {
  cnpj: string;
};

const SubContractorInvitationModal: React.FC<
  SubContractorInvitationModalProps
> = ({ visible = false, onClose, onSuccess, ...props }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<FormValues>({
    resolver: SubcontractorInvitationResolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const _result = await SubcontractorService.searchCompany({
        cnpj: MaskService.toRawValue("cnpj", data?.cnpj),
      });

      if (isErrorResult(_result)) {
        if (_result.errors?.cnpj)
          setError("cnpj", { message: t("message.error_other_email") });
        return;
      }

      const isDataFound = _result.data[0];

      if (!isDataFound) {
        setError("cnpj", { message: "Not found" });
        return;
      }

      await SubcontractorService.store(isDataFound.id);

      onSuccess?.();
    } catch (error: any) {
      alert(error);
    }
  };

  const onError = (error: any) => console.log("onSubmitError", error);

  useEffect(() => {
    if (!visible) reset();

    setIsVisible(visible);
  }, [visible]);

  return (
    <Modal {...props} show={isVisible} size="lg" centered onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="flex-grow-1 text-center">
          {t("configs.subcontractors.form_title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Row>
            <Form.Group className="mb-3" controlId="name" as={Col} xs="12">
              <Form.Label>{t("configs.company.cnpj")}</Form.Label>
              <Form.Control
                {...register("cnpj", {
                  onChange: (e) => MaskService.toInputMask("cnpj", e),
                })}
                placeholder="00.000.000/0000-00"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                isInvalid={!!errors?.cnpj?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.cnpj?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mt-4">
            <Stack
              className="d-flex justify-content-end"
              direction="horizontal"
              gap={2}
            >
              <Button type="submit">
                {isSubmitting ? t("status.sending") : t("button.confirm")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={onClose}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SubContractorInvitationModal;
