import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const typeOccurrence = yup
  .object({
    occurrences: yup.array(
      yup.object({
        occurrence: yup.string().required(),
      })
    ),
  })
  .required();

export const AppTypeOccurrenceResolver = yupResolver(typeOccurrence);
