import { BsBatteryFull, BsBatteryHalf, BsBattery } from "react-icons/bs";
import { IconBaseProps } from "react-icons";

interface BatteryIconProps extends IconBaseProps {
  percent: number;
}

export function BatteryIcon({
  percent,
  size = 20,
  ...props
}: BatteryIconProps) {
  if (!percent || percent === 0) {
    return <BsBattery {...props} size={size} />;
  } else if (percent > 0 && percent < 100) {
    return <BsBatteryHalf {...props} size={size} />;
  } else if (percent === 100) {
    return <BsBatteryFull {...props} size={size} />;
  } else {
    return <BsBattery {...props} size={size} />;
  }
}
