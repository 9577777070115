import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
`;

export const Separator = styled.div`
  width: 100%;
  border: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #ececec;
  margin-bottom: 1rem;
`;
