import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Container } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import Pagination from "../../components/Pagination";
import TableActions from "../../components/TableActions";
import CustomSearchInput from "../../components/CustomSearchInput";
import {
  ContractorService,
  GetContractor,
} from "../../core/services/ContractorService";
import { IContractor } from "../../core/interfaces/Contractor";
import Footer from "../../components/Footer";
import EmptyListMessage from "../../components/EmptyListMessage";
import { useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch } from "../../core/redux/hooks";
import ConfirmationModal from "../../components/ConfirmationModal";
import { updateAlert } from "../../core/redux/reducer/alert";
import { FaFilter } from "react-icons/fa";
import ContractorRestrictionsModal from "../../components/ContractorRestrictionsModal";
import CustomThead from "../../components/CustomThead";
import AssociateVehiclesAndTagsModal from "../../components/AssociateVehiclesAndTagsModal";
import { SubcontractorRestrictVehicleService } from "../../core/services/SubcontractorRestrictVehicleService";
import { SubcontractorService } from "../../core/services/SubcontractorService";
import { ISubcontractor } from "../../core/interfaces/Subcontractor";

type Register = IContractor & {
  // isAvailableEdited?: boolean;
  isAdvancedControlEdited?: boolean;
};

type FormValues = {
  registers: Register[];
};

const ConfigsContractors: React.FC = () => {
  const [isBusy, setBusy] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | false>(false);
  const [editId, setEditId] = useState<number | false>(false);

  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: string;
  }>({ page: 1, pages: 0, filter: "" });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const actions = {
    edit: t("configs.contractors.button.edit")
  };
  const actionOptions = Object.values(actions);

  const [selectedTags, setSelectedTags] = useState<number[] | null>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<number[] | null>([]);

  const [disapproveSubcontractor, setDisapproveSubcontractor] =
    useState<ISubcontractor | null>(null);
  const [approveSubcontractor, setApproveSubcontractor] = useState<ISubcontractor | null>(null);

  async function handleSelectAction(action: string, values: IContractor) {
    if (action === actions.edit) {
      if(fields){
        try{
          let result = await SubcontractorRestrictVehicleService.show(values.id);

          setEditId(values.id);
          // @ts-ignore
          const _tags = result.data?.vehicle_tags?.map((vehicle) => {
            return { id: vehicle?.id, label: vehicle?.key, key: vehicle?.key };
          });

          // @ts-ignore
          const _vehicles = result.data?.vehicles?.map((vehicle) => {
            return {
              id: vehicle?.id,
              label: vehicle?.license_plate,
              value: vehicle?.id,
            };
          });

          setSelectedTags(_tags);
          setSelectedVehicles(_vehicles);
        }catch(e){
          console.error(e)
        }
      }


    }
  }

  async function handledisapproveSubcontractor() {
    if (isBusy) return;
    setBusy(true);
    if (disapproveSubcontractor?.id)
      try {
        const response = await SubcontractorService.disapprove(
          disapproveSubcontractor.id
        );
        if (response?.message == "Contract desaproved") {
          dispatch(
            updateAlert({
              title: t("configs.subcontractor.successfully_disapproved"),
            })
          );
          loadContractors(pagination);
        } else {
          throw new Error("Error delete subcontractor.");
        }
      } catch (error: any) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            message: error.message,
            type: "error",
          })
        );
      } finally {
        setBusy(false);
        setDisapproveSubcontractor(null);
      }
  }

  async function handleApproveSubcontractor() {
    if (isBusy) return;
    setBusy(true);
    if (approveSubcontractor?.id)
      try {
        const response = await SubcontractorService.approve(
          approveSubcontractor.id
        );
        if (response?.message == "Contract approved") {
          dispatch(
            updateAlert({
              title: t("configs.subcontractor.successfully_approved"),
            })
          );
          loadContractors(pagination);
        } else {
          throw new Error("Error delete subcontractor.");
        }
      } catch (error: any) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            message: error.message,
            type: "error",
          })
        );
      } finally {
        setBusy(false);
        setApproveSubcontractor(null);
      }
  }


  async function onSubmitDelete() {
    if (!!deleteId) {
      await ContractorService.delete(deleteId);

      setDeleteId(false);
      loadContractors(pagination);
    }
  }

  async function onSubmitUpdate({ registers }: FormValues) {
    try {
      for await (let data of registers) {
        // if (!!data.id && data.isAvailableEdited) {
        //   const response = Boolean(data.available)
        //     ? await ContractorService.associate(data.company_id)
        //     : await ContractorService.disassociate(data.company_id);

        //   if (!response?.success) {
        //     throw Error(`${data?.contractor_name}: ${response?.message}`);
        //   }

        //   const i = registers.findIndex((item) => item.id === data.id);
        //   registers[i] = { ...data, isAvailableEdited: false };
        // }

        if (!!data.id && data.isAdvancedControlEdited) {
          const response = await ContractorService.setAdvancedControl(
            data.company_id,
            data.advanced_control
          );

          if (!response?.success) {
            throw Error(`${data?.contractor_name}: ${response?.message}`);
          }

          const i = registers.findIndex((item) => item.id === data.id);
          registers[i] = { ...data, isAdvancedControlEdited: false };
        }
      }

      reset({ registers });

      dispatch(updateAlert({ title: t("message.success.save_data") }));
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleEditCancel = async () => {
    replace(fields);
  };

  async function loadContractors({ filter = "", page = 1 }: GetContractor) {
    try {
      const _response = await ContractorService.get({ filter, page });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        replace(_response.data);

        setPagination((prev) => ({
          ...prev,
          page: _response.meta?.current_page || 0,
          pages: _response.meta?.last_page || 0,
        }));
      }
    } catch (error: any) {
      console.log("Erro in load contractor", error.message);
    }
  }

  async function handleAssociateVehicles(data: any){
    if(editId){
      const { vehicle_type, tags } = data;

      const currentSelectedVehiclesIds = selectedVehicles?.map((v:any) => v.id) ?? [];
      const currentSelectedTagsIds = selectedTags?.map((t:any) => t.id) ?? [];
  
      const vehiclesToAssociate = vehicle_type?.filter((v:any) => !currentSelectedVehiclesIds.includes(v.value)).map((v:any) => v.value) ?? [];
      const tagsToAssociate = tags?.filter((t:any) => !currentSelectedTagsIds.includes(t.id)).map((t:any) => t.id) ?? [];
  
      const vehiclesToDisassociate = vehicle_type ? currentSelectedVehiclesIds.filter((v:any) => !vehicle_type.find((vt:any) => v == vt.value)) : [];
      const tagsToDisassociate = tags ? currentSelectedTagsIds.filter((t:any) => !tags.find((_t:any) => t == _t.id)) : [];
  
      try{
        await SubcontractorRestrictVehicleService.associateVehicle({
          contract_id: editId,
          tags_id: tagsToAssociate,
          vehicles_id: vehiclesToAssociate
        });

        await SubcontractorRestrictVehicleService.disassociateVehicle({
          contract_id: editId,
          tags_id: tagsToDisassociate,
          vehicles_id: vehiclesToDisassociate
        });

        dispatch(
          updateAlert({
            title: t("message.success.save_data"),
            type: "success",
          })
        );

        setSelectedVehicles([]);
        setSelectedTags([]);
        setEditId(false);
        loadContractors({ filter: pagination.filter, page: pagination.page });
      }catch(e){
        console.error(e)
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            message: "",
            type: "error",
          })
        );
      }
    }
  }

  const selectStatusLabel = (is_active: boolean, total_vehicles: number) => {
    if(is_active && total_vehicles > 0){
      return t("configs.subcontractor.active");
    }else if(is_active && total_vehicles == 0){
      return t("configs.subcontractor.awaiting_approval");
    }else{
      return t("configs.subcontractor.inactive");
    }
  }

  useEffect(() => {
    loadContractors({ filter: pagination.filter, page: pagination.page });
  }, [pagination.filter, pagination.page]);

  return (
    <Container className="mb-3">
      <Row className="mb-4">
        <Col xl={9} lg={6} md={4} className="d-flex align-items-center">
          <h5 className="mb-0 p-0">{t("configs.contractors.title")}</h5>
        </Col>

        <Col xl={3} lg={6} md={8}>
          <Stack direction="horizontal" gap={2}>
            <CustomSearchInput
              placeholder={t("search")}
              onChange={(e) =>
                setPagination((prev) => ({
                  ...prev,
                  page: 1,
                  filter: e.target.value,
                }))
              }
            />

            <Button variant="outline-secondary">
              <FaFilter size={18} />
            </Button>
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive>
            <CustomThead
              data={[
                {
                  column: "1",
                  name: t("configs.contractors.table_contractor"),
                },
                { column: "2", name: t("configs.contractors.table_vehicles") },
                // {
                //   column: "3",
                //   name: t("configs.contractors.table_disponibility"),
                // },
                // {
                //   column: "4",
                //   name: t("configs.contractors.table_advanced_control"),
                // },
                { column: "4", name: t("configs.subcontractor.visibility"), menu: true},
                { column: "5", name: t("configs.subcontractors.table_status") },
                { column: "menu", name: <th />, menu: true },
              ]}
              onChangeOrder={(event) => console.log(event)}
            />

            <tbody>
              {fields?.map((item: any, index: number) => (
                <tr key={`${index}_${item.fieldId}`}>
                  <td>{item.company_contractor.company_name}</td>
                  <td>{item.total_vehicles}</td>
                  {/* <td>
                    <Form.Check
                      type="switch"
                      id={`${index}_available`}
                      disabled
                      {...register(`registers.${index}.available`, {
                        // onChange: () => {
                        //   setValue(
                        //     `registers.${index}.isAvailableEdited`,
                        //     true
                        //   );
                        // },
                      })}
                    />
                  </td> */}
                  {/* <td>
                    <Form.Check
                      type="switch"
                      id={`${index}_advanced_control`}
                      {...register(`registers.${index}.advanced_control`, {
                        onChange: () => {
                          setValue(
                            `registers.${index}.isAdvancedControlEdited`,
                            true
                          );
                        },
                      })}
                    />
                  </td> */}
                  <td>
                    <Form.Check
                      type="switch"
                      id={`${index}_available`}
                      checked={item.active}
                      onChange={(e) => {
                        e.preventDefault();
                        if(item.active){
                          setDisapproveSubcontractor(item);
                        }else{
                          setApproveSubcontractor(item);
                        }
                      }}
                    />
                  </td>
                  <td className="col-2">
                    {selectStatusLabel(item.active, item.total_vehicles)}
                  </td>
                  <td>
                    {
                      item.active > 0 && 
                      <TableActions
                        items={actionOptions}
                        onSelectAction={(action) =>
                          handleSelectAction(action, item)
                        }
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          {!fields.length && (
            <EmptyListMessage style={{ marginTop: "8%" }}>
              {t("configs.contractor.empty_list")}
            </EmptyListMessage>
          )}
        </Col>
      </Row>

      <br />
      <br />

      <Row>
        <Footer>
          <div>
            <Stack className="mb-3" direction="horizontal" gap={2}>
              <Button
                onClick={handleSubmit(onSubmitUpdate, console.log)}
                disabled={
                  !watch("registers")?.find(
                    ({ isAdvancedControlEdited }) => !!isAdvancedControlEdited
                  )
                }
              >
                {isSubmitting ? t("status.saving") : t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={handleEditCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </div>

          <div className="mx-3">
            <Pagination
              current_page={pagination.page}
              qtd={pagination.pages}
              onSelectPage={(page) => {
                loadContractors({ ...pagination, page });
              }}
            />
          </div>
        </Footer>
      </Row>

      <AssociateVehiclesAndTagsModal
        onClose={() => setEditId(false)}
        onSubmit={handleAssociateVehicles}
        visible={!!editId}
        selectedTags={selectedTags}
        selectedVehicles={selectedVehicles}
      />

      {/* <ContractorRestrictionsModal
        contractorId={Number(editId)}
        visible={!!editId}
        onClose={() => {
          setEditId(false);
        }}
        onSuccess={() => {
          setEditId(false);
          dispatch(updateAlert({ title: t("message.success.save_data") }));
        }}
        onError={(message) => {
          setEditId(false);
          dispatch(
            updateAlert({
              title: t("message.error.save_data"),
              message,
              type: "error",
            })
          );
        }}
      /> */}

      <ConfirmationModal
        visible={!!deleteId}
        title={t("configs.contractor.confirm_modal_title")}
        message={t("configs.contractor.confirm_modal_message")}
        onClose={() => setDeleteId(false)}
        onConfirm={onSubmitDelete}
      />

      <ConfirmationModal
        visible={!!disapproveSubcontractor?.id}
        title={t("configs.subcontractor.confirm_modal_title")}
        onConfirm={handledisapproveSubcontractor}
        onClose={() => setDisapproveSubcontractor(null)}
        isLoading={isBusy}
      />

      <ConfirmationModal
        visible={!!approveSubcontractor?.id}
        title={t("configs.subcontractor.confirm_approval_title")}
        onConfirm={handleApproveSubcontractor}
        onClose={() => setApproveSubcontractor(null)}
        isLoading={isBusy}
      />
    </Container>
  );
};

export default ConfigsContractors;
