import React from "react";
import { Button, Col, Form, Modal, Row, Spinner, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { updateAlert } from "../../../core/redux/reducer/alert";
import { useDispatch } from "react-redux";
import { ModalStyles } from "./styles";
import { Link } from "react-router-dom";

interface EditBillingAddressProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}

export type FormValues = {
  company_name: string;
  zipcode: string;
  state: string;
  city: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
  document: string;
};

const EditBillingAddressModal: React.FC<EditBillingAddressProps> = ({
  visible = false,
  onClose,
  onSuccess,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      company_name: "",
      zipcode: "",
      state: "",
      city: "",
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
      document: "",
    },
  });

  async function onSubmit({
    company_name,
    zipcode,
    state,
    city,
    street,
    number,
    neighborhood,
    complement,
    document,
  }: FormValues) {
    try {
      console.log({
        company_name,
        zipcode,
        state,
        city,
        street,
        number,
        neighborhood,
        complement,
        document,
      });
      // reset();
      onSuccess?.();
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: "Erro ao salvar os dados!",
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleClose = () => {
    // reset();
    onClose?.();
  };

  return (
    <Modal
      {...props}
      show={visible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header className="border-0 p-4 pb-0 pt-3" closeButton>
        <Modal.Title>{t("configs.menu.edit_billing_address")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pb-3 pt-3">
        <ModalStyles>
          <Form noValidate onSubmit={handleSubmit(onSubmit, console.warn)}>
            <Row>
              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>{t("configs.company.company_title")}</Form.Label>
                <Form.Control
                  {...register("company_name")}
                  placeholder={t("configs.company.company_title")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.company_name?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.company_name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>{t("configs.company.zipcode")}</Form.Label>
                <Form.Control
                  {...register("zipcode", {
                    required: true,
                  })}
                  placeholder={t("configs.company.zipcode")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.zipcode?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.zipcode?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>{t("configs.company.state_label")}</Form.Label>
                <Form.Select
                  {...register("state", {
                    required: true,
                  })}
                >
                  <option value={""} color="#6c757d">
                    {"São Paulo"}
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.state?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="city" as={Col} xs="6">
                <Form.Label>{t("configs.company.city")}</Form.Label>
                <Form.Control
                  {...register("city", {
                    required: true,
                  })}
                  placeholder={t("configs.company.city")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.city?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.city?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="street" as={Col} xs="8">
                <Form.Label>{t("configs.company.street")}</Form.Label>
                <Form.Control
                  {...register("state")}
                  placeholder={t("configs.company.street")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.street?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.street?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="number" as={Col} xs="4">
                <Form.Label>{t("configs.company.number")}</Form.Label>
                <Form.Control
                  {...register("number", {
                    required: true,
                  })}
                  placeholder={t("configs.company.number")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.number?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.number?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>{t("configs.company.neighborhood")}</Form.Label>
                <Form.Control
                  {...register("neighborhood")}
                  placeholder={t("configs.company.neighborhood")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.neighborhood?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.neighborhood?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="complement"
                as={Col}
                xs="6"
              >
                <Form.Label>{t("configs.company.complement")}</Form.Label>
                <Form.Control
                  {...register("complement", {
                    required: true,
                  })}
                  placeholder={t("configs.company.complement")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.complement?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.complement?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>
                  {t("drivers.table_document")}/{t("configs.company.cnpj")}
                </Form.Label>
                <Form.Control
                  {...register("document")}
                  placeholder={""}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.document?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.document?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Stack
              direction="horizontal"
              gap={2}
              style={{ justifyContent: "end" }}
              className="mt-5"
            >
              <Button
                id="on-submit-access-profile"
                variant="primary"
                type="submit"
              >
                {t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>

              <Button variant="secondary" onClick={handleClose}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Form>
        </ModalStyles>
      </Modal.Body>
    </Modal>
  );
};

export default EditBillingAddressModal;
