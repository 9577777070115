export function cep(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

export function measurementsWeight(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  // value = value.replace(/\D/g, "");
  // value = value.replace(/(\d{3})(\d)/, "$1.$2");
  // value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/\D/g, "");

  // Adiciona a vírgula decimal antes dos dois últimos dígitos
  value = value.replace(/(\d{2})$/, ".$1");

  // Adiciona pontos para separar os milhares
  // value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  // Atualiza o valor do campo de entrada
  e.currentTarget.value = value;

  return e;
}
export function measurements(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 6;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  e.currentTarget.value = value;
  return e;
}

export function plate(e: React.FormEvent<HTMLInputElement>) {
  // [A-Z]{3}[0-9][0-9A-Z][0-9]
  e.currentTarget.maxLength = 8;
  let value = e.currentTarget.value;
  value = value.replace(/^(\w{3})(\d)/g, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

export function phone(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 8;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d\d)(\d)/g, "($1)$2");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

export function currency(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  e.currentTarget.value = value;
  return e;
}

export function cpf(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{2})$/, "$1-$2");
    e.currentTarget.value = value;
  }
  return e;
}

export function cnpj(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 18;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{2})$/, "$1-$2");
    e.currentTarget.value = value;
  }
  return e;
}

export function time(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 8;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2}):(\d{2}):(\d{2})$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1:$2");
    value = value.replace(/(\d{2})(\d)/, "$1:$2");
    value = value.replace(/(\d{2})(\d)/, "$1:$2").padEnd(0, "0");
    e.currentTarget.value = value;
  }
  return e;
}

export function imei(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15;
  return e;
}
