import { useState, useRef, useCallback, useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { API_LOADER_MAP } from "../../constants/map";
import { Container } from "./styles";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import MapSidebar from "../ElectronicFenceMap/mapSidebar";
import CustomExpandMapButton from "../CustomExpandMapButton";
import { RoutesMock } from "../../constants/routes_mock";
import { MapEletronicFence } from "../../core/providers/map.eletronic_fence";
import { FenceType } from "../../core/interfaces/EletronicFence";
import { PointsFence } from "../../core/interfaces/Geofence";

export type FormValues = {
  type: FenceType;
  radius: number;
  perimeter: number;
  routes: {
    formated_address?: string;
    lat?: number;
    lng?: number;
  }[];
  address: string;
};

interface ElectronicFenceMapProps {
  onFullscreen?: (value: boolean) => void;
  handlePoints: any;
  infos: { type: FenceType; points: PointsFence[] };
  visible: boolean;
  onClose?(): void;
}

export default function MapFenceModal({
  onFullscreen,
  handlePoints,
  visible,
  onClose,
  infos,
  ...props
}: ElectronicFenceMapProps) {
  const mapRef = useRef<MapEletronicFence>();
  const polygonRef = useRef<google.maps.Polygon>();
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);

  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader(API_LOADER_MAP);

  function handleOnLoad(map: google.maps.Map) {
    mapRef.current = new MapEletronicFence(map);
    mapRef.current.type = infos.type;
    // mapRef.current.onRadiusChanged = (radius: number) => {
    //   setValue("radius", Number((radius / 1000).toFixed(3)));
    // };
  }

  function centerMap() {
    let minLatitude = 0;
    let minLongitude = 0;
    let maxLatitude = 0;
    let maxLongitude = 0;

    minLatitude = infos?.points[0]?.latitude;
    minLongitude = infos?.points[0]?.longitude;
    maxLatitude = infos?.points[infos?.points?.length - 1]?.latitude;
    maxLongitude = infos?.points[infos?.points?.length - 1]?.longitude;

    const middlePoint = {
      lat: (minLatitude + maxLatitude) / 2,
      lng: (minLongitude + maxLongitude) / 2,
    };
    return middlePoint;
  }

  const [pointsFence, setPointsFence] = useState({
    lat: "",
    lng: "",
  });

  const handlePointsInfos = () => {
    if (mapRef.current?.fence) return;

    mapRef.current?.changeType(infos?.type);

    if (infos?.points?.length > 0) {
      const pointsFence = infos?.points?.map((point) => ({
        lat: point?.latitude,
        lng: point?.longitude,
      }));
      const radius = Number(infos?.points[0]?.radius);
      if (pointsFence?.length > 0)
        mapRef.current?.loadFence(pointsFence, radius ?? 100);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handlePointsInfos();
    }, 500);

    setTimeout(() => {
      mapRef.current?.setCenter(centerMap());
      mapRef.current?.fence?.setEditable(false);
      mapRef.current?.fence?.setDraggable(false);
      mapRef.current?.fence?.setOptions({
        draggable: false,
        clickable: false,
        editable: false,
      });
    }, 600);
  }, [infos?.type, visible]);

  const handleCancel = () => {
    onClose?.();
  };

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton></Modal.Header>
      <Modal.Body className="mt-4">
        <Container>
          {isLoaded && (
            <GoogleMap
              id="electronic_fence_map_id"
              mapContainerStyle={{ height: "100%", flex: 1 }}
              center={centerMap()}
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControlOptions: {
                  position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
              }}
              onLoad={handleOnLoad}
              zoom={15}
            />
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
