import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  .pr-4 {
    padding-right: 56px;
  }
  .title {
    color: #6c757d;
    font-weight: 500;
    font-size: 28px;
    margin-top: 28px;
    margin-bottom: 40px;
  }

  .button {
    border: 0;
    background: 0;
    color: #6c757d;
  }
`;
