import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { AlertsService } from "../../core/services/AlertsService";
import CameraModal from "../CameraModal";

interface MediaModalProps {
  visible: boolean;
  onClose?(): void;
  media: {
    images: string[];
    videos: string[];
  };
}

const MediaModal: React.FC<MediaModalProps> = ({
  onClose,
  visible,
  media,
  ...props
}) => {
  const { t } = useTranslation();
  const [mediaUrl, setMediaUrl] = useState("");
  const [isImage, setIsImage] = useState(false);

  const handleCloseMedia = () => {
    setMediaUrl("");
    setIsImage(false);
  };

  const handleMedia = (media: string) => {
    const url = `https://hml.core.fleetdesk.io/api/v2/camera/alarm-files/${media}`;
    setMediaUrl(url);
  };

  return (
    <>
      <Modal
        {...props}
        show={visible}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onClose}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>{t("alerts.view_media")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {media?.videos?.length > 0 && (
            <>
              <h5>{t("alerts.table_videos")}:</h5>
              {media?.videos.map((video, key: number) => (
                <button onClick={() => handleMedia(video)}>
                  {t("alerts.video_label") + ` ${key + 1}`}
                </button>
              ))}
            </>
          )}

          {media?.images?.length > 0 && (
            <>
              <h5>{t("alerts.table_images")}:</h5>
              {media?.images.map((image, key: number) => (
                <button
                  onClick={() => {
                    handleMedia(image);
                    setIsImage(true);
                  }}
                >
                  {t("alerts.image_label") + ` ${key + 1}`}
                </button>
              ))}
            </>
          )}
        </Modal.Body>
      </Modal>

      <CameraModal onClose={handleCloseMedia} url={mediaUrl} img={isImage} />
    </>
  );
};

export default MediaModal;
