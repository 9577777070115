import styled from "styled-components";

export const Container = styled.div`
  color: rgba(255, 255, 255, 0.5);

  .btn {
    color: inherit;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 600;
  }
`;
