import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { APiQueryBase } from "../../interfaces/ApiResponseBase";
import { RoutingService } from "../../services/RoutingService";
import { Job, Route, RouteData, Timeline, TimelineDataResponse, AllTask, DataTask, DataRoute, RoutingFilter, RoutingFilterMapBounds } from "../../interfaces/RoutingPlaning";
import { RootState } from "../store";
import { PlanRoutingStorage } from "../../storage/plan_routing_storage";

export interface IRoterizationItem {
  id: string;
  created_at: string;
  user: { name: string; last_name: string };
  plan_routing_status: { status: string };
  amount_total_meters: number;
  amount_total_routes: number;
  amount_total_tasks: number;
}

export interface TaskItem {
  id: string;
  created_at: string;
  customer: string;
  weight: number;
  volume: number;
  city: string;
  country: string;
  state: string;
  latitude: number;
  longitude: number;
}

export interface TaskResponse {
  address: string;
  amount: number;
  customer_phone: string;
  customer_phone_pickup: string;
  average_service_time: string;
  average_service_time_pickup: string;
  burgh: string;
  burgh_pickup: string;
  city: string;
  city_pickup: string;
  cod_customer: string;
  cod_customer_pickup: string;
  company_id: number;
  complement: string;
  complement_pickup: string;
  country: string;
  country_pickup: string;
  created_at: string;
  customer: string;
  customer_pickup: string;
  divergence: string;
  end_timewindow: string;
  end_timewindow_pickup: string;
  id: number;
  initial_timewindow: string;
  initial_timewindow_pickup: string;
  is_divergence: number;
  job_type: {};
  job_type_id: number;
  latitude: number;
  latitude_pickup: number;
  longitude: number;
  longitude_pickup: number;
  nf: string;
  number: string;
  number_pickup: string;
  observation: string;
  pickup_address: string;
  plan_routing_id: string;
  state: string;
  state_pickup: string;
  updated_at: string;
  user_id: number;
  volume: number;
  weight: number;
  zipcode: string;
  zipcode_pickup: number;
  tags: string[];
  vehicle_tags: string[];
}

type TimelinePrevNextTaks = {
  prev: number;
  next: number
}

export interface IRoterizationListInitialState {
  roterizationList: IRoterizationItem[];
  task: TaskItem[];
  timeline: any;
  singleTask: TaskResponse;
  
  routing_filters: RoutingFilter;
  routing_filters_map_bounds: RoutingFilterMapBounds;

  loading: boolean;
  loadingRoute: boolean;
  loadingTask: boolean;

  pending: {};
  pendingRoute: {},
  pendingTask: {},

  error: string;
  errorRoute: string;
  errorTask: string;

  isNext: boolean;
  isPrev: boolean;
  next_id?: number;
  prev_id?: number;

  selected_all: boolean;
  isMove: boolean;
  job: Job
  timeline_response: TimelineDataResponse
  timeline_routes: Route[];
  timeline_tasks: Timeline[];
  timeline_task_id: DataTask;
  timeline_route_id: DataRoute;
  timeline_prev_next_taks: TimelinePrevNextTaks;
  vehicles_ids: number[];
  routes_ids: number[];
}

const initialState: IRoterizationListInitialState = {
  roterizationList: [],
  task: [],
  timeline: [],
  singleTask: {} as TaskResponse,

  routing_filters: PlanRoutingStorage.getFilter() || {
    vehicle: [],
    vehicle_type: [],
    driver_status: [],
    driver: [],
    tags: [],
    date: {
      from: '',
      to: ''
    }
  },
  routing_filters_map_bounds: PlanRoutingStorage.getFilterMapBounds() || {},
  
  loading: false,
  loadingRoute: false,
  loadingTask: false,

  pending: {},
  pendingRoute: {},
  pendingTask: {},
  
  error: "",
  errorRoute: "",
  errorTask: "",

  isNext: false,
  isPrev: false,
  next_id: 0,
  prev_id: 0,

  selected_all: false,
  isMove: false,

  timeline_response: {} as TimelineDataResponse,
  job: {} as Job,
  timeline_routes: [],
  timeline_tasks: [],
  timeline_route_id: {} as DataRoute,
  timeline_task_id: {} as DataTask,
  timeline_prev_next_taks: {
    prev: 0,
    next: 0
  },
  routes_ids: [],
  vehicles_ids: []
};

export const fetchRouting = createAsyncThunk(
  "routing/fetchRouting",
  async (filters: APiQueryBase) => {
    const response = await RoutingService.getRoterization(filters);
    return response;
  }
);

export const fetchRoutingTask = createAsyncThunk(
  "routing/fetchRoutingTask",
  async (filters: APiQueryBase) => {
    const response = await RoutingService.getTasks(filters);

    return response;
  }
);

export const roterizationTracking = createAsyncThunk(
  "routing/tracking",
  async (filters: APiQueryBase) => {
    const response = RoutingService.getRoterizationTracking(filters);
    return response;
  }
);

export const getPendingTasks = createAsyncThunk("routing/pending", async () => {
  const response = RoutingService.getPendingTasks();
  return response;
});

export const getRouteId = createAsyncThunk(
  "planning-roterization/roterization", async (filters: APiQueryBase) => {
  const response = RoutingService.getRoterizationTracking(filters);
  return response
})

export const getRoutebyId = createAsyncThunk(
  'planning-roterization/plan-route/show-route', async (id: number) => {
  const response = RoutingService.getRoterizationById(id);
  return response
})

export const getTaskbyId = createAsyncThunk(
  'planning-roterization/plan-route/jobs', async (id: number) => {
  const response = RoutingService.getTaskRoterizationById(id);
  return response
})

interface ReducerActions extends SliceCaseReducers<IRoterizationListInitialState> {}

export const routingSlice: Slice<
  IRoterizationListInitialState,
  ReducerActions
> = createSlice({
  name: "routing",
  initialState,
  reducers: {
    updateSelectAll: (state, action: PayloadAction<{selected_all: boolean}>) => {
      state.selected_all = action.payload.selected_all
    },
    updateIsMove: (state, action) => {
      state.isMove = action.payload
    },
    updateTimelineResponse: (state, action: PayloadAction<TimelineDataResponse>) => {
      state.timeline_response = action.payload
    },
    changeTimelineRouteId: (state, action) => {
      state.timeline_route_id = action.payload
    },
    changeTimelineRoute: (state, action) => {
      state.timeline_routes = action.payload
    },
    changeTimelineTaskId: (state, action) => {
      state.timeline_task_id = action.payload
    },
    changeTimelineTask: (state, action) => {
      state.timeline_tasks = action.payload
    },
    changePrevNextTask: (state, action: PayloadAction<TimelinePrevNextTaks>) => {
      state.timeline_prev_next_taks.next = action.payload.next
      state.timeline_prev_next_taks.prev = action.payload.prev
    },
    updateTrackingFilter: (state, action: PayloadAction<RoutingFilter>) => {
      state.routing_filters = action.payload;
      PlanRoutingStorage.setFilter(action?.payload);
    },
    updateTrackingFilterMapBounds: (
      state,
      action: PayloadAction<RoutingFilterMapBounds>
    ) => {
      state.routing_filters_map_bounds = action.payload;
      PlanRoutingStorage.setFilterMapBounds(action.payload);
    },
  },
  extraReducers: (builder) => {
    // Routing Cases
    builder.addCase(fetchRouting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchRouting.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.roterizationList = action.payload;
        state.error = "";
      }
    );
    builder.addCase(fetchRouting.rejected, (state, action) => {
      state.loading = false;
      state.roterizationList = [];
      state.error = action.error.message || "Something went wrong";
    });
    // Routing Tasks
    builder.addCase(fetchRoutingTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchRoutingTask.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.task = action.payload;
        state.error = "";
      }
    );
    builder.addCase(fetchRoutingTask.rejected, (state, action) => {
      state.loading = false;
      state.task = [];
      state.error = action.error.message || "Something went wrong";
    });

    // Vehicle Cases
    builder.addCase(roterizationTracking.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      roterizationTracking.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.timeline = action.payload;
        state.error = "";
      }
    );
    builder.addCase(
      getPendingTasks.pending,
      (state, action: PayloadAction<any>) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getPendingTasks.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getPendingTasks.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.pending = action.payload;
        state.error = "";
      }
    );
    builder.addCase(roterizationTracking.rejected, (state, action) => {
      state.loading = false;
      state.timeline = [];
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getRouteId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getRouteId.fulfilled,(state, action: PayloadAction<any>) => {
        state.loading = false;
        state.timeline_response = action.payload
        state.timeline_routes = action.payload?.data;
        state.vehicles_ids = action.payload?.meta?.vehicles_ids;
        state.routes_ids = action.payload?.meta?.vehicles_ids;
        state.error = "";
      }
    );

    builder.addCase(getRouteId.rejected, (state, action) => {
      state.loading = false;
      state.timeline_routes = [];
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getRoutebyId.pending, (state) => {
      state.loadingRoute = true;
    });

    builder.addCase(getRoutebyId.fulfilled,(state, action: PayloadAction<any>) => {
      state.loadingRoute = false;
      state.timeline_route_id = action.payload.data
      state.errorRoute = ""
    });

    builder.addCase(getRoutebyId.rejected, (state, action) => {
      state.loadingRoute = false;
      state.timeline_route_id = {} as DataRoute;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getTaskbyId.pending, (state, action) => {
      state.loadingTask = true
    });

    builder.addCase(getTaskbyId.fulfilled,(state, action) => {
      state.loadingTask = false;
      state.timeline_task_id = action.payload.data
      state.next_id = action.payload.next_job_id
      state.prev_id = action.payload.previous_job_id
      state.isNext = action.payload.next_job_id ? true : false
      state.isPrev = action.payload.previous_job_id ? true : false
      state.errorTask = ""
    });

    builder.addCase(getTaskbyId.rejected, (state, action) => {
      state.loadingRoute = false;
      state.timeline_task_id = {} as DataTask;
      state.errorTask = action.error.message || "Something went wrong";
    });
  },
});

export const getTimelineResponse = (state: RootState) => state.routing.timeline_response;
export const isLoadingTimeline = (state: RootState) => state.routing.loading;
export const isMove = (state: RootState) => state.routing.isMove;
export const isLoadingRoute = (state: RootState) => state.routing.loadingRoute;
export const isLoadingTask = (state: RootState) => state.routing.loadingTask;
export const getTimelineRouteId = (state: RootState) => state.routing.timeline_route_id;
export const getTimelineTaskId = (state: RootState) => state.routing.timeline_task_id;
export const getTimelineTask = (state: RootState) => state.routing.timeline_tasks;
export const getTimelineRoute = (state: RootState) => state.routing.timeline_routes;
export const getJob = (state: RootState) => state.routing.job;
export const getTimelinePrevNextJob = (state: RootState) => state.routing.timeline_prev_next_taks;
export const getRoutingFilter = (state: RootState) =>
  state.routing.routing_filters;
export const getRoutingFilterMapBounds = (state: RootState) =>
  state.routing.routing_filters_map_bounds;
export const getIsPrev = (state: RootState) =>  state.routing.isPrev;
export const getIsNext = (state: RootState) =>  state.routing.isNext;
export const getNextId = (state: RootState) =>  state.routing.next_id;
export const getPrevId = (state: RootState) =>  state.routing.prev_id;

export const {
  updateSelectAll,
  updateTimelineResponse,
  changeTimelineRouteId,
  changeTimelineRoute,
  changeTimelineTaskId,
  changeTimelineTask,
  changePrevNextTask,
  updateTrackingFilter
} = routingSlice.actions;

export default routingSlice.reducer;