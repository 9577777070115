import styled from "styled-components";

export const ListEmptyComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  gap: 1rem;

  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #6c757d;
  text-align: center;
  padding: 16px 30%;
`;
