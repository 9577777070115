import React from "react";
import {
  Line,
  LineChart as ReLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BoxTooltip } from "./styles";

interface LineChartProps {
  data?: { value: number; label: string }[];
}

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  console.log("data", data);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <BoxTooltip>
          <p className="fw-700">
            {payload[0].value.toFixed(2)} ({label})
          </p>
        </BoxTooltip>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <ReLineChart data={data}>
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="linear"
          dataKey="value"
          stroke="#007BFF"
          activeDot={{ r: 4 }}
        />
      </ReLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
