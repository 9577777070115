import { api } from ".";
import { ErrorResult } from "../interfaces/ErrorResult";
import {
  ApiResponseJourneyControl,
  JourneyControl,
  JourneyControlQuery,
} from "../interfaces/JourneyControl";
import { isErrorResult } from "../utils/api";

export class JourneyControlService {
  static async get(
    params: JourneyControlQuery
  ): Promise<ApiResponseJourneyControl | ErrorResult> {
    const { filter, order, per_page = 15 } = { ...params };
    return await api
      .get("/api/v2/journey-control", {
        params: {
          ...params,
          per_page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getAll(): Promise<JourneyControl[]> {
    const first_page = await this.get({
      page: 1,
    });

    if (isErrorResult(first_page)) return [];
    if (first_page.meta?.current_page === first_page.meta?.last_page)
      return first_page.data;

    const all_pages_result = await Promise.all(
      [...new Array(first_page.meta?.last_page)].map((_, i) =>
        this.get({ page: i + 1 })
      )
    );

    let only_success_results: JourneyControl[] = [];

    all_pages_result.forEach((results) => {
      if (!isErrorResult(results)) {
        only_success_results = only_success_results.concat(results.data);
      }
    });

    return only_success_results;
  }
}
