import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const reports = yup
  .object({
    vehicle_id: yup.number().required(),
    date_from: yup.string().required(),
    date_to: yup.string().required(),
    event_ids: yup.array(yup.number()).required(),
  })
  .required();

export const auditReports = yupResolver(reports);
