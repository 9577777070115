import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const sensor_alert = yup
  .object({
    name: yup.string().required(),
    alert_type_id: yup.object().required(),
    conditions: yup
      .array()
      .of(
        yup.object().shape({
          rule: yup.string().required(),
          value: yup.string().required(),
          operator: yup.string().required(),
        })
      )
      .required(),
    notify_by_email: yup.boolean(),
    notify_on_monitoring: yup.boolean(),
    notify_by_sms: yup.boolean(),
  })
  .test("true", function (values) {
    const { notify_by_email, notify_by_sms, notify_on_monitoring } = values;
    if (!notify_by_email && !notify_by_sms && !notify_on_monitoring) {
      return this.createError({
        message: "At least one alert notification required",
        path: "notify_by_email",
      });
    }
    return true;
  })
  .required();

export const SensorAlertResolver = yupResolver(sensor_alert);
