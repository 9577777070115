import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { Locale } from "../../interfaces/Locale";
import { User } from "../../interfaces/User";
import { SessionStorage } from "../../storage/session_storage";
import type { RootState } from "../store";

// Define a type for the slice state
interface AuthState {
  isSigned: boolean;
  user?: User;
  token?: string;
  locales: Array<Locale>;
}

const isSigned = !!SessionStorage.getToken();
const user = SessionStorage.getUser();
const locales = SessionStorage.getLocales();

// Define the initial state using that type
const initialState: AuthState = {
  isSigned: isSigned || false,
  user: user || undefined,
  token: undefined,
  locales: locales || [],
};

export const authSlice: Slice<AuthState> = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;

      if (action.payload) SessionStorage.setUser(action.payload);
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isSigned = !!action.payload;

      if (action.payload) SessionStorage.setToken(action.payload);
    },
    updateLocales: (state, action: PayloadAction<Array<Locale>>) => {
      state.locales = action.payload;

      if (action.payload) SessionStorage.setLocales(action.payload);
    },
    logout: (state) => {
      console.log("logout user", { state });

      state.isSigned = false;
      state.user = undefined;
      state.token = undefined;

      SessionStorage.removeUser();
      SessionStorage.removeToken();
    },
  },
});

export const { updateUser, logout, updateToken, updateLocales } =
  authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getIsSigned = (state: RootState) => state.auth.isSigned;
export const getUser = (state: RootState) => state.auth.user;
export const getLocales = (state: RootState) => state.auth.locales;

export default authSlice.reducer;
