import * as masks from "./masks";

interface MaskTypes {
  cep: string;
  cnpj: string;
  phone: string;
  against_password: string;
}

export class MaskService {
  static toMask(type: keyof MaskTypes, value: string | number): any {
    if (type === "cep") {
      return value
        .toString()
        .replace(/\D/g, "")
        .replace(/^(\d{5})?(\d{2})?/, "$1-$2");
    } else if (type === "cnpj") {
      return value
        .toString()
        .replace(/\D/g, "")
        .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
    } else if (type === "phone") {
      return value
        .toString()
        .replace(/\D/g, "")
        .replace(/^(\d\d)(\d)/g, "($1)$2")
        .replace(/(\d{5})(\d)/, "$1-$2");
    } else if (type === "against_password") {
      return value
        .toString()
        .padStart(6, "0")
        .replace(/^(\d{3})?(\d{3})?/, "$1-$2");
    } else {
      return value;
    }
  }

  static toRawValue(type: keyof MaskTypes, maskedValue: string): any {
    if (type === "cep") {
      return maskedValue.replace(/\D/g, "");
    } else if (type === "cnpj") {
      return maskedValue.replace(/\D/g, "");
    } else if (type === "phone") {
      return maskedValue.replace(/\D/g, "");
    } else {
      return maskedValue;
    }
  }

  static toInputMask(
    type: keyof typeof masks,
    e: React.FormEvent<HTMLInputElement>
  ) {
    return masks[type](e);
  }
}
