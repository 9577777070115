import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import PublicMasterPage from "../../layout/PublicMasterPage";
import { ReactComponent as IcLogoDark } from "../../assets/img/logo_dark.svg";
import "./styles.css";
import { Link, useLocation, useParams } from "react-router-dom";
import LoginCarousel from "../../components/LoginCarousel";
import { MdArrowBack } from "react-icons/md";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../core/services/ResetPassword";
import { useTranslation } from "react-i18next";

interface IFormEntry {
  password: string
  repeat_password: string
  response:any
}

const ResetPassword: React.FC = () => {
  const { register, handleSubmit, formState: { isSubmitting, errors }, getValues, setError } = useForm<IFormEntry>()
  const [isRequestCompleted, setIsRequestCompleted] = useState(false)
  const {token} = useParams()
  const {search} = useLocation()
  const email = new URLSearchParams(search).get('email')
  const { t, i18n } = useTranslation();

  async function onSubmit(data: IFormEntry) {
    if(getValues('password') != getValues('repeat_password')){
      setError('password', {
        message:'As senhas não são iguais'
      })
      return
    }
    try {
      const res = await resetPassword({
        password: data.password,
        token:token!,
        email:email!
      })
      if(res.success){
        setIsRequestCompleted(true)
      }else{
        setError('response',{message:'Token inválido'})
      }
    } catch (err) {
      console.error(err)
    }
  }

  
  useEffect(() => {
      {/**@ts-ignore */}
    var userLang = navigator.language || navigator.userLanguage; 
    localStorage.setItem("LANGUAGE_ID", userLang);
    if(localStorage.getItem('LANGUAGE_ID')){
      i18n.changeLanguage(localStorage.getItem('LANGUAGE_ID') ?? '');
    }
  },[])

  return (
    <PublicMasterPage>
      <Container fluid id="login">
        <Row>
          <Col className="p-0" xs={6}>
            <LoginCarousel />
          </Col>
          <Col xs={6} className="right p-0">
            <div className="right-container">
              <div className="logo-container">
                <IcLogoDark />
              </div>
              <div id="form-container">
       
                <div className="wellcome-message">
                  <div className="title">{t('reset_password.title')}</div>
                  <p className="sub-title mb-1">
                  {t('reset_password.subtitle')}
                  </p>
               
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{t('reset_password.new_password')}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t('reset_password.new_password_placeholder')}
                      isInvalid={!!errors?.password}
                      {...register("password")}
                      required
                    />
                      <Form.Control.Feedback type="invalid">
                    {errors?.password?.message}
                  </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{t('reset_password.repeat_password')}</Form.Label>
                    <Form.Control
                      isInvalid={!!errors?.password}
                      type="password"
                      placeholder={t('reset_password.repeat_password_placeholder')}
                      {...register("repeat_password")}
                      required
                    />
                  <Form.Control.Feedback type="invalid">
                    {errors?.password?.message}
                  </Form.Control.Feedback>
                  </Form.Group>

                  <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {t('reset_password.repeat_password_button')}
                  </Button>

                  {isRequestCompleted && !errors.response?.message && (
                    <>
                    <Alert variant="success" className="mt-3">
                      {t('reset_password.repeat_password_success_message')}
                    </Alert>

                  <Link to={"/login"} className="d-flex gap-2 align-items-center back_to_login_btn mt-3">
                    <MdArrowBack />
                    <span>{t('reset_password.back_to_login')}</span>
                  </Link>
                    
                    </>
                  )}

                  {errors.response?.message && (
                        <Alert variant="danger" className="mt-3">
                      {t('reset_password.repeat_password_error_message')}
                      </Alert>
                  )}
                 
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PublicMasterPage>
  );
};

export default ResetPassword;
