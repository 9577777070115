import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const notification = yup
  .object({
    moment: yup.string().required(),
    subject: yup.string().max(20).required(),
    message: yup.string().max(34).required(),
  })
  .required();

export const NotificationResolver = yupResolver(notification);
