import { createIntl, createIntlCache } from "react-intl";

export const convertToIntCurrency = (
  /**
   * Converte um tipo decimal em inteiro, exemplo (1.00 = 100)
   * Um tipo inteiro com a quantia (1000 = R$ 10,00)
   **/
  currencyValue: number
) => {
  const result: number = currencyValue * 100;
  return Number(result.toFixed());
};

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: "pt-BR",
    messages: {},
  },
  cache
);

export const convertToCurrency = (value: string | number) =>
  intl.formatNumber(parseFloat(value.toString()) / 100, {
    style: "decimal",
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

// export const convertToCurrency = (value: string | number) =>
// new Intl
//   .NumberFormat('pt-BR', {
//     style: 'currency',
//     currency: 'BRL',
//     minimumIntegerDigits: 2
//   })
//   .format(parseFloat(value.toString()) / 100);
