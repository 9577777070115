import { HTMLAttributes, useContext } from "react";
import {
  AccordionContext,
  Col,
  Row,
  Stack,
  useAccordionButton,
} from "react-bootstrap";
import { TbChevronDown, TbChevronRight } from "react-icons/tb";
import { ActionToolButton } from "../ActionToolButton";
import { Container } from "./styles";

interface AuditDataAccordion {
  title?: string;
  subtitles?: string[];
}

interface AuditAccordionProps extends HTMLAttributes<HTMLDivElement> {
  data?: AuditDataAccordion;
  eventKey: string;
  callback?(key: string): void;
}

export function CustomAuditAccordion({
  data,
  eventKey,
  callback,
  ...props
}: AuditAccordionProps) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  return (
    <Container
      {...props}
      style={{ borderBottomWidth: !isCurrentEventKey ? 1 : 0 }}
    >
      <Row>
        <Col xl="5">
          <Stack direction="horizontal" gap={2}>
            <ActionToolButton type="button" onClick={decoratedOnClick}>
              {isCurrentEventKey && <TbChevronDown />}
              {!isCurrentEventKey && <TbChevronRight />}
            </ActionToolButton>
            <label className="w-100">{data?.title}</label>
          </Stack>
        </Col>

        {data?.subtitles?.map((value: any, i: number) => (
          <Col key={i} className="text-muted d-flex align-items-center">
            {value}
          </Col>
        ))}
      </Row>
    </Container>
  );
}
