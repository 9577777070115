import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Company } from "../../core/interfaces/Company";
import { UserInvitation } from "../../core/interfaces/UserInvitation";
import { useAppSelector } from "../../core/redux/hooks";
import { getProfiles } from "../../core/redux/reducer/user";
import { UserInvitationResolver } from "../../core/schemaValidations/user_invitation";
import { CompanyService } from "../../core/services/CompanyService";
import { UserService } from "../../core/services/UserService";
import { isErrorResult } from "../../core/utils/api";

interface UserInvitationModalProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}

const UserInvitationModal: React.FC<UserInvitationModalProps> = ({
  visible = false,
  onClose,
  onSuccess,
  ...props
}) => {
  const profiles = useAppSelector(getProfiles);
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
    setValue,
  } = useForm<UserInvitation>({
    resolver: UserInvitationResolver,
  });

  const onSubmit: SubmitHandler<UserInvitation> = async (data) => {
    const _result = await UserService.create(data as any);

    if (isErrorResult(_result)) {
      if (_result.errors?.email)
        setError("email", { message: t("message.error_other_email") });
      if (_result.errors?.name || _result.errors?.last_name)
        setError("name", { message: t("message.error_invalid_name") });
      return;
    }

    reset();
    onSuccess?.();
  };
  const onError = (error: any) => console.log("onSubmitError", error);

  useEffect(() => {
    if (!visible) reset();

    if (visible) {
      (async () => {
        const response = await CompanyService.get();
        const company: Company = response?.data?.[0]?.[0];
        console.log("Company", { company });

        setValue("languages_id", company?.languages_id);
        setValue("company_id", company?.id);
        setValue("password", "E$emplo@123");
        setValue("password_confirmation", "E$emplo@123");
      })();
    }

    setIsVisible(visible);
  }, [visible]);

  return (
    <Modal {...props} show={isVisible} size="lg" centered onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="flex-grow-1 text-center">
          {t("configs.user.invite_user")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
          <Row>
            <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
              <Form.Label>{t("configs.user.name")}</Form.Label>
              <Form.Control
                {...register("name")}
                placeholder={t("configs.user.name")}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                isInvalid={!!errors?.name?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="email" as={Col} xs="6">
              <Form.Label>{t("configs.user.email")}</Form.Label>
              <Form.Control
                {...register("email")}
                type="email"
                placeholder={t("configs.user.email_enter")}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                isInvalid={!!errors?.email?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              className="mb-3"
              controlId="user_profile_id"
              as={Col}
              xs="5"
            >
              <Form.Label>{t("configs.user.access_level")}</Form.Label>

              <Form.Select
                {...register("user_profile_id")}
                isInvalid={!!errors?.user_profile_id?.message}
              >
                <option disabled value="">
                  {t("configs.user.access_level_enter")}
                </option>
                {profiles.map((profile) => (
                  <option key={`profile_${profile.id}`} value={profile.id}>
                    {profile.profile_name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors?.user_profile_id?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Col className="p-1" xs={{ offset: 8, span: 2 }}>
              <Button className="w-100" variant="primary" type="submit">
                {isSubmitting ? t("status.sending") : t("button.confirm")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
            </Col>
            <Col className="p-1" xs="2">
              <Button className="w-100" variant="secondary" onClick={onClose}>
                {t("button.cancel")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserInvitationModal;
