import { useTranslation } from "react-i18next";

export function useAudit() {
  const { t } = useTranslation();

  const actuatorOptions = [
    { label: t("actuators.fuel_blockage"), value: "fuel_blockage" },
    { label: t("actuators.lock_main_chest"), value: "main_chest" },
    { label: t("actuators.fifth_wheel_lock"), value: "fifth_wheel" },
    { label: t("actuators.lock_side_chest"), value: "side_chest" },
    { label: t("actuators.siren"), value: "siren" },
  ];

  const sensorOptions = [
    { label: t("sensor.side_chest"), value: "side_chest", output: 1 },
    { label: t("sensor.fifth_wheel"), value: "fifth_wheel", output: 2 },
    { label: t("sensor.main_chest"), value: "main_chest", output: 3 },
    { label: t("sensor.right_door"), value: "right_door", output: 4 },
    { label: t("sensor.panel"), value: "panel", output: 5 },
    { label: t("sensor.panic"), value: "panic", output: 6 },
    { label: t("sensor.ignition"), value: "ignition", output: 7 },
    { label: t("sensor.hitch"), value: "hitch", output: 8 },
  ];

  const actionOptions = [
    { label: t("audit.options.creation"), value: "c" },
    { label: t("audit.options.exclusion"), value: "d" },
    { label: t("audit.options.edition"), value: "u  " },
  ];

  const recordTypeOptions = [
    { label: t("configs.audit.commands"), value: 66 },
    { label: t("configs.audit.positions"), value: 11 },
    { label: t("configs.audit.login"), value: 1 },
    { label: t("configs.audit.logout"), value: 2 },
    {
      label: t("configs.audit.driver_app_settings"),
      value: 36,
    },
    { label: t("configs.audit.electronic_fences"), value: 64 },
    { label: t("configs.audit.system_record"), value: 69 },
    { label: t("configs.audit.messages"), value: 67 },
  ];

  const recordOriginOptions = [
    { label: t("audit.options.settings"), value: "settings" },
    { label: t("audit.options.forms"), value: "forms" },
    { label: t("audit.options.alerts"), value: "alerts" },
    { label: t("audit.options.driver_journey"), value: "driver_journey" },
    { label: t("audit.options.drivin_behavior"), value: "drivin_behavior" },
    { label: t("audit.options.vehicles"), value: "vehicles" },
    { label: t("audit.options.drivers"), value: "drivers" },
    { label: t("audit.options.optimization"), value: "optimization" },
  ];

  function getRecordTypeTitle(type: string) {
    if (type === "system_record") {
      return t("configs.audit.system_record");
    } else if (type === "positions") {
      return t("configs.audit.positions");
    } else if (type === "messages") {
      return t("configs.audit.messages");
    } else if (type === "login") {
      return t("configs.audit.login");
    } else if (type === "electronic_fences") {
      return t("configs.audit.electronic_fences");
    } else if (type === "driver_app_settings") {
      return t("configs.audit.driver_app_settings");
    } else if (type === "commands") {
      return t("configs.audit.commands");
    } else return "";
  }

  return {
    actuatorOptions,
    sensorOptions,
    getRecordTypeTitle,
    actionOptions,
    recordTypeOptions,
    recordOriginOptions,
  };
}
