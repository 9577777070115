import React from "react";
import { Button, Form, Modal, Row, Spinner, Stack, Col } from "react-bootstrap";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppFormResolver } from "../../core/schemaValidations/app_form";
import QuestionFieldArray from "./questionFieldArray";
import { Card, Line } from "./styles";
import { SurveyService } from "../../core/services/SurveyService";
import { isErrorResult } from "../../core/utils/api";
import { SurveyFormData } from "../../core/interfaces/SurveyForm";
import { surveyFormDataMapper } from "../../core/mappers/survey-form.mapper";
import { useDispatch } from "react-redux";
import { updateAlert } from "../../core/redux/reducer/alert";

interface NewFormModalProps {
  visible?: boolean;
  surveyId?: number;
  onClose?(): void;
  onSuccess?(): void;
}

const NewFormModal: React.FC<NewFormModalProps> = ({
  visible = false,
  surveyId,
  onClose,
  onSuccess,
  ...props
}) => {
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultValues: SurveyFormData = {
    name: "",
    required: true,
    active: false,
    type: "job",
    questions: [
      {
        name: "",
        required: true,
        input_type: "text",
      },
    ],
  };

  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SurveyFormData>({
    resolver: AppFormResolver,
    defaultValues,
  });

  const loadSurveyFullData = async (id: number) => {
    if (isLoading) return;
    setLoading(true);
    const response = await SurveyService.getById(id);
    if (isErrorResult(response)) {
      dispatch(
        updateAlert({
          title: t("message.error.load_data"),
          message: response.message,
          type: "error",
        })
      );
    } else {
      const data = surveyFormDataMapper(response);
      Object.keys(data).forEach((value) => {
        const key = value as keyof SurveyFormData;
        setValue(key, data[key]);
      });
    }
    setLoading(false);
  };

  const onSubmit: SubmitHandler<SurveyFormData> = async (values) => {
    let survey_id = !!values?.id ? parseInt(values?.id) : null;

    if (!survey_id) {
      const response = await SurveyService.create({
        title: values.name,
        mandatory: +values.required,
        status: +values.active,
        type: values.type,
      });
      if (isErrorResult(response)) {
        handleSaveErrors(response.message);
        return;
      } else {
        survey_id = response.survey.id;
      }
    } else {
      const response = await SurveyService.update(survey_id, {
        title: values.name,
        mandatory: +values.required,
        status: +values.active,
        type: values.type,
      });
      if (isErrorResult(response)) {
        handleSaveErrors(response.message);
        return;
      }
    }

    if (!values?.questions?.length) {
      handleSubmissionSuccess();
      dispatch(updateAlert({ title: t("message.success.save_data") }));
      return;
    }

    const questionResponse = await SurveyService.updateOrCreateQuestions({
      survey_id,
      questions: values.questions.map((question) => ({
        id: question?.id,
        question: question.name,
        required_field: +question.required,
        answers: !question?.options?.length
          ? [
              {
                field_type: question?.input_type,
              },
            ]
          : question.options.map((option) => ({
              id: option?.id,
              answer: option?.name,
              field_type: question?.input_type,
            })),
      })),
    });
    if (isErrorResult(questionResponse)) {
      handleSaveErrors(questionResponse.message);
    } else {
      handleSubmissionSuccess();
      dispatch(updateAlert({ title: t("message.success.save_data") }));
    }
  };

  const onError: SubmitErrorHandler<SurveyFormData> = (errors) => {
    console.log("on submit error", errors);
  };

  const handleClose = () => {
    reset();
    onClose?.();
  };

  const handleSubmissionSuccess = () => {
    reset();
    onSuccess?.();
  };

  const handleSaveErrors = (message: string) => {
    dispatch(
      updateAlert({
        title: t("message.error.save_data"),
        message,
        type: "error",
      })
    );
  };

  React.useEffect(() => {
    if (!!surveyId && !!visible) {
      loadSurveyFullData(surveyId);
    }
  }, [visible]);

  return (
    <Modal
      {...props}
      show={visible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header className="border-0 p-4 pb-0 pt-3" closeButton>
        <Modal.Title>
          {!!surveyId
            ? t("configs.app.form_title_edit")
            : t("configs.app.form_title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pb-3 pt-3">
        <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
          <Card>
            <Row>
              <Col sm={8}>
                <Form.Group controlId="name">
                  <Form.Control
                    plaintext
                    placeholder={t("configs.app.form_name")}
                    isInvalid={!!errors?.name?.message}
                    {...register("name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.name?.message}
                  </Form.Control.Feedback>
                  <Line />
                </Form.Group>

                <Form.Group controlId="required">
                  <Form.Check
                    id="required"
                    type="switch"
                    label={t("configs.app.form_required")}
                    {...register("required")}
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="type">
                  <Form.Label>{t("configs.app.form_table_type")}</Form.Label>
                  <Form.Select {...register("type")}>
                    <option value="job">
                      {t("configs.app.option_type_job")}
                    </option>
                    <option value="vehicle">
                      {t("configs.app.option_type_vehicle")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card>

          <QuestionFieldArray
            control={control}
            register={register}
            isLoading={isLoading}
          />

          <Stack
            direction="horizontal"
            gap={2}
            style={{ justifyContent: "end" }}
          >
            <Button id="on-submit-new-form" variant="primary" type="submit">
              {isSubmitting
                ? t("status.saving")
                : !!surveyId
                ? t("button.save")
                : t("configs.app.button_add")}
              {isSubmitting && <Spinner animation="border" size="sm" />}
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              {t("configs.app.button_cancel")}
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewFormModal;
