import styled from "styled-components";

export const Card = styled.div`
  padding: 24px 40px;
  margin-bottom: 26px;
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 0px 5px 5px 5px;
  color: #6c757d;
`;

export const Line = styled.div`
  width: 100%;
  height: 0px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #f2f2f2;
  margin-bottom: 1rem;
`;

export const NestedFieldArrayContainer = styled.div`
  padding: 0 40px;
`;

export const ActionButton = styled.button`
  height: 40px;
  width: 40px;
  color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-width: 0;
  padding: 0;
  background-color: transparent;

  &:hover {
    color: #b72026;
    transition: color 0.3s ease;
  }
`;

export const SpinnerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  color: #6c757d;
`;
