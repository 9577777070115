import React, { useEffect } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { Controller, useForm } from "react-hook-form";

import { VehicleService } from "../../core/services/VehicleService";
import { isErrorResult } from "../../core/utils/api";

import { Vehicle } from "../../core/interfaces/Vehicle";
import { AsyncPaginate } from "react-select-async-paginate";

import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { VehicleTagService } from "../../core/services/VehicleTagService";

import CustomInputTagAlt from "../CustomInputTagAlt";

type FormValues = {
  vehicles: { id: number; label: string; checked: boolean }[];
  vehicle_type: any | null;
  tags: any | null;
  activity: string;
  reason: string;
  period: { from: string; to: string };
  lat: string;
  long: string;
};

interface IValues {
  vehicles: Vehicle[];
  selected_vehicles: {
    label: string;
    value: string;
  };
  vehicle_id: number | null;
  selectedTags: any | null;
  isLoading: boolean;
}

const AssociateVehiclesAndTagsModal = ({
  onClose,
  visible,
  onSubmit,
  selectedTags,
  selectedVehicles,
}: any) => {
  const { control, handleSubmit, reset, setValue, formState } =
    useForm<FormValues>();
  const { t } = useTranslation();
  // const { isSubmitting } = useFormState();
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Error in loading tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  useEffect(() => {
    if (visible) {
      if (selectedVehicles?.length) {
        setValue("vehicle_type", selectedVehicles);
      } else {
        setValue("vehicle_type", null);
      }

      if (selectedTags?.length && selectedTags?.length !== 0) {
        setValue("tags", selectedTags);
      } else {
        setValue("tags", []);
      }
    } else {
      setValue("vehicle_type", null);
      setValue("tags", []);
    }
  }, [visible, selectedVehicles, selectedTags, setValue]);

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
    } else {
      setValue("vehicle_type", null);
      setValue("tags", null);
    }
  }, [visible]);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(onSubmit)();
    setValue("vehicle_type", null);
    setValue("tags", null);
    reset({
      vehicle_type: null,
      tags: null,
    });

    onClose?.();
  };
  function handleCancel() {
    setValue("vehicle_type", null);
    reset({
      vehicle_type: null,
      tags: null,
    });

    onClose?.();
  }

  return (
    <Modal show={visible} size="lg" centered onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>
          {t("electronic_fences.action_associate_vhicles")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pt-0">
        <Form onSubmit={handleFormSubmit}>
          <Col lg={6} md={12}>
            <Row className="mt-4 mb-3">
              <Form.Group as={Col} lg={12} md={12} controlId="vehicle_type">
                <Form.Label>{t("configs.fleetmate.ei.vehicle")}</Form.Label>
                <Controller
                  control={control}
                  name="vehicle_type"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <AsyncPaginate
                      isClearable
                      isMulti
                      placeholder={t("input.select_text")}
                      loadOptions={async (inputValue, options, additional) =>
                        VehicleService.loadVehicleOptions(
                          inputValue,
                          options,
                          additional
                        )
                      }
                      additional={{ page: 1 }}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </Form.Group>
            </Row>
          </Col>
          <Row>
            <Form.Group className="mb-4" controlId="name" as={Col}>
              <Form.Label>{t("component.tag_label")}</Form.Label>
              <Controller
                name="tags"
                control={control}
                // defaultValue={[]}
                render={({ field: { value, onChange } }) => {
                  return (
                    <CustomInputTagAlt
                      selected={
                        // selectedTags?.length && selectedTags?.length !== 0
                        //   ? value
                        //   : []
                        selectedTags?.length && selectedTags?.length !== 0
                          ? value
                          : value
                      }
                      onChange={(values: string[]) => {
                        // console.log("values", values);
                        onChange(values);
                      }}
                      options={vehicleTags?.map((op) => {
                        return op;
                      })}
                      isLoading={isLoadingTags}
                    />
                  );
                }}
              />
            </Form.Group>
          </Row>{" "}
          <Row className="mt-5">
            <Stack
              className="d-flex justify-content-end"
              direction="horizontal"
              gap={3}
            >
              <Button
                variant={"primary"}
                disabled={formState.isSubmitting}
                type="submit"
              >
                {t("button.save")}
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AssociateVehiclesAndTagsModal;
