import { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  useFieldArray,
  Control,
  UseFormRegister,
  useFormState,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoTrashOutline } from "react-icons/io5";
import { IAlertRuleBody } from ".";
import { ActionToolButton } from "../../../components/ActionToolButton";
import AddButton from "../../../components/AddButton";
import { Separator } from "./styles";

interface ConditionFieldArrayProps {
  control: Control<IAlertRuleBody>;
  register: UseFormRegister<IAlertRuleBody>;
}

export default function ConditionFieldArray({
  control,
  register,
}: ConditionFieldArrayProps) {
  const { t } = useTranslation();
  const { append, fields, remove } = useFieldArray({
    control,
    name: "conditions",
  });

  const { errors } = useFormState({
    control,
  });

  const handleAddCondition = () => {
    append({ rule: "more_then", value: "", operator: "and" });
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <Form.Label>{t("sensors.form_send_alert_label")}</Form.Label>
          <Separator />
        </Col>
      </Row>

      {fields?.map((field, i) => (
        <div key={field.id}>
          <Row className="mb-3 gap-lg-0 gap-md-0 gap-sm-3">
            <Form.Group md={3} as={Col} controlId={`conditions.${i}.rule`}>
              <Form.Select
                {...register(`conditions.${i}.rule`)}
                isInvalid={!!errors?.conditions?.[i]?.rule?.message}
              >
                <option value="more_then">
                  {t("sensors.form_option_rule_more_then")}
                </option>
                <option value="less_then">
                  {t("sensors.form_option_rule_less_then")}
                </option>
                <option value="percent_variation">
                  {t("sensors.form_option_rule_percent")}
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.conditions?.[i]?.rule?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group md={3} as={Col} controlId={`conditions.${i}.value`}>
              <Form.Control
                // min={0}
                type="number"
                placeholder={t("sensors.form_rule_enter")}
                {...register(`conditions.${i}.value`)}
                isInvalid={!!errors?.conditions?.[i]?.value?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.conditions?.[i]?.value?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {i + 1 < fields.length && (
              <Form.Group
                md={3}
                as={Col}
                controlId={`conditions.${i}.operator`}
              >
                <Form.Select
                  {...register(`conditions.${i}.operator`)}
                  isInvalid={!!errors?.conditions?.[i]?.operator?.message}
                >
                  <option value="and">
                    {t("sensors.form_option_operator_and")}
                  </option>
                  <option value="or">
                    {t("sensors.form_option_operator_or")}
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.conditions?.[i]?.operator?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {i >= 1 && (
              <Col
                className="d-flex justify-content-end"
                style={{ maxWidth: "100%" }}
              >
                <ActionToolButton onClick={() => remove(i)}>
                  <IoTrashOutline size="1.4rem" />
                </ActionToolButton>
              </Col>
            )}
          </Row>
          <Separator />
        </div>
      ))}

      <Row>
        <Col className="my-4">
          <AddButton onClick={handleAddCondition}>
            {t("sensors.form_add_condition")}
          </AddButton>
        </Col>
      </Row>
    </Fragment>
  );
}
