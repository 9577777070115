import React from "react";
import { Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Container, Footer, Label } from "./styles";
import { BiInfoCircle } from "react-icons/bi";
import { RoutingVehicleJourneyResolver } from "../../../core/schemaValidations/routing_vehicle_journey";
import {
  ConfigRoutingRules,
  ConfigVehicleJourneyRules,
} from "../../../core/interfaces/ConfigRouting";
import { useDispatch } from "react-redux";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { ConfigRoutingService } from "../../../core/services/ConfigRoutingService";
import { isErrorResult } from "../../../core/utils/api";
import { MaskService } from "../../../utils/masks-service";
import { useAppSelector } from "../../../core/redux/hooks";
import { getUser } from "../../../core/redux/reducer/auth";

type FormValues = ConfigVehicleJourneyRules;

const VehicleJourney: React.FC = () => {
  const user = useAppSelector(getUser);
  const [responseGet, setResponseGet] =
    React.useState<ConfigVehicleJourneyRules>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const loadRoutingRules = async () => {
    try {
      const response =
        await ConfigRoutingService.getRoutingRulesVehicleJourney();
      if (!isErrorResult(response) && response) {
        setResponseGet({
          start_journey: response.data[0].roterization_start_journey,
          end_journey: response.data[0].roterization_end_journey,
          average_service_time:
            response.data[0].roterization_average_service_time,
          maximum_amount_of_jobs_per_route:
            response.data[0].roterization_maximum_amount_of_jobs_per_route,
          include_interval: response.data[0].roterization_include_interval,
          start_first_break: response.data[0].roterization_start_first_break,
          end_first_break: response.data[0].roterization_end_first_break,
          start_second_break: response.data[0].roterization_start_second_break,
          end_second_break: response.data[0].roterization_end_second_break,
          start_third_break: response.data[0].roterization_start_third_break,
          end_third_break: response.data[0].roterization_end_third_break,
        });
        setIsLoading(false);
      } else {
        throw new Error("Error loading routing rules.");
      }
    } catch (error: any) {
      alert(JSON.stringify(error?.message));
    }
  };

  React.useEffect(() => {
    loadRoutingRules();
  }, [user?.company_id]);

  React.useEffect(() => {
    if (!isLoading) {
      reset({
        start_journey: responseGet?.start_journey,
        end_journey: responseGet?.end_journey,
        average_service_time: responseGet?.average_service_time,
        maximum_amount_of_jobs_per_route:
          responseGet?.maximum_amount_of_jobs_per_route,
        include_interval: responseGet?.include_interval,
        start_first_break: responseGet?.start_first_break,
        end_first_break: responseGet?.end_first_break,
        start_second_break: responseGet?.start_second_break,
        end_second_break: responseGet?.end_second_break,
        start_third_break: responseGet?.start_third_break,
        end_third_break: responseGet?.end_third_break,
      });
    }
  }, [isLoading]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<FormValues>({
    resolver: RoutingVehicleJourneyResolver,
    defaultValues: responseGet,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      const response = await ConfigRoutingService.updateVehicleJourneyRules({
        start_journey: watch("start_journey"),
        end_journey: watch("end_journey"),
        average_service_time: watch("average_service_time"),
        maximum_amount_of_jobs_per_route: watch(
          "maximum_amount_of_jobs_per_route"
        ),
        include_interval: watch("include_interval") ? 1 : 0,
        start_first_break: watch("start_first_break"),
        end_first_break: watch("end_first_break"),
        start_second_break: watch("start_second_break"),
        end_second_break: watch("end_second_break"),
        start_third_break: watch("start_third_break"),
        end_third_break: watch("end_third_break"),
      });

      if (response?.message === "Vehicle journey successfully updated!") {
        dispatch(updateAlert({ title: t("message.success.save_data") }));
      } else {
        throw new Error("Error save vehicle journey.");
      }
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  };

  function handleCancel(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    reset(responseGet, {
      keepDirty: false,
      keepIsValid: false,
      keepErrors: false,
    });
  }

  return (
    <Container className="p-0">
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit, console.warn)}
        className="form"
      >
        <Label>{t("configs.routing.vehicles_journey_title")}</Label>
        <Row className="mb-4 col-lg-12">
          <Col className="col-lg-2">
            <Form.Group controlId="start_journey">
              <Form.Label>
                {t("configs.routing.start_journey")}{" "}
                <BiInfoCircle title="info" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="00:00:00"
                isInvalid={!!errors.start_journey?.message}
                {...register("start_journey", {
                  onChange: (e: any) => MaskService.toInputMask("time", e),
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.start_journey?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="col-lg-3">
            <Form.Group controlId="end_journey">
              <Form.Label>
                {t("configs.routing.end_journey")} <BiInfoCircle title="info" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="00:00:00"
                isInvalid={!!errors.end_journey?.message}
                {...register("end_journey", {
                  onChange: (e: any) => MaskService.toInputMask("time", e),
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.end_journey?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="col-lg-3">
            <Form.Group controlId="average_service_time">
              <Form.Label>
                {t("configs.routing.average_service_time")}{" "}
                <BiInfoCircle title="info" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="00:00:00"
                isInvalid={!!errors.average_service_time?.message}
                {...register("average_service_time", {
                  onChange: (e: any) => MaskService.toInputMask("time", e),
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.average_service_time?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="col-lg-4">
            <Form.Group controlId="maximum_amount_of_jobs_per_route">
              <Form.Label>
                {t("configs.routing.maximum_tasks_per_route")}{" "}
                <BiInfoCircle title="info" />
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                placeholder="00"
                isInvalid={!!errors.maximum_amount_of_jobs_per_route?.message}
                {...register("maximum_amount_of_jobs_per_route")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.maximum_amount_of_jobs_per_route?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Group controlId="include_interval">
              <Form.Check
                id="include_interval"
                type="switch"
                label={
                  <label htmlFor="include_interval">
                    {t("configs.routing.include_lunch_break")}{" "}
                    <BiInfoCircle title="info" />
                  </label>
                }
                {...register("include_interval")}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Row>
              <Form.Text>{t("configs.routing.first_interval")}</Form.Text>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="start_first_break">
                  <Form.Label>{t("configs.routing.start_interval")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00:00:00"
                    isInvalid={!!errors.start_first_break?.message}
                    {...register("start_first_break", {
                      onChange: (e: any) => MaskService.toInputMask("time", e),
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.start_first_break?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="end_first_break">
                  <Form.Label>{t("configs.routing.end_interval")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00:00:00"
                    isInvalid={!!errors.end_first_break?.message}
                    {...register("end_first_break", {
                      onChange: (e: any) => MaskService.toInputMask("time", e),
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.end_first_break?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Form.Text>
                {t("configs.routing.second_interval")}{" "}
                <Form.Text muted>({t("configs.routing.optional")})</Form.Text>
              </Form.Text>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="start_second_break">
                  <Form.Label>{t("configs.routing.start_interval")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00:00:00"
                    isInvalid={!!errors.start_second_break?.message}
                    {...register("start_second_break", {
                      onChange: (e: any) => MaskService.toInputMask("time", e),
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.start_second_break?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="end_second_break">
                  <Form.Label>{t("configs.routing.end_interval")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00:00:00"
                    isInvalid={!!errors.end_second_break?.message}
                    {...register("end_second_break", {
                      onChange: (e: any) => MaskService.toInputMask("time", e),
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.end_second_break?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Form.Text>
                {t("configs.routing.third_interval")}{" "}
                <Form.Text muted>({t("configs.routing.optional")})</Form.Text>
              </Form.Text>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="start_third_break">
                  <Form.Label>{t("configs.routing.start_interval")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00:00:00"
                    isInvalid={!!errors.start_third_break?.message}
                    {...register("start_third_break", {
                      onChange: (e: any) => MaskService.toInputMask("time", e),
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.start_third_break?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="end_third_break">
                  <Form.Label>{t("configs.routing.end_interval")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00:00:00"
                    isInvalid={!!errors.end_third_break?.message}
                    {...register("end_third_break", {
                      onChange: (e: any) => MaskService.toInputMask("time", e),
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.end_third_break?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer>
          <Stack className="mb-3" direction="horizontal">
            <Button
              id="vehicle_journey_submit"
              type="submit"
              disabled={!isDirty || !isValid}
            >
              {isSubmitting ? t("status.saving") : t("button.save")}
              {isSubmitting && <Spinner animation="border" size="sm" />}
            </Button>
            <Button className="ms-3" variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Footer>
      </Form>
    </Container>
  );
};

export default VehicleJourney;
