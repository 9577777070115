import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Row,
  Spinner,
  Stack,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Container } from "./styles";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";

import { ActionButtonOutline } from "../../components/ActionButtonOutline";
import { FaFilter } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import AddButton from "../../components/AddButton";
import { Navigate, useNavigate } from "react-router-dom";
import CustomSearchInput from "../../components/CustomSearchInput";
import TableActions from "../../components/TableActions";
import { useFieldArray, useForm } from "react-hook-form";
import { updateAlert } from "../../core/redux/reducer/alert";
import moment from "moment";
import ElectronicFenceFilterModal, {
  FenceFilterEvent,
} from "../../components/ElectronicFenceFilterModal";
import AssociatedVehiclesModal from "../../components/AssociatedVehiclesModal";
import RetroactModal from "../../components/RetroactModal";
import CustomThead from "../../components/CustomThead";
import { APiQueryBase } from "../../core/interfaces/ApiResponseBase";
import { GeofenceService } from "../../core/services/GeofenceService";
import { isErrorResult } from "../../core/utils/api";
import TableFooter from "../../components/TableFooter";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getUser } from "../../core/redux/reducer/auth";
import { useFormatDate } from "../../core/hooks/useFormatDate";
import { DateTimerPicker } from "../../components/DateTimerPicker";
import { format } from "date-fns";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";

interface IValues {
  selected_initial_date: string;
  selected_final_date: string;
}

const ElectronicFences: React.FC = () => {
  const [selectedInitialDate, setSelectedInitialDate] = useState("");
  const [selectedFinalDate, setSelectedFinalDate] = useState("");
  const [isBusy, setBusy] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | false>(false);
  const [selectedId, setSelectedId] = useState<number | false>(false);
  const [isLoading, setLoading] = useState(false);
  const [isOpenFilter, setOpenFilter] = React.useState<boolean>(false);
  const [actionShow, setActionShow] = React.useState<string | false>(false);
  const minDate = `2021-01-01T00:00`;
  const maxDate = `2099-01-01T00:00`;

  const [values, setValues] = useState<IValues>({
    selected_initial_date: "",
    selected_final_date: "",
  });

  function updateValues({ name, value }: { name: string; value: any }) {
    setValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  }

  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order?: { [key: string]: string };
  }>({ page: 1, pages: 0, filter: "" });

  type Register = any & {
    isEdited?: boolean;
    isSelected?: boolean;
  };

  type FormValues = {
    registers: Register[];
  };

  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { validateDateFormatAndTime } = useFormatDate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    control,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const actions = {
    associate_vhicles: t("electronic_fences.action_associate_vhicles"),
    // retroact: t("electronic_fences.action_retroact"),
    edit: t("electronic_fences.action_edit"),
    delete: t("electronic_fences.action_delete"),
  };
  const actionOptions = Object.values(actions);

  async function loadRegisters({ filter, page = 1, order }: APiQueryBase) {
    setLoading(true);
    try {
      const response = await GeofenceService.get({ filter, page, order });
      if (!isErrorResult(response) && response.data) {
        replace(response.data);
        setPagination((prev) => ({
          ...prev,
          page: response?.meta?.current_page || 1,
          pages: response?.meta?.last_page || 0,
        }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  function handleCancel(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
  }

  async function onSubmitUpdate({ registers }: FormValues) {
    try {
      for await (let data of registers) {
        // if (!!data[0].id && data[0].isEdited) {
        //   const response = await VehicleService.update(data[0]);
        //   if (!response?.success) throw Error(response?.message);
        //   const i = registers.findIndex((item) => item[0].id === data[0].id);
        //   data[0].isEdited = false;
        //   registers[i] = { ...data };
        // }
      }

      reset({ registers });

      dispatch(updateAlert({ title: t("message.success.save_data") }));
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  }

  function onSearch(text: string): void {
    const filter = { ...pagination.filter, search: text };

    setPagination((prev) => ({ ...prev, filter }));
  }

  function handleSelectAction(action: string, id: number) {
    setActionShow(action);

    if (action === actions.edit) navigate(`${id}`);
    if (action === actions.associate_vhicles) setSelectedId(id);
    if (action === actions.delete) setDeleteId(id);
  }

  async function switchFence(fenceId: number) {
    try {
      await GeofenceService.switchFence(fenceId);
      loadRegisters(pagination);
    } catch {}
  }

  function onChangeFilter(options: FenceFilterEvent) {
    const { tags = [], enabled, group_id, type, user } = options;

    const filter: any = {
      ...pagination.filter,
      type: type,
      enabled: enabled,
      group_id: group_id,
      tags_id: tags.map((element) => element.id),
    };

    if (!type?.length) delete filter.type;
    if (!tags?.length) delete filter.tags_id;
    if (!enabled) delete filter.enabled;
    if (!group_id?.length) delete filter.group_id;

    setPagination((prev) => ({ ...prev, filter }));
  }

  function haveEditedItems(): boolean {
    return !!watch("registers")?.find((item) => !!item?.isEdited);
  }

  async function onSubmitDelete() {
    if (!deleteId || isBusy) return;
    setBusy(true);

    const response = await GeofenceService.delete(deleteId);
    if (response?.success) {
      setBusy(false);
      setDeleteId(false);
      dispatch(updateAlert({ title: t("message.success.delete_many_data") }));
      loadRegisters(pagination);
    } else {
      setBusy(false);
      setDeleteId(false);
      dispatch(
        updateAlert({
          title: t("message.error.delete_data"),
          message: response.message,
          type: "error",
        })
      );
    }
  }

  useEffect(() => {
    if (values.selected_final_date || values.selected_initial_date) {
      setPagination((prev) => ({
        ...prev,
        filter: {
          date: {
            from: values.selected_initial_date,
            to: values.selected_final_date,
          },
        },
      }));
    }
  }, [values.selected_final_date, values.selected_initial_date]);

  useEffect(() => {
    loadRegisters(pagination);
  }, [pagination.filter, pagination.page, pagination.order, user?.company_id]);

  return (
    <PrivateMasterPage
      title={t("electronic_fences.title")}
      breadcrumb={[t("electronic_fences.title")]}
    >
      <ProtectedComponent
        module_name={module_names.GEOFENCES}
        property="view"
        fallbackComponent={<Navigate to={"/alerts"} />}
      >
        <Container>
          <Card className="m-3">
            <Card.Body className={(!fields?.length && "flex-none") + " p-4"}>
              <Row className="mb-4 d-flex justify-content-between">
                <Col className="d-flex align-items-center mb-3" xl={5} lg={4}>
                  <AddButton onClick={() => navigate("/fences/new")}>
                    {t("electronic_fences.button_add")}
                  </AddButton>
                </Col>

                <DateTimerPicker
                  label={t("electronic_fences.initial_data")}
                  returnDateFormat="yyyy-MM-dd HH:mm:ss"
                  value={selectedInitialDate}
                  onChange={(value) => {
                    setSelectedInitialDate(value);
                  }}
                  onSelectDate={(date) => {
                    updateValues({
                      name: "selected_initial_date",
                      value: date.formattedDate,
                    });
                  }}
                />
                <DateTimerPicker
                  label={t("electronic_fences.final_data")}
                  returnDateFormat="yyyy-MM-dd HH:mm:ss"
                  value={selectedFinalDate}
                  onChange={(value) => {
                    setSelectedFinalDate(value);
                  }}
                  onSelectDate={(date) => {
                    updateValues({
                      name: "selected_final_date",
                      value: date.formattedDate,
                    });
                  }}
                />

                {/* <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("electronic_fences.initial_data")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  min={minDate}
                  max={maxDate}
                  placeholder=""
                  onChange={(e) => {
                    console.log(e.target.value);
                  }}
                />
              </Form.Group> */}

                {/* <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("electronic_fences.final_data")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  min={minDate}
                  max={maxDate}
                  placeholder=""
                />
              </Form.Group> */}

                <Col className="d-flex align-items-end mb-3">
                  <Stack
                    className="d-flex align-items-end w-100"
                    direction="horizontal"
                    gap={3}
                  >
                    <CustomSearchInput
                      placeholder={t("search")}
                      onChange={(e) => onSearch(e.target.value)}
                    />

                    {/* <ActionButtonOutline>
                    <FiDownload size={20} />
                  </ActionButtonOutline> */}

                    <ActionButtonOutline onClick={() => setOpenFilter(true)}>
                      <FaFilter size={18} />
                    </ActionButtonOutline>
                  </Stack>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <Table responsive>
                    <CustomThead
                      data={[
                        {
                          column: "name",
                          name: t("electronic_fences.table_name"),
                        },
                        {
                          column: "type",
                          name: t("electronic_fences.table_type"),
                        },
                        {
                          column: "geofence_group_name",
                          name: t("electronic_fences.table_group"),
                        },
                        {
                          column: "created_at",
                          name: t("electronic_fences.table_created_at"),
                        },
                        {
                          column: "amount_events",
                          name: t("electronic_fences.table_qtd_alert"),
                        },
                        {
                          column: "geofence_author_name",
                          name: t("electronic_fences.table_author"),
                        },
                        {
                          column: "enabled",
                          name: t("electronic_fences.table_enable"),
                        },
                        { column: "menu", name: <th />, menu: true },
                      ]}
                      onChangeOrder={(order) =>
                        setPagination((prev) => ({ ...prev, order }))
                      }
                    />
                    <tbody>
                      {fields?.map((item, i) => (
                        <tr key={i}>
                          <td>{item.name}</td>
                          <td>{item.type}</td>
                          <td>{item.geofence_group_name}</td>
                          <td>{validateDateFormatAndTime(item.created_at)}</td>
                          <td>{item.amount_events}</td>
                          <td>{item.author?.name}</td>
                          <td>
                            <FormCheck
                              id={`${item?.id}`}
                              type="switch"
                              checked={item.enabled}
                              onChange={() => switchFence(item.id)}
                            />
                          </td>
                          <td>
                            <TableActions
                              id={`${item?.fieldId}.${i}.actions`}
                              items={actionOptions}
                              onSelectAction={(action) =>
                                handleSelectAction(action, item?.id)
                              }
                              module_name={module_names.GEOFENCES}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-center">
                  {isLoading && <Spinner animation="border" />}
                </Col>
              </Row>
            </Card.Body>
            <TableFooter
              actions={{
                hidden: !haveEditedItems(),
                titleSubmit: isSubmitting
                  ? t("status.saving")
                  : t("button.save"),
                titleCancel: t("button.cancel"),
                isSubmitting,
              }}
              paginate={{
                current_page: pagination.page,
                qtd: pagination.pages,
                onSelectPage: (page) =>
                  setPagination((prev) => ({ ...prev, page })),
              }}
            />
          </Card>

          <ElectronicFenceFilterModal
            visible={isOpenFilter}
            onClose={() => setOpenFilter(false)}
            onChange={onChangeFilter}
          />
          <AssociatedVehiclesModal
            selectedEletronicFenceId={selectedId}
            visible={actionShow === actions.associate_vhicles}
            onClose={() => setActionShow(false)}
            onChange={(data) =>
              console.log("Opção AssociatedVehiclesModal", data)
            }
          />
          {/* <AssociatedVehiclesModal
          visible={actionShow === actions.associate_vhicles}
          onClose={() => setActionShow(false)}
          onChange={(data) =>
            console.log("Opção AssociatedVehiclesModal", data)
          }
        />

        <RetroactModal
          visible={actionShow === actions.retroact}
          onClose={() => setActionShow(false)}
          onChange={(data) => console.log("Opção RetroactModal", data)}
        /> */}

          <ConfirmationModal
            visible={!!deleteId}
            title={t("electronic_fences.confirm_delete_title")}
            onClose={() => setDeleteId(false)}
            onConfirm={onSubmitDelete}
            isLoading={isBusy && !!deleteId}
          />
        </Container>
      </ProtectedComponent>
    </PrivateMasterPage>
  );
};

export default ElectronicFences;
