import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import CustomInputTag from "../CustomInputTag";
import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { FaFilter } from "react-icons/fa";
import { VehicleTypeService } from "../../core/services/VehicleTypeService";
import {
  useForm,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
} from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";

type FormValues = {
  vehicle_type: { label: string; value: number };
  tags: string[];
};

export type VehicleFilterEvent = {
  vehicle_type: number;
  tags: IVehicleTag[];
};

interface VehicleFilterModalProps {
  visible: boolean;
  onClose?(): void;
  onChange?(event: VehicleFilterEvent): void;
}

const VehicleFilterModal: React.FC<VehicleFilterModalProps> = ({
  onClose,
  visible,
  onChange,
  ...props
}) => {
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<FormValues>();

  const handleCancel = () => {
    reset();
    onClose?.();
  };

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const response = await VehicleTagService.getAllVehicleTags();
      if (response) {
        setVehicleTags(response);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const tags = vehicleTags?.filter((item) =>
      values?.tags?.includes(item.key)
    );
    onChange?.({ tags, vehicle_type: values?.vehicle_type?.value });
    onClose?.();
  };

  const onError: SubmitErrorHandler<FormValues> = (error) => {
    console.log("onSubmitError", error);
  };

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
    }
  }, [visible]);

  return (
    <Modal {...props} show={visible} size="md" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <FaFilter size={18} />
        <Modal.Title>{t("text.filter")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        <Form>
          <Row className="mt-4 mb-3">
            <Form.Group as={Col} lg={6} md={12} controlId="vehicle_type">
              <Form.Label>{t("configs.fleetmate.ei.vehicle_type")}</Form.Label>
              <Controller
                control={control}
                name="vehicle_type"
                render={({ field: { value, onChange, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    placeholder={t("input.select_text")}
                    loadOptions={(inputValue, options, additional) =>
                      VehicleTypeService.loadVehicleTypeOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>
          </Row>
        </Form>

        <Row>
          <Col>
            <Form.Group className="mb-4" controlId="name" as={Col}>
              <Form.Label>
                {t("configs.fleetmate.ei.attributes_tag")}
              </Form.Label>
              <Controller
                name="tags"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <CustomInputTag
                    defaultSelected={value}
                    onChange={(values: string[]) => onChange(values)}
                    options={vehicleTags?.map((op) => op.key)}
                    isLoading={isLoadingTags}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button onClick={handleSubmit(onSubmit, onError)}>
              {t("button.save")}
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default VehicleFilterModal;
