import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { StartPointResolver } from "../../../core/schemaValidations/start_point";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import { API_LOADER_MAP } from "../../../constants/map";
import PlacesSearchBox, {
  OnChangeData,
} from "../../../components/PlacesSearchBox";
import { StartPointService } from "../../../core/services/StartPointService";
import { StartPoint } from "../../../core/interfaces/StartPoint";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { useAppDispatch } from "../../../core/redux/hooks";

interface MarkerOptions extends google.maps.LatLngLiteral {
  title: string;
}

interface ExitLocationsModalProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
  itemEdit?: StartPoint;
}

type FormValues = {
  name: string;
  address: string;
  longitude: number;
  latitude: number;
  enabled: number;

  location: string;
  control_type: "address" | "coords";
};

const ExitLocationsForm: React.FC<ExitLocationsModalProps> = ({
  visible = false,
  onClose,
  itemEdit,
  onSuccess,
  ...props
}) => {
  const [marker, setMarker] = useState<MarkerOptions>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -27.590824,
    lng: -48.551262,
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isLoaded } = useJsApiLoader(API_LOADER_MAP);
  // const { isLoaded } = useLoadScript(API_LOADER_MAP);

  const {
    control,
    register,
    watch,
    reset,
    setValue,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: StartPointResolver,
    defaultValues: { control_type: "address", enabled: 1 },
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const { control_type, location, ...data } = values;

    try {
      if (itemEdit?.id) {
        await StartPointService.update({ ...itemEdit, ...data });
        dispatch(updateAlert({ title: t("message.success.save_data") }));
      } else {
        await StartPointService.create(data);
        dispatch(updateAlert({ title: t("message.success.save_data") }));
      }

      if(onSuccess) onSuccess();
    } catch (error: any) {
      alert(JSON.stringify(error));
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    reset();
    onClose?.();
    setCenter({
      lat: -27.590824,
      lng: -48.551262,
    });
    setMarker(undefined);
  };

  const handleAddressLocation = (data: OnChangeData) => {
    // console.log("Lod data", data);

    if (data.lat && data.lng) {
      setCenter({ lat: data.lat, lng: data.lng });
      setMarker({ title: data?.address, lat: data.lat, lng: data.lng });

      setValue("address", data.address);
      setValue("latitude", data.lat);
      setValue("longitude", data.lng);
    }
  };

  const onLoad = (marker: any) => {
    console.log("marker: ", marker);
  };

  React.useEffect(() => {
    if (visible && !!itemEdit) {
      setValue("name", itemEdit.name);
      setValue("address", itemEdit.address);
      setValue("latitude", itemEdit.latitude);
      setValue("longitude", itemEdit.longitude);
      setValue("location", itemEdit.address);

      setCenter({
        lat: itemEdit.latitude,
        lng: itemEdit.longitude,
      });
      setMarker({
        title: itemEdit?.address,
        lat: itemEdit.latitude,
        lng: itemEdit.longitude,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, itemEdit]);

  return (
    <Modal
      {...props}
      show={visible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header className="p-4 pb-2 pt-3" closeButton>
        <Modal.Title>{t("configs.exit_location.add")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Row style={{ paddingLeft: "1.5em" }}>
          <Col xs="5">
            <Form
              className="h-100 d-flex flex-column"
              onSubmit={handleSubmit(onSubmit, console.warn)}
            >
              <Row>
                <Form.Group className="mb-3 mt-4" controlId="moment">
                  <Form.Label>
                    {t("configs.exit_location.form_location_name")}
                  </Form.Label>
                  <Form.Control
                    placeholder={t(
                      "configs.exit_location.form_placeholder_name"
                    )}
                    {...register("name")}
                    isInvalid={!!errors?.name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="h-100">
                <div className="mb-3" style={{ flex: 1 }}>
                  <div style={{ display: "flex" }}>
                    <Form.Label style={{ flex: 1 }}>
                      {t("configs.exit_location.form_location") + "*"}
                    </Form.Label>

                    <Form.Check
                      id="address"
                      type="radio"
                      label={t("configs.exit_location.form_address")}
                      value="address"
                      {...register("control_type")}
                    />
                    <Form.Check
                      id="coords"
                      type="radio"
                      label={t("configs.exit_location.form_coordinates")}
                      value="coords"
                      className="ms-3"
                      {...register("control_type")}
                    />
                  </div>

                  <Controller
                    name="location"
                    control={control}
                    render={({ field: { value, name, onChange, onBlur } }) => (
                      <PlacesSearchBox
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        className="w-100"
                        apiMapLoaded={isLoaded}
                        onChangeSelected={handleAddressLocation}
                        value={value}
                        placeholder={
                          watch("control_type") === "address"
                            ? t(
                                "configs.exit_location.form_placeholder_location"
                              )
                            : "-000.000000000, +000.00000000000"
                        }
                        controlType={watch("control_type")}
                        isInvalid={!!errors?.location?.message}
                        error={errors?.location?.message}
                      />
                    )}
                  />
                </div>
              </Row>

              <Stack className="mb-3" direction="horizontal" gap={2}>
                <Button type="submit">
                  {isSubmitting ? t("status.saving") : t("button.save")}
                  {isSubmitting && <Spinner animation="border" size="sm" />}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  {t("configs.company.cancel")}
                </Button>
              </Stack>
            </Form>
          </Col>

          <Col lg="7">
            {isLoaded && (
              <GoogleMap
                id={API_LOADER_MAP.id}
                mapContainerStyle={{
                  width: "100%",
                  height: "60vh",
                  maxHeight: 677,
                }}
                zoom={15}
                center={center}
              >
                {!!marker?.lat && !!marker.lng && (
                  <Marker
                    onLoad={onLoad}
                    title={marker?.title}
                    position={center}
                  />
                )}
              </GoogleMap>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ExitLocationsForm;
