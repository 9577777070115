import styled from "styled-components";

export const InputContainer = styled.div`
  button {
    right: 3%;
    top: 10%;
    border: 0;
    background: transparent;
  }
`;
