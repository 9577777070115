import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import CustomInputTag from "../CustomInputTag";
import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { FaFilter } from "react-icons/fa";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { TYPES } from "../../constants/geofence";
import { GeofenceService } from "../../core/services/GeofenceService";
import DropdownSearchAndCheck, { Items } from "../DropdownSearchAndCheck";

export interface FenceFilterEvent {
  tags?: IVehicleTag[];
  group_id?: any[];
  user?: any[];
  type?: any[];
  enabled?: boolean;
}

type FormValues = {
  tags: string[];
  groups?: { id: string; label: string; checked: boolean }[];
  user?: { id: number; label: string; checked: boolean }[];
  type?: { id: number; label: string; checked: boolean }[];
  enabled?: boolean;
};

interface ElectronicFenceFilterModalProps {
  visible: boolean;
  onClose?(): void;
  onChange?(data: FenceFilterEvent): void;
}

const ElectronicFenceFilterModal: React.FC<ElectronicFenceFilterModalProps> = ({
  onClose,
  visible,
  onChange,
  ...props
}) => {
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);
  const [authorsOptions, setAuthorsOptions] = React.useState([]);
  const [groupOptions, setGroupOptions] = React.useState([]);

  const { t } = useTranslation();
  const { register, control, handleSubmit, reset, getValues } =
    useForm<FormValues>();

  const handleCancel = () => {
    reset();
    onClose?.();
  };

  function handleDropdownItems(items: Items[], type?: string) {
    if (type === "string") {
      return items
        .filter((item) => item.checked)
        .map((item) => t(String(item.id), { lng: "en" }).toLowerCase());
    }

    return items.filter((item) => item.checked).map((item) => Number(item.id));
  }

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  async function loadAuthorsOptions() {
    try {
      const response = await GeofenceService.getAuthors({
        filter: "",
        page: 1,
      });
      const isError = isErrorResult(response);

      if (!isError && response.data) {
        const options = response.data.map((d: any) => ({
          label: `${d.name}`,
          value: d.id,
        }));

        setAuthorsOptions(options);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async function loadGroupOptions() {
    try {
      const response = await GeofenceService.getGroup({
        page: 1,
      });
      const isError = isErrorResult(response);

      if (!isError && response.data) {
        const options = response.data.map((d: any) => ({
          label: `${d.name}`,
          value: d.id,
        }));

        setGroupOptions(options);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const tags = vehicleTags?.filter((item) =>
      values?.tags?.includes(item.key)
    );
    onChange?.({
      tags,
      type: handleDropdownItems(values?.type ?? [], "string"),
      group_id: handleDropdownItems(values?.groups ?? []),
      user: handleDropdownItems(values?.user ?? []),
      enabled: values.enabled,
    });
    onClose?.();
  };

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
      loadAuthorsOptions();
      loadGroupOptions();
    }
  }, [visible]);

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <FaFilter size={18} />
        <Modal.Title>{t("text.filter")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mt-5 mb-3">
          <Col>
            <Form.Group controlId="tags" as={Col}>
              <Form.Label>
                {t("configs.fleetmate.ei.attributes_tag")}
              </Form.Label>
              <Controller
                name="tags"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <CustomInputTag
                    defaultSelected={value}
                    onChange={(values: string[]) => onChange(values)}
                    options={vehicleTags?.map((op) => op.key)}
                    isLoading={isLoadingTags}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>{t("electronic_fences.table_author")}</Form.Label>
              <Controller
                control={control}
                name="user"
                render={({ field: { value, onChange, onBlur } }) => (
                  <DropdownSearchAndCheck
                    values={value}
                    onChange={onChange}
                    label={t("input.select_text")}
                    search={false}
                    items={authorsOptions?.map((item: any, key) => ({
                      id: item.value,
                      label: t(item.label),
                      checked: getValues(`user.${key}.checked`),
                    }))}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>{t("electronic_fences.table_group")}</Form.Label>
              <Controller
                control={control}
                name="groups"
                render={({ field: { value, onChange } }) => (
                  <DropdownSearchAndCheck
                    values={value}
                    onChange={onChange}
                    label={t("input.select_text")}
                    search={false}
                    items={groupOptions?.map((item: any, key: number) => ({
                      id: item.value,
                      label: item.label,
                      checked: getValues(`groups.${key}.checked`),
                    }))}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Group>
              <Form.Label>{t("electronic_fences.table_type")}</Form.Label>
              <Controller
                control={control}
                name="type"
                render={({ field: { value, onChange } }) => (
                  <DropdownSearchAndCheck
                    values={value}
                    onChange={onChange}
                    label={t("input.select_text")}
                    search={false}
                    items={TYPES?.map((item, key: number) => ({
                      id: item.label,
                      label: t(item.label),
                      checked: getValues(`type.${key}.checked`),
                    }))}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="pt-4 mt-3" controlId="enabled-fences">
              <Form.Switch
                label={t("electronic_fences.show_enabled_fences")}
                defaultChecked={false}
                {...register("enabled")}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button onClick={handleSubmit(onSubmit)}>{t("button.save")}</Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ElectronicFenceFilterModal;
