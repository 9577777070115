/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";

export type OnChangeData = {
  address: string;
  lat: number;
  lng: number;
};

export interface IPlacesSearchBox {
  className?: string;
  name: string;
  value?: string;
  placeholder?: string;
  onChange?(value: string): void;
  onChangeSelected?(value: OnChangeData): void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  apiMapLoaded: boolean;
  isInvalid?: boolean;
  controlType: "address" | "coords";
  error?: string;
  disabled?: boolean;
}

const PlacesSearchBox: React.FC<IPlacesSearchBox> = (props) => {
  const [places, setPlaces] = useState<string[]>([]);

  const { t } = useTranslation();

  const inputProps = {
    name: props.name,
    id: props.name,
    style: !!props.disabled
      ? { opacity: 0.5, backgroundColor: "transparent" }
      : {},
  };

  async function handlePlacesAndCoords() {
    let input = props.value;
    let div = document.getElementById(props.name);

    if (!props.apiMapLoaded || !input || !div) return;
    const service = new google.maps.places.AutocompleteService();
    await service.getPlacePredictions({ input }, (predictions, status) => {
      if (status === "OK") {
        setPlaces(predictions?.map(({ description }) => description) || []);
      } else {
        console.log("Erro ao buscar locais.");
      }
    });
  }

  const handleGeocoderLatLng = (latLng: string) => {
    const latlngStr = latLng?.split(",", 2);
    const latlng = new google.maps.LatLng({
      lat: parseFloat(latlngStr[0]),
      lng: parseFloat(latlngStr[1]),
    });

    const geoService = new google.maps.Geocoder();
    geoService.geocode({ location: latlng }, (data, status) => {
      if (status === "OK" && !!data) {
        const [geoResult] = data;
        props.onChange?.(latLng);
        props.onChangeSelected?.({
          address: geoResult.formatted_address,
          lat: geoResult?.geometry?.location.lat?.(),
          lng: geoResult?.geometry?.location?.lng?.(),
        });
      } else {
        console.log("Erro ao buscar coordenadas do local.");
      }
    });
  };

  const handleGeocoderAddress = (address: string) => {
    const geoService = new google.maps.Geocoder();
    geoService.geocode({ address }, (data, status) => {
      if (status === "OK" && !!data) {
        const [geoResult] = data;
        props.onChange?.(geoResult.formatted_address);
        props.onChangeSelected?.({
          address: geoResult.formatted_address,
          lat: geoResult?.geometry?.location.lat?.(),
          lng: geoResult?.geometry?.location?.lng?.(),
        });
      } else {
        console.log("Erro ao buscar coordenadas do local.");
      }
    });
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      const value = props.value || "";

      switch (props.controlType) {
        case "coords":
          return handleGeocoderLatLng(value);
        case "address":
          return handleGeocoderAddress(value);
      }
    }
  };

  const handleTypeaheadInputChange = (selected: any[]) => {
    if (!selected[0]) return;
    const [value] = selected;

    switch (props.controlType) {
      case "coords":
        return handleGeocoderLatLng(value);
      case "address":
        return handleGeocoderAddress(value);
    }
  };

  useEffect(() => {
    console.warn = function () {};
    props.controlType === "address" && handlePlacesAndCoords();
    props.controlType === "coords" && setPlaces([]);
  }, [props.apiMapLoaded, props.name, props.value, props.controlType]);

  return (
    <Form.Group className="mb-3">
      <Typeahead
        disabled={props.disabled}
        id={`typeahead_${props.name}`}
        onKeyDown={handleKeyDown}
        placeholder={props.placeholder}
        emptyLabel={t("component.input_tag.emptylabel")}
        inputProps={inputProps}
        options={places}
        onBlur={props.onBlur}
        selected={props.value ? [props.value] : []}
        onInputChange={(value) => {
          props.onChange?.(value);
        }}
        onChange={handleTypeaheadInputChange}
        isInvalid={props.isInvalid}
        className={props.className}
        flip
      />

      <Row>
        {props.controlType === "coords" && (
          <Form.Text>{t("search_box_places.coords")}</Form.Text>
        )}
        {props.error && (
          <Form.Text className="text-danger">{props.error}</Form.Text>
        )}
      </Row>
    </Form.Group>
  );
};

export default PlacesSearchBox;
