import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between !important;
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem !important;
  margin-bottom: 1rem !important;

  button {
    height: fit-content;
  }
`;

export const PaginateContainer = styled.div`
  display: flex;
  justify-content: flex-end !important;
  width: 100%;
  margin-bottom: 1rem !important;
`;
