import { api } from ".";
import {
  APiQueryBase,
  APiQueryJSONBase,
  ApiResponseBase,
} from "../interfaces/ApiResponseBase";
import {
  Driver,
  DriverQuery,
  SwitchActivateDeactivate,
  SwitchAssociateDriver,
} from "../interfaces/Driver";
import { ErrorResult } from "../interfaces/ErrorResult";
import { isErrorResult } from "../utils/api";

export class DriverService {
  static async get(
    params?: DriverQuery
  ): Promise<ApiResponseBase<Driver[]> | ErrorResult> {
    const { filter, order, per_page = 10 } = { ...params };
    return await api
      .get(`/api/v2/driver`, {
        params: {
          ...params,
          per_page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadDriverOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await DriverService.get({
      page,
      filter: {
        name: search,
      },
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d) => ({
      label: `${d.name} ${d.last_name}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async getDriversByKey(
    keys: string,
    params?: DriverQuery
  ): Promise<ApiResponseBase<Driver[]>> {
    const { filter, order, per_page = 10 } = { ...params };
    return await api
      .get(`/api/v2/driver-search-for-keys`, {
        params: {
          ...params,
          keys,
          per_page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data);
  }

  static async switchAssociate(
    data: SwitchAssociateDriver
  ): Promise<ErrorResult> {
    return await api.post("/api/v2/driver/associate", data).then((r) => r.data);
  }

  static async switchActivateDeactivate(
    data: SwitchActivateDeactivate
  ): Promise<ErrorResult> {
    return await api.post("/api/v2/driver/activate-deactivate", data).then((r) => r.data);
  }
}
