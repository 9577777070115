import { api } from ".";
import { IFenceBody } from "../../pages/ElectronicFencesForm";
import {
  APiQueryBase,
  APiQueryJSONBase,
  ApiResponseBase,
} from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { isErrorResult } from "../utils/api";
import { Geofence } from "../interfaces/Geofence";
import { t } from "i18next";

export class GeofenceService {
  static async get({
    filter,
    page,
    order,
  }: APiQueryBase): Promise<ApiResponseBase<Geofence[]> | ErrorResult> {
    return await api
      .get("/api/v2/geofence", {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getById(
    fenceId: string
  ): Promise<ApiResponseBase<Geofence> | ErrorResult> {
    return await api
      .get(`/api/v2/geofence/${fenceId}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getByGroup({
    filter,
    page,
    group,
  }: any): Promise<ApiResponseBase<any> | ErrorResult> {
    return await api
      .get(`/api/v2/geofence`, {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(fenceId: string, data: IFenceBody) {
    return await api
      .put(`/api/v2/geofence/${fenceId}`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async associate_vehicle(fenceId: number, data: any) {
    return await api
      .post(`/api/v2/geofence/${fenceId}/associate`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getAuthors({ filter, page }: APiQueryBase) {
    return await api
      .get("/api/v2/geofence/authors", {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getGroup({ filter, page }: APiQueryJSONBase<any>) {
    return await api
      .get("/api/v2/geofence/group", {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadGeofaceById(
    search: string,
    loadedOptions: any,
    additional?: any,
    group?: any
  ) {
    const { page } = additional;
    const response = await this.getByGroup({
      page,
      filter: {
        search: search,
        group_id: group,
      },
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d: any) => ({
      label: `${d.name}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async loadGroupTypeOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await this.getGroup({
      page,
      filter: {
        description: search,
      },
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d: any) => ({
      label: `${t(d.name)}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async create(data: IFenceBody): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/geofence`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(fenceId: number): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/geofence/${fenceId}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async switchFence(fenceId: number) {
    return await api
      .post(`/api/v2/geofence/${fenceId}/activate-deactivate`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getWithFilter({
    filter,
    page,
  }: APiQueryJSONBase<any>): Promise<
    ApiResponseBase<any[]> | ErrorResult
  > {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/geofence`, {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadGeofenceOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await this.getWithFilter({
      page,
      filter: {
        search: search,
      },
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d) => ({
      label: `${t(d.name)}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }
}
