import styled from "styled-components";
import { Typeahead as BsTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

export const Typeahead = styled(BsTypeahead)`
  .rbt-input {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
    min-height: 120px;
    z-index: 0;
  }

  .rbt-input-wrapper {
    gap: 4px;
    display: flex;
    align-items: center;
  }

  .rbt-token {
    background-color: #007bff;
    border-radius: 62px;
    padding: 7px 4px;
    gap: 8px;
    color: #f8f9fa;
    display: flex;
    align-items: center;
  }

  .rbt-token-label {
    font-family: sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #f8f9fa;
  }

  .rbt-token .rbt-token-remove-button {
    height: 12px;
    width: 12px;
    font-weight: 500;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;
    padding-top: 0px;
  }

  .rbt-token .rbt-token-remove-button:hover {
    opacity: 0.5;
  }
`;
