import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "../core/redux/hooks";
import { getIsSigned } from "../core/redux/reducer/auth";
import Alerts from "../pages/Alerts";
import Configs from "../pages/Configs";
import Dashboard from "../pages/Dashboard";
import Drivers from "../pages/Drivers";
import DriversForm from "../pages/DriversForm";
// import Driversjourney from "../pages/Driversjourney";
import DrivingBehavior from "../pages/DrivingBehavior";
import DrivingBehaviorDetails from "../pages/DrivingBehaviorDetails";
import ElectronicFences from "../pages/ElectronicFences";

import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import NotFoundPublic from "../pages/NotFoundPublic";

import Sensors from "../pages/Sensors";
import Reports from "../pages/Reports";
import AlertRule from "../pages/Sensors/AlertRule";
import AlertRuleForm from "../pages/Sensors/AlertRuleForm";
import SensorDetail from "../pages/Sensors/SensorDetail";
import SignUp from "../pages/SignUp";

import Vehicles from "../pages/Vehicles";
import VehiclesCreate from "../pages/Vehicles/Create";
import ResetPassword from "../pages/ResetPassword";
import { FallbackSpinner } from "../components/FallbackSpinner";

const Tracking = lazy(() => import("../pages/Tracking"));
const Trips = lazy(() => import("../pages/Trips"));
const RoutingTracking = lazy(() => import("../pages/RoutingTracking"));
const RoutingTasks = lazy(() => import("../pages/RoutingTasks"));
const TripDetails = lazy(() => import("../pages/Trips/TripDetails"));
const Routing = lazy(() => import("../pages/Routing"));
const RoutingForm = lazy(() => import("../pages/RoutingForm"));
const Driversjourney = lazy(() => import("../pages/Driversjourney"));
const ElectronicFencesForm = lazy(
  () => import("../pages/ElectronicFencesForm")
);

const Redirect: React.FC<{ to: string }> = ({ to }) => {
  window.location.href = to;

  return <div></div>;
};

const RoutesRoot: React.FC = () => {
  const { ready } = useTranslation();
  const isSigned = useAppSelector(getIsSigned);

  if (!ready) return <div>carregando...</div>;

  return (
    <Routes>
      {!isSigned && (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login/forgot-password" element={<ForgotPassword />} />
          <Route path="/password/reset/:token" element={<ResetPassword />} />
          <Route path="*" element={<NotFoundPublic />} />
        </>
      )}
      {!!isSigned && (
        <>
          <Route path="/" element={<Redirect to="/tracking" />} />
          <Route path="/drivers">
            <Route path="new" element={<DriversForm />} />
            <Route index element={<Drivers />} />
          </Route>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/routing">
            <Route
              index
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <Routing />
                </Suspense>
              }
            />
            <Route
              path="tracking/:id"
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <RoutingTracking />
                </Suspense>
              }
            />
            <Route path="/routing/task">
              <Route
                index
                element={
                  <Suspense fallback={<FallbackSpinner />}>
                    <RoutingTasks />
                  </Suspense>
                }
              />
              <Route
                path="new"
                element={
                  <Suspense fallback={<FallbackSpinner />}>
                    <RoutingForm />
                  </Suspense>
                }
              />
              <Route
                path="edit/:id"
                element={
                  <Suspense fallback={<FallbackSpinner />}>
                    <RoutingForm />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path="/configs/*" element={<Configs />} />
          <Route path="/vehicles" element={<Vehicles />} />
          <Route path="/vehicles/new" element={<VehiclesCreate />} />
          <Route path="/vehicles/edit" element={<VehiclesCreate />} />
          <Route path="/trips">
            <Route
              path="details"
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <TripDetails />
                </Suspense>
              }
            />
            <Route
              index
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <Trips />
                </Suspense>
              }
            />
          </Route>
          <Route path="/sensors">
            <Route index element={<Sensors />} />
            <Route path=":id" element={<SensorDetail />} />
            <Route path="alerts">
              <Route index element={<AlertRule />} />
              <Route path="new" element={<AlertRuleForm />} />
              <Route path=":id" element={<AlertRuleForm />} />
            </Route>
          </Route>
          <Route path="/reports">
            <Route index element={<Reports />} />
          </Route>
          <Route
            path="/journey"
            element={
              <Suspense fallback={<FallbackSpinner />}>
                <Driversjourney />
              </Suspense>
            }
          />
          <Route path="/fences">
            <Route index element={<ElectronicFences />} />
            <Route
              path="new"
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <ElectronicFencesForm />
                </Suspense>
              }
            />
            <Route
              path=":id"
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <ElectronicFencesForm />
                </Suspense>
              }
            />
          </Route>
          <Route path="/tracking">
            <Route
              index
              element={
                <Suspense fallback={<FallbackSpinner />}>
                  <Tracking />
                </Suspense>
              }
            />
          </Route>
          <Route path="/driving-behavior">
            <Route index element={<DrivingBehavior />} />
            <Route path="details" element={<DrivingBehaviorDetails />} />
          </Route>
          <Route path="/alerts" element={<Alerts />} />
          <Route path="*" element={<NotFound />} />
        </>
      )}
    </Routes>
  );
};

export default RoutesRoot;
