import { Response } from "./../interfaces/Response";
import { api } from ".";
import {
  APiQueryBase,
  APiQueryJSONBase,
  ApiResponseBase,
} from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import {
  GenerateActuatorCode,
  GenerateActuatorCodeResponse,
  ICamBody,
  IReportsVehicles,
  LiveStream,
  Vehicle,
  VehicleBody,
} from "../interfaces/Vehicle";
import { isErrorResult } from "../utils/api";

export class VehicleService {
  static async get({
    filter,
    page,
    per_page = 10,
    order,
  }: APiQueryBase): Promise<ApiResponseBase<Vehicle[]> | ErrorResult> {
    // const filterQuery = filter
    //   ? `&conditions=license_plate:like:%${filter}%;vehicle_type_id:like:%${filter}%`
    //   : "";

    return await api
      .get("/api/v2/vehicle", {
        params: {
          page,
          per_page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  static async getVehicleById(
    vehicleId: number
  ): Promise<ApiResponseBase<Vehicle[]> | ErrorResult> {
    return await api
      .get(`/api/v2/vehicle/${vehicleId}`, {})
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getWithFilter({
    filter,
    page,
  }: APiQueryJSONBase<Vehicle>): Promise<
    ApiResponseBase<Vehicle[]> | ErrorResult
  > {
    return await api
      .get(`/api/v2/vehicle?page=${page}`, {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(data: VehicleBody): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/vehicle`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(data: VehicleBody): Promise<ErrorResult> {
    return await api
      .put(`/api/v2/vehicle`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(id: number): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/vehicle`, { data: { id } })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async switchAssociate(data: {
    vehicle_id: number;
    associate_disassociate: number;
  }): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/vehicle`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadVehicleOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await this.getWithFilter({
      filter: { license_plate: search },
      page,
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d) => ({
      label: `${d.license_plate}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async generateActuatorCode(
    data: GenerateActuatorCode
  ): Promise<GenerateActuatorCodeResponse> {
    return await api
      .post("/api/v2/vehicle/generate-actuator-code", data)
      .then((r) => r.data);
  }

  static async getModelsCam() {
    return await api
      .get("/api/v2/camera/models")
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getCam(vehicleId: string) {
    return await api
      .get(`/api/v2/camera/${vehicleId}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async postCam(data: ICamBody) {
    return await api
      .post("/api/v2/camera", data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async putCam(data: ICamBody, vehicleId: string) {
    return await api
      .put(`/api/v2/camera/${vehicleId}`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async liveStream(data: LiveStream) {
    return await api
      .post(`/api/v2/camera/live-stream`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async loadModelsCam() {
    const response = await this.getModelsCam();
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    options.options = response?.map((d: any) => ({
      label: `${d.name}`,
      value: d.id,
    }));

    return options;
  }

  static async auditReport(data: IReportsVehicles) {
    return await api
      .post(`/api/v2/audit/export-consolidated-report`, data)
      .then((r) => r.data)
      .catch((e) => e?.Response?.data);
  }

  static async getVehiclesPositions(id: number) {
    return await api
      .get(`/api/v2/tracking/get-vehicles/${id}`)
      .then((r) => r.data)
      .catch((e) => e?.Response?.data);
  }

  static async fetchFile(path: string) {
    return await api
      .post("/api/v2/file/get-blob", { path }, { responseType: 'blob' })
      .then((r) => r)
      .catch((e) => e?.Response?.data);
  }
}
