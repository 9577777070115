import styled from "styled-components";
import BSContainer from "react-bootstrap/Container";

export const Container = styled(BSContainer)`
  height: 100vh;
  background-color: #fff;

  .card {
    background: #ffffff;
    border: none;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  }

  & > .row {
    height: 100%;
  }

  .sidebar {
    min-height: 100%;
    max-height: max-content;
  }

  .content-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
  }

  .content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .table-responsive {
    border-radius: 10px;
  }

  table {
    thead,
    tbody,
    tr,
    th,
    td {
      border: none !important;
    }

    th,
    td {
      padding: 10px 15px;
    }

    thead {
      font-size: 0.9rem;
      /* background: #f2f2f2; */
      /* border-color: transparent; */
    }

    thead th {
      background: #f2f2f2;
    }

    thead th:nth-child(1) {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    thead th:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`;
