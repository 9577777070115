import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Nav,
  Row,
  Spinner,
  Stack,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Container, CustomButton, CustomLink } from "./styles";
import { isErrorResult } from "../../../core/utils/api";
import Pagination from "../../../components/Pagination";
import TableActions from "../../../components/TableActions";
import CustomSearchInput from "../../../components/CustomSearchInput";
import Footer from "../../../components/Footer";
import EmptyListMessage from "../../../components/EmptyListMessage";
import { useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { FaFilter } from "react-icons/fa";
import { ActionButtonOutline } from "../../../components/ActionButtonOutline";
import { APiQueryBase } from "../../../core/interfaces/ApiResponseBase";
import AddButton from "../../../components/AddButton";
import { useNavigate, useParams } from "react-router-dom";
import CustomThead from "../../../components/CustomThead";
import { SecuryRulesGroup } from "../../../core/services/SecuryRulesGroup";
import moment from "moment";
import { IVehicleTag } from "../../../core/interfaces/VehicleTag";
import VehicleFilterModal from "../../../components/VehicleFilterModal";
import { getUser } from "../../../core/redux/reducer/auth";
import { useFormatDate } from "../../../core/hooks/useFormatDate";
import AssociateVehicleAndTagsModal from "../../../components/AssociateVehicleAndTagsModal";
import { FleetMateService } from "../../../core/services/FleetMateService";
import LinkGoBack from "../../../components/LinkGoBack";
import { BsArrowLeftShort } from "react-icons/bs";
import FleetMateGroupSettings from "../Settings/FleetMateGroupSettings";

type Register = {
  id?: number;
  name: string;
  enabled?: boolean;
  user_id: number;
  user_name: string;
  amount_vehicles: number;
  created_at: string;
  updated_at: string;
};

type FormValues = {
  registers: Register[];
};

export type VehicleFilterEvent = {
  vehicle_type: number;
  tags: IVehicleTag[];
};

const GroupsConfigs: React.FC = () => {
  const { id } = useParams();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  // const [associateModalVisible, setAssociateModalVisible] = useState(true);
  const [setupForm, setSetupForm] = useState<boolean>(false);

  const [groupConfigId, setGroupId] = useState(0);
  const [associateModalVisible, setAssociateModalVisible] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [selectedInitialDate, setSelectedInitialDate] = useState<string>("");
  const [selectedFinalDate, setSelectedFinalDate] = useState<string>("");
  const [defaultValues, setDefaultValues] = useState<FormValues>();
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order?: { [key: string]: string };
  }>({ page: 1, pages: 0, filter: "" });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { validateDateFormatAndTime } = useFormatDate();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const actions = {
    edit: t("text.edit"),
    vehicle_association: t("configs.fleetmate.ei.action_vehicle_association"),
  };
  const actionOptions = Object.values(actions);

  async function onSubmitUpdate({ registers }: FormValues) {
    try {
      for await (let data of registers) {
        if (!!data.id && data) {
          //@ts-ignore
          if (data.enabled === true || data.enabled === false) {
            await SecuryRulesGroup.patch(data.id, data.enabled);
          }
        }
      }

      reset({ registers });
      loadEmbeddedIntelligences(pagination);
      dispatch(
        updateAlert({
          title: t("message.success.save_data"),
        })
      );
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleEditCancel = async () => {
    replace(fields);
  };
  const handleCloseAssociateModal = async () => {
    setAssociateModalVisible(false);
    loadEmbeddedIntelligences(pagination);
  };

  const handleAction = async (action: string, id: any) => {
    const chaves = Object.keys(actions);
    let chaveEncontrada = null;

    for (const chave of chaves) {
      //@ts-ignore
      if (actions[chave] === action) {
        chaveEncontrada = chave;
        break;
      }
    }
    if (chaveEncontrada === "vehicle_association") {
      setSelectedGroupId(`${id}`);
      setAssociateModalVisible(true);
    } else {
      // navigate(`edit-config/${id}`);

      if (id) {
        setGroupId(id);
        setSetupForm(true);
      }
    }
  };

  function getEnableSaveButton(): boolean {
    return !watch("registers")?.find(({ enabled }) => enabled);
  }

  async function loadEmbeddedIntelligences({
    filter = "",
    page = 1,
    order,
  }: APiQueryBase) {
    try {
      const _response = await FleetMateService.getGroupConfigs({
        filter,
        page,
        order,
      });

      const isError = isErrorResult(_response);
      if (!isError && _response.data) {
        //@ts-ignore
        replace(_response.data);
        //@ts-ignore
        setDefaultValues(_response.data);
        setPagination((prev) => ({
          ...prev,
          //@ts-ignore
          page: _response?.current_page || 1,
          //@ts-ignore
          pages: _response?.last_page || 0,
        }));
      }
    } catch (error: any) {
      console.log("Erro in load fleetmate.ei", error.message);
    }
  }

  function onChangeFilter(options: VehicleFilterEvent) {
    const { tags = [], vehicle_type } = options;
    const filter: any = {
      ...pagination.filter,
      vehicle_types_id: !!vehicle_type ? [vehicle_type] : [],
      vehicle_tags_id: tags.map((element) => element.id),
    };

    if (!vehicle_type) delete filter.vehicle_types_id;
    if (!tags?.length) delete filter.vehicle_tags_id;
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onSearch(text: string): void {
    const filter = { ...pagination.filter, search: text };
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onDateFrom(text: any): void {
    const filter = { ...pagination.filter, from: text };
    if (!text) delete filter.from;
    setSelectedInitialDate(text);
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onDateTo(text: string): void {
    const filter = { ...pagination.filter, to: text };
    if (!text) delete filter.to;
    setSelectedFinalDate(text);
    setPagination((prev) => ({ ...prev, filter }));
  }

  useEffect(() => {
    loadEmbeddedIntelligences(pagination);
  }, [
    pagination.filter,
    pagination.page,
    pagination.order,
    user?.company_id,
    associateModalVisible,
  ]);

  console.log(watch("registers"), defaultValues);

  if (setupForm) {
    return (
      <Container className="mb-3">
        <Row className="mt-3 mb-4 pb-3 border-bottom">
          <Col className="d-flex align-items-end" lg={3}>
            <LinkGoBack className="mx-4" onClick={() => setSetupForm(false)}>
              <BsArrowLeftShort size={24} />
              {t("link.go_back")}
            </LinkGoBack>
          </Col>
        </Row>

        <FleetMateGroupSettings
          id={groupConfigId === 0 ? null : groupConfigId}
          back={() => {
            setSetupForm(false);
            setGroupId(0);
            loadEmbeddedIntelligences(pagination);
          }}
        />
      </Container>
    );
  }
  return (
    <Container className="mb-3">
      <Row className="mb-4">
        <Col className="d-flex align-items-end" lg={3}>
          <AddButton
            onClick={() => {
              // navigate(`new-config/`);
              setSetupForm(true);
            }}
          >
            {t("configs.fleetmate.ei.add_button_new_setup")}
          </AddButton>
        </Col>

        <Col lg={9}>
          <Stack className="filter-container" gap={3}>
            <Form.Group>
              <CustomSearchInput
                placeholder={t("search")}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                {t("configs.fleetmate.ei.label_from_data")}
              </Form.Label>
              <Form.Control
                min={new Date("2021-01-01T00:00")?.toDateString()}
                max={new Date()?.toDateString()}
                value={selectedInitialDate}
                type="datetime-local"
                onChange={(e) => onDateFrom(e?.target?.value)}
                className="form-control p-2"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>{t("configs.fleetmate.ei.label_to_data")}</Form.Label>
              <Form.Control
                min={new Date("2021-01-01T00:00")?.toDateString()}
                max={new Date()?.toDateString()}
                value={selectedFinalDate}
                type="datetime-local"
                onChange={(e) => onDateTo(e?.target?.value)}
                className="form-control p-2"
              />
            </Form.Group>

            <ActionButtonOutline onClick={() => setOpenFilter(true)}>
              <FaFilter size={18} />
            </ActionButtonOutline>
          </Stack>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <Table responsive>
            <CustomThead
              data={[
                {
                  column: "created_at",
                  name: t("configs.fleetmate.ei.created_at"),
                },
                {
                  column: "name",
                  name: t("configs.fleetmate.ei.setup_name"),
                },

                {
                  column: "amount_vehicles",
                  name: t("configs.fleetmate.ei.qnt_vehicles"),
                },
                { column: "user_name", name: t("configs.fleetmate.ei.user") },

                {
                  column: "updated_at",
                  name: t("configs.fleetmate.ei.updated_at"),
                },
                { column: "menu", name: <th />, menu: true },
              ]}
              onChangeOrder={(order) => {
                setPagination((prev) => ({ ...prev, order }));
              }}
            />

            <tbody>
              {fields?.map((item, index) => {
                return (
                  <tr key={`${index}_${item.fieldId}`}>
                    <td>{validateDateFormatAndTime(item.created_at, true)}</td>
                    <td>
                      {/* <CustomLink to={`edit-config/${item.id}`}> */}
                      <CustomButton
                        emphasized={true}
                        onClick={() => {
                          if (item.id) {
                            setGroupId(item.id);
                            setSetupForm(true);
                            loadEmbeddedIntelligences(pagination);
                          }
                        }}
                      >
                        {item.name}
                      </CustomButton>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.amount_vehicles}
                    </td>
                    <td>{item.user_name}</td>
                    <td>
                      {validateDateFormatAndTime(item.updated_at, true)}
                    </td>{" "}
                    <td>
                      <TableActions
                        items={actionOptions}
                        onSelectAction={(e) => {
                          handleAction(e, `${item.id}`);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          {!fields.length && (
            <EmptyListMessage style={{ marginTop: "2%" }}>
              {t("configs.fleetmate.ei.empty_list")}
            </EmptyListMessage>
          )}
        </Col>
      </Row>

      <Row>
        <Footer>
          <div>
            <Stack className="mb-3" direction="horizontal" gap={3}>
              <Button
                onClick={handleSubmit(onSubmitUpdate)}
                disabled={getEnableSaveButton()}
              >
                {isSubmitting ? t("status.saving") : t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={handleEditCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </div>

          <div className="mx-3">
            <Pagination
              current_page={pagination.page}
              qtd={pagination.pages}
              onSelectPage={(page) => {
                loadEmbeddedIntelligences({ ...pagination, page });
              }}
            />
          </div>
        </Footer>
      </Row>

      <VehicleFilterModal
        visible={openFilter}
        onClose={() => setOpenFilter(false)}
        onChange={onChangeFilter}
      />
      <AssociateVehicleAndTagsModal
        selectedGroupId={selectedGroupId}
        onClose={handleCloseAssociateModal}
        visible={associateModalVisible}
      />
    </Container>
  );
};

export default GroupsConfigs;
