import { api } from ".";
import { IAlertRuleBody } from "../../pages/Sensors/AlertRuleForm";
import { AlertComment } from "../interfaces/Alert";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";

export class AlertsService {
  static async get({
    filter,
    page,
    order,
  }: APiQueryBase): Promise<ApiResponseBase<IAlertRuleBody[]> | ErrorResult> {
    return await api
      .get("/api/v2/sensors/alerts-triggers", {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getAlerts({
    filter,
    page,
    order,
  }: APiQueryBase): Promise<ApiResponseBase<IAlertRuleBody[]> | ErrorResult> {
    return await api
      .get("/api/v2/alerts", {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(data: IAlertRuleBody): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/sensors/alerts-triggers`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(
    alertId: number,
    data: IAlertRuleBody
  ): Promise<ErrorResult> {
    return await api
      .put(`/api/v2/sensors/alerts-triggers/${alertId}`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getAlert(
    alertId: number
  ): Promise<ApiResponseBase<IAlertRuleBody> | ErrorResult> {
    return await api
      .get(`/api/v2/sensors/alerts-triggers/${alertId}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(alertId: number | string): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/sensors/alerts-triggers/${alertId}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getMedia(media: string) {
    return await api
      .get(`/api/v2/camera/alarm-files/${media}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getComments(
    alertId: number
  ): Promise<ApiResponseBase<AlertComment> | ErrorResult> {
    return await api
      .get(`/api/v2/tracking/alert/${alertId}/comment`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async createComment(alertId: number, data: { comment: string }) {
    return await api
      .post(`/api/v2/tracking/alert/${alertId}/comment`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
