import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { Alert } from "../../interfaces/Alert";
import { UserProfile } from "../../interfaces/User";
import type { RootState } from "../store";

// Define a type for the slice state
interface UserState {
  profiles: Array<UserProfile>;
}

// Define the initial state using that type
const initialState: UserState = {
  profiles: [],
};

interface ReducerActions extends SliceCaseReducers<UserState> {
  updateProfiles(
    state: UserState,
    action: PayloadAction<Array<UserProfile>>
  ): void;
}

export const userSlice: Slice<UserState, ReducerActions> = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateProfiles: (state, action: PayloadAction<Array<UserProfile>>) => {
      state.profiles = action.payload;
    },
  },
});

export const { updateProfiles } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getProfiles = (state: RootState) => state.user.profiles;

export default userSlice.reducer;
