import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as ICMore } from "../../assets/icons/more.svg";
import { useTranslation } from "react-i18next";
import { ProtectedComponent } from "../ProtectedComponent";

// import { Container } from './styles';

interface TableActionsProps {
  id?: string | number;
  items?: Array<string>;
  module_name?: string;
  onSelectAction?(action: string): void;
}

const TableActions: React.FC<TableActionsProps> = ({
  id,
  items,
  onSelectAction,
  module_name,
}) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      style={{
        position: "unset",
        height: "fit-content",
      }}
    >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <div style={{ padding: "0 8px" }}>
          <ICMore />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items?.map((item, i) => {
          if (
            item === t("text.edit") ||
            item === t("electronic_fences.action_edit")
          ) {
            return (
              <ProtectedComponent
                key={`action_${id}_${i}`}
                module_name={module_name}
                property="edit"
                fallbackComponent={
                  <Dropdown.Item disabled eventKey={i}>
                    {item}
                  </Dropdown.Item>
                }
              >
                <Dropdown.Item
                  eventKey={i}
                  onClick={() => onSelectAction?.(item)}
                >
                  {item}
                </Dropdown.Item>
              </ProtectedComponent>
            );
          }
          if (
            item === t("text.delete") ||
            item === t("electronic_fences.action_delete")
          ) {
            return (
              <ProtectedComponent
                key={`action_${id}_${i}`}
                module_name={module_name}
                property="delete"
                fallbackComponent={
                  <Dropdown.Item disabled eventKey={i}>
                    {item}
                  </Dropdown.Item>
                }
              >
                <Dropdown.Item
                  eventKey={i}
                  onClick={() => onSelectAction?.(item)}
                >
                  {item}
                </Dropdown.Item>
              </ProtectedComponent>
            );
          }
          return (
            <Dropdown.Item
              key={`action_${id}_${i}`}
              eventKey={i}
              onClick={() => onSelectAction?.(item)}
            >
              {item}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TableActions;

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef<any, any>(
  ({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )
);

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
// const CustomMenu = React.forwardRef<any, any>(
//   ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
//     const [value, setValue] = useState("");

//     return (
//       <div
//         ref={ref}
//         style={style}
//         className={className}
//         aria-labelledby={labeledBy}
//       >
//         <Form.Control
//           autoFocus
//           className="mx-3 my-2 w-auto"
//           placeholder="Type to filter..."
//           onChange={(e) => setValue(e.target.value)}
//           value={value}
//         />
//         <ul className="list-unstyled">
//           {React.Children.toArray(children).filter(
//             (child) =>
//               !value || child.props.children.toLowerCase().startsWith(value)
//           )}
//         </ul>
//       </div>
//     );
//   }
// );
