import styled from "styled-components";

export const Container = styled.div`
  border: 0;
  padding: 12px 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #f2f2f2;

  &:nth-last-child(1) {
    border-bottom-width: 0 !important;
  }

  &:nth-last-child(2) {
    border-bottom-width: 0 !important;
  }
`;
