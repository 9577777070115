import { api } from ".";
import { ErrorResult } from "../interfaces/ErrorResult";

export class LanguageService {
  static async get(): Promise<any | ErrorResult> {
    return api
      .get(`/api/v2/language`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  
  static async setLanguageUser(acronym: String): Promise<any | ErrorResult> {
    return api
      .put(`/api/v2/user/update-language`,{
        language: acronym
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
