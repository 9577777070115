import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FlexRow,
  Container,
  Footer,
  SectionSubtitle,
  SectionTitle,
  CustomCol,
} from "./styles";
import { CompanySettingsService } from "../../../core/services/CompanySettings";
import { isErrorResult } from "../../../core/utils/api";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import { getUser } from "../../../core/redux/reducer/auth";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { HosRulesCompanyData } from "../../../core/interfaces/HosRulesCompany";
import { HosRulesCompanyService } from "../../../core/services/HosRulesCompanyService";
import { Skeleton } from "../../../components/Skeleton";
import { ProtectedComponent } from "../../../components/ProtectedComponent";
import { module_names } from "../../../constants/module_names";

type FormValues = {
  job_confirmation: boolean;
  alert: boolean;
  show_status_driver_in_app: boolean;
  chat_messages: boolean;
  driving_behavior: boolean;
  peripheral: boolean;
  image_upload: boolean;
  mandatory_image_upload: boolean;
  shipping_required_tasks: boolean;
  proof_occurrences: boolean;
  shipping_required_occurrences: boolean;
  type_occurrences: boolean;
  shipping_required_type_occurrences: boolean;
  not_allow_driver_disable_voice: boolean;
  block_vehicle_ignition: boolean;
  driver_journey_control: boolean;
  hos_rule_id: number;
  vehicle_ignition_blockage_during_overrun: boolean;
  do_not_allow_the_driver_to_deactivate_voice_notification: boolean;
  job_image_upload: boolean;
  job_sending_incident_required: boolean;
  job_sending_incident: boolean;
  job_sending_incident_photo: boolean;
  job_sending_incident_photo_required: boolean;
  driver_journey_control_legislation_id: number;
};

interface IValues {
  legislationOptions: HosRulesCompanyData[];
  isLoadingLegislationOptions: boolean;
}

const GeneralSettings: React.FC = () => {
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<IValues>({
    legislationOptions: [],
    isLoadingLegislationOptions: true,
  });

  function updateValues({ name, value }: { name: string; value: any }) {
    setValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  }

  const onSubmit = async (values: FormValues) => {
    // alert(JSON.stringify(values));
    setIsLoading(true);
    try {
      const response = await CompanySettingsService.update({
        alerts: convertBooleanToNumber(values.alert),
        automatic_detection_stopped_driving_status: 1,
        bluetooth: 1,
        peripheral: convertBooleanToNumber(values.peripheral),
        chat: convertBooleanToNumber(values.chat_messages),
        driver_journey_control: convertBooleanToNumber(
          values.driver_journey_control
        ),
        driver_journey_control_legislation_id:
          values.driver_journey_control_legislation_id,
        show_status_driver_in_app: convertBooleanToNumber(
          values.show_status_driver_in_app
        ),
        driving_behavior: convertBooleanToNumber(values.driving_behavior),
        vehicle_ignition_blockage_during_overrun: convertBooleanToNumber(
          values.vehicle_ignition_blockage_during_overrun
        ),
        do_not_allow_the_driver_to_deactivate_voice_notification:
          convertBooleanToNumber(
            values.do_not_allow_the_driver_to_deactivate_voice_notification
          ),
        job_sending_incident: convertBooleanToNumber(
          values.job_sending_incident
        ),
        job_sending_incident_required: convertBooleanToNumber(
          values.job_sending_incident_required
        ),
        job_sending_incident_photo: convertBooleanToNumber(
          values.job_sending_incident_photo
        ),
        job_sending_incident_photo_required: convertBooleanToNumber(
          values.job_sending_incident_photo_required
        ),
        geofencing: 1,
        job_confirmation: convertBooleanToNumber(values.job_confirmation),
        journey_module: 1,
        job_image_upload: convertBooleanToNumber(values.job_image_upload),
        mandatory_image_upload: convertBooleanToNumber(
          values.mandatory_image_upload
        ),

        mandatory_survey_job: 1,
        mandatory_survey_vehicle: 1,
        map_mode: 1,
        position: 1,
        require_password_every_new_status: 1,
        truck_navigation: 1,
        hos_rule_id: Number(values.hos_rule_id),
      });
      dispatch(
        updateAlert({
          title: t("configs.app.general_settings.updated"),
          // message: response.message,
          type: "error",
        })
      );
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  function convertBooleanToNumber(entry: boolean) {
    if (entry) {
      return 1;
    }
    return 0;
  }

  async function getCompanyConfigs() {
    const response = await CompanySettingsService.get();
    console.log(isErrorResult(response));
    if (!isErrorResult(response)) {
      console.log(response.data);
      setValue("alert", Boolean(response.data[0].alerts));
      setValue("driving_behavior", Boolean(response.data[0].driving_behavior));
      setValue("chat_messages", Boolean(response.data[0].chat));
      setValue("peripheral", Boolean(response.data[0].peripheral));
      setValue("job_image_upload", Boolean(response.data[0].job_image_upload));
      setValue(
        "mandatory_image_upload",
        Boolean(response.data[0].mandatory_image_upload)
      );
      setValue("job_confirmation", Boolean(response.data[0].job_confirmation));
      setValue(
        "show_status_driver_in_app",
        Boolean(response.data[0].show_status_driver_in_app)
      );
      setValue(
        "driver_journey_control",
        Boolean(response.data[0].driver_journey_control)
      );
      setValue(
        "do_not_allow_the_driver_to_deactivate_voice_notification",
        Boolean(
          response.data[0]
            .do_not_allow_the_driver_to_deactivate_voice_notification
        )
      );
      setValue(
        "vehicle_ignition_blockage_during_overrun",
        Boolean(response.data[0].vehicle_ignition_blockage_during_overrun)
      );
      setValue(
        "job_sending_incident",
        Boolean(response.data[0].job_sending_incident)
      );
      setValue(
        "job_sending_incident_required",
        Boolean(response.data[0].job_sending_incident_required)
      );
      setValue(
        "job_sending_incident_photo",
        Boolean(response.data[0].job_sending_incident_photo)
      );
      setValue(
        "job_sending_incident_photo_required",
        Boolean(response.data[0].job_sending_incident_photo_required)
      );
      setValue(
        "driver_journey_control_legislation_id",
        response.data[0].driver_journey_control_legislation_id
      );
    }
  }

  async function getCompanyRules() {
    updateValues({ name: "isLoadingLegislationOptions", value: true });
    try {
      const response = await HosRulesCompanyService.get();
      console.log(isErrorResult(response));
      if (!isErrorResult(response)) {
        console.log(response);
        updateValues({
          name: "legislationOptions",
          value: response.data,
        });
      }
    } catch (err) {
    } finally {
      updateValues({ name: "isLoadingLegislationOptions", value: false });
    }
  }

  useEffect(() => {
    getCompanyConfigs();
    getCompanyRules();
  }, [user?.company_id]);
  return (
    <Container className="p-0">
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit, console.warn)}
        className="form"
      >
        <SectionTitle>{t("configs.app.general_settings.title")}</SectionTitle>
        <SectionSubtitle>
          ({t("configs.app.general_settings.subtitle")})
        </SectionSubtitle>

        <FlexRow>
          <CustomCol>
            <Form.Group as={Row} controlId="task">
              <Form.Label lg={10} as={Col}>
                {t("configs.app.general_settings.tasks")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="job_confirmation"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("job_confirmation")}
                />
              </Col>
            </Form.Group>
          </CustomCol>

          <CustomCol>
            <Form.Group as={Row} controlId="alert">
              <Form.Label as={Col}>
                {t("configs.app.general_settings.alerts")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="alert"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("alert")}
                />
              </Col>
            </Form.Group>
          </CustomCol>
        </FlexRow>

        <FlexRow>
          <CustomCol>
            <Form.Group as={Row} controlId="show_status_driver_in_app">
              <Form.Label lg={10} as={Col}>
                {t("configs.app.general_settings.driver_status")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="show_status_driver_in_app"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("show_status_driver_in_app")}
                />
              </Col>
            </Form.Group>
          </CustomCol>

          <CustomCol>
            <Form.Group as={Row} controlId="chat_messages">
              <Form.Label as={Col}>
                {t("configs.app.general_settings.chat_messages")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="chat_messages"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("chat_messages")}
                />
              </Col>
            </Form.Group>
          </CustomCol>
        </FlexRow>

        <FlexRow>
          <CustomCol>
            <Form.Group as={Row} controlId="driving_behavior">
              <Form.Label lg={10} as={Col}>
                {t("configs.app.general_settings.ranking")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="driving_behavior"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("driving_behavior")}
                />
              </Col>
            </Form.Group>
          </CustomCol>

          <CustomCol>
            <Form.Group as={Row} controlId="peripheral">
              <Form.Label as={Col}>
                {t("configs.app.general_settings.peripherals")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="peripheral"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("peripheral")}
                />
              </Col>
            </Form.Group>
          </CustomCol>
        </FlexRow>

        <SectionTitle className="mt-5 mb-3">
          {t("configs.app.general_settings.drivers_workflow")}
        </SectionTitle>

        <FlexRow>
          <CustomCol>
            <Form.Group as={Row} controlId="proof_tasks">
              <Form.Label lg={10} as={Col}>
                {t("configs.app.general_settings.proof_tasks")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="job_image_upload"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("job_image_upload")}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="mandatory_image_upload">
              <Form.Check
                id="mandatory_image_upload"
                type="checkbox"
                label={t("configs.app.general_settings.shipping_required")}
                defaultChecked={false}
                {...register("mandatory_image_upload")}
              />
            </Form.Group>
          </CustomCol>

          <CustomCol>
            <Form.Group as={Row} controlId="job_sending_incident_photo">
              <Form.Label lg={10} as={Col}>
                {t("configs.app.general_settings.proof_occurrences")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="job_sending_incident_photo"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("job_sending_incident_photo")}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="job_sending_incident_photo_required">
              <Form.Check
                id="job_sending_incident_photo_required"
                type="checkbox"
                label={t("configs.app.general_settings.shipping_required")}
                defaultChecked={false}
                {...register("job_sending_incident_photo_required")}
              />
            </Form.Group>
          </CustomCol>
        </FlexRow>

        <FlexRow>
          <CustomCol>
            <Form.Group as={Row} controlId="job_sending_incident">
              <Form.Label lg={10} as={Col}>
                {t("configs.app.general_settings.type_occurrences")}
              </Form.Label>
              <Col>
                <Form.Check
                  id="job_sending_incident"
                  reverse
                  type="switch"
                  defaultChecked={true}
                  {...register("job_sending_incident")}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="job_sending_incident_required">
              <Form.Check
                id="job_sending_incident_required"
                type="checkbox"
                label={t("configs.app.general_settings.shipping_required")}
                defaultChecked={false}
                {...register("job_sending_incident_required")}
              />
            </Form.Group>
          </CustomCol>
        </FlexRow>

        <SectionTitle className="mt-5 mb-3">
          {t("configs.app.journey_control_tab")}
        </SectionTitle>

        <FlexRow>
          <CustomCol>
            <Form.Group as={Row} controlId="driver_journey_control">
              <Col lg={1}>
                <Form.Check
                  id="driver_journey_control"
                  type="switch"
                  defaultChecked={true}
                  {...register("driver_journey_control")}
                />
              </Col>
              <Form.Label lg={11} as={Col}>
                {t("configs.app.journey_control_enable")}
              </Form.Label>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mt-2"
              controlId="vehicle_ignition_blockage_during_overrun"
            >
              <Col lg={1}>
                <Form.Check
                  id="vehicle_ignition_blockage_during_overrun"
                  type="switch"
                  defaultChecked={true}
                  {...register("vehicle_ignition_blockage_during_overrun")}
                />
              </Col>
              <Form.Label lg={11} as={Col}>
                {t("configs.app.block_vehicle_ignition")}
              </Form.Label>
            </Form.Group>
          </CustomCol>

          <ProtectedComponent
            module_name={module_names.CONFIG_JOURNEY}
            property="view"
          >
            <CustomCol>
              <Form.Group className="w-100">
                <Form.Label>
                  {t("configs.app.journey_control_legislation")}
                </Form.Label>
                <Skeleton
                  isLoading={values.isLoadingLegislationOptions}
                  width="100%"
                  height="40px"
                >
                  <ProtectedComponent
                    module_name={module_names.CONFIG_JOURNEY}
                    property="edit"
                    fallbackComponent={
                      <Form.Select disabled>
                        {values.legislationOptions?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option?.name}...
                          </option>
                        ))}
                      </Form.Select>
                    }
                  >
                    <Form.Select
                      {...register("driver_journey_control_legislation_id")}
                    >
                      {values.legislationOptions?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option?.name}...
                        </option>
                      ))}
                    </Form.Select>
                  </ProtectedComponent>
                </Skeleton>
              </Form.Group>
            </CustomCol>
          </ProtectedComponent>
        </FlexRow>

        <FlexRow>
          <CustomCol>
            <Form.Group controlId="do_not_allow_the_driver_to_deactivate_voice_notification">
              <Form.Check
                id="do_not_allow_the_driver_to_deactivate_voice_notification"
                type="checkbox"
                label={t("configs.app.not_allow_driver_disable_voice")}
                defaultChecked={false}
                {...register(
                  "do_not_allow_the_driver_to_deactivate_voice_notification"
                )}
              />
            </Form.Group>
          </CustomCol>
        </FlexRow>

        <Footer>
          <Stack className="mb-3" direction="horizontal" gap={2}>
            <Button type="submit" disabled={isLoading}>
              {t("button.save")}
            </Button>
            <Button variant="secondary">{t("button.cancel")}</Button>
          </Stack>
        </Footer>
      </Form>
    </Container>
  );
};

export default GeneralSettings;
