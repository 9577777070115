import React, { useEffect } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { FaFilter } from "react-icons/fa";
import { useAudit } from "../../core/hooks/useAudit";
import { Controller, useForm } from "react-hook-form";
import { DriverService } from "../../core/services/DriverService";
import { AsyncPaginate } from "react-select-async-paginate";
import { VehicleService } from "../../core/services/VehicleService";
import { UserService } from "../../core/services/UserService";
import { AuditService } from "../../core/services/AuditService";
import { AuditEvent, AuditModule } from "../../core/interfaces/Audit";
import { ErrorResult } from "../../core/interfaces/ErrorResult";
import { ApiResponseBase } from "../../core/interfaces/ApiResponseBase";
import { Items } from "../DropdownSearchAndCheck";

interface OnChangeFilter {
  tags?: IVehicleTag[];
  event_id?: string;
  module_id?: string;
  action?: string;
  driver_id?: any;
  vehicle_id: any;
  user_id?: any;
}

interface AuditFilterModalProps {
  visible: boolean;
  onClose?(): void;
  onChange?(data: OnChangeFilter): void;
  events: AuditEvent[];
}

interface FormValues {
  reccordType: string;
  action: string;
  reccordOrigin: any;
  driver: any;
  user: any;
  vehicle: any;
}

type ResponseType = ErrorResult | ApiResponseBase<AuditModule>;

const AuditFilterModal: React.FC<AuditFilterModalProps> = ({
  onClose,
  visible,
  onChange,
  events,
  ...props
}) => {
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);
  const [tags, setTags] = React.useState<string[]>([]);
  const [filter, setFilter] = React.useState<OnChangeFilter>();
  const [selectedDriver, setSelectedDriver] = React.useState();
  const [selectedUser, setSelectedUser] = React.useState();
  const [selectedVehicle, setSelectedVehicle] = React.useState();
  const [modules, setModules] = React.useState<any>();

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<FormValues>();

  const { t } = useTranslation();

  const { recordTypeOptions, recordOriginOptions, actionOptions } = useAudit();

  const [types, setTypes] = React.useState(
    recordTypeOptions.map((item, i) => ({
      label: item.label,
      value: item.value,
      id: item.value,
      checked: false,
      index: i,
    }))
  );

  const [recordOrigin, setRecordOrigin] = React.useState(
    recordOriginOptions.map((item, i) => ({
      label: item.label,
      value: item.value,
      id: item.value,
      checked: false,
      index: i,
    }))
  );

  const [actions, setActions] = React.useState(
    actionOptions.map((item, i) => ({
      label: item.label,
      value: item.value,
      id: item.value,
      checked: false,
      index: i,
    }))
  );

  const handleCancel = () => {
    onClose?.();
    setTags([]);
    setFilter(undefined);
  };
  // async function loadVehicleTags() {
  //   if (isLoadingTags) return;
  //   setLoadingTags(true);

  //   try {
  //     const _response = await VehicleTagService.getVehicleTags({
  //       filter: "",
  //       page: 1,
  //     });

  //     const isError = isErrorResult(_response);

  //     if (!isError && _response.data) {
  //       setVehicleTags(_response.data);
  //     }
  //   } catch (error: any) {
  //     console.log("Erro in load tags", error.message);
  //   } finally {
  //     setLoadingTags(false);
  //   }
  // }

  function onSubmit() {
    // const _tags = vehicleTags?.filter((item) => tags?.includes(item.key));
    // @ts-ignore

    onChange?.({
      event_id: getValues("reccordType"),
      vehicle_id: getValues(`vehicle.value`),
      action: getValues("action"),
      driver_id: getValues(`driver.value`),
      user_id: getValues(`user.value`),
      module_id: getValues("reccordOrigin"),
    });

    onClose?.();
  }

  function handleDropdownItems(items: Items[]): string[] {
    return items.filter((item) => item.checked).map((item) => String(item.id));
  }

  async function loadModules() {
    try {
      const _response = await AuditService.getModules({});

      const isError = isErrorResult(_response);

      setModules(_response);
    } catch (error) {}
  }

  React.useEffect(() => {
    if (visible) {
      // loadVehicleTags();
      setSelectedUser(undefined);
      setSelectedDriver(undefined);
      setSelectedVehicle(undefined);

      setTypes(
        recordTypeOptions.map((item, i) => ({
          label: item.label,
          value: item.value,
          id: item.value,
          checked: false,
          index: i,
        }))
      );

      setRecordOrigin(
        recordOriginOptions.map((item, i) => ({
          label: item.label,
          value: item.value,
          id: item.value,
          checked: false,
          index: i,
        }))
      );

      setActions(
        actionOptions.map((item, i) => ({
          label: item.label,
          value: item.value,
          id: item.value,
          checked: false,
          index: i,
        }))
      );
    }
  }, [visible]);

  useEffect(() => {
    if (visible === false) return;
    loadModules();
  }, [visible]);

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <FaFilter size={18} />
        <Modal.Title>{t("text.filter")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mt-5 mb-3">
          <Col>
            <Form.Group>
              <Form.Label>{t("configs.audit.record_type")}</Form.Label>
              {/* <DropdownSearchAndCheck
                label={t("input.select_text")}
                search={false}
                onChange={(items) =>
                  setFilter((prev) => ({
                    ...prev,
                    record_types: handleDropdownItems(items),
                  }))
                }
                items={recordTypeOptions?.map((item) => ({
                  id: item.value,
                  label: item.label,
                  checked: false,
                }))}
              /> */}
              <Controller
                control={control}
                name="reccordType"
                render={({ field: { value, onChange, onBlur } }) => (
                  // <DropdownSearchAndCheck
                  //   label={t("configs.menu.audit.filter_modal.select_type")}
                  //   search={false}
                  //   onChange={(selectedItem: any) => {
                  //     const checkedType = types.filter((item) =>
                  //       selectedItem.some(
                  //         (selected: any) =>
                  //           selected.id === item.id && selected.checked
                  //       )
                  //     );

                  //     setTypes((prev: any) =>
                  //       prev.map((item: any, i: any) => ({
                  //         ...item,
                  //         checked:
                  //           i === selectedItem.map((item: any) => item.index)
                  //             ? !item.checked
                  //             : item.checked,
                  //       }))
                  //     );

                  //     setFilter((prev: any) => ({
                  //       ...prev,
                  //       event_id: checkedType.map((item) => item.value)[0],
                  //     }));

                  //     setValue(
                  //       "reccordType",
                  //       checkedType.map((item) => item.value)[0]
                  //     );
                  //   }}
                  //   values={types}
                  //   items={types}
                  // />

                  <Form.Select
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  >
                    <option key="blankChoice" hidden value={""} color="#6c757d">
                      {t("configs.menu.audit.filter_modal.select_type")}
                    </option>

                    <option value={""} color="#6c757d">
                      {t("journey.all")}
                    </option>

                    {events.map((item, i) => (
                      <option key={i} value={(value = String(item.id))}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>{t("configs.audit.record_origin")}</Form.Label>
              <Controller
                control={control}
                name="reccordOrigin"
                render={({ field: { value, onChange, onBlur } }) => (
                  // <DropdownSearchAndCheck
                  //   label={t("configs.menu.audit.filter_modal.select_origin")}
                  //   search={false}
                  //   onChange={(selectedItem: any) => {
                  //     const checkedOrigins = recordOrigin.filter((item) =>
                  //       selectedItem.some(
                  //         (selected: any) =>
                  //           selected.id === item.id && selected.checked
                  //       )
                  //     );

                  //     setRecordOrigin((prev) =>
                  //       prev.map((item: any, i: any) => ({
                  //         ...item,
                  //         checked:
                  //           i === selectedItem.map((item: any) => item.index)
                  //             ? !item.checked
                  //             : item.checked,
                  //       }))
                  //     );

                  //     setFilter((prev: any) => ({
                  //       ...prev,
                  //       record_origins: checkedOrigins.map(
                  //         (item) => item.value
                  //       )[0],
                  //     }));
                  //     setValue(
                  //       "reccordOrigin",
                  //       checkedOrigins.map((item) => item.value)[0]
                  //     );
                  //   }}
                  //   values={recordOrigin}
                  //   items={recordOrigin}
                  // />

                  <Form.Select
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  >
                    <option key="blankChoice" hidden value={""} color="#6c757d">
                      {t("configs.menu.audit.filter_modal.select_origin")}
                    </option>

                    <option value={""} color="#6c757d">
                      {t("journey.all")}
                    </option>

                    {modules?.data?.map((item: any, i: number) => (
                      <option key={i} value={(value = item.id)}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>{t("configs.audit.action")}</Form.Label>

              <Controller
                control={control}
                name="action"
                render={({ field: { value, onChange, onBlur } }) => (
                  // <DropdownSearchAndCheck
                  //   label={t("configs.menu.audit.filter_modal.select_action")}
                  //   search={false}
                  //   onChange={(selectedItem: any) => {
                  //     const checkedActions = actions.filter((item) =>
                  //       selectedItem.some(
                  //         (selected: any) =>
                  //           selected.id === item.id && selected.checked
                  //       )
                  //     );

                  //     setActions((prev: any) =>
                  //       prev.map((item: any, i: any) => ({
                  //         ...item,
                  //         checked:
                  //           i === selectedItem.map((item: any) => item.index)
                  //             ? !item.checked
                  //             : item.checked,
                  //       }))
                  //     );

                  //     setFilter((prev: any) => ({
                  //       ...prev,
                  //       actions: checkedActions.map((item) => item.value)[0],
                  //     }));
                  //     setValue(
                  //       "action",
                  //       checkedActions.map((item) => item.value)[0]
                  //     );
                  //   }}
                  //   values={actions}
                  //   items={actions}
                  // />

                  <Form.Select
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  >
                    <option key="blankChoice" hidden value={""} color="#6c757d">
                      {t("configs.menu.audit.filter_modal.select_action")}
                    </option>

                    <option value={""} color="#6c757d">
                      {t("journey.all")}
                    </option>

                    {actionOptions.map((item, i) => (
                      <option key={i} value={(value = item.value)}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                )}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>{t("configs.audit.driver")}</Form.Label>

              <Controller
                control={control}
                name="driver"
                render={({ field: { value, onChange, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    placeholder={t("input.select_text")}
                    loadOptions={(inputValue, options, additional) =>
                      DriverService.loadDriverOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>{t("configs.audit.license_plate_label")}</Form.Label>
              <Controller
                control={control}
                name="vehicle"
                render={({ field: { value, onChange, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    placeholder={t("input.select_text")}
                    loadOptions={(inputValue, options, additional) =>
                      VehicleService.loadVehicleOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>{t("configs.audit.user")}</Form.Label>
              <Controller
                control={control}
                name="user"
                render={({ field: { value, onChange, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    placeholder={t("input.select_text")}
                    loadOptions={(inputValue, options, additional) =>
                      UserService.getUsersOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <Form.Group className="mb-3" controlId="name" as={Col}>
              <Form.Label>
                {t("configs.fleetmate.ei.attributes_tag")}
              </Form.Label>
              <CustomInputTag
                defaultSelected={tags}
                onChange={(values: string[]) => setTags(values)}
                options={vehicleTags?.map((op) => op.key)}
                isLoading={isLoadingTags}
              />
            </Form.Group>
          </Col>
        </Row> */}

        <Row className="mt-2">
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button onClick={onSubmit}>{t("button.save")}</Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AuditFilterModal;
