import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  .filters {
    & > div {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  th {
    text-transform: uppercase;
  }

  td:first-child {
    color: #ed9d25;
  }

  .table > :not(caption) > * > *:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .table > :not(caption) > * > *:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .img-profile {
    height: 59px;
    width: 59px;
    border-radius: 50px;
    object-fit: cover;
  }

  .h-lg {
    height: 130.93px;
  }

  .h-sm {
    height: 91px;
  }

  .detail-card {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 208.62px;
    background: #fafafb;
    border-radius: 14.3878px;
    padding: 22px 18px;
    color: #000000;

    strong {
      font-size: 16px;
      line-height: 24px;
      color: #6c757d;
    }
  }

  .ranking {
    display: flex;
    justify-content: center;
    color: #ffc107;
  }
`;
