import React from "react";
import { Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";

import { useForm, SubmitHandler } from "react-hook-form";
import { MdEdit } from "react-icons/md";
import { AlertsService } from "../../core/services/AlertsService";

interface AddCommentModalProps {
  visible: boolean;
  onClose?(): void;
  alertId: number | null;
}

type FormValues = {
  comment: string;
};

const AddCommentModal: React.FC<AddCommentModalProps> = ({
  onClose,
  visible,
  alertId,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const handleCancel = () => {
    reset();
    onClose?.();
  };

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      if (alertId) {
        await AlertsService.createComment(alertId, values);
        handleCancel();
      }
    } catch (error) {
      console.log("load comment error", error);
    }
  };

  return (
    <Modal {...props} show={visible} size="md" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <MdEdit size={24} />
        <Modal.Title>{t("traking.btn_add_comment")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="mt-4">
        <Form>
          <Row>
            <Form.Group as={Col} controlId={"comment"}>
              <Form.Label>{t("traking.label_comment")}</Form.Label>
              <Form.Control as="textarea" rows={4} {...register("comment")} />
            </Form.Group>
          </Row>

          <Row className="mt-4">
            <Stack
              className="d-flex justify-content-end"
              direction="horizontal"
              gap={3}
            >
              <Button onClick={handleSubmit(onSubmit, console.log)}>
                {isSubmitting ? t("status.saving") : t("button.confirm")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCommentModal;
