import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import CustomInputTag from "../CustomInputTag";
import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { VehicleTypeService } from "../../core/services/VehicleTypeService";
import { VehicleType } from "../../core/interfaces/VehicleType";
import { AsyncPaginate } from "react-select-async-paginate";
import { VehicleService } from "../../core/services/VehicleService";
import { GeofenceService } from "../../core/services/GeofenceService";

type AssociatedVehiclesPeriod = { from?: string; to?: string };

type AssociatedVehiclesFilter = {
  vehicle_type: number;
  tags: IVehicleTag[];
  period?: AssociatedVehiclesPeriod;
  indefinitely: boolean;
};

interface AssociatedVehiclesModalProps {
  selectedEletronicFenceId?: number | false;
  visible: boolean;
  onClose?(): void;
  onChange?(filter: AssociatedVehiclesFilter): void;
}

const AssociatedVehiclesModal: React.FC<AssociatedVehiclesModalProps> = ({
  selectedEletronicFenceId,
  onClose,
  visible,
  onChange,
  ...props
}) => {
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [selectedVehicles, setSelectedVehicles] = React.useState<any[]>([]);
  const [vehicleTypes, setVehicleTypes] = React.useState<VehicleType[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);
  const [vehicleType, setVehicleType] = React.useState<string>("");
  const [tags, setTags] = React.useState<string[]>([]);
  const [period, setPeriod] = React.useState<AssociatedVehiclesPeriod>();
  const [indefinitely, setIndefinitely] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const handleCancel = () => {
    onClose?.();
    setVehicleType("");
    setTags([]);
  };

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  async function loadVehicleType() {
    try {
      const _response = await VehicleTypeService.get({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTypes(_response.data);
      }
    } catch (error: any) {
      console.log("Erro in load vehicle types", error.message);
    }
  }

  function onSubmit() {
    const _tags = vehicleTags?.filter((item) => tags?.includes(item.key));
    onChange?.({
      vehicle_type: +vehicleType,
      tags: _tags,
      indefinitely,
      period,
    });

    const tagsId = _tags?.map((tag) => tag.id);
    const vehiclesId = selectedVehicles?.map((vehicle) => vehicle.id);

    const payload = {
      tags: tagsId,
      vehicles: vehiclesId,
    };

    if (selectedEletronicFenceId) {
      GeofenceService.associate_vehicle(selectedEletronicFenceId, payload);
    }

    onClose?.();
  }

  React.useEffect(() => {
    async function loadFenceById(id: string) {
      try {
        const _response = await GeofenceService.getById(id);

        const isError = isErrorResult(_response);

        if (!isError && _response.data) {
          setSelectedVehicles(
            _response.data?.vehicles.map((vehicle: any) => {
              return {
                id: vehicle.id,
                value: vehicle.id,
                label: vehicle?.license_plate || "",
              };
            })
          );

          setTags(_response.data?.tags.map((tag: any) => tag.key));
        }
      } catch (error: any) {
        console.log("Erro in load geofence", error.message);
      } finally {
      }
    }

    if (visible && selectedEletronicFenceId) {
      loadFenceById(`${selectedEletronicFenceId}`);
    }
  }, [visible, selectedEletronicFenceId]);
  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
      loadVehicleType();
    }
  }, [visible]);

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <Modal.Title>
          {t("electronic_fences.action_associate_vhicles")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mt-5 mb-3">
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label>{t("configs.fleetmate.ei.vehicle")}</Form.Label>

              <AsyncPaginate
                value={selectedVehicles}
                classNamePrefix="filter-multi-select"
                isMulti
                placeholder={t("input.select_text")}
                loadOptions={(inputValue, options, additional) => {
                  return VehicleService.loadVehicleOptions(
                    inputValue,
                    options,
                    additional
                  );
                }}
                additional={{ page: 1 }}
                onChange={(selectedOptions) => {
                  if (selectedOptions !== null) {
                    setSelectedVehicles(
                      selectedOptions.map((option: any) => ({
                        id: option.value,
                        value: option.value,
                        label: option.label,
                      }))
                    );
                  }
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            {/* {JSON.stringify(tags)} */}
            <Form.Group className="mb-4" controlId="name" as={Col}>
              <Form.Label>
                {t("configs.fleetmate.ei.attributes_tag")}
              </Form.Label>
              <CustomInputTag
                // defaultSelected={tags}
                selected={tags}
                onChange={(values) => {
                  setTags(values);
                }}
                options={vehicleTags?.map((op) => op.key)}
                isLoading={isLoadingTags}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* <Row className="mb-3">
          <Col>
            <Form.Group as={Row}>
              <Form.Label sm={5} column>
                {t("electronic_fences.map_maintain_association_from")}
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  type="datetime-local"
                  onChange={(e) => {
                    setPeriod((prev) => ({ ...prev, from: e.target.value }));
                  }}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col sm={5}>
            <Form.Group as={Row}>
              <Form.Label sm={2} column>
                {t("electronic_fences.map_maintain_association_to")}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="datetime-local"
                  onChange={(e) => {
                    setPeriod((prev) => ({ ...prev, to: e.target.value }));
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row> */}

        {/* <Row className="mb-3">
          <Form.Group
            sm={{ offset: 4 }}
            as={Col}
            controlId="membership_indefinitely"
          >
            <Form.Check
              className="text-muted"
              label={t("electronic_fences.map_label_membership_indefinitely")}
              defaultChecked={false}
              onChange={(e) => {
                setIndefinitely(e.target.checked);
              }}
            />
          </Form.Group>
        </Row> */}

        <Row className="mt-2">
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button onClick={onSubmit}>{t("button.save")}</Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AssociatedVehiclesModal;
