import styled from "styled-components";
import { Container as BsContainer } from "react-bootstrap";

export const Container = styled(BsContainer)`
  height: 100% !important;
  display: flex;
  padding: 0.5rem 1.5rem !important;
  flex-direction: column;
  /* background-color: green; */

  input[type="text"] {
    min-width: 5.5rem;
    max-width: 5.5rem;
  }

  input[type="number"] {
    min-width: 5.5rem;
    max-width: 5.5rem;
  }

  .form-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }

  .row {
    align-items: flex-end;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #f2f2f2;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
  color: #212529;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;

  @media (max-width: 768px) {
    position: relative;
    margin-top: 3rem;
  }
`;
