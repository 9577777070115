import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdGroups } from "react-icons/md";
import AddButton from "../../components/AddButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomSearchInput from "../../components/CustomSearchInput";
import CustomThead from "../../components/CustomThead";
import EditAccessProfileModal from "../../components/EditAccessProfileModal";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";
import TableActions from "../../components/TableActions";
import UserInvitationModal from "../../components/UserInvitationModal";
import { User } from "../../core/interfaces/User";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { updateAlert } from "../../core/redux/reducer/alert";
import { getProfiles } from "../../core/redux/reducer/user";
import { CredentialsService } from "../../core/services/CredentialsService";
import { UserService } from "../../core/services/UserService";
import { isErrorResult } from "../../core/utils/api";
import { Container } from "./styles";
import { getUser } from "../../core/redux/reducer/auth";
import AssociateVehiclesAndTagsModal from "../../components/AssociateVehiclesAndTagsModal";

type Register = User & {
  isProfileEdited?: boolean;
  isEnableEdited?: boolean;
  email_verified_at: string | null;
};

type FormValues = {
  registers: Register[];
};

const Users: React.FC = () => {
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const profiles = useAppSelector(getProfiles);
  const actions = {
    link_tags_plates: t("electronic_fences.action_associate_vhicles"),
    reset_password: t("configs.user.reset_password"),
    delete: t("configs.user.user_delete"),
  };
  const actionOptions = Object.values(actions);

  const [invitation_is_visible, setInvitationIsVisible] = useState(false);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: string;
    order: any;
  }>({ page: 1, pages: 0, filter: "", order: undefined });
  const [deleteUser, setDeleteUser] = useState<User | null>(null);

  const [selectedTags, setSelectedTags] = useState<number[] | null>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<number[] | null>([]);

  const [associateModalVisible, setAssociateModalVisible] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<
    string | number | null
  >(null);

  const [resetPasswordUser, setResetPasswordUser] = useState<User | null>(null);
  const [isBusy, setBusy] = useState<boolean>(false);
  const [openedAccessProfile, setOpenedAccessProfile] =
    useState<boolean>(false);

  async function loadUsers({
    filter = "",
    page = 1,
    order,
  }: {
    filter: string;
    page: number;
    order: any;
  }) {
    try {
      const _response = await UserService.getUsers({ filter, page, order });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        // setUsers(_response.data);
        replace(_response.data);

        setPagination((prev) => ({
          ...prev,
          page: _response.meta?.current_page || 0,
          pages: _response.meta?.last_page || 0,
        }));
      }
    } catch (error) {
      console.log("loadUsers error", error);
    }
  }

  function handleOnInvitationSuccess() {
    setInvitationIsVisible(false);
    loadUsers(pagination);
    dispatch(
      updateAlert({
        title: t("message.user_successfully_guest"),
        message: t("message.user_receive_link"),
      })
    );
  }

  function handleEditAccessProfileSuccess() {
    setOpenedAccessProfile(false);
    // loadUsers(pagination);
    dispatch(
      updateAlert({
        title: t("configs.user.access_profile.message.success"),
      })
    );
  }
  const handleCloseAssociateModal = async () => {
    setAssociateModalVisible(false);
    // loadEmbeddedIntelligences(pagination);
  };

  function handleSelectAction(action: string, data: User) {
    if (action === actions.delete) {
      setDeleteUser(data);
    } else if (action === actions.reset_password) {
      setResetPasswordUser(data);
    } else if (action === actions.link_tags_plates) {
      setAssociateModalVisible(true);
    }
  }

  async function handleDeleteUser() {
    if (deleteUser?.id && !isBusy) {
      setBusy(true);

      await UserService.delete(deleteUser.id)
        .then((result) => {
          setDeleteUser(null);
          dispatch(
            updateAlert({ title: t("message.user_successfully_deleted") })
          );
          loadUsers(pagination);
        })
        .finally(() => setBusy(false));
    }
  }

  const loadUser = async (id: number) => {
    const user = await UserService.getUserById(id);

    // @ts-ignore
    const _tags = user.data?.vehicle_tags?.map((vehicle) => {
      return { id: vehicle?.id, label: vehicle?.key, key: vehicle?.key };
    });
    // @ts-ignore
    const _vehicles = user.data?.vehicles?.map((vehicle) => {
      return {
        id: vehicle?.id,
        label: vehicle?.license_plate,
        value: vehicle?.id,
      };
    });

    setSelectedVehicles(_vehicles);
    setSelectedTags(_tags);
  };

  async function onSubmit(data: any) {
    const { vehicle_type, tags } = data;

    const vehicles = vehicle_type?.map(
      (vehicle: any) => vehicle?.value || vehicle?.id
    );
    const vehicle_tags = tags?.map((vehicle: any) => vehicle?.id);
    const payload = {
      vehicle_tags: vehicle_tags || [],
      vehicles: vehicles || [],
    };

    if (user) {
      if (user.id === selectedGroupId) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            message: t("message.error.ownuser"),
            type: "error",
          })
        );
      } else {
        try {
          const response = await UserService.associateVehicles(
            Number(selectedGroupId),
            payload
          );

          if (response?.message === "Successfully associated vehicles") {
            dispatch(
              updateAlert({
                title: t("message.success.save_data"),
                type: "success",
              })
            );
          } else {
            dispatch(
              updateAlert({
                title: t("message.error.save_data"),
                message: response?.message,
                type: "error",
              })
            );
          }
        } catch (error: any) {
          dispatch(
            updateAlert({
              title: t("message.error.save_data"),
              message: error.message,
              type: "error",
            })
          );
        }
      }
    }
  }

  async function handleResetPasswordUser() {
    setResetPasswordUser(null);
    if (!resetPasswordUser || isBusy) return;

    setBusy(true);

    const response = await CredentialsService.resetPassword(
      resetPasswordUser.email
    ).finally(() => setBusy(false));

    if (!response.success) {
      dispatch(
        updateAlert({
          title: t("message.user_error_reset_password"),
          message: response.message,
          type: "error",
        })
      );
      return;
    }

    dispatch(
      updateAlert({
        title: t("message.user_successfully_reset_password"),
        message: t("message.user_successfully_reset_password_send_email"),
      })
    );
  }

  async function onSubmitUpdate({ registers }: FormValues) {
    try {
      for await (let data of registers) {
        if (!!data.id && data.isProfileEdited) {
          const { email_verified_at, id: user_id, ...rest } = data;

          const response = await UserService.update({ user_id, ...rest });

          if (!response?.success) throw Error(response?.message);

          const i = registers.findIndex((item) => item.id === data.id);
          registers[i] = { ...data, isProfileEdited: false };
        }

        if (!!data.id && data.isEnableEdited) {
          const response = !!data.email_verified_at
            ? await UserService.activate(data.id)
            : await UserService.desactivate(data.id);

          if (!response?.success) throw Error(response?.message);

          const i = registers.findIndex((item) => item.id === data.id);
          registers[i] = { ...data, isEnableEdited: false };
        }
      }

      reset({ registers });

      dispatch(updateAlert({ title: t("message.success.save_data") }));
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleEditCancel = async () => {
    replace(fields);
  };

  useEffect(() => {
    loadUsers({
      filter: pagination.filter,
      page: pagination.page,
      order: pagination.order,
    });
  }, [pagination.filter, pagination.page, pagination.order, user?.company_id]);

  useEffect(() => {
    if (selectedGroupId) {
      loadUser(Number(selectedGroupId));
    }
    if (!associateModalVisible) {
      setSelectedTags([]);
      setSelectedVehicles([]);
    }
  }, [selectedGroupId, associateModalVisible]);
  return (
    <Container className="mb-3">
      <Row className="mb-4">
        <Col xl={6} lg={6} md={4}>
          <AddButton onClick={() => setInvitationIsVisible(true)}>
            {t("configs.user.add")}
          </AddButton>
        </Col>

        <Col xl={6} lg={6} md={8}>
          <Stack direction="horizontal" gap={2}>
            <Button
              className="w-100"
              variant="outline-secondary"
              onClick={() => setOpenedAccessProfile(true)}
            >
              <MdGroups size={24} />
              {t("configs.user.edit_access_level")}
            </Button>

            <CustomSearchInput
              className="search"
              placeholder={t("search")}
              onChange={(e) =>
                setPagination((prev) => ({
                  ...prev,
                  page: 1,
                  filter: e.target.value,
                }))
              }
            />
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive>
            <CustomThead
              data={[
                { column: "name", name: t("configs.user.table_name") },
                { column: "email", name: t("configs.user.table_email") },
                { column: "profile_name", name: t("configs.user.table_nivel") },
                {
                  column: "email_verified_at",
                  name: t("configs.user.table_status"),
                },
                {
                  column: "",
                  name: t("configs.user.table_enabled"),
                  menu: true,
                },
                { column: "menu", name: <th />, menu: true },
              ]}
              onChangeOrder={(values) =>
                setPagination((prev) => ({
                  ...prev,
                  page: 1,
                  order: values,
                }))
              }
            />

            <tbody>
              {fields?.map((item, index) => (
                <tr key={`${index}_${item.fieldId}`}>
                  <td>
                    <div className="d-flex align-items-center text-nowrap">
                      {/* <div
                        className="user_img"
                        style={{ backgroundImage: `url(${user_placeholder})` }}
                      ></div> */}
                      {`${item.name} ${item.last_name}`}
                    </div>
                  </td>
                  <td>{item.email}</td>
                  <td className="col-3">
                    {profiles.length > 0 && (
                      <Form.Select
                        defaultValue={item.user_profile_id}
                        {...register(`registers.${index}.user_profile_id`, {
                          onChange: () => {
                            setValue(
                              `registers.${index}.isProfileEdited`,
                              true
                            );
                          },
                        })}
                      >
                        {profiles.map((profile) => (
                          <option
                            value={profile.id}
                            key={`user_${item.id}_profile_option_${profile.id}`}
                          >
                            {profile.profile_name}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </td>
                  <td>
                    {!!item.email_verified_at
                      ? t("configs.user.user_status_active")
                      : t("configs.user.user_status_guest")}
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={`${index}_enabled`}
                      defaultChecked={!!item?.email_verified_at}
                      {...register(`registers.${index}.email_verified_at`, {
                        onChange: (e) => {
                          setValue(`registers.${index}.isEnableEdited`, true);
                        },
                      })}
                    />
                  </td>
                  <td>
                    <TableActions
                      id={item.id}
                      items={actionOptions}
                      onSelectAction={(action) => {
                        setSelectedGroupId(item.id);
                        handleSelectAction(action, item);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-5">
        <Footer>
          <div className="mb-3">
            <Stack direction="horizontal" gap={2}>
              <Button
                onClick={handleSubmit(onSubmitUpdate, console.log)}
                disabled={
                  !watch("registers")?.find(
                    ({ isProfileEdited, isEnableEdited }) =>
                      !!isProfileEdited || !!isEnableEdited
                  )
                }
              >
                {isSubmitting ? t("status.saving") : t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={handleEditCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </div>

          <div className="mx-3">
            <Pagination
              current_page={pagination.page}
              qtd={pagination.pages}
              onSelectPage={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
            />
          </div>
        </Footer>
      </Row>

      <UserInvitationModal
        visible={invitation_is_visible}
        onClose={() => setInvitationIsVisible(false)}
        onSuccess={() => handleOnInvitationSuccess()}
      />

      <ConfirmationModal
        visible={!!deleteUser?.id}
        title={t("message.user_delete")}
        message={t("message.action_not_reversed")}
        onConfirm={handleDeleteUser}
        onClose={() => setDeleteUser(null)}
        isLoading={isBusy}
      />

      <ConfirmationModal
        visible={!!resetPasswordUser?.id}
        title={t("message.user_reset_password")}
        message={t("message.action_not_reversed")}
        onConfirm={handleResetPasswordUser}
        onClose={() => setResetPasswordUser(null)}
        isLoading={isBusy}
      />

      <EditAccessProfileModal
        visible={openedAccessProfile}
        onClose={() => setOpenedAccessProfile(false)}
        onSuccess={() => handleEditAccessProfileSuccess()}
      />
      <AssociateVehiclesAndTagsModal
        onClose={handleCloseAssociateModal}
        onSubmit={onSubmit}
        visible={associateModalVisible}
        selectedTags={selectedTags}
        selectedVehicles={selectedVehicles}
      />
    </Container>
  );
};

export default Users;
