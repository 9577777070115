import { api } from ".";
import { ApiResponseBase } from "../interfaces/ApiResponseBase";
import { IContractor } from "../interfaces/Contractor";
import { ErrorResult } from "../interfaces/ErrorResult";

export interface GetContractor {
  filter?: string;
  page?: number;
}

export class ContractorService {
  static async get({
    filter,
    page,
  }: GetContractor): Promise<ApiResponseBase<IContractor[]> | ErrorResult> {
    const params = filter ? `&conditions=company_name:like:%${filter}%` : "";
    return api
      .get(`/api/v2/subcontractor/contractor?page=${page}${params}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async associate(contractorId: number): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/contractor/associate-contractor`, {
        contractor_id: contractorId,
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async disassociate(contractorId: number): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/contractor/disassociate-contractor`, {
        contractor_id: contractorId,
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async setAdvancedControl(
    contractorId: number,
    advancedControl: number
  ): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/contractor/set-advanced-control`, {
        contractor_id: contractorId,
        advanced_control: advancedControl,
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(contractorId: number): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/contractor`, {
        data: { contractor_id: contractorId },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
