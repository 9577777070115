import { api } from ".";
import { ApiPublic, ApiPublicObject } from "../interfaces/ApiPublic";
import { ErrorResult } from "../interfaces/ErrorResult";

export class ApiPublicService {
  static async get(): Promise<ApiPublic | ErrorResult> {
    return await api
      .get(`/api/v2/api-public`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(
    type: "production" | "sandbox"
  ): Promise<ApiPublicObject | ErrorResult> {
    return await api
      .post(`/api/v2/api-public/${type}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async clientId(): Promise<ApiPublicObject | ErrorResult> {
    return await api
      .post(`/api/v2/api-public/generate-clientid`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
