import { api } from ".";
import { ApiResponseBase } from "../interfaces/ApiResponseBase";
import { AuditEvent, AuditModule, AuditRegistry } from "../interfaces/Audit";
import { AuditRegistries } from "../interfaces/AuditRegistries";
import { ErrorResult } from "../interfaces/ErrorResult";
import { IPositions } from "../interfaces/IPositions";
import { IReportsVehicles } from "../interfaces/Vehicle";
import { VehiclePosition } from "../interfaces/VehiclePosition";
import { isErrorResult } from "../utils/api";

interface GetAudit {
  filter?: Object;
  page?: number;
}

export class AuditService {
  static async getEvents({
    filter,
    page,
  }: GetAudit): Promise<AuditEvent[] | AuditEvent | ErrorResult> {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/audit/events?page=${page}${filterQuery}`)
      .then((r) => r.data?.data)
      .catch((e) => e?.response?.data);
  }

  static async getModules({
    filter,
    page,
  }: GetAudit): Promise<ApiResponseBase<AuditModule> | ErrorResult> {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/audit/modules?page=${page}${filterQuery}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getRegisters({
    filter,
    page,
  }: GetAudit): Promise<ApiResponseBase<AuditRegistry>> {
    return await api
      .get("/api/v2/audit/registry", {
        params: {
          page,
          ...filter,
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getRegistersDetails(registerId: any) {
    return await api
      .get(`/api/v2/audit/registries/${registerId}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getRegistersByUserId(id: number): Promise<ApiResponseBase<any>> {
    return await api
      .get(`/api/v2/audit/registry?user_id=${id}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getEventsOptions(
    search: string,
    loadedOptions: any,
    additional?: any
  ) {
    const { page } = additional;
    const response = await this.getEvents({
      filter: {},
      page,
    });
    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    if (Array.isArray(response)) {
      options.options = response.map((d) => ({
        label: `${d.name}`,
        value: d.id,
      }));
    } else {
      options.options.push({
        label: `${response.name}`,
        value: response.id,
      });
    }

    options.hasMore =
      //@ts-ignore
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;
  }

  static async postDownload(data: IReportsVehicles) {
    return api
      .post("/api/v2/audit/export-consolidated-report", data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
