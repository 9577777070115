import React, { HTMLAttributes } from "react";
import { Link } from "./styles";

const LinkGoBack: React.FC<HTMLAttributes<HTMLAnchorElement>> = (props) => {
  return (
    <div className="d-flex align-flex-start">
      <Link {...props} />
    </div>
  );
};

export default LinkGoBack;
