import { Card as BSCard, Container as BSContainer } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  .pr-4 {
    padding-right: 56px;
  }
  .title {
    color: #6c757d;
    font-weight: 500;
  }

  .billing_title {
    color: #000000;
    font-size: 18px;
  }

  strong {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
  }

  .text-right {
    text-align: right;
  }

  .heading__5 {
    color: #6c757d;
    font-weight: 500;
    font-size: 20px;
  }

  .line-text-decoration-line-through {
    text-decoration: line-through;
  }

  .content {
    color: #6c757d;
    font-weight: 500;
    font-size: 16px;
  }

  .flex-initial {
    flex: initial;
  }

  .link {
    color: #2f80ed;
    font-size: 16px;
    margin: 0;
    border: 0;
    text-decoration: underline;
    background: none;
  }

  .spotlight_title {
    color: #6c757d;
    font-weight: 500;
    font-size: 28px;
  }

  .w-300 {
    max-width: 300px;
  }

  .button {
    border: 0;
    background: 0;
    color: #6c757d;
  }

  .action {
    color: #2f80ed;
    text-decoration: underline;
    border: 0;
    padding: 0;
    background: none;
  }

  .divider-b {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const Card = styled(BSCard)`
  min-height: 85vh;
  width: 100%;
  overflow: hidden;

  .card-body,
  .config-content {
    min-height: 85vh;
  }

  .config-content {
    padding-top: 27px;
    /* padding-right: 30px; */

    & > div {
      padding-right: 20px !important;
    }
  }
`;
