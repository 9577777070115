import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { Vehicle } from "../../interfaces/Vehicle";

// Define a type for the slice state
interface VehicleState {
  vehicles: Vehicle[];
}

// Define the initial state using that type
const initialState: VehicleState = {
  vehicles: [],
};

interface ReducerActions extends SliceCaseReducers<VehicleState> {
  updateVehicles(state: VehicleState, action: PayloadAction<Vehicle[]>): void;
}

export const vehicleSlice: Slice<VehicleState, ReducerActions> = createSlice({
  name: "vehicle",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateVehicles: (state, action: PayloadAction<Vehicle[]>) => {
      state.vehicles = action.payload;
    },
  },
});

export const { updateVehicles } = vehicleSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getVehicles = (state: RootState) => state.vehicle.vehicles;

export default vehicleSlice.reducer;
