export class LocalStorageUtils {
  static key: string;

  protected static setItem(key: string, data: any) {
    let _data = data;

    if (typeof data !== typeof "") _data = JSON.stringify(data);

    localStorage.setItem(key, _data);
  }

  protected static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  protected static getItem(key: string) {
    const item = localStorage.getItem(key);

    if (!item) return null;

    let data: any = item;

    try {
      data = JSON.parse(item);
    } catch (error) {}

    return data;
  }
}
