import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";

import { parse, format, isValid } from "date-fns";
import { IDateTimerPicker } from "./IDateTimerPicker";
import InputMask from "react-input-mask";
import { InputContainer } from "./styles";
import { MdCalendarToday } from "react-icons/md";
import { Calendar } from "./Calendar";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../../core/hooks/useOnClickOutside";

export const DateTimerPicker = ({
  onChange,
  value,
  label,
  onSelectDate,
  returnDateFormat = "yyyy-MM-dd'T'HH:mm",
  disabled,
  haveTimerSelection = true,
}: IDateTimerPicker) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [dateFormat, setDateFormat] = useState(
    haveTimerSelection ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"
  );
  const { i18n } = useTranslation();
  useOnClickOutside(calendarRef, closeCalendar);

  //check if the haveTimerSelection is true, and validate the time format
  function validateDateFormatOnTimerPickerOn(language: string) {
    if (haveTimerSelection) {
      return language === "en" ? "MM/dd/yyyy HH:mm" : "dd/MM/yyyy HH:mm";
    } else {
      return language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy";
    }
  }

  //call when its a valid date on the input. This function return a date object contain a date and a formated date based on the format passed on props
  function onChangeDate(strDate: string) {
    if (haveTimerSelection && strDate.length === 16) {
      //we need to check if the data its valid, if its not, we change the date to today to not break the input
      if (isValid(parse(strDate, dateFormat, new Date()))) {
        const result = parse(strDate, dateFormat, new Date());
        const formattedDate = format(result, returnDateFormat);
        onSelectDate({
          formattedDate,
          date: result,
        });
      } else {
        const formattedDate = format(new Date(), returnDateFormat);
        onSelectDate({
          formattedDate,
          date: new Date(),
        });
      }
    }

    if (!haveTimerSelection && strDate.length === 10) {
      //we need to check if the data its valid, if its not, we change the date to today to not break the input
      if (isValid(parse(strDate, dateFormat, new Date()))) {
        const result = parse(strDate, dateFormat, new Date());
        const formattedDate = format(result, returnDateFormat);
        console.log({
          formattedDate,
          date: result,
        });
        onSelectDate({
          formattedDate,
          date: result,
        });
      } else {
        const formattedDate = format(new Date(), returnDateFormat);
        onSelectDate({
          formattedDate,
          date: new Date(),
        });
      }
    }
  }

  function closeCalendar() {
    setIsCalendarOpen(false);
  }
  function openCalendar() {
    setIsCalendarOpen(true);
  }

  //format the onChange function to the right format
  function handleFormatOnChange(value: string) {
    let formated_date = value;

    if (!value.includes("_") && value.length === 16 && haveTimerSelection) {
      if (isValid(parse(value, dateFormat, new Date()))) {
        formated_date = format(
          parse(value, dateFormat, new Date()),
          dateFormat
        );
      } else {
        formated_date = format(new Date(), dateFormat);
      }
    }
    if (!value.includes("_") && value.length === 10 && !haveTimerSelection) {
      formated_date = format(parse(value, dateFormat, new Date()), dateFormat);
    }

    onChange(formated_date);
  }

  useEffect(() => {
    if (!value.includes("_")) {
      onChangeDate(value);
    }
  }, [value]);

  //watch the language changes to convert the mask to the correct format
  useEffect(() => {
    // const newFormat =
    //   i18n.language === "en" ? "MM/dd/yyyy HH:mm" : "dd/MM/yyyy HH:mm";
    const newFormat = validateDateFormatOnTimerPickerOn(i18n.language);
    console.log({ newFormat, dateFormat });
    setDateFormat(newFormat);
    if (haveTimerSelection) {
      if (value.length === 16 && !value.includes("_")) {
        if (isValid(parse(value, dateFormat, new Date()))) {
          onChange(format(parse(value, dateFormat, new Date()), newFormat));
        } else {
          onChange(format(new Date(), newFormat));
        }
      }
    } else {
      if (value.length === 10 && !value.includes("_")) {
        onChange(format(parse(value, dateFormat, new Date()), newFormat));
      }
    }
  }, [i18n.language]);

  return (
    <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
      {label && <Form.Label>{label}</Form.Label>}
      <InputContainer className="position-relative">
        <InputMask
          mask={haveTimerSelection ? "99/99/9999 99:99" : "99/99/9999"}
          value={value}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleFormatOnChange(e.currentTarget.value)
          }
          disabled={disabled}
        >
          {(inputProps: any) => (
            <Form.Control disabled={disabled} {...inputProps} />
          )}
        </InputMask>

        <button
          type="button"
          onClick={openCalendar}
          className="position-absolute"
        >
          <MdCalendarToday />
        </button>

        {isCalendarOpen && (
          <Calendar
            ref={calendarRef}
            haveTimerSelection={haveTimerSelection}
            onSelectDate={(date) => {
              if (date) {
                onSelectDate({
                  date,
                  formattedDate: format(date, returnDateFormat),
                });
                onChange(format(date, dateFormat));
              }
            }}
            onSelectTime={(time) => {
              if (time) {
                onChange(format(time, dateFormat));
              }
            }}
            selectedDate={parse(value, dateFormat, new Date())}
            selectedTime={parse(value, dateFormat, new Date())}
          />
        )}
      </InputContainer>
    </Form.Group>
  );
};
