export const SENSORS = [
  { label: "sensors.temperature", value: 1 },
  { label: "sensors.speed", value: 2 },
  { label: "sensors.fuel", value: 3 },
  { label: "sensors.battery", value: 4 },
  { label: "sensors.geofence", value: 5 },
  { label: "sensors.camera", value: 6 },
  { label: "sensors.moisture", value: 7 },
  { label: "sensors.security", value: 8 },
  { label: "sensors.unknown", value: 255 }
];