import React, { useEffect, useState } from 'react'
import * as S from './style';
import { 
  Button, 
  Col, 
  Form, 
  Row, 
  Stack, 
} from 'react-bootstrap';
import { 
  BsArrowLeftShort,
} from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { isErrorResult } from '../../../core/utils/api';
import { SecurityRules } from '../../../core/services/SecurityRules';
import { SecurityRulesGroupById } from '../../../core/interfaces/SecurityRules';
import { useTranslation } from 'react-i18next';
import AddButton from '../../../components/AddButton';
import LinkGoBack from '../../../components/LinkGoBack';
import EmbeddedIntelligenceForm from '../EmbeddedIntelligenceForm';
import { SecuryRulesGroup } from '../../../core/services/SecuryRulesGroup';
import { SecuryRulesGroupItem } from '../../../core/interfaces/SecuryRulesGroup';
import { useAppDispatch } from '../../../core/redux/hooks';
import { updateAlert } from '../../../core/redux/reducer/alert';
import Pagination from '../../../components/Pagination';
import { APiQueryBase } from '../../../core/interfaces/ApiResponseBase';
import { Ports } from '../../../core/interfaces/Ports';
import { FleetMateService } from '../../../core/services/FleetMateService';

const RulesList:React.FC = () => {
  const [ruleGroup, setRouleGroup] = useState<any[] | null>([]);
  const [group, setGroup] = useState<SecuryRulesGroupItem>();
  const [name, setName] = useState<string>()
  const [openIndex, setOpenIndex] = useState<number | null>(null);   
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
  }>({ page: 0, pages: 0});
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAccordionClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  
  async function loadRulesList({
    page = 1,
  }: APiQueryBase) {
    try {
      if(id){
        const _response = await SecurityRules.getByGroup({ page, id });
        const isError = isErrorResult(_response);
        if (!isError && _response) {
          //@ts-ignore
          setRouleGroup(_response.data)
          setPagination((prev) => ({
            ...prev,
            page: _response.meta?.current_page || 1,
            pages: _response.meta?.last_page || 0,
          }));
        }
      }
    } catch (error: any) {
      console.log("Erro in load fleetmate.ei", error.message);
    }
  };

  async function loadRuleGroup(){
    try {
      if(id){
        const _response = await SecuryRulesGroup.getById(id);
        const isError = isErrorResult(_response);
        if (!isError && _response) {
          setGroup(_response);
        }
      }
    } catch (error: any) {
      console.log("Erro in load fleetmate.ei", error.message);
    }
  }

  async function onSubmit(){
    try{
      if(group){
        SecuryRulesGroup.put({id: id, name: name})
        dispatch(
          updateAlert({
            title: t("message.success.save_data"),
            onClick() {
              navigate('/configs/mate')
            },
          })
        );
      } else {
        SecuryRulesGroup.post({name: name})
        .then((resp) => {
          dispatch(
            updateAlert({
              title: t("message.success.save_data"),
              onClick() {
                navigate(`/configs/mate/${resp?.id}`)
              },
            })
          );
        })
      }
      loadRulesList({...pagination})
    } catch(err){
      console.error(err)
    }
  }
  
  useEffect(() => {
    if(id) {
      loadRulesList(pagination)
      loadRuleGroup()
    }
  }, [id, pagination.page])

  return (
    <S.Container fluid className="mb-3">
      <Row className="mb-4 pb-3 border-bottom">
        <Col className="d-flex align-items-end" lg={3}>
          <LinkGoBack className="mx-4" onClick={() => navigate("/configs/mate")}>
            <BsArrowLeftShort size={24} />
            {t("link.go_back")}
          </LinkGoBack>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="d-flex align-items-end" lg={3}>
          <AddButton 
            onClick={() => {
              if(id) navigate("new-rule")
            }}
          >
            {t("configs.fleetmate.ei.add_button_new_rule")}
          </AddButton>
        </Col>
      </Row>
      <S.AccordionList>
        {ruleGroup?.map((item, index) => (
          <EmbeddedIntelligenceForm 
            id={item.id.toString()}
            cancel={() => handleAccordionClick(index)}
            key={index}
            onClick={() => handleAccordionClick(index)}            
            isOpen={openIndex === index}
            title={item.name}
            enabled={item.enabled}
            refetch={() => loadRulesList(pagination)}
          />
        ))}
      </S.AccordionList>
      <Row className="mt-4">
        <Form.Group className="mb-3" as={Col} lg={4} controlId="ruleset_name">
          <Form.Label style={{fontWeight: 700}}>{t("sensors.rule.table_ruleset_name")}</Form.Label>
          <Form.Control
            placeholder={t("sensors.rule.table_ruleset_name")}
            defaultValue={group?.name}
            onChange={(e) => setName(e?.target?.value)}
            // isInvalid={!!errors?.name?.message}
          />
          {/* <Form.Control.Feedback type="invalid">
            {errors?.name?.message}
          </Form.Control.Feedback> */}
        </Form.Group>
      </Row>
      <Row>
        <Stack className="mt-3" direction="horizontal" gap={3}>
          <Button type="submit" onClick={onSubmit}>
            {t("button.save")}
          </Button>
          <Button variant="secondary" onClick={() => navigate('/configs/mate')}>
            {t("button.cancel")}
          </Button>
        </Stack>
      </Row>
      <div className="mx-3">
        <Pagination
          current_page={pagination.page}
          qtd={pagination.pages}
          onSelectPage={(page) => {
            loadRulesList({ page });
            setOpenIndex(null)
          }}
        />
      </div>
    </S.Container>
  )
}

export default RulesList