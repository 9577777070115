import React, { CSSProperties, HTMLAttributes } from "react";
import { Stack } from "react-bootstrap";
import { FiAlertTriangle } from "react-icons/fi";
import { Container, Link } from "./styles";

interface LinkAlertProps extends HTMLAttributes<HTMLAnchorElement> {
  children?: string;
  style?: CSSProperties;
  reverse?: boolean;
}

export default function LinkAlert({
  children,
  style,
  reverse,
  ...props
}: LinkAlertProps) {
  return (
    <Container style={style}>
      <Link {...props}>
        <Stack
          className={reverse ? "flex-row-reverse" : ""}
          direction="horizontal"
          gap={2}
        >
          <span>{children}</span>
          <FiAlertTriangle size={18} />
        </Stack>
      </Link>
    </Container>
  );
}
