import styled from "styled-components";

export const Container = styled.div`
  /* td:nth-child(1) {
    width: 250px;
  } */

  th {
    text-transform: uppercase;
  }

  tbody {
    height: 140px !important;
  }

  td:last-child {
    width: 100px;
  }
`;
