import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer";
import { isErrorResult } from "../../../core/utils/api";
import EntriesFieldArray from "./entriesFieldArray";
import Intervals from "./intervals";
import OutputsFieldArray from "./outputsFieldArray";
import { FleetMateService } from "../../../core/services/FleetMateService";
import { FleetMateConfigs } from "../../../core/interfaces/FleetMate";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { getUser } from "../../../core/redux/reducer/auth";

const FleetMateSettings: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const user = useAppSelector(getUser);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(
    null
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FleetMateConfigs>();

  const onSubmit: SubmitHandler<FleetMateConfigs> = async (data) => {
    const delays: { [key: string]: number } = data.entryPorts
      .filter((port) => Number(port.delay) !== 0)
      .reduce((acc: { [key: string]: number }, port) => {
        acc[`port${port.id}`] = Number(port.delay);
        return acc;
      }, {});

    const entryPorts: { [key: string]: string } = data.entryPorts.reduce(
      (acc: { [key: string]: string }, port: any, index: number) => {
        acc[`port${index + 1}`] = port.sensor.toUpperCase();
        return acc;
      },
      {}
    );

    const exitPorts: { [key: string]: any } = data.exitPorts.reduce(
      (acc: { [key: string]: any }, port: any, index: number) => {
        const { id, name, ...rest } = port;
        acc[`port${index + 1}`] = {
          ...rest,
          pulsed: port.pulsed === "true" || port.pulsed === true,
        };
        return acc;
      },
      {}
    );

    const lorawanNetworkCommunicationIntervals =
      data.lorawanNetworkCommunicationIntervals;

    const iridiumNetworkCommunicationIntervals =
      data.iridiumNetworkCommunicationIntervals;

    const objToSend = {
      data: {
        lorawanNetworkCommunicationIntervals,
        iridiumNetworkCommunicationIntervals,
        entryPorts,
        delays,
        exitPorts,
      },
    };

    try {
      const result = await FleetMateService.update(objToSend);
      if (!isErrorResult(result) && result) {
        dispatch(updateAlert({ title: t("message.success.save_data") }));
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.log("post error", error);
    }
  };

  const onError = (error: any) => console.log("onSubmitError", error);

  async function loadConfigs(id: string | number | null = null) {
    let result = await FleetMateService.getConfigs(id);

    if (
      !result?.data?.entryPorts ||
      !result?.data?.exitPorts ||
      result?.data?.entryPorts?.length === 0 ||
      result?.data?.exitPorts === 0
    ) {
      result = await FleetMateService.getConfigs();
    }

    const data = result?.data;
    setValue("entryPorts", data?.entryPorts);
    setValue("exitPorts", data?.exitPorts);
    setValue(
      "iridiumNetworkCommunicationIntervals",
      data?.iridiumNetworkCommunicationIntervals
    );
    setValue(
      "lorawanNetworkCommunicationIntervals",
      data?.lorawanNetworkCommunicationIntervals
    );
  }

  useEffect(() => {
    if (selectedVehicleId !== null && selectedVehicleId !== "0") {
      loadConfigs(selectedVehicleId);
    } else {
      loadConfigs();
    }
  }, [user?.company_id, selectedVehicleId]);
  return (
    <Form
      className="pt-3"
      noValidate
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Intervals control={control} register={register} />
      <EntriesFieldArray control={control} register={register} />
      <OutputsFieldArray control={control} register={register} />

      <Footer>
        <div className="mt-3 mb-3 mx-2">
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
          <Button id="button-submit-fleetmate" type="submit">
            {isSubmitting ? t("status.saving") : t("button.save")}
            {isSubmitting && <Spinner animation="border" size="sm" />}
          </Button>
          <Button className="ms-3" variant="secondary">
            {t("button.cancel")}
          </Button>
        </div>
      </Footer>
    </Form>
  );
};

export default FleetMateSettings;
