import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaFilter } from "react-icons/fa";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ActionChips from "../../components/ActionChips";
import CustomSearchInput from "../../components/CustomSearchInput";
import CustomThead from "../../components/CustomThead";
import LinkAlert from "../../components/LinkAlert";
import MapModal from "../../components/MapModal";
import SensorFilterModal, {
  SensorFilterEvent,
} from "../../components/SensorFilterModal";
import TableFooter from "../../components/TableFooter";
import { APiQueryBase } from "../../core/interfaces/ApiResponseBase";
import { Sensor } from "../../core/interfaces/Sensors";
import {
  ISetVehicleEntry,
  SensorsService,
} from "../../core/services/SensorsService";
import { isErrorResult } from "../../core/utils/api";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { Container } from "./styles";
import { useFormatDate } from "../../core/hooks/useFormatDate";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { getUser } from "../../core/redux/reducer/auth";
import TableActions from "../../components/TableActions";
import AssociateVehicleModal from "../../components/AssociateVehicleModal";
import { updateAlert } from "../../core/redux/reducer/alert";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";

const Sensors: React.FC = () => {
  const dispatch = useAppDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState({} as Sensor);
  const [showAssociateModal, setShowAssociateModal] = useState(false);
  const [centerMap, setCenterMap] = useState({ lat: 1, lng: 1 });
  const [sensorId, setSensorId] = useState("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order?: { [key: string]: string };
  }>({ page: 1, pages: 0, filter: "", order: { date_time: "desc" } });

  type Register = Sensor & {
    isEdited?: boolean;
    isSelected?: boolean;
  };

  type FormValues = {
    registers: Register[];
  };

  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { validateDateFormatAndTime } = useFormatDate();
  const navigate = useNavigate();

  const actions = {
    option: t("link_vehicle"),
  };

  const actionOptions = Object.values(actions);

  async function loadSensors({ filter, page = 1, order }: APiQueryBase) {
    setLoading(true);
    try {
      const response = await SensorsService.get({ filter, page, order });
      if (!isErrorResult(response) && response.data) {
        replace(response.data);
        setPagination((prev) => ({
          ...prev,
          page: response?.meta?.current_page || 1,
          pages: response?.meta?.last_page || 0,
        }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  function haveEditedItems(): boolean {
    return !!watch("registers")?.find((item) => !!item?.isEdited);
  }

  function onSearch(text: string): void {
    const filter = { ...pagination.filter, search: text };
    setPagination((prev) => ({ ...prev, filter }));
  }

  function onChangeFilter(options: SensorFilterEvent) {
    const { tags = [], vehicle_type, sensor_type, period } = options;
    const filter: any = {
      ...pagination.filter,
      sensor_type: sensor_type,
      vehicle_type: vehicle_type,
      tags: tags.map((element) => element.id),
      start_date: period.from,
      end_date: period.to,
    };

    if (!vehicle_type) delete filter.vehicle_type;
    if (!tags?.length) delete filter.tags;
    if (!sensor_type) delete filter.sensor_type;
    if (!period?.from) delete filter.start_date;
    if (!period?.to) delete filter.end_date;

    setPagination((prev) => ({ ...prev, filter }));
  }

  function getFormatSensor(value: number, type: number) {
    switch (type) {
      case 1:
        return `${value.toFixed(2)}°C`;
      case 2:
        return `${value} Km/h`;
      case 3:
        return `${value.toFixed(2)}%`;
      case 4:
        return `${value.toFixed(2)}%`;
      case 7:
        return `${value.toFixed(2)}%`;
      default:
        return `${value.toFixed(2)}`;
    }
  }

  function handleShowMap(lat: number, lng: number, sensorId: string) {
    setCenterMap({
      lat: lat,
      lng: lng,
    });
    setSensorId(sensorId);
    setShowMap(true);
  }

  useEffect(() => {
    loadSensors(pagination);
  }, [pagination.filter, pagination.page, pagination.order, user?.company_id]);

  return (
    <PrivateMasterPage
      title={t("sensors.title")}
      breadcrumb={[t("sensors.title")]}
    >
      <ProtectedComponent
        module_name={module_names.SENSORS}
        property="view"
        fallbackComponent={<Navigate to={"/fences"} />}
      >
        <Container fluid className="p-3">
          <Card className="mb-5">
            <Card.Body className={(!fields?.length && "flex-none") + " p-4"}>
              <Row className="mb-4">
                <Col>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("alerts");
                    }}
                  >
                    {t("sensors.alert_rules_button_text")}
                  </Button>
                </Col>

                <Col className="filter-col">
                  <Stack direction="horizontal" gap={2}>
                    <CustomSearchInput
                      placeholder={t("search")}
                      onChange={(e) => onSearch(e.target.value)}
                    />

                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowFilter(true)}
                    >
                      <FaFilter size={18} />
                    </Button>
                  </Stack>
                </Col>
              </Row>

              <Table responsive hover>
                <CustomThead
                  data={[
                    { column: "sensor_id", name: t("sensors.table_sensor") },
                    { column: "sensor_type_id", name: t("sensors.table_type") },
                    { column: "value", name: t("sensors.table_last_read") },
                    { column: "date_time", name: t("sensors.table_datetime") },
                    {
                      column: "plate",
                      name: t("sensors.table_vehicle"),
                    },
                    { column: "", name: t("sensors.table_location") },
                    { column: "driver", name: t("sensors.table_driver") },
                    { column: "", name: t("sensors.table_alert") },
                    { column: "menu", name: <th />, menu: true },
                  ]}
                  onChangeOrder={(order) =>
                    setPagination((prev) => ({ ...prev, order }))
                  }
                />
                <tbody>
                  {fields.map((item, index) => (
                    <tr key={`sensor_${index}`}>
                      <td>
                        <Link to={`/sensors/${item?.sensor_id}`}>
                          {item?.sensor_id}
                        </Link>
                      </td>
                      <td>{item?.type?.description}</td>
                      <td>
                        {getFormatSensor(item?.value, item?.sensor_type_id)}
                      </td>
                      <td>{validateDateFormatAndTime(item?.date_time)}</td>
                      <td>{item?.vehicle?.license_plate}</td>
                      <td>
                        <ActionChips
                          onClick={() =>
                            handleShowMap(
                              item.latitude,
                              item.longitude,
                              item?.sensor_id
                            )
                          }
                        >
                          {t("sensors.view_location")}
                        </ActionChips>
                      </td>
                      <td>{item?.driver_name}</td>
                      <td>
                        {item?.alerts?.length > 0 && (
                          <LinkAlert>
                            {item?.alerts?.length.toString()}
                          </LinkAlert>
                        )}
                      </td>

                      <td>
                        <TableActions
                          id={item.id}
                          items={actionOptions}
                          onSelectAction={(action) => {
                            setSelectedSensor(item);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Row>
                <Col className="d-flex justify-content-center">
                  {isLoading && <Spinner animation="border" />}
                </Col>
              </Row>
            </Card.Body>

            <TableFooter
              actions={{
                hidden: !haveEditedItems(),
                titleSubmit: isSubmitting
                  ? t("status.saving")
                  : t("button.save"),
                titleCancel: t("button.cancel"),
                isSubmitting,
              }}
              paginate={{
                current_page: pagination.page,
                qtd: pagination.pages,
                onSelectPage: (page) =>
                  setPagination((prev) => ({ ...prev, page })),
              }}
            />
          </Card>
        </Container>
      </ProtectedComponent>

      <SensorFilterModal
        visible={showFilter}
        onClose={() => setShowFilter(false)}
        onChange={onChangeFilter}
      />

      <MapModal
        visible={showMap}
        onClose={() => setShowMap(false)}
        center={centerMap}
        sensorId={sensorId}
      />

      <AssociateVehicleModal
        onFinishRequest={() => loadSensors(pagination)}
        visible={Boolean(selectedSensor.vehicle)}
        onClose={() => setSelectedSensor({} as Sensor)}
        sensor={selectedSensor}
      />
    </PrivateMasterPage>
  );
};

export default Sensors;
