import { InputGroup } from "react-bootstrap";
import styled from "styled-components";

export const SectionTitle = styled.label`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
`;

export const CustomInputGroup = styled(InputGroup)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  .form-label {
    margin-bottom: 0;
  }

  .form-select, .form-control {
    border-radius: 0.375rem !important;
  }
`;
