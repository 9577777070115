import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Comment, Details, Modal } from "./styles";

import { useForm, useFieldArray } from "react-hook-form";
import { AlertsService } from "../../core/services/AlertsService";
import { isErrorResult } from "../../core/utils/api";
import moment from "moment";
import { AlertComment } from "../../core/interfaces/Alert";

interface CommentsModalProps {
  visible: boolean;
  onClose?(): void;
  alertId: number | null;
}

type FormValues = {
  registers: AlertComment[];
};

const CommentsModal: React.FC<CommentsModalProps> = ({
  onClose,
  visible,
  alertId,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const { control } = useForm<FormValues>();

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  useEffect(() => {
    if (!alertId && alertId! > 0) return;
    handleComments(alertId!);
  }, [alertId]);

  const handleComments = async (id: number) => {
    setIsLoading(true);
    
    try {
      if(id > 0) {
        const response = await AlertsService.getComments(id);
  
        if (!isErrorResult(response) && response.data) {
          replace(response.data);
        }
      }
    } catch (error) {
      console.log("load comment error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    onClose?.();
  };

  return (
    <Modal {...props} show={visible} size="sm" centered onHide={handleCancel}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>{t("alerts.view_comments_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Spinner animation="border" />
            </Col>
          </Row>
        ) : (
          <>
            {fields?.length ? (
              <>
                {fields?.map((item, i) => (
                  <Card key={i}>
                    <CardBody>
                      <Comment>{item?.comment}</Comment>

                      <Stack gap={1} style={{ maxWidth: "fit-content" }}>
                        <Details>
                          {moment(item?.created_at).format(
                            "DD/MM/YYYY - HH:mm"
                          )}
                        </Details>
                        <Details>
                          {item?.user?.name} {item?.user?.last_name}
                        </Details>
                      </Stack>
                    </CardBody>
                  </Card>
                ))}
              </>
            ) : (
              <Modal.Footer>{t("alerts.comments_not_found")}</Modal.Footer>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CommentsModal;
