import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Company } from "../../core/interfaces/Company";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { getLocales, getUser } from "../../core/redux/reducer/auth";
import { CompanyResolver } from "../../core/schemaValidations/company";
import { CompanyService } from "../../core/services/CompanyService";
import { isErrorResult } from "../../core/utils/api";
import estados_cidades from "../../core/resources/states-cities.json";
import timezones_data from "../../core/resources/timezones.json";
import { LanguageService } from "../../core/services/LanguageService";
import Footer from "../../components/Footer";
import { updateAlert } from "../../core/redux/reducer/alert";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import CountryFlagSelect from "../../components/CountryFlagSelect";
import { cep, cnpj } from "../../utils/masks";
import { MaskService } from "../../utils/masks-service";

type FormValues = Company & {};

const ConfigsCompany: React.FC = () => {
  const [languages, setLanguages] = useState<any[]>([]);
  const [defaultValues, setDefaultValues] = useState<FormValues>();

  const user = useAppSelector(getUser);
  const locales = useAppSelector(getLocales);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
    setValue,
    reset,
    getValues,
    control,
  } = useForm<FormValues>({
    resolver: CompanyResolver,
    mode: "onChange",
    defaultValues,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data: Company) => {
    data.cep = MaskService.toRawValue("cep", data?.cep.toString());
    data.cnpj = MaskService.toRawValue("cnpj", data?.cnpj);

    setErrorMessage("");
    const _result = await CompanyService.update(data);
    const isError = isErrorResult(_result);

    if (isError) {
      setErrorMessage(_result.message);
    } else if (!isError) {
      dispatch(updateAlert({ title: t("message.success.save_data") }));
      const values = getValues();

      setDefaultValues(values);
      reset(values, {
        keepDirty: false,
        keepIsValid: false,
        keepErrors: false,
      });
    }
  };
  const onError = (error: any) => console.log("onSubmitError", error);

  function handleCancel(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    setErrorMessage("");
    reset(defaultValues, {
      keepDirty: false,
      keepIsValid: false,
      keepErrors: false,
    });
  }

  useEffect(() => {
    Promise.all([CompanyService.get(), LanguageService.get()])
      .then(([companyResponse, languageResponse]) => {
        const company = companyResponse?.data?.[0]?.[0];

        if (company.cep) company.cep = MaskService.toMask("cep", company.cep);

        if (company.cnpj)
          company.cnpj = MaskService.toMask("cnpj", company.cnpj);

        setLanguages(languageResponse.data);
        setDefaultValues(company);

        if (company) {
          Object.keys(company).forEach((key: string) => {
            const _key = key as keyof Company;
            setValue(_key, company?.[_key as keyof Company] || "");
          });
        }
      })
      .catch((e) => {
        console.log("useEffect ConfigsCompany error", e);
      });
  }, [user?.company_id]);

  function getCities(state: string) {
    return estados_cidades.estados.find((estado) => estado.sigla === state)
      ?.cidades;
  }

  const timezones = timezones_data?.flatMap((timezone) => {
    // const [time, ...name] = timezone.text?.split(" ");

    return timezone.utc.map((utc) => ({
      name: utc, //`${name.join(" ")} ${time}`,
      value: timezone.value,
    }));
  });

  console.log("Render ConfigsCompany", { languages });

  return (
    <Container fluid className="px-0">
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Row className="mb-4">
          <Col className="d-flex align-items-center">
            <h5 className="text-dark">{t("configs.company.language_title")}</h5>
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="country">
            <Form.Label>{t("configs.company.country")}</Form.Label>
            <Controller
              control={control}
              name="country"
              defaultValue="BR"
              render={({ field: { name, value, onChange, onBlur } }) => (
                <CountryFlagSelect
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="languages_id">
            <Form.Label>{t("configs.company.language")}</Form.Label>
            <Form.Select {...register("languages_id")}>
              {languages?.map((item) => (
                <option key={`language_${item.id}`} value={item.id}>
                  {item.language}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} controlId="timezone">
            <Form.Label>{t("configs.company.timezone")} (GMT)</Form.Label>
            <Form.Select {...register("timezone")}>
              {timezones.map((timezone, i) => (
                <option key={i}>{timezone.name}</option>
              ))}
              {/* <option>Brasília (+3:00)</option> */}
            </Form.Select>
          </Form.Group>
        </Row>

        <Row>
          <Col className="mb-3 mt-5" xs="12">
            <h5 className="text-dark">{t("configs.company.company_title")}</h5>
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="company_name" xs="8">
            <Form.Label>{t("configs.company.company_name")}</Form.Label>
            <input {...register("id")} type="hidden" />
            <Form.Control
              {...register("company_name")}
              isInvalid={!!errors?.company_name?.message}
              placeholder={t("configs.company.company_name_enter")}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.company_name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="cnpj" xs="4">
            <Form.Label>{t("configs.company.cnpj")}</Form.Label>
            <Form.Control
              {...register("cnpj", {
                onChange: (e) => MaskService.toInputMask("cnpj", e),
              })}
              isInvalid={!!errors?.cnpj?.message}
              placeholder="00.000.000/0000-00"
            />
            <Form.Control.Feedback type="invalid">
              {errors?.cnpj?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} controlId="address" xs="8">
            <Form.Label>{t("configs.company.street")}</Form.Label>
            <Form.Control
              {...register("address")}
              isInvalid={!!errors?.address?.message}
              placeholder={t("configs.company.street_enter")}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.address?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} controlId="number" xs="2">
            <Form.Label>{t("configs.company.number")}</Form.Label>
            <Form.Control
              {...register("number")}
              isInvalid={!!errors?.number?.message}
              placeholder=""
            />
            <Form.Control.Feedback type="invalid">
              {errors?.number?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            className="mt-3"
            as={Col}
            controlId="address_complement"
            xs="4"
          >
            <Form.Label>{t("configs.company.complement")}</Form.Label>
            <Form.Control
              {...register("address_complement")}
              isInvalid={!!errors?.address_complement?.message}
              placeholder={t("configs.company.complement_enter")}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.address_complement?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} controlId="burgh" xs="4">
            <Form.Label>{t("configs.company.neighborhood")}</Form.Label>
            <Form.Control
              {...register("burgh")}
              isInvalid={!!errors?.burgh?.message}
              placeholder={t("configs.company.neighborhood_enter")}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.burgh?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mt-3 mb-5">
          <Form.Group as={Col} controlId="state" xs="2">
            <Form.Label>{t("configs.company.state")}</Form.Label>
            <Form.Select
              defaultValue=""
              {...register("state")}
              isInvalid={!!errors?.burgh?.message}
            >
              <option value="">{t("input.select_text")}</option>
              {estados_cidades?.estados?.map((estado, i) => (
                <option key={i}>{estado.sigla}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors?.state?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="city" xs="4">
            <Form.Label>{t("configs.company.city")}</Form.Label>
            <Form.Select
              defaultValue=""
              {...register("city")}
              isInvalid={!!errors?.city?.message}
            >
              <option value="">{t("input.select_text")}</option>
              {getCities(watch("state"))?.map((cidade, i) => (
                <option key={i}>{cidade}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors?.city?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="cep" xs="3">
            <Form.Label>{t("configs.company.zipcode")}</Form.Label>
            <Form.Control
              {...register("cep", {
                onChange: (e) => MaskService.toInputMask("cep", e),
              })}
              isInvalid={!!errors?.cep?.message}
              placeholder="00000-000"
            />
            <Form.Control.Feedback type="invalid">
              {errors?.cep?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3" as={Col} controlId="mail_billing" xs="6">
            <Form.Label>{t("configs.company.email")}</Form.Label>
            <Form.Control
              {...register("mail_billing")}
              isInvalid={!!errors?.mail_billing?.message}
              placeholder={t("configs.company.email_enter")}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.mail_billing?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="pt-5">
          <Footer>
            <div className="mb-3">
              {errorMessage && (
                <div className="text-danger mb-3">{errorMessage}</div>
              )}

              <Button disabled={!isDirty || !isValid} type="submit">
                {isSubmitting ? t("status.saving") : t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button
                className="ms-3"
                variant="secondary"
                onClick={handleCancel}
              >
                {t("configs.company.cancel")}
              </Button>
            </div>
          </Footer>
        </Row>
      </Form>
    </Container>
  );
};

export default ConfigsCompany;
