import { useTranslation } from "react-i18next";

export function useFleetMate() {
  const { t } = useTranslation();

  const timeOptionsLoRaWANIgnitionOn = [
    { label: "15 " + t("fleetmate.time.minutes"), value: "15" },
    { label: "30 " + t("fleetmate.time.minutes"), value: "30" },
    { label: "60 " + t("fleetmate.time.minutes"), value: "60" },
    { label: "120 " + t("fleetmate.time.minutes"), value: "120" },
  ];

  const timeOptionsLoRaWANIgnitionOff = [
    { label: "120 " + t("fleetmate.time.minutes"), value: "15" },
    { label: "240 " + t("fleetmate.time.minutes"), value: "30" },
    { label: "360 " + t("fleetmate.time.minutes"), value: "60" },
    { label: "720 " + t("fleetmate.time.minutes"), value: "120" },
  ];

  const timeOptionsIridiumIgnitionOn = [
    { label: "30 " + t("fleetmate.time.minutes"), value: "30" },
    { label: "60 " + t("fleetmate.time.minutes"), value: "60" },
    { label: "120 " + t("fleetmate.time.minutes"), value: "120" },
    { label: "240 " + t("fleetmate.time.minutes"), value: "240" },
  ];

  const timeOptionsIridiumIgnitionOff = [
    { label: "90 " + t("fleetmate.time.minutes"), value: "30" },
    { label: "120 " + t("fleetmate.time.minutes"), value: "60" },
    { label: "240 " + t("fleetmate.time.minutes"), value: "120" },
    { label: "480 " + t("fleetmate.time.minutes"), value: "240" },
  ];

  const entrieOptions = [
    { label: t("fleetmate.sensor.right_door"), value: "RIGHT_DOOR" },
    { label: t("fleetmate.sensor.left_door"), value: "LEFT_DOOR" },
    { label: t("fleetmate.entries.fifth_wheel"), value: "FIFTH_WHEEL" },
    { label: t("fleetmate.sensor.side_chest"), value: "SIDE_DOOR" },
    { label: t("fleetmate.sensor.panic"), value: "PANIC_BUTTON" },
    { label: t("fleetmate.entries.panel"), value: "PANEL" },
    { label: t("fleetmate.outings.ignition_lock"), value: "IGNITION" },
    { label: t("fleetmate.entries.silo"), value: "SILO" },
    { label: t("fleetmate.outings.flush_door_lock"), value: "FLUSH_DOOR_MAIN" },
  ];

  const outputOptions = [
    { label: t("fleetmate.entries.fuel_blockage"), value: "fuel_blockage" },
    { label: t("fleetmate.entries.trunk_lock"), value: "trunk_lock" },
    {
      label: t("fleetmate.entries.fifth_wheel_lock"),
      value: "fifth_wheel_lock",
    },
  ];

  const actuatorsOptions = [
    { 
      label: t("fleetmate.outings.fuel_block"), 
      value: "FUEL_BLOCK",
      form_key: "fuel_block"
    },
    { 
      label: t("fleetmate.entries.side_door"), 
      value: "SIDE_DOOR_LOCK",
      form_key: "side_door" 
    },
    {
      label: t("fleetmate.entries.flush_door_main"),
      value: "FLUSH_DOOR_LOCK",
      form_key: "flush_door_main"
    },
    { 
      label: t("fleetmate.outings.fifth_wheel_lock"), 
      value: "FIFTH_WHEEL_LOCK",
      form_key: "fifth_wheel_lock" },
    { 
      label: t("fleetmate.entries.siren"), 
      value: "SIREM_ALARM_LOCK",
      form_key: "siren" 
    },
    { label: t("fleetmate.outings.trailer_door_lock"), 
      value: "TRAILER_DOOR_LOCK",
      form_key: "trailer_door_lock" 
    },
    { 
      label: t("fleetmate.entries.ignition"), 
      value: "IGNITION_LOCK",
      form_key: "ignition" 
    },
  ];

  const sensorStatus = [
    { label: t("fleetmate.sensor.actived"), value: "E" },
    { label: t("fleetmate.sensor.disabled"), value: "D" },
  ];

  const eletronicFenceGroupOptions = [
    { label: t("fleetmate.efg.operation_base"), value: "operation_base" },
    { label: t("fleetmate.efg.client"), value: "client" },
    {
      label: t("fleetmate.efg.authorized_stopping_point"),
      value: "authorized_stopping_point",
    },
    {
      label: t("fleetmate.efg.authorized_route"),
      value: "authorized_route",
    },
    { label: t("fleetmate.efg.system_routes"), value: "system_routes" },
    {
      label: t("fleetmate.efg.jammer_interface_zone"),
      value: "jammer_interface_zone",
    },
    { label: t("fleetmate.efg.risk_zone"), value: "risk_zone" },
  ];

  const geoOfenceGroup = [
    { label: t("fleetmate.geofence_base"), value: "1" },
    { label: t("fleetmate.geofence_client"), value: "2" },
    { label: t("fleetmate.geofence_risk_area"), value: "3" },
    { label: t("fleetmate.geofence_autorized"), value: "4" },
  ]

  return {
    timeOptionsLoRaWANIgnitionOn,
    timeOptionsLoRaWANIgnitionOff,
    timeOptionsIridiumIgnitionOn,
    timeOptionsIridiumIgnitionOff,
    entrieOptions,
    outputOptions,
    actuatorsOptions,
    sensorStatus,
    eletronicFenceGroupOptions,
    geoOfenceGroup
  };
}
