import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { SpinnerContainer } from "./style";

export const FallbackSpinner = () => {
  return (
    <PrivateMasterPage>
      <SpinnerContainer>
        <div className="container-spinner d-flex align-items-center justify-content-center">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </SpinnerContainer>
    </PrivateMasterPage>
  );
};
