import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(213, 179, 61, 0.1);
  border-radius: 5px;
  width: fit-content;
  padding: 3px 13px;
`;

export const Link = styled.a`
  font-size: 16px;
  line-height: 19px;
  color: #ed9d25;
  display: flex;
  text-align: center;
  text-transform: none;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;
