import React from "react";
import RBPagination, { PaginationProps } from "react-bootstrap/Pagination";

export interface IPaginationProps extends PaginationProps {
  qtd: number;
  current_page: number;
  onSelectPage?(page: number): void;
}

const Pagination: React.FC<IPaginationProps> = ({
  qtd,
  current_page,
  onSelectPage,
  ...props
}) => {
  let items = [];
  for (let number = 1; number <= qtd; number++) {
    items.push(
      <RBPagination.Item
        key={number}
        active={number === current_page}
        onClick={() => onSelectPage?.(number)}
      >
        {number}
      </RBPagination.Item>
    );
  }

  return (
    <RBPagination className="justify-content-end" size="sm" {...props}>
      <RBPagination.Prev
        disabled={current_page === 1}
        onClick={() => onSelectPage?.(current_page - 1)}
      />
      {qtd === 0 && qtd}
      {items.slice(0, 5)}
      {items.length > 5 && (
        <>
          <RBPagination.Ellipsis disabled={true} />
          {items[items.length - 1]}
        </>
      )}

      <RBPagination.Next
        disabled={current_page === qtd}
        onClick={() => onSelectPage?.(current_page + 1)}
      />
    </RBPagination>
  );
};

export default Pagination;
