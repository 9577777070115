import { Control, UseFormRegister, useFieldArray } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomInputGroup, SectionTitle } from "./styles";
import { FleetMateConfigs } from "../../../core/interfaces/FleetMate";
import { useEffect, useState } from "react";
import { FleetMateService } from "../../../core/services/FleetMateService";
import { getUser } from "../../../core/redux/reducer/auth";
import { useAppSelector } from "../../../core/redux/hooks";

interface OutputsFieldArrayProps {
  control: Control<FleetMateConfigs>;
  register: UseFormRegister<FleetMateConfigs>;
}

export default function OutputsFieldArray({
  control,
  register,
}: OutputsFieldArrayProps) {
  const [portsOptions, setPortsOptions] = useState([]);
  const user = useAppSelector(getUser);
  const { t, i18n } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: "exitPorts",
    keyName: "fieldId",
  });

  async function getAllPorts() {
    const response = await FleetMateService.getPorts();

    const options: any = [];

    response.forEach((obj: any) => {
      if (obj.type === "exit") {
        options.push({
          value: obj.constant,
          label: t(`fleetmate.outings.${obj.constant.toLowerCase()}`),
        });
      }
    });

    setPortsOptions(options);
  }

  useEffect(() => {
    getAllPorts();
  }, [i18n.language, user?.company_id]);

  return (
    <Row className="mb-5">
      <Col className="mb-3">
        <Row>
          <Col className="my-3">
            <SectionTitle>
              {t("configs.fleetmate.setting.outputs")}
            </SectionTitle>
          </Col>
        </Row>

        {fields.length > 0 && portsOptions.length > 0 && (
          <Row>
            {fields?.map((item, index) => (
              <Form.Group
                key={`${index}_${item.fieldId}`}
                xl={4}
                md={12}
                as={Col}
                controlId={`exitPorts.${index}.sensor`}
                className="mb-2"
              >
                <input
                  hidden
                  value={index + 1}
                  {...register(`exitPorts.${index}.id`)}
                />

                <CustomInputGroup className="mb-2">
                  <Form.Label>{`${t("configs.fleetmate.setting.output")} ${
                    index + 1
                  }:`}</Form.Label>
                  <Form.Select {...register(`exitPorts.${index}.sensor`)}>
                    {portsOptions?.map((item: any, i) => (
                      <option key={i} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </CustomInputGroup>
                <CustomInputGroup className="mb-2">
                  <Form.Label>
                    {t("configs.fleetmate.setting.type")}:
                  </Form.Label>
                  <Form.Select {...register(`exitPorts.${index}.pulsed`)}>
                    <option value={"true"}>
                      {t("configs.fleetmate.setting.type_encoded")}
                    </option>
                    <option value={"false"}>
                      {t("configs.fleetmate.setting.type_decoded")}
                    </option>
                  </Form.Select>
                </CustomInputGroup>
                <Form.Check
                  id={`${index}_checked`}
                  className="mb-3"
                  label={t("configs.fleetmate.setting.driver_controls_exit")}
                  defaultChecked={false}
                  {...register(`exitPorts.${index}.driverCanControl`)}
                />
              </Form.Group>
            ))}
          </Row>
        )}
      </Col>
    </Row>
  );
}
