import { api } from ".";
import {
  APiQueryBase,
  APiQueryJSONBase,
  ApiResponseBase,
} from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { CreateVehicleTags, IVehicleTag } from "../interfaces/VehicleTag";
import { isErrorResult } from "../utils/api";

export class VehicleTagService {
  static async get({
    filter,
    page,
  }: APiQueryJSONBase<IVehicleTag>): Promise<
    ApiResponseBase<IVehicleTag[]> | ErrorResult
  > {
    return await api
      .get(`/api/v2/vehicle-tags`, {
        params: {
          page: page,
          filter: JSON.stringify(filter),
          per_page: 500,
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getVehicleTags({
    filter,
    page,
    per_page,
  }: APiQueryBase): Promise<ApiResponseBase<IVehicleTag[]> | ErrorResult> {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/vehicle-tags?page=${page}${filterQuery}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getAllVehicleTags(): Promise<IVehicleTag[]> {
    const first_page = await this.get({
      page: 1,
      per_page: 500,
    });

    if (isErrorResult(first_page)) return [];
    if (first_page.meta?.current_page === first_page.meta?.last_page)
      return first_page.data;

    const all_pages_result = await Promise.all(
      [...new Array(first_page.meta?.last_page)].map((_, i) =>
        this.get({ page: i + 1, per_page: 500 })
      )
    );

    let only_success_results: IVehicleTag[] = [];

    all_pages_result.forEach((results) => {
      if (!isErrorResult(results)) {
        // console.log("all_pages_result", results);
        only_success_results = only_success_results.concat(results.data);
      }
    });

    return only_success_results;
  }

  static async create(data: CreateVehicleTags): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/vehicle-tags`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async associate(data: {
    tags: {
      vehicle_tag_id: number;
      vehicle_id: number;
    }[];
  }): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/vehicle-tags/associate`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async disassociate(data: {
    tags: {
      vehicle_tag_id: number;
      vehicle_id: number;
    }[];
  }): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/vehicle-tags/disassociate`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async associateAndDisassociate(
    vehicleId: number,
    data: {
      currentTags: IVehicleTag[];
      tags: IVehicleTag[];
    }
  ): Promise<ErrorResult> {
    const promises = [];
    const newTags = data?.tags?.filter((item) => {
      return !data?.currentTags?.map((item) => item.id).includes(item.id);
    });

    const deletedTagIds = data?.currentTags?.filter((item) => {
      return !data?.tags?.map((item) => item.id).includes(item.id);
    });

    if (newTags?.length) {
      promises.push(
        this.associate({
          tags: newTags.map((tag) => ({
            vehicle_tag_id: tag.id,
            vehicle_id: vehicleId,
          })),
        })
      );
    }

    if (deletedTagIds?.length) {
      promises.push(
        this.disassociate({
          tags: deletedTagIds.map((tag) => ({
            vehicle_tag_id: tag.id,
            vehicle_id: vehicleId,
          })),
        })
      );
    }

    return await Promise.all(promises)
      .then((r) => r)
      .catch((e) => e);
  }
}
