import { Container as BSContainer } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(BSContainer)`
  .filter-col {
    max-width: 340px;
  }

  .text-alert {
    text-decoration: underline;
    color: #eb5757;
  }

  .sensor-detail-actions {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 40px;
      width: 40px;
    }

    button:hover {
      background: #fff;
      color: #6c757d;
    }
  }

  .temperature {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 18px;
    height: 226px;
    background-color: #2f80ed;
    color: #fff;

    .title {
      font-weight: bold;
    }

    .value {
      flex: 1;
      font-size: 4rem;
      font-weight: bolder;
    }
  }

  .chart {
    background-color: #f2f2f2;
  }

  th {
    text-transform: uppercase;
  }

  td:last-child {
    width: 50px;
  }
`;

export const LinkOnMap = styled.a`
  font-size: 0.9rem;
  background-color: rgba(213, 179, 61, 0.1);
  color: #ed9d25;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }
`;
