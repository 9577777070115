/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useTransition } from "react";
import { Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { ActionButtonOutline } from "../ActionButtonOutline";
import CustomSearchInput from "../CustomSearchInput";

export interface Items {
  id: string | number;
  label: string;
  checked: boolean;
  key?: string;
}

interface DropdownSearchAndCheckProps {
  items: Items[];
  search?: boolean;
  searchPlaceholder?: string;
  label?: string;
  onChange?(items: Items[]): void;
  onChangeSearch?(value: string): void;
  values?: Items[];
  type?: "select" | "button";
  component?: React.ReactElement;
  disabled?: boolean;
}

const CustomToggle = React.forwardRef<any, any>(
  ({ children, onClick, disabled }, ref) => (
    <Dropdown.Toggle
      disabled={disabled}
      ref={ref}
      style={{
        backgroundColor: "transparent",
        color: "#6C757D",
        borderColor: "#CED4DA",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={onClick}
    >
      {children}
    </Dropdown.Toggle>
  )
);

const CustomToggleButton = React.forwardRef<any, any>(
  ({ children, onClick, disabled }, ref) => (
    <Dropdown.Toggle
      disabled={disabled}
      ref={ref}
      bsPrefix="btn"
      as={ActionButtonOutline}
      onClick={onClick}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      {children}
    </Dropdown.Toggle>
  )
);

const CheckDropdownItem = React.forwardRef<any, any>(
  ({ children, id, checked, onChange, defaultChecked }, ref) => {
    return (
      <Form.Group ref={ref} className="p-1 px-3 mb-0" controlId={id}>
        <Form.Check
          id={id}
          type="checkbox"
          label={children}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={onChange}
          style={{ whiteSpace: "nowrap" }}
          className="p-1 px-3 mb-0"
        />
      </Form.Group>
    );
  }
);

const DropdownSearchAndCheck: React.FC<DropdownSearchAndCheckProps> = ({
  items = [],
  onChange,
  searchPlaceholder,
  label,
  search,
  onChangeSearch,
  values,
  type = "select",
  component,
  disabled,
}) => {
  const { t } = useTranslation();

  label = !!label ? label : t("input.select_text");
  searchPlaceholder = !!searchPlaceholder ? searchPlaceholder : t("search");

  const handleChecked = (i: number, value: boolean) => {
    items[i].checked = value;
    // items.forEach((element, index) => {
    //   if (index !== i) {
    //     element.checked = false;
    //   }
    // });
    onChange?.(items);
  };

  const getTotalSelecteds = () => {
    return values?.filter((item) => item?.checked)?.length;
  };

  React.useEffect(() => {
    if (values?.length) items = values;
  }, [values, items]);

  return (
    <Dropdown drop="down">
      {type === "select" && (
        <Dropdown.Toggle
          disabled={disabled}
          as={CustomToggle}
          id="dropdown-custom-components"
        >
          {!!getTotalSelecteds()
            ? t("selecteds") + " " + getTotalSelecteds()
            : label}
        </Dropdown.Toggle>
      )}

      {type === "button" && (
        <Dropdown.Toggle disabled={disabled} as={CustomToggleButton}>
          {component}
        </Dropdown.Toggle>
      )}

      <Dropdown.Menu className="w-100" style={{maxHeight:'350px',overflow:'auto'}}>
        {search && (
          <Form.Group className="mx-2 mb-2">
            <CustomSearchInput
              style={{ backgroundColor: "#F1F1F1" }}
              placeholder={searchPlaceholder}
              onChange={(e) => onChangeSearch?.(e.target.value)}
            />
          </Form.Group>
        )}

        {items?.map((item, i) => (
          <Dropdown.Item
            disabled={disabled}
            as={CheckDropdownItem}
            key={item.key ? item.key : item.id}
            id={item.key ? item.key : `${item.id}_${i}`}
            checked={item.checked}
            onChange={(e: any) => handleChecked(i, e.target.checked)}
          >
            <p
              className="text-wrap"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              {item.label}
            </p>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSearchAndCheck;
