import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  height: 70px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(49px);
  padding: 0 29px;
  z-index: 9;

  .title {
    font-size: 1.6rem;
    margin-bottom: 0px;
  }

  .breadcrumb {
    margin-bottom: 10px;
  }

  .breadcrumb-item {
    font-size: 0.8rem;
    color: #6c757d;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;
