import { api } from ".";
import { APiQueryBase } from "../interfaces/ApiResponseBase";
import { FleetMateConfigsUpdate } from "../interfaces/FleetMate";
import { Ports } from "../interfaces/Ports";

interface LinkConfigurationVehicleInterface {
  group_id: number;
  vehicles_id: number[];
}

export class FleetMateService {
  static async getConfigs(vehicleId: string | number | null = null) {
    const endpoint = vehicleId
      ? `/api/v2/fleetmate/configuration/${vehicleId}`
      : "/api/v2/fleetmate/configuration";
    return await api
      .get(endpoint)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  // static async getGroupConfigs(group_id: string | number | null = null) {
  static async getGroupConfigsById(id: string | number | null = null) {
    const endpoint = `/api/v2/fleetmate/configuration/group/${id}`;

    return await api
      .get(endpoint)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  static async getGroupConfigs({ id, filter, page, order }: APiQueryBase) {
    const endpoint = id
      ? `/api/v2/fleetmate/configuration/group/${id}`
      : "/api/v2/fleetmate/configuration/group";

    return await api
      .get(endpoint, {
        params: {
          page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getPorts(): Promise<Ports[]> {
    return await api
      .get<Ports[]>("/api/v2/fleetmate/ports/false")
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async updateGroup(id: string | number, data: FleetMateConfigsUpdate) {
    return await api
      .put(`/api/v2/fleetmate/configuration/group/${id}`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  static async storeGroup(data: FleetMateConfigsUpdate) {
    return await api
      .post(`/api/v2/fleetmate/configuration/group`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  static async update(data: FleetMateConfigsUpdate) {
    return await api
      .post(`/api/v2/fleetmate/configuration`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  static async linkConfigurationVehicle(
    data: LinkConfigurationVehicleInterface
  ) {
    return await api
      .post(`/api/v2/fleetmate/configuration/group/associate-vehicle`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async unlinkEquipment(vehicle_id: number) {
    return await api
      .delete(`/api/v2/fleetmate/${vehicle_id}/unlink`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
