import * as React from "react";
import { Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiInfoCircle } from "react-icons/bi";
import { RoutingRuleResolver } from "../../../core/schemaValidations/routing_rule";
import { Container, Footer, Label, Line } from "./styles";
import { ConfigRoutingService } from "../../../core/services/ConfigRoutingService";
import { isErrorResult } from "../../../core/utils/api";
import {
  ConfigRoutingRules,
  getConfigRouting,
} from "../../../core/interfaces/ConfigRouting";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../core/redux/hooks";
import { getUser } from "../../../core/redux/reducer/auth";

type FormValues = ConfigRoutingRules & {};

const RoutingRule: React.FC = () => {
  const [responseGet, setResponseGet] = React.useState<ConfigRoutingRules>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const user = useAppSelector(getUser);
  const loadRoutingRules = async () => {
    try {
      const response =
        await ConfigRoutingService.getRoutingRulesVehicleJourney();
      if (!isErrorResult(response) && response) {
        setResponseGet({
          allow_vehicle_charging:
            response.data[0].roterization_allow_vehicle_charging!,
          create_open_routes: response.data[0].roterization_create_open_routes!,
          allow_multiple_day_routes:
            response.data[0].roterization_allow_multiple_day_routes!,
          group_deliveries_at_stopping_point:
            response.data[0].roterization_group_deliveries_at_stopping_point!,
          allow_dynamic_routing:
            response.data[0].roterization_allow_dynamic_routing!,
          optimization_pattern:
            response.data[0].roterization_optimization_pattern,
          transit_condition: response.data[0].roterization_transit_condition,
          measurement_system: response.data[0].roterization_measurement_system,
        });
        setIsLoading(false);
      } else {
        throw new Error("Error loading routing rules.");
      }
    } catch (error: any) {
      alert(JSON.stringify(error?.message));
    }
  };

  React.useEffect(() => {
    loadRoutingRules();
  }, [user?.company_id]);

  const [defaultValues, setDefaultValues] = React.useState<ConfigRoutingRules>({
    allow_vehicle_charging: responseGet?.allow_vehicle_charging
      ? responseGet?.allow_vehicle_charging
      : 1,
    create_open_routes: responseGet?.create_open_routes
      ? responseGet?.create_open_routes
      : 1,
    allow_multiple_day_routes: responseGet?.allow_multiple_day_routes
      ? responseGet?.allow_multiple_day_routes
      : 0,
    group_deliveries_at_stopping_point:
      responseGet?.group_deliveries_at_stopping_point
        ? responseGet?.group_deliveries_at_stopping_point
        : 1,
    allow_dynamic_routing: responseGet?.allow_dynamic_routing
      ? responseGet?.allow_dynamic_routing
      : 1,
    optimization_pattern: responseGet?.optimization_pattern
      ? responseGet?.optimization_pattern
      : "km",
    transit_condition: responseGet?.transit_condition
      ? responseGet?.transit_condition
      : "slow",
    measurement_system: responseGet?.measurement_system
      ? responseGet?.measurement_system
      : "km",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<FormValues>({
    resolver: RoutingRuleResolver,
    defaultValues,
  });

  React.useEffect(() => {
    if (!isLoading) {
      reset(responseGet);
    }
  }, [isLoading]);

  const onSubmit: SubmitHandler<FormValues> = async () => {
    try {
      const response = await ConfigRoutingService.updateRoutingRules({
        //@ts-ignore
        roterization_allow_vehicle_interjourney: 0,
        allow_vehicle_charging: watch("allow_vehicle_charging") ? 1 : 0,
        create_open_routes: watch("create_open_routes") ? 1 : 0,
        allow_multiple_day_routes: watch("allow_multiple_day_routes") ? 1 : 0,
        group_deliveries_at_stopping_point: watch(
          "group_deliveries_at_stopping_point"
        )
          ? 1
          : 0,
        allow_dynamic_routing: watch("allow_dynamic_routing") ? 1 : 0,
        optimization_pattern: watch("optimization_pattern"),
        transit_condition: watch("transit_condition"),
        measurement_system: watch("measurement_system"),
      });
      console.log({
        allow_vehicle_charging: watch("allow_vehicle_charging") ? 1 : 0,
        create_open_routes: watch("create_open_routes") ? 1 : 0,
        allow_multiple_day_routes: watch("allow_multiple_day_routes") ? 1 : 0,
        group_deliveries_at_stopping_point: watch(
          "group_deliveries_at_stopping_point"
        )
          ? 1
          : 0,
        allow_dynamic_routing: watch("allow_dynamic_routing") ? 1 : 0,
        optimization_pattern: watch("optimization_pattern"),
        transit_condition: watch("transit_condition"),
        measurement_system: watch("measurement_system").toString(),
      });
      if (response?.message === "Rules successfully updated!") {
        setDefaultValues(defaultValues);
        dispatch(updateAlert({ title: t("message.success.save_data") }));
      } else {
        throw new Error("Error save routing rules.");
      }
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: error.message,
          type: "error",
        })
      );
    }
  };

  function handleCancel(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    reset(defaultValues, {
      keepDirty: false,
      keepIsValid: false,
      keepErrors: false,
    });
  }

  return (
    <Container className="p-0">
      <Form onSubmit={handleSubmit(onSubmit, console.warn)}>
        <Row>
          <Label>{t("configs.routing.rule_title")}</Label>
          <Col>
            <Form.Group controlId="allow_vehicle_charging">
              <Form.Check
                id="allow_vehicle_charging"
                type="switch"
                label={
                  <label htmlFor="allow_vehicle_charging">
                    {t("configs.routing.allow_vehicle_recharge")}{" "}
                    <BiInfoCircle className="text-muted" title="info" />
                  </label>
                }
                {...register("allow_vehicle_charging")}
              />
            </Form.Group>

            <Form.Group controlId="group_deliveries_at_stopping_point">
              <Form.Check
                id="group_deliveries_at_stopping_point"
                type="switch"
                label={
                  <label htmlFor="group_deliveries_at_stopping_point">
                    {t("configs.routing.grouping_deliveries_breakpoint")}{" "}
                    <BiInfoCircle className="text-muted" title="info" />
                  </label>
                }
                {...register("group_deliveries_at_stopping_point")}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="create_open_routes">
              <Form.Check
                id="create_open_routes"
                type="switch"
                label={
                  <label htmlFor="create_open_routes">
                    {t("configs.routing.create_open_routes")}{" "}
                    <BiInfoCircle className="text-muted" title="info" />
                  </label>
                }
                {...register("create_open_routes")}
              />
            </Form.Group>

            <Form.Group controlId="allow_dynamic_routing">
              <Form.Check
                id="allow_dynamic_routing"
                type="switch"
                label={
                  <label htmlFor="allow_dynamic_routing">
                    {t("configs.routing.allow_dynamic_routing")}{" "}
                    <BiInfoCircle className="text-muted" title="info" />
                  </label>
                }
                {...register("allow_dynamic_routing")}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="allow_multiple_day_routes">
              <Form.Check
                id="allow_multiple_day_routes"
                type="switch"
                label={
                  <label htmlFor="allow_multiple_day_routes">
                    {t("configs.routing.allow_multi_day_routes")}{" "}
                    <BiInfoCircle className="text-muted" title="info" />
                  </label>
                }
                {...register("allow_multiple_day_routes")}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3 mb-4">
          <Line />
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="optimization_standard">
              <Label>
                {t("configs.routing.optimization_standard")}{" "}
                <BiInfoCircle className="text-muted" title="info" />
              </Label>

              <Form.Check
                id={"OS1"}
                type="radio"
                value="km"
                label={t("configs.routing.optimize_mileage")}
                {...register("optimization_pattern")}
              />
              <Form.Check
                id={"OS2"}
                type="radio"
                value="service_time"
                label={t("configs.routing.optimize_service_time")}
                {...register("optimization_pattern")}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="transit_condition">
              <Label>
                {t("configs.routing.traffic_conditions")}{" "}
                <BiInfoCircle className="text-muted" title="info" />
              </Label>

              <Form.Check
                id={"TC1"}
                type="radio"
                label={t("configs.routing.slow")}
                value={"slow"}
                {...register("transit_condition")}
              />
              <Form.Check
                id={"TC2"}
                type="radio"
                label={t("configs.routing.normal")}
                value={"normal"}
                {...register("transit_condition")}
              />
              <Form.Check
                id={"TC3"}
                type="radio"
                label={t("configs.routing.fast")}
                value={"fast"}
                {...register("transit_condition")}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="measurement_system">
              <Label>
                {t("configs.routing.measurement_system")}{" "}
                <BiInfoCircle className="text-muted" title="info" />
              </Label>

              <Form.Check
                id={"MS1"}
                type="radio"
                label={t("configs.routing.metric_system")}
                value={"km"}
                {...register("measurement_system")}
              />
              <Form.Check
                id={"MS2"}
                type="radio"
                label={t("configs.routing.imperial_system")}
                value={"miles"}
                {...register("measurement_system")}
              />
            </Form.Group>
          </Col>
        </Row>

        <Footer>
          <Stack className="mb-3" direction="horizontal">
            <Button
              id="routing_rule_submit"
              type="submit"
              disabled={!isDirty || !isValid}
            >
              {isSubmitting ? t("status.saving") : t("button.save")}
              {isSubmitting && <Spinner animation="border" size="sm" />}
            </Button>
            <Button className="ms-3" variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Footer>
      </Form>
    </Container>
  );
};

export default RoutingRule;
