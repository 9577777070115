import { ReactComponent as IcLogoDark } from "../../assets/img/logo_dark.svg";
import { ReactComponent as CarrierWebLogo } from "../../assets/img/logo_carrierweb_login.svg";
import { useLocation } from "react-router-dom";
export const Logo = () => {
  const location = useLocation();
  const currentHost = window.location.host;

  const carrierLogo = () => (
    <div style={{ marginTop: "6rem" }}>
      <CarrierWebLogo style={{ width: "30rem" }} />
    </div>
  );
  const defaultLogo = () => {
    return (
      <div className="logo-container">
        <IcLogoDark />
      </div>
    );
  };

  const hostLogo = {
    "localhost:3000": carrierLogo(),
    "carrierweb.fleetdesk.io": carrierLogo(),
  };

  const renderLogo = () => {
    return hostLogo[currentHost] || defaultLogo();
  };

  return <>{renderLogo()}</>;
};
