import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function SystemRecord({ data, summary }: any) {
  const [summarized, setSummarized] = useState<any>();
  const { t } = useTranslation();

  function checkAction(action: string) {
    switch (action) {
      case "c": {
        return t("configs.audit.create");
      }
      case "u": {
        return t("configs.audit.update");
      }
      case "d": {
        return t("configs.audit.delete");
      }
    }
  }

  useEffect(() => {
    if (typeof data?.affected_registrys === "string") {
      const parsedData = JSON.parse(data?.affected_registrys);
      setSummarized(parsedData);
    }
  }, [summary]);

  const containsObject = data?.registry?.affected_registrys?.some(
    (item: any) => typeof item.data === "object" && item.data !== null
  );

  return (
    <>
      <div className="item-group p-0">
        <Row>
          <Col>
            <label>{t("configs.audit.event_date_hour")}</label>
            <p>
              {data?.registry?.generated_at
                ? moment(data?.registry?.generated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.action")}</label>
            <p>
              {data?.event?.action ? checkAction(data?.event?.action) : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.origin")}</label>
            <p>{data?.module?.name ? data?.module?.name : "--"}</p>
          </Col>

          <Col>
            <label>{t("configs.audit.user")}</label>
            <p>
              {data?.user?.name
                ? data?.user?.name + " " + data?.user?.last_name
                : data?.driver?.name
                ? data?.driver?.name + " " + data?.driver?.last_name
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      {data?.registry?.data?.text_message ? (
        <div className="item-group mb-3">
          <Row>
            <Col>
              <label>{t("configs.audit.message")}:</label>
              <p>{data?.registry?.data?.text_message}</p>
            </Col>
          </Row>
        </div>
      ) : null}

      {data?.affected_registrys.length > 0 && !containsObject && (
        <Row>
          <div className="table-container">
            <Table responsive borderless={false}>
              <thead>
                <tr>
                  <th>{t("configs.audit.changed_field")}</th>
                  <th>{t("configs.audit.initial_registration")}</th>
                  <th>{t("configs.audit.final_registration")}</th>
                </tr>
              </thead>
              {data?.affected_registrys && (
                <tbody>
                  {data?.affected_registrys?.map((item: any, i: number) => (
                    <tr key={i}>
                      <td>{item.field}</td>
                      <td>
                        {typeof item.data === "object"
                          ? JSON.stringify(item.data)
                          : item.data}
                      </td>
                      <td>
                        {typeof item.affected === "object"
                          ? JSON.stringify(item.affected)
                          : item.affected}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {summarized && !containsObject && (
                <tbody>
                  {summarized.map((item: any, i: number) => (
                    <tr key={i}>
                      <td>{item.field}</td>
                      <td>
                        {typeof item.data === "object"
                          ? JSON.stringify(item.data)
                          : item.data}
                      </td>
                      <td>
                        {typeof item.affected === "object"
                          ? JSON.stringify(item.affected)
                          : item.affected}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        </Row>
      )}
    </>
  );
}
