import styled from "styled-components";

export const Container = styled.div`
  background-color: #f2f2f2;
  border-radius: 23px;
  width: fit-content;
  max-width: 100%;
  min-width: 70%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Status = styled.div`
  min-height: 9px;
  max-height: 9px;
  min-width: 9px;
  max-width: 9px;
  border-radius: 9px;
  background-color: #939698;
  margin-right: 8px;
`;

export const Text = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #939698;
  text-align: center;
  width: 100%;
`;
