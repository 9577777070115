import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { languages } from "../../config/i18n";

import { Container } from "./styles";
import { LanguageService } from "../../core/services/LanguageService";
import { useLocation } from "react-router-dom";

const LanguageDropDown: React.FC = () => {
  const [selected, setSelected] = useState("");
  const location = useLocation();
  const { i18n } = useTranslation();
  const language = languages.find((l) => l.name === i18n.language);

  function handleSelectLanguage(item: {
    id: number;
    name: string;
    label: string;
    acronym: string;
  }) {
    LanguageService.setLanguageUser(item.acronym);
    localStorage.setItem("LANGUAGE_ID", item.id.toString());
    i18n.changeLanguage(item.name);
    checkRoutesAndReloadPage();
  }

  useEffect(() => {
    const language_id = localStorage.getItem("LANGUAGE_ID");
    if (language_id) {
      const language = languages.find((l) => l.id === parseInt(language_id));
      i18n.changeLanguage(language?.name);
    }
  }, []);

  //validate if we are on /route or fences/new and reload the page to reflect on map
  function checkRoutesAndReloadPage() {
    if (location.pathname === "/trips" || location.pathname === "/fences/new") {
      window.location.reload();
    }
  }

  return (
    <Container>
      <Dropdown>
        <Dropdown.Toggle variant="link" id="dropdown-basic">
          {language?.label}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {languages.map((item) => (
            <Dropdown.Item
              key={`lang_item_${item.label}`}
              onClick={() => handleSelectLanguage(item)}
            >
              {item.label}
            </Dropdown.Item>
          ))}
          {/* <Dropdown.Item href="#/action-1">PT-BR</Dropdown.Item>
          <Dropdown.Item href="#/action-2">EN</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default LanguageDropDown;
