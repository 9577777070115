import { HTMLAttributes, JSXElementConstructor, ReactElement } from "react";
import { Container, Text } from "./styled";

interface EmptyListMessageProps extends HTMLAttributes<HTMLDivElement> {
  children?: string | string[] | ReactElement | ReactElement[];
}

export default function EmptyListMessage({
  children,
  ...props
}: EmptyListMessageProps) {
  return (
    <Container {...props}>
      <Text>{children}</Text>
    </Container>
  );
}
