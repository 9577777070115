import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { ProtectedComponent } from "../ProtectedComponent";
import { module_names } from "../../constants/module_names";

const ConfigsMenu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const menus = [
    {
      name: t("configs.menu.company"),
      path: "/configs/company",
      module_name: module_names.CONFIG_COMPANY,
    },
    {
      name: t("configs.menu.billing"),
      path: "/configs/billing",
      module_name: module_names.CONFIG_BILLING,
    },
    {
      name: t("configs.menu.exit_locations"),
      path: "/configs/exit-locations",
      module_name: module_names.CONFIG_EXIT_LOCATION,
    },
    {
      name: t("configs.menu.routing"),
      path: "/configs/routing",
      module_name: module_names.CONFIG_ROUTING,
    },
    {
      name: t("configs.menu.users"),
      path: "/configs/users",
      module_name: module_names.CONFIG_USER,
    },
    {
      name: t("configs.menu.subcontractors"),
      path: "/configs/sub-contractors",
    },
    { name: t("configs.menu.contractors"), path: "/configs/contractors" },
    // { name: t("configs.menu.sms"), path: "/configs/sms" },
    { name: t("configs.menu.app"), path: "/configs/app", module_name: "" },
    {
      name: t("configs.menu.mate"),
      path: "/configs/mate",
      module_name: module_names.FLEETMATE,
    },
    {
      name: t("configs.menu.api"),
      path: "/configs/api",
      module_name: module_names.CONFIG_API,
    },
    {
      name: t("configs.menu.audit"),
      path: "/configs/audit",
      module_name: module_names.CONFIG_AUDIT,
    },
  ];

  // console.log("configs menu", location);

  const handlePathnameOfSubroutes: () => string = () => {
    if (pathname.split("/").length <= 3) return pathname;

    return pathname
      .split("/") // Transforma a string em um array, usando delimitador "/".
      .filter((v, index) => index !== 3) // Filtra e retorna os 3 três primeiros items.
      .join("/"); // Transforma o array em string, adicionando o delimitador "/".
  };

  return (
    <Container>
      {menus.map((item) => (
        <ProtectedComponent
          key={`menu_item_${item.name}`}
          module_name={item.module_name}
          property="view"
        >
          <div
            className={`menu-item ${
              item.path === handlePathnameOfSubroutes() ? "selected" : ""
            }`}
            onClick={() => navigate(item.path)}
          >
            {item.name}
          </div>
        </ProtectedComponent>
      ))}
    </Container>
  );
};

export default ConfigsMenu;
