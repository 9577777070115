import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { IBilling } from "../interfaces/Billing";

import { ErrorResult } from "../interfaces/ErrorResult";

export interface GetContractor {
  filter?: Object;
  page?: number;
}

interface Associante {
  vehicle_ids: number[];
  vehicle_tag_ids: number[];
}

export class BillingService {
  static async get({
    filter,
    page,
    order,
  }: APiQueryBase): Promise<ApiResponseBase<IBilling[]> | ErrorResult> {
    return api
      .get(`/api/v2/billing/invoices`, {
        params: {
          page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
