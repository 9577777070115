export function convertToYMDHMS(date: Date) {
  if (isNaN(date.getTime())) {
    return "";
  } else {
    console.log(date, "aa");
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:00`;
  }
}

export function limitText(text: string, count: number) {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}

export function numberArraysEqual(a:number[], b:number[]) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  let _a = [...a].sort()
  let _b = [...b].sort()

  for (var i = 0; i < _a.length; ++i) {
    if (_a[i] !== _b[i]) return false;
  }
  return true;
}

export function anyArraysEqual(a:any[], b:any[]) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}