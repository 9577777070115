import { api } from ".";

import { ErrorResult } from "../interfaces/ErrorResult";
import { HosRulesCompany } from "../interfaces/HosRulesCompany";

export class HosRulesCompanyService {
  static async get(): Promise<HosRulesCompany | ErrorResult> {
    return api
      .get(`/api/v2/api-hos/list-all-rules`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
