import styled, { css } from "styled-components";

type WeekdayButtonProps = {
  selected?: boolean;
};

export const Container = styled.div`
  display: flex;
  gap: 1rem !important;
`;

export const WeekdayButton = styled.button<WeekdayButtonProps>`
  height: 36px;
  width: 36px;
  border-radius: 5px;

  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;

  ${(props) =>
    props.selected
      ? css`
          background-color: #2f80ed;
          border: 0;
          color: white;

          &:hover {
            background-color: #1f5cad;
            transition: background-color 0.2s ease-in;
          }
        `
      : css`
          background-color: transparent;
          border: 1px solid #93969850;
          color: #6c757d;

          &:hover {
            background-color: #f5f5f5;
            transition: background-color 0.2s ease-in;
          }
        `};
`;
