import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomThead from "../../CustomThead";
import moment from "moment";
import { useEffect, useState } from "react";

export default function DriverAppSettings({ data, setupType, summary }: any) {
  const { t } = useTranslation();
  const [summarized, setSummarized] = useState([]);

  function checkAction(action: string) {
    switch (action) {
      case "c": {
        return t("configs.audit.create");
      }
      case "u": {
        return t("configs.audit.update");
      }
      case "d": {
        return t("configs.audit.delete");
      }
    }
  }

  function registerOrigin() {
    switch (setupType) {
      case 1: {
        return t("configs.audit.create_geofence");
      }
      case 2: {
        return t("configs.audit.run_command");
      }
      case 3: {
        return t("configs.audit.setup_configurations");
      }
      case 4: {
        return t("configs.audit.remove_geofences");
      }
      case 5: {
        return t("configs.audit.create_rule");
      }
      case 6: {
        return t("configs.audit.remove_rule");
      }
      case 7: {
        return t("configs.audit.fleetmate_sensors");
      }
      case 8: {
        return t("configs.audit.remove_registers");
      }
      default: {
        return "--";
      }
    }
  }

  useEffect(() => {
    if (typeof data?.registry?.data === "string") {
      const parsedData = JSON.parse(data?.registry?.data);
      setSummarized(parsedData);
    }
  }, [summary]);

  return (
    <>
      <div
        style={{
          marginBottom: "40px",
        }}
      >
        <h2
          style={{
            color: "#6C757D",
            fontWeight: 500,
          }}
        >
          {t("configs.audit.driver_app_settings")}
        </h2>
      </div>

      <div className="item-group p-0">
        <Row>
          <Col>
            <label>{t("configs.audit.event_date_hour")}</label>
            <p>
              {data?.registry?.generated_at
                ? moment(data?.registry?.generated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.user")}</label>
            <p>
              {data?.user?.name
                ? data?.user?.name + " " + data?.user?.last_name
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group mb-3">
        <Row>
          <Col>
            <label>{t("configs.audit.settings_origin")}</label>
            <p>{registerOrigin()}</p>
          </Col>

          <Col>
            <label>{t("configs.audit.action")}</label>
            <p>
              {data?.event?.action ? checkAction(data?.event?.action) : "--"}
            </p>
          </Col>
        </Row>
      </div>

      {data?.affected_registrys.length > 0 && (
        <Row>
          <div className="table-container">
            <Table responsive borderless={false}>
              <thead>
                <tr>
                  <th>{t("configs.audit.changed_field")}</th>
                  <th>{t("configs.audit.initial_registration")}</th>
                  <th>{t("configs.audit.final_registration")}</th>
                </tr>
              </thead>
              {data?.affected_registrys && (
                <tbody>
                  {data?.affected_registrys?.map((item: any, i: number) => (
                    <tr key={i}>
                      <td>{item.field}</td>
                      <td>
                        {item.field === "readed_at" ||
                        item.field === "updated_at"
                          ? moment(item.data).format("DD/MM/YYYY - HH:mm:ss")
                          : item.data}
                      </td>
                      <td>
                        {item.field === "readed_at" ||
                        item.field === "updated_at"
                          ? moment(item.affected).format(
                              "DD/MM/YYYY - HH:mm:ss"
                            )
                          : item.affected}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {summarized && (
                <tbody>
                  {summarized.map((item: any, i: number) => (
                    <tr key={i}>
                      <td>{item.field}</td>
                      <td>
                        {item.field === "readed_at" ||
                        item.field === "updated_at"
                          ? moment(item.data).format("DD/MM/YYYY - HH:mm:ss")
                          : item.data}
                      </td>
                      <td>
                        {item.field === "readed_at" ||
                        item.field === "updated_at"
                          ? moment(item.affected).format(
                              "DD/MM/YYYY - HH:mm:ss"
                            )
                          : item.affected}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        </Row>
      )}
    </>
  );
}
