import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  max-height: max-content;

  .header {
    h4 {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: #6c757d;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6c757d;
    }
  }

  .card {
    width: 100%;
    min-height: 100%;
    max-height: max-content;
  }

  .table {
    tbody tr:nth-child(1) td {
      padding-top: 16px;
    }

    td:last-child {
      width: 50px;
      &:hover {
        cursor: pointer;
      }
    }

    td {
      color: #6c757d;
    }
  }
`;
