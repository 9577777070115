import styled from "styled-components";

export const ActionToolButton = styled.button.attrs({ type: "button" })`
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px;
  border-radius: 16px;
  color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-width: 0;
  padding: 0;
  background-color: transparent;
  transition: background-color 0.5s ease-out;

  &:hover {
    background-color: #f5f5f5;
    transition: background-color 0.2s ease-in;
  }
`;
