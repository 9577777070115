import React from "react";
import { Button, Form, Modal, Spinner, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { updateAlert } from "../../../core/redux/reducer/alert";
import { useDispatch } from "react-redux";
import { ModalStyles } from "./styles";
import { Link } from "react-router-dom";

interface EditAccessProfileModalProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}

export type FormValues = {
  quantity: number;
};

const ManageLicenseModal: React.FC<EditAccessProfileModalProps> = ({
  visible = false,
  onClose,
  onSuccess,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      quantity: 0,
    },
  });

  async function onSubmit({ quantity }: FormValues) {
    try {
      console.log(quantity);
      // reset();
      onSuccess?.();
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: "Erro ao salvar os dados!",
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleClose = () => {
    // reset();
    onClose?.();
  };

  function increaseQuantity() {
    setValue("quantity", Number(getValues("quantity")) + 1);
  }

  function decreaseQuantity() {
    if (getValues("quantity") === 0) return;
    setValue("quantity", Number(getValues("quantity")) - 1);
  }

  return (
    <Modal
      {...props}
      show={visible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header className="border-0 p-4 pb-0 pt-3" closeButton>
        <Modal.Title>{t("configs.menu.manage_license")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pb-3 pt-3">
        <ModalStyles>
          <p className="modal_subtitle">
            Escolha o número de licenças necessárias po mês
          </p>

          <Form noValidate onSubmit={handleSubmit(onSubmit, console.warn)}>
            <Stack
              direction="horizontal"
              gap={2}
              className="justify-content-between"
            >
              <div>
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <button
                    className="change_qty_button"
                    onClick={decreaseQuantity}
                  >
                    <svg
                      width="14"
                      height="2"
                      viewBox="0 0 14 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 1.99805H0V-0.00195312H14V1.99805Z"
                        fill="#0062FF"
                      />
                    </svg>
                  </button>

                  <div className="quantity">{getValues("quantity")}</div>

                  <button
                    className="change_qty_button"
                    onClick={increaseQuantity}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 7.99805H8V13.998H6V7.99805H0V5.99805H6V-0.00195312H8V5.99805H14V7.99805Z"
                        fill="#0062FF"
                      />
                    </svg>
                  </button>
                </Stack>

                <p className="modal_subtitle mt-2">
                  2 Licenças em Uso, 10 Disponíveis
                </p>

                <Link to="#" className="link">
                  Gerenciar Licenças dos Motoristas
                </Link>
              </div>

              <div>
                <strong className="quantity_value">R$ 358,80</strong>
                <p className="modal_subtitle text-right my-2">por mês</p>
                <p className="modal_subtitle m-0">(R$ 29,90 por Licença)</p>
              </div>
            </Stack>

            <Stack
              direction="horizontal"
              gap={2}
              style={{ justifyContent: "end" }}
              className="mt-5"
            >
              <Button
                id="on-submit-access-profile"
                variant="primary"
                type="submit"
              >
                {t("configs.menu.go_to_checkout")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>

              <Button variant="secondary" onClick={handleClose}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Form>
        </ModalStyles>
      </Modal.Body>
    </Modal>
  );
};

export default ManageLicenseModal;
