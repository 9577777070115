import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

interface VehicleTypeButtonProps {
  isSelected?: boolean;
}

export const Modal = styled(RBModal)`
  .modal-header {
    border: 0;
    padding: 1.5em;
    margin-bottom: -3em;
    z-index: 5;
  }

  .attribute-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: fit-content;

    label {
      margin-bottom: 8px;
    }
  }
`;

export const VehicleTypeButton = styled.button<VehicleTypeButtonProps>`
  width: 73.61px;
  height: 31.75px;
  background: ${(props) => (props.isSelected ? "#007bff" : "#6C757D")};
  border-radius: 4.36409px;
  border: none;
  fill: white;
  color: white;
`;
