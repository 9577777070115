import { IProps } from "./IProps";
import { useAppSelector } from "../../core/redux/hooks";
import { getProfiles } from "../../core/redux/reducer/user";
import { getUser } from "../../core/redux/reducer/auth";
import { useNavigate, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const ProtectedComponent = ({
  children,
  module_name,
  property,
  redirect_url = null,
  fallbackComponent,
}: IProps) => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const navigate = useNavigate();
  const profiles = useAppSelector(getProfiles);
  const user = useAppSelector(getUser);
  const user_profile = profiles.find(
    (profile) => profile.id === user?.user_profile_id
  );

  const canViewComponent = Boolean(module_name)
    ? Boolean(
        user_profile?.user_access_modules?.find(
          (module) => module.module_name === module_name
        )?.pivot![property]
      )
    : true;

  useEffect(() => {
    if (Boolean(user_profile?.id)) {
      setIsPageLoading(false);
    }
  }, [canViewComponent]);

  if (!canViewComponent) {
    if (fallbackComponent && !isPageLoading) return fallbackComponent;
    return null;
  }

  return <>{children}</>;
};
