import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { Alert } from "../../interfaces/Alert";
import type { RootState } from "../store";

// Define a type for the slice state
interface AlertState {
  title?: string;
  message?: string;
  type?: "success" | "error" | "";
  onClick?(): void;
}

// Define the initial state using that type
const initialState: AlertState = {
  title: "",
  message: "",
  type: "",
};

interface ReducerActions extends SliceCaseReducers<AlertState> {
  updateAlert(state: AlertState, action: PayloadAction<Alert>): void;
  hideAlert(state: AlertState): void;
}

export const alertSlice: Slice<AlertState, ReducerActions> = createSlice({
  name: "alert",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateAlert: (state, action: PayloadAction<Alert>) => {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.type = action.payload?.type || "success";
      state.onClick = action.payload?.onClick;
    },
    hideAlert: (state) => {
      state.title = "";
      state.message = "";
      state.type = "";
      state.onClick = undefined;
    },
  },
});

export const { updateAlert, hideAlert } = alertSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getAlert = (state: RootState) => state.alert;

export default alertSlice.reducer;
