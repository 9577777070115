import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";
import { IoTrashOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomThead from "../../components/CustomThead";
import Pagination from "../../components/Pagination";
import { updateAlert } from "../../core/redux/reducer/alert";

import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { Container } from "./styles";
import { DriverService } from "../../core/services/DriverService";
import { Driver, DriverQuery } from "../../core/interfaces/Driver";
import { DriverAssociateResolver } from "../../core/schemaValidations/driver_add";

type FormUsers = Driver & {
  isSelected?: boolean;
};

type FormValues = {
  users: FormUsers[];
};

type DriverFilter = {
  filter?: { [key: string]: any };
  order?: { [key: string]: string };
  page: number;
  pages: number;
};

const DriversForm: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = React.useState<DriverFilter>({
    page: 1,
    pages: 0,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    formState: { isSubmitting, isValid },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: DriverAssociateResolver,
  });
  const { fields, remove, replace } = useFieldArray({
    control,
    name: "users",
    keyName: "fieldId",
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ users }) => {
    const driverIds = users.filter((user) => !!user?.isSelected);

    try {
      const response = await DriverService.switchAssociate({
        drivers_id: driverIds.map((user) => user.id),
        associate_disassociate: 1,
      });
      if (response?.success === 200) {
        const title = `${driverIds?.length} ${t("drivers.success_title")}`;
        dispatch(updateAlert({ title }));
      }
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("drivers.add.message_error"),
          message: error.response.data?.message,
          type: "error",
        })
      );
    } finally {
      navigate(-1);
      reset();
    }
  };

  const findDriversByKeys = async (keys: string, params: DriverQuery) => {
    if (isLoading) return;
    setLoading(true);

    try {
      const response = await DriverService.getDriversByKey(keys, params);
      if (response?.data && response?.meta) {
        replace(response.data);
        setFilters((prev) => ({
          ...prev,
          page: response.meta.current_page,
          pages: response.meta.last_page,
        }));
      }
    } catch (err: any) {
      alert("Error in loading drivers by keys: " + keys);
    } finally {
      setLoading(false);
    }
  };

  function getSelectedAll(): boolean {
    const data = watch("users");
    let count = 0;

    data?.forEach((item) => {
      if (!!item?.isSelected) count++;
    });

    return data?.length === count && data?.length >= 1;
  }

  function handleSelectAll() {
    const data = watch("users");
    const value = !getSelectedAll();

    const newDataList: any[] = data?.map((item) => {
      item.isSelected = value;
      return item;
    });

    replace(newDataList);
  }

  React.useEffect(() => {
    const { users } = location.state as { users: string };
    if (!!users) {
      findDriversByKeys(users, filters);
    }
  }, [location, filters.filter, filters.order, filters.page]);

  return (
    <PrivateMasterPage
      title={t("drivers.title")}
      breadcrumb={[t("drivers.title")]}
    >
      <Container>
        <Card className="m-3">
          <Card.Body className="p-4">
            <Form>
              <Row>
                <Col className="header mb-3">
                  <h4 className="mb-0">
                    {watch("users")?.length} {t("drivers.add_register_found")}
                  </h4>
                  <p>{t("drivers.add_driver_text_confirm")}</p>
                </Col>
              </Row>

              <Table responsive>
                <CustomThead
                  checkbox
                  selectedAll={getSelectedAll()}
                  onChangeSelectAll={handleSelectAll}
                  data={[
                    { column: "name", name: t("drivers.table_name") },
                    { column: "cpf", name: t("drivers.table_document") },
                    { column: "email", name: t("drivers.table_email") },
                    { column: "phone", name: t("drivers.table_phone") },
                    { column: "menu", name: <th />, menu: true },
                  ]}
                  onChangeOrder={(order) => {
                    setFilters((prev) => ({ ...prev, order }));
                  }}
                />
                <tbody>
                  {fields?.map((field, i) => (
                    <tr key={field.fieldId}>
                      <td>
                        <FormCheck
                          id={"selected_" + field.fieldId}
                          className="d-flex gap-3"
                          label={field.name}
                          {...register(`users.${i}.isSelected`)}
                        />
                      </td>
                      <td>{field.cpf}</td>
                      <td>{field.email}</td>
                      <td>{field.phone}</td>
                      <td>
                        <IoTrashOutline
                          size={20}
                          className="me-2"
                          onClick={() => remove(i)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form>

            <Row>
              <Col className="d-flex justify-content-center">
                {isLoading && <Spinner animation="border" />}
              </Col>
            </Row>
          </Card.Body>

          <div className="d-flex justify-content-between p-4">
            <div className="d-flex gap-3">
              <Button
                disabled={!isValid}
                variant="primary"
                onClick={handleSubmit(onSubmit, console.log)}
              >
                {isSubmitting ? t("status.saving") : t("drivers.add_button")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                {t("drivers.cancel_button")}
              </Button>
            </div>

            <Pagination
              className="mb-0"
              current_page={filters?.page}
              qtd={filters.pages}
              onSelectPage={(page) => {
                setFilters((prev) => ({ ...prev, page }));
              }}
            />
          </div>
        </Card>
      </Container>
    </PrivateMasterPage>
  );
};

export default DriversForm;
