import { api } from ".";
import { ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ISubcontractor } from "../interfaces/Subcontractor";
import { ErrorResult } from "../interfaces/ErrorResult";

export interface GetSubcontractor {
  filter?: string;
  page?: number;
}

export class SubcontractorService {
  static async searchCompany(params: { cnpj: string }) {
    return api
      .get(`/api/v2/subcontractor/search-company/${params.cnpj}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async get({
    filter,
    page,
  }: GetSubcontractor): Promise<
    ApiResponseBase<ISubcontractor[]> | ErrorResult
  > {
    const params = filter
      ? `&conditions=subcontractor_name:like:%${filter}%;email:like:%${filter}%`
      : "";

    return api
      .get(`/api/v2/subcontractor/hired?page=${page}${params}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async store(company_hired_id: number): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor/store`, { company_hired_id })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(email: string): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor`, { email })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async setVisible(
    subcontractorId: number,
    visible: number
  ): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor/set-visible`, {
        subcontractor_id: subcontractorId,
        visible,
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(contractorId: number): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/subcontractor`, {
        data: { contractor_id: contractorId },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async disapprove(contract_id: number): Promise<any | ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor/disapprove`, { contract_id })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async approve(contract_id: number): Promise<any | ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor/approve`, { contract_id })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
