import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { IUserProfile } from "../interfaces/UserProfile";

export class UserProfileService {
  static async getProfiles({
    filter,
    page,
  }: APiQueryBase): Promise<ApiResponseBase<IUserProfile> | ErrorResult> {
    const params = filter ? `&conditions=name:like:%${filter}%` : "";

    return api
      .get(`/api/v2/user-profile?page=${page}${params}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getAllProfiles(): Promise<
    ApiResponseBase<IUserProfile> | ErrorResult
  > {
    return api
      .get(`/api/v2/user-profile`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async associate(data: {
    user_profile_id: number;
    access_module_id: number;
  }): Promise<ErrorResult> {
    return api
      .put(`/api/v2/user-profile/associate`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async desassociate(data: {
    user_profile_id: number;
    access_module_id: number;
  }): Promise<ErrorResult> {
    return api
      .put(`/api/v2/user-profile/disassociate`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async updateAccessModule(data: {
    user_profile_id: number;
    user_access_module_id: number;
    view: number;
    edit: number;
    commands: number;
    delete: number;
  }): Promise<ErrorResult> {
    return api
      .put(`/api/v2/user-access-module`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
