import styled from "styled-components";

export const Container = styled.div`
  h6 {
    color: #000;
  }

  .unlink-button {
    color: #939698;
    padding: 0;
    border: 0;
    margin: 0;
    background: transparent;
    height: 0;
  }

  .network-status {
    flex: auto;
    margin-top: 10px;
    margin-right: 40px;
    color: #6c757d;

    .status {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* max-width: 150.09px; */
      height: 31.27px;
      margin-left: 10px;
      background: #f2f2f2;
      border-radius: 28.7675px;
      padding: 0 12px;
      overflow: hidden;
      gap: 16px;
    }

    .status-text {
      flex: 1;
      text-align: center;
      font-size: 1rem;
      line-height: 24px;
      font-weight: 400;
      word-break: break-word;
      text-transform: capitalize;
    }

    .status-color {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background-color: red;
    }

    .date {
      font-size: 0.8rem;
      text-align: center;
      color: #6c757d;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .cam {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 5px;
      border: 1px solid #ced4da;
      margin-bottom: 5px;
    }

    .title {
      font-size: 0.8rem;
    }
  }

  .actuators {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 10px 15px;
    min-height: 101px;
    /* margin-top: 28px; */

    .title {
      font-weight: 600;
    }

    .confirmation {
      font-size: 0.9rem;
      margin-top: 15px;
      justify-content: space-between;

      .status {
        margin: 0 7px;
      }
    }
  }

  .password {
    padding: 24px 12px;
    background: #f9f9f9;
    border-radius: 5px;

    & > div {
      flex: 1;
    }

    button {
      width: 115px;
    }

    .preview {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
      height: 56px;
      background-color: #fff;
      padding-left: 8px;
      padding-right: 8px;
    }

    .copy {
      font-size: 1rem;
      font-weight: 400;
      text-decoration: underline;

      display: flex;
      gap: 1rem;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }

    .copied {
      font-size: 1rem;
      font-weight: 400;
      text-decoration: underline;

      display: flex;
      gap: 1rem;
      align-items: center;
      background-color: #f2f2f2;
      border-radius: 4px;
      padding: 6px 12px 6px 14px;
    }

    .helper {
      font-size: 0.8rem;
      margin-top: 8px;
    }
  }

  .sensor {
    height: 36px;
    margin-right: 30px;
    margin-bottom: 0.6rem;

    &:hover {
      border-radius: 6px;
      background: #f2f2f2;
      padding: 0 11px;
      margin-left: -11px;
      margin-right: 19px;
      cursor: default;
    }

    &:active {
      background: #d3d3d3;
    }

    .title {
      flex: 1;
    }

    .status {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      gap: 16px;

      min-height: 25px;
      margin-left: 10px;
      margin-right: 4px;
      background: #f2f2f2;
      border-radius: 28.7675px;
      padding: 0 10px;
      overflow: hidden;
    }

    .status-text {
      flex: 1;
      text-align: center;
      font-size: 0.8rem;
      line-height: 24px;
      word-break: break-word;
    }

    .status-color {
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: red;
    }
  }
`;

export const CustomInfos = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;

  > div,
  input {
    min-width: 300px;
  }

  .input-label {
    font-size: 18px;
    color: #6c757d;
    font-weight: 600;
  }

  .input-info {
    color: #6c757d;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const EditButton = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2f80ed;

  border: none;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;
