import styled from "styled-components";

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;

  input {
    margin-left: 5px;
    border: none;
    width: 100%;
    outline: none;
    background-color: transparent;
  }
`;
export const InputGroupText = styled.div``;
