import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import user_placeholder from "../../assets/img/user_placeholder.png";
import { Container } from "./styles";
import { ActionButtonOutline } from "../../components/ActionButtonOutline";
import { FaChevronLeft } from "react-icons/fa";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { useNavigate } from "react-router-dom";
import LinkGoBack from "../../components/LinkGoBack";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ReactComponent as IcFile } from "../../assets/icons/mdi_file-export-outline.svg";
import { ReactComponent as BxCar } from "../../assets/icons/bx_bx-car.svg";
import { ReactComponent as IcMap } from "../../assets/icons/clarity_map-solid.svg";
import { ReactComponent as IcSpeed } from "../../assets/icons/ic_outline-speed.svg";
import { ReactComponent as IcDrivingTime } from "../../assets/icons/ic-driving-time.svg";
import { ReactComponent as IcBrakeAbs } from "../../assets/icons/mdi_car-brake-abs.svg";
import { ReactComponent as IcCarControl } from "../../assets/icons/mdi_car-traction-control.svg";
import { ReactComponent as IcPhone } from "../../assets/icons/zmdi_smartphone-erase.svg";
import { BsStarFill } from "react-icons/bs";

const DrivingBehaviorDetails: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PrivateMasterPage
      title={t("Comportamento de Direção")}
      breadcrumb={[t("Comportamento de Direção")]}
    >
      <Container className="p-3">
        <Card className="h-100 p-1">
          <Card.Body className="p-4 h-100 overflow-hidden">
            <div className="d-flex justify-content-between mb-4">
              <LinkGoBack
                className="d-flex gap-2 align-items-center text-decoration-none"
                onClick={() => navigate(-1)}
              >
                <FaChevronLeft />
                {t("link.go_back")}
              </LinkGoBack>

              <ActionButtonOutline>
                <IcFile />
              </ActionButtonOutline>
            </div>

            <Row>
              <Col sm={5}>
                <div className="d-flex gap-3 align-items-center">
                  <img
                    alt="img"
                    className="img-profile"
                    src={user_placeholder}
                  />
                  <h3 className="m-0">Alex Saldanha de Souza</h3>
                </div>

                <div className="ranking mt-5">
                  <div style={{ width: 278, height: 278 }}>
                    <CircularProgressbarWithChildren
                      value={7.5}
                      maxValue={10}
                      strokeWidth={5}
                      counterClockwise
                      styles={buildStyles({
                        trailColor: "transparent",
                        pathColor: "#FFC107",
                      })}
                    >
                      <p className="fs-3 fw-bold mb-0">
                        {t("driving_behavior.detail_average_text")}
                      </p>
                      <div style={{ fontSize: 57 }}>
                        <BsStarFill /> <strong>8.5</strong>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              </Col>

              <Col sm={7}>
                <div className="d-flex gap-3 mb-3 h-lg">
                  <div className="detail-card">
                    <BxCar fill="#6C757D" height={43} width={43} />
                    <div>
                      <div>{t("driving_behavior.table_acceleration")}</div>
                      <strong>10/10</strong>
                    </div>
                  </div>

                  <div className="detail-card">
                    <IcBrakeAbs />
                    <div>
                      <div>{t("driving_behavior.table_braking")}</div>
                      <strong>10/10</strong>
                    </div>
                  </div>

                  <div className="detail-card">
                    <IcCarControl />
                    <div>
                      <div>{t("driving_behavior.detail_underwear")}</div>
                      <strong>10/10</strong>
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-3 mb-3 h-lg">
                  <div className="detail-card">
                    <IcSpeed />
                    <div>
                      <div>{t("driving_behavior.detail_speed")}</div>
                      <strong>10/10</strong>
                    </div>
                  </div>

                  <div className="detail-card">
                    <IcPhone />
                    <div>
                      <div>{t("driving_behavior.table_cell_phone")}</div>
                      <strong>10/10</strong>
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-3 h-sm">
                  <div className="detail-card">
                    <IcMap />
                    <div>
                      <div>{t("driving_behavior.table_km_traveled")}</div>
                      <strong>320Km</strong>
                    </div>
                  </div>

                  <div className="detail-card">
                    <IcDrivingTime />
                    <div>
                      <div>{t("driving_behavior.detail_driving_time")}</div>
                      <strong>04:50</strong>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </PrivateMasterPage>
  );
};

export default DrivingBehaviorDetails;
