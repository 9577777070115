import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, Row, Spinner, Stack } from "react-bootstrap";
import LinkGoBack from "../../../components/LinkGoBack";
import DropdownSearchAndCheck from "../../../components/DropdownSearchAndCheck";
import {
  Container,
  AccordionContainer,
  Content,
  CustomLink,
  Header,
  CustomButton,
  ModalBody,
} from "./styles";
import { useAppDispatch } from "../../../core/redux/hooks";
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormSetValue,
} from "react-hook-form";
import { FleetMateRuleResolver } from "../../../core/schemaValidations/fleet_mate_rule";
import {
  Actuators,
  SecurityRuleForm,
} from "../../../core/interfaces/SecurityRulesForm";
import { SecurityRules } from "../../../core/services/SecurityRules";
import { SecuryRulesGroup } from "../../../core/services/SecuryRulesGroup";
import { DriverStatus } from "../../../core/interfaces/DriverStatus";
import { isErrorResult } from "../../../core/utils/api";
import { useFleetMate } from "../../../core/hooks/useFleetMate";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import {
  BsArrowLeftShort,
  BsTrash3,
  BsCheckCircleFill,
  BsXCircleFill,
} from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import FleetMateVehicleAssociationModal from "../../../components/FleetMateVehicleAssociationModal";
import { Ports } from "../../../core/interfaces/Ports";
import { FleetMateService } from "../../../core/services/FleetMateService";
import { GeofenceService } from "../../../core/services/GeofenceService";
import { AsyncPaginate } from "react-select-async-paginate";
import ErrorModal from "../../../components/ErrorModal";

interface IProps {
  id?: string;
  cancel?: any;
}

interface Items {
  id: string | number;
  label: string;
  checked: boolean;
}

interface Actuator {
  port: string;
  level: string;
}

const Accordion: React.FC<any> = ({
  title,
  children,
  isOpen,
  onClick,
  enabled,
  isEdit,
  onDelet,
  associate,
}) => {
  return (
    <AccordionContainer>
      {isEdit ? (
        <Header>
          <div className="d-flex">
            <CustomButton onClick={onClick} type="button">
              {isOpen ? (
                <MdKeyboardArrowDown size={25} />
              ) : (
                <MdKeyboardArrowRight size={25} />
              )}
            </CustomButton>
            <p>{title}</p>
            {enabled ? (
              <BsCheckCircleFill
                size={10}
                color="#28A745"
                style={{ marginBottom: "10px", marginLeft: "5px" }}
              />
            ) : (
              <BsXCircleFill
                size={10}
                color="#B72026"
                style={{ marginBottom: "10px", marginLeft: "5px" }}
              />
            )}
          </div>
          <div>
            <CustomButton className="" type="button" onClick={associate}>
              <FiEdit size={20} />
            </CustomButton>
            <CustomButton>
              <BsTrash3 size={20} type="button" onClick={onDelet} />
            </CustomButton>
          </div>
        </Header>
      ) : null}
      <Content isOpen={isEdit ? isOpen : true}>
        {isEdit ? isOpen && children : children}
      </Content>
    </AccordionContainer>
  );
};

const EmbeddedIntelligenceForm: React.FC<any> = ({
  id,
  cancel,
  children,
  isOpen,
  onClick,
  enabled,
  title,
  refetch,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting, defaultValues },
  } = useForm<SecurityRuleForm>({
    resolver: FleetMateRuleResolver,
  });
  const [actuatorsOn, selectedActuatorsOn] = useState<Actuators[]>([]);
  const [actuatorsOff, selectedActuatorsOff] = useState<Actuators[]>([]);
  const [sensors, selectedSensors] = useState<Items[]>([]);
  const [statusDriver, setStatusDriver] = useState<DriverStatus>();
  const [actuatorsOnLevel, setActuatorsOnLevel] = useState<string>();
  const [actuatorsOffLevel, setActuatorsOffLevel] = useState<string>();
  const [reportDriverStatus, setReportDriverStatus] = useState<boolean>(false);
  const [anchorDistance, setAnchorDistance] = useState<boolean>(false);
  const [newDriverStatus, setNewDriverStatus] = useState<boolean>(false);
  const [toleranceForRule, setTettoleranceForRule] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [openVehicleAssociation, setOpenVehicleAssociation] =
    useState<boolean>(false);
  const [geofanceGrou, setGeofanceGroup] = useState<any>();

  const { sensorStatus } = useFleetMate();

  const loadGeofaceGroup = async () => {
    const _response = await GeofenceService.getGroup({
      page: 1,
    });

    if (_response) {
      setGeofanceGroup(_response?.data);
    }
  };

  function lockedOrUnlocked(constantName: string, isOpen: boolean) {
    switch (constantName) {
      case "LEFT_DOOR":
      case "RIGHT_DOOR":
      case "SIDE_DOOR":
      case "FLUSH_DOOR_LOCK":
      case "SIDE_DOOR_LOCK":
      case "PANEL":
      case "COUPLING":
      case "FLUSH_DOOR_MAIN":
      case "PANEL_NARROW":
      case "TRAILER_DOOR":
      case "FLUSH_DOOR": {
        return isOpen
          ? t("traking.task.modal_open")
          : t("traking.task.modal_locked");
      }
      case "SILO":
      case "FIFTH_WHEEL_LOCK":
      case "FUEL_BLOCK":
      case "IGNITION_LOCK": {
        return isOpen
          ? t("traking.task.modal_blocked")
          : t("traking.task.modal_unblocked");
      }
      case "FIFTH_WHEEL": {
        return isOpen
          ? t("traking.task.modal_fifth_wheel_unblocked")
          : t("traking.task.modal_fifth_wheel_blocked");
      }
      case "IGNITION":
      case "SIREM_ALARM_LOCK": {
        return isOpen
          ? t("traking.task.modal_on")
          : t("traking.task.modal_off");
      }
      case "PANIC_BUTTON":
      case "STANDBY_MODE": {
        return isOpen
          ? t("traking.task.modal_enabled")
          : t("traking.task.modal_disabled");
      }
      default:
        return "--";
    }
  }
  const sensorFormatedStatusLabel = (labelId: string, label: string) => {
    const _sensorStatus = lockedOrUnlocked(labelId, true);

    if (_sensorStatus !== "--") {
      if (labelId === "FLUSH_DOOR_MAIN") {
        return `${t(labelId)} (${
          sensorStatus[0]?.label || ""
        } = ${_sensorStatus})`;
      }
      const chave = label.toLocaleLowerCase();
      return `${t(chave)} (${sensorStatus[0]?.label || ""} = ${_sensorStatus})`;
    } else {
      return `${label} `;
    }
  };
  const onSubmit: SubmitHandler<SecurityRuleForm> = (values) => {
    let _actuatorsOn: Actuator[] = [];
    let _actuatorsOff: Actuator[] = [];
    let _sensors: string[] = [];

    actuatorsOn
      .filter((a) => a.checked)
      .map((item) => {
        _actuatorsOn.push({
          port: item?.id,
          level: actuatorsOnLevel!,
        });
      });
    actuatorsOff
      .filter((a) => a.checked)
      .map((item) => {
        _actuatorsOff.push({
          port: item?.id,
          level: actuatorsOffLevel!,
        });
      });
    sensors
      .filter((s) => s.checked)
      .map((item) => {
        _sensors.push(item?.id?.toString());
      });

    if (id) {
      const obj = Object.assign(values, {
        rule: {
          actuators_on: _actuatorsOn,
          actuators_off: _actuatorsOff,
          sensors: _sensors,
          delay_tolerance: values.rule.delay_tolerance,
          time_mins_report_driver_status:
            values.rule.time_mins_report_driver_status,
          anchor_distance: values.rule.anchor_distance,
          alert_driver: values.rule.alert_driver,
          send_satellite_alert: values.rule.send_satellite_alert,
          disable_jammer_detection: values.rule.disable_jammer_detection,
          generate_monitoring_alert: values.rule.generate_monitoring_alert,
          delay: values.rule.delay,
          rule_for_sensors: values.rule.rule_for_sensors,
          rule_for_drivers: values.rule.rule_for_drivers,
          rule_for_geofence: values.rule.rule_for_geofence,
          sensor_status_active:
            values.rule.sensor_status_active === "E" ? true : false,
          driver_status_id: values.rule.driver_status_id,
          new_driver_status_id:
            values.rule.new_driver_status_id === 0
              ? null
              : values.rule.new_driver_status_id,
          geofence_group_id: values.rule.geofence_group_id,
          geofence_event: values.rule.geofence_event,
          geofence_time: values.rule.geofence_time,
          geofence_id: Number(values.rule.geofence_id?.value),
        },
      });

      const hasDriverStatusValid = statusDriver?.status_driver?.filter(
        (status) => status?.id == obj?.rule?.driver_status_id
      );
      if (hasDriverStatusValid?.length === 0) {
        // @ts-ignore
        delete obj.rule.driver_status_id;
      }
      console.log("teste obj ->", obj);
      console.log("teste obj rule->", obj?.rule);
      console.log(
        "teste obj rule new_driver_status_id->",
        obj?.rule?.new_driver_status_id
      );
      console.log("teste statusDriver ->", newDriverStatus);
      if (newDriverStatus && !obj?.rule?.new_driver_status_id) {
        dispatch(
          updateAlert({
            title: t("configs.fleetmate.ei.configs.error.new_driver_status"),
            message: "Error",
            type: "error",
          })
        );
      } else {
        SecurityRules.put(obj)
          .then((resp) => {
            if (resp.status) {
              dispatch(
                updateAlert({
                  title: t("message.success.save_data"),
                  onClick: () => {
                    navigate(`/configs/mate/${params?.id}`);
                  },
                })
              );
            }
          })
          .catch((erro: any) => {
            if (
              erro?.response?.data?.errors["rule.new_driver_status_id"][0] ===
              "The selected rule.new driver status id is invalid."
            ) {
              dispatch(
                updateAlert({
                  title: t(
                    "configs.fleetmate.ei.configs.error.new_driver_status"
                  ),
                  message: erro?.response?.data?.message || "Error",
                  type: "error",
                })
              );
            } else {
              dispatch(
                updateAlert({
                  title: erro?.message || "Error",
                  message: erro?.response?.data?.message || "Error",
                  type: "error",
                })
              );
            }
          });
      }
    } else {
      const obj = Object.assign(values, {
        security_rule_group_id: params?.id,
        enabled: true,
        rule: {
          actuators_on: _actuatorsOn,
          actuators_off: _actuatorsOff,
          sensors: _sensors,
          delay_tolerance: values.rule.delay_tolerance,
          time_mins_report_driver_status:
            values.rule.time_mins_report_driver_status,
          anchor_distance: values.rule.anchor_distance,
          alert_driver: values.rule.alert_driver,
          send_satellite_alert: values.rule.send_satellite_alert,
          disable_jammer_detection: values.rule.disable_jammer_detection,
          generate_monitoring_alert: values.rule.generate_monitoring_alert,
          delay: values.rule.delay,
          rule_for_sensors: values.rule.rule_for_sensors,
          rule_for_drivers: values.rule.rule_for_drivers,
          rule_for_geofence: values.rule.rule_for_geofence,
          sensor_status_active:
            values.rule.sensor_status_active === "E" ? true : false,
          driver_status_id: values.rule.driver_status_id,
          new_driver_status_id: values.rule.new_driver_status_id,
          geofence_group_id: values.rule.geofence_group_id,
          geofence_event: values.rule.geofence_event,
          geofence_time: values.rule.geofence_time,
          geofence_id: Number(values.rule.geofence_id?.value),
        },
      });
      SecurityRules.post(obj).then((resp) => {
        if (resp) {
          dispatch(
            updateAlert({
              title: t("message.success.save_data"),
              onClick: () => {
                navigate(`/configs/mate/${params?.id}`);
              },
            })
          );
        }
      });
    }
  };

  function handleFormCancel() {
    reset();
    navigate(-1);
  }

  function handleActuatorOn(items: Items[]) {
    const updatedActuators = (actuatorsOff || []).map((off, i) => ({
      label: off.label,
      id: off.id,
      checked: off.checked && items[i].checked ? false : off.checked,
      key: off.label + "_off",
    }));

    selectedActuatorsOff(updatedActuators);
  }

  function handleActuatorOff(items: Items[]) {
    const updatedActuators = (actuatorsOn || []).map((on, i) => ({
      label: on.label,
      id: on.id,
      checked: on.checked && items[i].checked ? false : on.checked,
      key: on.label + "_on",
    }));
    selectedActuatorsOn(updatedActuators);
  }

  async function loadDriverStatus() {
    try {
      const _response = await SecuryRulesGroup.getDriveStatus();
      const isError = isErrorResult(_response);
      if (!isError && _response.data) {
        setStatusDriver(_response.data);
      }
    } catch (error: any) {
      console.error("Erro in load fleetmate.ei", error.message);
    }
  }

  async function loadRule() {
    const ports = await FleetMateService.getPorts();
    // console.log(ports, "1234");

    if (ports) {
      try {
        if (id) {
          const _response = await SecurityRules.getById(id);
          const isError = isErrorResult(_response);
          if (!isError && _response) {
            let _actuatorsOn: Actuators[] = [];
            let _actuatorsOff: Actuators[] = [];
            let _sensors: Items[] = [];

            ports
              ?.filter((entry: Ports) => entry.type === "exit")
              .forEach((item: Ports) => {
                const foundPort = _response?.rule?.actuators_on?.find(
                  (portItem) => portItem.port === item.constant
                );

                if (foundPort) {
                  _actuatorsOn.push({
                    id: item.constant,
                    label: item.name,
                    checked: true,
                    key: item.name + "_on",
                  });
                } else {
                  _actuatorsOn.push({
                    id: item.constant,
                    label: item.name,
                    checked: false,
                    key: item.name + "_on",
                  });
                }
              });

            ports
              ?.filter((entry: Ports) => entry.type === "exit")
              .forEach((item: Ports) => {
                const foundPort = _response?.rule?.actuators_off?.find(
                  (portItem) => portItem.port === item.constant
                );
                if (foundPort) {
                  _actuatorsOff.push({
                    id: item.constant,
                    label: item.name,
                    checked: true,
                    key: item.name + "_off",
                  });
                } else {
                  _actuatorsOff.push({
                    id: item.constant,
                    label: item.name,
                    checked: false,
                    key: item.name + "_off",
                  });
                }
              });

            ports
              ?.filter((entry: Ports) => entry.type !== "exit")
              .forEach((e: Ports) => {
                const foundPort = _response?.rule?.sensors?.find(
                  (portItem: any) => portItem === e.constant
                );
                if (foundPort) {
                  _sensors.push({
                    id: e.constant,
                    label: e.name,
                    checked: true,
                  });
                } else {
                  _sensors.push({
                    id: e.constant,
                    label: e.name,
                    checked: false,
                  });
                }
              });

            _response?.rule?.actuators_on?.forEach((item) => {
              if (item.level?.toString() === "50") {
                setActuatorsOnLevel("50");
              } else {
                setActuatorsOnLevel("100");
              }
            });

            _response?.rule?.actuators_off?.forEach((item) => {
              if (item.level?.toString() === "50") {
                setActuatorsOffLevel("50");
              } else {
                setActuatorsOffLevel("100");
              }
            });

            if (_response.rule.time_mins_report_driver_status)
              setReportDriverStatus(true);
            if (_response.rule.anchor_distance) setAnchorDistance(true);
            if (_response.rule.new_driver_status_id) setNewDriverStatus(true);
            if (_response.rule.delay_tolerance) setTettoleranceForRule(true);

            reset({
              company_id: _response.company_id,
              created_at: _response.created_at,
              deleted_at: _response.deleted_at,
              enabled: _response.enabled,
              id: _response.id,
              name: _response.name,
              rule: _response.rule,
              security_rule_group_id: _response.security_rule_group_id,
              updated_at: _response.updated_at,
              user_id: _response.user_id,
              uuid: _response.uuid,
            });
            setValue("rule.actuators_on", _actuatorsOn);
            setValue("rule.actuators_off", _actuatorsOff);
            setValue("rule.sensors", _sensors);
            setValue(
              "rule.sensor_status_active",
              _response?.rule?.sensor_status_active ? "E" : "D"
            );
            selectedSensors(_sensors);
            selectedActuatorsOff(_actuatorsOff);
            selectedActuatorsOn(_actuatorsOn);
            setValue("rule.vehicle_tag_ids", _response?.rule?.vehicle_tag_ids);
            setValue("rule.vehicle_ids", _response?.rule?.vehicle_ids);
          }
        } else {
          let _actuatorsOn: Actuators[] = [];
          let _actuatorsOff: Actuators[] = [];
          let _sensors: Items[] = [];

          ports
            ?.filter((entry: Ports) => entry.type === "exit")
            .forEach((item: Ports) => {
              _actuatorsOff.push({
                id: item.constant,
                label: t(item.name.toLowerCase()),
                checked: false,
                key: item.name + "_off",
              });
              _actuatorsOn.push({
                id: item.constant,
                label: t(item.name.toLowerCase()),
                checked: false,
                key: item.name + "_on",
              });
            });

          ports?.forEach((e: Ports) => {
            if (e.type !== "exit")
              _sensors.push({
                id: e.constant,
                label: t(e.name.toLowerCase()),
                checked: false,
              });
          });

          setValue("rule.actuators_on", _actuatorsOn);
          setValue("rule.actuators_off", _actuatorsOff);
          setValue("rule.sensors", _sensors);
          selectedSensors(_sensors);
          selectedActuatorsOn(_actuatorsOn);
          selectedActuatorsOff(_actuatorsOff);
        }
      } catch (error: any) {
        console.log("Erro in load fleetmate.ei", error.message);
      }
    }
  }

  async function deleteRule(id: string) {
    await SecurityRules.delete(id);
    refetch?.();
    setShow(false);
  }

  useEffect(() => {
    loadGeofaceGroup();
  }, [i18n.language]);

  useEffect(() => {
    loadDriverStatus();
    loadRule();
  }, [id, i18n.language]);

  return (
    <>
      <Accordion
        onClick={onClick}
        isOpen={isOpen}
        title={watch("name") ? watch("name") : title}
        enabled={enabled}
        onDelet={() => setShow(true)}
        associate={() => setOpenVehicleAssociation(true)}
        isEdit={id ? true : false}
      >
        <Container className="mb-3">
          {!id && (
            <>
              <LinkGoBack className="mx-4" onClick={handleFormCancel}>
                <BsArrowLeftShort size={24} />
                {t("link.go_back")}
              </LinkGoBack>
              <div className="separator-line mt-3" />
            </>
          )}

          <Form className="mx-4" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-4">
              <Form.Group
                className="mb-3"
                as={Col}
                lg={4}
                controlId="rule_name"
              >
                <Form.Label>{t("configs.fleetmate.ei.rule_name")}</Form.Label>
                <Form.Control
                  placeholder={t("configs.fleetmate.ei.rule_name_placeholder")}
                  isInvalid={!!errors?.name?.message}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" as={Col} controlId="driver_status">
                <Form.Label>
                  {t("configs.fleetmate.ei.status_driver")}
                </Form.Label>
                <Controller
                  control={control}
                  name="rule.driver_status_id"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Form.Select
                        isInvalid={!!errors?.rule?.driver_status?.message}
                        //@ts-ignore
                        placeholder={t("input.select_text")}
                        onChange={onChange}
                        value={value}
                      >
                        <option>{t("input.select_text")}</option>
                        {statusDriver?.status_driver?.map((item, i) => {
                          if (item?.id) {
                            return (
                              <option key={i} value={item.id}>
                                {t(item.name.toLowerCase())}
                              </option>
                            );
                          }
                        })}
                      </Form.Select>
                    );
                  }}
                />
                <Form.Text>
                  {t("configs.fleetmate.ei.status_driver_description")}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.driver_status?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" as={Col} controlId="sensors">
                <Form.Label>{t("configs.fleetmate.ei.sensors")}</Form.Label>
                <Controller
                  control={control}
                  name="rule.sensors"
                  render={({ field: { onChange, value } }) => {
                    const formattedSensors = sensors
                      ?.map((_sensor) => {
                        if (_sensor?.id) {
                          return {
                            ..._sensor,

                            label: sensorFormatedStatusLabel(
                              `${_sensor?.id}`,
                              _sensor?.label
                            ),
                          };
                        }
                        return { id: "0", label: "", checked: false };
                      })
                      .filter(Boolean);

                    return (
                      <DropdownSearchAndCheck
                        items={formattedSensors || []}
                        values={sensors}
                        onChange={onChange}
                        search={false}
                      />
                    );
                  }}
                />
                <Form.Text>
                  {t("configs.fleetmate.ei.sensors_description")}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {/* {errors?.rule?.sensors?.message} */}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" as={Col} controlId="sensor_status">
                <Form.Label>
                  {t("configs.fleetmate.ei.status_sensor")}
                </Form.Label>
                <Form.Select
                  isInvalid={!!errors?.rule?.sensor_status_active?.message}
                  {...register("rule.sensor_status_active")}
                >
                  {sensorStatus.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text>
                  {t("configs.fleetmate.ei.status_sensor_description")}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.sensor_status_active?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row md="3">
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="groups_electronic_fences"
              >
                <Form.Label>
                  {t("configs.fleetmate.ei.group_electronic_fences")}
                </Form.Label>
                <Controller
                  control={control}
                  name="rule.geofence_group_id"
                  render={({ field: { onChange, value } }) => (
                    <Form.Select
                      isInvalid={!!errors?.rule?.driver_status?.message}
                      //@ts-ignore
                      placeholder={t("input.select_text")}
                      onChange={onChange}
                      value={value}
                    >
                      <option value="">{t("input.select_text")}</option>
                      {geofanceGrou?.map((item: any, i: number) => {
                        if (item?.id) {
                          return (
                            <option key={i} value={item.id}>
                              {t(item.name)}
                            </option>
                          );
                        }
                      })}
                    </Form.Select>
                  )}
                />
                <Form.Text>
                  {t(
                    "configs.fleetmate.ei.group_electronic_fences_description"
                  )}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.actuators_off?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                as={Col}
                lg={4}
                controlId="length_of_stay"
              >
                <Col>
                  <Form.Label>
                    {t("configs.fleetmate.ei.length_of_stay")}
                  </Form.Label>
                  <Row className="justify-content-start align-items-center">
                    <Form.Control
                      style={{ width: 70, marginLeft: 10 }}
                      type="number"
                      min={0}
                      defaultValue={0}
                    />
                    <Col>
                      <p>{t("configs.fleetmate.setting.seconds")}</p>
                    </Col>
                  </Row>
                  <Form.Control.Feedback type="invalid">
                    {errors?.rule?.actuators_off?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                className="mb-3"
                as={Col}
                controlId="active_rule_electronic_fences"
              >
                <Form.Label>
                  {t("configs.fleetmate.ei.electronic_fences_when")}
                </Form.Label>
                <Controller
                  control={control}
                  name="rule.geofence_event"
                  render={({ field: { onChange, value } }) => (
                    <Form.Select
                      isInvalid={!!errors?.rule?.actuators_off?.message}
                      onChange={onChange}
                      value={value}
                    >
                      <option>{t("input.select_text")}</option>
                      <option value="E">
                        {t("Ao Entrar na Cerca Eletrô...")}
                      </option>
                      <option value="L">
                        {t("Ao Sair da Cerca Eletrô...")}
                      </option>
                    </Form.Select>
                  )}
                />
                <Form.Text>
                  {t("configs.fleetmate.ei.electronic_fences_when_description")}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.actuators_on?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} sm={4} className="mb-3">
                <Form.Label>{t("electronic_fences")}</Form.Label>

                <Controller
                  control={control}
                  name="rule.geofence_id"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <AsyncPaginate
                      isClearable
                      loadOptionsOnMenuOpen
                      onFocus={() => {
                        GeofenceService.loadGeofaceById(
                          "",
                          [],
                          "",
                          watch("rule.geofence_group_id")
                        );
                      }}
                      placeholder={t("input.select_text")}
                      loadOptions={(inputValue, options, additional) =>
                        GeofenceService.loadGeofaceById(
                          inputValue,
                          options,
                          additional,
                          watch("rule.geofence_group_id")
                        )
                      }
                      additional={{ page: 1 }}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </Form.Group>
            </Row>

            <Row>
              <strong>{t("configs.fleetmate.ei.actuators_label")}</strong>
              <Form.Group
                className="mb-3"
                as={Col}
                lg={4}
                controlId="rule.actuators_on"
              >
                <Controller
                  control={control}
                  name="rule.actuators_on"
                  render={({ field: { name, onChange } }) => (
                    <DropdownSearchAndCheck
                      items={actuatorsOn}
                      values={actuatorsOn}
                      onChange={handleActuatorOn}
                      search={false}
                    />
                  )}
                />
                <Form.Text>
                  {t("configs.fleetmate.ei.electronic_fences_when_description")}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.actuators_on?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                as={Col}
                lg={4}
                controlId="rule.actuators_off"
              >
                <Controller
                  control={control}
                  name="rule.actuators_off"
                  render={({ field: { name, onChange } }) => (
                    <DropdownSearchAndCheck
                      items={actuatorsOff}
                      values={actuatorsOff}
                      onChange={handleActuatorOff}
                      search={false}
                    />
                  )}
                />
                <Form.Text>
                  {t("configs.fleetmate.ei.when_the_rule_will_be_deactivated")}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.actuators_off?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" as={Col} lg={4}>
                <div>{t("fleetmate.actuators.level_block")}</div>
                <Form.Select
                  //@ts-ignore
                  placeholder={t("input.select_text")}
                  onChange={(e) => setActuatorsOnLevel(e?.target?.value)}
                  value={actuatorsOnLevel}
                >
                  <option value="">{t("input.select_text")}</option>
                  <option value="50">{t("traking.task_detail_vehicle")}</option>
                  <option value="100">{t("traking.center")}</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" as={Col} lg={4}>
                <div>{t("fleetmate.actuators.level_block")}</div>
                <Form.Select
                  //@ts-ignore
                  placeholder={t("input.select_text")}
                  onChange={(e) => setActuatorsOffLevel(e?.target?.value)}
                  value={actuatorsOffLevel}
                >
                  <option value="">{t("input.select_text")}</option>
                  <option value="50">{t("traking.task_detail_vehicle")}</option>
                  <option value="100">{t("traking.center")}</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="activate_rule_driver_not_report_time"
              >
                <div className="d-flex align-items-center gap-3">
                  <Form.Check
                    checked={reportDriverStatus}
                    label={t("configs.fleetmate.ei.trigger_by_time_pt1")}
                    onChange={(e) => {
                      setReportDriverStatus(e.target.checked);
                      if (e.target.checked || reportDriverStatus) {
                        setValue("rule.time_mins_report_driver_status", null);
                      }
                    }}
                  />

                  <Form.Control
                    disabled={!reportDriverStatus}
                    style={{ width: 100 }}
                    type="number"
                    min={0}
                    defaultValue={0}
                    isInvalid={
                      !!errors?.rule?.time_mins_report_driver_status?.message
                    }
                    {...register("rule.time_mins_report_driver_status")}
                  />
                  <Form.Label>
                    {t("configs.fleetmate.ei.trigger_by_time_pt2")}
                  </Form.Label>
                </div>

                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.time_mins_report_driver_status?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" as={Col} controlId="anchor_distance">
                <div className="d-flex align-items-center gap-3">
                  <Form.Check
                    checked={anchorDistance}
                    label={t(
                      "configs.fleetmate.ei.configs.fleetmate.ei.rule_drive_move"
                    )}
                    onChange={(e) => {
                      setAnchorDistance(e.target.checked);
                      if (e.target.checked || anchorDistance) {
                        setValue("rule.anchor_distance", null);
                      }
                    }}
                  />

                  <Form.Control
                    disabled={!anchorDistance}
                    style={{ width: 100 }}
                    type="number"
                    min={0}
                    defaultValue={0}
                    isInvalid={
                      !!errors?.rule?.time_mins_report_driver_status?.message
                    }
                    {...register("rule.anchor_distance")}
                  />
                  <Form.Label>{t("fleetmate.time.meters")}</Form.Label>
                </div>

                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.time_mins_report_driver_status?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="new_driver_status_id"
              >
                <div className="d-flex align-items-center gap-3">
                  <Form.Check
                    checked={newDriverStatus}
                    label={t("configs.fleetmate.ei.change_driver_status_to")}
                    onChange={(e) => {
                      setNewDriverStatus(e.target.checked);
                      if (e.target.checked || newDriverStatus) {
                        setValue("rule.new_driver_status_id", 0);
                      }
                    }}
                  />
                  <Form.Group as={Col} lg={4} controlId="driver_status">
                    <Controller
                      control={control}
                      name="rule.new_driver_status_id"
                      render={({ field: { onChange, value } }) => (
                        <Form.Select
                          disabled={!newDriverStatus}
                          isInvalid={!!errors?.rule?.driver_status?.message}
                          //@ts-ignore
                          placeholder={t("input.select_text")}
                          onChange={onChange}
                          value={value!}
                        >
                          <option value="">{t("input.select_text")}</option>
                          {statusDriver?.status_driver?.map((item, i) => {
                            if (item?.id) {
                              return (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </Form.Select>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.rule?.driver_status_id?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.time_mins_report_driver_status?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" as={Col} controlId="delay_tolerance">
                <div className="d-flex align-items-center gap-3">
                  <Form.Check
                    checked={toleranceForRule}
                    label={t(
                      "configs.fleetmate.setting.tolerance_for_rule_execution"
                    )}
                    onChange={(e) => {
                      setTettoleranceForRule(e.target.checked);
                      if (e.target.checked || toleranceForRule) {
                        setValue("rule.delay_tolerance", null);
                      }
                    }}
                  />
                  <Form.Control
                    disabled={!toleranceForRule}
                    style={{ width: 100 }}
                    type="number"
                    min={0}
                    defaultValue={0}
                    isInvalid={!!errors?.rule?.delay_tolerance?.message}
                    {...register("rule.delay_tolerance")}
                  />
                  <Form.Label>
                    {t("configs.fleetmate.setting.seconds")}
                  </Form.Label>
                </div>

                <Form.Control.Feedback type="invalid">
                  {errors?.rule?.delay_tolerance?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Col lg={5}>
                <Form.Group
                  className="mb-3"
                  as={Col}
                  controlId="wait_seconds_action_rule"
                >
                  <div className="d-flex align-items-center gap-3">
                    <Form.Label>
                      {t("configs.fleetmate.ei.wait_pt1")}
                    </Form.Label>

                    <Form.Control
                      style={{ width: 100 }}
                      type="number"
                      min={0}
                      defaultValue={0}
                      isInvalid={!!errors?.rule?.delay?.message}
                      {...register("rule.delay")}
                    />
                    <Form.Label>
                      {t("configs.fleetmate.ei.wait_pt2")}
                    </Form.Label>
                  </div>

                  <Form.Control.Feedback type="invalid">
                    {errors?.rule?.delay?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} className="d-flex flex-wrap">
                <Col>
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    controlId="alert_driver"
                  >
                    <Form.Check
                      defaultChecked={false}
                      label={t("configs.fleetmate.ei.warning_the_driver")}
                      {...register("rule.alert_driver")}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    as={Col}
                    controlId="send_satellite_alert"
                  >
                    <Form.Check
                      defaultChecked={false}
                      label={t(
                        "configs.fleetmate.ei.generate_alert_in_monitoring"
                      )}
                      {...register("rule.generate_monitoring_alert")}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    controlId="generate_monitoring_alert"
                  >
                    <Form.Check
                      defaultChecked={false}
                      label={t("configs.fleetmate.ei.sending_alerts_satellite")}
                      {...register("rule.send_satellite_alert")}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    as={Col}
                    controlId="disable_jammer_detection"
                  >
                    <Form.Check
                      defaultChecked={false}
                      label={t("configs.fleetmate.ei.disable_jammer_detection")}
                      {...register("rule.disable_jammer_detection")}
                    />
                  </Form.Group>
                </Col>
              </Col>
            </Row>

            <Row>
              <Stack className="" direction="horizontal" gap={3}>
                <Button type="submit" disabled={watch("name")?.length <= 6}>
                  {isSubmitting ? t("status.saving") : t("button.save")}
                  {isSubmitting && <Spinner animation="border" size="sm" />}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    cancel?.();
                    reset();
                  }}
                >
                  {t("button.cancel")}
                </Button>
              </Stack>
            </Row>
          </Form>
        </Container>
      </Accordion>

      <FleetMateVehicleAssociationModal
        tagsRule={watch("rule.vehicle_tag_ids")}
        vehiclesRule={watch("rule.vehicle_ids")}
        visible={openVehicleAssociation}
        onClose={() => setOpenVehicleAssociation(false)}
        id={watch("id")}
        refetch={() => loadRule()}
      />
      <Modal size="lg" show={show} centered>
        <ModalBody>
          <p className="text-center">
            Você tem certeza que deseja excluir essa configuração?
          </p>
          <p className="text-center">Essa ação não poderá ser revertida.</p>
          <Row className="justify-content-center">
            <Col lg={2}>
              <Button
                type="button"
                onClick={() => {
                  deleteRule(watch("id").toString());
                }}
              >
                Sim
              </Button>
            </Col>
            <Col lg={2}>
              <Button
                type="button"
                variant="secondary"
                onClick={() => setShow(false)}
              >
                Não
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EmbeddedIntelligenceForm;
