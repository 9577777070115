import { useEffect, useRef } from "react";
import { Modal } from "./styles";

import flvjs from "flv.js";

interface CameraModalProps {
  onClose(): void;
  url: string;
  isLive?: boolean;
  img?: boolean;
}

const CameraModal: React.FC<CameraModalProps> = ({
  onClose,
  url,
  img,
  isLive,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!url || !isLive) return;

    if (videoRef.current) {
      const flvPlayer = flvjs.createPlayer({
        type: "flv",
        url: url,
      });
      flvPlayer.attachMediaElement(videoRef.current);
      flvPlayer.load();

      const onLoadedMetadata = () => {
        flvPlayer.play();
        videoRef.current?.removeEventListener(
          "loadedmetadata",
          onLoadedMetadata
        );
      };

      videoRef.current.addEventListener("loadedmetadata", onLoadedMetadata);

      flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail) => {
        console.log("Erro de reprodução de vídeo:", errorType, errorDetail);
        flvPlayer.pause();
        flvPlayer.unload();

        return onClose();
      });

      return () => {
        flvPlayer.destroy();
      };
    }
  }, [url]);

  return (
    <Modal
      {...props}
      show={!!url}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Body>
        {img ? (
          <img src={url} alt="image" />
        ) : !isLive ? (
          <>
            <video controls width="100%">
              <source src={url} type="video/mp4" />
              Seu navegador não suporta a exibição de vídeos.
            </video>
          </>
        ) : (
          <video ref={videoRef} controls width="100%" />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CameraModal;
