import { Modal, Spinner } from "react-bootstrap";
import { useAudit } from "../../core/hooks/useAudit";
import Commands from "./RecordTypes/Commands";
import DriverAppSettings from "./RecordTypes/DriverAppSettings";
import ElectronicFences from "./RecordTypes/ElectronicFences";
import Login from "./RecordTypes/Login";
import Messages from "./RecordTypes/Messages";
import SystemRecord from "./RecordTypes/SystemRecord";
import { Container } from "./styles";
import { TrackingService } from "../../core/services/TrackingService";
import { useEffect, useMemo, useState } from "react";
import { AuditService } from "../../core/services/AuditService";
import { isErrorResult } from "../../core/utils/api";
import Positions from "./RecordTypes/IPositions";
import { useTranslation } from "react-i18next";

interface Props {
  visible?: boolean;
  onClose?(): void;
  data?: any;
}

export default function AuditDetailModal({
  data,
  visible,
  onClose,
  ...props
}: Props) {
  const { getRecordTypeTitle } = useAudit();
  const { t } = useTranslation();

  const [details, setDetails] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [setupId, setSetupId] = useState<number>();

  const loginLogoutIds = [1, 2, 3, 4, 13, 14];
  const positionsIds = [58, 59];
  const commandsIds = [35, 63, 64];
  const electronicFencesIds = [25, 26, 27, 18];
  const messagesIds = [5, 6, 7, 67];

  async function loadRegistries() {
    try {
      const _response = await AuditService.getRegistersDetails(data?.id);

      const isError = isErrorResult(_response);

      if (!isError && _response?.data) {
        setDetails(_response?.data);
        setSetupId(_response?.data?.registry?.data?.setup?.setup_type_id);
      }
    } catch (error) {
      console.log("loadRegistries error", error);
    } finally {
      if (details) {
        setIsLoading(false);
      }
    }
  }

  function modalTitle() {
    if (positionsIds.includes(data?.event?.id)) {
      return t("configs.audit.positions");
    }

    if (loginLogoutIds.includes(data?.event?.id)) {
      return t("configs.audit.login");
    }

    if (
      setupId !== undefined && isLoading === false
        ? setupId === 2 ||
          setupId === 3 ||
          setupId === 5 ||
          setupId === 6 ||
          setupId === 7
        : commandsIds.includes(data?.event?.id)
    ) {
      return t("configs.audit.commands");
    }

    if (
      setupId !== undefined && isLoading === false
        ? setupId === 1 || setupId === 4
        : electronicFencesIds.includes(data?.event?.id)
    ) {
      return t("configs.audit.electronic_fences");
    }

    if (messagesIds.includes(data?.event?.id)) {
      return t("configs.audit.messages");
    }

    if (
      !loginLogoutIds.includes(data?.event?.id) &&
      !positionsIds.includes(data?.event?.id) &&
      !commandsIds.includes(data?.event?.id) &&
      !electronicFencesIds.includes(data?.event?.id) &&
      !messagesIds.includes(data?.event?.id) &&
      data?.event?.id !== 50
    ) {
      return t("configs.audit.system_record");
    }
  }

  useEffect(() => {
    if (visible) {
      loadRegistries();
    }
  }, [visible]);

  const details_item = useMemo(() => {
    return details;
  }, [details]);

  useEffect(() => {
    setSetupId(details?.registry?.data?.setup?.setup_type_id);
  }, [details]);

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={onClose}>
      {!isLoading ? (
        <Container className="pb-2 px-3" style={{ border: 0 }}>
          <Modal.Header className="border-0 pt-4 pb-1 mb-4" closeButton>
            <Modal.Title>
              <h2
                style={{
                  color: "#6C757D",
                  fontWeight: 500,
                }}
              >
                {!isLoading ? modalTitle() : ""}
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isLoading &&
              setupId !== 7 &&
              setupId !== 6 &&
              setupId !== 5 &&
              setupId !== 4 &&
              setupId !== 3 &&
              setupId !== 2 &&
              setupId !== 1 &&
              data?.event?.id &&
              !loginLogoutIds.includes(data?.event?.id) &&
              !positionsIds.includes(data?.event?.id) &&
              !commandsIds.includes(data?.event?.id) &&
              !electronicFencesIds.includes(data?.event?.id) &&
              !messagesIds.includes(data?.event?.id) &&
              data?.event?.id !== 50 && (
                <SystemRecord
                  data={details_item}
                  summary={
                    typeof !!data?.data?.summarized_registry_id === "number" ||
                    data?.registry?.data?.audit_ids?.length > 0
                      ? true
                      : false
                  }
                />
              )}
            {positionsIds.includes(data?.event?.id) && !isLoading && (
              <Positions
                data={details_item}
                summary={
                  typeof !!data?.data?.summarized_registry_id === "number" ||
                  data?.registry?.data?.audit_ids?.length > 0
                    ? true
                    : false
                }
              />
            )}
            {!isLoading && commandsIds.includes(data?.event?.id) && (
              <Commands
                data={details_item}
                setupType={data?.data?.setup_type_id}
                summary={
                  typeof !!data?.data?.summarized_registry_id === "number" ||
                  data?.registry?.data?.audit_ids?.length > 0
                    ? true
                    : false
                }
              />
            )}
            {(!isLoading && setupId === 1) ||
              setupId === 4 ||
              (electronicFencesIds.includes(data?.event?.id) && (
                <ElectronicFences
                  data={details_item}
                  summary={
                    typeof !!data?.data?.summarized_registry_id === "number" ||
                    data?.registry?.data?.audit_ids?.length > 0
                      ? true
                      : false
                  }
                />
              ))}
            {data?.event?.id === 50 && !isLoading && (
              <DriverAppSettings
                data={details_item}
                setupType={data?.data?.setup_type_id}
                summary={
                  typeof !!data?.data?.summarized_registry_id === "number" ||
                  data?.registry?.data?.audit_ids?.length > 0
                    ? true
                    : false
                }
              />
            )}
            {messagesIds.includes(data?.event?.id) && !isLoading && (
              <Messages
                data={details_item}
                summary={
                  typeof !!data?.data?.summarized_registry_id === "number" ||
                  data?.registry?.data?.audit_ids?.length > 0
                    ? true
                    : false
                }
              />
            )}
            {loginLogoutIds.includes(data?.event?.id) && !isLoading && (
              <Login
                data={details_item}
                summary={
                  typeof !!data?.data?.summarized_registry_id === "number" ||
                  data?.registry?.data?.audit_ids?.length > 0
                    ? true
                    : false
                }
              />
            )}
          </Modal.Body>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      )}
    </Modal>
  );
}
