import styled from "styled-components";
import PrivateMasterPage from "../../layout/PrivateMasterPage";

export const Container = styled(PrivateMasterPage)`
  div.map {
    background-color: white;
  }
  div.busca {
    width: 100%;
    height: 49px;
    position: relative;
    background-color: rgba(206, 212, 218, 0.8);
    top: -48px;
  }
  div#lista {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    color: #6c757d;
    height: 48px;
    margin-left: 21px;
    text-align: right;
  }
  div.d-flex.bd-highlight.mb-3 {
    font-style: normal;
    font-weight: 400;
    color: #939698;
    text-align: right;
  }
  div.lista {
    top: -40px;
    width: 100%;
    position: relative;
  }
  .col {
    border: left;
  }
  .col1 {
    width: 16%;
    border-left: #212529;
  }
  .col2 {
    width: 27%;
  }
  .col3 {
    width: 28%;
  }
  .col4 {
    width: 22%;
  }
  input.busca {
    position: absolute;
    height: 37px;
    top: 6px;
    left: 31px;
    display: block;
    width: 18%;
    padding: 0.375rem 1.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  button.filtro {
    width: 40px;
    height: 36px;
    border-radius: 4.8px;
    position: absolute;
    left: 240px;
    top: 7px;
    border: 1px solid rgba(222, 22, 218);
    border-radius: 4.8px;
  }

  select.buscas {
    border: none;
    font-size: 14px;
    color: #6c757d;
    font-weight: 400;
    line-height: 21px;
    background-color: transparent;
    position: relative;
    top: 5px;
    left: -21px;
  }
  span.custo {
    position: relative;
    left: 275px;
    top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #6c757d;
  }
  svg.lupa {
    position: relative;
    left: 10px;
  }
  svg {
    position: sticky;
  }
  svg.fraca {
    left: -16px;
    position: relative;
  }

  div.col6::after {
    content: "";
    width: 1px;
    height: 96%;
    background-color: rgba(230, 230, 230, 1);
    display: block;
    position: absolute;
    top: 5px;
    margin-left: -3px;
    z-index: -1;
  }

  div.col6,
  .col8 {
    width: 11%;
    z-index: 9;
  }
  div.col10::after {
    content: "";
    width: 58%;
    height: 4px;
    border-radius: 11px;
    background-color: #3898ff;
    position: absolute;
    left: 466px;
    top: 75px;
    z-index: 10;
  }
  div.col11::after {
    content: "";
    width: 58%;
    height: 4px;
    border-radius: 11px;
    background-color: #ffc672;
    position: absolute;
    left: 466px;
    top: 130px;
    z-index: 10;
  }

  div.col12::after {
    content: "";
    width: 58%;
    height: 4px;
    border-radius: 11px;
    background-color: #3dd598;
    position: absolute;
    left: 466px;
    top: 185px;
    z-index: 10;
  }

  div.col13::after {
    content: "";
    width: 58%;
    height: 4px;
    border-radius: 11px;
    background-color: #ff6473;
    position: absolute;
    left: 466px;
    top: 242px;
    z-index: 10;
  }

  div#retangle1 {
    background-color: #4092ec;
    width: 44px;
    height: 36px;
    border-radius: 3px;
    position: absolute;
    left: 496px;
    top: 60px;
  }
  div#retangle2 {
    background-color: #4092ec;
    width: 65px;
    height: 36px;
    border-radius: 3px;
    position: absolute;
    left: 565px;
    top: 60px;
    z-index: 30;
  }
  span {
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    flex: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
