import axios from "axios";
import { SessionStorage } from "../storage/session_storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_LINK_API,
  timeout: 60000,
});

instance.interceptors.request.use(function (config) {
  try {
    const token = SessionStorage.getToken();

    if (!config.headers) config.headers = {};
    if (token) config.headers.Authorization = `Bearer ${token}`;
  } catch (error) {
    console.log("interceptor error", error);
  }

  return config;
});

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error.response.status === 401) {
      SessionStorage.removeUser();
      SessionStorage.removeToken();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export const api = instance;
