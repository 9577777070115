import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { string } from "yup";
import { TRACKING_GRID_COLUMNS } from "../../../constants/tracking_grid";
import { TRACKING_OPTIONS } from "../../../constants/tracking_options";
import { SelectOption } from "../../interfaces/SelectOption";
import {
  TrackingFilter,
  TrackingFilterMapBounds,
} from "../../interfaces/TrackingFilter";
import { TrackingGridItem } from "../../interfaces/TrackingGridItem";
import { TrackingModalInfo } from "../../interfaces/TrackingModalInfo";
import { TrackingOptions } from "../../interfaces/TrackingOptions";
import { TrackingVehicles } from "../../interfaces/TrackingVehicles";
import { TrackingStorage } from "../../storage/tracking_storage";
import type { RootState } from "../store";
import { TRACKING_TASK_OPTIONS_DEFAULT } from "../../../constants/tracking_tasks";
import { IOrphan } from "../../interfaces/Orphan";
import { IRouteTracking, ITask } from "../../interfaces/Timeline";
import { RouteData } from "../../interfaces/RoutingPlaning";
import { TrackingGeofence } from "../../interfaces/TrackingGeofence";

// Define a type for the slice state
interface TrackingState {
  grid_items: TrackingGridItem[];
  tracking_modal_info: TrackingModalInfo;
  tracking_filters: TrackingFilter;
  tracking_filters_map_bounds: TrackingFilterMapBounds;
  vehicle_selected: any;
  vehicle_selected_detail: TrackingVehicles | null;
  options: TrackingOptions[];
  filter: string;
  task: any;
  vehicle: any;
  center: any;
  date: string;
  position:{
    lat: number,
    lng: number
  },
  orphans: IOrphan,
  show_routing_form: boolean,
  routing_form_vehicle: {
    id: number,
    license_plate: string
  } | undefined;
  routes: any[] | null;
  route: IRouteTracking[];
  map: RouteData[];
  orphan_data: ITask[];
  geofence: TrackingGeofence[];
  jobs: Array<Array<Partial<ITask>>>;
  jobs_ids: number[];
}

// Define the initial state using that type
const initialState: TrackingState = {
  grid_items: TrackingStorage.getGrid() || TRACKING_GRID_COLUMNS,
  tracking_modal_info: {
    view: null,
    tab: null,
    item: null,
    tabOptions: TRACKING_TASK_OPTIONS_DEFAULT,
  },
  vehicle_selected: TrackingStorage.getVehicleSelected() || null,
  vehicle_selected_detail: null,
  tracking_filters: TrackingStorage.getFilter() || {
    vehicle: [],
    vehicle_type: [],
    driver_status: [],
    driver: [],
    tags: [],
  },
  tracking_filters_map_bounds: TrackingStorage.getFilterMapBounds() || {},
  filter: TrackingStorage.getLocalFilter() || "",
  options: TrackingStorage.getOptions() || TRACKING_OPTIONS,
  task: {},
  vehicle: {},
  center: {},
  date: new Date().toJSON(),
  position: {
    lat: 0,
    lng: 0
  },
  orphans: {
    data:[],
    links: {} as any,
    meta:{} as any
  },
  show_routing_form: false,
  routing_form_vehicle: undefined,
  routes: [],
  route: [],
  map: [],
  orphan_data: [],
  geofence: [],
  jobs: [],
  jobs_ids: []
};

interface ReducerActions extends SliceCaseReducers<TrackingState> {
  updateGridItems(
    state: TrackingState,
    action: PayloadAction<TrackingGridItem[]>
  ): void;
  updateTrackingModalInfo(
    state: TrackingState,
    action: PayloadAction<TrackingModalInfo>
  ): void;
  updateTrackingFilter(
    state: TrackingState,
    action: PayloadAction<TrackingFilter>
  ): void;
  updateTrackingFilterMapBounds(
    state: TrackingState,
    action: PayloadAction<TrackingFilterMapBounds>
  ): void;
  updateTrackingVehicleSelected(
    state: TrackingState,
    action: PayloadAction<any>
  ): void;
  updateTrackingVehicleSelectedDetail(
    state: TrackingState,
    action: PayloadAction<any>
  ): void;
  updateTrackingOptions(
    state: TrackingState,
    action: PayloadAction<TrackingOptions[]>
  ): void;
  updateTrackingLocalFilter(
    state: TrackingState,
    action: PayloadAction<string>
  ): void;
  updateTrackingDate(state: TrackingState, action: PayloadAction<string>): void;
  updateCenterLatLgn(state: TrackingState, action: PayloadAction<any>): void;
  updateOrphans(state: TrackingState, action:PayloadAction<IOrphan>): void;
  updateRoutes(state: TrackingState, action:PayloadAction<any[]>): void;
  updateRoute(state: TrackingState, action:PayloadAction<IRouteTracking[]>): void;
  updateMap(state: TrackingState, action:PayloadAction<RouteData[]>): void;
  updateOrphanData(state: TrackingState, action:PayloadAction<ITask[]>): void;
  updateGeofence(state: TrackingState, action:PayloadAction<TrackingGeofence[]>): void;
  updateJobsIds(state: TrackingState, action:PayloadAction<number[]>): void;
  updateJobs(state: TrackingState, action:PayloadAction<Array<Array<Partial<ITask>>>>): void;
}

export const trakingSlice: Slice<TrackingState, ReducerActions> = createSlice({
  name: "traking",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateGridItems: (state, action: PayloadAction<TrackingGridItem[]>) => {
      state.grid_items = action.payload;
      TrackingStorage.setGrid(action.payload);
    },
    updateTrackingModalInfo: (
      state,
      action: PayloadAction<TrackingModalInfo>
    ) => {
      state.tracking_modal_info = action.payload;
    },
    updateTrackingFilter: (state, action: PayloadAction<TrackingFilter>) => {
      state.tracking_filters = action.payload;
      TrackingStorage.setFilter(action.payload);
    },
    updateTrackingFilterMapBounds: (
      state,
      action: PayloadAction<TrackingFilterMapBounds>
    ) => {
      state.tracking_filters_map_bounds = action.payload;
      TrackingStorage.setFilterMapBounds(action.payload);
    },
    updateTrackingVehicleSelected: (state, action: PayloadAction<any>) => {
      state.vehicle_selected = action.payload;
      TrackingStorage.setVehicleSelected(action.payload);
    },
    updateTrackingVehicleSelectedDetail: (
      state,
      action: PayloadAction<any>
    ) => {
      state.vehicle_selected_detail = action.payload;
      TrackingStorage.setVehicleSelected(action.payload);
    },
    updateTrackingOptions: (
      state,
      action: PayloadAction<TrackingOptions[]>
    ) => {
      state.options = action.payload;
      TrackingStorage.setOptions(action.payload);
    },
    updateTrackingLocalFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
      TrackingStorage.setLocalFilter(action.payload);
    },
    updateTaskView: (state, action: PayloadAction<any>) => {
      state.task = action.payload;
    },
    updateVehicleView: (state, action: PayloadAction<any>) => {
      state.vehicle = action.payload;
    },
    updateTrackingDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    updateCenterLatLgn: (state, action: PayloadAction<any>) => {
      state.position = action.payload;
    },
    updateOrphans: (state, action) => {
      state.orphans = action.payload;
    },
    updateShowRoutingForm: (state, action: PayloadAction<any>) => {
      state.show_routing_form = action.payload
    },
    updateRoutingFormVehicle: (state, action) => {
      state.routing_form_vehicle = { ...action.payload }
    },
    updateRoutes: (state, action: PayloadAction<any>) => {
      state.routes = action.payload
    },
    updateRoute: (state, action: PayloadAction<any>) => {
      state.route = action.payload
    },
    updateMap: (state, action: PayloadAction<any>) => {
      state.map = action.payload
    },
    updateOrphanData: (state, action: PayloadAction<any>) => {
      state.orphan_data = action.payload
    },
    updateGeofence: (state, action: PayloadAction<any>) => {
      state.geofence = action.payload
    },
    updateJobsIds(state, action) {
      state.jobs_ids = action.payload
    },
    updateJobs(state, action) {
      state.jobs = action.payload
    }
  },
});

export const {
  updateGridItems,
  updateTrackingModalInfo,
  updateTrackingFilter,
  updateTrackingFilterMapBounds,
  updateTrackingVehicleSelected,
  updateTrackingVehicleSelectedDetail,
  updateTrackingOptions,
  updateTrackingLocalFilter,
  updateTaskView,
  updateVehicleView,
  updateTrackingDate,
  updateCenterLatLgn,
  updateOrphans,
  updateShowRoutingForm,
  updateRoutingFormVehicle,
  updateRoutes,
  updateRoute,
  updateMap,
  updateGeofence,
  updateOrphanData,
  updateJobsIds,
  updateJobs
} = trakingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getTracking = (state: RootState) => state.tracking;
export const getTrackingFilter = (state: RootState) =>
  state.tracking.tracking_filters;
export const getTrackingFilterMapBounds = (state: RootState) =>
  state.tracking.tracking_filters_map_bounds;
export const getTrackingVehicleSelected = (state: RootState) =>
  state.tracking.vehicle_selected;
export const getTrackingVehicleSelectedDetail = (state: RootState) =>
  state.tracking.vehicle_selected_detail;
export const getTrackingModalInfo = (state: RootState) =>
  state.tracking.tracking_modal_info;
export const getOptions = (state: RootState) => state.tracking.options;
export const getTrackingFilterLocal = (state: RootState) =>
  state.tracking.filter;
export const getTaskView = (state: RootState) => state.tracking.task;
export const getVehicleView = (state: RootState) => state.tracking.vehicle;
export const getTrackingDate = (state: RootState) => state.tracking.date;
export const getCenterLatLgn = (state: RootState) => state.tracking.position;
export const getTrackingOrphans = (state: RootState) => state.tracking.orphans;
export const getShowRoutingForm = (state:RootState) => state.tracking.show_routing_form;
export const getRoutingFormVehicle = (state:RootState) => state.tracking.routing_form_vehicle;
export const getRoutes = (state:RootState) => state.tracking.routes;
export const getRoute = (state:RootState) => state.tracking.route;
export const getMap = (state:RootState) => state.tracking.map;
export const getGeofence = (state:RootState) => state.tracking.geofence;
export const getOrphanData = (state:RootState) => state.tracking.orphan_data;
export const getJobsIds = (state: RootState) => state.tracking.jobs_ids;
export const getJobs = (state: RootState) => state.tracking.jobs;

export default trakingSlice.reducer;
