import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const login = yup
  .object({
    login: yup.string().email().max(130).required(),
    password: yup.string().min(4).max(16).required(),
  })
  .required();

export const LoginResolver = yupResolver(login);
