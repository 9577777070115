export class ColorsUtils {
  static getMapColors() {
    const colors = ["#4092EC", "#FFAE34", "#3DD598", "#DC3545", "#9B51E0"];

    return colors;
  }

  static getColorByIndex(index: number) {
    const colors = this.getMapColors();

    const color_index = index % colors.length;

    // 11

    return colors[color_index];
  }
}
