import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { Sensor, SensorFilter } from "../interfaces/Sensors";

export interface ISetVehicleEntry {
  license_plate: string;
  sensor_id: string;
}
export class SensorsService {
  static async get({
    filter,
    page,
    order,
  }: APiQueryBase): Promise<ApiResponseBase<Sensor[]> | ErrorResult> {
    return await api
      .get("/api/v2/sensors", {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async setVehicle({ license_plate, sensor_id }: ISetVehicleEntry) {
    return await api.post(`/api/v2/sensors/set-vehicle`, {
      license_plate,
      sensor_id,
    });
  }
}

export class SensorService {
  static async get({
    sensor_id,
    page,
    order,
    filter,
  }: SensorFilter): Promise<ApiResponseBase<Sensor[]> | ErrorResult> {
    return await api
      .get(`/api/v2/sensors/${sensor_id}`, {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}

export class SensorChart {
  static async get({
    sensor_id,
    order,
    filter,
  }: SensorFilter): Promise<ApiResponseBase<Sensor[]> | ErrorResult> {
    return await api
      .get(`/api/v2/sensors/get-chart-data`, {
        params: {
          sensor_id: sensor_id,
          order: JSON.stringify(order),
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
