import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { Controller, useForm } from "react-hook-form";

import { VehicleService } from "../../core/services/VehicleService";
import { isErrorResult } from "../../core/utils/api";

import { Sensor } from "../../core/interfaces/Sensors";

import { Vehicle } from "../../core/interfaces/Vehicle";
import { AsyncPaginate } from "react-select-async-paginate";

import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import { FleetMateService } from "../../core/services/FleetMateService";

type FormValues = {
  vehicles: { id: number; label: string; checked: boolean }[];
  vehicle_type: any | null;
  tags: any | null;
  activity: string;
  reason: string;
  period: { from: string; to: string };
  lat: string;
  long: string;
};

interface AssocianteVehicleFormModalProps {
  visible: boolean;
  onClose?(): void;
  onFinishRequest: () => void;
  sensor: Sensor;
}

interface IValues {
  vehicles: Vehicle[];
  selected_vehicles: {
    label: string;
    value: string;
  };
  vehicle_id: number | null;
  isLoading: boolean;
}

const AssociateVehicleAndTagsModal = ({
  selectedGroupId = null,
  onClose,
  visible,
}: any) => {
  const { control, handleSubmit, reset, setValue } = useForm<FormValues>();
  const { t } = useTranslation();
  const [vehicleIds, setVehicleIds] = React.useState<Number[]>([]);
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);
  const [options, setOptions] = useState([]);
  const [initialVehicleOptions, setInitialVehicleOptions] = useState([]);

  const onSubmit = async (data: FormValues) => {
    const filteredData = data?.vehicle_type?.filter(
      (valor: any) => valor !== null && valor !== undefined
    );
    const vehicles_id = filteredData?.map((valor: any) => valor.value);

    const payload = { group_id: Number(selectedGroupId), vehicles_id };

    FleetMateService.linkConfigurationVehicle(payload);
    onClose?.();
    reset();
    setValue("vehicle_type", null);
  };

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Error in loading tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }
  async function loadFleetGroupInfo() {
    if (selectedGroupId) {
      try {
        const _response = await FleetMateService.getGroupConfigsById(
          selectedGroupId
        );

        const _vehiclesIds = _response?.data?.vehicles?.map(
          (vehicle: any) => vehicle?.id
        );

        const vehicleDetails = await Promise.all(
          _vehiclesIds.map(async (id: any) => {
            const vehicle = await VehicleService.getVehicleById(id); // Assumindo que este método exista

            // @ts-ignore
            return { label: vehicle.data.license_plate, value: id }; // Ajuste conforme necessário
          })
        );

        // @ts-ignore
        setInitialVehicleOptions(vehicleDetails);

        setValue("vehicle_type", vehicleDetails);
        setVehicleIds(_vehiclesIds);
        // const isError = isErrorResult(_response);

        // if (!isError && _response.data) {
        //   setVehicleTags(_response.data);
        // }
      } catch (error: any) {
        console.log("Error in loading tags", error.message);
      } finally {
        setLoadingTags(false);
      }
    }
  }

  React.useEffect(() => {
    if (selectedGroupId) {
      loadFleetGroupInfo();
    }
  }, [selectedGroupId, visible]);

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
    }
  }, [visible]);

  const [values, setValues] = useState<IValues>({
    vehicles: [],
    selected_vehicles: {} as any,
    vehicle_id: null,
    isLoading: false,
  });

  function handleCancel() {
    reset();
    onClose?.();
    setValue("vehicle_type", null);
  }
  useEffect(() => {
    const initialState: () => void = async () => {
      const selectedOptions = await options?.map((option: any) => {
        if (vehicleIds.includes(option.value)) {
          return option;
        }
      });
      if (selectedOptions) {
        setValue("vehicle_type", selectedOptions);
      }
    };

    initialState();
  }, [options]);
  return (
    <Modal show={visible} size="lg" centered onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>{t("associante_vehicle_modal_title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pt-0">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-4 mb-3">
            <Form.Group as={Col} lg={12} md={12} controlId="vehicle_type">
              <Form.Label>{t("configs.fleetmate.ei.vehicle_type")}</Form.Label>
              <Controller
                control={control}
                name="vehicle_type"
                render={({ field: { value, onChange, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    isMulti
                    placeholder={t("input.select_text")}
                    loadOptions={async (inputValue, options, additional) =>
                      VehicleService.loadVehicleOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>
          </Row>
          {/* <Row>
            <Form.Group className="mb-4" controlId="name" as={Col}>
              <Form.Label>{t("component.tag_label")}</Form.Label>
              <Controller
                name="tags"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <CustomInputTag
                    defaultSelected={value}
                    onChange={(values: string[]) => onChange(values)}
                    options={vehicleTags?.map((op) => op.key)}
                    isLoading={isLoadingTags}
                  />
                )}
              />
            </Form.Group>
          </Row> */}

          <Row className="mt-5">
            <Stack
              className="d-flex justify-content-end"
              direction="horizontal"
              gap={3}
            >
              <Button
                variant={"primary"}
                disabled={values.isLoading}
                type="submit"
              >
                {t("button.save")}
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AssociateVehicleAndTagsModal;
