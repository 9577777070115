import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionChips from "../../ActionChips";
import CustomThead from "../../CustomThead";
import MapModal from "../../MapModal";
import moment from "moment";
import MapFenceModal from "../../MapFenceModal";

export default function ElectronicFences({ data, summary }: any) {
  const { t } = useTranslation();
  const [sumarized, setSummarized] = useState(undefined);
  const [showMap, setShowMap] = useState(false);
  const [centerMap, setCenterMap] = useState({ lat: 1, lng: 1 });
  function handleShowMap(lat: number, lng: number) {
    setCenterMap({
      lat: lat,
      lng: lng,
    });
    setShowMap(true);
  }

  function checkAction(action: string) {
    switch (action) {
      case "c": {
        return t("configs.audit.create");
      }
      case "u": {
        return t("configs.audit.update");
      }
      case "d": {
        return t("configs.audit.delete");
      }
    }
  }

  useEffect(() => {
    if (typeof data?.registry?.data === "string") {
      const parsedData = JSON.parse(data?.registry?.data);
      setSummarized(parsedData);
    }
  }, [summary]);

  return (
    <>
      <MapFenceModal
        onClose={() => setShowMap(!showMap)}
        visible={showMap}
        handlePoints={
          data?.registry?.data?.setup?.data?.points
            ? data?.registry?.data?.setup?.data?.points
            : data?.registry?.lat
            ? { lat: data?.registry?.lat, lng: data?.registry?.long }
            : sumarized
            ? //@ts-ignore
              { lat: sumarized?.latitude, lng: sumarized?.longitude }
            : {}
        }
        infos={{
          points: data?.registry?.data?.setup?.data?.points
            ? data?.registry?.data?.setup?.data?.points
            : data?.registry?.lat
            ? { lat: data?.registry?.lat, lng: data?.registry?.long }
            : sumarized
            ? //@ts-ignore
              { lat: sumarized?.latitude, lng: sumarized?.longitude }
            : {},
          type: data?.registry?.data?.setup?.data?.type
            ? data?.registry?.data?.setup?.data?.type
            : data?.registry?.type
            ? data?.registry?.type
            : //@ts-ignore
            sumarized?.type
            ? //@ts-ignore
              sumarized?.type
            : {},
        }}
      />
      <div className="item-group p-0">
        <Row>
          <Col>
            <label>{t("configs.audit.event_date_hour")}</label>
            <p>
              {data?.registry?.generated_at
                ? moment(data?.registry?.generated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>

          <Col>
            <div className="d-flex justify-content-between">
              <div>
                <label>{t("configs.audit.user")}</label>
                <p>
                  {data?.user?.name
                    ? data?.user?.name + " " + data?.user?.last_name
                    : "--"}
                </p>
              </div>

              <div className="pt-3">
                {data?.registry?.data?.setup?.data?.points &&
                data?.registry?.data?.setup?.data?.points[
                  data?.registry?.data?.setup?.data?.points?.length - 1
                ]?.latitude ? (
                  <ActionChips
                    backgroundColor="#F2F8FF"
                    color="#007BFF"
                    onClick={() => {
                      handleShowMap(0, 0);
                    }}
                  >
                    {t("text.map_view")}
                  </ActionChips>
                ) : (
                  <ActionChips
                    backgroundColor="#F2F8FF"
                    color="#007BFF"
                    onClick={() => {
                      alert("Coordenadas inválidas");
                    }}
                  >
                    {t("text.map_view")}
                  </ActionChips>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="item-group mb-3">
        <Row>
          <Col>
            <label>{t("configs.audit.electronic_fence_name")}</label>
            <p>
              {data?.registry?.data?.setup?.data?.name
                ? data?.registry?.data?.setup?.data?.name
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.action")}</label>
            <p>{checkAction(data?.event?.action)}</p>
          </Col>
        </Row>
      </div>

      <Row>
        <div className="table-container">
          <Table responsive borderless={false}>
            <CustomThead
              data={[
                { column: "1", name: t("configs.audit.plate"), menu: true },
                { column: "2", name: t("configs.audit.confirmation_datetime") },
              ]}
              onChangeOrder={(event) => console.log(event)}
            />
            <tbody>
              {data?.registry?.data?.setup?.data?.points?.map(
                (item: any, i: number) => (
                  <tr key={i}>
                    <td>
                      {data?.vehicle?.license_plate
                        ? data?.vehicle?.license_plate
                        : "--"}
                    </td>
                    <td>
                      {item?.created_at
                        ? moment(item?.executed_at).format(
                            "DD/MM/YYYY - HH:mm:ss"
                          )
                        : "--"}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
}
