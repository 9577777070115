import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const form = yup
  .object({
    name: yup.string().required(),
    questions: yup.array(
      yup.object({
        name: yup.string().required(),
        // options: yup.array(
        //   yup.object({
        //     name: yup.string().required(),
        //   })
        // ),
      })
    ),
  })
  .required();

export const AppFormResolver = yupResolver(form);
