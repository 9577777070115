import React, { useEffect, CSSProperties } from "react";
import { Breadcrumb, Col, Row, Stack } from "react-bootstrap";
import ErrorModal from "../../components/ErrorModal";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import SuccessModal from "../../components/SuccessModal";
import { useAppDispatch } from "../../core/redux/hooks";
import { updateProfiles } from "../../core/redux/reducer/user";
import { UserService } from "../../core/services/UserService";
import { isErrorResult } from "../../core/utils/api";
import { Container } from "./styles";

interface PrivateMasterPageProps {
  children?: any;
  title?: string;
  breadcrumb?: string[];
  contentStyleContainer?: CSSProperties;
}

const PrivateMasterPage: React.FC<PrivateMasterPageProps> = ({
  children,
  title,
  breadcrumb,
  contentStyleContainer,
  ...props
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([UserService.getProfiles()]).then(([profilesResponse]) => {
      if (!isErrorResult(profilesResponse))
        dispatch(updateProfiles(profilesResponse?.data));
    });
  }, []);

  return (
    <Container fluid {...props}>
      <Row>
        <Col className="sidebar p-0" xs="auto">
          <SideBar />
        </Col>
        <Col className="content-container p-0" xs>
          {title && <NavBar title={title} breadcrumb={breadcrumb} />}
          <div className="content" style={contentStyleContainer}>
            {children}
          </div>
        </Col>
      </Row>

      <SuccessModal />
      <ErrorModal />
    </Container>
  );
};

export default PrivateMasterPage;
