import { api } from ".";
import {
  ConfigRoutingRules,
  ConfigVehicleJourneyRules,
  getConfigRouting,
} from "../interfaces/ConfigRouting";
import { ErrorResult } from "../interfaces/ErrorResult";

export class ConfigRoutingService {
  static async getRoutingRulesVehicleJourney(): Promise<getConfigRouting | ErrorResult> {
    return await api
      .get("/api/v2/company-configurations")
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async updateRoutingRules(
    data: ConfigRoutingRules
  ): Promise<ErrorResult> {
    return await api
      .put("/api/v2/company-configurations/roterization/rules", data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  // static async getVehicleJourneyRules(): Promise<
  //   ConfigVehicleJourneyRules | ErrorResult
  // > {
  //   return await api
  //     .get("/api/v2/roterization/configuration-vehicle-journey-roterization")
  //     .then((r) => r.data)
  //     .catch((e) => e?.response?.data);
  // }

  static async updateVehicleJourneyRules(
    data: ConfigVehicleJourneyRules
  ): Promise<ErrorResult> {
    return await api
      .put(
        "/api/v2/company-configurations/roterization/vehicle-journey",
        data
      )
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
