import { api } from ".";
import { ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { Occurrence } from "../interfaces/Occurrence";

interface GetOccurrence {
  filter?: string;
  page?: number;
}

export class OccurrenceService {
  static async get({
    filter,
    page,
  }: GetOccurrence): Promise<ApiResponseBase<Occurrence> | ErrorResult> {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/occurrence?page=${page}${filterQuery}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(data: Partial<Occurrence>): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/occurrence`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(
    id: number,
    data: Partial<Occurrence>
  ): Promise<ErrorResult> {
    return await api
      .put(`/api/v2/occurrence`, { ...data, occurrence_id: id })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(id: number): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/occurrence`, { data: { occurrence_id: id } })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
