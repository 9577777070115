import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import ManageLicenseModal from "./ManageLicenseModal";
import EditContactModal from "./EditContactModal";
import EditBillingAddressModal from "./EditBillingAddressModal";
import { ManagePlan } from "./ManagePlan";
import { InvoiceHistory } from "./InvoiceHistory";

interface IValues {
  isManageLicenseModalVisible: boolean;
  isEditContactVisible: boolean;
  isEditBillingAddressModalVisible: boolean;
  isBillingViewOpen: boolean;
  isInvoiceHistoryViewOpen: boolean;
}

export const ConfigsBilling = () => {
  const { t, i18n } = useTranslation();
  const [currency, setCurrency] = useState("USD");

  console.log(currency);
  function currencyFormatter(value: number) {
    return new Intl.NumberFormat(currency, {
      style: "currency",
      currency,
    }).format(value);
  }

  const [values, setValues] = useState({
    isManageLicenseModalVisible: false,
    isEditContactVisible: false,
    isEditBillingAddressModalVisible: false,
    isBillingViewOpen: false,
    isInvoiceHistoryViewOpen: false,
  } as IValues);

  function updateValues({ name, value }: { name: string; value: any }) {
    setValues({
      ...values,
      [name]: value,
    });
  }

  useEffect(() => {
    if (i18n.language === "en") {
      setCurrency("USD");
    }
    if (i18n.language === "pt") {
      setCurrency("BRL");
    }
    if (i18n.language === "fr") {
      setCurrency("EUR");
    }
  }, [i18n.language]);

  if (values.isBillingViewOpen) {
    return (
      <ManagePlan
        onClickBack={() =>
          updateValues({ name: "isBillingViewOpen", value: false })
        }
      />
    );
  }

  if (values.isInvoiceHistoryViewOpen) {
    return (
      <InvoiceHistory
        onClickBack={() =>
          updateValues({ name: "isInvoiceHistoryViewOpen", value: false })
        }
      />
    );
  }

  return (
    <Container className="px-0">
      <Row>
        <Col xs={8} className="pr-4">
          <h4 className="title">FleetDesk LLC</h4>

          <div className="mt-5">
            <strong>{t("configs.menu.your_plan")}</strong>
          </div>

          <Row className="mt-5 divider-b pb-5">
            <Col xs={6}>
              <span className="heading__5">Advanced</span>
              <p className="my-2 content">
                2x {t("configs.menu.licences")} x {currencyFormatter(29.9)}{" "}
                {t("configs.menu.by_licences")}{" "}
              </p>

              <button
                className="action"
                onClick={() =>
                  updateValues({
                    name: "isManageLicenseModalVisible",
                    value: true,
                  })
                }
              >
                {t("configs.menu.manage_licenses")}
              </button>
            </Col>
            <Col xs={6} className="text-right">
              <span className="heading__5">
                {currencyFormatter(29.9)} / {t("configs.menu.monthly")}
              </span>
              <p className="my-2 content">
                {t("configs.menu.by_active_driver")}
              </p>

              <button
                className="action"
                onClick={() => {
                  updateValues({
                    name: "isBillingViewOpen",
                    value: true,
                  });
                }}
              >
                {t("configs.menu.manage_plan")}
              </button>
            </Col>
          </Row>

          <div className="mt-5">
            <strong>Add-ons</strong>
          </div>

          <div className="d-flex flex-column gap-3 mt-4">
            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.billing_iridium_connectivity")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(90)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.billing_connectivity")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(10)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.journey_control")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(5)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.driving_behavior")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(10)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.advanced_library")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(5)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.video_monitoring")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(35)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.sensores_bluetooth")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(10)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.routing_backoffice")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(0.2)}/ {t("configs.menu.monthly")}
              </p>
            </div>

            <div className="w-100 d-flex gap-3 justify-content-between align-items-center">
              <Form.Group controlId="allow_vehicle_charging">
                <Form.Check
                  id="allow_vehicle_charging"
                  type="switch"
                  label={
                    <label htmlFor="allow_vehicle_charging">
                      {t("configs.menu.sms_trigger")}{" "}
                    </label>
                  }
                  onChange={(e) => console.log(e)}
                />
              </Form.Group>

              <p className="heading__5 my-0">
                {currencyFormatter(0.1)}/ {t("configs.menu.by")} SMS
              </p>
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <h4 className="billing_title">
            {t("configs.menu.estimate_of_the_next_invoice")}
          </h4>

          <p className="content my-4">21 de Março, 2024</p>

          <div className="d-flex gap-3 align-items-center divider-b pb-3">
            <strong className="spotlight_title">
              {currencyFormatter(159.9)}
            </strong>
            <p className="heading__5 line-text-decoration-line-through my-0">
              R$ {currencyFormatter(319.8)}
            </p>
          </div>

          <div className="mt-5">
            <strong>{t("configs.menu.last_invoice")}</strong>
          </div>

          <Row className="my-3">
            <Col>
              <p>21 de Fevereiro, 2024</p>
            </Col>
            <Col>
              <div className="d-flex gap-2 justify-content-end">
                <p className="content m-0 flex-initial">
                  {currencyFormatter(79.8)}
                </p>
                <a href="#" className="link">
                  PDF
                </a>
              </div>
            </Col>
          </Row>

          <button
            className="link text-center d-block"
            onClick={() =>
              updateValues({ name: "isInvoiceHistoryViewOpen", value: true })
            }
          >
            {t("configs.menu.invoice_date")}
          </button>

          <div className="mt-5 d-flex justify-content-between">
            <strong>{t("configs.menu.payment_method")}</strong>
            <TbEdit size={24} />
          </div>

          <div className="mt-5 d-flex justify-content-between">
            <img src="" />

            <div className="d-flex gap-3">
              <p className="content m-0 flex-initial">**** 3298</p>
              <p className="content m-0 flex-initial">02/26*</p>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-between">
            <strong>{t("configs.menu.invoice_contact")}</strong>
            <button
              className="button"
              onClick={() =>
                updateValues({ name: "isEditContactVisible", value: true })
              }
            >
              <TbEdit size={24} />
            </button>
          </div>

          <div className="mt-3 d-flex flex-column gap-2">
            <p className="content m-0">João Gustavo Souza</p>
            <p className="content">financas@acmestudio.com</p>
          </div>

          <div className="mt-5 d-flex justify-content-between">
            <strong>{t("configs.menu.billing_address")}</strong>
            <button
              className="button"
              onClick={() => {
                updateValues({
                  name: "isEditBillingAddressModalVisible",
                  value: true,
                });
              }}
            >
              <TbEdit size={24} />
            </button>
          </div>

          <div className="mt-3 d-flex flex-column gap-2">
            <p className="content w-300">
              FleetDesk LLC United States, 3280 HABERSHAM ROAD NW, Atlanta, GA,
              30305-520
            </p>
          </div>
        </Col>
      </Row>

      <ManageLicenseModal
        visible={values.isManageLicenseModalVisible}
        onClose={() =>
          updateValues({ name: "isManageLicenseModalVisible", value: false })
        }
        onSuccess={() => console.log("")}
      />

      <EditContactModal
        visible={values.isEditContactVisible}
        onClose={() =>
          updateValues({ name: "isEditContactVisible", value: false })
        }
        onSuccess={() => console.log("")}
      />

      <EditBillingAddressModal
        visible={values.isEditBillingAddressModalVisible}
        onClose={() =>
          updateValues({
            name: "isEditBillingAddressModalVisible",
            value: false,
          })
        }
        onSuccess={() => console.log("")}
      />
    </Container>
  );
};
