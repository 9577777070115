import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { string } from "yup";
import { TRACKING_GRID_COLUMNS } from "../../../constants/tracking_grid";
import { TRACKING_OPTIONS } from "../../../constants/tracking_options";
import { SelectOption } from "../../interfaces/SelectOption";
import {
  TrackingFilter,
  TrackingFilterMapBounds,
} from "../../interfaces/TrackingFilter";
import { TrackingGridItem } from "../../interfaces/TrackingGridItem";
import { TrackingModalInfo } from "../../interfaces/TrackingModalInfo";
import { TrackingOptions } from "../../interfaces/TrackingOptions";
import { TrackingVehicles } from "../../interfaces/TrackingVehicles";
import { PlanRoutingStorage } from "../../storage/plan_routing_storage";
import type { RootState } from "../store";
import { TRACKING_TASK_OPTIONS_DEFAULT } from "../../../constants/tracking_tasks";
import { RoutingModalInfo } from "../../interfaces/RoutingModalInfo";
import { ROUTING_TASK_OPTIONS_DEFAULT } from "../../../constants/routing_tasks";
import { RoutingFilter } from "../../interfaces/RoutingPlaning";

// Define a type for the slice state
interface PlanRouting {
  grid_items: TrackingGridItem[];
  plan_routing_modal_info: any;
  plan_routing_filters: RoutingFilter;
  plan_routing_filters_map_bounds: TrackingFilterMapBounds;
  vehicle_selected: any;
  vehicle_selected_detail: TrackingVehicles | null;
  options: TrackingOptions[];
  filter: string;
  task: any;
  vehicle: any;
  center: any;
  date: string;
  position: {
    lat: number;
    lng: number;
  };
  index: number;
}

// Define the initial state using that type
const initialState: PlanRouting = {
  grid_items: PlanRoutingStorage.getGrid() || TRACKING_GRID_COLUMNS,
  plan_routing_modal_info: {
    view: null,
    tab: null,
    item: {
      task_id: 0,
      route_id: 0
    },
    tabOptions: ROUTING_TASK_OPTIONS_DEFAULT,
  },
  vehicle_selected: PlanRoutingStorage.getVehicleSelected() || null,
  vehicle_selected_detail: null,
  plan_routing_filters: PlanRoutingStorage.getFilter() || {
    vehicle: [],
    vehicle_type: [],
    driver_status: [],
    driver: [],
    tags: [],
    date: {
      from: '',
      to: ''
    }
  },
  plan_routing_filters_map_bounds:
    PlanRoutingStorage.getFilterMapBounds() || {},
  filter: PlanRoutingStorage.getLocalFilter() || "",
  options: PlanRoutingStorage.getOptions() || TRACKING_OPTIONS,
  task: {},
  vehicle: {},
  center: {},
  date: new Date().toJSON(),
  position: {
    lat: 0,
    lng: 0,
  },
  index: 0,
};

interface ReducerActions extends SliceCaseReducers<PlanRouting> {
  updatePlanRoutingTasksIndex(
    state: PlanRouting,
    action: PayloadAction<any>
  ): void;
  updatePlanRoutingGridItems(
    state: PlanRouting,
    action: PayloadAction<TrackingGridItem[]>
  ): void;
  updatePlanRoutingModalInfo(
    state: PlanRouting,
    action: PayloadAction<any>
  ): void;
  updatePlanRoutingFilter(
    state: PlanRouting,
    action: PayloadAction<any>
  ): void;
  updatePlanRoutingFilterMapBounds(
    state: PlanRouting,
    action: PayloadAction<TrackingFilterMapBounds>
  ): void;
  updatePlanRoutingVehicleSelected(
    state: PlanRouting,
    action: PayloadAction<any>
  ): void;
  updatePlanRoutingVehicleSelectedDetail(
    state: PlanRouting,
    action: PayloadAction<any>
  ): void;
  updatePlanRoutingOptions(
    state: PlanRouting,
    action: PayloadAction<TrackingOptions[]>
  ): void;
  updatePlanRoutingLocalFilter(
    state: PlanRouting,
    action: PayloadAction<string>
  ): void;
  updatePlanRoutingDate(
    state: PlanRouting,
    action: PayloadAction<string>
  ): void;
  updatePlanRoutingCenterLatLgn(
    state: PlanRouting,
    action: PayloadAction<any>
  ): void;
}

export const planRoutingSlice: Slice<PlanRouting, ReducerActions> = createSlice(
  {
    name: "PlanRouting",
    initialState,
    reducers: {
      updatePlanRoutingTasksIndex: (state, action: PayloadAction<any>) => {
        state.index = action.payload;
        PlanRoutingStorage.setIndex(action.payload);
      },

      updatePlanRoutingGridItems: (
        state,
        action: PayloadAction<TrackingGridItem[]>
      ) => {
        state.grid_items = action.payload;
        PlanRoutingStorage.setGrid(action.payload);
      },
      updatePlanRoutingModalInfo: (
        state,
        action: PayloadAction<RoutingModalInfo>
      ) => {
        state.plan_routing_modal_info = action.payload;
      },
      updatePlanRoutingFilter: (
        state,
        action: PayloadAction<RoutingFilter>
      ) => {
        state.plan_routing_filters = action.payload;
        PlanRoutingStorage.setFilter(action.payload);
      },
      updatePlanRoutingFilterMapBounds: (
        state,
        action: PayloadAction<TrackingFilterMapBounds>
      ) => {
        state.plan_routing_filters_map_bounds = action.payload;
        PlanRoutingStorage.setFilterMapBounds(action.payload);
      },
      updatePlanRoutingVehicleSelected: (state, action: PayloadAction<any>) => {
        state.vehicle_selected = action.payload;
        PlanRoutingStorage.setVehicleSelected(action.payload);
      },
      updatePlanRoutingVehicleSelectedDetail: (
        state,
        action: PayloadAction<any>
      ) => {
        state.vehicle_selected_detail = action.payload;
        PlanRoutingStorage.setVehicleSelected(action.payload);
      },
      updatePlanRoutingOptions: (
        state,
        action: PayloadAction<TrackingOptions[]>
      ) => {
        state.options = action.payload;
        PlanRoutingStorage.setOptions(action.payload);
      },
      updatePlanRoutingLocalFilter: (state, action: PayloadAction<string>) => {
        state.filter = action.payload;
        PlanRoutingStorage.setLocalFilter(action.payload);
      },
      updatePlanRoutingTaskView: (state, action: PayloadAction<any>) => {
        state.task = action.payload;
      },
      updatePlanRoutingVehicleView: (state, action: PayloadAction<any>) => {
        state.vehicle = action.payload;
      },
      updatePlanRoutingDate: (state, action: PayloadAction<string>) => {
        state.date = action.payload;
      },
      updatePlanRoutingCenterLatLgn: (state, action: PayloadAction<any>) => {
        state.position = action.payload;
      },
    },
  }
);

export const {
  updatePlanRoutingTasksIndex,
  updatePlanRoutingGridItems,
  updatePlanRoutingModalInfo,
  updatePlanRoutingFilter,
  updatePlanRoutingFilterMapBounds,
  updatePlanRoutingVehicleSelected,
  updatePlanRoutingVehicleSelectedDetail,
  updatePlanRoutingOptions,
  updatePlanRoutingLocalFilter,
  updatePlanRoutingTaskView,
  updatePlanRoutingVehicleView,
  updatePlanRoutingDate,
  updatePlanRoutingCenterLatLgn,
} = planRoutingSlice.actions;

export const getPlanRoutingTasksIndex = (state: RootState) =>
  state.plan_routing.index;
export const getPlanRouting = (state: RootState) => state.plan_routing;
export const getPlanRoutingFilter = (state: RootState) =>
  state.plan_routing.plan_routing_filters;
export const getPlanRoutingFilterMapBounds = (state: RootState) =>
  state.plan_routing.plan_routing_filters_map_bounds;
export const getPlanRoutingVehicleSelected = (state: RootState) =>
  state.plan_routing.vehicle_selected;
export const getPlanRoutingVehicleSelectedDetail = (state: RootState) =>
  state.plan_routing.vehicle_selected_detail;
export const getPlanRoutingGeofenceSelected = (state: RootState) =>
  state.tracking.options.find(x => x.id === "4")?.checked ?? false 
export const getPlanRoutingModalInfo = (state: RootState) =>
  state.plan_routing.plan_routing_modal_info;
export const getPlanRoutingOptions = (state: RootState) =>
  state.tracking.options;
export const getPlanRoutingFilterLocal = (state: RootState) =>
  state.plan_routing.filter;
export const getPlanRoutingTaskView = (state: RootState) =>
  state.plan_routing.task;
export const getPlanRoutingVehicleView = (state: RootState) =>
  state.plan_routing.vehicle;
export const getPlanRoutingDate = (state: RootState) => state.plan_routing.date;
export const getPlanRoutingCenterLatLgn = (state: RootState) =>
  state.plan_routing.position;

export default planRoutingSlice.reducer;
