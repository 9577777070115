import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Modal = styled(RBModal)`
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-content {
    min-width: 400px;
    padding: 28px 34px;
  }

  .modal-header {
    padding: 0;
    margin-bottom: 20px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0;

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      width: fit-content;
      text-decoration: underline;

      :hover {
        color: rgba(0, 98, 255);
      }
    }

    h5 {
      font-size: 18px;
      color: #6c757d;
    }
  }
`;
