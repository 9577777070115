import { VEHICLE_SENSORS } from "./vehicle_sensors";

export interface TrackingGridProps {
  _id: number;
  label: string;
  value: boolean;
}

const GRID_COLUMNS: TrackingGridProps[] = [
  {
    _id: 1,
    label: "traking.table_timeline_tasks",
    value: true,
  },
  {
    _id: 2,
    label: "traking.table_route",
    value: false,
  },
  {
    _id: 3,
    label: "traking.table_plate",
    value: true,
  },
  {
    _id: 4,
    label: "traking.table_status",
    value: true,
  },
  {
    _id: 5,
    label: "traking.table_battery",
    value: true,
  },
  {
    _id: 11,
    label: "traking.table_macaddress",
    value: false,
  },
  {
    _id: 12,
    label: "traking.table_location",
    value: true,
  },
  {
    _id: 6,
    label: "traking.table_driving_time",
    value: false,
  },
  {
    _id: 7,
    label: "traking.table_time_stoped",
    value: false,
  },
  {
    _id: 8,
    label: "traking.table_idle_engine_time",
    value: false,
  },
  {
    _id: 9,
    label: "traking.table_address",
    value: true,
  },
  {
    _id: 10,
    label: "traking.table_speed",
    value: false,
  },
  {
    _id: 13,
    label: "traking.table_last_date_comunication_vehicle",
    value: false,
  },
  {
    _id: 14,
    label: "traking.table_last_date_update_gps_vehicle",
    value: true,
  },
  {
    _id: 15,
    label: "traking.table_options",
    value: false
  },
  {
    _id: 16,
    label: "traking.table_route_description", 
    value: false
  },
  {
    _id: 16,
    label: "traking.table_geofence", 
    value: false
  }
];

const TRACKING_GRID_COLUMNS: TrackingGridProps[] = [
  ...GRID_COLUMNS,
  ...Object.keys(VEHICLE_SENSORS).map((item, index) => ({
    _id: 15 + index,
    label: "traking.table_" + item?.toLowerCase(),
    value: false,
  })),
];

const TRAKING_ROUTING_GRID_COLUMNS: TrackingGridProps[] = [
  {
    _id: 0,
    label: "traking.table_timeline_tasks",
    value: true,
  },
  {
    _id: 1,
    label: "traking.table_route",
    value: true,
  },
  {
    _id: 2,
    label: "traking.table_plate",
    value: true,
  },
  {
    _id: 3,
    label: "traking.table_km",
    value: true,
  },
  {
    _id: 4,
    label: "traking.task_review_volume_label",
    value: true,
  },
  {
    _id: 5,
    label: "traking.task_review_weight_label",
    value: true,
  },
  {
    _id: 6,
    label: "traking.task_review_value_label",
    value: true,
  },
  {
    _id: 16,
    label: "traking.table_route_description", 
    value: false
  }
];

export { TRACKING_GRID_COLUMNS, TRAKING_ROUTING_GRID_COLUMNS };
