import styled from "styled-components";
import ReactPhoneNumberInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const PhoneInput = styled(ReactPhoneNumberInput)`
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: 0.35em;
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 1;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 0.9em;
  --PhoneInputCountryFlag-borderWidth: 0;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);

  .PhoneInputCountry {
    border: 1px solid #ced4da;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    padding-left: 0.75rem;
    padding-right: 0.35rem;
    background: #e9ecef;
  }

  .PhoneInputInput {
    flex: 1 1;
    border-color: #ced4da;
    padding: 0.375rem 0.75rem;
    color: #212529;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-left-width: 0;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 1;

    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
      outline: none;
    }
  }
`;
