import styled from "styled-components";

export const Container = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  width: fit-content;

  .icon {
    align-items: center;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.09);
    display: flex;
    filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.1));
    height: 40px;
    justify-content: center;
    margin-right: 10px;
    min-height: 40px;
    min-width: 40px;
    width: 40px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

export const Title = styled.h5`
  color: #6c757d;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
`;
