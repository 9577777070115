import { Locale } from "../interfaces/Locale";
import { User } from "../interfaces/User";
import { LocalStorageUtils } from "../utils/local_storage";

export class SessionStorage extends LocalStorageUtils {
  static KEY = "@Session";
  static TOKEN_KEY = `${this.KEY}:token`;
  static USER_KEY = `${this.KEY}:user`;
  static LOCALES_KEY = `${this.KEY}:locales`;
  static LANGUAGE_KEY = `${this.KEY}:language`;

  static getUser(): User {
    return this.getItem(this.USER_KEY);
  }

  static getLanguage(): string {
    return this.getItem(this.LANGUAGE_KEY);
  }

  static getToken(): string {
    return this.getItem(this.TOKEN_KEY);
  }

  static getLocales(): Array<Locale> {
    return this.getItem(this.LOCALES_KEY);
  }

  static setUser(user: User) {
    this.setItem(this.USER_KEY, user);
  }
  
  static setLanguage(language: any) {
    this.setItem(this.LANGUAGE_KEY, language);
  }

  static setToken(token: string) {
    this.setItem(this.TOKEN_KEY, token);
  }

  static setLocales(locales: Array<Locale>) {
    this.setItem(this.LOCALES_KEY, locales);
  }

  static removeUser() {
    this.removeItem(this.USER_KEY);
  }

  static removeToken() {
    this.removeItem(this.TOKEN_KEY);
  }

  static removeLocales() {
    this.removeItem(this.LOCALES_KEY);
  }
}
