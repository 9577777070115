import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 236px;
  background-color: #f6f6f6;
  padding-top: 27px;

  .menu-item {
    display: flex;
    height: 38px;
    align-items: center;
    padding-left: 15px;
    margin-left: 10px;
    margin-bottom: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: #fff;
      box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.17);
    }
  }
`;
