import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { isErrorResult } from "../../core/utils/api";
import { VehicleTagService } from "../../core/services/VehicleTagService";
import { IVehicleTag } from "../../core/interfaces/VehicleTag";
import { VehicleService } from "../../core/services/VehicleService";
import { APiQueryBase } from "../../core/interfaces/ApiResponseBase";
import { SecuryRulesGroup } from "../../core/services/SecuryRulesGroup";
import { FiDownload } from "react-icons/fi";
import { Controller, set, useForm } from "react-hook-form";
import { auditReports } from "../../core/schemaValidations/reports";
import { IReportsVehicles } from "../../core/interfaces/Vehicle";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import SuccessModal from "../SuccessModal";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../core/redux/hooks";
import { updateAlert } from "../../core/redux/reducer/alert";
import DropdownSearchAndCheck from "../DropdownSearchAndCheck";
import { useAudit } from "../../core/hooks/useAudit";
import moment from "moment";
import { AuditService } from "../../core/services/AuditService";
import { AuditEvent } from "../../core/interfaces/Audit";
import { AsyncPaginate } from "react-select-async-paginate";

export type VehicleFilterEvent = {
  vehicle_type: number;
  tags: IVehicleTag[];
};

interface FleetMateVehicleAssociationModalProps {
  visible: boolean;
  onClose?(): void;
  id?: number;
}

const AuditReportModal: React.FC<FleetMateVehicleAssociationModalProps> = ({
  onClose,
  visible,
  id,
  ...props
}) => {
  const { t } = useTranslation();
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [vehicles, setVehicles] = React.useState<any[]>([]);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);
  const [tags, setTags] = React.useState<string[]>([]);
  const [vehiclesId, setVehiclesId] = React.useState<any[]>([]);
  const [filterVehicles, setFilterVehicles] = React.useState<string>("");
  const [successModal, setSuccessModal] = React.useState<boolean>(false);
  const [minEndDate, setMinEndDate] = React.useState<string>("");
  const [maxEndDate, setMaxEndDate] = React.useState<any>("");
  const [dateFrom, setDateFrom] = React.useState<string>("");
  const [dateTo, setDateTo] = React.useState<string>("");
  const [vehicle_Id, setVehicle_Id] = React.useState<any>();
  const minDateFrom = `2021-01-01T00:00`;

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const maxDateFrom = `${year}-${month}-${day}T00:00`;
  const [minDateTo, setMinDateTo] = useState<string>();
  const [maxDateTo, setMaxDateTo] = useState<string>();
  const [auditEvents, setAuditEvents] = useState<AuditEvent[]>();

  function handleMaxDateTo() {
    if (minDateTo) {
      setMaxDateTo(
        moment(minDateTo).add(30, "days").format("YYYY-MM-DDTHH:mm")
      );
    }
  }

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<IReportsVehicles>({
    resolver: auditReports,
  });

  const handleCancel = () => {
    setTags([]);
    setDateFrom("");
    setDateTo("");
    setMaxEndDate("");
    setVehicle_Id("");
    reset?.();
    onClose?.();
  };

  const dispatch = useAppDispatch();

  async function handleReports(values: IReportsVehicles) {
    AuditService.postDownload({
      date_from: getValues("date_from"),
      date_to: getValues("date_to"),
      event_ids: getValues("event_ids"),
      vehicle_id: getValues("vehicle_id"),
    })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
    reset?.();
  }

  async function loadVehicleTags() {
    if (isLoadingTags) return;
    setLoadingTags(true);

    try {
      const _response = await VehicleTagService.getVehicleTags({
        filter: "",
        page: 1,
      });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        setVehicleTags(_response.data);
      }
    } catch (error: any) {
      console.log("Erro in load tags", error.message);
    } finally {
      setLoadingTags(false);
    }
  }

  function onDateFrom(text: any): void {
    setDateFrom(text);
  }
  function onDateTo(text: any): void {
    setDateTo(text);
  }

  function thirtyDays(text: string): void {
    const teste = new Date(text).getDate() + 30;

    if (new Date(teste) >= new Date()) {
      setMaxEndDate(new Date().toDateString());
      console.log(true, teste);
    } else {
      console.log(false, teste);

      setMaxEndDate(new Date(text)?.getDate() + 30);
    }
  }

  async function loadVehicles({ filter = "", page = 1 }: APiQueryBase) {
    try {
      const _response = await VehicleService.get({});

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        const resp = _response.data?.map((item) => ({
          id: item.id,
          label: item.license_plate,
          checked: false,
        }));
        setVehicles(resp);
      }
    } catch (error: any) {
      console.log("Erro in load vehicle", error.message);
    }
  }

  async function loadAuditEvents() {
    try {
      const _response = (await AuditService.getEvents({})) as AuditEvent[];

      const isError = isErrorResult(_response);

      if (!isError && _response) {
        _response.sort((a, b) => a.name.localeCompare(b.name));

        setAuditEvents(_response);
      }
    } catch (error) {}
  }

  const [types, setTypes] = React.useState(
    auditEvents?.map((item, i) => ({
      label: item.name,
      value: item.id,
      id: item.id,
      checked: false,
      index: i,
    }))
  );

  function downloadReport() {
    if (
      getValues("date_from") !== null &&
      getValues("date_from") !== undefined &&
      getValues("date_to") !== null &&
      getValues("date_to") !== undefined &&
      getValues("event_ids") !== null &&
      getValues("event_ids") !== undefined &&
      getValues("vehicle_id") !== null &&
      getValues("vehicle_id") !== undefined
    ) {
      AuditService.postDownload({
        date_from: getValues("date_from"),
        date_to: getValues("date_to"),
        //@ts-ignore
        event_ids: getValues(`event_ids.${0}.value`),
        //@ts-ignore
        vehicle_id: getValues(`vehicle_id.value`),
      })
        .then((r) =>
          dispatch(
            updateAlert({ title: t("configs.menu.audit.download_modal") })
          )
        )
        .catch((e) => e?.response?.data);
    } else {
      alert("Preencha todos os dados corretamente.");
    }
  }

  useEffect(() => {
    reset?.();
    onClose?.();
  }, [successModal]);

  React.useEffect(() => {
    if (visible) {
      loadVehicleTags();
    }
  }, [visible]);

  useEffect(() => {
    if (visible === false) return;

    loadAuditEvents();
    setTypes(
      auditEvents?.map((item, i) => ({
        label: item.name,
        value: item.id,
        id: item.id,
        checked: false,
        index: i,
      }))
    );
  }, [visible]);

  React.useEffect(() => {
    handleMaxDateTo();
  }, [minDateTo]);

  return (
    <Modal {...props} show={visible} size="md" centered onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title style={{ alignItems: "center", justifyContent: "center" }}>
          <FiDownload style={{ marginRight: "20px" }} />
          Baixar relatório
        </Modal.Title>
      </Modal.Header>

      <SuccessModal onClose={() => successModal} />

      <Modal.Body as={Form} onSubmit={handleSubmit(handleReports)}>
        <Row className="mt-5 mb-3">
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label>{t("configs.fleetmate.ei.vehicle")}</Form.Label>
              <Controller
                control={control}
                name="vehicle_id"
                render={({ field: { value, onChange, onBlur } }) => (
                  <AsyncPaginate
                    isClearable
                    placeholder={t("input.select_text")}
                    loadOptions={(inputValue, options, additional) =>
                      VehicleService.loadVehicleOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label>
                {t("configs.menu.audit.filter_modal.select_type")}
              </Form.Label>
              {visible === true ? (
                <Controller
                  control={control}
                  name="event_ids"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <AsyncPaginate
                      isClearable
                      isMulti
                      placeholder={t("input.select_text")}
                      loadOptions={(inputValue, options, additional) =>
                        AuditService.getEventsOptions(
                          inputValue,
                          options,
                          additional
                        )
                      }
                      additional={{ page: 1 }}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Form.Group className="mb-3" controlId="name" as={Row}>
            <Col lg={6} md={12}>
              <Form.Label>{t("configs.audit.filter_start_date")}</Form.Label>
              <Controller
                control={control}
                name="date_from"
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    style={{ width: "271px" }}
                    type="datetime-local"
                    onChange={(e) => {
                      onDateFrom(e?.target?.value);
                      onDateFrom(e?.target?.value);
                      setMinDateTo(e.target.value);
                      onChange(e?.target?.value);
                    }}
                    value={dateFrom}
                    min={minDateFrom}
                    max={maxDateFrom}
                    className="form-control p-1"
                  />
                )}
              />
            </Col>
            <Col lg={6} md={12}>
              <Form.Label style={{ marginLeft: "12px" }}>
                {t("configs.audit.filter_end_date")}
              </Form.Label>
              <Controller
                control={control}
                name="date_to"
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    style={{ width: "271px", marginLeft: "12px" }}
                    type="datetime-local"
                    onChange={(e) => {
                      onDateTo(e?.target?.value);
                      onChange(e?.target?.value);
                    }}
                    value={dateTo}
                    min={minDateTo}
                    max={maxDateTo}
                    disabled={
                      minDateTo === undefined ||
                      minDateTo === null ||
                      minDateTo === ""
                        ? true
                        : false
                    }
                    className="form-control p-1"
                  />
                )}
              />
            </Col>
          </Form.Group>
        </Row>

        <Row className="mt-2">
          <Stack
            className="d-flex justify-content-end"
            direction="horizontal"
            gap={3}
          >
            <Button type="submit" onClick={downloadReport}>
              {isSubmitting ? t("status.saving") : t("button.save")}
              {isSubmitting && <Spinner animation="border" size="sm" />}
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AuditReportModal;
