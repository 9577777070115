import React from "react";
import { Button, Form, Modal, Spinner, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserProfileService } from "../../core/services/UserProfileService";
import ProfileFieldArray from "./profileFieldArray";
import { updateAlert } from "../../core/redux/reducer/alert";
import { useDispatch } from "react-redux";
import { useAppSelector, useAppDispatch } from "../../core/redux/hooks";
import { getProfiles } from "../../core/redux/reducer/user";

interface EditAccessProfileModalProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}

export type FormValues = {
  profiles: {
    id?: number;
    profile_name: string;
    user_access_modules?: {
      id: number;
      module_name: string;
      view: number;
      edit: number;
      security: number;
      commands: number;
      delete: number;
      show_command?: number;
      show_delete?: number;
      pivot?: {
        commands: number;
        company_id: number;
        edit: number;
        user_access_module_id: number;
        user_profile_id: number;
        view: number;
        delete: number;
      };
      isEdited?: boolean;
    }[];
  }[];
};

const EditAccessProfileModal: React.FC<EditAccessProfileModalProps> = ({
  visible = false,
  onClose,
  onSuccess,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profiles = useAppSelector(getProfiles);

  console.log(profiles);
  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  async function onSubmit({ profiles }: FormValues) {
    try {
      for await (let data of profiles) {
        if (!!data.id && !!data.user_access_modules) {
          for await (let accessModule of data?.user_access_modules) {
            if (accessModule.isEdited) {
              const response = await UserProfileService.updateAccessModule({
                user_profile_id: data.id,
                user_access_module_id: accessModule.id,
                ...accessModule,
              });

              if (!response?.success) throw Error(response?.message);
            }
          }
        }
      }

      // reset();
      onSuccess?.();
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: "Erro ao salvar os dados!",
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleClose = () => {
    // reset();
    onClose?.();
  };

  return (
    <Modal
      {...props}
      show={visible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header className="border-0 p-4 pb-0 pt-3" closeButton>
        <Modal.Title>{t("configs.user.access_profile.title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pb-3 pt-3">
        <Form noValidate onSubmit={handleSubmit(onSubmit, console.warn)}>
          <ProfileFieldArray
            control={control}
            register={register}
            setValue={setValue}
          />

          <Stack
            direction="horizontal"
            gap={2}
            style={{ justifyContent: "end" }}
          >
            <Button
              id="on-submit-access-profile"
              variant="primary"
              type="submit"
            >
              {isSubmitting ? t("status.saving") : t("button.save")}
              {isSubmitting && <Spinner animation="border" size="sm" />}
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              {t("button.cancel")}
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditAccessProfileModal;
