import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  .pr-4 {
    padding-right: 56px;
  }
  .title {
    color: #6c757d;
    font-weight: 500;
  }

  .billing_title {
    color: #000000;
    font-size: 18px;
  }

  strong {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
  }

  .text-right {
    text-align: right;
  }

  .heading__5 {
    color: #6c757d;
    font-weight: 500;
    font-size: 20px;
  }

  .line-text-decoration-line-through {
    text-decoration: line-through;
  }

  .content {
    color: #6c757d;
    font-weight: 500;
    font-size: 16px;
  }

  .flex-initial {
    flex: initial;
  }

  .link {
    color: #2f80ed;
    font-size: 16px;
    margin: 0;
    border: 0;
    text-decoration: underline;
    background: none;
  }

  .spotlight_title {
    color: #6c757d;
    font-weight: 500;
    font-size: 28px;
  }

  .w-300 {
    max-width: 300px;
  }

  .button {
    border: 0;
    background: 0;
    color: #6c757d;
  }

  .action {
    color: #2f80ed;
    text-decoration: underline;
    border: 0;
    padding: 0;
    background: none;
  }

  .divider-b {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const Card = styled.div`
  margin-top: 28px;
  .card__title {
    font-size: 28px;
    font-weight: 500;
    display: block;
    text-align: center;
  }

  .card__plan-price {
    margin: 40px 0;
  }

  .card__price {
    color: #6c757d;
    font-size: 20px;
    text-align: center;
    display: block;
  }

  .card__price b {
    color: #28a745;
    font-size: 28px;
    font-weight: 500;
  }

  .card__compare-price {
    color: #6c757d;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-decoration: line-through;
  }

  .card_main-features {
    font-size: 20px;
    display: block;
    margin-top: 40px;
    margin-bottom: 28px;
    text-align: center;
  }

  .card_features-list {
    color: #6c757d;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`;

export const CardPlanButton = styled.button<{ $isActivePlan: boolean }>`
  background: ${(props) => (props.$isActivePlan ? "white" : "#007BFF")};
  color: ${(props) => (props.$isActivePlan ? "#007BFF" : "white")};
  border: 1px solid #007bff;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
`;
