import React from "react";
import { useTranslation } from "react-i18next";
import { Typeahead } from "./styles";
import { SelectEvent } from "react-bootstrap-typeahead/types/types";

export interface AllowNewOption {
  id: string;
  name: string;
  customOption: boolean;
}

interface CustomInputTagProps {
  options: string[] | AllowNewOption[];
  onChange?(selecteds: any[]): void;
  allowNew?: boolean;
  name?: string;
  defaultSelected?: string[] | AllowNewOption[];
  selected?: string[] | AllowNewOption[];
  isLoading?: boolean;
  paginate?: boolean;
  paginationText?: React.ReactNode;
  onPaginate?(event: SelectEvent<HTMLElement>, shownResults: number): void;
}

export default function CustomInputTag({
  options,
  onChange,
  allowNew,
  name,
  defaultSelected,
  selected,
  isLoading,
  paginate,
  paginationText,
  onPaginate,
}: CustomInputTagProps) {
  const { t } = useTranslation();

  return (
    <Typeahead
      defaultSelected={defaultSelected}
      id={name || "multiple-tag"}
      labelKey="name"
      multiple
      options={options}
      selected={selected}
      allowNew={allowNew}
      newSelectionPrefix={t("component.input_tag_new.placeholder")}
      placeholder={t("component.input_tag.placeholder")}
      emptyLabel={t("component.input_tag.emptylabel")}
      onChange={onChange}
      paginate={paginate}
      isLoading={isLoading}
      maxResults={paginate ? 1 : undefined}
      paginationText={paginationText}
      onPaginate={onPaginate}
    />
  );
}
