import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const driverAssociate = yup
  .object()
  .shape({
    users: yup
      .array()
      .of(yup.object().shape({ isSelected: yup.boolean().required() }))
      .min(1),
  })
  .test(
    "pelo-menos-um-selecionado",
    "Pelo menos um usuário deve ser selecionado.",
    (obj: any) => {
      return obj.users.some((user: any) => user.isSelected);
    }
  );

const driverAdd = yup.object().shape({
  users: yup.string().required('Drivers is a required field'),
});

export const DriverAddResolver = yupResolver(driverAdd);
export const DriverAssociateResolver = yupResolver(driverAssociate);
