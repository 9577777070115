import React from "react";
import { ReactComponent as ICSearch } from "../../assets/icons/search.svg";

import { InputGroup } from "./styles";

interface CustomSearchInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

const CustomSearchInput: React.FC<CustomSearchInputProps> = ({
  style,
  ...props
}) => {
  return (
    <InputGroup className="form-control" style={style}>
      <div>
        <ICSearch />
      </div>
      <input {...props} type="text" />
    </InputGroup>
  );
};

export default CustomSearchInput;
