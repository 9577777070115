import styled from "styled-components";

interface Props {
  long?: boolean;
}

export const Container = styled.div`
  height: 100% !important;
  display: flex;
  padding: 1rem 1.5rem !important;
  flex-direction: column;

  .form-switch {
    .form-check-label {
      color: #212529;
    }
  }
`;

export const THead = styled.thead`
  display: flex;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 6px 28px;
  margin-bottom: 0.5rem;

  tr {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  tr th {
    flex: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #6c757d;
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  align-self: flex-start;

  @media (max-width: 768px) {
    position: relative;
    margin-top: 3rem;
  }
`;

export const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 35px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 0;
  border-color: #f2f2f2;

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #6c757d;
  }
`;

export const FieldStatus = styled(Field)`
  padding: 1.5rem 0;
  border-left: 1px dashed #6c757d;
  margin-left: 10px;

  svg {
    min-width: 1.5rem;
  }

  :last-child {
    padding-bottom: 0;
  }
`;

export const FieldSub = styled(FieldStatus)`
  margin-left: 260px;
  padding: 2rem 0;
`;

export const FieldName = styled.h5`
  flex: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #6c757d;
  margin-left: 8px;
  margin-bottom: 0;
  max-width: fit-content;
`;

export const Dashed = styled.div<Props>`
  width: ${(props) => props.long ? '450px' : '250px'};
  border-bottom: 1px dashed #6c757d;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;

  .add-button {
    color: #0062ff;
    text-decoration: underline;
  }

  button {
    text-decoration: underline;
  }
`;
