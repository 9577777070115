import RBModal from "react-bootstrap/Modal";
import BsCard from "react-bootstrap/Card";
import styled from "styled-components";

export const Modal = styled(RBModal)`
  overflow: hidden;

  .modal-body {
    max-height: 50vh;
    overflow-y: auto;
    padding: 24px 24px 22px 34px;
    margin-right: 10px;
  }

  .modal-header {
    padding: 28px 24px 24px 34px;
  }
  
  .modal-body::-webkit-scrollbar {
    width: 6px;
  }

  .modal-body::-webkit-scrollbar-track {
    background: transparent;
    margin: 24px 0 34px 0 !important;
  }

  .modal-body::-webkit-scrollbar-thumb {
    background-color: #d4d3d3;
    border-radius: 3px;
  }

  .modal-footer {
    justify-content: center;
    border: none;
  }
`;

export const Card = styled(BsCard)`
  min-height: 32px;
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  flex: none;
  order: 3;
  flex-grow: 0;
  border: 0;
  margin-bottom: 12px;
`;

export const CardBody = styled(BsCard.Body)`
  display: flex;
  justify-content: space-between;
  gap: 19px;
  padding: 4px 11px !important;
`;

export const Comment = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #646d77;
  flex: 1;
`;

export const Details = styled.small`
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #aeb5bc;
  flex: none;
`;
