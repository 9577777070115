import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupMatchTime } from "./time";

const vehicleJourney = yup
  .object({
    start_journey: yupMatchTime,
    end_journey: yupMatchTime,
    average_service_time: yupMatchTime,
    include_interval: yup.boolean().notRequired(),
    start_first_break: yup.string().when("include_interval", {
      is: true,
      then: yupMatchTime.required("Primeiro intervalo é obrigatório"),
    }),
    end_first_break: yup.string().when("include_interval", {
      is: true,
      then: yupMatchTime.required("Fim do intervalo é obrigatório"),
    }),
    start_second_break: yupMatchTime.notRequired(),
    end_second_break: yupMatchTime.notRequired(),
    start_third_break: yupMatchTime.notRequired(),
    end_third_break: yupMatchTime.notRequired(),
  })
  .required();

export const RoutingVehicleJourneyResolver = yupResolver(vehicleJourney);
