import React from "react";
import { Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Capabilities from "./Capabilities";
import RoutingRule from "./RoutingRule";

import { Tabs } from "./styles";
import TypesMaterial from "./TypesMaterial";
import VehicleJourney from "./VehicleJourney";

const ConfigsRouting: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Tabs
      defaultActiveKey="routing_rule"
      className="mb-3"
      style={{ marginTop: "-25px", marginLeft: "-25px" }}
    >
      <Tab
        eventKey="routing_rule"
        title={t("configs.routing.routing_rule_tab")}
      >
        <RoutingRule />
      </Tab>
      <Tab
        eventKey="vehicle_journey"
        title={t("configs.routing.vehicle_journey_tab")}
      >
        <VehicleJourney />
      </Tab>
      {/* <Tab
        eventKey="capabilities"
        title={t("configs.routing.capabilities_tab")}
      >
        <Capabilities />
      </Tab>
      <Tab
        eventKey="types_material"
        title={t("configs.routing.types_material_tab")}
      >
        <TypesMaterial />
      </Tab> */}
    </Tabs>
  );
};

export default ConfigsRouting;
