export function convertTime(seconds: number): string {
  let hours = 0;
  let minutes = Math.floor(seconds / 60);
  let secondsLeft = seconds % 60;
  let data = [
    String(hours).padStart(2, "0"),
    String(minutes).padStart(2, "0"),
    String(secondsLeft).padStart(2, "0"),
  ];

  return data.join(":");
}
