import { api } from ".";
import {
  CompanySettingsQuery,
  CompanySettingsResponse,
  CreateCompanySettings,
  UpdateCompanySettings,
} from "../interfaces/CompanySettings";
import { ErrorResult } from "../interfaces/ErrorResult";

export class CompanySettingsService {
  static async get(
    params?: CompanySettingsQuery
  ): Promise<CompanySettingsResponse | ErrorResult> {
    return api
      .get(`/api/v2/company-configurations`, {
        params: {
          page: params?.page,
          fields: params?.fields?.join(","),
          conditions: params?.conditions,
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(data: UpdateCompanySettings): Promise<ErrorResult> {
    return api
      .put(`/api/v2/company-configurations`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(data: CreateCompanySettings): Promise<ErrorResult> {
    return api
      .post(`/api/v2/company-configurations`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(): Promise<ErrorResult> {
    return api
      .delete(`/api/v2/company-configurations`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
