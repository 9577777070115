import React, { MouseEvent } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  Container,
  LinkGoBack,
  Card,
  AddButton,
  CardTitle,
  AutocompleteFieldList,
  ActionButton,
  AutocompleteField,
  AutocompleteFieldName,
} from "./styles";

import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort, BsPlus, BsTrash } from "react-icons/bs";
import { TbGripVertical } from "react-icons/tb";
import { useFieldArray, useForm } from "react-hook-form";
import { NotificationResolver } from "../../../core/schemaValidations/notification";

type FormValues = {
  moment: string;
  subject: string;
  message: string;
  autocomplete: {
    fieldName: string;
  }[];
};

const NotificationForm: React.FC = () => {
  const SMS_TEXT_LIMIT = 34;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({ resolver: NotificationResolver });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "autocomplete", // unique name for your Field Array
    }
  );

  const handleAddAutomaticField = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    append({ fieldName: "Autocomplete" });
  };

  const handleDelete = (
    event: MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    event.preventDefault();

    remove(index);
  };

  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    reset();
    navigate(-1);
  };

  const onSubmit = (values: FormValues) => {
    alert(JSON.stringify(values));
  };

  return (
    <Container>
      <LinkGoBack className="mb-3" onClick={() => navigate(-1)}>
        <Stack direction="horizontal" gap={1}>
          <BsArrowLeftShort size={24} />
          {t("configs.sms.back")}
        </Stack>
      </LinkGoBack>

      <Form onSubmit={handleSubmit(onSubmit, console.warn)} className="form">
        <Stack
          className="align-items-start"
          as="div"
          direction="horizontal"
          gap={5}
        >
          <Card style={{ minWidth: 464 }}>
            <Form.Group className="mb-4" controlId="moment">
              <Form.Label>{t("configs.sms.form_input_moment")}</Form.Label>
              <Form.Select
                aria-label="Default select"
                isInvalid={!!errors?.moment?.message}
                {...register("moment")}
              >
                <option value="">{t("configs.sms.form_select")}</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.moment?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4" controlId="subject">
              <Form.Label>{t("configs.sms.form_label_sms_subject")}</Form.Label>
              <Form.Control
                {...register("subject")}
                isInvalid={!!errors?.subject?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.subject?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="message">
              <Form.Label>{t("configs.sms.form_label_sms_content")}</Form.Label>
              <Form.Control
                as="textarea"
                maxLength={SMS_TEXT_LIMIT}
                isInvalid={!!errors?.message?.message}
                style={{ height: "137px" }}
                {...register("message")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.message?.message}
              </Form.Control.Feedback>
              <div className="d-flex justify-content-end">
                <Form.Text muted>
                  {SMS_TEXT_LIMIT - watch("message")?.length}{" "}
                  {t("configs.sms.textarea_text")}
                </Form.Text>
              </div>
            </Form.Group>
          </Card>

          <Card style={{ minWidth: 413 }}>
            <Stack direction="horizontal" gap={4}>
              <AddButton onClick={handleAddAutomaticField}>
                <BsPlus size={20} />
              </AddButton>

              <CardTitle>{t("configs.sms.field_title")}</CardTitle>
            </Stack>

            <AutocompleteFieldList>
              {fields.map((item, index) => (
                <AutocompleteField key={index}>
                  <TbGripVertical size={22} />
                  <AutocompleteFieldName>
                    {item.fieldName}
                  </AutocompleteFieldName>
                  <ActionButton onClick={(event) => handleDelete(event, index)}>
                    <BsTrash size={22} />
                  </ActionButton>
                </AutocompleteField>
              ))}
            </AutocompleteFieldList>
          </Card>
        </Stack>

        <Stack className="mb-3" direction="horizontal">
          <Button type="submit">{t("configs.company.save")}</Button>
          <Button className="ms-3" variant="secondary" onClick={handleCancel}>
            {t("configs.company.cancel")}
          </Button>
        </Stack>
      </Form>
    </Container>
  );
};

export default NotificationForm;
