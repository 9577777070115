import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoTrashOutline } from "react-icons/io5";
import AddButton from "../../components/AddButton";
import CustomSearchInput from "../../components/CustomSearchInput";
import CustomThead from "../../components/CustomThead";
import DriverInvitationModal from "../../components/DriverInvitationModal";

import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import ListEmpty from "../../components/ListEmpty";
import TableFooter from "../../components/TableFooter";
import { Driver, DriverQuery } from "../../core/interfaces/Driver";
import { updateAlert } from "../../core/redux/reducer/alert";
import { DriverService } from "../../core/services/DriverService";
import { isErrorResult } from "../../core/utils/api";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { Container } from "./styles";
import { useAppSelector } from "../../core/redux/hooks";
import { getUser } from "../../core/redux/reducer/auth";
import { module_names } from "../../constants/module_names";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { Navigate } from "react-router-dom";

type Register = Driver & {
  isEdited?: boolean;
  isSelected?: boolean;
  authorized?: boolean;
  pivot?: {
    active?: number;
  };
};

type FormValues = {
  registers: Register[];
};

type DriverFilter = {
  filter?: { [key: string]: any };
  order?: { [key: string]: string };
  page: number;
  pages: number;
};

const Drivers: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [addDriverIsVisible, setAddDriverIsVisible] = useState(false);
  const [filters, setFilters] = React.useState<DriverFilter>({
    page: 1,
    pages: 0,
  });
  const [defaultValues, setDefaultValues] = useState<FormValues>();
  const [deleteMany, setDeleteMany] = useState<boolean>();
  const [deleteId, setDeleteId] = useState<number | false>(false);
  const [isBusyDeleting, setBusyDeleting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues,
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  async function switchActivateDeactivate(
    driver_id: number,
    activate_deactivate: number
  ) {
    try {
      const response = await DriverService.switchActivateDeactivate({
        driver_id: driver_id,
        activate_deactivate: activate_deactivate,
      });
      if (response?.success === 200) {
        loadDrivers(filters);
        const title = t("drivers.delete_success_title");
        // dispatch(updateAlert({ title }));
      }
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("drivers.delete.message_error"),
          message: error.response.data?.message,
          type: "error",
        })
      );
    }
  }

  async function loadDrivers(params?: DriverQuery) {
    // if (isLoading) return;
    setLoading(true);
    try {
      const response = await DriverService.get(params);
      const isError = isErrorResult(response);
      if (!isError && response.data) {
        setDefaultValues({ registers: response.data });
        reset({ registers: response.data }, { keepDirty: false });
        setFilters((prev) => ({
          ...prev,
          page: response.meta.current_page,
          pages: response.meta.last_page,
        }));
      }
    } catch (error) {
      alert("Error in load drivers!");
    } finally {
      setLoading(false);
    }
  }

  const onSubmitDeleteMany: SubmitHandler<FormValues> = async ({
    registers,
  }) => {
    const registerIds = registers.filter((register) => !!register?.isSelected);
    const total = registerIds?.length;

    try {
      const response = await DriverService.switchAssociate({
        drivers_id: registerIds.map((register) => register.id),
        associate_disassociate: 0,
      });
      if (response?.success === 200) {
        loadDrivers(filters);
        const title = `${total} ${t("drivers.delete_many_success_title")}`;
        dispatch(updateAlert({ title }));
      }
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("drivers.delete_many.message_error"),
          message: error.response.data?.message,
          type: "error",
        })
      );
    } finally {
      setDeleteMany(false);
    }
  };

  const onSubmitDelete = async () => {
    if (!deleteId || isBusyDeleting) return;
    setBusyDeleting(true);

    try {
      const response = await DriverService.switchAssociate({
        drivers_id: [deleteId],
        associate_disassociate: 0,
      });
      if (response?.success === 200) {
        loadDrivers(filters);
        const title = t("drivers.delete_success_title");
        dispatch(updateAlert({ title }));
      }
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("drivers.delete.message_error"),
          message: error.response.data?.message,
          type: "error",
        })
      );
    } finally {
      setDeleteId(false);
      setBusyDeleting(false);
    }
  };

  function getSelectedAll(): boolean {
    const data = watch("registers");
    let count = 0;

    data?.forEach((item) => {
      if (!!item?.isSelected) count++;
    });

    return data?.length === count && data?.length >= 1;
  }

  function getSelected(): boolean {
    const data = watch("registers");
    return !!data?.find((item) => item?.isSelected === true);
  }

  function handleSelectAll() {
    const data = watch("registers");
    const value = !getSelectedAll();

    const newDataList: any[] = data?.map((item) => {
      item.isSelected = value;
      return item;
    });

    replace(newDataList);
  }

  const handleEditCancel = async () => {
    reset(defaultValues, { keepDirty: false });
  };

  function haveEditedItems(): boolean {
    return isDirty && !!watch("registers")?.find((item) => !!item?.isEdited);
  }

  function onSearch(text: string): void {
    let filter = { search: text };
    setFilters((prev) => ({ ...prev, filter }));
  }

  React.useEffect(() => {
    loadDrivers(filters);
  }, [filters.filter, filters.page, filters.order, user?.company_id]);

  return (
    <PrivateMasterPage
      title={t("drivers.title")}
      breadcrumb={[t("drivers.title")]}
    >
      <ProtectedComponent
        module_name={module_names.DRIVERS}
        property="view"
        fallbackComponent={<Navigate to={"/journey"} />}
      >
        <Container>
          <Card className="m-3">
            <Card.Body className={(!fields?.length && "flex-none") + " p-4"}>
              <Row className="mb-4">
                <Col>
                  <AddButton onClick={() => setAddDriverIsVisible(true)}>
                    {t("drivers.add")}
                  </AddButton>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="d-flex align-items-center" xs>
                  <h5 className="m-0">{t("drivers.table_title")}</h5>
                </Col>
                <Col className="d-flex justify-content-center" xs="auto">
                  <ProtectedComponent
                    module_name={module_names.DRIVERS}
                    property="delete"
                  >
                    <Button
                      className="text-secondary"
                      disabled={!getSelected()}
                      variant="link"
                      onClick={() => setDeleteMany(true)}
                    >
                      <IoTrashOutline size={20} className="me-2" />
                      {t("drivers.delete_selected_registers")}
                    </Button>
                  </ProtectedComponent>
                </Col>
                <Col xs="auto">
                  <CustomSearchInput
                    className="search"
                    placeholder={t("search")}
                    onChange={(e) => onSearch(e.currentTarget.value)}
                  />
                </Col>
              </Row>
              <Table responsive>
                <CustomThead
                  checkbox
                  selectedAll={getSelectedAll()}
                  onChangeSelectAll={handleSelectAll}
                  data={[
                    { column: "name", name: t("drivers.table_name") },
                    { column: "cpf", name: t("drivers.table_document") },
                    { column: "email", name: t("drivers.table_email") },
                    { column: "phone", name: t("drivers.table_phone") },
                    {
                      column: "authorized",
                      name: t("drivers.table_authorized"),
                    },
                    {
                      column: "responsible_user_name",
                      name: t("drivers.table_responsible"),
                    },
                    { column: "menu", name: <th />, menu: true },
                  ]}
                  onChangeOrder={(order) => {
                    setFilters((prev) => ({ ...prev, order }));
                  }}
                />
                <tbody>
                  {fields?.map((driver, i) => (
                    <tr key={driver?.fieldId}>
                      <td>
                        <FormCheck
                          id={`selected_${driver?.fieldId}`}
                          label={driver?.name}
                          {...register(`registers.${i}.isSelected`)}
                        />
                      </td>
                      <td>{driver?.cpf}</td>
                      <td>{driver?.email}</td>
                      <td>{driver?.phone}</td>
                      <td>
                        <Form.Check
                          type="switch"
                          id={`authorized_${driver?.fieldId}`}
                          defaultChecked={
                            driver?.pivot?.active == 1 ? true : false
                          }
                          {...register(`registers.${i}.authorized`, {
                            onChange: (event) => {
                              const activate_deactivate =
                                event.target.checked == true ? 1 : 0;
                              switchActivateDeactivate(
                                driver?.id,
                                activate_deactivate
                              );
                              setValue(`registers.${i}.isEdited`, true);
                            },
                          })}
                        />
                      </td>
                      <td>{driver?.responsible_user_name ?? "-"}</td>
                      <td>
                        <ProtectedComponent
                          module_name={module_names.DRIVERS}
                          property="delete"
                          fallbackComponent={
                            <IoTrashOutline
                              size={20}
                              className="me-2 cursor"
                              opacity={"0.5"}
                            />
                          }
                        >
                          <IoTrashOutline
                            size={20}
                            className="me-2 cursor"
                            onClick={() => setDeleteId(driver.id)}
                          />
                        </ProtectedComponent>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Row>
                <Col className="d-flex justify-content-center">
                  {isLoading && <Spinner animation="border" />}
                </Col>
              </Row>
            </Card.Body>

            <ListEmpty hidden={fields?.length >= 1}>
              {t("drivers.empty_list.message")}
              <AddButton
                style={{ flexDirection: "column", gap: 5 }}
                onClick={() => setAddDriverIsVisible(true)}
              >
                {t("drivers.add")}
              </AddButton>
            </ListEmpty>

            <TableFooter
              hiddenAction
              actions={{
                hidden: !haveEditedItems(),
                isSubmitting,
                onCancel: handleEditCancel,
              }}
              paginate={{
                current_page: filters.page,
                qtd: filters.pages,
                onSelectPage: (page) =>
                  setFilters((prev) => ({ ...prev, page })),
              }}
            />
          </Card>
        </Container>
      </ProtectedComponent>

      <DriverInvitationModal
        visible={addDriverIsVisible}
        onClose={() => setAddDriverIsVisible(false)}
      />

      <ConfirmationModal
        visible={!!deleteId}
        title={t("drivers.delete_registers.message")}
        onClose={() => setDeleteId(false)}
        onConfirm={onSubmitDelete}
        isLoading={isBusyDeleting}
      />

      <ConfirmationModal
        visible={deleteMany}
        title={t("drivers.delete_many_registers.message")}
        onClose={() => setDeleteMany(false)}
        isLoading={isSubmitting && deleteMany}
        onConfirm={handleSubmit(onSubmitDeleteMany, console.log)}
      />
    </PrivateMasterPage>
  );
};

export default Drivers;
