const languagesIds = [
  {
    id: "1",
    language: "pt",
  },
  {
    id: "2",
    language: "en",
  },
  {
    id: "3",
    language: "fr",
  },
];
// interface IApiLoader {
//   id?: string | undefined;
//   googleMapsApiKey: any;
//   language?: string | undefined;
//   libraries?:
//     | ("drawing" | "geometry" | "localContext" | "places" | "visualization")[]
//     | undefined;
//   googleMapsClientId?: string | undefined;
//   version?: string | undefined;
//   region?: string | undefined;
//   channel?: string | undefined;
//   mapIds?: string[] | undefined;
//   authReferrerPolicy?: "origin" | undefined;
// }
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_KEY;

function getLanguageFromLocalStorage() {
  const languageId = localStorage.getItem("LANGUAGE_ID");
  const languageById = languagesIds.find(
    (language) => language.id === languageId
  );
  return languageById?.language;
}

//remove interface
export const API_LOADER_MAP: any = {
  id: "google-map-script",
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  language: getLanguageFromLocalStorage(),
  libraries: ["places", "geometry"],
};
