import { useEffect, useState, forwardRef } from "react";
import {
  Container,
  TimerContainer,
  CalendarContainer,
  CalendarDayWrapper,
  CalendarDays,
  CalendarHeader,
  CalendarHeaderItem,
  CalendarWeekDays,
  TimerSelector,
  CalendarButtonState,
} from "./styles";
import {
  IoChevronBackOutline,
  IoChevronDown,
  IoChevronForward,
} from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";
import {
  add,
  compareAsc,
  eachDayOfInterval,
  eachYearOfInterval,
  endOfMonth,
  format,
  getDay,
  getYear,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  set,
  setYear,
  startOfMonth,
  startOfToday,
  Locale,
  setHours,
  setMinutes,
  getMinutes,
  getHours,
} from "date-fns";
import * as dateFnsLocales from "date-fns/locale";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface IProps {
  selectedDate: Date | null | "Invalid Date";
  selectedTime: Date | null | "Invalid Date";
  onSelectDate: (date: Date) => void;
  onSelectTime: (date: Date) => void;
  haveTimerSelection: boolean;
}

interface Locales {
  [key: string]: Locale;
}

interface IValues {
  activeCalendarMode: "calendar" | "timer";
  currentMonth: string;
  hour: number;
  minutes: number;
}

export const Calendar = forwardRef(
  (
    { selectedDate, onSelectDate, onSelectTime, haveTimerSelection }: IProps,
    ref: any
  ) => {
    const { t, i18n } = useTranslation();

    const months = [
      t("calendar.january"),
      t("calendar.february"),
      t("calendar.march"),
      t("calendar.april"),
      t("calendar.may"),
      t("calendar.june"),
      t("calendar.july"),
      t("calendar.august"),
      t("calendar.september"),
      t("calendar.october"),
      t("calendar.november"),
      t("calendar.december"),
    ];

    const i18nDays = [
      t("calendar.sunday"),
      t("calendar.monday"),
      t("calendar.tuesday"),
      t("calendar.wednesday"),
      t("calendar.thursday"),
      t("calendar.friday"),
      t("calendar.saturday"),
    ];

    const getDateFnsLocale = (): Locale => {
      const locales: Locales = {
        pt: dateFnsLocales.ptBR,
        en: dateFnsLocales.enUS,
        fr: dateFnsLocales.fr,
      };

      return locales[i18n.language];
    };

    const today = startOfToday();
    const years = eachYearOfInterval({
      start: new Date(2016, 1, 1),
      end: new Date(),
    }).sort((a, b) => b.getTime() - a.getTime());

    const [values, setValues] = useState<IValues>({
      activeCalendarMode: "calendar",
      currentMonth: format(today, "MMM-yyyy"),
      hour: 0,
      minutes: 0,
    });

    const [currentYear, setCurrentYear] = useState(format(today, "yyyy"));
    const firstDayCurrentMonth = parse(
      values.currentMonth,
      "MMM-yyyy",
      new Date()
    );

    function updateValues({ name, value }: { name: string; value: any }) {
      setValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
      }));
    }

    const days = eachDayOfInterval({
      start: startOfMonth(firstDayCurrentMonth),
      end: endOfMonth(firstDayCurrentMonth),
    });

    function previousMonth() {
      const firstDayPrevMonth = add(firstDayCurrentMonth, { months: -1 });

      updateValues({
        name: "currentMonth",
        value: format(firstDayPrevMonth, "MMM-yyyy"),
      });
      setCurrentYear(format(getYear(firstDayPrevMonth), "yyyy"));
    }

    function nextMonth() {
      const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
      updateValues({
        name: "currentMonth",
        value: format(firstDayNextMonth, "MMM-yyyy"),
      });

      setCurrentYear(format(getYear(firstDayNextMonth), "yyyy"));
    }

    function selectYear(year: number) {
      const newYear = setYear(values.currentMonth, year);
      setCurrentYear(format(newYear, "yyyy"));
      updateValues({
        name: "currentMonth",
        value: format(newYear, "MMM-yyyy"),
      });
    }

    function activeCalendarState(state: "calendar" | "timer") {
      updateValues({ name: "activeCalendarMode", value: state });
    }

    //convert the number value of hour to string format
    function convertHourToString() {
      if (values.hour < 10) {
        return `0${values.hour}`;
      }
      return `${values.hour}`;
    }
    //convert the number value of minutes to string format
    function convertMinutesToString() {
      if (values.minutes < 10) {
        return `0${values.minutes}`;
      }
      return `${values.minutes}`;
    }

    //handle change on input hour
    function handleChangeHour(e: React.ChangeEvent<HTMLInputElement>) {
      const value = parseInt(e.target.value);
      if (value > 23) return;
      updateValues({ name: "hour", value });
    }
    //increase the hour value and validate to convert to the correct format of 23 hour
    function increaseHour() {
      if (values.hour > 23) {
        return updateValues({ name: "hour", value: 0 });
      }

      updateValues({ name: "hour", value: values.hour + 1 });
    }

    //decrease the hour value and validate to convert to the correct format of 23 hour
    function decreaseHour() {
      if (values.hour < 0) {
        return updateValues({ name: "hour", value: 23 });
      }

      updateValues({ name: "hour", value: values.hour - 1 });
    }

    //handle change on input minutes
    function handleChangeMinutes(e: React.ChangeEvent<HTMLInputElement>) {
      const value = parseInt(e.target.value);
      if (value > 59) return;
      updateValues({ name: "minutes", value });
    }

    //increase the minutes value and validate to convert to the correct format of 23 hour
    function increaseMinutes() {
      if (values.minutes === 59) {
        return updateValues({ name: "minutes", value: 0 });
      }

      updateValues({ name: "minutes", value: values.minutes + 1 });
    }

    //decrease the minutes value and validate to convert to the correct format of 23 hour
    function decreaseMinutes() {
      if (values.minutes === 0) {
        return updateValues({ name: "minutes", value: 59 });
      }

      updateValues({ name: "minutes", value: values.minutes - 1 });
    }

    //Watch the months change to change the year
    useEffect(() => {
      setCurrentYear(format(values.currentMonth, "yyyy"));
    }, [values.currentMonth]);

    //Watch the hours changes
    useEffect(() => {
      if (values.hour && selectedDate) {
        if (selectedDate == "Invalid Date") {
          const result = setHours(new Date(), values.hour ?? 0);
          onSelectDate(result);

          //in case date its invalid, we add a new Date and update the minutes to now
          updateValues({ name: "minutes", value: getMinutes(new Date()) });
          return;
        }

        onSelectTime(setHours(selectedDate, values.hour ?? 0));
      }
    }, [values.hour]);

    //Watch the minutes changes
    useEffect(() => {
      if (values.minutes && selectedDate) {
        if (selectedDate == "Invalid Date") {
          const result = setMinutes(new Date(), values.minutes ?? 0);
          onSelectDate(result);
          //in case date its invalid, we add a new Date and update the hour to now
          updateValues({ name: "hour", value: getHours(new Date()) });
          return;
        }
        onSelectTime(setMinutes(selectedDate!, values.minutes));
      }
    }, [values.minutes]);

    //watch the language changes to convert the mask to the correct format
    useEffect(() => {
      if (selectedDate != "Invalid Date" && selectedDate != null) {
        //in case date its invalid, we add a new Date and update the hour to now

        updateValues({ name: "hour", value: getHours(selectedDate) });
        updateValues({ name: "minutes", value: getMinutes(selectedDate) });
        return;
      }
    }, [selectedDate]);

    return (
      <Container ref={ref}>
        {values.activeCalendarMode === "calendar" && (
          <CalendarContainer>
            <CalendarHeader>
              {/* <CalendarHeaderItem>
            <div>
              <Dropdown as="div">
                <Dropdown.Toggle id="month-dropdown">
                  <IoChevronDown color="#92929D" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {months.map((month, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() =>
                        setCurrentMonth(
                          format(
                            new Date(getYear(firstDayCurrentMonth), index, 1),
                            "MMM-yyyy"
                          )
                        )
                      }
                    >
                      {month}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <time>{currentYear}</time>
              <Dropdown as="div">
                <Dropdown.Toggle id="year-dropdown">
                  <IoChevronDown color="#92929D" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {years.map((year, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => selectYear(+format(year, "yyyy"))}
                    >
                      {format(year, "yyyy")}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </CalendarHeaderItem> */}

              <CalendarHeaderItem>
                <div>
                  <button type="button" onClick={previousMonth}>
                    <IoChevronBackOutline color="#92929D" />
                  </button>
                </div>
                <span style={{ textTransform: "capitalize" }}>
                  {format(values.currentMonth, "MMMM", {
                    locale: getDateFnsLocale(),
                  })}
                </span>
                <div>
                  <button type="button" onClick={nextMonth}>
                    <IoChevronForward color="#92929D" />
                  </button>
                </div>
              </CalendarHeaderItem>
            </CalendarHeader>

            <CalendarWeekDays>
              {i18nDays.map((day, index) => (
                <div key={index}>{day.substring(0, 3)}</div>
              ))}
            </CalendarWeekDays>

            <CalendarDays>
              {days.map((day: Date, dayIdx: number) => (
                <CalendarDayWrapper
                  key={dayIdx}
                  $isToday={isToday(day)}
                  $day={day}
                  $dayIdx={dayIdx}
                  $dayOfWeek={getDay(day)}
                  $selectedDate={selectedDate}
                  $isSameMonth={isSameMonth(day, firstDayCurrentMonth)}
                >
                  <button
                    type="button"
                    onClick={() => {
                      const new_date = set(day, {
                        hours: values.hour,
                        minutes: values.minutes,
                      });
                      onSelectDate(new_date);
                    }}
                  >
                    <time dateTime={format(day, "yyyy-MM-dd")}>
                      {format(day, "d")}
                    </time>
                  </button>
                </CalendarDayWrapper>
              ))}
            </CalendarDays>
          </CalendarContainer>
        )}

        {haveTimerSelection && (
          <>
            {values.activeCalendarMode === "timer" ? (
              <CalendarButtonState
                type="button"
                onClick={() => activeCalendarState("calendar")}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 11H9V13H7V11ZM7 15H9V17H7V15ZM11 11H13V13H11V11ZM11 15H13V17H11V15ZM15 11H17V13H15V11ZM15 15H17V17H15V15Z"
                    fill="#6C757D"
                  />
                  <path
                    d="M5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22ZM19 8L19.001 20H5V8H19Z"
                    fill="#6C757D"
                  />
                </svg>
              </CalendarButtonState>
            ) : (
              <CalendarButtonState
                type="button"
                onClick={() => activeCalendarState("timer")}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1.625C6.71836 1.625 1.625 6.71836 1.625 13C1.625 19.2816 6.71836 24.375 13 24.375C19.2816 24.375 24.375 19.2816 24.375 13C24.375 6.71836 19.2816 1.625 13 1.625ZM13 22.4453C7.78477 22.4453 3.55469 18.2152 3.55469 13C3.55469 7.78477 7.78477 3.55469 13 3.55469C18.2152 3.55469 22.4453 7.78477 22.4453 13C22.4453 18.2152 18.2152 22.4453 13 22.4453Z"
                    fill="#6C757D"
                  />
                  <path
                    d="M17.4357 16.2144L13.815 13.5967V7.3125C13.815 7.20078 13.7236 7.10938 13.6119 7.10938H12.3906C12.2789 7.10938 12.1875 7.20078 12.1875 7.3125V14.3051C12.1875 14.3711 12.218 14.432 12.2713 14.4701L16.4709 17.5322C16.5623 17.5982 16.6893 17.5779 16.7553 17.4891L17.4814 16.4988C17.5475 16.4049 17.5271 16.2779 17.4357 16.2144Z"
                    fill="#6C757D"
                  />
                </svg>
              </CalendarButtonState>
            )}
          </>
        )}

        {values.activeCalendarMode === "timer" && (
          <TimerContainer>
            <TimerSelector>
              <div className="d-flex flex-column align-items-center gap-3">
                <button type="button" onClick={increaseHour}>
                  <FaChevronUp color="#6C757D" />
                </button>

                <InputMask
                  className="timer_input"
                  value={convertHourToString()}
                  mask="99"
                  maskChar=""
                  onChange={handleChangeHour}
                />

                <button type="button" onClick={decreaseHour}>
                  <FaChevronDown color="#6C757D" />
                </button>
              </div>
              <span>:</span>
              <div className="d-flex flex-column align-items-center gap-3">
                <button type="button" onClick={increaseMinutes}>
                  <FaChevronUp color="#6C757D" />
                </button>

                <InputMask
                  className="timer_input"
                  value={convertMinutesToString()}
                  mask="99"
                  maskChar=""
                  onChange={handleChangeMinutes}
                />

                <button type="button" onClick={decreaseMinutes}>
                  <FaChevronDown color="#6C757D" />
                </button>
              </div>
            </TimerSelector>
          </TimerContainer>
        )}
      </Container>
    );
  }
);
