import React, { useState } from "react";
import { Button, Col, Form, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddButton from "../../../components/AddButton";

import { ActionButton, Container } from "./styles";
import Pagination from "../../../components/Pagination";

import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import CustomThead from "../../../components/CustomThead";
import { APiQueryJSONBase } from "../../../core/interfaces/ApiResponseBase";
import { SMSNotification } from "../../../core/interfaces/SMSNotification";
import { SMSNotificationService } from "../../../core/services/SMSNotificationService";
import { isErrorResult } from "../../../core/utils/api";

const Notification: React.FC = () => {
  const [notifications, setNotifications] = useState<SMSNotification[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<
    APiQueryJSONBase<SMSNotification>
  >({ page: 1 });

  const handleAddSMSNotification = () => {
    navigate("new");
  };

  const loadSMSNotifications = async (
    query: APiQueryJSONBase<SMSNotification>
  ) => {
    try {
      const response = await SMSNotificationService.get(query);
      if (!isErrorResult(response) && response?.notifications?.data?.length) {
        setNotifications(response.notifications.data);
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  React.useEffect(() => {
    loadSMSNotifications(pagination);
  }, [pagination]);

  // const notifications = [
  //   {
  //     enabled: true,
  //     subject: "Entrega Agendada",
  //     time: "Planejamento da tarefa",
  //   },
  //   {
  //     enabled: false,
  //     subject: "Entrega Agendada",
  //     time: "Planejamento da tarefa",
  //   },
  // ];

  return (
    <Container>
      <Col xs="12">
        <Col className="mb-4" xs="6">
          <AddButton onClick={handleAddSMSNotification}>
            {t("configs.sms.add")}
          </AddButton>
        </Col>

        <Table responsive>
          <CustomThead
            data={[
              { column: "1", name: t("configs.sms.table_enabled") },
              { column: "2", name: t("configs.sms.table_subject") },
              { column: "3", name: t("configs.sms.table_time") },
              { column: "menu", name: <th />, menu: true },
            ]}
            onChangeOrder={(event) => console.log(event)}
          />

          <tbody>
            {notifications?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Form.Check
                    type="switch"
                    id={String(index)}
                    defaultChecked={Boolean(item?.habilitado)}
                  />
                </td>
                <td>
                  <div>{item?.title}</div>
                </td>
                <td>
                  <div>{item?.created_at}</div>
                </td>
                <td>
                  <ActionButton
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <BsThreeDotsVertical />
                  </ActionButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          current_page={1}
          // current_page={pagination.page}
          qtd={notifications.length}
          // qtd={pagination.pages}
          onSelectPage={(page) => setPagination((prev) => ({ ...prev, page }))}
        />
      </Col>
      <Stack className="mb-3" direction="horizontal">
        <Button type="submit">{t("configs.company.save")}</Button>
        <Button className="ms-3" variant="secondary">
          {t("configs.company.cancel")}
        </Button>
      </Stack>
    </Container>
  );
};

export default Notification;
