import { TRACKING_GRID_COLUMNS } from "../../constants/tracking_grid";
import { RoutingFilter } from "../interfaces/RoutingPlaning";
import {
  TrackingFilter,
  TrackingFilterMapBounds,
} from "../interfaces/TrackingFilter";
import { TrackingGridItem } from "../interfaces/TrackingGridItem";
import { LocalStorageUtils } from "../utils/local_storage";

export class PlanRoutingStorage extends LocalStorageUtils {
  static KEY = "@Tracking";
  static GRID_KEY = `${this.KEY}:grid`;
  static FILTER_KEY = `${this.KEY}:filter`;
  static FILTER_MAP_BOUNDS_KEY = `${this.KEY}:filter_map_bounds`;
  static FILTER_LOCAL_KEY = `${this.KEY}:filter_local`;
  static OPTIONS_KEY = `${this.KEY}:options`;
  static VEHICLE_SELECTED_KEY = `${this.KEY}:vehicle_selected`;
  static INDEX = 0;

  static getFilter(): RoutingFilter {
    return this.getItem(this.FILTER_KEY);
  }

  static getGrid(): TrackingGridItem[] {
    const grid_items: TrackingGridItem[] = this.getItem(this.GRID_KEY);

    if (
      grid_items?.length > 0 &&
      TRACKING_GRID_COLUMNS.length !== grid_items.length
    ) {
      const ids = grid_items.map((item) => item._id);
      const news = TRACKING_GRID_COLUMNS.filter(
        (item) => !ids.includes(item._id)
      );

      grid_items.push(...news);
    }

    return grid_items;
  }

  static getVehicleSelected(): any {
    return this.getItem(this.VEHICLE_SELECTED_KEY);
  }

  static getOptions(): any {
    return this.getItem(this.OPTIONS_KEY);
  }

  static getLocalFilter(): string {
    return this.getItem(this.FILTER_LOCAL_KEY);
  }

  static getFilterMapBounds(): TrackingFilterMapBounds {
    return this.getItem(this.FILTER_MAP_BOUNDS_KEY);
  }

  static setFilter(filter: RoutingFilter) {
    this.setItem(this.FILTER_KEY, filter);
  }

  static setFilterMapBounds(filter: TrackingFilterMapBounds) {
    this.setItem(this.FILTER_MAP_BOUNDS_KEY, filter);
  }

  static setLocalFilter(filter: string) {
    this.setItem(this.FILTER_LOCAL_KEY, filter);
  }

  static setIndex(index: number) {
    this.INDEX = index;
  }

  static setGrid(grid: TrackingGridItem[]) {
    this.setItem(this.GRID_KEY, grid);
  }

  static setVehicleSelected(data: any) {
    this.setItem(this.VEHICLE_SELECTED_KEY, data);
  }

  static setOptions(data: any) {
    this.setItem(this.OPTIONS_KEY, data);
  }

  static removeFilter() {
    this.removeItem(this.FILTER_KEY);
  }

  static removeFilterMapBounds() {
    this.removeItem(this.FILTER_MAP_BOUNDS_KEY);
  }

  static removeFilterLocal() {
    this.removeItem(this.FILTER_LOCAL_KEY);
  }

  static removeGrid() {
    this.removeItem(this.GRID_KEY);
  }

  static removeVehicleSelected() {
    this.removeItem(this.VEHICLE_SELECTED_KEY);
  }

  static removeOptions() {
    this.removeItem(this.OPTIONS_KEY);
  }
}
