import React from "react";

interface PublicMasterPageProps {
  children: any;
}

const PublicMasterPage: React.FC<PublicMasterPageProps> = ({ children }) => {
  return <div style={{ height: "100vh", overflow: "hidden" }}>{children}</div>;
};

export default PublicMasterPage;
