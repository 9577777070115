import styled from "styled-components";

interface ISidebarProps {
  expanded: boolean;
}

export const Container = styled.div<ISidebarProps>`
  width: ${(props) => (props.expanded ? "328px" : "70px")};
  background-color: #263238;
  height: 100%;

  .menu {
    height: 100vh;

    overflow-y: auto;
    overflow-x: hidden;
  }

  .menu-items {
    flex: 1;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 23px 0;
  }

  .expander-outer {
    display: flex;
    padding-top: 8px;
    padding-bottom: 16px;
    gap: 16px;
    align-items: flex-start;
  }

  .autocomplete-input-outer {
    padding-left: 20px;
    flex-grow: 1;
    display: ${(props) => (props.expanded ? "block" : "none")};
    color: #ffffff;
    label {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .expander {
    text-align: right;
    padding: 4px 8px;
    width: 32px;
    cursor: pointer;
    rotate: ${(props) => (props.expanded ? "180deg" : "0deg")};
    margin-left: auto;
  }

  .company_alert {
    margin-bottom: 10px;
    color: white;
  }

  .company_alert .btn-close {
    padding: 1rem !important;
  }

  .company_alert .alert-heading {
    font-weight: 500;
    font-size: 14px !important;
    margin-bottom: 0;
  }

  .menu-item {
    display: flex;
    height: 46px;
    align-items: center;
    justify-content: ${(props) => (props.expanded ? "left" : "center")};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    gap: 16px;
    cursor: pointer;
    text-decoration: none;
    ${(props) => props.expanded && "padding-left: 18px"};

    &.active {
      border-left: 6px solid white;
      background-color: #212529;
    }

    &__text {
      display: ${(props) => (props.expanded ? "block" : "none")};
      color: #ffffff;
    }
  }

  .actions {
  }

  .action-item {
    display: flex;
    height: 46px;
    align-items: center;
    justify-content: ${(props) => (props.expanded ? "left" : "center")};
    cursor: pointer;
    ${(props) => props.expanded && "padding-left: 12x"};
  }
`;
