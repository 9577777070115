import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as IcSuccess } from "../../assets/icons/success.svg";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import {
  getAlert,
  hideAlert,
  updateAlert,
} from "../../core/redux/reducer/alert";
import { Modal } from "./styles";

interface SuccessModalProps {
  onClose?(): void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ onClose, ...props }) => {
  const dispatch = useAppDispatch();
  const { title, message, type, onClick } = useAppSelector(getAlert);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  function handleOnClick() {
    if (onClick) {
      onClick();
    }
    dispatch(hideAlert());
  }

  useEffect(() => {
    setIsVisible(type === "success" && (!!title || !!message));
  }, [title, message, type]);

  return (
    <Modal
      {...props}
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      {/* <Modal.Header className="border-0" closeButton></Modal.Header> */}
      <Modal.Body>
        <IcSuccess />

        <p>
          {!!title && <div>{title}</div>}
          {!!message && <div>{message}</div>}
        </p>
        <Button onClick={handleOnClick}>Ok</Button>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
