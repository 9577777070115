import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Modal = styled(RBModal)`
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-content {
    width: 600px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 27px;
  }

  p {
    align-self: center;
    max-width: 382px;
    margin-top: 18px;
    margin-bottom: 35px;
  }

  .btn {
    width: 119px;
    margin-bottom: 8px;
  }
`;
