import React from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IVehicleTag } from "../../../core/interfaces/VehicleTag";
import { VehicleType } from "../../../core/interfaces/VehicleType";
import { useAppDispatch } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { VehicleResolver } from "../../../core/schemaValidations/vehicle";
import { VehicleService } from "../../../core/services/VehicleService";
import { VehicleTagService } from "../../../core/services/VehicleTagService";
import { VehicleTypeService } from "../../../core/services/VehicleTypeService";
import PrivateMasterPage from "../../../layout/PrivateMasterPage";
import Create from "./create";

import { useLocation, useNavigate } from "react-router-dom";
import { AllowNewOption } from "../../../components/CustomInputTag";
import { Vehicle } from "../../../core/interfaces/Vehicle";
import Hardware from "./hardware";
import { Container } from "./styles";
import Monitoring from "./monitoring";
import { ProtectedComponent } from "../../../components/ProtectedComponent";
import { module_names } from "../../../constants/module_names";

export type FormValues = {
  id?: number;
  vehicle_type_id: number;
  license_plate: string;
  total_weight: number;
  total_volume: number;
  total_amount: number;
  value_by_km: number;
  observations: string;
  active: number;
  tags: string[] | AllowNewOption[];
  material_type: string;
};

const CreateTabs: React.FC = () => {
  const [vehicleTypes, setVehicleTypes] = React.useState<VehicleType[]>([]);
  const [vehicleTags, setVehicleTags] = React.useState<IVehicleTag[]>([]);
  const [vehicle, setVehicle] = React.useState<Vehicle | null>(null);
  const [isLoadingTags, setLoadingTags] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { control, register, handleSubmit, setValue } = useForm<FormValues>({
    resolver: VehicleResolver,
  });

  async function loadVehiclesTypes() {
    try {
      const response = await VehicleTypeService.getAllVehicleTypes();
      if (!!response) {
        setVehicleTypes(response);
      }
    } catch (error) {
      console.log("Error em load vehicle types", error);
    }
  }

  async function loadVehiclesTags() {
    setLoadingTags(true);
    try {
      const response = await VehicleTagService.getAllVehicleTags();
      if (!!response) {
        setVehicleTags(response);
      }
    } catch (error) {
      console.log("Error em load vehicle tags", error);
    } finally {
      setLoadingTags(false);
    }
  }

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    for (const key of Object.keys(values) as (keyof FormValues)[]) {
      if (values[key] === "") {
        delete values[key];
      }
    }

    const setNewTags = new Set<string>([]);
    const setTags = new Set<string>([]);

    values.tags.forEach((tag) => {
      if (typeof tag === "object" && tag.customOption) {
        setNewTags.add(tag.name);
        setTags.add(tag.name);
      } else if (typeof tag === "string") {
        setTags.add(tag);
      }
    });

    const newTags = await handleCreateTags(Array.from(setNewTags));
    const updatedVehicleTags = newTags.length >= 1 ? newTags : vehicleTags;
    setVehicleTags(updatedVehicleTags);

    const tags = updatedVehicleTags.filter((item) => {
      return Array.from(setTags)?.includes(item.key);
    });

    const data = {
      ...values,
      vehicle_type_id: Number(values?.vehicle_type_id),
      total_weight: Number(values?.total_weight),
      total_volume: Number(values?.total_volume),
      total_amount: Number(values?.total_amount),
      value_by_km: Number(values?.value_by_km),
      tags: tags?.map((item) => item.id),
    };

    try {
      if (!!values?.id) {
        const response = await VehicleService.update(data);
        if (!response.success) throw Error(response.message);
      } else {
        const response = await VehicleService.create(data);
        if (!response.success) throw Error(response.message);
      }

      dispatch(updateAlert({ title: t("message.success.save_data") }));
      requestAnimationFrame(() => navigate(-1));
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: t("message.error.save_data.existplate"),
          type: "error",
        })
      );
    }
  };

  const handleCreateTags = async (tags: string[]): Promise<IVehicleTag[]> => {
    if (tags?.length === 0) return [];
    const data = tags.map((key) => ({ key }));

    try {
      const response = await VehicleTagService.create({ tags: data });
      if (response.success) {
        return await VehicleTagService.getAllVehicleTags();
      }
    } catch (error) {
      console.log("Error em create vehicle tags", error);
    }
    return [];
  };

  const onError: SubmitErrorHandler<FormValues> = (error) => {
    console.log("onSubmitError", error);
  };

  const getTagKeys = (data: IVehicleTag[]): string[] => {
    return data?.map((el) => el.key);
  };

  React.useEffect(() => {
    loadVehiclesTypes();
    loadVehiclesTags();
  }, []);

  React.useEffect(() => {
    if (location?.pathname === "/vehicles/edit") {
      const { data = null } = location?.state as { data: Vehicle | null };
      setVehicle(data);

      if (!!data) {
        const tags = getTagKeys(data?.vehicle_tags);
        setValue("id", data?.id);
        setValue("license_plate", data?.license_plate);
        setValue("total_weight", data?.total_weight);
        setValue("total_volume", data?.total_volume);
        setValue("total_amount", data?.total_amount);
        setValue("value_by_km", data?.value_by_km);
        setValue("observations", data?.observations);
        setValue("active", data?.active);
        setValue("tags", tags);
        setValue("material_type", "");
        setValue("vehicle_type_id", data?.vehicle_type_id);
      }
    }
  }, [vehicleTags, vehicleTypes, location, setValue]);

  return (
    <PrivateMasterPage
      title={
        location?.pathname === "/vehicles/edit"
          ? t("vehicles.edit.title")
          : t("vehicles.new.title")
      }
      breadcrumb={[
        location?.pathname === "/vehicles/edit"
          ? t("vehicles.edit.title")
          : t("vehicles.new.title"),
      ]}
    >
      <ProtectedComponent module_name={module_names.VEHICLES} property="edit">
        <Container>
          <ProtectedComponent
            module_name={module_names.CONFIG_CAMERA}
            property="view"
            fallbackComponent={
              <Tabs
                defaultActiveKey="create"
                // transition={false}
                className="m-3"
              >
                <Tab eventKey="create" title={t("vehicles.tab.register_title")}>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Create
                      control={control}
                      register={register}
                      options={{
                        tags: vehicleTags ?? [],
                        tagsLoading: isLoadingTags,
                        types: vehicleTypes ?? [],
                      }}
                    />
                  </Form>
                </Tab>
                <Tab
                  eventKey="hardware"
                  title={t("vehicles.tab.hardware_title")}
                  disabled={location?.pathname === "/vehicles/new"}
                >
                  <Hardware vehicleId={vehicle?.id} />
                </Tab>
              </Tabs>
            }
          >
            <Tabs
              defaultActiveKey="create"
              // transition={false}
              className="m-3"
            >
              <Tab eventKey="create" title={t("vehicles.tab.register_title")}>
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                  <Create
                    control={control}
                    register={register}
                    options={{
                      tags: vehicleTags ?? [],
                      tagsLoading: isLoadingTags,
                      types: vehicleTypes ?? [],
                    }}
                  />
                </Form>
              </Tab>
              <Tab
                eventKey="hardware"
                title={t("vehicles.tab.hardware_title")}
                disabled={location?.pathname === "/vehicles/new"}
              >
                <Hardware vehicleId={vehicle?.id} />
              </Tab>
              <Tab
                eventKey="monitoring"
                title={t("vehicles.hardware.video_monitoring.title")}
                disabled={location?.pathname === "/vehicles/new"}
              >
                <Monitoring vehicleId={vehicle?.id} />
              </Tab>
            </Tabs>
          </ProtectedComponent>
        </Container>
      </ProtectedComponent>
    </PrivateMasterPage>
  );
};

export default CreateTabs;
