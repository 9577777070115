import { ErrorResult } from "../interfaces/ErrorResult";

export function isErrorResult<T>(data: ErrorResult | T): data is ErrorResult {
  const _data = data as ErrorResult;
  return (
    _data?.message === "Unauthorized" ||
    _data?.message === "Server Error" ||
    _data?.success === false ||    
    _data?.exception ||
    (_data?.errors && Object.keys(_data?.errors).length > 0)
  );
}
