import { api } from ".";
import { ErrorResult } from "../interfaces/ErrorResult";
import {
  ApiResponseSurvey,
  CreateSurvey,
  CreateSurveyResponse,
  SurveyFull,
  SurveyQuery,
  UpdateOrCreateQuestions,
  UpdateSurvey,
  UpdateSurveyMany,
  UpdateSurveyResponse,
} from "../interfaces/Survey";

export class SurveyService {
  static async get(
    params?: SurveyQuery
  ): Promise<ApiResponseSurvey | ErrorResult> {
    const { filter, order, per_page = 10 } = { ...params };
    return await api
      .get("/api/v2/survey", {
        params: {
          ...params,
          per_page,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getById(id: number): Promise<SurveyFull | ErrorResult> {
    return await api
      .get(`/api/v2/survey/${id}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(
    data: CreateSurvey
  ): Promise<CreateSurveyResponse | ErrorResult> {
    return await api
      .post("/api/v2/survey", data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(
    id: number,
    data: UpdateSurvey
  ): Promise<UpdateSurveyResponse | ErrorResult> {
    return await api
      .put(`/api/v2/survey/${id}`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async updateMany(
    data: UpdateSurveyMany[]
  ): Promise<UpdateSurveyResponse[] | ErrorResult> {
    const promises = data?.map((item) => {
      const { id, ...survey } = item;
      return api.put(`/api/v2/survey/${id}`, survey);
    });

    return Promise.all(promises)
      .then((res) => res?.map((r) => r?.data))
      .catch((err) => err?.response?.data);
  }

  static async updateOrCreateQuestions(
    data: UpdateOrCreateQuestions
  ): Promise<SurveyFull | ErrorResult> {
    return await api
      .post("/api/v2/survey/update-store-questions", data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async changeStatus(id: number) {
    return await api
      .post(`/api/v2/survey/update-status/${id}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
