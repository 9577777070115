import { TrackingTabOptions } from "../components/TrakingTasksModal";

export const TRACKING_TASK_OPTIONS_DEFAULT: TrackingTabOptions = {
  show: {
    task: true,
    vehicle: true,
    notification: true,
    message: true,
    security: true,
    bluetooth: true,
  },
};

export const TRACKING_ROUTING_TASK_OPTIONS: TrackingTabOptions = {
  show: {
    task: true,
    vehicle: true,
    notification: false,
    message: false,
    security: false,
  },
};
