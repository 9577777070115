import { Modal } from "./styles";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { API_LOADER_MAP } from "../../constants/map";
import { useTranslation } from "react-i18next";

interface MapModalProps {
  visible: boolean;
  center: google.maps.LatLngLiteral;
  sensorId?: string;
  onClose?(): void;
}

const MapModal: React.FC<MapModalProps> = ({
  onClose,
  visible,
  center,
  sensorId,
  ...props
}) => {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader(API_LOADER_MAP);

  const handleCancel = () => {
    onClose?.();
  };

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <Modal.Title>
         {sensorId && (<>{t("sensors.table_sensor")} {sensorId}</>)} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-4">
        {isLoaded && (
          <GoogleMap
            id="modal_map_id"
            mapContainerStyle={{ width: "100%", height: "60vh" }}
            center={center}
            options={{ fullscreenControl: false }}
            zoom={15}
          >
            <MarkerF position={center} />
          </GoogleMap>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MapModal;
