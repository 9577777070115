import React from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { FaFilter } from "react-icons/fa";
import DoubleRangeSlider from "../DoubleRangeSlider";
import { useForm, Controller } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types";

interface OnChangeFilter {
  driver: number | string;
  ranking: { min: number; max: number };
}

interface DrivingBehaviorFilterModalProps {
  visible: boolean;
  onClose?(): void;
  onChange?(data: OnChangeFilter): void;
}

const DrivingBehaviorFilterModal: React.FC<DrivingBehaviorFilterModalProps> = ({
  onClose,
  visible,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const { control, register, handleSubmit, reset, watch } =
    useForm<OnChangeFilter>({
      defaultValues: { ranking: { min: 1, max: 10 } },
    });

  const handleCancel = () => {
    onClose?.();
    reset();
  };

  const onSubmit: SubmitHandler<OnChangeFilter> = (values) => {
    alert(JSON.stringify(values));
    onChange?.(values);
    // handleCancel();
  };

  return (
    <Modal {...props} show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <FaFilter size={18} />
        <Modal.Title>{t("text.filter")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Row className="my-5 mx-1">
            <Form.Group as={Col} controlId="driver">
              <Form.Label>{t("driving_behavior.table_driver")}</Form.Label>
              <Form.Select {...register("driver")}>
                <option value="">
                  {t("driving_behavior.filter_driver_enter")}
                </option>
                <option value="1">Alex Saldanha de Souza</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="ranking">
              <div className="d-flex justify-content-between mb-2">
                <Form.Label>{t("driving_behavior.table_ranking")}</Form.Label>
                <Form.Label>
                  {t("driving_behavior.filter_ranking_label_between")}{" "}
                  {watch("ranking")?.min}{" "}
                  {t("driving_behavior.filter_ranking_label_and")}{" "}
                  {watch("ranking")?.max}
                </Form.Label>
              </div>

              <div className="d-flex gap-3 align-items-center">
                <Form.Label className="m-0">1</Form.Label>
                <Col>
                  <Controller
                    control={control}
                    name="ranking"
                    render={({ field: { value, onChange } }) => (
                      <DoubleRangeSlider
                        min={1}
                        max={10}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
                <Form.Label className="m-0">10</Form.Label>
              </div>
            </Form.Group>
          </Row>

          <Row className="mt-2">
            <Stack
              className="d-flex justify-content-end"
              direction="horizontal"
              gap={3}
            >
              <Button onClick={handleSubmit(onSubmit, console.log)}>
                {t("button.save")}
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DrivingBehaviorFilterModal;
