import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ActionButtonOutline = styled(Button).attrs({
  variant: "outline-secondary",
})`
  min-height: 40px;
  padding: 6px 10px;
  border: 1px solid #ced4da;
  border-radius: 4.8px;
  background-color: #ffffff;
`;
