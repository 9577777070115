import React, { useState } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddButton from "../../../components/AddButton";

import { AbsoluteRight, Container } from "./styles";
import Pagination from "../../../components/Pagination";

import NewFormModal from "../../../components/NewFormModal";
import CustomThead from "../../../components/CustomThead";
import { Survey, SurveyQuery } from "../../../core/interfaces/Survey";
import { SurveyService } from "../../../core/services/SurveyService";
import { isErrorResult } from "../../../core/utils/api";
import { useForm, useFieldArray } from "react-hook-form";
import { ActionToolButton } from "../../../components/ActionToolButton";
import { TbEdit } from "react-icons/tb";
import { useAppSelector } from "../../../core/redux/hooks";
import { getUser } from "../../../core/redux/reducer/auth";
import { ProtectedComponent } from "../../../components/ProtectedComponent";
import { module_names } from "../../../constants/module_names";

type Register = Survey & {
  edited?: boolean;
};

type FormValues = {
  registers: Register[];
};

type Filters = {
  page: number;
  pages: number;
  order?: { [key: string]: string };
};

const Forms: React.FC = () => {
  const [isOpenNewForm, setOpenNewForm] = useState(false);
  const [defaultValues, setDefaultValues] = useState<FormValues>({
    registers: [],
  });
  const [pagination, setPagination] = useState<Filters>({ page: 1, pages: 0 });
  const [registerEditId, setRegisterEditId] = useState<number | undefined>(
    undefined
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { control, register, setValue, reset, watch } = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { fields } = useFieldArray({
    control,
    name: "registers",
    keyName: "fieldId",
  });

  const handleNewForm = () => {
    setOpenNewForm(true);
  };

  function handleSelectAction(item: Register) {
    setRegisterEditId(item.id);
    setOpenNewForm(true);
  }

  async function loadSurveys(params?: SurveyQuery) {
    setLoading(true);
    const response = await SurveyService.get(params);
    if (!isErrorResult(response)) {
      setPagination((prev) => ({
        ...prev,
        page: response.meta.current_page,
        pages: response.meta.last_page,
      }));
      setDefaultValues({ registers: response.data });
      reset({ registers: response.data }, { keepDirty: false });
    } else {
      console.log("Error loading survey data", response?.message);
    }
    setLoading(false);
  }

  const updateStatus = async (id: number) => {
    setLoading(true);
    const response = await SurveyService.changeStatus(id);
    if (!isErrorResult(response)) {
      loadSurveys();
      const registers = watch("registers");
      setDefaultValues({ registers });
      reset({ registers }, { keepDirty: false });
    }
  };

  React.useEffect(() => {
    const { page, order } = pagination;
    loadSurveys({ page, order });
  }, [pagination.order, pagination.page, user?.company_id]);

  return (
    <Container className="p-0">
      <Col xs="12">
        <Col className="mb-4" xs="6">
          <AddButton onClick={handleNewForm}>
            {t("configs.app.form_title")}
          </AddButton>
        </Col>

        <Table responsive>
          <CustomThead
            data={[
              { column: "title", name: t("configs.app.form_table_name") },
              {
                column: "mandatory",
                name: t("configs.app.form_table_required"),
              },
              { column: "type", name: t("configs.app.form_table_type") },
              { column: "stage", name: t("configs.app.form_table_stage") },
              { column: "status", name: t("configs.app.form_table_enabled") },
              { column: "menu", name: <div />, menu: true },
            ]}
            onChangeOrder={(order) =>
              setPagination((prev) => ({ ...prev, order }))
            }
          />

          <tbody>
            {fields.map((item, index) => (
              <tr key={item.fieldId}>
                <td>{item?.title}</td>
                <td>{item?.mandatory ? t("text.yes") : t("text.no")}</td>
                <td>{item?.type}</td>
                <td>{item?.step}</td>
                <ProtectedComponent
                  module_name={module_names.CONFIG_SURVEY}
                  property="edit"
                >
                  <td>
                    <Form.Switch
                      id={item.fieldId}
                      {...register(`registers.${index}.status`, {
                        onChange(event) {
                          setValue(`registers.${index}.edited`, true);
                          updateStatus(item?.id);
                        },
                      })}
                    />
                  </td>
                </ProtectedComponent>
                <ProtectedComponent
                  module_name={module_names.CONFIG_SURVEY}
                  property="edit"
                >
                  <td>
                    <ActionToolButton onClick={() => handleSelectAction(item)}>
                      <TbEdit />
                    </ActionToolButton>
                  </td>
                </ProtectedComponent>
              </tr>
            ))}
          </tbody>
        </Table>

        <Row>
          <Col className="d-flex justify-content-center">
            {isLoading && <Spinner animation="border" />}
          </Col>
        </Row>
      </Col>

      <AbsoluteRight>
        <Pagination
          current_page={pagination.page}
          qtd={pagination.pages}
          onSelectPage={(page) => setPagination((prev) => ({ ...prev, page }))}
        />
      </AbsoluteRight>

      <NewFormModal
        visible={isOpenNewForm}
        surveyId={registerEditId}
        onClose={() => {
          setRegisterEditId(undefined);
          setOpenNewForm(false);
        }}
        onSuccess={() => {
          setRegisterEditId(undefined);
          setOpenNewForm(false);
          loadSurveys({ page: pagination?.page, order: pagination?.order });
        }}
      />
    </Container>
  );
};

export default Forms;
