import React, { MouseEvent, useRef, useState } from "react";
import { Button, Col, Form, Spinner, Stack } from "react-bootstrap";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TbEdit, TbGripVertical, TbTrash } from "react-icons/tb";
import { ActionToolButton } from "../../../components/ActionToolButton";
import AddButton from "../../../components/AddButton";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Pagination from "../../../components/Pagination";
import { Occurrence } from "../../../core/interfaces/Occurrence";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { AppTypeOccurrenceResolver } from "../../../core/schemaValidations/app_type_occurrence";
import { OccurrenceService } from "../../../core/services/OccurrenceService";
import { isErrorResult } from "../../../core/utils/api";
import {
  AbsoluteLeft,
  AbsoluteRight,
  Container,
  Field,
  FieldList,
  THead,
} from "./styles";
import { getUser } from "../../../core/redux/reducer/auth";

type FormValues = {
  input: string;
  occurrences: Partial<{
    id: number;
    occurrence: string;
    company_id: number;
    fieldId?: string;
    isEdited?: boolean;
  }>[];
};

interface LoadData {
  filter: string;
  page: number;
}

const TypesOccurrence: React.FC = () => {
  const [isEditing, setEditing] = useState<string | false>(false);
  const [showConfirm, setShowConfirm] = useState<string | false>(false);
  const [occurrences, setOccurrences] = useState<Occurrence[]>([]);
  const [isBusy, setBusy] = useState<boolean>(false);
  const [showNewOccurrence, setShowNewOccurence] = useState(false);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: string;
  }>({ page: 1, pages: 0, filter: "" });

  const occurrenceRemovedRef = useRef<number | null>(null);
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
    getValues,
    resetField,
    reset,
  } = useForm<FormValues>({
    resolver: AppTypeOccurrenceResolver,
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "occurrences",
    keyName: "fieldId",
  });

  const handleAddField = async () => {
    if (!getValues("input")) {
      return setError("input", {
        message: t("configs.app.type_in.required"),
      });
    }

    append({
      occurrence: getValues("input"),
      isEdited: false,
    });
    resetField("input");
    setShowNewOccurence(false);
  };

  const handleDelete = (index: number, id?: number) => {
    if (!!id) {
      setShowConfirm(String(index));
      occurrenceRemovedRef.current = id;
    } else {
      remove(index);
    }
  };

  const handleCancel = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    reset();
    replace(occurrences);
    setEditing(false);
    clearErrors();
  };

  const onSubmit: SubmitHandler<FormValues> = async ({ occurrences }) => {
    try {
      for await (let data of occurrences) {
        if (!!data.id && data.isEdited) {
          await OccurrenceService.update(data?.id, data);
        } else if (!data.id) {
          await OccurrenceService.create(data);
        }
      }

      dispatch(updateAlert({ title: t("message.success.save_data") }));
    } catch (error: any) {
      alert(`${error.message}`);
    } finally {
      setEditing(false);
      loadOccurrences(pagination);
    }
  };

  const onSubmitDelete = async () => {
    if (!!occurrenceRemovedRef.current) {
      await OccurrenceService.delete(occurrenceRemovedRef.current);

      remove(+showConfirm);
      setShowConfirm(false);
      occurrenceRemovedRef.current = null;
    } else {
      remove(+showConfirm);
      setShowConfirm(false);
    }
  };

  const loadOccurrences = async ({ filter, page }: LoadData) => {
    if (isBusy) return;
    setBusy(true);

    try {
      const _response = await OccurrenceService.get({ filter, page });

      const isError = isErrorResult(_response);

      if (!isError && _response.data) {
        reset();
        replace(_response.data);
        setOccurrences(_response.data as any);

        setPagination((prev) => ({
          ...prev,
          page: _response.meta?.current_page || 0,
          pages: _response.meta?.last_page || 0,
        }));
      }
    } catch (error: any) {
      console.log("Erro in load type occurrences", error.message);
    } finally {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    loadOccurrences({ filter: "", page: 1 });
  }, [user?.company_id]);

  return (
    <Container className="p-0">
      <Form>
        <THead>
          <tr>
            <th>{t("configs.app.types_occurrence_title")}</th>
          </tr>
        </THead>

        <FieldList>
          {fields.map((item, index) => (
            <Field key={item.fieldId}>
              <Col sm={6}>
                <Stack direction="horizontal" gap={2}>
                  <TbGripVertical size="1.5rem" />

                  <Form.Group
                    style={{ flexGrow: 1 }}
                    controlId={`occurrences.${index}.occurrence`}
                  >
                    <Form.Control
                      plaintext={isEditing !== item.fieldId}
                      disabled={isEditing !== item.fieldId}
                      placeholder={t("configs.app.enter_occurrence_type")}
                      isInvalid={
                        !!errors?.occurrences?.[index]?.occurrence?.message
                      }
                      {...register(`occurrences.${index}.occurrence`)}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors?.occurrences?.[index]?.occurrence?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Stack>
              </Col>

              <Stack direction="horizontal" gap={2}>
                <ActionToolButton
                  onClick={async (e) => {
                    setEditing(item.fieldId);
                    setValue(`occurrences.${index}.isEdited`, true);
                  }}
                >
                  <TbEdit />
                </ActionToolButton>

                <ActionToolButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(index, item.id);
                  }}
                >
                  <TbTrash />
                </ActionToolButton>
              </Stack>
            </Field>
          ))}
        </FieldList>

        {showNewOccurrence && (
          <Field className="mb-4 pt-0 pb-2">
            <Col sm={6}>
              <Form.Group controlId="input">
                <Form.Control
                  placeholder={t("configs.app.enter_occurrence_type")}
                  isInvalid={!!errors?.input?.message}
                  {...register("input")}
                />

                <Form.Control.Feedback type="invalid">
                  {errors?.input?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Stack direction="horizontal" gap={2}>
              <Button onClick={handleAddField}>
                {t("configs.app.button_add")}
              </Button>
            </Stack>
          </Field>
        )}

        <AddButton
          children={t("configs.app.new_occurrence_type")}
          onClick={() => setShowNewOccurence(true)}
          disabled={showNewOccurrence}
        />
      </Form>

      <br />
      <br />
      <br />
      <br />

      <AbsoluteLeft>
        <Stack className="mb-3" direction="horizontal" gap={2}>
          <Button type="submit" onClick={handleSubmit(onSubmit, console.warn)}>
            {isSubmitting ? t("status.saving") : t("button.save")}
            {isSubmitting && <Spinner animation="border" size="sm" />}
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            {t("button.cancel")}
          </Button>
        </Stack>
      </AbsoluteLeft>

      <AbsoluteRight>
        <Pagination
          current_page={pagination.page}
          qtd={pagination.pages}
          onSelectPage={(page) => {
            loadOccurrences({ ...pagination, page });
          }}
        />
      </AbsoluteRight>

      <ConfirmationModal
        visible={!!showConfirm}
        title={t("configs.app.occurrence_type_modal_title")}
        onClose={() => {
          setShowConfirm(false);
          occurrenceRemovedRef.current = null;
        }}
        onConfirm={onSubmitDelete}
      />
    </Container>
  );
};

export default TypesOccurrence;
