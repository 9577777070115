import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  margin: -27px -24px 0;
  padding-top: 25px;

  .separator-line {
    height: 1px;
    width: 100%;
    background-color: #f2f2f2;
  }
`;

export const CustomLink = styled.a`
  color: #6c757d;

  :hover {
    cursor: pointer;
  }
`;


export const Header = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: #6C757D;
  border-bottom: 1px solid #F2F2F2;

  p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  
  button {
    border: none;
    bottom: white;
  }
`;

export const Content = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  height: ${(props) => (props.isOpen ? 'auto' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: height 0.3s ease, opacity 0.3s ease;
  padding: ${(props) => (props.isOpen ? '0 40px' : '0')};
`;

export const AccordionContainer = styled.div`
  margin-bottom: 10px;
`;


export const CustomButton = styled.button`
  background-color: transparent;
  border: none;
  color: #6C757D;
`;

export const ModalBody = styled(Modal.Body)`
  padding: 40px 85px;
  width: 600px;

  > p:nth-child(1){ 
    color: #6C757D;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 108.333% */
  }

  > p:nth-child(2){ 
    color: #6C757D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 49px;
  }
`;