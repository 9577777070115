export const en = {
  password: "password",
  search: "Search",
  searchJobDocument: "Doc. no.",
  painel: "Panel",
  "tela de janela": "Window screen",
  "porta do trailer": "Trailer door",
  "modo espera": "Fleetmate Status",
  "inicio viagem": "Start of trip",
  "fim de viagem": "End of trip",
  "Ao Entrar na Cerca Eletrô...": "When Entering the Electronic Fence...",
  "Ao Sair da Cerca Eletrô...": "When Leaving the Electronic Fence...",
  "trava do trailer": "Trailer lock",
  Carreta: "Carts",
  Carro: "Car",
  Moto: "Motorcycle",
  Toco: "Toco",
  Truck: "Truck",
  Utilitario: "Utility",
  Van: "Van",
  VUC: "UCV",
  Base: "Base",
  Cliente: "Client",
  no_equipment: "No Equipment",
  "Área de risco": "Risk area",
  "Ponto de parada autorizado": "Authorized stopping point",
  Rota: "Route",
  not_found: "Nothing found",
  "sidebar.change_company": "Change company",
  "sidebar.search": "Search...",
  "company.success": "Changed company",
  "tracking.modal_move_confirm":
    "You confirm the change of task to the vehicle",
  "tracking.modal_move_confirm_title":
    "Do you confirm changing the task to orphan?",
  "bloqueio de combustível": "Fuel lock",
  "trava de baú principal": "Main trunk lock",
  "trava de baú lateral": "Side trunk lock",
  "trava da quinta roda": "Fifth wheel lock",
  "quinta roda": "Fifth wheel",
  "porta do motorista": "Driver's door",
  "porta do carona": "Passenger door",
  engate: "Hitch",
  sirene: "Siren",
  export: "Export",
  FLUSH_DOOR_MAIN: "Secondary Chest Door",
  "aside.tracking": "Tracking",
  "aside.routing": "Routing",
  "aside.vehicles": "Vehicles",
  "aside.drivers": "Drivers",
  "aside.journey": "Journey",
  "aside.trips": "Trips",
  "aside.sensors": "Sensors",
  "aside.fences": "Fences",
  "aside.alerts": "Alerts",
  "aside.reports": "Reports",
  send: "Send",
  "porta baú lateral": "Side Trunk Door",
  "porta baú principal": "Main Chest Door",
  ignição: "Ignition",
  silo: "Silo",
  pânico: "Panic",
  electronic_fences: "Electronic fence",
  "month.January": "January",
  "month.February": "February",
  "month.March": "March",
  "month.April": "April",
  "month.May": "May",
  "month.June": "June",
  "month.July": "July",
  "month.August": "August",
  "month.September": "September",
  "month.October": "October",
  "month.November": "November",
  "month.December": "December",
  "button.save": "Save",
  "button.delete": "Delete",
  "button.cancel": "Cancel",
  "button.confirm": "Confirm",
  "confirm.message": "Confirmation",
  "text.yes": "Yes",
  "text.no": "No",
  "uploadbox.sub_title_link": "Download",
  "uploadbox.sub_title":
    "example for sending tasks in xls or send a file in zip format containing the xml's of the NFe's.",
  "uploadbox.success.message": "The Tasks Were Processed Successfully",
  "uploadbox.message": "Processing",
  "text.edit": "Edit",
  "text.delete": "Delete",
  "status.saving": "Saving... ",
  "status.sending": "Sending... ",
  "status.waiting": "Wait... ",
  "input.select_text": "Select",
  "link.go_back": "Come back",
  "text.filter": "Filter",
  "text.map_view": "View on Map",
  "text.route_view": "View Route",
  "text.required": "(Required)",
  "placeholde.select": "Chose vehicle...",
  "component.tag_label": "Attribute (TAG)",
  "message.confirm_delete_many_registers":
    "Are you sure you want to delete the selected records?",
  "message.confirm_delete_register":
    "Are you sure you want to delete this record?",
  selecteds: "Selecteds",

  "send.router": "Are you sure you want to send this routing for monitoring?",

  "login.welcome_message": "Welcome back!",
  "login.subtitle": "Enter your details to access the system",
  "login.password_label": "Password",
  "login.email_placeholder": "Type your e-mail",
  "login.password_placeholder": "Type your password",
  "login.remember_password": "Remember my password",
  "login.recover_password": "Recover password",

  "login.slide_first_title": "Chest temperature and humidity",
  "login.slide_first_subtitle":
    "Control the temperature and humidity of the trunk using Bluetooth data loggers with an internal battery of up to 5 years.",
  "login.slide_second_title": "Driving behavior",
  "login.slide_second_subtitle":
    "Avoid accidents and reduce your fleet's operating costs. Control events involving speeding, cell phone use, braking, acceleration and sudden turns.",
  "login.slide_third_title": "Electronic Fences",
  "login.slide_third_subtitle":
    "Create circular or polygonal fences to control the timing and movements of your fleet. Customize alerts and notifications and focus your team only on exceptions.",
  "login.slide_fourth_title": "Cloud Routing",
  "login.slide_fourth_subtitle":
    "Create optimized routes for your fleet considering numerous restrictions: such as service window, driver journey, vehicle attributes, volume, weight and value.",

  "forgot_password.back": "Back",
  "forgot_password.title": "Forgot password?",
  "forgot_password.subtitle":
    "Enter the email address you used when you joined and we'll send you instructions for resetting your password.",
  "forgot_password.second_subtitle":
    "For security reasons, we DO NOT store your password. So please be assured that we will never send your password via email.",
  "forgot_password.email_label": "Email address",
  "forgot_password.placeholder": "Type your e-mail",
  "forgot_password.button_message": "Send settings instructions",
  "forgot_password.success_message": "A message has been sent to your email.",

  "reset_password.title": "Redefine password",
  "reset_password.subtitle": "Create a new password",
  "reset_password.new_password": "New Password",
  "reset_password.new_password_placeholder": "Enter a new password",
  "reset_password.repeat_password": "New password (confirmation)",
  "reset_password.repeat_password_placeholder": "Repeat previous password",
  "reset_password.repeat_password_button": "Send settings instructions",
  "reset_password.repeat_password_success_message":
    "Submit Your password has been changed successfully!",
  "reset_password.repeat_password_error_message": "Invalid token",
  "reset_password.back_to_login": "Back to login",

  "drivers.add": "Add Drivers",
  "drivers.title": "Drivers",
  "drivers.upload_title": "Drag here to import multiple drivers",
  "drivers.tasks_title": "Total Outstanding Routing Tasks",
  "drivers.tasks_button": "View Tasks",
  "drivers.table_title": "Authorized Drivers",
  "drivers.table_name": "DRIVER NAME",
  "drivers.table_document": "DOCUMENT",
  "drivers.table_email": "E-MAIL",
  "drivers.table_phone": "PHONE",
  "drivers.table_authorized": "AUTHORIZED",
  "drivers.table_responsible": "RESPONSIBLE USER",
  "drivers.delete_selected_registers": "Delete Selected Records",
  "drivers.delete_many_registers.message":
    "Are you sure you want to delete the selected drivers?",
  "drivers.delete_registers.message":
    "Are you sure you want to delete this driver?",
  "drivers.add_upload_label":
    "Add the drivers' email, telephone or CPF number.",
  "drivers.add_upload_subtitle":
    "The data must be separated by a comma or semicolon.",
  "drivers.add_upload_enter": "Copy and Paste Here",
  "drivers.add_button": "Add",
  "drivers.cancel_button": "Cancel",
  "drivers.add_driver_text_confirm":
    "Please check the records and confirm the addition of drivers",
  "drivers.add_register_found": "registrations found",
  "drivers.success_title": "drivers added successfully!",
  "drivers.add.message_error": "Failed to add drivers",
  "drivers.delete_many_success_title": "drivers successfully deleted!",
  "drivers.delete_success_title": "Driver deleted successfully!",
  "drivers.delete_many.message_error": "Failed to delete selected drivers",
  "drivers.delete.message_error": "Failed to delete driver",
  "drivers.empty_list.message": "You don't have any authorized drivers yet",

  "routing.add": "Create new task manually",
  "routing.title": "Routing",
  "routing.upload_title": "Upload Tasks",
  "routing.tasks_title": "Pending Routing Tasks",
  "routing.tasks_button": "Select and Route",
  "routing.table_title": "Routings performed",
  "routing.table_date": "Creation date",
  "routing.table_id": "ID",
  "routing.table_status": "STATUS",
  "routing.table_qtd_tasks": "TASKS",
  "routing.table_qtd_routes": "ROUTES",
  "routing.table_total_distance": "Total Km",
  "routing.table_user": "USER",
  "routing.tasks_view_title": "View Tasks",
  "routing.selected_tasks": "Selected Routing Tasks",
  "routing.routing_start_button": "Start Routing",
  "routing.table_task_id": "Task ID",
  "routing.table_customer": "Customer",
  "routing.table_address": "Address",
  "routing.table_type": "Type",
  "routing.table_weight": "Weight",
  "routing.table_volume": "Volume",
  "routing.table_value": "Value",
  "routing.table_document_label": "Document Number",
  "routing.divergence_error.type_service_required":
    "Type of service required – The type of service must be entered.",
  "routing.divergence_error.tag_not_found":
    "Tag not found – The tag you are looking for was not found.",
  "routing.divergence_error.valor_required":
    "Value required – A value must be entered.",
  "routing.divergence_error.valor_required_numeric":
    "Value required – The value entered must be numeric.",
  "routing.divergence_error.valor_required_greater_than_or_equal_to_0":
    "Value must be greater than or equal to 0 – The value entered must be greater than or equal to zero.",
  "routing.divergence_error.volume_required":
    "Volume required – A volume must be entered.",
  "routing.divergence_error.volume_required_numeric":
    "Volume required numeric – The volume entered must be numeric.",
  "routing.divergence_error.volume_required_greater_than_or_equal_to_0":
    "Volume must be greater than or equal to 0 – The volume entered must be greater than or equal to zero.",
  "routing.divergence_error.peso_required":
    "Weight required – A weight is required.",
  "routing.divergence_error.peso_required_numeric":
    "Weight required numeric – The weight entered must be numeric.",
  "routing.divergence_error.peso_required_greater_than_or_equal_to_0":
    "Weight must be greater than or equal to 0 – The weight entered must be greater than or equal to zero.",
  "routing.divergence_error.address_required":
    "Address mandatory – An address is required.",
  "routing.divergence_error.data_jurney_final_required":
    "Mandatory journey end date – The journey end date is required.",
  "routing.divergence_error.data_jurney_start_required":
    "Mandatory journey start date – The journey start date is required.",
  "routing.divergence_error.hour_jurney_final_required":
    "Mandatory journey end time – The journey end time is required.",
  "routing.divergence_error.hour_jurney_start_required":
    "Mandatory journey start time – The journey start time is required.",
  "routing.divergence_error.latitude_invalid":
    "Invalid latitude – The latitude provided is not valid.",
  "routing.divergence_error.longitude_invalid":
    "Invalid longitude – The longitude provided is not valid.",
  "routing.divergence_error.date_jurney_final_pickup_required":
    "Mandatory pickup end date – You must enter the final pickup date.",
  "routing.divergence_error.date_jurney_start_pickup_required":
    "Mandatory pickup start date – You must enter the initial pickup date.",
  "routing.divergence_error.hour_jurney_final_pickup_required":
    "Mandatory pickup end time – You must enter the final pickup time.",
  "routing.divergence_error.hour_jurney_start_pickup_required":
    "Mandatory pickup start time – You must enter the initial pickup time.",
  "routing.divergence_error.address_de_retirada_required":
    "Pickup address required – You must enter the pickup address.",
  "routing.divergence_error.latitude_pickup_invalid":
    "Invalid pickup latitude – The pickup latitude entered is not valid.",
  "routing.divergence_error.longitude_pickup_invalid":
    "Invalid pickup longitude – The pickup longitude entered is not valid.",
  "routing.inconsistent_address": "Inconsistent Address",
  "routing.send_success_title": "Routing successfully sent.",
  "routing.send_success_message":
    "Follow the processing on the initial routing screen or click here to consult now.",
  "routing.confirm_modal_popover":
    "When creating a task for future routing, it will be available to be included in a new routing.",
  "routing.confirm_modal_title": "Confirm Routing",
  "routing.confirm_modal_subtitle":
    "To confirm your routing, please enter the information below and click confirm.",
  "routing.start_location_label": "Output Location",
  "routing.start_location_select": "Select an exit location",
  "routing.start_label": "Start",
  "routing.end_label": "End",
  "routing.location_title_modal": "Correct Address",
  "routing.location_import_address": "Import Address",
  "routing.location_correct_address_label": "Correct Address",
  "routing.location_correct_address_enter": "Enter the correct address here",
  "routing.until": "until",
  "routing.filter_select_inconsistent_address_label":
    "Select only tasks with inconsistent address",
  "routing.task_type_label": "Task Type",
  "routing.option_delivery": "Delivery",
  "routing.option_collect": "Collect",
  "routing.option_service": "Service",
  "routing.option_collect_and_service": "Collection + Delivery",

  "routing.customer_enter": "Enter customer name here",
  "routing.cod_customer_enter": "Enter customer code here",
  "routing.service_window_label": "Routing Window",
  "routing.service_time_label": "Routing Time",
  "routing.location_label": "Location",
  "routing.delete_selected": "Delete Selected Records",
  "routing.observation_label": "Observations",

  "routing.footer_amount_routes": "Number of routes",
  "routing.footer_amount_jobs": "Number of Tasks",
  "routing.footer_amount_meters": "Km Total",
  "routing.footer_amount_amount": "Total Value",
  "routing.footer_amount_weight": "Total Weight",
  "routing.footer_amount_km": "Total Volume",

  "routing.phone_enter": "Enter phone number here",
  "routing.document_label": "Document Number",
  "routing.document_enter": "Enter document here",
  "routing.collect_location_label": "Pickup Location",
  "routing.modal.confirm":
    "When creating a task for immediate execution, it will be allocated to the closest vehicle that meets all the characteristics of the task.",
  "routing.modal.startdate":
    "When choosing the Start Date the date should be greater than Today ",
  "routing.modal.finaldate":
    "When choosing the End Date the date should be greater than the starting date ",
  "routing.tags_popover":
    "Use tags to add operation restrictions to the task. Example: use the 'refrigerated' tag so that the task is only allocated in a refrigerated vehicle.",
  "routing.run_task_immediately_label": "Run Task Immediately",
  "routing.run_task_immediately_popover":
    "When creating a task for immediate execution, it will be allocated to the closest vehicle that meets all the characteristics of the task.",
  "routing.request_prior_monitoring_validation_label":
    "Request Prior Monitoring Validation",
  "routing.request_prior_monitoring_validation_popover":
    "Priority tasks will be executed before other tasks.",
  "routing.create_button_submit": "Create",
  "routing.update_button_submit": "Update",
  "routing.quantity_label": "Capacity Used",
  "routing.weight_label": "Weight",
  "routing.amount_label": "Amount",
  "routing.value_label": "Volume",
  "routing.material_label": "Material",
  "routing.material_type_select": "Select material type",
  "routing.form_title": "Create Task Manually",
  "routing.confirm_delete_many_registers":
    "Are you sure you want to delete the selected tasks?",
  "routing.confirm_delete_register":
    "Are you sure you want to delete this task?",
  "routing.confirm_delete_message":
    "It will be sent to the routing module, where it can be reprogrammed.",
  "routing.date_start_enter": "Start Date",
  "routing.date_end_enter": "End Date",
  "routing.service_window_collect_label": "Collection Service Window",
  "routing.edit.title": "Edit Task",
  "routing.select.departure": "Departure Location",
  "routing.select.arrival": "Arrival Location",
  "routing.select.message":
    "To confirm your routing, please enter the details below and  click confirm.",
  "routing.select.departure.dropdown": "Select an output location",

  "routing.apartment_label": "Apartment",
  "routing.apartment_pickup_label": "Apartment Pickup",
  "routing.phone_label": "Phone",
  "routing.phone_pickup_label": "Phone Pickup",
  "routing.customer_label": "Customer",
  "routing.mandatory.field": "Fields marked as ",
  "field.mandatory": " are mandatory",

  "routing.customer_pickup_label": "Customer Pickup",

  "routing.map_header_Creation_Date": "Creation Date",
  "routing.map_header_Sending_Date": "Sending Date",
  "routing.map_header_Status": "Status",
  "routing.map_header_User": "User",

  "routing.modal.network_status": "Network Status",
  "routing.status.Roterization completed": "Roterization completed",
  "routing.status.Sent to monitoring": "Sent to monitoring",
  "routing.status.Pending review": "Pending review",
  "routing.status.Roterization empty":
    "Routing without routes due to time or distance",
  "routing.status.Roterization failed - vroom":
    "Unable to process this routing. Please try again later",

  "configs.title": "Settings",

  "configs.company.language_title": "Language and Regional Settings",
  "configs.company.country": "Country",
  "configs.company.language": "Language",
  "configs.company.timezone": "Timezone",
  "configs.company.company_title": "Company",
  "configs.company.company_name": "Company Name",
  "configs.company.company_name_enter": "Enter the company name",
  "configs.company.cnpj": "CNPJ",
  "configs.company.street": "Street",
  "configs.company.street_enter": "Enter the street",
  "configs.company.number": "Number",
  "configs.company.complement": "Complement",
  "configs.company.complement_enter": "Enter an add-on",
  "configs.company.neighborhood": "Neighborhood",
  "configs.company.neighborhood_enter": "Enter the neighborhood",
  "configs.company.state": "UF",
  "configs.company.city": "City",
  "configs.company.zipcode": "Zipcode",
  "configs.company.state_label": "State",
  "configs.company.email": "Billing E-mail",
  "configs.company.email_enter": "Enter your email",
  "configs.company.save": "Save",
  "configs.company.cancel": "Cancel",

  "configs.exit_location.back": "New Exit Location",
  "configs.exit_location.form_location_name": "Location name",
  "configs.exit_location.form_location": "Location",
  "configs.exit_location.form_address": "Address",
  "configs.exit_location.form_coordinates": "Coordinates",

  "configs.exit_location.title": "Exit Locations",
  "configs.exit_location.add": "New Exit Location",
  "configs.exit_location.search": "Search",
  "configs.exit_location.table_name": "Name",
  "configs.exit_location.table_address": "Address",
  "configs.exit_location.table_enabled": "Enabled",
  "configs.exit_location.confirm_modal_title":
    "Are you sure you want to delete this start point?",

  "configs.exit_location.form_placeholder_name": "Enter name",
  "configs.exit_location.form_placeholder_location": "Enter the address",

  "search_box_places.coords": "Press Enter to search for coordinates",

  // Configs Routing Tabs
  "configs.routing.routing_rule_tab": "Routing Rules",
  "configs.routing.vehicle_journey_tab": "Vehicle Journey",
  "configs.routing.capabilities_tab": "Capabilities",
  "configs.routing.types_material_tab": "Types of Material",

  // Configs Routing Rules
  "configs.routing.rule_title": "Routing Rules",
  "configs.routing.allow_vehicle_recharge": "Allow Vehicle Recharge",
  "configs.routing.grouping_deliveries_breakpoint":
    "Grouping Deliveries at Breakpoint",
  "configs.routing.create_open_routes": "Create Open Routes",
  "configs.routing.allow_dynamic_routing": "Allow Dynamic Routing",
  "configs.routing.allow_multi_day_routes": "Allow Multi-Day Routes",
  "configs.routing.optimization_standard": "Optimization Standard",
  "configs.routing.optimize_mileage": "Optimize Mileage",
  "configs.routing.optimize_service_time": "Optimize Service Time",
  "configs.routing.traffic_conditions": "Traffic Conditions",
  "configs.routing.slow": "Slow",
  "configs.routing.normal": "Normal",
  "configs.routing.fast": "Fast",
  "configs.routing.measurement_system": "Measurement System",
  "configs.routing.metric_system": "Metric System (Kilometers)",
  "configs.routing.imperial_system": "Imperial System (Miles)",

  // Configs Routing Vehicle journey
  "configs.routing.vehicles_journey_title": "Vehicles Day",
  "configs.routing.start_journey": "Start of the Journey",
  "configs.routing.end_journey": "End of Journey",
  "configs.routing.average_service_time": "Average Service Time (TMA)",
  "configs.routing.maximum_tasks_per_route":
    "Maximum Number of Tasks per Route",
  "configs.routing.include_lunch_break": "Include Rest/Lunch Break",
  "configs.routing.first_interval": "First Interval",
  "configs.routing.second_interval": "Second Interval",
  "configs.routing.third_interval": "Third Interval",
  "configs.routing.start_interval": "Start of Interval",
  "configs.routing.end_interval": "End of Interval",
  "configs.routing.optional": "Optional",

  // Configs Routing Capability
  "configs.routing.capability_title": "Vehicle Capacity",
  "configs.routing.capability": "Capacity",
  "configs.routing.tank": "Tank",
  "configs.routing.enable_capability": "Enable Capacity",
  "configs.routing.difine_transported_meterial": "Define Transported Material",
  "configs.routing.allocate_only_one_task_per_capacity":
    "Allocate Only One Task per Capacity",

  // Configs Routing Types Material
  "configs.routing.types_material_title": "Types of Material",
  "configs.routing.enter_material_type": "Enter a material type",
  "configs.routing.new_material_type": "New Material Type",
  "configs.routing.material_type.required": "Meterial type is a required field",

  // Configs Driver App Types Occurrence
  "configs.app.types_occurrence_title": "Type of Occurrence",
  "configs.app.enter_occurrence_type": "Enter an event type",
  "configs.app.new_occurrence_type": "New Type of Occurrence",
  "configs.app.type_in.required": "Enter an event type name",
  "configs.app.occurrence_type_modal_title":
    "Are you sure you want to delete this type of occurrence?",

  "configs.app.block_vehicle_ignition":
    "Bloquear a ignição do veículo no estouro da jornada",
  "configs.app.not_allow_driver_disable_voice":
    "Do not allow the driver to disable voice notifications",

  // Configs Driver App journey Control
  "configs.app.journey_control_enable": "Enable Driver's Journey Control",
  "configs.app.journey_control_legislation": "Workday Control Legislation",
  "configs.app.journey_control_select": "Select...",

  // Configs Driver App Form
  "configs.app.form_title": "New Form",
  "configs.app.form_title_edit": "Edit Form",
  "configs.app.form_table_name": "Name",
  "configs.app.form_table_required": "Mandatory completion",
  "configs.app.form_table_stage": "Stage",
  "configs.app.form_table_enabled": "Enabled",
  "configs.app.form_table_type": "Form type",

  "configs.app.form_name": "Form Name",
  "configs.app.form_required": "Make this form mandatory",
  "configs.app.question_without_title": "Untitled question",
  "configs.app.option_type_short": "Short answer",
  "configs.app.option_type_long": "Long answer",
  "configs.app.option_type_multiple": "Multiple choice",
  "configs.app.option_type_selection": "Selection",
  "configs.app.option_type_images": "Images",
  "configs.app.response_required": "Mandatory answer",
  "configs.app.button_add_option": "Add option",
  "configs.app.button_new_question": "new question",
  "configs.app.button_add": "Add",
  "configs.app.button_cancel": "Cancel",
  "configs.app.option_type_job": "Job",
  "configs.app.option_type_vehicle": "Vehicle",

  // Configs Driver App Form
  "configs.app.table_type_status": "Status Types",
  "configs.app.table_journer_relation": "Status Link to Journey Control",
  "configs.app.type_status.required": "Status Type is required",
  "configs.app.journey_entry.required": "Journey tracking is required",
  "configs.app.select": "Select...",
  "configs.app.journer_select": "Select status link to journey control...",
  "configs.app.enter_driver_status": "Enter a Status",
  "configs.app.new_driver_status": "New Status",
  "configs.app.disable_auto_detection":
    "Disable Auto Detection of Stopped and Driving Status",
  "configs.app.driver_status.modal_title":
    "Are you sure you want to delete this status?",
  "configs.app.driver_status.request_password":
    "Request driver password at each new status",

  // Configs Driver App General Settings
  "configs.app.general_settings.title": "Application Modules",
  "configs.app.general_settings.subtitle":
    "Select the modules that will be enabled for your drivers",
  "configs.app.general_settings.tasks": "Tasks",
  "configs.app.general_settings.alerts": "Alerts",
  "configs.app.general_settings.driver_status": "Drivers Status",
  "configs.app.general_settings.chat_messages": "Messages (Chat)",
  "configs.app.general_settings.ranking": "Ranking (Director Behavior)",
  "configs.app.general_settings.peripherals": "Peripherals",
  "configs.app.general_settings.drivers_workflow": "Drivers Workflow",
  "configs.app.general_settings.proof_tasks":
    "Submission of Proof of Tasks (Photo)",
  "configs.app.general_settings.shipping_required": "Mandatory Shipping",
  "configs.app.general_settings.type_occurrences":
    "Submission of the Type of Occurrences",
  "configs.app.general_settings.proof_occurrences":
    "Submission of Proof of Occurrences (Photo)",

  "configs.contractors.header.title": "Contractor Settings",
  "configs.contractors.title": "Contractors",
  "configs.contractors.search": "Search",
  "configs.contractors.table_contractor": "CONTRACTOR",
  "configs.contractors.table_vehicles": "VEHICLES AVAILABLE",
  "configs.contractors.table_disponibility": "DISPONIBILITY",
  "configs.contractors.table_advanced_control": "ADVANCED CONTROL",
  "configs.contractors.button.edit": "Edit Restrictions",
  "configs.contractor.confirm_modal_title":
    "Are you sure you want to delete this contractor?",
  "configs.contractor.confirm_modal_message": "This action cannot be reversed.",
  "configs.contractors.form_restrictions_title": "Edit Restrictions",
  "configs.contractors.form_restrictions_description":
    "Make available only vehicles that have the attributes (TAG) listed.",
  "configs.contractors.form_label_tags": "Attributes (TAG)",
  "configs.contractor.empty_list": "You don't have any contractors yet",

  "configs.subcontractors.title": "Subcontractors",
  "configs.subcontractors.add": "Invite Subcontractor",
  "configs.subcontractors.search": "Search",
  "configs.subcontractors.table_subcontractor": "SUBCONTRACTOR",
  "configs.subcontractors.table_vehicle": "AGGREGATE VEHICLE",
  "configs.subcontractors.table_email": "EMAIL",
  "configs.subcontractors.table_visibility": "VISIBLE",
  "configs.subcontractors.table_status": "STATUS",

  "configs.subcontractors.form_title": "Invite Subcontractor",
  "configs.subcontractors.form_name": "Name",
  "configs.subcontractors.form_name_placeholder": "Enter name here...",
  "configs.subcontractors.form_email": "Email",
  "configs.subcontractors.form_email_placeholder": "Enter email here...",
  "configs.subcontractor.empty_list": "You don't have any Subcontractors yet",
  "configs.subcontractor.confirm_modal_title":
    "Do you want to hide subcontracted vehicles in the system?",
  "configs.subcontractor.confirm_modal_message":
    "This action cannot be reversed.",
  "configs.subcontractor.confirm_approval_title":
    "Do you want to make subcontractor-released vehicles visible in the system?",
  "configs.subcontractor.successfully_invited":
    "Successfully invited subcontractor!",
  "configs.subcontractor.successfully_approved":
    "Vehicles successfully made visible!",
  "configs.subcontractor.successfully_disapproved":
    "Vehicles successfully hidden!",
  "configs.subcontractor.active": "Active",
  "configs.subcontractor.inactive": "Inactive",
  "configs.subcontractor.awaiting_approval": "Waiting approval",
  "configs.subcontractor.visibility": "Visibility",
  "configs.api.title":
    "Here you can create API keys for production and Sandbox environments.",
  "configs.api.client_id": "Client ID",
  "configs.api.client_secret": "Generate new ClientSecret",
  "configs.api.production": "New API Key in Production",
  "configs.api.sandbox": "New API Key in Sandbox",
  "configs.api.copy": "Copy",

  "configs.menu.company": "Company",
  "configs.menu.billing": "Billing",
  "configs.menu.manage_license": "Manage license",
  "configs.menu.go_to_checkout": "Go to Checkout",
  "configs.menu.edit_contact": "Edit Contact for Invoice",
  "configs.menu.edit_billing_address": "Edit Billing Address",
  "configs.menu.last_name": "surname",
  "configs.menu.billing_iridium_connectivity": "Iridium Connectivity",
  "configs.menu.billing_connectivity": "LoRaWAN connectivity",
  "configs.menu.journey_control": "Journey Control",
  "configs.menu.advanced_library": "Advanced CAN-BUS Library",
  "configs.menu.video_monitoring": "Video monitoring",
  "configs.menu.sensores_bluetooth": "Bluetooth Sensors",
  "configs.menu.routing_backoffice": "Routing and Backoffice",
  "configs.menu.plan_essential": "Essential",
  "configs.menu.plan_advanced": "Advanced",
  "configs.menu.plan_security": "Security",
  "configs.menu.plan_professional": "Professional",
  "configs.menu.chose_plan": "Choose Plan",
  "configs.menu.current_plan": "Current Plan",
  "configs.menu.monthly": "Monthly",
  "configs.menu.main_features": "Main Features",
  "configs.menu.fleet_tracking": "Fleet Tracking",
  "configs.menu.electronic_fences": "Electronic Fences",
  "configs.menu.travel_history": "Travel History",
  "configs.menu.integration_api": "Integration API",
  "configs.menu.delivery_control": "Delivery Control",
  "configs.menu.digital_forms": "Digital Forms",
  "configs.menu.security": "Security (Powered by FleetMate)",
  "configs.menu.invoice_date": "Invoice History",
  "configs.menu.date_hour": "INVOICE DATE",
  "configs.menu.invoice": "INVOICE",
  "configs.menu.value": "Value",
  "configs.menu.status": "STATUS",
  "configs.menu.your_plan": "Your plan",
  "configs.menu.licences": "Licenses",
  "configs.menu.by_licences": "By license",
  "configs.menu.by": "By",
  "configs.menu.by_active_driver": "By active driver",
  "configs.menu.manage_licenses": "Manage Licenses",
  "configs.menu.manage_plan": "Manage Plan",
  "configs.menu.last_invoice": "Last Invoice",
  "configs.menu.estimate_of_the_next_invoice": "Estimate of the Next Invoice",
  "configs.menu.payment_method": "Payment method",
  "configs.menu.invoice_contact": "Invoice Contact",
  "configs.menu.billing_address": "Billing address",
  "configs.menu.empty_table": "No invoices found",
  "configs.menu.sms_trigger": "SMS trigger",
  "configs.menu.exit_locations": "Exit Locations",
  "configs.menu.routing": "Routing Options",
  "configs.menu.users": "Users",
  "configs.menu.contractors": "Contractors",
  "configs.menu.subcontractors": "Subcontractors",
  "configs.menu.sms": "SMS Notifications",
  "configs.menu.app": "Driver App",
  "configs.menu.mate": "FleetMate",
  "configs.menu.api": "API Integrations",
  "configs.menu.audit": "Audit",
  "configs.menu.audit.filter_modal.select_type": "Select the type...",
  "configs.menu.audit.filter_modal.select_origin": "Select the origin...",
  "configs.menu.audit.filter_modal.select_action": "Select the action...",
  "configs.menu.audit.download_modal":
    "The report has been generated and will be sent by email.",
  "configs.menu.audit.no_records": "There are no records.",

  "sensors.audit.temperature": "Temperature Sensor",
  "sensors.audit.speed": "Speed Sensor",
  "sensors.audit.fuel": "Fuel Sensor",
  "sensors.audit.battery": "Battery Sensor",
  "sensors.audit.CO2": "CO₂ Sensor",
  "sensors.audit.camera": "Camera",
  "sensors.audit.moisture": "Moisture Sensor",

  "configs.user.add": "New User",
  "configs.user.edit_access_level": "Edit access level",
  "configs.user.table_name": "NAME",
  "configs.user.table_email": "EMAIL",
  "configs.user.table_nivel": "LEVEL",
  "configs.user.table_status": "STATUS",
  "configs.user.table_enabled": "ENABLED",
  "configs.user.reset_password": "Reset Password",
  "configs.user.user_delete": "Delete User",
  "configs.user.user_status_active": "Active",
  "configs.user.user_status_guest": "Guest",
  "configs.user.invite_user": "Invite User",
  "configs.user.name": "Name",
  "configs.user.name_enter": "Enter name",
  "configs.user.email": "Email",
  "configs.user.email_enter": "Enter email",
  "configs.user.access_level": "Access Level",
  "configs.user.access_level_enter": "Select a level",
  "configs.user.access_level_view": "View",
  "configs.user.access_level_edit": "Edit",
  "configs.user.access_level_security": "Security",
  "configs.user.access_level_commands": "Commands",
  "configs.user.access_level_delete": "Deletion",
  "configs.user.configs.user.link_tags_plates": "Link Tags and Vehicles",

  "configs.sms.back": "Back",
  "configs.sms.add": "New Notification",
  "configs.sms.table_enabled": "enabled",
  "configs.sms.table_subject": "subject",
  "configs.sms.table_time": "time of notification",
  "configs.sms.form_select": "Select...",
  "configs.sms.form_input_moment": "When will the customer be notified?",
  "configs.sms.form_label_sms_subject": "What will be the subject of the SMS?",
  "configs.sms.form_label_sms_content": "What will be the content of the SMS?",
  "configs.sms.textarea_text": "characters remaining.",
  "configs.sms.field_title": "Autocomplete Fields",

  "configs.audit.title": "Audit",
  "configs.audit.add": "New Notification",
  "configs.audit.filter_start_date": "Registration Start Date",
  "configs.audit.filter_end_date": "Registration End Date",
  "configs.audit.table_register_date_hour": "REGISTRATION DATE AND TIME",
  "configs.audit.table_register_type": "RECORD TYPE",
  "configs.audit.table_event_date_hour": "EVENT DATE AND TIME",
  "configs.audit.table_user": "USER",
  "configs.audit.table_driver": "DRIVER",
  "configs.audit.form_select": "Select...",
  "configs.audit.form_input_moment": "When will the customer be notified?",
  "configs.audit.form_label_sms_subject":
    "What will be the subject of the SMS?",
  "configs.audit.form_label_sms_content":
    "What will be the content of the SMS?",
  "configs.audit.textarea_text": "characters remaining.",
  "configs.audit.field_title": "Autocomplete Fields",
  "configs.audit.sensor.output_text": "Output",
  "configs.audit.system_record": "System Records",
  "configs.audit.positions": "Positions",
  "configs.audit.messages": "Messages",
  "configs.audit.login": "Login",
  "configs.audit.electronic_fences": "Electronic Fences",
  "configs.audit.driver_app_settings": "Driver App Settings",
  "configs.audit.commands": "Commands",
  "configs.audit.command.expired": "Expired",
  "configs.audit.command.received": "Received",
  "configs.audit.command.sent": "Sent",
  "configs.audit.status": "Status",
  "configs.audit.register_date_hour": "Date and Time of Registration",
  "configs.audit.event_date_hour": "Event Date and Time",
  "configs.audit.datetime_execution": "Execution Date and Time",
  "configs.audit.expiration_datetime": "Expiration Date and Time",
  "configs.audit.confirmation_datetime": "Confirmation Date and Time",
  "configs.audit.login_datetime": "Login Date and Time",
  "configs.audit.datetime_receipt": "Date and Time of Receipt",
  "configs.audit.datetime_reading": "Reading Date and Time",
  "configs.audit.date_reading": "Reading Date",
  "configs.audit.action": "Action",
  "configs.audit.origin": "Origin",
  "configs.audit.user": "User",
  "configs.audit.driver": "Driver",
  "configs.audit.license_plate": "Vehicle Plate",
  "configs.audit.plate": "Plate",
  "configs.audit.command_origin": "Command Origin",
  "configs.audit.settings_origin": "Settings Origin",
  "configs.audit.network": "Network",
  "configs.audit.rule": "Rule",
  "configs.audit.actuators": "Actuators",
  "configs.audit.sensors": "Sensors",
  "configs.audit.electronic_fence_name": "Electronic Fence Name",
  "configs.audit.sender": "Sender",
  "configs.audit.recipient": "Recipient",
  "configs.audit.message": "Message",
  "configs.audit.speed": "Speed",
  "configs.audit.ignition": "Ignition",
  "configs.audit.battery_voltage": "Battery Voltage",
  "configs.audit.location": "Location",
  "configs.audit.temperature_sensor": "Temperature Sensor",
  "configs.audit.changed_field": "Change Field",
  "configs.audit.initial_registration": "Initial Registration",
  "configs.audit.final_registration": "Final Registration",
  "audit.options.creation": "Creation",
  "audit.options.edition": "Exclusion",
  "audit.options.exclusion": "Edit",
  "configs.audit.logout": "Logout",
  "audit.options.settings": "Settings",
  "audit.options.forms": "Forms",
  "audit.options.alerts": "Alerts",
  "audit.options.driver_journey": "Driver's Journey",
  "audit.options.drivin_behavior": "Driving Behavior",
  "audit.options.vehicles": "Vehicles",
  "audit.options.drivers": "Drivers",
  "audit.options.optimization": "Optimization",
  "configs.audit.record_origin": "Record Origin",
  "configs.audit.record_type": "Record Type",
  "configs.audit.license_plate_label": "Vehicle (License Plate)",
  "configs.audit.create": "Create",
  "configs.audit.update": "Update",
  "configs.audit.delete": "Delete",
  "configs.audit.create_geofence": "Create Geofence",
  "configs.audit.run_command": "Run Command on Fleetmate",
  "configs.audit.setup_configurations": "Setup configurations",
  "configs.audit.remove_geofences": "Remove geofences",
  "configs.audit.create_rule": "Create security rule",
  "configs.audit.remove_rule": "Remove security rule",
  "configs.audit.fleetmate_sensors": "Fleetmate sensors",
  "configs.audit.remove_registers": "Remove records from the queue",

  // Configs Driver App Tabs
  "configs.app.general_settings_tab": "General Settings",
  "configs.app.driver_status_tab": "Drivers Status",
  "configs.app.forms_tab": "Forms",
  "configs.app.journey_control_tab": "Journey Control",
  "configs.app.types_occurrence_tab": "Types Occurrences",

  "vehicles.title": "Vehicles",
  "vehicles.button_add": "New Vehicles",
  "vehicles.new.title": "Register Vehicle",
  "vehicles.edit.title": "Edit Vehicle",
  "vehicles.table_title": "New Vehicles",
  "vehicles.table_type": "Type",
  "vehicles.table_board": "Board",
  "vehicles.table_created_at": "Creation data",
  "vehicles.table_price_by_km": "Cost per km",
  "vehicles.table_hardware": "Hardware",
  "vehicles.table_maximum_volume": "Maximum Volume",
  "vehicles.table_maximum_weight": "Maximum Weight",
  "vehicles.table_maximum_value": "Maximum Value",
  "vehicles.table_availability": "Availability",
  "vehicles.delete_many_text": "Delete Selected Records",
  "vehicles.confirm_delete_many_title":
    "Are you sure you want to delete selected records?",
  "vehicles.confirm_delete_title":
    "Are you sure you want to delete this vehicle?",
  "vehicles.tab.hardware_title": "Hardware",
  "vehicles.tab.register_title": "Registration",
  "vehicles.form.label.license_plate": "Plate",
  "vehicles.form.label.license_plate.invalid":
    "Vehicle plate number must be provided",
  "vehicles.form.label.vehicle_type": "Vehicle Type",
  "vehicles.form.label.vehicle_type.invalid":
    "The type of vehicle must be informed",
  "vehicles.form.label.price_by_km": "Cost Per KM",
  "vehicles.form.label.active": "Make Vehicle Available",
  "vehicles.form.label.weight": "Weight",
  "vehicles.form.label.weight.invalid":
    "The maximum weight capacity must be informed",
  "vehicles.form.label.amount": "Amount",
  "vehicles.form.label.amount.invalid": "The maximum value must be informed",
  "vehicles.form.label.tank": "Tank",
  "vehicles.form.label.volume": "Volume",
  "vehicles.form.label.volume.invalid": "The maximum volume must be informed",
  "vehicles.form.select.material_type": "Select Material Type",
  "vehicles.form.label.tag": "Attributes",
  "vehicles.form.description.tag":
    "Vehicle attributes are used to categorize reports and include constraints in route optimization.",
  "vehicles.form.label.observation": "Observations",
  "vehicles.form.enter.observation": "Type here...",
  "vehicles.hardware.message.confirm_disable_sensor":
    "Are you sure you want to disable this sensor?",
  "vehicles.hardware.message.confirm_enable_sensor":
    "Are you sure you want to enable this sensor?",
  "vehicles.hardware.message.confirm_send_command":
    "Are you sure you want to send this command?",
  "vehicles.hardware.send_command_satellite.label":
    "Send command also via satellite.",
  "vehicles.hardware.message.success_send_command":
    "The command was sent successfully. It will be executed shortly.",
  "vehicles.hardware.title.error_send_command": "Failed to execute command",
  "vehicles.hardware.title.error_generate_actuator_code":
    "Failed to generate actuator code",
  "vehicles.hardware.sensor.status_confirm.label": "Status Confirmation",
  "vehicles.hardware.unlink_equipment": "Unlink Equipment",
  "vehicles.hardware.serial": "Serial Number",
  "vehicles.hardware.firmware": "Firmware Version",
  "vehicles.hardware.manufacturer": "Manufacturer Name",
  "vehicles.hardware.model": "Model",
  "vehicles.hardware.sensor.title": "Sensors",
  "vehicles.hardware.actuator.title": "Actuators",
  "vehicles.hardware.against_password.title": "Password Generation",
  "vehicles.hardware.disable_actuator.label":
    "Select the actuator you want to disable",
  "vehicles.hardware.button.generate": "Generate",
  "vehicles.hardware.against_password_view": "Click generate to view Password",
  "vehicles.hardware.against_password_expires":
    "This Password will expire in: ",
  "vehicles.hardware.video_monitoring.title": "Video Monitoring",
  "vehicles.hardware.camera.label": "Camera",
  "vehicles.hardware.camera.last_update": "Last update",
  "vehicles.hardware.camera.network_type": "Network Type",
  "vehicles.hardware.network_status.title": "Network Status",
  "vehicles.hardware.no_cameras": "This vehicle has no cameras",
  "vehicles.sensor.exit": "Exit",
  "vehicles.sensor.entry": "Entry",
  "vehicles.empty_list.message": "You don't have any vehicle registered yet",
  "vehicles.hardware.copy": "Copy",
  "vehicles.hardware.copied": "Copied!",
  "vehicles.hardware.sensor.status_active.label": "Active",
  "vehicles.hardware.sensor.status_inactive.label": "Inactive",
  "vehicles.video_monitoring": "Monitoring",
  "vehicles.video_monitoring.last_update": "Last update",
  "vehicles.video_monitoring.network_type": "Network type",
  "vehicles.video_monitoring.hardware_information": "Hardware Information",
  "vehicles.video_monitoring.imei": "IMEI",
  "vehicles.video_monitoring.sim_card": "SIM card number",
  "vehicles.video_monitoring.model": "Model",
  vehicles_manufacturer_empty: "No manufacturer",
  associante_vehicle_modal_title: "Vehicle Association",
  link_vehicle: "Associate vehicle",
  unlink_vehicle: "Unlink vehicle",

  "trips.title": "Travel and Tasks",
  "trips.table_id": "Id task",
  "trips.table_date": "Appointment date",
  "trips.table_status": "Status",
  "trips.table_driver": "Driver",
  "trips.table_travelled_distance": "km Traveled",
  "trips.table_task": "Tasks",
  "trips.table_driving_time": "Driving Time",
  "trips.table_stops": "Downtime",
  "trips.table_alerts": "Alerts",
  "trips.table_task_details": "See details",
  "trips.table_empty_list": "Select a vehicle and dates to view the report",

  "trips.eye.finished_routes": "Routes taken",
  "trips.eye.planned_routes": "Planned routes",
  "trips.eye.waypoints": "Waypoints",

  "trips.pannel.qtd_vehicles": "Qtde. Vehicles",
  "trips.pannel.average_speed": "Spd.Average",
  "trips.pannel.qtd_trips": "Qtd.Trips",
  "trips.pannel.qtd_stop": "Qtd.Paradas",
  "trips.pannel.total_tasks": "Total Tasks",
  "trips.pannel.completed": "Completed",
  "trips.pannel.occurrence": "Occurrences",
  "trips.pannel.partials": "Partial",

  "trips.detail.title": "Task Details",
  "trips.detail.table_doc": "Documents",
  "trips.detail.table_status": "Status",
  "trips.detail.table_customer": "Customer",
  "trips.detail.table_voucher": "Vouncher",
  "trips.detail.table_occurrence": "Occurrence",
  "trips.detail.table_occurrence_date": "Occurrence Date",
  "trips.detail.table_document_number": "Document number",
  "trips.detail.table_service_date": "Service date",
  "trips.detail.table_duration": "Duration",
  "trips.detail.table_note_place": "Note place",
  "trips.detail.table_route_description": "Route description",
  "trips.detail.view_on_map": "View on map",

  "trips.task_detail.question": "Question",
  "trips.task_detail.response": "Response",
  "trips.task_detail.customer": "Customer",
  "trips.task_detail.doc": "Document",
  "trips.task_detail.status": "Status",
  "trips.task_detail.address": "Address",
  "trips.task_detail.phone": "Phone",
  "trips.task_detail.weight": "Weight",
  "trips.task_detail.volume": "Volume",
  "trips.task_detail.service_window": "Service window (effective hours)",
  "trips.task_detail.expected_duration":
    "Expected duration (effective duration)",
  "trips.task_detail.value": "Value",
  "trips.task_detail.attributes": "Attributes",

  "sensors.title": "Sensors",
  "sensors.table_date": "Date",
  "sensors.table_hour": "Hour",
  "sensors.table_sensor": "Sensor",
  "sensors.table_type": "Type",
  "sensors.table_last_read": "Last Read",
  "sensors.table_vehicle": "Vehicle",
  "sensors.table_localization": "Localization",
  "sensors.table_driver": "Driver",
  "sensors.table_alert": "Alert",

  "sensor_detail.table_view_route": "View Route",
  "sensor_detail.table_view_map": "View on Map",

  "journey.title": "Journey of Drivers",
  "journey.drivers_title": "Drivers",
  "journey.table_activity": "ACTIVITY",
  "journey.table_duration": "DURATION ATV.",
  "journey.table_start": "START",
  "journey.table_end": "END",
  "journey.table_location": "LOCATION",
  "journey.table_overflow": "OVERFLOW",
  "journey.table_extra": "ORDINARY HOURS",
  "journey.table_alerts": "ALERTS",
  "journey.driver_name": "DRIVER",
  "journey.vehicle_license_plate": "VEHICLE",
  "journey.cards.journey": "Journey",
  "journey.cards.driving": "Driving",
  "journey.cards.rest": "Rest",
  "journey.cards.wait": "Wait",
  "journey.cards.lunch": "Lunch",
  "journey.cards.extra_hour": "Extra Hour",
  "journey.cards.ordinary_extra_hour": "Ordinary Hours",
  "journey.add_register": "Add Registration Manually",
  "journey.we_found": "We found",
  "journey.registers": "records",
  "journey.journey_reports": "Journey Reports",
  "journey.time_sheet_type": "Timesheet Report Type",
  "journey.consolidated": "Consolidated",
  "journey.detailed": "Detailed",
  "journey.time_sheet": "Time Sheet",
  "journey.archive_afd_afdt": "Archive AFD / AFDT",
  "journey.edit_records": "Edit Records",
  "journey.selected_driver": "Selected Driver",
  "journey.all": "All",
  "journey.form_title_add": "Add Record",
  "journey.form_title_edit": "Edit Registry",
  "journey.form_title_delete": "Delete Record",
  "journey.form_vehicle_label": "Vehicle",
  "journey.form_active_type": "Activity type",
  "journey.form_date_initial": "Start",
  "journey.form_date_final": "End",
  "journey.form_reason_label_add": "Reason for Adding.",
  "journey.form_reason_label_edit": " Reason for Editing.",
  "journey.form_reason_label_delete": "Delete Reason.",
  "journey.form_reason_enter": "Describe here...",
  "journey.form_reason_characters": "characters left.",
  "journey.button_disabled_add_register": "Select a driver",

  "calendar.january": "January",
  "calendar.february": "February",
  "calendar.march": "March",
  "calendar.april": "April",
  "calendar.may": "May",
  "calendar.june": "June",
  "calendar.july": "July",
  "calendar.august": "August",
  "calendar.september": "September",
  "calendar.october": "October",
  "calendar.november": "November",
  "calendar.december": "December",
  "calendar.sunday": "Sunday",
  "calendar.monday": "Monday",
  "calendar.tuesday": "Tuesday",
  "calendar.wednesday": "Wednesday",
  "calendar.thursday": "Thursday",
  "calendar.friday": "Friday",
  "calendar.saturday": "Saturday",

  "configs.fleetmate.settings": "Settings",
  "configs.fleetmate.embedded_intelligence": "Embedded Intelligence",
  "configs.fleetmate.ei.add_button": "Add Ruleset",
  "configs.fleetmate.ei.add_button_new_rule": "Add Rule",
  "configs.fleetmate.ei.add_button_new_setup": "New Setup",
  "configs.fleetmate.ei.rule_name": "Rule name",
  "sensors.rule.table_ruleset_name": "Ruleset name",
  "configs.fleetmate.ei.created_at": "Creation date",
  "configs.fleetmate.ei.updated_at": "latest edition",
  "configs.fleetmate.ei.user": "User",
  "configs.fleetmate.ei.qtd_events": "Number of events",
  "configs.fleetmate.ei.eligibility": "Enabling",
  "configs.fleetmate.ei.action_vehicle_association": "Vehicle Association",
  "configs.fleetmate.ei.label_from_data": "Start date",
  "configs.fleetmate.ei.label_to_data": "End date",
  "configs.fleetmate.ei.confirm_modal_title":
    "Are you sure you want to delete this record",
  "configs.fleetmate.ei.confirm_modal_message":
    "This action cannot be reversed",
  "configs.fleetmate.ei.rule_name_placeholder": "Type a name here...",
  "configs.fleetmate.ei.create_rule_using": "Create Rule Using:",
  "configs.fleetmate.ei.status_sensors": "Sensors Status",
  "configs.fleetmate.ei.status_driver": "Driver Status",
  "configs.fleetmate.ei.electronic_fences": "Electronic Fences",
  "configs.fleetmate.ei.sensors": "Sensor",
  "configs.fleetmate.ei.sensors_description":
    "Which sensors will trigger the rule?",
  "configs.fleetmate.ei.status_sensor": "Sensor Status",
  "configs.fleetmate.ei.status_sensor_description":
    "Which status will trigger the rule?",
  "configs.fleetmate.ei.status_driver_description":
    "Which driver status will trigger the rule?",
  "configs.fleetmate.ei.group_electronic_fences": "Electronic fence trigger",
  "configs.fleetmate.ei.group_electronic_fences_description":
    "Which electronic fence groups will trigger the rule?",
  "configs.fleetmate.ei.length_of_stay": "Length of stay",
  "configs.fleetmate.ei.electronic_fences_when": "When?",
  "configs.fleetmate.ei.electronic_fences_when_description":
    "When the rule will be activated",
  "configs.fleetmate.ei.when_the_rule_will_be_deactivated":
    "When the rule will be deactivated",
  "configs.fleetmate.ei.trigger_by_time_pt1":
    "Trigger the rule if the driver doesn't report a status after",
  "configs.fleetmate.ei.trigger_by_time_pt2": "minutes stopped.",
  "configs.fleetmate.ei.actuators_label": "Actuators",
  "configs.fleetmate.ei.actuators_description":
    "(Select the actions that the rule will perform)",
  "configs.fleetmate.ei.fuel_blockage": "Fuel Blockage",
  "configs.fleetmate.ei.chest_blockage": "Main Chest Lock",
  "configs.fleetmate.ei.fifth_wheel_blockage": "Fifth Wheel Lock",
  "configs.fleetmate.ei.siren": "Siren",
  "configs.fleetmate.ei.side_chest_blockage": "Side Chest Lock",
  "configs.fleetmate.ei.wait_pt1": "Wait",
  "configs.fleetmate.ei.wait_pt2": "seconds in condition to trigger rule",
  "configs.fleetmate.ei.generate_alert_monitoring":
    "Generate Alert on Monitoring",
  "configs.fleetmate.ei.disable_jammer_detection": "Disable Jammer Detection",
  "configs.fleetmate.ei.tags_label": "Tags",
  "configs.fleetmate.ei.tags_description":
    "(Use to apply the rule only to vehicles associated with tags)",
  "configs.fleetmate.setting.interval_lorawan":
    "Communication Range in LoRaWAN Network",
  "configs.fleetmate.setting.interval_iridium":
    "Communication Interval in the Iridium Network",
  "configs.fleetmate.setting.ignition_on": "Ignition On",
  "configs.fleetmate.setting.ignition_off": "Ignition Off",
  "configs.fleetmate.setting.entries": "Entries",
  "configs.fleetmate.setting.entrie": "Entry",
  "configs.fleetmate.setting.outputs": "Outputs",
  "configs.fleetmate.setting.output": "Output",
  "configs.fleetmate.setting.driver_controls_exit":
    "Allow the driver to control the exit",
  "configs.fleetmate.setting.type": "Type",
  "configs.fleetmate.setting.type_encoded": "Encoded Output",
  "configs.fleetmate.setting.type_decoded": "Not Codified",
  "configs.fleetmate.setting.tolerance_time": "Tolerance Time",
  "configs.fleetmate.setting.tolerance_for_rule_execution":
    "Tolerance for rule execution",
  "traking.center": "Central",
  "configs.fleetmate.setting.seconds": "seconds",
  "configs.fleetmate.ei.vehicle_type": "Vehicle Type",
  "configs.fleetmate.ei.attributes_tag": "Attributes (TAG)",
  "configs.fleetmate.ei.vehicle_association": "Vehicle Association",
  "configs.fleetmate.ei.vehicle": "Vehicle",
  "configs.fleetmate.ei.vehicle_select": "Select a vehicle...",
  "configs.fleetmate.ei.configs.fleetmate.ei.rule_drive_move":
    "Trigger the rule if the driver moves the vehicle more than",
  "configs.fleetmate.ei.configs.error.new_driver_status":
    "Driver status required",

  "configs.fleetmate.ei.change_driver_status_to": "Change Driver Status to: ",
  "configs.fleetmate.ei.warning_the_driver": "Warning the driver",
  "configs.fleetmate.ei.generate_alert_in_monitoring":
    "Generate Alert in Monitoring",
  "configs.fleetmate.ei.sending_alerts_satellite":
    "Sending Alerts via the Satellite Network",

  "fleetmate.time.meters": "meters",
  "fleetmate.time.minute": "Minute",
  "fleetmate.time.minutes": "Minutes",
  "fleetmate.entries.fifth_wheel": "Fifth Wheel",
  "fleetmate.entries.siren": "Siren",
  "fleetmate.entries.fuel_blockage": "Fuel Blockage",
  "fleetmate.entries.trunk_lock": "Chest Lock",
  "fleetmate.entries.side_door": "Side Door",
  "fleetmate.entries.flush_door": "Main Door",
  "fleetmate.entries.right_door": "Right Door",
  "fleetmate.entries.left_door": "Left Door",
  "fleetmate.entries.panel": "Panel",
  "fleetmate.entries.coupling": "Coupling",
  "fleetmate.entries.trailer_door": "Trailer door",
  "fleetmate.entries.panic_button": "Panic",
  "fleetmate.entries.ignition": "Ignition",
  "fleetmate.entries.silo": "Silo",
  "fleetmate.entries.flush_door_main": "Main chest door",
  "fleetmate.entries.panel_narrow": "Window Screen",
  "fleetmate.outings.fuel_block": "Fuel Blockage",
  "fleetmate.outings.side_door_lock": "Side chest lock",
  "fleetmate.outings.flush_door_lock": "Main chest lock",
  "fleetmate.outings.fifth_wheel_lock": "Fifth Wheel Lock",
  "fleetmate.outings.sirem_alarm_lock": "Alarm",
  "fleetmate.outings.trailer_door_lock": "Trailer lock",
  "fleetmate.outings.ignition_lock": "Ignition lock",
  "fleetmate.sensor.side_chest": "Side Chest",
  "fleetmate.sensor.main_chest": "Main Chest",
  "fleetmate.sensor.jammer_detection": "Jammer Detection",
  "fleetmate.sensor.hitch": "Hitch",
  "fleetmate.sensor.panic": "Panic",
  "fleetmate.sensor.window_screen": "Window Screen",
  "fleetmate.sensor.actived": "Activated",
  "fleetmate.sensor.disabled": "Disabled",
  "fleetmate.sensor.right_door": "Right door",
  "fleetmate.sensor.left_door": "Left door",
  "fleetmate.efg.operation_base": "Operation Base",
  "fleetmate.efg.client": "Customer",
  "fleetmate.efg.authorized_stopping_point": "Authorized Stopping Point",
  "fleetmate.efg.authorized_route": "Authorized Route",
  "fleetmate.efg.system_routes": "System Routes (Route Optimization)",
  "fleetmate.efg.jammer_interface_zone": "Jammer Interference Zone",
  "fleetmate.efg.risk_zone": "Risk Zone",

  "fleetmate.actuators.level_block": "Blocking level",

  "fleetmate.geofence_base": "Base",
  "fleetmate.geofence_client": "Client",
  "fleetmate.geofence_risk_area": "Risk área",
  "fleetmate.geofence_autorized": "Authorized stopping point",

  "actuators.fuel_blockage": "Fuel Blockage",
  "actuators.lock_main_chest": "Main Chest Lock",
  "actuators.fifth_wheel_lock": "Fifth Wheel Lock",
  "actuators.lock_side_chest": "Side Chest Lock",
  "actuators.siren": "Siren",

  "sensor.side_chest": "Side Chest",
  "sensor.fifth_wheel": "Fifth Wheel",
  "sensor.main_chest": "Main Chest",
  "sensor.right_door": "Right Door",
  "sensor.panel": "Panel",
  "sensor.panic": "Panic",
  "sensor.ignition": "Ignition",
  "sensor.hitch": "Hitch",

  "component.input_tag_new.placeholder": "Add a new tag: ",
  "component.input_tag.placeholder": "Add tag",
  "component.input_tag.emptylabel": "No results to display.",

  "configs.user.access_profile.title": "Edit Access Levels",
  "configs.user.access_profile.checkbox_all": "Select all",
  "configs.user.access_profile.message.success":
    "Access Levels Edited Successfully.",

  "message.success.save_data": "Data saved successfully!",
  "message.error.save_data": "Error saving data!",
  "message.error.ownuser": "You cannot change your own data!",
  "message.error.save_data.existplate":
    "This plate is already registered or the plate format is incorrect.",
  "message.success.delete_data": "Successfully deleted record!",
  "message.error.delete_data": "Error deleting record!",
  "message.success.delete_many_data": "Successfully deleted records!",
  "message.error.delete_many_data": "Error deleting records!",
  "message.error.default": "There was an Error Please check your Details",
  "message.error.date": "End Date must be Greater than Start Date",
  "message.action_not_reversed": "This action cannot be reversed",
  "message.success.load_data": "Data successfully loaded.",
  "message.error.load_data": "Failed to load data!",
  "message.failed.start.route":
    "Failed to start Roterization, Provide more details and continue",
  "message.success.start_route":
    "Script sent successfully. Follow the processing on the initial routing screen.",
  "message.user_delete": "Are you sure you want to delete the user?",
  "message.user_reset_password":
    "Are you sure you want to reset the user password?",
  "message.user_successfully_reset_password": "Password reset successfully!",
  "message.user_error_reset_password": "Error resetting password!",
  "message.user_successfully_reset_password_send_email":
    "The user will receive a link in the registered email to create a new password.",
  "message.user_successfully_deleted": "User successfully deleted!",
  "message.user_successfully_guest": "Successfully invited user!",
  "message.user_receive_link":
    "The user will receive a link in the registered email.",
  "message.error_other_email": "Enter another email",
  "message.error_invalid_name": "Invalid name",

  "fence_group.client": "Client",
  "fence_group.operation_base": "Operation Base",
  "fence_group.risk_zone": "Risk Zone",
  "fence_group.authorized_route": "Authorized Waypoint",
  "fence_group.authorized_stopping_point": "Authorized Route",

  "weekday.sunday": "Sunday",
  "weekday.monday": "Monday",
  "weekday.tuesday": "Tuesday",
  "weekday.wednesday": "Wednesday",
  "weekday.thursday": "Thursday",
  "weekday.friday": "Friday",
  "weekday.saturday": "Saturday",

  "electronic_fences.title": "Electronic Fences",
  "electronic_fences.new.title": "New Electronic Fence",
  "electronic_fences.edit.title": "Edit Electronic Fence",
  "electronic_fences.button_add": "New Electronic Fence",
  "electronic_fences.table_name": "Name",
  "electronic_fences.table_type": "Type",
  "electronic_fences.table_group": "Group",
  "electronic_fences.table_created_at": "Date Created",
  "electronic_fences.table_qtd_alert": "Amount of Alerts",
  "electronic_fences.table_author": "Author",
  "electronic_fences.table_enable": "Enable",
  "electronic_fences.initial_data": "Start date",
  "electronic_fences.final_data": "End Date",
  "electronic_fences.action_associate_vhicles": "Associate Vehicles",
  "electronic_fences.action_retroact": "Retroact",
  "electronic_fences.action_edit": "Edit Electronic Fence",
  "electronic_fences.action_delete": "Delete Electronic Fence",
  "electronic_fences.label_electronic_fence_name": "Electronic Fence Name",
  "electronic_fences.enter_electronic_fence_name": "Type the name here...",
  "electronic_fences.label_fences_group": "Fence Group",
  "electronic_fences.select_fences_group": "Choose a group...",
  "electronic_fences.title_electronic_fence_alerts": "Electronic Fence Alerts",
  "electronic_fences.label_alert_when": "Alert when:",
  "electronic_fences.title_hours": "Hours",
  "electronic_fences.label_period_from": "From",
  "electronic_fences.label_period_to": "Until",
  "electronic_fences.label_enter_fence": "Enter fence",
  "electronic_fences.label_exit_fence": "Exit at fence",
  "electronic_fences.label_stay_longer": "Stay longer than",
  "electronic_fences.label_report": "Report",
  "electronic_fences.label_fence_minutes": "fences minutes",
  "electronic_fences.label_remain_less": "Remain less than",
  "electronic_fences.label_fence_km": "km/h on the fence.",
  "electronic_fences.label_speed_higher": "Speed is greater than",
  "electronic_fences.label_email_notify": "Notify via email",
  "electronic_fences.label_notify_monitoring": "Notify on monitoring",
  "electronic_fences.label_email_address": "Email address",
  "electronic_fences.label_email_address_detail": "• Comma separated • Up to 5",
  "electronic_fences.label_tags":
    "Attributes (Only generate alerts for vehicles with the following attributes)",
  "electronic_fences.map_option_circle": "Circle",
  "electronic_fences.map_option_polygonal": "Polygonal",
  "electronic_fences.map_option_route": "Route",
  "electronic_fences.map_import_route": "Import Route",
  "electronic_fences.map_import_stop_point": "Import Stop Points",
  "electronic_fences.map_new_circle": "Create Circle Fence",
  "electronic_fences.map_new_polygonal": "Create Polygonal Fence",
  "electronic_fences.map_new_route": "Create Route Fence",
  "electronic_fences.map_info_import_route":
    "Use this option to import all the references of the route used. This option is recommended when you do not want to use the route calculated by the system.",
  "electronic_fences.map_info_import_stop_point":
    "Use this option to import only the references of the waypoints on the route. In this option, the route will be calculated automatically by the system.",
  "electronic_fences.map_label_address": "Address",
  "electronic_fences.map_label_radius": "Radius (Km)",
  "electronic_fences.map_enter_address": "Enter address here...",
  "electronic_fences.map_import_references": "Import References",
  "electronic_fences.map_download_template": "Download Template",
  "electronic_fences.map_add_route": "Add Destination",
  "electronic_fences.map_label_perimeter": "Perimeter (Km)",
  "electronic_fences.map_maintain_association_from":
    "Maintain association from",
  "electronic_fences.map_maintain_association_to": "until",
  "electronic_fences.map_label_membership_indefinitely":
    "Keep membership indefinitely.",
  "electronic_fences.select_author": "Select the author...",
  "electronic_fences.select_type": "Select the type...",
  "electronic_fences.show_enabled_fences": "Show only enabled fences",
  "electronic_fences.confirm_delete_title":
    "Are you sure you want to delete this electronic fence?",

  "traking.customize_grid": "Customize Grid",
  "traking.option_path": "Route",
  "traking.option_vehicle": "Vehicles",
  "traking.option_customer": "Customers",
  "traking.option_electronic_fence": "Electronic Fence",
  "traking.filter_vehicle_type": "Vehicle Type",
  "traking.filter_vehicle": "Vehicle",
  "traking.filter_driver": "Driver",
  "traking.filter_driver_status": "Status Driver",
  "traking.filter_route_description": "Route description",
  "traking.filter_geofence": "Geofence",
  "traking.filter_select_label": "Select Filter",
  "traking.filter_initial_date": "Start",
  "traking.filter_final_date": "End",
  "traking.filter_add_new": "New Filter",
  "traking.customize_grid_subtitle":
    "Select and order the items that will appear on the Grid.",
  "traking.table_route_description": "Route description",
  "traking.table_timeline_tasks": "Tasks Timeline",
  "traking.table_route": "Route",
  "traking.table_plate": "Plate",
  "traking.table_status": "Status",
  "traking.table_battery": "Battery",
  "traking.table_left_door": "Driver's door",
  "traking.table_right_door": "Hitchhiking door",
  "traking.table_fifth_wheel": "Fifth wheel",
  "traking.table_side_door": "Side Chest Door",
  "traking.table_flush_door": "Rear Chest Door",
  "traking.table_panel": "Panel",
  "traking.table_panel_narrow": "Window screen",
  "traking.table_coupling": "Coupling",
  "traking.table_trailer_door": "Trailer door",
  "traking.table_panic_button": "Panic Button",
  "traking.table_ignition": "Ignition",
  "traking.table_silo": "Silo",
  "traking.table_fuel_block": "Fuel Block",
  "traking.table_side_door_lock": "Side chest lock",
  "traking.table_flush_door_lock": "Rear chest lock",
  "traking.table_fifth_wheel_lock": "Fifth wheel lock",
  "traking.table_sirem_alarm_lock": "Alarm",
  "traking.table_trailer_door_lock": "Trailer lock",
  "traking.table_flush_door_main": "Main chest door",
  "traking.table_macaddress": "Mac Address",
  "traking.table_location": "Location",
  "traking.table_driving_time": "Driving Time",
  "traking.table_time_stoped": "Time stopped",
  "traking.table_idle_engine_time": "Idle Engine Time",
  "traking.table_address": "Address",
  "traking.table_speed": "Speed",
  "traking.table_options": "Options",
  "traking.table_geofence": "Geofence",
  "traking.btn_hidden": "Hide",
  "traking.msg_box": "Message Box",
  "traking.notification_center": "Notification Center",
  "traking.btn_all_read": "Mark all as read",
  "traking.btn_add_comment": "Add comment",
  "traking.btn_mark_read": "Mark as read",
  "traking.label_comment": "Comment",
  "traking.enter_message": "Menssage...",
  "traking.task_detail_customer": "Customer",
  "traking.task_detail_phone": "Phone",
  "traking.task_service_window": "Attendance Window",
  "traking.task_service_time": "Attendance Time",
  "traking.table_document": "Document",
  "traking.table_image": "Image",
  "traking.task_eta_provided": "Scheduled",
  "traking.task_eta_accomplished": "Accomplished",
  "traking.task_previous": "Previous",
  "traking.task_next": "Next",
  "traking.status_pending": "Pending",
  "traking.status_completed": "Completed",
  "traking.status_partial": "Partial",
  "traking.attached_image": "Attached image",
  "traking.attached_doc": "Attached document",
  "traking.task_detail_vehicle": "Vehicle",
  "traking.task_detail_driver": "Driver",
  "traking.task_detail_cell_phone": "Cell Phone",
  "traking.task_detail_route_id": "Route ID",
  "traking.task_detail_total_value": "Total value",
  "traking.task_detail_volume_total": "Total volume",
  "traking.task_detail_weight_total": "Total weight",
  "traking.task_detail_journey": "Journey",
  "traking.task_detail_total_route_km": "Total Route Km",
  "traking.task_detail_start_of_route": "Start of route",
  "traking.task_detail_end_of_route": "End of route",
  "traking.task_detail_fuel_consumption": "Fuel consumption/km",
  "traking.task_detail_fuel_total": "Total fuel",
  "traking.task_detail_total_toll": "Total toll",
  "traking.task_network_status": "Network Status",
  "traking.task_actuators": "Actuators",
  "traking.task_sensors": "Sensors",
  "traking.notify_review": "Review",
  "traking.notify_comment": "Comment",
  "traking.footer_number_routes": "Number of Routes",
  "traking.footer_completed_tasks": "Completed Tasks",
  "traking.footer_occurrences": "Occurrences",
  "traking.footer_delays": "Delays",
  "traking.footer_average_service_time": "Average Service Time",
  "traking.footer_average_speed": "Average Speed",
  "traking.task_type_label": "Task type",
  "traking.task_review_observation_label": "Remarks",
  "traking.task_review_documents_label": "Documents",
  "traking.task_review_driver_label": "Driver",
  "traking.task_review_allocated_vehicle_label": "Allocated Vehicle",
  "traking.task_review_weight_label": "Weight",
  "traking.task_review_volume_label": "Volume",
  "traking.task_review_value_label": "Value",
  "traking.task_review_exit_point_label": "Exit Point Distance",
  "traking.task_review_task_location_label": "Task Location Distance",
  "traking.task_review_title": "Review Task",
  "traking.task_comment_title": "Add Comment",
  "traking.task_comment": "Comment",
  "traking.task_task_type": "Task Type",
  "traking.table_km": "Km",
  "traking.table_total_weight": "Weight",
  "traking.table_total_volume": "Volume",
  "traking.table_total_amount": "Value",
  "traking.table_last_date_comunication_vehicle": "Last Vehicle Communication",
  "traking.table_last_date_update_gps_vehicle": "Last GPS Update",
  "traking.task.modal_locked": "Locked",
  "traking.task.modal_open": "Open",
  "traking.task.modal_blocked": "Blocked",
  "traking.task.modal_unblocked": "Unblocked",
  "traking.task.modal_fifth_wheel_blocked": "Engaged",
  "traking.task.modal_fifth_wheel_unblocked": "Disengaged",
  "traking.task.modal_off": "Off",
  "traking.task.modal_on": "On",
  "traking.task.modal_disabled": "Disabled",
  "traking.task.modal_enabled": "Enabled",
  "traking.task.modal_lock": "Lock",
  "traking.task.modal_unlock": "Unlock",
  "traking.task.modal_block": "Block",
  "traking.task.modal_unblock": "Unblock",
  "traking.task.modal_turnoff": "Turn Off",
  "traking.task.modal_turnon": "Turn On",
  "traking.task.modal_disable": "Disable",
  "traking.task.modal_enable": "Enable",
  "traking.task.modal_sensors_entry": "Sensors (Inputs)",
  "traking.task.modal_actuators_exit": "Actuators (Outputs)",
  "traking.task.modal_executing": "Command in Execution",
  "traking.task.modal_current_status": "Current Status",
  "traking.task.modal_executed": "Command Executed",
  "traking.task.modal_network_error": "All communication networks are offline.",
  "traking.task.modal_attendance_window": "to",
  "traking.task.modal_arrival_time": "Arrival time",
  "traking.task.modal_phone_number": "Phone number",
  "traking.notifications.modal_today": "Today",
  "traking.notifications.modal_yesterday": "Yesterday",

  "traking.routing.error_document_duplicated": "Document number already exists",
  "traking.routing.error_document_invalid": "Document number is invalid",
  "traking.routing.error_task_type_invalid": "Task type is invalid",
  "traking.routing.error_vehicle_invalid": "Vehicle data is invalid",
  "traking.routing.error_cod_customer_invalid": "Customer code is invalid",
  "traking.routing.error_customer_invalid": "Customer name is invalid",
  "traking.routing.error_address_invalid":
    "The address is invalid. Please check if it is correct and/or exists",
  "traking.routing.error_weight_invalid": "Weight is invalid",
  "traking.routing.error_volume_invalid": "Volume is invalid",
  "traking.routing.error_amount_invalid": "Amount is invalid",
  "traking.routing.error_input_invalid_details":
    "Some input value is empty, incorrect or invalid",
  "traking.routing.error_failed_routerization": "Routerization failed",
  "traking.routing.error_failed_routerization_details":
    "Please check your address and client information and ensure it is valid.",

  "driving_behavior.title": "Driving Behavior",
  "driving_behavior.subtitle": "Drivers",
  "driving_behavior.table_ranking": "Ranking",
  "driving_behavior.table_driver": "Driver",
  "driving_behavior.table_km_traveled": "Km Traveled",
  "driving_behavior.table_driving_hours": "Driving Hours",
  "driving_behavior.table_acceleration": "Acceleration",
  "driving_behavior.table_braking": "Braking",
  "driving_behavior.table_cell_phone": "Cell phone usage",
  "driving_behavior.table_search_underwear": "Search Curve",
  "driving_behavior.table_speeding": "Speeding",
  "driving_behavior.filter_driver_enter": "Select driver...",
  "driving_behavior.filter_ranking_label_between": "Enter",
  "driving_behavior.filter_ranking_label_and": "and",
  "driving_behavior.detail_underwear": "Curve",
  "driving_behavior.detail_speed": "Speed",
  "driving_behavior.detail_driving_time": "Driving Time",
  "driving_behavior.detail_average_text": "Overall Average",

  "sensors.table_datetime": "Date and Time",
  "sensors.table_location": "Location",
  "sensors.alert_rules_button_text": "Sensors Alert Rules",
  "sensors.view_location": "View location",
  "sensors.option_edit_rule": "Edit rule",
  "sensors.option_delete_rule": "Delete rule",
  "sensors.new_alert_rule_sensor": "Create New Sensor Alert Rule",
  "sensors.rule.table_created_at": "Date created",
  "sensors.rule.table_rule_name": "Rule name",
  "sensors.rule.table_sensor_type": "Sensor type",
  "sensors.rule.table_user": "Responsible user",
  "sensor_detail.table_date_time": "Date and Time",
  "sensor_detail.table_vehicle": "Vehicle",
  "sensor_detail.table_read": "Read",
  "sensor_detail.table_battery": "Battery",
  "sensor_detail.table_route": "Route",
  "sensor_detail.table_location": "Location",
  "sensor_detail.table_driver": "Driver",
  "sensor_detail.table_alert": "Alerts",
  "sensors.sonsor_id": "Sensor ID",
  "sensors.chart_label": "Alert Level",
  "sensors.temperature": "Temperature",
  "sensors.speed": "Speed",
  "sensors.fuel": "Fuel",
  "sensors.battery": "Battery",
  "sensors.geofence": "Geofence",
  "sensors.camera": "Camera",
  "sensors.moisture": "Moisture",
  "sensors.security": "Security",
  "sensors.unknown": "Unknown",
  "sensors.CO2": "CO₂",
  "sensors.last_update": "Last update:",
  "sensors.filter_last_read_label": "Last Read:",
  "sensors.filter_start_date": "Start",
  "sensors.filter_end_date": "End",
  "sensors.filter_sensor_type": "Sensor Type",
  "sensors.form_alert_name": "Alert Name",
  "sensors.form_alert_name_enter": "Enter the name of the alert here",
  "sensors.form_type": "Type",
  "sensors.form_send_alert_label": "Send alert when:",
  "sensors.form_option_rule_more_then": "Greater than",
  "sensors.form_option_rule_less_then": "Less than",
  "sensors.form_option_rule_percent": "Percent change",
  "sensors.form_rule_enter": "Enter here...",
  "sensors.form_option_operator_and": "and",
  "sensors.form_option_operator_or": "or",
  "sensors.form_add_condition": "Add condition",
  "sensors.form_change_on": "Alert when:",
  "sensors.form_receive_event": "On receipt of event",
  "sensors.form_weekdays_label": "Days of the week",
  "sensors.form_issue_alert_when_label": "Issue alert when:",
  "sensors.form_from": "From:",
  "sensors.form_to": "To:",
  "sensors.form_and": "and",
  "sensors.form_notify_email": "Notify via email",
  "sensors.form_notify_traking": "Notify on tracking",
  "sensors.form_notify_sms": "Notify by SMS",
  "sensors.form_alert_more_then_enter": "Inform",
  "sensors.form_alert_more_then": "Alert only when remaining more than",
  "sensors.form_condition_seconds": "seconds in condition.",
  "sensors.form_inhibit_alerts_within_references":
    "Inhibit alerts within base registered references.",
  "sensors.form_inhibit_alerts_within_references_customer":
    "Inhibit alerts within client referrals.",
  "sensors.form_inhibit_alerts_vehicles_not_associate":
    "Inhibit alerts when the vehicle has no associated task.",
  "sensors.form_title": "Create Sensor Alert",
  "sensors.status_enabled": "Enabled",
  "sensors.status_disabled": "Disabled",
  "sensors.value_open": "Open",
  "sensors.value_close": "Closed",
  "sensors.unlinked_vehicle": "Vehicle successfully disassociated",
  "sensors.linked_vehicle": "Successfully associated vehicle",
  "configs.app.general_settings.updated": "Settings updated successfully",
  "alerts.alert_title": "Alerts",
  "alerts.description": "Description",
  "alerts.table_event_date": "Event date",
  "alerts.table_alert": "Alert",
  "alerts.table_type_alert": "Alert type",
  "alerts.table_reading": "Reading",
  "alerts.table_vehicle": "Vehicle",
  "alerts.table_route": "Route",
  "alerts.table_location": "Location",
  "alerts.table_driver": "Driver",
  "alerts.filter_initial_date_label": "Start Date",
  "alerts.filter_end_date_label": "End Date",
  "alerts.option_add_comment": "Add Comment",
  "alerts.option_view_comments": "View Comments",
  "alerts.view_comments_title": "View Comments",
  "alerts.comments_not_found": "No reviews found!",
  "alerts.option_view_media": "View Media",
  "alerts.view_media": "View Media",
  "alerts.table_videos": "Videos",
  "alerts.video_label": "Video",
  "alerts.table_images": "Images",
  "alerts.video_image": "Image",
  "alerts.filter_vehicle_type_label": "Vehicle Type",
  "alerts.filter_alert_type_label": "Alert Type",
  "alerts.filter_vehicle_label": "Vehicle",
  "alerts.confirm_delete_title": "Are you sure you want to delete this alert?",

  "electronic_fences.upload_file": "Upload fence configuration file",
  "electronic_fences.select_file": "Select fence configuration file",

  "configs.group.name_input": "Enter a name to describe the configuration",
  "jorney.no_end_jorney": "No End of Journey",

  "reports.title": "Reports Center",
  "reports.loading": "Loading reports center",
  "reports.success": "Success",
  "reports.failure": "Failure",
  "reports.description": "Description",
  "reports.status": "Status",
  "reports.created_at": "Creation",
  "reports.user_id": "User",
  "reports.completed_at": "Completed",
  "reports.download": "Download",
};
