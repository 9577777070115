import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { SubcontractorRestrictVehicle } from "../interfaces/SubcontractorRestrictVehicle";

export class SubcontractorRestrictVehicleService {
  static async get({
    filter,
    page,
  }: APiQueryBase): Promise<
    ApiResponseBase<SubcontractorRestrictVehicle[]> | ErrorResult
  > {
    const filterQuery = filter;

    return await api
      .get(`/api/v2/subcontractor-restrict-vehicle?page=${page}${filterQuery}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async associateVehicle(params: {
    contract_id: number;
    tags_id: Array<number>;
    vehicles_id: Array<number>;
  }): Promise<ApiResponseBase<SubcontractorRestrictVehicle> | ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor/associate-vehicle`, params)
      .then((r) => r.data?.[0])
      .catch((e) => e?.response?.data);
  }

  static async disassociateVehicle(params: {
    contract_id: number;
    tags_id: Array<number>;
    vehicles_id: Array<number>;
  }): Promise<ApiResponseBase<SubcontractorRestrictVehicle> | ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor/disassociate-vehicle`, params)
      .then((r) => r.data?.[0])
      .catch((e) => e?.response?.data);
  }

  static async getByContractor(
    contractorId: number
  ): Promise<ApiResponseBase<SubcontractorRestrictVehicle> | ErrorResult> {
    const query = `conditions=company_id:=:${contractorId}`;

    return await api
      .get(`/api/v2/subcontractor-restrict-vehicle?${query}`)
      .then((r) => r.data?.[0])
      .catch((e) => e?.response?.data);
  }

  static async create(data: {
    contractor_id: number;
    vehicle_tag_id: number;
  }): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/subcontractor-restrict-vehicle`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(data: {
    subcontractor_restrict_id: number;
    vehicle_tag_id: number;
  }): Promise<ErrorResult> {
    return await api
      .put(`/api/v2/subcontractor-restrict-vehicle`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(data: {
    subcontractor_restrict_id: number;
  }): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/subcontractor-restrict-vehicle`, { data })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async show(contract_id: number): Promise<any> {
    return await api
      .get(`/api/v2/subcontractor/contract/${contract_id}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
  
}
