import { api } from ".";
import { MoveJob2, MoveToOrphan2 } from "../interfaces/MoveJobs";
import { Response } from "../interfaces/Response";
import { TrackingAlertComment } from "../interfaces/TrackingAlertComment";
import {
  TrackingGeofenceParams,
  TrackingGeofenceResponse,
} from "../interfaces/TrackingGeofence";
import {
  TrackingAlert,
  TrackingAlertFilter,
  TrackingAlertNumber,
  TrackingAlertNumberFilter,
  TrackingAlertsResponse,
  TrackingGlobalAlertFilter,
  TrackingGlobalMessage,
  TrackingGlobalMessageFilter,
  TrackingMessageNumber,
  TrackingMessageNumberFilter,
  TrackingMessagesResponse,
} from "../interfaces/TrackingMessage";
import {
  TrackingSummaryFooterFilter,
  TrackingSummaryFooterResponse,
} from "../interfaces/TrackingSummaryFooter";
import {
  TrackingVehicles,
  TrackingVehiclesFilter,
  TrackingVehiclesResponse,
} from "../interfaces/TrackingVehicles";

export class TrackingService {
  static GET_VEHICLES_ROUTE = "/api/v2/tracking/get-vehicles";
  static GET_VEHICLES_DETAIL_ROUTE = "/api/v2/tracking/get-vehicles";
  static GET_SUMMARY_FOOTER_ROUTE = "/api/v2/tracking/get-summary-footer";
  static GET_GEO_FENCE_ROUTE = "/api/v2/tracking/geofence";
  static GET_TRACKING_MESSAGES = "/api/v2/tracking/message/index-message";
  static GET_TRACKING_NUMBER_OF_MESSAGES =
    "/api/v2/tracking/message/number-new-message";
  static GET_TRACKING_GLOBAL_MESSAGES =
    "/api/v2/tracking/message/vehicle-has-new-message";
  static GET_TRACKING_NUMBER_OF_ALERTS =
    "/api/v2/tracking/alert-global/number-unread";
  static GET_TRACKING_GLOBAL_ALERTS = "/api/v2/tracking/alert-global";
  static GET_TRACKING_ALERTS =
    "/api/v2/tracking/get-vehicles/{vehicle_id}/alert";
  static POST_SEND_MESSAGE = "/api/v2/tracking/message/send-message";
  static POST_SEND_COMMAND = "/api/v2/tracking/send-command";
  static POST_UPDATE_SENSOR =
    "/api/v2/tracking/fleetmate-sensor-enable-disable";
  static POST_SEND_ALERT_COMMENT = "/api/v2/tracking/alert";
  static GET_ALERT_COMMENTS = "/api/v2/tracking/alert";
  static GET_ALERT_MARK_READ = "/api/v2/tracking/alert-global/mark-read";
  static POST_READ_MESSAGE =
    "/api/v2/tracking/message/mark-all-messages-sent-from-vehicle-read/{vehicle_id}";

  static getVehicles({
    page,
    filter,
  }: {
    page: number;
    filter?: TrackingVehiclesFilter;
  }) {
    return api
      .get<TrackingVehiclesResponse>(this.GET_VEHICLES_ROUTE, {
        params: {
          page,
          per_page: 400,
          filter: JSON.stringify(filter),
        },
      })
      .then((r) => r.data);
  }

  static getVehicleDetail(id: number | string) {
    return api
      .get<Response<TrackingVehicles>>(`${this.GET_VEHICLES_ROUTE}/${id}`)
      .then((r) => r.data);
  }

  static getGeoFence(params: TrackingGeofenceParams) {
    return api
      .get<TrackingGeofenceResponse>(this.GET_GEO_FENCE_ROUTE, {
        params: {
          ...params,
          per_page: 100,
        },
      })
      .then((r) => r.data);
  }

  static getSummaryFooter(params: TrackingSummaryFooterFilter) {
    return api
      .post<TrackingSummaryFooterResponse>(
        this.GET_SUMMARY_FOOTER_ROUTE,
        params
      )
      .then((r) => r.data);
  }

  static getNumberOfMessages(params: TrackingMessageNumberFilter) {
    return api
      .get<{ data: TrackingMessageNumber }>(
        this.GET_TRACKING_NUMBER_OF_MESSAGES,
        {
          params,
        }
      )
      .then((r) => r.data.data);
  }

  static getNumberOfAlerts(params: TrackingAlertNumberFilter) {
    // console.log(params,"vehicle_idsvehicle_ids")
    return api
      .get<{ data: TrackingAlertNumber }>(this.GET_TRACKING_NUMBER_OF_ALERTS, {
        params,
      })
      .then((r) => {
        return r.data.data;
      });
  }

  static getGlobalMessages(params: TrackingGlobalMessageFilter) {
    return api
      .get<{ data: TrackingGlobalMessage[] }>(
        this.GET_TRACKING_GLOBAL_MESSAGES,
        {
          params: { ...params, per_page: 300 },
        }
      )
      .then((r) => r.data.data);
  }

  static getGlobalAlerts(params: TrackingGlobalAlertFilter) {
    return api
      .get<{ data: TrackingAlert[] }>(this.GET_TRACKING_GLOBAL_ALERTS, {
        params: { ...params, per_page: 100 },
      })
      .then((r) => r.data.data);
  }

  static getAlerts({
    vehicle_id,
    page,
  }: TrackingAlertFilter): Promise<TrackingAlertsResponse> {
    return api
      .get<TrackingAlertsResponse>(
        this.GET_TRACKING_ALERTS?.replace("{vehicle_id}", String(vehicle_id)),
        { params: { page, per_page: 100 } }
      )
      .then((r) => r.data);
  }

  static getMessages({
    vehicle_id,
    page,
  }: {
    vehicle_id: number;
    page: number;
  }): Promise<TrackingMessagesResponse> {
    return api
      .get(`${this.GET_TRACKING_MESSAGES}/${vehicle_id}`, {
        params: {
          per_page: 100,
          page,
        },
      })
      .then((r) => r.data);
  }

  static sendMessage(data: FormData) {
    return api
      .post(this.POST_SEND_MESSAGE, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((r) => r.data);
  }

  static sendCommand(data: {
    vehicle_id: number;
    command_type_constant: string;
    action: boolean;
  }) {
    return api
      .post(this.POST_SEND_COMMAND, { ...data, action: Number(data.action) })
      .then((r) => r.data);
  }

  static updateSensor(data: {
    vehicle_id: number;
    command_type_constant: string;
    action: boolean;
  }) {
    return api
      .post(this.POST_UPDATE_SENSOR, {
        vehicle_id: data.vehicle_id,
        port: data.command_type_constant,
        enable: data.action,
      })
      .then((r) => r.data);
  }

  static sendAlertComment({
    alert_id,
    comment,
  }: {
    alert_id: number;
    comment: string;
  }) {
    return api
      .post(`${this.POST_SEND_ALERT_COMMENT}/${alert_id}/comment`, {
        comment,
      })
      .then((r: any) => r.data);
  }

  static getAlertComments(alert_id: number) {
    if (alert_id > 0) {
      return api
        .get(`${this.GET_ALERT_COMMENTS}/${alert_id}/comment`)
        .then((r) => r.data.data);
    } else return null;
  }

  static getAlertMarkAsRead(alert_id: number): Promise<any> {
    return api
      .get(this.GET_ALERT_MARK_READ + "/" + alert_id)
      .then((r) => r.data);
  }

  static getAlertMarkAsReadAll(vehicles_ids: number[]): Promise<any> {
    return api
      .get(this.GET_ALERT_MARK_READ, {
        params: {
          vehicles_ids: vehicles_ids,
        },
      })
      .then((r) => r.data);
  }

  static markAllMessages(vehicle_id: number): Promise<any> {
    return api
      .post(this.POST_READ_MESSAGE?.replace("{vehicle_id}", String(vehicle_id)))
      .then((r) => r.data);
  }

  static async moveJob(data: MoveJob2) {
    return await api
      .post(`api/v2/tracking/job/insert-job-in-route`, data)
      .then((r: any) => r.data);
  }

  static async moveToOrphan(data: MoveToOrphan2) {
    return await api
      .post(`api/v2/tracking/job/set-job-orfa`, data)
      .then((r: any) => r.data);
  }

  static async changeVichle(data: any) {
    return await api
      .post(`api/v2/tracking/job/transhipment`, data)
      .then((r: any) => r.data);
  }
}
