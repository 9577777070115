import styled from "styled-components";

export const Container = styled.div`
  table {
    th,
    td {
      border: none !important;
    }

    tbody td {
      padding: 0.5rem 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6c757d;
    }

    thead th {
      padding: 2px 12px;
      background: #f2f2f2;

      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
      text-transform: uppercase;
    }

    thead th:nth-child(1) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    thead th:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .item-group {
    border: 0;
    padding-top: 16px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: #f2f2f2;

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6c757d;
    }
  }

  .accordion-header {
    border: 0;
    padding: 12px 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: #f2f2f2;
  }

  .accordion-collapse {
    label:first-child {
      margin-left: 60px;
    }
  }

  .table-container {
    /* min-height: 444px; */
    max-height: 300px;
    overflow: auto;
  }

  .text-muted {
    color: #939698;
    opacity: 0.5;
  }
`;
