import React, { useState } from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Field,
  FieldList,
  FieldName,
  Container,
  THead,
  FieldSub,
  Dashed,
  FieldStatus,
  Actions,
} from "./styles";
import { TbEdit } from "react-icons/tb";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { isErrorResult } from "../../../core/utils/api";
import { JourneyControlService } from "../../../core/services/JourneyControlService";
import { JourneyControl } from "../../../core/interfaces/JourneyControl";
import { StatusDriverService } from "../../../core/services/StatusDriverService";
import { IoTrashOutline } from "react-icons/io5";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { useDispatch } from "react-redux";
import { CompanySettingsService } from "../../../core/services/CompanySettings";
import { useAppSelector } from "../../../core/redux/hooks";
import { getUser } from "../../../core/redux/reducer/auth";

interface SubStatusProps {
  name: string;
  id?: number;
  status_driver_id?: number;
  isEdit?: boolean;
}
interface StatusProps {
  id?: number;
  name: string;
  isEdit?: boolean;

  stopped_timer: number;
  group_to: number;
  sub_status: SubStatusProps[];
  status_driver_group: number;
  target_journey_control: number | string;
}

type FormValues = {
  status: { start_and_end: StatusProps[]; other_status: StatusProps[] };
};

const DriverStatus: React.FC = () => {
  const [isBusy, setBusy] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | false>(false);
  const [deleteSubId, setDeleteSubId] = useState<{
    keyStatus: number | null;
    idSub: number | null;
  }>({
    keyStatus: null,
    idSub: null,
  });
  const [journeyControls, setJourneyControls] = useState<JourneyControl[]>([]);
  const [autoDetection, setAutoDetection] = useState<boolean>(false);
  const [autoDetectionPassword, setAutoDetectionPassword] =
    useState<boolean>(false);

  const user = useAppSelector(getUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, reset, register } =
    useForm<FormValues>({
      mode: "onChange",
    });

  const { fields } = useFieldArray({
    control,
    name: "status.start_and_end",
    keyName: "fieldId",
  });

  const {
    fields: fieldsOther,
    append: appendOther,
    remove: removeOther,
  } = useFieldArray({
    control,
    name: "status.other_status",
    keyName: "fieldId",
  });

  const handleAddStatus = () => {
    appendOther({
      name: "",
      group_to: fields[0].group_to,
      stopped_timer: 0,
      target_journey_control: "",
      sub_status: [],
      status_driver_group: fields[0].group_to,
    });
  };

  const handleAddSubStatus = (index: number) => {
    const objectToModify = fieldsOther[index];

    if (objectToModify) {
      const newObjInSubStatus = {
        name: "",
      };

      const updatedSubStatus = [
        ...objectToModify.sub_status,
        newObjInSubStatus,
      ];

      setValue(
        "status.other_status",
        fieldsOther.map((item) =>
          item === objectToModify
            ? { ...item, sub_status: updatedSubStatus }
            : item
        )
      );
    }
  };

  const handleDeleteSubStatus = (
    indexOther: number,
    indexSubStatus: number
  ) => {
    const updatedFields = fieldsOther.map((item, i) => {
      if (i === indexOther) {
        const updatedSubStatus = item.sub_status.filter(
          (_, index) => index !== indexSubStatus
        );
        return {
          ...item,
          sub_status: updatedSubStatus,
        };
      }
      return item;
    });

    setValue("status.other_status", updatedFields);
  };

  const handleEditSubStatus = (
    indexStatus: number,
    indexSub: number,
    cancel?: boolean
  ) => {
    const updatedFields = fieldsOther.map((status, i) => {
      if (i === indexStatus) {
        status.sub_status = status.sub_status.map((subStatus, j) => {
          if (j === indexSub) {
            return {
              ...subStatus,
              isEdit: cancel ? false : true,
            };
          }
          return subStatus;
        });
      }
      return status;
    });

    setValue(`status.other_status`, updatedFields);
  };

  const handleEditStatus = (index: number, cancel?: boolean) => {
    const updatedFields = fieldsOther.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isEdit: cancel ? false : true,
        };
      }
      return item;
    });

    setValue("status.other_status", updatedFields);
  };

  const onSubmitSubStatus: SubmitHandler<FormValues> = async (values) => {
    const subStatus = values.status.other_status.map((status) => {
      const subStatusWithoutId = status.sub_status.filter((sub) => !sub.id);

      return { subStatusDrivers: subStatusWithoutId, id: status.id };
    });

    const subStatusNew = subStatus.find(
      (objeto) => objeto.subStatusDrivers.length > 0
    );

    const subStatusUpdated = values.status.other_status.map((status) => {
      const subStatusIsEdit = status.sub_status.filter((sub) => sub.isEdit);

      return subStatusIsEdit;
    });

    const isSubStatusUpdated = subStatusUpdated.find(
      (subStatusArray) => subStatusArray.length > 0
    );

    if (subStatusNew?.subStatusDrivers?.length) {
      const response = await StatusDriverService.createSubStatus(
        { subStatusDrivers: subStatusNew!.subStatusDrivers },
        subStatusNew?.id!
      );

      if (isErrorResult(response)) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            message: response.message,
            type: "error",
          })
        );
      } else {
        loadAllStatusDriver();
        dispatch(updateAlert({ title: t("message.success.save_data") }));
      }
    }

    if (isSubStatusUpdated) {
      const response = await StatusDriverService.updateSubStatus(
        isSubStatusUpdated![0].name,
        isSubStatusUpdated![0].id!
      );

      if (isErrorResult(response)) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            message: response.message,
            type: "error",
          })
        );
      } else {
        loadAllStatusDriver();
        dispatch(updateAlert({ title: t("message.success.save_data") }));
      }
    }
  };

  const onSubmitStatus: SubmitHandler<FormValues> = async (values) => {
    const statusNew = values.status.other_status.filter(
      (status) => !status?.id
    );

    const statusUpdated = values.status.other_status.filter(
      (status) => !!status?.id && status?.isEdit
    );

    if (!!statusNew.length) {
      const response = await StatusDriverService.create({
        statusDrivers: statusNew.map((status) => ({
          name: status.name,
          target_journey_control: +status.target_journey_control,
          group_to: status.group_to,
          stopped_timer: status.stopped_timer,
          group_from: status.group_to,
        })),
      });

      if (isErrorResult(response)) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            type: "error",
          })
        );
      } else {
        loadAllStatusDriver();
        dispatch(
          updateAlert({
            title: t("message.success.save_data"),
            type: "success",
          })
        );
      }
    }

    if (!!statusUpdated.length) {
      const response = await StatusDriverService.update({
        statusDrivers: statusUpdated.map((status) => ({
          name: status.name,
          target_journey_control: +status.target_journey_control,
          group_to: status.group_to,
          stopped_timer: status.stopped_timer,
          id: status.id,
          group_from: status.group_to,
        })),
      });

      if (isErrorResult(response)) {
        dispatch(
          updateAlert({
            title: t("message.error.save_data"),
            type: "error",
          })
        );
      } else {
        loadAllStatusDriver();
        dispatch(
          updateAlert({
            title: t("message.success.save_data"),
            type: "success",
          })
        );
      }
    }
  };

  const onSubmitDelete = async () => {
    if (!deleteId && !deleteSubId.idSub) return;

    const indexToRemove = fieldsOther.findIndex((item) => item.id === deleteId);

    if (indexToRemove && deleteId) {
      const response = await StatusDriverService.delete({
        statusDrivers: [{ id: deleteId }],
      });

      if (isErrorResult(response)) {
        dispatch(
          updateAlert({
            title: t("message.error.delete_data"),
            message: response.message,
            type: "error",
          })
        );
      } else {
        setDeleteId(false);
        removeOther(indexToRemove);
        dispatch(updateAlert({ title: t("message.success.delete_data") }));
      }
    }

    if (deleteSubId.idSub) {
      const idStatusRemove = fieldsOther[deleteSubId.keyStatus!]?.id;
      const indexToRemoveSub = fieldsOther[
        deleteSubId!.keyStatus!
      ].sub_status?.findIndex((item) => item?.id === deleteSubId.idSub);
      const response = await StatusDriverService.deleteSubStatus(
        {
          statusDrivers: [{ id: idStatusRemove! }],
        },
        deleteSubId.idSub
      );

      if (isErrorResult(response)) {
        dispatch(
          updateAlert({
            title: t("message.error.delete_data"),
            message: response.message,
            type: "error",
          })
        );
      } else {
        handleDeleteSubStatus(deleteSubId.keyStatus!, indexToRemoveSub);
        setDeleteSubId({ idSub: null, keyStatus: null });
        dispatch(updateAlert({ title: t("message.success.delete_data") }));
      }
    }
  };

  const toggleAutomaticStatusDetection = async (checked: boolean) => {
    if (isBusy) return;
    setBusy(true);
    setAutoDetection(checked);

    const response = await StatusDriverService.switchAutomaticStatusDetection();
    if (isErrorResult(response)) {
      setAutoDetection(!checked);
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: response.message,
          type: "error",
        })
      );
    } else {
      const data = response.data;
      setAutoDetection(
        Boolean(data.disable_automatic_detection_of_stopped_driving_status)
      );
    }
    setBusy(false);
  };

  const toggleRequestPassword = async (checked: boolean) => {
    if (isBusy) return;
    setBusy(true);
    setAutoDetectionPassword(checked);

    const response = await StatusDriverService.switchRequestPassword();
    if (isErrorResult(response)) {
      setAutoDetectionPassword(!checked);
      dispatch(
        updateAlert({
          title: t("message.error.save_data"),
          message: response.message,
          type: "error",
        })
      );
    } else {
      const data = response.data;
      setAutoDetectionPassword(
        Boolean(data.request_driver_password_change_status)
      );
    }
    setBusy(false);
  };

  const loadAllJourneyControl = async () => {
    const data = await JourneyControlService.getAll();
    setJourneyControls(data);
  };

  const loadAllStatusDriver = async () => {
    const data = await StatusDriverService.get();
    if (!isErrorResult(data)) {
      const response = data.data;

      const statusDriver = response.status_driver_start_and_end;

      const statusOther = response.other_status.map((item) => ({
        id: item.id,
        name: item.name,
        stopped_timer: item.stopped_timer,
        group_from: item.group_to,
        group_to: item.group_to,
        sub_status: item.sub_status,
        target_journey_control: item.journey_control,
      }));

      const status = {
        start_and_end: statusDriver,
        other_status: statusOther,
      };

      reset({ status }, { keepDirty: false });
    }
  };

  const loadCompanySettings = async () => {
    if (isBusy) return;
    setBusy(true);
    const response = await CompanySettingsService.get({
      fields: [
        "disable_automatic_detection_of_stopped_driving_status",
        "request_driver_password_change_status",
      ],
    });
    if (isErrorResult(response)) {
      setAutoDetection(false);
      console.log("Load Company Settings Error: " + response.message);
    } else {
      const data = response.data?.[0];

      if (
        data?.disable_automatic_detection_of_stopped_driving_status !==
        undefined
      ) {
        setAutoDetection(
          Boolean(data.disable_automatic_detection_of_stopped_driving_status)
        );
      }

      if (data?.request_driver_password_change_status !== undefined) {
        setAutoDetectionPassword(
          Boolean(data.request_driver_password_change_status)
        );
      }
    }
    setBusy(false);
  };

  React.useEffect(() => {
    loadCompanySettings();
    loadAllStatusDriver();
    loadAllJourneyControl();
  }, [user?.company_id]);

  return (
    <Container className="p-0">
      <THead>
        <tr>
          <th>{t("configs.app.driver_status_tab")}</th>
        </tr>
      </THead>

      <FieldList>
        {fields?.map((item, index) => (
          <Field key={index}>
            <Col sm={6}>
              <Stack direction="horizontal">
                {index === 0 && <BiChevronDown size="1.5rem" />}
                <FieldName>{item?.name}</FieldName>
                {index === 0 && (
                  <Actions>
                    <button
                      className="add-button"
                      type="button"
                      onClick={handleAddStatus}
                    >
                      Adicionar status
                    </button>
                  </Actions>
                )}
              </Stack>
            </Col>

            {index === 0 && (
              <>
                {fieldsOther.map((statusDriver, key: number) => (
                  <FieldStatus>
                    <Stack direction="horizontal" className="align-items-start">
                      <Dashed />
                      {statusDriver?.name && !statusDriver.isEdit ? (
                        <Col>
                          <Stack
                            direction="horizontal"
                            style={{ marginTop: "-13px" }}
                          >
                            <BiChevronDown size="1.5rem" />
                            <FieldName>{statusDriver?.name}</FieldName>
                            <Actions>
                              <button
                                className="add-button"
                                type="button"
                                onClick={() => handleAddSubStatus(key)}
                              >
                                Adicionar status
                              </button>
                              <button
                                type="button"
                                onClick={() => setDeleteId(statusDriver.id!)}
                              >
                                <IoTrashOutline size={25} />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleEditStatus(key)}
                              >
                                <TbEdit size={25} />
                              </button>
                            </Actions>
                          </Stack>
                        </Col>
                      ) : (
                        <Form
                          onSubmit={handleSubmit(onSubmitStatus, console.warn)}
                        >
                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ marginTop: "-17px" }}
                          >
                            <BiChevronRight size="1.5rem" />
                            <Form.Group style={{ width: "100%" }}>
                              <Form.Control
                                type="text"
                                {...register(`status.other_status.${key}.name`)}
                              />
                            </Form.Group>
                            <Form.Group style={{ width: "100%" }}>
                              <Form.Select
                                defaultValue=""
                                {...register(
                                  `status.other_status.${key}.target_journey_control`
                                )}
                              >
                                <option disabled value="">
                                  {t("configs.app.journer_select")}
                                </option>
                                {journeyControls?.map((item, i) => (
                                  <option
                                    key={`${i}_${item.id}`}
                                    value={item.id}
                                  >
                                    {item?.description}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Stack>
                          <Actions
                            style={{
                              justifyContent: "flex-end",
                              marginTop: "16px",
                            }}
                          >
                            <button
                              type="button"
                              onClick={() => {
                                if (statusDriver.isEdit) {
                                  handleEditStatus(key, statusDriver.isEdit);
                                } else {
                                  removeOther(key);
                                }
                              }}
                            >
                              Cancelar
                            </button>
                            <button type="submit" className="add-button">
                              Salvar
                            </button>
                          </Actions>
                        </Form>
                      )}
                    </Stack>

                    {statusDriver?.sub_status?.map(
                      (subStatus, keySub: number) => (
                        <FieldSub>
                          <Stack
                            direction="horizontal"
                            className="align-items-start"
                          >
                            <Dashed long />
                            {subStatus?.name && !subStatus.isEdit ? (
                              <Stack
                                style={{ marginTop: "-10px" }}
                                direction="horizontal"
                              >
                                <FieldName>{subStatus?.name}</FieldName>
                                <Actions>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setDeleteSubId({
                                        idSub: subStatus?.id!,
                                        keyStatus: key,
                                      })
                                    }
                                  >
                                    <IoTrashOutline size={25} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleEditSubStatus(key, keySub)
                                    }
                                  >
                                    <TbEdit size={25} />
                                  </button>
                                </Actions>
                              </Stack>
                            ) : (
                              <Form
                                onSubmit={handleSubmit(
                                  onSubmitSubStatus,
                                  console.warn
                                )}
                              >
                                <Stack style={{ marginTop: "-20px" }}>
                                  <Stack>
                                    <Form.Group
                                      style={{
                                        marginLeft: "8px",
                                      }}
                                    >
                                      <Form.Control
                                        type="text"
                                        {...register(
                                          `status.other_status.${key}.sub_status.${keySub}.name`
                                        )}
                                      />
                                    </Form.Group>
                                  </Stack>
                                  <Actions
                                    style={{
                                      justifyContent: "flex-end",
                                      marginTop: "16px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (subStatus.isEdit) {
                                          handleEditSubStatus(
                                            key,
                                            keySub,
                                            subStatus.isEdit
                                          );
                                        } else {
                                          handleDeleteSubStatus(key, keySub);
                                        }
                                      }}
                                    >
                                      Cancelar
                                    </button>
                                    <button
                                      type="submit"
                                      className="add-button"
                                    >
                                      Salvar
                                    </button>
                                  </Actions>
                                </Stack>
                              </Form>
                            )}
                          </Stack>
                        </FieldSub>
                      )
                    )}
                  </FieldStatus>
                ))}
              </>
            )}
          </Field>
        ))}
      </FieldList>

      <Row className="mt-5">
        <Col>
          <Form.Group>
            <Form.Check
              id="disable_auto_detection_stopped_driving"
              label={t("configs.app.disable_auto_detection")}
              checked={autoDetection}
              onChange={(e) => {
                toggleAutomaticStatusDetection(e.target.checked);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              id="request_driver_password_change_status"
              label={t("configs.app.driver_status.request_password")}
              checked={autoDetectionPassword}
              onChange={(e) => {
                toggleRequestPassword(e.target.checked);
              }}
            />
            <Form.Text>{isBusy && t("status.saving")}</Form.Text>
          </Form.Group>
        </Col>
      </Row>

      <ConfirmationModal
        visible={!!deleteId || !!deleteSubId.idSub}
        title={t("configs.app.driver_status.modal_title")}
        onClose={() => {
          setDeleteId(false);
          setDeleteSubId({ idSub: null, keyStatus: null });
        }}
        onConfirm={onSubmitDelete}
      />
    </Container>
  );
};

export default DriverStatus;
