import React, { useEffect, useState } from "react";
import { Button, Form, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";

interface ConfirmationSendCommandProps {
  onClose?(): void;
  onConfirm?(event: { sendCommand: boolean }): void;
  visible?: boolean;
  isLoading?: boolean;
}

const ConfirmationSendCommandModal: React.FC<ConfirmationSendCommandProps> = ({
  visible = false,
  isLoading,
  onClose,
  onConfirm,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [sendCommand, setSendCommand] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <Modal
      {...props}
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Body className="pb-4">
        <p className="title">
          {t("vehicles.hardware.message.confirm_send_command")}
        </p>

        <Form.Check
          id="send_command_check_id"
          label={t("vehicles.hardware.send_command_satellite.label")}
          defaultChecked={true}
          onChange={(e) => {
            setSendCommand(e.currentTarget.checked);
          }}
        />

        <Stack
          className="d-flex justify-content-center mt-5"
          direction="horizontal"
          gap={3}
        >
          <Button onClick={() => onConfirm?.({ sendCommand })}>
            {isLoading ? t("status.waiting") : t("text.yes")}
            {isLoading && <Spinner animation="border" size="sm" />}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            {t("text.no")}
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationSendCommandModal;
