import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/AddButton";

import { ReactComponent as ICCopy } from "../../assets/icons/copy.svg";

import { Container } from "./styles";
import { ApiPublicService } from "../../core/services/ApiPublicService";
import { isErrorResult } from "../../core/utils/api";
import { ApiPublic } from "../../core/interfaces/ApiPublic";
import { useAppSelector } from "../../core/redux/hooks";
import { getUser } from "../../core/redux/reducer/auth";
import { UserService } from "../../core/services/UserService";
import { Company } from "../../core/interfaces/Company";

const ConfigsAPI: React.FC = () => {
  const [apiKeys, setApiKeys] = useState<ApiPublic>();

  const { t } = useTranslation();
  const user = useAppSelector(getUser);

  const [userCompany, setUserCompany] = useState({} as Company);

  const loadApiKeys = async () => {
    try {
      const data = await ApiPublicService.get();
      const isError = isErrorResult(data);

      if (!isError && data) {
        setApiKeys(data);
      }
    } catch (error) {
      console.log("Error in load api keys:", error);
    }
  };

  const onSubmitClientSecret = async () => {
    try {
      const data = await ApiPublicService.clientId();
      const isError = isErrorResult(data);

      if (!isError && data) {
        alert(data.message);
        getCompaniesList();
      }
    } catch (error) {
      console.log("Error in submit client secret:", error);
    }
  };

  const onSubmitProduction = async () => {
    try {
      const data = await ApiPublicService.create("production");
      const isError = isErrorResult(data);

      if (!isError && data) {
        setApiKeys((prev) => ({ ...prev, tokenProduction: data } as any));
        alert(data.message);
      }
    } catch (error) {
      console.log("Error in sibmit production:", error);
    }
  };

  const handleClipboard = (text?: string) => {
    if (!text) return;
    navigator.clipboard.writeText(text);
    alert("clipboard saved successfully");
  };

  async function getCompaniesList() {
    try {
      const getCompanyService = await UserService.listCompany({});
      console.log(user);
      if (!isErrorResult(getCompanyService)) {
        const company = getCompanyService.data.find(
          (company) => company.id === user?.company_id
        );
        if (company) {
          setUserCompany(company);
        }

        return getCompanyService.data;
      }
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    loadApiKeys();
    getCompaniesList();
  }, [user?.company_id]);

  return (
    <Container className="mb-3">
      <Row className="mb-4">
        <Col className="d-flex align-items-center" xs="12">
          <h5 className="mb-0 p-0">{t("configs.api.title")}</h5>
        </Col>

        <Col xs="12" className="mt-5 title">
          {t("configs.api.client_id")}
        </Col>
        <Col xs="12" className="mt-4">
          <div className="code">
            {user?.company_id}
            <div
              onClick={() => {
                handleClipboard(user?.company_id + "" ?? "");
              }}
            >
              {t("configs.api.copy")} <ICCopy />
            </div>
          </div>
        </Col>

        <Col xs="12" className="mt-5">
          <AddButton onClick={onSubmitClientSecret}>
            {t("configs.api.client_secret")}
          </AddButton>
        </Col>
        <Col xs="12" className="mt-4">
          <div className="code">
            {userCompany?.api_secret}

            <div
              onClick={() => {
                handleClipboard(userCompany.api_secret);
              }}
            >
              {t("configs.api.copy")} <ICCopy />
            </div>
          </div>
        </Col>

        <Col xs="12" className="mt-5">
          <AddButton onClick={onSubmitProduction}>
            {t("configs.api.production")}
          </AddButton>
        </Col>
        <Col xs="12" className="mt-4">
          <div className="code">
            {apiKeys?.tokenProduction.data.api_token}
            <div
              onClick={() => {
                handleClipboard(apiKeys?.tokenProduction.data.api_token);
              }}
            >
              {t("configs.api.copy")} <ICCopy />
            </div>
          </div>
        </Col>
        {/* <Col xs="12" className="mt-5">
          <AddButton onClick={onSubmitSandbox}>
            {t("configs.api.sandbox")}
          </AddButton>
        </Col>
        <Col xs="12" className="mt-4">
          <div className="code">
            {apiKeys?.tokenSandbox.data.api_token}
            <div
              onClick={() => {
                handleClipboard(apiKeys?.tokenSandbox.data.api_token);
              }}
            >
              {t("configs.api.copy")} <ICCopy />
            </div>
          </div>
        </Col> */}
      </Row>
    </Container>
  );
};

export default ConfigsAPI;
