import styled from "styled-components";

export const Link = styled.a`
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  color: #6c757d;
  gap: 0.5em;

  &:hover {
    cursor: pointer;
  }
`;
