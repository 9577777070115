import { Col, Row, Stack } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { Card, CardPlanButton, Container } from "./styles";
import { BsArrowLeftShort } from "react-icons/bs";
import { useEffect, useState } from "react";

interface IProps {
  onClickBack: () => void;
}
export const ManagePlan = ({ onClickBack }: IProps) => {
  const { t, i18n } = useTranslation();
  const [currency, setCurrency] = useState("USD");

  console.log(currency);
  function currencyFormatter(value: number) {
    return new Intl.NumberFormat(currency, {
      style: "currency",
      currency,
    }).format(value);
  }

  useEffect(() => {
    if (i18n.language === "en") {
      setCurrency("USD");
    }
    if (i18n.language === "pt") {
      setCurrency("BRL");
    }
    if (i18n.language === "fr") {
      setCurrency("EUR");
    }
  }, [i18n.language]);

  return (
    <Container className="px-0">
      <button className="button" onClick={onClickBack}>
        <Stack direction="horizontal" gap={2}>
          <BsArrowLeftShort size={24} />
          {t("link.go_back")}
        </Stack>
      </button>
      <Row>
        <Col sm="3">
          <Card>
            <strong className="card__title">
              {t("configs.menu.plan_essential")}
            </strong>
            <div className="card__plan-price">
              <span className="card__price">
                <b>{currencyFormatter(29.9)}</b> / {t("configs.menu.monthly")}
              </span>
              <p className="card__compare-price">{currencyFormatter(49.9)}</p>
            </div>

            <CardPlanButton $isActivePlan={true}>
              {t("configs.menu.current_plan")}
            </CardPlanButton>

            <strong className="card_main-features">
              {t("configs.menu.main_features")}:
            </strong>

            <ul className="card_features-list">
              <li>{t("configs.menu.fleet_tracking")}</li>
              <li>{t("configs.menu.electronic_fences")}</li>
              <li>{t("configs.menu.travel_history")}</li>
              <li>{t("configs.menu.integration_api")}</li>
            </ul>
          </Card>
        </Col>
        <Col sm="3">
          <Card>
            <strong className="card__title">
              {t("configs.menu.plan_advanced")}
            </strong>
            <div className="card__plan-price">
              <span className="card__price">
                <b>{currencyFormatter(29.9)}</b> / {t("configs.menu.monthly")}
              </span>
              <p className="card__compare-price">{currencyFormatter(49.9)}</p>
            </div>

            <CardPlanButton $isActivePlan={false}>
              {t("configs.menu.chose_plan")}
            </CardPlanButton>

            <strong className="card_main-features">
              {t("configs.menu.main_features")}:
            </strong>

            <ul className="card_features-list">
              <li>{t("configs.menu.fleet_tracking")}</li>
              <li>{t("configs.menu.electronic_fences")}</li>
              <li>{t("configs.menu.travel_history")}</li>
              <li>{t("configs.menu.integration_api")}</li>
              <li>{t("configs.menu.delivery_control")}</li>
              <li>{t("configs.menu.digital_forms")}</li>
            </ul>
          </Card>
        </Col>

        <Col sm="3">
          <Card>
            <strong className="card__title">
              {t("configs.menu.plan_security")}
            </strong>
            <div className="card__plan-price">
              <span className="card__price">
                <b>{currencyFormatter(29.9)}</b> / {t("configs.menu.monthly")}
              </span>
              <p className="card__compare-price">{currencyFormatter(49.9)}</p>
            </div>

            <CardPlanButton $isActivePlan={false}>
              {t("configs.menu.chose_plan")}
            </CardPlanButton>

            <strong className="card_main-features">
              {t("configs.menu.main_features")}:
            </strong>

            <ul className="card_features-list">
              <li>{t("configs.menu.fleet_tracking")}</li>
              <li>{t("configs.menu.electronic_fences")}</li>
              <li>{t("configs.menu.travel_history")}</li>
              <li>{t("configs.menu.integration_api")}</li>
              <li>{t("configs.menu.security")}</li>
            </ul>
          </Card>
        </Col>

        <Col sm="3">
          <Card>
            <strong className="card__title">
              {t("configs.menu.plan_professional")}
            </strong>
            <div className="card__plan-price">
              <span className="card__price">
                <b>{currencyFormatter(29.9)}</b> / {t("configs.menu.monthly")}
              </span>
              <p className="card__compare-price">{currencyFormatter(49.9)}</p>
            </div>

            <CardPlanButton $isActivePlan={false}>
              {t("configs.menu.chose_plan")}
            </CardPlanButton>

            <strong className="card_main-features">
              {t("configs.menu.main_features")}:
            </strong>

            <ul className="card_features-list">
              <li>{t("configs.menu.fleet_tracking")}</li>
              <li>{t("configs.menu.electronic_fences")}</li>
              <li>{t("configs.menu.travel_history")}</li>
              <li>{t("configs.menu.integration_api")}</li>
              <li>{t("configs.menu.delivery_control")}</li>
              <li>{t("configs.menu.digital_forms")}</li>
              <li>{t("configs.menu.security")}</li>
            </ul>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
