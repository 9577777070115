import { Accordion, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAudit } from "../../../core/hooks/useAudit";
import { CustomAuditAccordion } from "../../CustomAuditAccordion";
import moment from "moment";
import { useEffect, useState } from "react";

export default function Commands({ data, setupType, summary }: any) {
  const { t } = useTranslation();
  const { actuatorOptions } = useAudit();
  const [sumarized, setSummarized] = useState(undefined);

  function checkStatus() {
    if (data?.expired_at) {
      return <p>{t("configs.audit.command.expired")}</p>;
    } else if (
      data?.registry?.affected?.expired
        ? data?.registry?.affected?.expired === true
        : data?.registry?.data?.setup?.expired === true
    ) {
      return <p>{t("configs.audit.command.received")}</p>;
    } else {
      return <p>{t("configs.audit.command.sent")}</p>;
    }
  }

  function registerOrigin() {
    switch (setupType) {
      case 1: {
        return t("configs.audit.create_geofence");
      }
      case 2: {
        return t("configs.audit.run_command");
      }
      case 3: {
        return t("configs.audit.setup_configurations");
      }
      case 4: {
        return t("configs.audit.remove_geofences");
      }
      case 5: {
        return t("configs.audit.create_rule");
      }
      case 6: {
        return t("configs.audit.remove_rule");
      }
      case 7: {
        return t("configs.audit.fleetmate_sensors");
      }
      case 8: {
        return t("configs.audit.remove_registers");
      }
      default: {
        return "--";
      }
    }
  }

  function networkType() {
    if (
      data?.registry?.data?.network &&
      data?.registry?.data?.network.toLowerCase() !== "app"
    ) {
      return data?.registry?.data?.network;
    } else if (
      data?.registry?.data?.network &&
      data?.registry?.data?.network.toLowerCase() === "app"
    ) {
      return "gsm";
    }

    if (
      data?.registry?.data?.setup?.network &&
      data?.registry?.data?.setup?.network.toLowerCase() !== "app"
    ) {
      return data?.registry?.data?.setup?.network;
    } else if (
      data?.registry?.data?.setup?.network &&
      data?.registry?.data?.setup?.network.toLowerCase() === "app"
    ) {
      return "gsm";
    }

    if (
      !data?.registry?.data?.network &&
      !data?.registry?.data?.setup?.network
    ) {
      return "--";
    }
  }

  useEffect(() => {
    if (typeof data?.registry?.data === "string") {
      const parsedData = JSON.parse(data?.registry?.data);
      setSummarized(parsedData);
    }
  }, [summary]);

  console.log(data?.registry?.data?.setup?.data?.exitPorts);

  return (
    <>
      <div className="item-group p-0">
        <Row>
          <Col>
            <label>{t("configs.audit.status")}</label>
            <p>{checkStatus()}</p>
          </Col>

          <Col>
            <label>{t("configs.audit.event_date_hour")}</label>
            <p>
              {data?.registry?.generated_at
                ? moment(data?.registry?.generated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.datetime_execution")}</label>
            <p>
              {data?.registry?.affected?.updated_at
                ? moment(data?.registry?.affected?.updated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : data?.registry?.data?.updated_at
                ? moment(data?.registry?.data?.updated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.expiration_datetime")}</label>
            <p>
              {data?.registry?.expired_at
                ? moment(data?.registry?.expired_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.driver")}</label>
            <p>
              {data?.driver?.name
                ? data?.driver?.name + " " + data?.driver?.last_name
                : "--"}
            </p>
          </Col>
          <Col>
            <label>{t("configs.audit.license_plate")}</label>
            <p>
              {data?.vehicle?.license_plate
                ? data?.vehicle?.license_plate
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.command_origin")}</label>
            <p>{registerOrigin()}</p>
          </Col>
          <Col>
            <label>{t("configs.audit.network")}</label>
            <p>{networkType()}</p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          {/* <Col>
            <label>{t("configs.audit.rule")}</label>
            <p>Entrada em Zona de Rísco</p>
          </Col> */}
          <Col>
            <label>{t("configs.audit.user")}</label>
            <p>
              {data?.user?.name
                ? data?.user?.name + " " + data?.user?.last_name
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      {data?.registry?.data?.setup?.data?.exitPorts !== undefined ? (
        <Row>
          <Col>
            <Accordion id="audit_position_accordion" alwaysOpen>
              <CustomAuditAccordion
                eventKey="0"
                data={{ title: t("configs.audit.actuators") }}
              />
              <Accordion.Collapse eventKey="0">
                <>
                  {data?.registry?.data?.setup?.data?.exitPorts.map(
                    (item: any, i: number) => (
                      <Row key={i} className="pb-3">
                        <Col xl="8">
                          <label>
                            {t(
                              "fleetmate.outings." + item?.sensor.toLowerCase()
                            )}
                          </label>
                        </Col>
                        <Col className="text-muted">Habilitar</Col>
                      </Row>
                    )
                  )}
                </>
              </Accordion.Collapse>
            </Accordion>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
