import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const startPoint = yup
  .object({
    name: yup.string().required(),
    location: yup.string().required(),
    // latitude: yup.number().min(-90).max(90).required(),
    // longitude: yup.number().min(-180).max(180).required(),
  })
  .required();

export const StartPointResolver = yupResolver(startPoint);
