import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { SecurityRulesGroupById } from "../interfaces/SecurityRules";
import { SecurityRule } from "../interfaces/SecurityRulesForm";

export class SecurityRules{
  static async getByGroup({
    page,
    id
  }: APiQueryBase): Promise<ApiResponseBase<SecurityRulesGroupById> | ErrorResult>{
    return await api.get(`/api/v2/security-rules?filter={"group_id": ${id}}&page=${page}&order={"created_at": "desc"}`)
    .then((r) => r?.data)
    .catch((e) => e?.response?.data);
  }

  static async getById(id:string): Promise<SecurityRule>{
    return await api.get(`/api/v2/security-rules/${id}`)
    .then((r) => r?.data)
    .catch((e) => e?.response?.data);
  }

  static async put(data: any){
    return await api.put(`/api/v2/security-rules/${data?.id}`, data)
  }

  static async post(data: any){
    return await api.post(`/api/v2/security-rules`, data)
  }

  static async delete(id: string){
    return await api.delete(`/api/v2/security-rules/${id}`)
    .then((r) => r?.data)
    .catch((e) => e?.response?.data);
  }
}