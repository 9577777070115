import parse from "html-react-parser";
import React from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  Control,
  Controller,
  UseFormRegister,
  useFormState,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormValues } from ".";
import CurrencyInput from "../../../components/CurrencyInput";
import CustomInputTag from "../../../components/CustomInputTag";
import { IVehicleTag } from "../../../core/interfaces/VehicleTag";
import { VehicleType } from "../../../core/interfaces/VehicleType";
import { measurements, measurementsWeight, plate } from "../../../utils/masks";

interface CreateProps {
  control: Control<FormValues>;
  register: UseFormRegister<FormValues>;
  options: { tags: IVehicleTag[]; types: VehicleType[]; tagsLoading?: boolean };
}

const Create: React.FC<CreateProps> = ({ control, register, options }) => {
  const { errors, isSubmitting } = useFormState({ control });
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Row className="m-3">
        <input type="hidden" {...register("id")} />

        <Form.Group className="mb-3" controlId="license_plate" as={Col} xs="3">
          <Form.Label>{t("vehicles.form.label.license_plate")}</Form.Label>
          <Form.Control
            // {...register("license_plate")}
            {...register("license_plate", {
              onChange: (value) => plate(value),
            })}
            placeholder={t("sensors.form_alert_more_then_enter")}
            // autoCapitalize="off"
            // autoComplete="off"
            // autoCorrect="off"
            isInvalid={!!errors?.license_plate?.message}
          />
          <Form.Control.Feedback type="invalid">
            {t("vehicles.form.label.license_plate.invalid")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="vehicle_type_id"
          as={Col}
          xs="3"
        >
          <Form.Label>{t("vehicles.form.label.vehicle_type")}</Form.Label>
          <Form.Select
            {...register("vehicle_type_id")}
            isInvalid={!!errors?.vehicle_type_id?.message}
          >
            <option value="" disabled selected>
              {t("input.select_text")}
            </option>
            {options?.types?.map((item, index) => (
              <option key={`${index}_${item.id}`} value={item.id}>
                {t(item.description)}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t("vehicles.form.label.vehicle_type.invalid")}
          </Form.Control.Feedback>
        </Form.Group>

        <Controller
          control={control}
          name="value_by_km"
          defaultValue={0}
          render={({ field: { onChange, value, onBlur } }) => (
            <Form.Group
              className="mb-3"
              controlId="value_by_km"
              as={Col}
              xs="3"
            >
              <Form.Label>{t("vehicles.form.label.price_by_km")}</Form.Label>
              <CurrencyInput
                onChangeText={onChange}
                value={value}
                onBlur={onBlur}
                isInvalid={!!errors?.value_by_km?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.value_by_km?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />

        <Form.Group controlId="active" as={Col} xs="auto">
          <Form.Label />
          <Form.Check
            className="mt-2"
            type="switch"
            id="active"
            reverse
            // size={30}
            label={parse(`<strong>${t("vehicles.form.label.active")}</strong>`)}
            defaultChecked={false}
            {...register("active")}
          />
        </Form.Group>
      </Row>

      <Row className="m-3">
        <Col xs="2">
          <Form.Group className="mb-3" controlId="total_weight">
            <Form.Label>{t("vehicles.form.label.weight")}</Form.Label>
            <Form.Control
              {...register("total_weight", {
                onChange: (value) => {
                  measurementsWeight(value);
                },
              })}
              placeholder="0,00"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              isInvalid={!!errors?.total_weight?.message}
            />
            <Form.Control.Feedback type="invalid">
              {t("vehicles.form.label.weight.invalid")}
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="board">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">Selecione o Tipo de Material</option>
            <option value="1">Material 1</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group> */}
        </Col>

        <Col xs="2">
          <Form.Group className="mb-3" controlId="total_amount">
            <Form.Label>{t("vehicles.form.label.amount")}</Form.Label>
            <Form.Control
              {...register("total_amount", {
                onChange: (value) => measurements(value),
              })}
              placeholder="0,00"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              isInvalid={!!errors?.total_amount?.message}
            />
            <Form.Control.Feedback type="invalid">
              {t("vehicles.form.label.amount.invalid")}
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="board">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">Selecione o Tipo de Material</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group> */}
        </Col>

        <Col xs="2">
          <Form.Group className="mb-3" controlId="total_volume">
            <Form.Label>{t("vehicles.form.label.volume")}</Form.Label>
            <Form.Control
              {...register("total_volume", {
                onChange: (value) => measurements(value),
              })}
              placeholder="0,00"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              isInvalid={!!errors?.total_volume?.message}
            />
            <Form.Control.Feedback type="invalid">
              {t("vehicles.form.label.volume.invalid")}
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="material_type">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">{t("vehicles.form.select.material_type")}</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group> */}
        </Col>

        {/* <Col xs="3">
        <Form.Group className="mb-3" controlId="board">
          <Form.Label>Tanque 2</Form.Label>
          <Form.Control
            {...register("total_volume")}
            placeholder="0,00"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            isInvalid={!!errors?.total_volume?.message}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.total_volume?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="board">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">Selecione o Tipo de Material</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col> */}

        {/* <Col xs="3">
        <Form.Group className="mb-3" controlId="board">
          <Form.Label>Tanque 3</Form.Label>
          <Form.Control
            {...register("total_volume")}
            placeholder="0,00"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            isInvalid={!!errors?.total_volume?.message}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.total_volume?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="board">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">Selecione o Tipo de Material</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col> */}

        {/* <Col xs="3">
        <Form.Group className="mb-3" controlId="board">
          <Form.Label>Tanque 4</Form.Label>
          <Form.Control
            {...register("total_volume")}
            placeholder="0,00"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            isInvalid={!!errors?.total_volume?.message}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.total_volume?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="board">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">Selecione o Tipo de Material</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col> */}

        {/* <Col xs="3">
        <Form.Group className="mb-3" controlId="board">
          <Form.Label>Tanque 5</Form.Label>
          <Form.Control
            {...register("total_volume")}
            placeholder="0,00"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            isInvalid={!!errors?.total_volume?.message}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.total_volume?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="board">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">Selecione o Tipo de Material</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col> */}

        {/* <Col xs="3">
        <Form.Group className="mb-3" controlId="board">
          <Form.Label>Tanque 6</Form.Label>
          <Form.Control
            {...register("total_volume")}
            placeholder="0,00"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            isInvalid={!!errors?.total_volume?.message}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.total_volume?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="board">
          <Form.Select
            {...register("material_type")}
            isInvalid={!!errors?.material_type?.message}
          >
            <option value="">Selecione o Tipo de Material</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors?.material_type?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col> */}

        <Col className="mt-3" xs="12">
          <h5>
            <span className="text-dark">{t("component.tag_label")}</span>
          </h5>
        </Col>
        <Form.Group className="mb-3" controlId="tags" as={Col} xs="12">
          <Form.Label>{t("vehicles.form.description.tag")}</Form.Label>
          <Controller
            control={control}
            name="tags"
            defaultValue={[]}
            render={({ field: { name, value, onChange } }) => (
              <CustomInputTag
                name={name}
                allowNew
                selected={value}
                options={options?.tags?.map((item) => item.key)}
                isLoading={options.tagsLoading}
                onChange={onChange}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.tags?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Col className="mt-3" xs="12">
          <h5>
            <span className="text-dark">
              {t("vehicles.form.label.observation")}
            </span>
          </h5>
        </Col>

        <Form.Group className="mb-3" controlId="observations" as={Col} xs="12">
          {/* <Form.Label></Form.Label> */}
          <Form.Control
            className="mt-2"
            {...register("observations")}
            as="textarea"
            rows={4}
            placeholder={t("vehicles.form.enter.observation")}
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            isInvalid={!!errors?.observations?.message}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.observations?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Col xs="12">
          <Button id="vehicle_button_submit" type="submit" className="me-2">
            {isSubmitting ? t("status.saving") : t("button.save")}
            {isSubmitting && <Spinner animation="border" size="sm" />}
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate(-1)}
          >
            {t("button.cancel")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Create;
