import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  th {
    text-transform: uppercase;
  }

  tbody {
    height: 140px !important;
  }

  td:last-child {
    width: 100px;
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
`;

export const CustomLink = styled(NavLink)`
  color: #2f80ed;

  :hover {
    cursor: pointer;
  }
`;
export const CustomButton = styled.button<{ emphasized: boolean }>`
  color: #2f80ed;
  border: none;
  background: transparent;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;
