import { ColorsUtils } from "../utils/colors";
import { FenceType } from "../interfaces/EletronicFence";

export class MapEletronicFence {
  map?: google.maps.Map = undefined;
  positions: any[] = [];
  colors: string[] = [];
  type: FenceType = null;
  fence:
    | google.maps.Polyline
    | google.maps.Circle
    | google.maps.Polygon
    | null = null;
  mark: google.maps.Marker | null = null;
  points: google.maps.Circle[] = [];
  onRadiusChanged?: (radius: number) => void;
  updatePoints: () => any = () => {};

  constructor(map: google.maps.Map, updatePoints?: () => void) {
    this.map = map;
    this.colors = ColorsUtils.getMapColors();

    this.updatePoints = updatePoints ? updatePoints : () => {};
  }

  loadFence(routes: { lat: number; lng: number }[], radius?: number) {
    this.fence?.setMap(null);
    this.setCenter({ lat: routes[0].lat, lng: routes[0].lng });

    if (this.type === "route") {
      this.fence = new google.maps.Polyline({
        path: routes.map((r) => ({ lat: r.lat, lng: r.lng })),
        geodesic: true,
        strokeColor: this.colors[0],
        strokeOpacity: 1.0,
        strokeWeight: 5,
        editable: true,
        draggable: true,
        map: this.map,
      });
      this.attachPolylineListeners();
    } else if (this.type === "circle") {
      this.fence = new google.maps.Circle({
        strokeWeight: 1,
        strokeColor: "#2F80ED",
        fillColor: "rgb(47, 128, 237)",
        fillOpacity: 0.25,
        map: this.map,
        center: { lat: routes[0].lat, lng: routes[0].lng },
        editable: true,
        draggable: false,
        radius: radius ?? 100,
      });
      this.attachCircleListeners();
      this.attachRadiusChangedListener();
    } else if (this.type === "polygon") {
      this.fence = new google.maps.Polygon({
        paths: routes,
        strokeWeight: 1,
        strokeColor: "#2F80ED",
        strokeOpacity: 1,
        fillColor: "rgb(47, 128, 237)",
        fillOpacity: 0.35,
        editable: true,
        draggable: true,
        map: this.map,
      });

      this.attachPolygonListeners();
    }
  }

  private attachRadiusChangedListener() {
    const circle = this.fence as google.maps.Circle;
    circle.addListener("radius_changed", () => {
      const newRadius = circle.getRadius();
      this.onRadiusChanged?.(newRadius);
      this?.updatePoints();
    });
  }
  private attachCircleListeners() {
    const circle = this.fence as google.maps.Circle;

    // Listener para quando o centro do círculo é movido
    google.maps.event.addListener(circle, "center_changed", () => {
      const newCenter = circle.getCenter();
      if (newCenter) {
        this?.updatePoints();
      }
    });

    // Listener para quando o raio do círculo é alterado
    google.maps.event.addListener(circle, "radius_changed", () => {
      const newRadius = circle.getRadius();

      this.onRadiusChanged?.(newRadius);
    });
  }
  private onRoutePointMoved() {
    const polyline = this.fence as google.maps.Polyline;
    const path = polyline.getPath();
    const newCoords: any[] = [];

    path.forEach(function (vertex, index) {
      const lat = vertex.lat();
      const lng = vertex.lng();
      newCoords.push({ lat, lng });
    });
  }

  private attachPolylineListeners() {
    const polyline = this.fence as google.maps.Polyline;
    const path = polyline.getPath();

    // Listener para quando um ponto da rota é movido
    google.maps.event.addListener(path, "set_at", (index: any) => {
      this.onRoutePointMoved();
      this?.updatePoints();
    });

    // Listener para quando um novo ponto é adicionado à rota
    google.maps.event.addListener(path, "insert_at", (index: any) => {
      this.onRoutePointMoved();
      this?.updatePoints();
    });

    // Listener para quando um ponto da rota é removido
    google.maps.event.addListener(path, "remove_at", (index: any) => {
      this.onRoutePointMoved();
      this?.updatePoints();
    });
  }
  private attachPolygonListeners() {
    const polygon = this.fence as google.maps.Polygon;
    const path = polygon.getPath();

    // Listener para capturar movimento dos pontos do polígono
    google.maps.event.addListener(path, "set_at", (index: any) => {
      this.onPolygonPointMoved();
      this?.updatePoints();
    });

    // Listener para capturar inserção de novos pontos no polígono
    google.maps.event.addListener(path, "insert_at", (index: any) => {
      this.onPolygonPointMoved();
      this?.updatePoints();
    });

    // Listener para capturar remoção de pontos do polígono
    google.maps.event.addListener(path, "remove_at", (index: any) => {
      this.onPolygonPointMoved();
      this?.updatePoints();
    });
  }

  private onPolygonPointMoved() {
    const polygon = this.fence as google.maps.Polygon;
    const path = polygon.getPath();
    const newCoords: any[] = [];

    path.forEach(function (vertex, index) {
      const lat = vertex.lat();
      const lng = vertex.lng();
      newCoords.push({ lat, lng });
    });

    // Aqui você pode usar as novas coordenadas

    this?.updatePoints();
  }

  getCircleRadius(): number | null {
    if (this.fence instanceof google.maps.Circle) {
      return this.fence.getRadius();
    }
    return null;
  }

  setCenter(center: { lat: number; lng: number }) {
    this.map?.setCenter(center);
  }

  getCenter() {
    const center = this.map?.getCenter();
    if (center) {
      const lat = center.lat();
      const lng = center.lng();
      return { lat, lng };
    }

    return null;
  }

  onClick(e: google.maps.MapMouseEvent) {
    if (!e.latLng?.lat() || !e.latLng?.lng() || !this?.map) return;

    const position = { lat: e.latLng?.lat(), lng: e.latLng?.lng() };

    if (this.type === "circle") {
      this.positions = [];
      this.setCenter(position);
      this.onRadiusChanged?.(100);
    }

    this.positions.push(position);
    this.mark?.setMap(null);

    this.loadFence(this.positions);
  }

  changeType(type: FenceType) {
    this.type = type;
    this.fence?.setMap(null);
    this.mark?.setMap(null);
    this.positions = [];
  }

  getPolyline() {
    if (this.fence instanceof google.maps.Polyline) {
      const paths = this.fence.getPath().getArray();

      const coordinates = paths.map((path) => ({
        latitude: path.lat(),
        longitude: path.lng(),
      }));

      return coordinates;
    }
  }

  getPolygon() {
    if (this.fence instanceof google.maps.Polygon) {
      const paths = this.fence.getPaths().getArray();

      const coordinates = paths.map(
        (path: google.maps.MVCArray<google.maps.LatLng>) =>
          path.getArray().map((coord: google.maps.LatLng) => ({
            latitude: coord.lat(),
            longitude: coord.lng(),
          }))
      );

      return coordinates[0];
    }

    return [];
  }

  getCircle() {
    if (this.fence instanceof google.maps.Circle) {
      const center = this.fence.getCenter();
      const radius = this.fence.getRadius();

      const circleData = [
        {
          latitude: center?.lat(),
          longitude: center?.lng(),
          radius: radius,
        },
      ];

      return circleData;
    }

    return null;
  }
}
