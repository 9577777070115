import { api } from ".";
import { ErrorResult } from "../interfaces/ErrorResult";
import { Login } from "../interfaces/Login";
import { LoginResultSuccess } from "../interfaces/LoginResult";

export class CredentialsService {
  static async login(data: Login): Promise<LoginResultSuccess | ErrorResult> {
    // const { access_token } = await this.getToken();

    return api
      .post("/api/v2/login", data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async resetPassword(email: string): Promise<ErrorResult> {
    return api
      .post(`/api/v2/reset-password`, { email })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  // private static async getToken() {
  //   return api
  //     .post("/oauth/token", {
  //       grant_type: "client_credentials",
  //       client_id: 2,
  //       client_secret: "ClnD9I9bSJFTP768dqDehNePqpqw3oJoHb1HVUPb",
  //       user_id: 1,
  //     })
  //     .then((r) => r.data)
  //     .catch((e) => e?.response?.data);
  // }
}
