import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0;
  /* padding-top: 1.5rem; */
  padding: 1.5rem !important;
  overflow: auto;

  td:nth-child(1) {
    width: 250px;
  }

  td:last-child {
    width: 25px;
  }

  td {
    text-transform: capitalize;
  }

  th {
    text-transform: uppercase;
  }

  .more-icon {
    height: 15px;
  }
`;

export const ActionButton = styled.button`
  height: 32px;
  width: 32px;
  color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-width: 0;
  background-color: transparent;

  &:hover {
    color: #000000;
    transition: color 0.3s ease;
  }
`;

export const AbsoluteLeft = styled.div`
  position: absolute;
  bottom: 0;
  align-self: flex-start;
`;

export const AbsoluteRight = styled(AbsoluteLeft)`
  position: absolute;
  bottom: 0;
  align-self: flex-end;
`;
