import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Modal = styled(RBModal)`
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-content {
    width: 600px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 27px;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 43px;
    color: #6C757D;
  }

  p {
    align-self: center;
    max-width: 482px;
    margin-top: 18px;
    margin-bottom: 35px;
  }

  /* .btn {
    width: 70px;
    margin: 5px;
  } */
`;
