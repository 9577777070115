import React from "react";
import { Breadcrumb } from "react-bootstrap";

import { Container } from "./styles";

interface NavbarProps {
  title: string;
  breadcrumb?: string[];
}

const NavBar: React.FC<NavbarProps> = ({ title, breadcrumb }) => {
  return (
    <Container>
      <div>
        {title && <h1 className="title">{title}</h1>}
        {breadcrumb && (
          <Breadcrumb>
            {breadcrumb.map((item) => (
              <Breadcrumb.Item key={`navbar_item_${item}`}>
                {item}
              </Breadcrumb.Item>
            ))}
            <Breadcrumb.Item></Breadcrumb.Item>
          </Breadcrumb>
        )}
      </div>
    </Container>
  );
};

export default NavBar;
