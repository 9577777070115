import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { ReactComponent as IcCopy } from "../../../assets/icons/copy.svg";
import { ReactComponent as IcPowerOff } from "../../../assets/icons/ic_power_off.svg";
import moment from "moment";
import { TrackingVehicleSensor } from "../../../core/interfaces/TrackingVehicles";
import { BsCheckAll } from "react-icons/bs";
import {
  useForm,
  useFieldArray,
  Controller,
  SubmitHandler,
} from "react-hook-form";
import { TrackingService } from "../../../core/services/TrackingService";
import { TrackingVehicleNetwork } from "../../../core/interfaces/TrackingVehicles";
import { useDispatch } from "react-redux";
import { updateAlert } from "../../../core/redux/reducer/alert";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ConfirmationSendCommandModal from "../../../components/ConfirmationSendCommandModal";
import { convertTime } from "../../../utils/time";
import { MaskService } from "../../../utils/masks-service";
import { VehicleService } from "../../../core/services/VehicleService";
import { VehicleHardwareResolver } from "../../../core/schemaValidations/vehicle";
import { FleetMateService } from "../../../core/services/FleetMateService";

interface HardwareProps {
  vehicleId?: number;
}

type FormValues = {
  actuator_code: string;
  actuator_code_constant?: string;
  networks?: TrackingVehicleNetwork;
  fleetmate_sensors?: TrackingVehicleSensor[];
};

const Hardware: React.FC<HardwareProps> = ({ vehicleId }) => {
  const [sensor, setSensor] = useState<TrackingVehicleSensor | null>(null);
  const [defaultValues, setDefaultValues] = useState<FormValues>();
  const dispatch = useDispatch();

  const {
    control,
    register,
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues,
    resolver: VehicleHardwareResolver,
  });
  const [copied, setCopied] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<string | false>(false);
  const [optionsActuators, setOptionsActuators] = useState<
    { name: string; id: string }[]
  >([]);
  const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timer>();
  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: "fleetmate_sensors",
    keyName: "fieldId",
  });

  function getNetworkColor(active?: string) {
    switch (active) {
      case "offline":
        return "red";
      case "online":
        return "green";
      case "standby":
        return "#0062ff";
    }
  }

  function getSensorColor(active?: boolean) {
    return active ? "#28A745" : "#939698";
  }

  function getDate(date?: string) {
    if (!date) return "-";
    return moment(date).format("DD/MM/YYYY - HH:mm");
  }

  const getOptionsActuators = (sensors: TrackingVehicleSensor[]) => {
    const data = sensors
      ?.filter((f) => f.port?.type?.toLowerCase() === "exit")
      ?.map((sensor) => ({ name: sensor.port.name, id: sensor.port.constant }));

    setOptionsActuators(data);
    setValue("actuator_code_constant", data?.[0]?.id);
  };

  function onSubmitSensor(sensor: TrackingVehicleSensor) {
    const isIntenger = Number.isInteger(+sensor.value);
    if (!isIntenger) {
      console.log("onSubmitSensor invalid sensor value", sensor.value);
      return;
    }

    const data = {
      vehicle_id: sensor.vehicle_id,
      command_type_constant: sensor.port.constant,
      action: Boolean(+sensor.value),
    };

    if (sensor.port.type === "exit") {
      TrackingService.sendCommand(data)
        .then((res) => {
          dispatch(
            updateAlert({
              title: t("vehicles.hardware.message.success_send_command"),
            })
          );
          loadVehicleDetails(vehicleId);
        })
        .catch((err) => {
          onCancel();
          dispatch(
            updateAlert({
              title: t("vehicles.hardware.title.error_send_command"),
              message: err?.response?.data?.message,
              type: "error",
            })
          );
        });
    }

    if (sensor.port.type === "entry") {
      data.action = !["active", "inactivate failed"].includes(
        sensor.status.name.toLowerCase()
      );
      TrackingService.updateSensor(data)
        .then((res) => {
          dispatch(
            updateAlert({
              title: t("vehicles.hardware.message.success_send_command"),
            })
          );
          loadVehicleDetails(vehicleId);
        })
        .catch((err) => {
          onCancel();
          dispatch(
            updateAlert({
              title: t("vehicles.hardware.title.error_send_command"),
              message: err?.response?.data?.message,
              type: "error",
            })
          );
        });
    }
  }

  function onCancel() {
    reset(defaultValues);
    if (!!sensor) setSensor(null);
  }

  function loadVehicleDetails(vehicleId?: number) {
    if (!vehicleId) return;

    TrackingService.getVehicleDetail(vehicleId)
      .then((res) => {
        setValue("fleetmate_sensors", res.data?.fleetmate_sensors);
        setValue("networks", res.data?.networks);
        setDefaultValues({
          actuator_code: "",
          fleetmate_sensors: res.data?.fleetmate_sensors,
          networks: res.data?.networks,
        });
        getOptionsActuators(res.data?.fleetmate_sensors);
      })
      .catch((err) => {
        alert("Error loading vehicle details");
      });
  }

  function handleCopyToClipboard(text: string) {
    if (copied) return;
    navigator.clipboard.writeText(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const handleGenerateActuatorCode: SubmitHandler<FormValues> = async (
    values
  ) => {
    const actuator = values?.actuator_code_constant;
    if (!vehicleId || !actuator) return;

    try {
      const response = await VehicleService.generateActuatorCode({
        vehicle_id: vehicleId,
        actuator,
        minutes: 30,
      });
      if (!!response?.code) {
        setValue("actuator_code", response.code);
        startCountdown();
      }
    } catch (err: any) {
      dispatch(
        updateAlert({
          title: t("vehicles.hardware.title.error_generate_actuator_code"),
          message: err?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  function startCountdown() {
    clearInterval(countdownInterval);
    let seconds = 60 * 30;
    setCountdown(convertTime(seconds));

    const interval = setInterval(() => {
      seconds--;
      setCountdown(convertTime(seconds));
      if (seconds <= 0) {
        clearInterval(interval);
        setCountdown(false);
      }
    }, 1000);
    setCountdownInterval(interval);
  }

  function formattedActuator(): string {
    return !!watch("actuator_code")
      ? MaskService.toMask("against_password", watch("actuator_code") || "")
      : "_ _ _-_ _ _";
  }

  async function handleUnlinkEquipment() {
    try {
      if (vehicleId) {
        await FleetMateService.unlinkEquipment(Number(vehicleId));
        loadVehicleDetails(vehicleId);
      }
    } catch (err) {}
  }

  React.useEffect(() => {
    if (vehicleId) {
      loadVehicleDetails(vehicleId);
    }
  }, [vehicleId]);

  return (
    <Row className="m-3">
      <Col xs="8">
        <h6>{t("vehicles.hardware.network_status.title")}</h6>
        <Stack direction="horizontal">
          <NetworkStatus
            title="GSM"
            status={getValues("networks.gsm_network_status")}
            statusColor={getNetworkColor(
              getValues("networks.gsm_network_status")
            )}
            date={getDate(getValues("networks.gsm_updated_at"))}
          />
          <NetworkStatus
            title="LoRaWAN"
            status={getValues("networks.lora_network_status")}
            statusColor={getNetworkColor(
              getValues("networks.lora_network_status")
            )}
            date={getDate(getValues("networks.lora_updated_at"))}
          />
          <NetworkStatus
            title="Iridium"
            status={getValues("networks.iridium_network_status")}
            statusColor={getNetworkColor(
              getValues("networks.iridium_network_status")
            )}
            date={getDate(getValues("networks.iridium_updated_at"))}
          />
        </Stack>
      </Col>

      <Col xs="12">
        <hr className="mt-3" />
      </Col>

      <Col className="my-1" xs="12">
        <h6>{t("vehicles.hardware.actuator.title")}</h6>
      </Col>

      {fields
        ?.filter((f) => f.port?.type?.toLowerCase() === "exit")
        ?.map((sensor, pos) => {
          const index = fields.indexOf(sensor);
          return (
            <Col key={`actuators_${sensor.fieldId}`} className="mb-3" xs="4">
              <Controller
                control={control}
                name={`fleetmate_sensors.${index}.value`}
                render={({ field: { value, onChange } }) => (
                  <Actuator
                    id={sensor.fieldId}
                    label={t("vehicles.hardware.sensor.status_confirm.label")}
                    title={`${t("vehicles.sensor.exit")}: ${pos + 1}`}
                    name={sensor.port.name}
                    status={sensor.status.name}
                    active={Boolean(+value)}
                    onChange={(e) => {
                      sensor.value = (+e.currentTarget.checked).toString();
                      onChange(e);
                      setSensor(sensor);
                    }}
                  />
                )}
              />
            </Col>
          );
        })}

      <Col xs="12">
        <hr className="mt-0" />
      </Col>

      <Col className="my-1" xs="12">
        <h6>{t("vehicles.hardware.against_password.title")}</h6>
      </Col>

      <Col xs="8">
        <Stack className="password" direction="horizontal">
          <Form.Group className="me-3" controlId="actuator_code_constant">
            <Form.Label>
              {t("vehicles.hardware.disable_actuator.label")}
            </Form.Label>
            <div className="d-flex">
              <div className="w-100">
                <Form.Select
                  {...register("actuator_code_constant")}
                  isInvalid={!!errors?.actuator_code_constant?.message}
                >
                  {!optionsActuators.length && (
                    <option value="" disabled>
                      {t("input.select_text")}
                    </option>
                  )}
                  {optionsActuators?.map((option, i) => (
                    <option key={i} value={option.id}>
                      {t(option.name.toLowerCase())}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.actuator_code_constant?.message}
                </Form.Control.Feedback>
              </div>

              <Button
                className="ms-3"
                // disabled={!!countdown}
                onClick={handleSubmit(handleGenerateActuatorCode)}
              >
                {t("vehicles.hardware.button.generate")}
              </Button>
            </div>
          </Form.Group>

          <div className="ms-3">
            <div className="preview">
              <div className="d-flex gap-2 align-items-center">
                {formattedActuator()}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </div>
              <div
                hidden={!watch("actuator_code")}
                className={copied ? "copied" : "copy"}
                onClick={() => handleCopyToClipboard(watch("actuator_code"))}
              >
                {copied
                  ? t("vehicles.hardware.copied")
                  : t("vehicles.hardware.copy")}
                <IcCopy />
              </div>
            </div>

            <div className="helper">
              {!!countdown
                ? t("vehicles.hardware.against_password_expires") + countdown
                : t("vehicles.hardware.against_password_view")}
            </div>
          </div>
        </Stack>
      </Col>

      <Col xs="12">
        <hr className="mt-3" />
      </Col>

      <Col className="my-1" xs="12">
        <h6>{t("vehicles.hardware.sensor.title")}</h6>
      </Col>

      {fields
        ?.filter((f) => f.port?.type?.toLowerCase() !== "exit")
        ?.map((sensor) => {
          const index = fields.indexOf(sensor);
          return (
            <Col xs="4" key={`${index}_${sensor.id}`}>
              <Controller
                control={control}
                name={`fleetmate_sensors.${index}.value`}
                render={({ field: { value } }) => (
                  <Sensor
                    title={t(sensor.port.name.toLowerCase()) + ":"}
                    status={sensor.status.name}
                    statusColor={getSensorColor(Boolean(+value))}
                    onClick={(e) => {
                      e.preventDefault();
                      sensor.value = String(+!Boolean(+value));
                      setSensor(sensor);
                    }}
                  />
                )}
              />
            </Col>
          );
        })}

      <Col xs="12" className="mt-4">
        <div className="d-flex justify-content-center gap-3">
          <p>
            {t("vehicles.hardware.model")}:{" "}
            {getValues("networks.serial_number")
              ? "Fleetmate"
              : t("no_equipment")}
          </p>
          <p>
            {t("vehicles.hardware.manufacturer")}:{" "}
            {getValues("networks.serial_number")
              ? "Fleetdesk"
              : t("no_equipment")}
          </p>
          <p>
            {t("vehicles.hardware.firmware") + ": "}
            {getValues("networks.firmware") ?? " "}
          </p>
          <p>
            {t("vehicles.hardware.serial") + " "}
            {getValues("networks.bluetooth_id") ?? "-"}
          </p>

          {getValues("networks.bluetooth_id") && (
            <button
              onClick={handleUnlinkEquipment}
              className="text-decoration-underline unlink-button"
              style={{ cursor: "pointer" }}
            >
              {t("vehicles.hardware.unlink_equipment")}
            </button>
          )}
        </div>
      </Col>

      <ConfirmationModal
        visible={sensor?.port?.type === "entry"}
        title={
          (sensor?.value === "0" &&
            t("vehicles.hardware.message.confirm_disable_sensor")) ||
          (sensor?.value === "1" &&
            t("vehicles.hardware.message.confirm_enable_sensor")) ||
          undefined
        }
        onClose={onCancel}
        onConfirm={() => {
          if (!!sensor) {
            onSubmitSensor(sensor);
          }
          setSensor(null);
        }}
      />

      <ConfirmationSendCommandModal
        visible={sensor?.port?.type === "exit"}
        onClose={onCancel}
        onConfirm={(e) => {
          if (!!sensor) {
            onSubmitSensor(sensor);
          }
          setSensor(null);
        }}
      />
    </Row>
  );
};

const Sensor = ({
  title,
  status,
  statusColor,
  onClick,
}: {
  title: string;
  status: string;
  statusColor?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <Stack className="sensor" direction="horizontal" onClick={onClick}>
      <div className="title">{title}</div>
      <div className="status">
        <div
          className="status-color"
          style={{ backgroundColor: statusColor }}
        />
        <div className="status-text">{status}</div>
      </div>
      <IcPowerOff />
    </Stack>
  );
};

const NetworkStatus = ({
  title,
  status,
  statusColor,
  date,
}: {
  title?: string;
  status?: string;
  statusColor?: string;
  date?: string;
}) => (
  <Stack className="network-status align-items-start" direction="horizontal">
    <div className="mt-1">{title}</div>
    <Stack direction="vertical" className="align-items-center">
      <div className="status">
        <div
          className="status-color"
          style={{ backgroundColor: statusColor }}
        />
        <div className="status-text">
          {status === "standby" ? "stand-by" : status}
        </div>
      </div>
      <div className="date">{date}</div>
    </Stack>
  </Stack>
);

const Actuator = (data: {
  id?: string;
  label?: string;
  title?: string;
  name: string;
  status: string;
  active?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const { t } = useTranslation();

  function getStatusNameActuador(status: string) {
    status = status.toLowerCase();
    switch (status) {
      case "active":
        return t("vehicles.hardware.sensor.status_active.label");
      case "inactive":
        return t("vehicles.hardware.sensor.status_inactive.label");
    }
  }

  return (
    <Stack className="actuators align-items-start" direction="vertical">
      <Stack className="align-items-start" direction="horizontal">
        <div style={{ flex: 1, justifyContent: "center" }}>
          {data?.title && <div className="label">{data.title}</div>}
          <div className="title">{t(data?.name.toLowerCase())}</div>
        </div>
        <Form.Switch
          className="mt-2"
          id={`command_sensor_${data?.id}`}
          checked={Boolean(Number(data.active))}
          onChange={data?.onChange}
          reverse
        />
      </Stack>
      <Stack direction="horizontal" className="confirmation">
        {!!data?.label && <div>{data.label}</div>}
        <div className="d-flex align-items-center gap-2">
          {["active", "inactivate failed"].includes(
            data?.status?.toLowerCase()
          ) && <BsCheckAll fill="#2f80ed" size={22} />}
          <div>{getStatusNameActuador(data?.status)}</div>
        </div>
      </Stack>
    </Stack>
  );
};

export default Hardware;
