import { api } from ".";
import { ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { StartPoint } from "../interfaces/StartPoint";

interface GetStartPoint {
  filter?: string;
  page?: number;
  order?: any;
}

export class StartPointService {
  static async get({
    filter,
    page,
    order,
  }: GetStartPoint): Promise<ApiResponseBase<StartPoint[]> | ErrorResult> {
    return await api
      .get(`/api/v2/start-point`, {
        params: {
          page: page ?? 1,
          filter: {
            search: filter,
          },
          order,
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(data: Partial<StartPoint>): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/start-point`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async update(data: Partial<StartPoint>): Promise<ErrorResult> {
    return await api
      .put(`/api/v2/start-point`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async delete(id: number): Promise<ErrorResult> {
    return await api
      .delete(`/api/v2/start-point/${id}`)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
