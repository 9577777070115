import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const LoadingReportScreen = () => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "300px" }}
    >
      <Spinner
        animation="border"
        role="status"
        variant="primary"
        className="mb-3"
        style={{ width: "3rem", height: "3rem" }}
      />
      <p className="text-secondary fw-semibold fs-5">{t("reports.loading")}</p>
    </div>
  );
};
