import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal } from "./styles";
import { DriverAddResolver } from "../../core/schemaValidations/driver_add";

interface DriverInvitationModalProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}
type FormValues = {
  users: string;
};

const DriverInvitationModal: React.FC<DriverInvitationModalProps> = ({
  visible = false,
  onClose,
  onSuccess,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: DriverAddResolver
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    navigate("new", { state: values });

    reset();
    onSuccess?.();
  };

  const onError: SubmitErrorHandler<FormValues> = (error) => {
    console.log("onSubmitError", error);
  };

  useEffect(() => {
    if (!visible) reset();
    setIsVisible(visible);
  }, [visible]);

  return (
    <Modal {...props} show={isVisible} size="lg" centered onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="flex-grow-1 text-start"
        >
          {t("drivers.add")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
          <Row>
            <Form.Group className="mb-3" controlId="users" as={Col} xs="12">
              <Form.Label>
                {t("drivers.add_upload_label")}
                <br />
                <span style={{ fontSize: "0.9rem", color: "#6C757D" }}>
                  {t("drivers.add_upload_subtitle")}
                </span>
              </Form.Label>
              <Form.Control
                {...register("users")}
                as="textarea"
                rows={8}
                placeholder={t("drivers.add_upload_enter")}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                isInvalid={!!errors?.users?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.users?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Col className="p-1" xs={{ offset: 8, span: 2 }}>
              <Button className="w-100" variant="primary" type="submit">
                {t("drivers.add_button")}
              </Button>
            </Col>
            <Col className="p-1" xs="2">
              <Button className="w-100" variant="secondary" onClick={onClose}>
                {t("drivers.cancel_button")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DriverInvitationModal;
