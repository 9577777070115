import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrivateMasterPage from "../../layout/PrivateMasterPage";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.indexOf("login") >= 0) {
      navigate("/tracking");
    }
  }, []);

  if (window.location.pathname.indexOf("login") >= 0) {
    return null;
  }

  return (
    <PrivateMasterPage>
      <div>Não encontrado</div>
    </PrivateMasterPage>
  );
};

export default NotFound;
