import styled from "styled-components";

export const Container = styled.div`
  height: 100% !important;
  display: flex;
  padding: 1rem 1.5rem !important;
  flex-direction: column;

  .form-switch {
    .form-check-label {
      color: #212529;
    }
  }
`;

export const THead = styled.thead`
  display: flex;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 6px 28px;
  margin-bottom: 0.5rem;

  tr {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  tr th {
    flex: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #6c757d;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 0px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #f2f2f2;
`;

export const Footer = styled.div`
  width: 100%;
  justify-content: space-between;

  /* position: absolute;
  bottom: 0;

  @media (max-width: 768px) {
    position: relative;
    margin-top: 3rem;
  } */
`;

export const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const Field = styled.div`
  display: flex;
  width: 100%;
  /* min-height: 54px; */
  border-radius: 4px;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #f2f2f2;
`;

export const AbsoluteLeft = styled.div`
  position: absolute;
  bottom: 0;
  align-self: flex-start;
`;

export const AbsoluteRight = styled.div`
  position: absolute;
  bottom: 0;
  align-self: flex-end;
`;
