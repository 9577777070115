import styled from "styled-components";

export const Container = styled.div`
  .btn {
    /* display: flex;
    align-items: center; */
    border-color: #ced4da;
  }

  .btn:focus {
    outline: none;

    /* box-shadow: 0 0 0 0.05rem rgba(108, 117, 125, 0.5); */
  }

  .btn:hover {
    background: transparent;
    color: #6c757d;
  }

  .btn svg {
    margin-right: 9px;
  }

  .user_img {
    display: block;
    height: 32px;
    min-height: 32px;
    width: 32px;
    min-width: 32px;
    margin-right: 10px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  /* td:nth-child(1) {
    width: 250px;
  } */

  td:last-child {
    width: 100px;
  }
`;
