import React from "react";

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Container } from "./styles";
import { API_LOADER_MAP } from "../../constants/map";

const Dashboard: React.FC = () => {
  const { isLoaded } = useJsApiLoader(API_LOADER_MAP);

  return (
    <Container>
      
    </Container>
  );
};

export default Dashboard;
