import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const company = yup
  .object({
    company_name: yup.string().max(40).required(),
    cnpj: yup.string().max(18).required(),
    address: yup.string().max(80).required(),
    state: yup.string().max(2).required(),
    city: yup.string().max(80).required(),
    number: yup.string().max(8).required(),
    cep: yup.string().max(9).required(),
    burgh: yup.string().max(80).required(),
    mail_billing: yup.string().email().max(80),
    languages_id: yup.number(),
    address_complement: yup.string().max(80).required(),
    timezone: yup.string(),
  })
  .required();

export const CompanyResolver = yupResolver(company);
