import { APiQueryBase } from "../interfaces/ApiResponseBase";
import { RoterizationResponse } from "../interfaces/Routing";
import { api } from ".";
import { TODO_TYPE } from "../redux/reducer/TODO";
import axios from "axios";
import { TaskItem } from "../redux/reducer/routing";
import { IMoveJob } from "../interfaces/MoveJob";
import { MoveJob, MoveToOrphan } from "../interfaces/MoveJobs";
import { AllTask, DataTask, RouteData } from "../interfaces/RoutingPlaning";
import { isErrorResult } from "../utils/api";

export class RoutingService {
  static GET_PLANNING_ROTERIZATION = "/api/v2/planning-roterization/plan-route/jobs/";
  static async getRoterization({
    page = 1,
    order,
    search,
    initial_timewindow,
    end_timewindow,
  }: APiQueryBase) {
    return await api
      .get("api/v2/planning-roterization/roterization", {
        params: {
          page: page,
          per_page: 10,
          filter: JSON.stringify({
            search,
            initial_timewindow,
            end_timewindow,
          }),
          order: JSON.stringify(order),
        },
      })
      .then((r: any) => r.data);
  }

  static async getRoterizationById(id: number | string){
    return api.get<any>(`/api/v2/planning-roterization/plan-route/show-route/${id}`)
      .then((r: any) => r.data)
  }

  static async getTaskRoterizationById(id: number | string){
    return api.get<DataTask>(`${this.GET_PLANNING_ROTERIZATION}${id}`)
      .then((r: any) => r.data)
  }

  static async getTasks({ filter, page = 1, search, order }: APiQueryBase) {
    return await api
      .get("/api/v2/planning-roterization/task", {
        params: {
          page: page,
          per_page: 10,
          q: search,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      })
      .then((r: any) => r.data);
  }

  static async getRoterizationTracking({
    id,
    filter,
    page = 1,
    per_page = 10,
    search,
  }: APiQueryBase) {
    return await api
      .get<RouteData>(
        `/api/v2/planning-roterization/roterization/${id}`,
        {
          params: {
            _page: page,
            per_page: per_page,
            search: search,
            filter: JSON.stringify(filter),
            // order: JSON.stringify(order),
          },
        }
      )
      .then((r: any) => r.data);
  }

  static async getExcelSheet() {
    try {
      const response = await api.get(
        "api/v2/planning-roterization/download-template-jobs-excel",
        {
          responseType: "arraybuffer",
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPendingTasks() {
    return await api
      .get(`api/v2/planning-roterization/amount-task`)
      .then((r: any) => r.data);
  }

  static async getTask(id: string) {
    return await api
      .get(`api/v2/planning-roterization/task/${id}`)
      .then((r: any) => r.data);
  }

  static async getConfig() {
    return await api
      .get(`api/v2/company-configurations`)
      .then((r: any) => r.data);
  }

  static async createTask(data: TODO_TYPE) {
    return await api
      .post(`api/v2/planning-roterization/task`, data)
      .then((r: any) => r.data);
  }

  static async updateTask(data: TODO_TYPE, id: string) {
    console.log(data, "payload");
    return await api
      .put(`api/v2/planning-roterization/task/${id}`, data)
      .then((r: any) => r.data);
  }

  static async uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    console.log(formData);

    try {
      const response = await api.post(
        `api/v2/planning-roterization/import-template-jobs-excel`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  }

  static async deleteTask(id: number) {
    return await api
      .delete(`api/v2/planning-roterization/task/${id}`)
      .then((r: any) => r.data);
  }

  static async getStartIds() {
    return await api.get(`api/v2/start-point`,  {
      params: {
        per_page: 1000,
      },
    }).then((r: any) => r.data);
  }

  static async startRoute(data: TODO_TYPE) {
    try {
      const response = await api.post(
        "/api/v2/planning-roterization/start-roterization",
        data
      );
      if (response.status === 200) {
        return response;
      } else {
        throw new Error("Failed to start route.");
      }
    } catch (error) {
      throw new Error("An error occurred while starting the route.");
    }
  }

  static async sendRoterization(data: any) {
    const apiUrl = "/api/v2/planning-roterization/send-planning-for-monitoring-job";
    const response = await api.post(apiUrl, data);
    return response;
  }

  static async deleteSelected(tasksToDelete: TODO_TYPE) {
    const apiUrl = "/api/v2/planning-roterization/mass-destroy-task";
    try {
      const response = await api.post(apiUrl, { tasks: tasksToDelete });
      return response;
    } catch (error) {
      console.error("Error deleting tasks:", error);
      throw error;
    }
  }

  static async moveJob(data: MoveJob) {
    return await api
      .post(`api/v2/planning-roterization/plan-route/jobs/insert-job-in-route`, data)
      .then((r: any) => r.data);
  }

  static async moveToOrphan(data: MoveToOrphan) {
    return await api
      .post(`api/v2/planning-roterization/plan-route/jobs/move-job-to-orfa`, data)
      .then((r: any) => r.data);
  }

  static async changeVichle(data:any){
    return await api
      .post(`api/v2/planning-roterization/plan-route/update-route-vehicle`, data)
      .then((r: any) => r.data);
  }

  static async getRouteOptions(search: string, page: number){
    return await api
      .get(`api/v2/filter/route-description`, { params: { filter: { search } }})
      .then((r: any) => r.data);
  }

  static async loadRouteDescriptionOptions(search: string,
    loadedOptions: any,
    additional?: any
  ){
    const { page } = additional;
    const response = await this.getRouteOptions(search, page);

    const options: any = {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
      },
    };

    if (isErrorResult(response)) return options;

    const data = response.data;

    options.options = data?.map((d: any) => ({
      label: `${d.description}`,
      value: d.id,
    }));

    options.hasMore =
      (response.meta && response.meta.current_page < response.meta.last_page) ||
      false;
    options.additional = {
      page: Number(page) + 1,
    };

    return options;

  }

  static async storeJob(data: TODO_TYPE){
    return await api
      .post(`api/v2/tracking/job/store-job`, data)
      .then((r: any) => r.data);
  }
}
