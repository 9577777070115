import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AudioMessage from "../../TrakingTasksModal/Messages/audio";

export default function Messages({ data, summary }: any) {
  const { t } = useTranslation();
  const [sumarized, setSummarized] = useState(undefined);

  useEffect(() => {
    if (typeof data?.registry?.data === "string") {
      const parsedData = JSON.parse(data?.registry?.data);
      setSummarized(parsedData);
    }
  }, [summary]);

  function verifyMessageType(type: string) {
    if (type === "audio") {
      return (
        <AudioMessage
          me
          message={
            data?.registry?.data?.message
              ? data?.registry?.data?.message
              : data?.registry?.data
          }
          name=""
        />
      );
    }

    if (type === "image") {
      return (
        <img
          className="img-sent"
          alt="image message"
          src={
            data?.registry?.data?.message?.attachment_url
              ? data?.registry?.data?.message?.attachment_url
              : data?.registry?.data?.attachment_url
          }
        />
      );
    }

    if (type === "text") {
      return (
        <p>
          {data?.registry?.data?.message?.message
            ? data?.registry?.data?.message?.message
            : data?.registry?.data?.message}
        </p>
      );
    }
  }

  return (
    <>
      <div className="item-group p-0">
        <Row>
          <Col>
            <label>{t("configs.audit.event_date_hour")}</label>
            <p>
              {data?.registry?.generated_at
                ? moment(data?.registry?.generated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>

          <Col>
            <label>{t("configs.audit.datetime_receipt")}</label>
            <p>
              {data?.registry?.received_at
                ? moment(data?.registry?.received_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.datetime_reading")}</label>
            <p>
              {data?.registry?.data?.read_at
                ? moment(data?.registry?.data?.read_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group">
        <Row>
          <Col>
            <label>{t("configs.audit.sender")}</label>
            <p>{data?.registry?.origin ? data?.registry?.origin : "--"}</p>
          </Col>
          <Col>
            <label>{t("configs.audit.recipient")}</label>
            <p>
              {data?.user?.name
                ? data?.user?.name + " " + data?.user?.last_name
                : "--"}
            </p>
          </Col>
        </Row>
      </div>

      <div className="item-group border-0">
        <Row>
          <Col>
            <label>{t("configs.audit.message")}:</label>
            {verifyMessageType(
              data?.registry?.data?.message?.type
                ? data?.registry?.data?.message?.type
                : data?.registry?.data?.type
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
