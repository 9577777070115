import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

type ContainerProps = {
  expanded?: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: 60vh;
  width: 100%;
  overflow: hidden;
  position: ${(props) => (props.expanded ? "absolute" : "relative")};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  display: flex;

  .gmnoprint[role="menubar"] {
    left: ${(props) => (props.expanded ? 370 : 0)}px !important;
  }

  .sidebar {
    min-width: 360px;
    max-width: 360px !important;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.67);
    backdrop-filter: blur(17.5px);
    /* padding: 36px 24px 16px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;

    div h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #6c757d;
    }

    .import-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6c757d;
    }

    .anchor {
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #6c757d;

      &:hover {
        cursor: pointer;
        color: #007bff;
        transition: color 0.2s ease;
      }
    }

    .btn-close-custom {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.4);

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.2);
        transition: color 0.3s ease;
      }
    }
  }

  .fence-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    gap: 11px;

    position: relative;
    top: 18px;
    left: 180px;
    margin: 0 auto;
  }

  .fence-button {
    width: 147.81px;
    height: 58.26px;

    display: flex;
    background: #ffffff;
    box-shadow: 0px -1px 34px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #007bff;

    &.selected {
      border: 1px solid #2f80ed;
    }
  }

  .expand-map {
    position: absolute;
    right: 11px;
    bottom: 127px;
  }
`;

export const Modal = styled(RBModal)`
  .modal-header {
    border: 0;
    padding: 1.5em;
    margin-bottom: -3em;
    z-index: 5;
  }

  .attribute-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: fit-content;

    label {
      margin-bottom: 8px;
    }
  }
`;
