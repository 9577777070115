import { FormSelect, FormSelectProps, InputGroup } from "react-bootstrap";
import countries from "../../core/resources/countries.json";

export default function CountryFlagSelect(props: FormSelectProps) {
  return (
    <InputGroup className="mb-3">
      <InputGroup.Text id="country-group">
        <span className={`fi fi-${props.value?.toString().toLowerCase()}`} />
      </InputGroup.Text>
      <FormSelect {...props}>
        {countries.map((country, i) => (
          <option key={country.sigla} value={country.sigla}>
            {country.nome_pais_int}
          </option>
        ))}
      </FormSelect>
    </InputGroup>
  );
}
