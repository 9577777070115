import { api } from ".";
import { APiQueryJSONBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import {
  ApiResponseSMSNotification,
  CreateSMSNotification,
  SMSNotification,
  SendSMSNotification,
  TypeSMSNotification,
} from "../interfaces/SMSNotification";

export class SMSNotificationService {
  static async get(
    query: APiQueryJSONBase<SMSNotification>
  ): Promise<ApiResponseSMSNotification | ErrorResult> {
    return await api
      .get("/api/v2/sms/notification", {
        params: {
          filter: JSON.stringify(query?.filter),
          order: JSON.stringify(query?.order),
          page: query?.page,
          per_page: 10,
        },
      })
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async getTypes(): Promise<TypeSMSNotification[] | ErrorResult> {
    return await api
      .get("/api/v2/sms/notificationtype")
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async create(data: CreateSMSNotification): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/sms/notification`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }

  static async send(data: SendSMSNotification): Promise<ErrorResult> {
    return await api
      .post(`/api/v2/sms/notification/sms`, data)
      .then((r) => r.data)
      .catch((e) => e?.response?.data);
  }
}
