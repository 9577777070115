import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import {
  TrackingAlert,
  TrackingGlobalMessage,
} from "../../interfaces/TrackingMessage";
import type { RootState } from "../store";

// Define a type for the slice state
interface TrackingComunicationState {
  number_of_messages: number;
  number_of_alerts: number;
  global_messages: TrackingGlobalMessage[];
  global_alerts: TrackingAlert[];
  vehicle_ids: number[];
}

// Define the initial state using that type
const initialState: TrackingComunicationState = {
  number_of_messages: 0,
  global_messages: [],
  number_of_alerts: 0,
  global_alerts: [],
  vehicle_ids: [],
};

interface ReducerActions extends SliceCaseReducers<TrackingComunicationState> {
  updateTrackingNumberOfMessages(
    state: TrackingComunicationState,
    action: PayloadAction<number>
  ): void;
  updateTrackinGlobalMessages(
    state: TrackingComunicationState,
    action: PayloadAction<TrackingGlobalMessage[]>
  ): void;
  updateTrackingNumberOfAlerts(
    state: TrackingComunicationState,
    action: PayloadAction<number>
  ): void;
  updateTrackinGlobalAlerts(
    state: TrackingComunicationState,
    action: PayloadAction<TrackingAlert[]>
  ): void;

  updateTrackinVehicleIds(
    state: TrackingComunicationState,
    action: PayloadAction<number[]>
  ): void;
}

export const trakingComunicationSlice: Slice<
  TrackingComunicationState,
  ReducerActions
> = createSlice({
  name: "tracking",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateTrackingNumberOfMessages: (state, action: PayloadAction<number>) => {
      state.number_of_messages = action.payload;
    },
    updateTrackinGlobalMessages: (
      state,
      action: PayloadAction<TrackingGlobalMessage[]>
    ) => {
      state.global_messages = action.payload;
    },
    updateTrackingNumberOfAlerts: (state, action: PayloadAction<number>) => {
      state.number_of_alerts = action.payload;
    },
    updateTrackinGlobalAlerts: (
      state,
      action: PayloadAction<TrackingAlert[]>
    ) => {
      state.global_alerts = action.payload;
    },
    updateTrackinVehicleIds: (state, action: PayloadAction<number[]>) => {
      state.vehicle_ids = action.payload;
    },
  },
});

export const {
  updateTrackingNumberOfMessages,
  updateTrackinGlobalMessages,
  updateTrackingNumberOfAlerts,
  updateTrackinGlobalAlerts,
  updateTrackinVehicleIds,
} = trakingComunicationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getTracking = (state: RootState) => state.tracking_comunication;
export const getTrackingNumberOfMessages = (state: RootState) =>
  state.tracking_comunication.number_of_messages;
export const getTrackingGlobalMessages = (state: RootState) =>
  state.tracking_comunication.global_messages;
export const getTrackingNumberOfAlerts = (state: RootState) =>
  state.tracking_comunication.number_of_alerts;
export const getTrackingGlobalAlerts = (state: RootState) =>
  state.tracking_comunication.global_alerts;
export const getTrackingVehicleIds = (state: RootState) =>
  state.tracking_comunication.vehicle_ids;
export default trakingComunicationSlice.reducer;
