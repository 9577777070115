import React, { useState } from "react";
import { Card, Col, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Container } from "./styles";
import Pagination from "../../components/Pagination";

import Footer from "../../components/Footer";
import { ActionButtonOutline } from "../../components/ActionButtonOutline";
import { FaFilter } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import DrivingBehaviorFilterModal from "../../components/DrivingBehaviorFilterModal";
import CustomThead from "../../components/CustomThead";

const DrivingBehavior: React.FC = () => {
  const [isOpenFilter, setOpenFilter] = React.useState<boolean>(false);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: string;
  }>({ page: 1, pages: 0, filter: "" });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDetails = () => {
    navigate("details");
  };

  return (
    <PrivateMasterPage
      title={t("driving_behavior.title")}
      breadcrumb={[t("driving_behavior.title")]}
    >
      <Container className="p-3">
        <Card className="h-100">
          <Card.Body className="p-4 mb-5 h-100 overflow-hidden">
            <Row className="mb-2">
              <Col className="d-flex align-items-end mb-3">
                <Stack
                  className="d-flex align-items-end w-100"
                  direction="horizontal"
                  gap={3}
                >
                  <div className="w-100">
                    <h4>{t("driving_behavior.subtitle")}</h4>
                  </div>

                  <ActionButtonOutline>
                    <FiDownload size={20} />
                  </ActionButtonOutline>

                  <ActionButtonOutline onClick={() => setOpenFilter(true)}>
                    <FaFilter size={18} />
                  </ActionButtonOutline>
                </Stack>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <Table responsive hover>
                  <CustomThead
                    data={[
                      {
                        column: "1",
                        name: t("driving_behavior.table_ranking"),
                      },
                      { column: "2", name: t("driving_behavior.table_driver") },
                      {
                        column: "3",
                        name: t("driving_behavior.table_km_traveled"),
                      },
                      {
                        column: "4",
                        name: t("driving_behavior.table_driving_hours"),
                      },
                      {
                        column: "5",
                        name: t("driving_behavior.table_acceleration"),
                      },
                      {
                        column: "6",
                        name: t("driving_behavior.table_braking"),
                      },
                      {
                        column: "7",
                        name: t("driving_behavior.table_cell_phone"),
                      },
                      {
                        column: "8",
                        name: t("driving_behavior.table_search_underwear"),
                      },
                      {
                        column: "9",
                        name: t("driving_behavior.table_speeding"),
                      },
                    ]}
                    onChangeOrder={(event) => console.log(event)}
                  />
                  <tbody>
                    <tr onClick={() => handleDetails()}>
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <BsStarFill />
                          8.5
                        </div>
                      </td>
                      <td>Alex Saldanha</td>
                      <td>33.300</td>
                      <td>04:22</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row className="mt-5">
              <Footer>
                <div />
                <div className="mx-3">
                  <Pagination
                    current_page={pagination.page}
                    qtd={pagination.pages}
                    // onSelectPage={(page) => {
                    //   loadEmbeddedIntelligences({ ...pagination, page });
                    // }}
                  />
                </div>
              </Footer>
            </Row>
          </Card.Body>
        </Card>

        <DrivingBehaviorFilterModal
          visible={isOpenFilter}
          onClose={() => setOpenFilter(false)}
        />
      </Container>
    </PrivateMasterPage>
  );
};

export default DrivingBehavior;
