import React from "react";
import { Button, Col, Form, Modal, Row, Spinner, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { updateAlert } from "../../../core/redux/reducer/alert";
import { useDispatch } from "react-redux";
import { ModalStyles } from "./styles";
import { Link } from "react-router-dom";

interface EditAccessProfileModalProps {
  visible?: boolean;
  onClose?(): void;
  onSuccess?(): void;
}

export type FormValues = {
  name: string;
  last_name: string;
  email: string;
};

const EditContactModal: React.FC<EditAccessProfileModalProps> = ({
  visible = false,
  onClose,
  onSuccess,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      last_name: "",
      email: "",
    },
  });

  async function onSubmit({ name, last_name, email }: FormValues) {
    try {
      console.log({
        name,
        last_name,
        email,
      });
      // reset();
      onSuccess?.();
    } catch (error: any) {
      dispatch(
        updateAlert({
          title: "Erro ao salvar os dados!",
          message: error.message,
          type: "error",
        })
      );
    }
  }

  const handleClose = () => {
    // reset();
    onClose?.();
  };

  return (
    <Modal
      {...props}
      show={visible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header className="border-0 p-4 pb-0 pt-3" closeButton>
        <Modal.Title>{t("configs.menu.manage_license")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pb-3 pt-3">
        <ModalStyles>
          <Form noValidate onSubmit={handleSubmit(onSubmit, console.warn)}>
            <Row>
              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>{t("configs.user.name")}</Form.Label>
                <Form.Control
                  {...register("name")}
                  placeholder={t("configs.user.name")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.name?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>{t("configs.menu.last_name")}</Form.Label>
                <Form.Control
                  {...register("last_name", {
                    required: true,
                  })}
                  placeholder={t("configs.menu.last_name")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.name?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.last_name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="name" as={Col} xs="6">
                <Form.Label>{t("configs.user.email")}</Form.Label>
                <Form.Control
                  {...register("email")}
                  placeholder={t("configs.user.email")}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  isInvalid={!!errors?.email?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Stack
              direction="horizontal"
              gap={2}
              style={{ justifyContent: "end" }}
              className="mt-5"
            >
              <Button
                id="on-submit-access-profile"
                variant="primary"
                type="submit"
              >
                {t("button.save")}
                {isSubmitting && <Spinner animation="border" size="sm" />}
              </Button>

              <Button variant="secondary" onClick={handleClose}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Form>
        </ModalStyles>
      </Modal.Body>
    </Modal>
  );
};

export default EditContactModal;
