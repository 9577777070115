import { api } from "."

interface ISendRecoverPasswordEntry{
    token:string
    email:string
    password:string
}

export async function resetPassword({email, token, password}:ISendRecoverPasswordEntry){
    try{
        const {data} = await api.post('/api/v2/forget-password/reset',{
            email,
            token,
            password
        })
        return data
    }catch(err:any){
        console.error(err)
        throw new Error(err)
    }
}