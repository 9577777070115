import { Col, Row, Stack, Table } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { Container } from "./styles";
import { BsArrowLeftShort } from "react-icons/bs";
import { useEffect, useState } from "react";
import EmptyListMessage from "../../../components/EmptyListMessage";
import CustomThead from "../../../components/CustomThead";
import Pagination from "../../../components/Pagination";
import { APiQueryBase } from "../../../core/interfaces/ApiResponseBase";
import { BillingService } from "../../../core/services/BillingService";
import { isErrorResult } from "../../../core/utils/api";
import { useAppSelector } from "../../../core/redux/hooks";
import { getUser } from "../../../core/redux/reducer/auth";
import Footer from "../../../components/Footer";
interface IProps {
  onClickBack: () => void;
}
export const InvoiceHistory = ({ onClickBack }: IProps) => {
  const { t } = useTranslation();
  const user = useAppSelector(getUser);
  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order?: { [key: string]: string };
  }>({ page: 1, pages: 0, filter: "" });
  const [fields, setFields] = useState<any[]>([]);

  async function loadBillingInvoices({
    filter = "",
    page = 1,
    order,
  }: APiQueryBase) {
    try {
      const _response = await BillingService.get({ filter, page, order });
      const isError = isErrorResult(_response);
      if (!isError && _response.data) {
        //@ts-ignore
        replace(_response.data);
        //@ts-ignore
        setDefaultValues(_response.data);
        setPagination((prev) => ({
          ...prev,
          //@ts-ignore
          page: _response?.current_page || 1,
          //@ts-ignore
          pages: _response?.last_page || 0,
        }));
      }
    } catch (error: any) {
      console.log("Erro in load fleetmate.ei", error.message);
    }
  }

  useEffect(() => {
    loadBillingInvoices(pagination);
  }, [pagination.filter, pagination.page, pagination.order, user?.company_id]);

  return (
    <Container className="px-0">
      <button className="button" onClick={onClickBack}>
        <Stack direction="horizontal" gap={2}>
          <BsArrowLeftShort size={24} />
          {t("link.go_back")}
        </Stack>
      </button>

      <h1 className="title">{t("configs.menu.invoice_date")}</h1>

      <Row className="mb-5">
        <Col>
          <Table responsive>
            <CustomThead
              data={[
                {
                  column: "created_at",
                  name: t("configs.menu.date_hour"),
                },
                {
                  column: "updated_at",
                  name: t("configs.menu.invoice"),
                },
                { column: "user_name", name: t("configs.menu.value") },
                {
                  column: "amount_events",
                  name: t("configs.menu.status"),
                },
              ]}
              onChangeOrder={(order) => {
                setPagination((prev) => ({ ...prev, order }));
              }}
            />

            <tbody>
              {fields?.map((item, index) => (
                <tr key={`${index}_${item.fieldId}`}>
                  <td>12/01/2020 - 17:39</td>
                  <td>#1297 PDF</td>
                  <td>R$ 19,90</td>
                  <td>Pago</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          {!fields.length && (
            <EmptyListMessage style={{ marginTop: "2%" }}>
              {t("configs.menu.empty_table")}
            </EmptyListMessage>
          )}
        </Col>
      </Row>

      <Row>
        <Footer>
          <div className="mx-3">
            <Pagination
              current_page={pagination.page}
              qtd={pagination.pages}
              onSelectPage={(page) => {
                loadBillingInvoices({ ...pagination, page });
              }}
            />
          </div>
        </Footer>
      </Row>
    </Container>
  );
};
