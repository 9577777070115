import { Card as BSCard, Container as BSContainer } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
`;

export const Card = styled(BSCard)`
  min-height: 85vh;
  width: 100%;
  overflow: hidden;

  .card-body,
  .config-content {
    min-height: 85vh;
  }

  .config-content {
    padding-top: 27px;
    /* padding-right: 30px; */

    & > div {
      padding-right: 20px !important;
    }
  }
`;
