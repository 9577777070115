import { api } from ".";

export class FileService {
  static async fetchFile(path: string) {
    return await api
      .post("/api/v2/file/get-blob", { path }, { responseType: "blob" })
      .then((r) => r)
      .catch((e) => e?.Response?.data);
  }
}
