import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import PublicMasterPage from "../../layout/PublicMasterPage";
import { ReactComponent as IcLogoDark } from "../../assets/img/logo_dark.svg";
import "./styles.css";
import { Link } from "react-router-dom";
import LoginCarousel from "../../components/LoginCarousel";
import { MdArrowBack } from "react-icons/md";
import { useForm } from "react-hook-form";
import { sendRecoverPassword } from "../../core/services/SendRecoverPasswordService";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/Logo";

interface IFormEntry {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormEntry>();
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const { t, i18n } = useTranslation();

  async function onSubmit(data: IFormEntry) {
    try {
      await sendRecoverPassword(data);
      setIsRequestCompleted(true);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    {
      /**@ts-ignore */
    }
    /* @ts-ignore */
    var userLang = navigator.language || navigator?.userLanguage;
    localStorage.setItem("LANGUAGE_ID", userLang);
    if (localStorage.getItem("LANGUAGE_ID")) {
      i18n.changeLanguage(localStorage.getItem("LANGUAGE_ID") ?? "");
    }
  }, []);

  return (
    <PublicMasterPage>
      <Container fluid id="login">
        <Row>
          <Col className="p-0" xs={6}>
            <LoginCarousel />
          </Col>
          <Col xs={6} className="right p-0">
            <div className="right-container">
              {/* <div className="logo-container">
                <IcLogoDark />
              </div> */}
              <Logo />
              <div id="form-container">
                <Link
                  to={"/login"}
                  className="d-flex gap-2 align-items-center back_to_login_btn"
                >
                  <MdArrowBack />
                  <span>{t("forgot_password.back")}</span>
                </Link>
                <div className="wellcome-message">
                  <div className="title">{t("forgot_password.title")}</div>
                  <p className="sub-title mb-1">
                    {t("forgot_password.subtitle")}
                  </p>
                  <p className="sub-title">
                    {t("forgot_password.second_subtitle")}
                  </p>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{t("forgot_password.email_label")}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("forgot_password.placeholder")}
                      {...register("email")}
                      required
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("forgot_password.button_message")}
                  </Button>

                  {isRequestCompleted && (
                    <Alert variant="success" className="mt-3">
                      {t("forgot_password.success_message")}
                    </Alert>
                  )}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PublicMasterPage>
  );
};

export default ForgotPassword;
