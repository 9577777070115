import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const subcontractor_invitation = yup
  .object({
    cnpj: yup.string().max(18).required(),
  })
  .required();

export const SubcontractorInvitationResolver = yupResolver(
  subcontractor_invitation
);
