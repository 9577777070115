import RBModal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Modal = styled(RBModal)`
  .modal-header {
    border: 0;
    padding: 1.5em;
  }

  input:disabled,
  select:disabled {
    background-color: transparent;
    opacity: 0.5;
  }
`;
